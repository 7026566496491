import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close, EditOutlined } from "@material-ui/icons";
// import AvatarGroup from '@mui/material/AvatarGroup';
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import jpegImage from "../../../assets/img/jpegImage.png";
import pdfImage from "../../../assets/img/pdfImage.png";
import moment from "moment";
import { PersonalService } from "../../../services/personal.service.js";
import { dateFormat } from "../../../Utils/dateFormate.js";

const useStyles = makeStyles(styles);
export default function AlternateIdInformation(props) {
  const classes = useStyles();
  const personalService = new PersonalService();

  React.useEffect(() => {
    personalService
      .alternateIdShow(props?.alternateIdentification[0]?.id)
      .then(() => {})
      .catch(() => {});
  }, []);

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%"
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          rightIcon={
            <EditOutlined
              onClick={() =>
                props?.handleOpenEdit(props?.alternateIdentification[0])
              }
              style={{
                color: color.white,
                marginLeft: "auto",
                fontSize: "36px",
                cursor: "pointer",
              }}
            />
          }
          heading={
            props?.alternateIdentification[0]?.id_type
              ? props?.alternateIdentification[0]?.id_type
              : "Information"
          }
          color={color.white}
        />
        <div className={classes.modalBody}>
          <div className="w-100 d-flex flex-row mb-5">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Personal > Alternate Identification > Information"}
            </Typography>
          </div>
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"ID Type"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.alternateIdentification[0]?.id_type !==
                  "Government Issued ID" &&
                props?.alternateIdentification[0]?.id_type !== "Military ID"
                  ? "Other"
                  : props?.alternateIdentification[0]?.id_type}
              </Typography>
            </Grid>
            {props?.alternateIdentification[0]?.id_type !==
              "Government Issued ID" &&
            props?.alternateIdentification[0]?.id_type !== "Military ID" ? (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Other"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew, fontSize: "18px" }}
                  >
                    {props?.alternateIdentification[0]?.id_type}
                  </Typography>
                </Grid>
              </>
            ) : null}
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"ID Number"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.alternateIdentification[0]?.identification_number ? (
                  props?.alternateIdentification[0]?.identification_number
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Expiration Date"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.alternateIdentification[0]?.expiration_date ? (
                  moment(
                    props?.alternateIdentification[0]?.expiration_date?.replaceAll(
                      "-",
                      "/"
                    )
                  ).format(dateFormat()?.toUpperCase())
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
          </Grid>
          {props?.alternateIdentification[0]?.documents && (
            <Grid
              container
              wrap="wrap"
              direction="row"
              spacing={3}
              className="mt-5"
            >
              <Grid item xs={12} sm={6} md={6}>
                <Typography
                  variant="h6"
                  component="h3"
                  style={{ color: color.themeColorNew }}
                >
                  {"Linked Items"}
                </Typography>
                <div className="d-flex flex-row align-items-center mt-2">
                  <img src={jpegImage} className="me-2" />
                  <img src={pdfImage} className="me-2" />
                  <Typography
                    onClick={props.handleOpenLinkedItems}
                    variant="h6"
                    component="h3"
                    align="right"
                    className="text-decoration-underline"
                    style={{ color: color.themeColorNew, cursor: "pointer" }}
                  >
                    {"See all"}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          )}
        </div>
      </CustomModal>
    </>
  );
}
