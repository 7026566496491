import React from "react";
import { Grid, Typography } from "@material-ui/core";
import PermissionTabs from "../../components/PermissionTabs/PermissionTabs.js";

export default function Permission() {
    return (
        <>
            <Grid container wrap="wrap"
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid item xs={12} sm={12} md={1}></Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "25px", }}>
                    <Typography variant="h6" component="h3" style={{ color: "#060d5c" }}>
                        {"Permission"}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={1}></Grid>
            </Grid>
            <Grid container wrap="wrap"
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid item xs={12} sm={12} md={1}></Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "25px", }}>
                    <PermissionTabs />
                </Grid>
                <Grid item xs={12} sm={12} md={1}></Grid>
            </Grid>
        </>
    );
}
