import * as actions from "../actions/types";
import isEmpty from "lodash/isEmpty";

export const intialState = {
  isAuthenticated: false,
  user: {},
  loading: false,
  error: false,
  loginAnimation: false,
  animation: false,
  loadAnimation: null,
};

export default function authReducer(state = intialState, action) {
  switch (action.type) {
    case actions.SET_AUTH_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload,
        error: false,
        loading: false,
        loginAnimation: true,
      };
    case actions.SET_AUTH_ERROR:
      return {
        ...state,
        error: action.payload,
        isAuthenticated: false,
        user: {},
        loading: false,
      };
    case actions.CLEAR_AUTH_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case "USER COUNT":
      return {
        ...state,
        user: {
          ...state.user,
          sign_in_count: action.payload,
        },
      };
    case "PHONE NUMBER":
      return {
        ...state,
        user: {
          ...state.user,
          phone: action.payload,
        },
      };
    case "2 FA":
      return {
        ...state,
        user: {
          ...state.user,
          twofa_enabled: action.payload,
        },
      };
    case "ANIMATION":
      return {
        ...state,
        animation: action.payload,
      };
    case "LOAD_ANIMATION":
      return {
        ...state,
        loadAnimation: action.payload,
      };
    case "PLAN TYPE":
      return {
        ...state,
        user: {
          ...state.user,
          account_type: action.payload,
          is_subscribed: true,
        },
      };
    case "TIMEZONE":
      return {
        ...state,
        user: {
          ...state.user,
          time_zone: action.payload,
        },
      };
    case "LOGIN_ANIMATION":
      return {
        ...state,
        loginAnimation: action.payload,
      };
    case "PAUSE_NOTIFICATION":
      return {
        ...state,
        user: {
          ...state.user,
          pause_notification: action.payload,
        },
      };
    case actions.SET_AUTH_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actions.CLEAR_STATE:
      return {
        ...state,
        isAuthenticated: false,
        user: {},
        loading: false,
        error: false,
        loginAnimation: false,
      };
    default:
      return state;
  }
}
