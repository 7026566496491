import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close, EditOutlined } from "@material-ui/icons";
// import AvatarGroup from '@mui/material/AvatarGroup';
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import jpegImage from "../../../assets/img/jpegImage.png";
import pdfImage from "../../../assets/img/pdfImage.png";
import { PropertyService } from "../../../services/property.service.js";
import { dateFormat } from "../../../Utils/dateFormate.js";
import moment from "moment";

const useStyles = makeStyles(styles);
export default function HouseInformation(props) {
  const classes = useStyles();
  const propertyService = new PropertyService();

  React.useEffect(() => {
    propertyService
      .housesShow(props?.house[0]?.id)
      .then(() => {})
      .catch(() => {});
  }, []);

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%"
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          rightIcon={
            <EditOutlined
              onClick={() => props?.handleOpenEdit(props?.house[0])}
              style={{
                color: color.white,
                marginLeft: "auto",
                fontSize: "36px",
                cursor: "pointer",
              }}
            />
          }
          heading={"House"}
          color={color.white}
        />
        <div className={classes.modalBody}>
          <div className="w-100 d-flex flex-row mb-5">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Property > House > Information"}
            </Typography>
          </div>
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Description"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.house[0]?.ownership_description}
              </Typography>
            </Grid>
            {props?.house[0]?.linked_items &&
              props?.house[0]?.linked_items?.mortgage && (
                <>
                  <Grid item xs={12} sm={4} md={4}>
                    <Typography
                      variant="h6"
                      component="h3"
                      style={{ color: color.themeColorNew }}
                    >
                      {"Mortgage"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} md={8}>
                    <Typography
                      variant="p"
                      component="p"
                      style={{ color: color.themeColorNew, fontSize: "18px" }}
                    >
                      {props?.house[0]?.linked_items &&
                        props?.house[0]?.linked_items?.mortgage &&
                        props?.house[0]?.linked_items?.mortgage?.mortgage_type}
                    </Typography>
                  </Grid>
                </>
              )}
            {props?.house[0]?.linked_items &&
              props?.house[0]?.linked_items?.property_insurance && (
                <>
                  <Grid item xs={12} sm={4} md={4}>
                    <Typography
                      variant="h6"
                      component="h3"
                      style={{ color: color.themeColorNew }}
                    >
                      {"Property Insurance"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} md={8}>
                    <Typography
                      variant="p"
                      component="p"
                      style={{ color: color.themeColorNew, fontSize: "18px" }}
                    >
                      {
                        props?.house[0]?.linked_items?.property_insurance
                          ?.insurance_provider
                      }
                    </Typography>
                  </Grid>
                </>
              )}
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"City"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.house[0]?.property_city}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Property Type"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.house[0]?.property_type ? (
                  props?.house[0]?.property_type
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Date Acquired"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.house[0]?.date_acquired
                  ? moment(props?.house[0]?.date_acquired?.replaceAll("-","/")).format(
                      dateFormat()?.toUpperCase()
                    )
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Cost"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.house[0]?.cost ? (
                  props?.house[0]?.cost
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Value"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.house[0]?.value ? (
                  props?.house[0]?.value
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Monthly Payment"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.house[0]?.monthly_payment ? (
                  props?.house[0]?.monthly_payment
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Owner"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.house[0]?.owner ? (
                  props?.house[0]?.owner.charAt(0).toUpperCase(1) +
                  props?.house[0]?.owner.slice(1)
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Percent Ownership"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.house[0]?.percent_ownership ? (
                  props?.house[0]?.percent_ownership
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Property Owner"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.house[0]?.property_owner ? (
                  props?.house[0]?.property_owner
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Tax Account ID"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.house[0]?.tax_account_id ? (
                  props?.house[0]?.tax_account_id
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Parcel ID Number"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.house[0]?.parcel_id_number ? (
                  props?.house[0]?.parcel_id_number
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Notes"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.house[0]?.notes ? (
                  props?.house[0]?.notes
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
          </Grid>
          {props?.house[0]?.documents && (
            <Grid
              container
              wrap="wrap"
              direction="row"
              spacing={3}
              className="mt-5"
            >
              <Grid item xs={12} sm={6} md={6}>
                <Typography
                  variant="h6"
                  component="h3"
                  style={{ color: color.themeColorNew }}
                >
                  {"Linked Items"}
                </Typography>
                <div className="d-flex flex-row align-items-center mt-2">
                  <img src={jpegImage} className="me-2" />
                  <img src={pdfImage} className="me-2" />
                  <Typography
                    onClick={props.handleOpenLinkedItems}
                    variant="h6"
                    component="h3"
                    align="right"
                    className="text-decoration-underline"
                    style={{ color: color.themeColorNew, cursor: "pointer" }}
                  >
                    {"See all"}
                  </Typography>
                </div>
              </Grid>
              {/*<Grid item xs={12} sm={6} md={6}>*/}
              {/*  <Typography*/}
              {/*    variant="h6"*/}
              {/*    component="h3"*/}
              {/*    align="right"*/}
              {/*    style={{ color: color.themeColorNew }}*/}
              {/*  >*/}
              {/*    {"Collaborators"}*/}
              {/*  </Typography>*/}
              {/*  <div className="d-flex flex-row align-items-center justify-content-end mt-2">*/}
              {/*    <img src={jpegImage} className="me-2" />*/}
              {/*    <img src={pdfImage} className="me-2" />*/}
              {/*    <Typography*/}
              {/*      onClick={props.handleOpenCollaborators}*/}
              {/*      variant="h6"*/}
              {/*      component="h3"*/}
              {/*      align="right"*/}
              {/*      className="text-decoration-underline"*/}
              {/*      style={{ color: color.themeColorNew, cursor: "pointer" }}*/}
              {/*    >*/}
              {/*      {"See all"}*/}
              {/*    </Typography>*/}
              {/*  </div>*/}
              {/*</Grid>*/}
            </Grid>
          )}
        </div>
      </CustomModal>
    </>
  );
}
