import "date-fns";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Switch,
  Typography,
  useTheme,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import color from "../../../assets/css/color.js";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { HistoryService } from "../../../services/history.service.js";
import SuccessGreen from "../Alerts/SuccessGreen/SuccessGreen";
import Error from "../Alerts/Error/Error";
import { Close } from "@material-ui/icons";

const timing = [
  "Select Start Date",
  "1 week from expiration",
  "2 weeks from expiration",
  "1 month from expiration",
  "3 months from expiration",
  "6 months from expiration",
];

const frequancy = [
  "Select Frequency",
  "Every other Day",
  "Daily",
  "Weekly",
  "Biweekly",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles(styles);

function AlertPrefrence(props) {
  const historyService = new HistoryService();
  const [alerts, setAlerts] = React.useState([]);
  const [alternateId, setAlternateId] = React.useState(null);
  const [autoInsurance, setAutoInsurance] = React.useState(null);
  const [autoService, setAutoService] = React.useState(null);
  const [driverLicenses, setDriverLicense] = React.useState(null);
  const [disabilityInsurance, setDisabilityInsurance] = React.useState(null);
  const [expenses, setExpenses] = React.useState(null);
  const [healthInsurance, setHealthInsurance] = React.useState(null);
  const [immunization, setImmunization] = React.useState(null);
  const [lifeInsurance, setLifeInsurance] = React.useState(null);
  const [cerId, setCerId] = React.useState(null);
  const [cerIdBefore, setCerIdBefore] = React.useState(null);

  const [medication, setMedication] = React.useState(null);
  const [otherInsurance, setOtherInsurance] = React.useState(null);
  const [passport, setPassport] = React.useState(null);
  const [propertyInsurance, setPropertInsurance] = React.useState(null);
  const [serialNumber, setSerialNumber] = React.useState(null);
  const [visa, setVisa] = React.useState(null);
  const [loading, setLoading] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [msg, setMsg] = React.useState(null);
  const [alternateIdBefore, setAlternateIdBefore] = React.useState(null);
  const [autoInsuranceBefore, setAutoInsuranceBefore] = React.useState(null);
  const [autoServiceBefore, setAutoServiceBefore] = React.useState(null);
  const [driverLicensesBefore, setDriverLicenseBefore] = React.useState(null);
  const [disabilityInsuranceBefore, setDisabilityInsuranceBefore] =
    React.useState(null);
  const [expensesBefore, setExpensesBefore] = React.useState(null);
  const [healthInsuranceBefore, setHealthInsuranceBefore] =
    React.useState(null);
  const [immunizationBefore, setImmunizationBefore] = React.useState(null);
  const [lifeInsuranceBefore, setLifeInsuranceBefore] = React.useState(null);
  const [medicationBefore, setMedicationBefore] = React.useState(null);
  const [otherInsuranceBefore, setOtherInsuranceBefore] = React.useState(null);
  const [passportBefore, setPassportBefore] = React.useState(null);
  const [propertyInsuranceBefore, setPropertInsuranceBefore] =
    React.useState(null);
  const [serialNumberBefore, setSerialNumberBefore] = React.useState(null);
  const [visaBefore, setVisaBefore] = React.useState(null);

  React.useEffect(() => {
    historyService
      .alertsIndex()
      .then((res) => {
        console.log({ res });
        setAlerts(res.data ? res.data : []);
        if (res.data) {
          let alter = res.data.filter(
            (a) => a.category === "AlternateIdentification"
          );
          setAlternateId({
            timing:
              alter[0].timing === "one_week"
                ? "1 week from expiration"
                : alter[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : alter[0].timing === "one_month"
                ? "1 month from expiration"
                : alter[0].timing === "three_months"
                ? "3 months from expiration"
                : alter[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              alter[0].frequency === "daily"
                ? "Daily"
                : alter[0].frequency === "every_other_day"
                ? "Every other Day"
                : alter[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: alter[0].is_active,
          });
          setAlternateIdBefore({
            timing:
              alter[0].timing === "one_week"
                ? "1 week from expiration"
                : alter[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : alter[0].timing === "one_month"
                ? "1 month from expiration"
                : alter[0].timing === "three_months"
                ? "3 months from expiration"
                : alter[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              alter[0].frequency === "daily"
                ? "Daily"
                : alter[0].frequency === "every_other_day"
                ? "Every other Day"
                : alter[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: alter[0].is_active,
          });
          let cer = res.data.filter((a) => a.category === "Certificate");
          setCerId({
            timing:
              cer[0].timing === "one_week"
                ? "1 week from expiration"
                : cer[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : cer[0].timing === "one_month"
                ? "1 month from expiration"
                : cer[0].timing === "three_months"
                ? "3 months from expiration"
                : cer[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              cer[0].frequency === "daily"
                ? "Daily"
                : cer[0].frequency === "every_other_day"
                ? "Every other Day"
                : cer[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: cer[0].is_active,
          });
          setCerIdBefore({
            timing:
            cer[0].timing === "one_week"
                ? "1 week from expiration"
                : cer[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : cer[0].timing === "one_month"
                ? "1 month from expiration"
                : cer[0].timing === "three_months"
                ? "3 months from expiration"
                : cer[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
            cer[0].frequency === "daily"
                ? "Daily"
                : cer[0].frequency === "every_other_day"
                ? "Every other Day"
                : cer[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: cer[0].is_active,
          });
          let autoSer = res.data.filter((a) => a.category === "AutoService");
          setAutoService({
            timing:
              autoSer[0].timing === "one_week"
                ? "1 week from expiration"
                : autoSer[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : autoSer[0].timing === "one_month"
                ? "1 month from expiration"
                : autoSer[0].timing === "three_months"
                ? "3 months from expiration"
                : autoSer[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              autoSer[0].frequency === "daily"
                ? "Daily"
                : autoSer[0].frequency === "every_other_day"
                ? "Every other Day"
                : autoSer[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: autoSer[0].is_active,
          });
          setAutoServiceBefore({
            timing:
              autoSer[0].timing === "one_week"
                ? "1 week from expiration"
                : autoSer[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : autoSer[0].timing === "one_month"
                ? "1 month from expiration"
                : autoSer[0].timing === "three_months"
                ? "3 months from expiration"
                : autoSer[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              autoSer[0].frequency === "daily"
                ? "Daily"
                : autoSer[0].frequency === "every_other_day"
                ? "Every other Day"
                : autoSer[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: autoSer[0].is_active,
          });
          let autoIn = res.data.filter((a) => a.category === "AutoInsurance");
          setAutoInsurance({
            timing:
              autoIn[0].timing === "one_week"
                ? "1 week from expiration"
                : autoIn[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : autoIn[0].timing === "one_month"
                ? "1 month from expiration"
                : autoIn[0].timing === "three_months"
                ? "3 months from expiration"
                : autoIn[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              autoIn[0].frequency === "daily"
                ? "Daily"
                : autoIn[0].frequency === "every_other_day"
                ? "Every other Day"
                : autoIn[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: autoIn[0].is_active,
          });
          setAutoInsuranceBefore({
            timing:
              autoIn[0].timing === "one_week"
                ? "1 week from expiration"
                : autoIn[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : autoIn[0].timing === "one_month"
                ? "1 month from expiration"
                : autoIn[0].timing === "three_months"
                ? "3 months from expiration"
                : autoIn[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              autoIn[0].frequency === "daily"
                ? "Daily"
                : autoIn[0].frequency === "every_other_day"
                ? "Every other Day"
                : autoIn[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: autoIn[0].is_active,
          });
          let dis = res.data.filter(
            (a) => a.category === "DisabilityInsurance"
          );
          setDisabilityInsurance({
            timing:
              dis[0].timing === "one_week"
                ? "1 week from expiration"
                : dis[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : dis[0].timing === "one_month"
                ? "1 month from expiration"
                : dis[0].timing === "three_months"
                ? "3 months from expiration"
                : dis[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              dis[0].frequency === "daily"
                ? "Daily"
                : dis[0].frequency === "every_other_day"
                ? "Every other Day"
                : dis[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: dis[0].is_active,
          });
          setDisabilityInsuranceBefore({
            timing:
              dis[0].timing === "one_week"
                ? "1 week from expiration"
                : dis[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : dis[0].timing === "one_month"
                ? "1 month from expiration"
                : dis[0].timing === "three_months"
                ? "3 months from expiration"
                : dis[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              dis[0].frequency === "daily"
                ? "Daily"
                : dis[0].frequency === "every_other_day"
                ? "Every other Day"
                : dis[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: dis[0].is_active,
          });
          let dl = res.data.filter((a) => a.category === "DriverLicense");
          setDriverLicense({
            timing:
              dl[0].timing === "one_week"
                ? "1 week from expiration"
                : dl[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : dl[0].timing === "one_month"
                ? "1 month from expiration"
                : dl[0].timing === "three_months"
                ? "3 months from expiration"
                : dl[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              dl[0].frequency === "daily"
                ? "Daily"
                : dl[0].frequency === "every_other_day"
                ? "Every other Day"
                : dl[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: dl[0].is_active,
          });
          setDriverLicenseBefore({
            timing:
              dl[0].timing === "one_week"
                ? "1 week from expiration"
                : dl[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : dl[0].timing === "one_month"
                ? "1 month from expiration"
                : dl[0].timing === "three_months"
                ? "3 months from expiration"
                : dl[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              dl[0].frequency === "daily"
                ? "Daily"
                : dl[0].frequency === "every_other_day"
                ? "Every other Day"
                : dl[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: dl[0].is_active,
          });
          let ex = res.data.filter((a) => a.category === "Expense");
          setExpenses({
            timing:
              ex[0].timing === "one_week"
                ? "1 week from expiration"
                : ex[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : ex[0].timing === "one_month"
                ? "1 month from expiration"
                : ex[0].timing === "three_months"
                ? "3 months from expiration"
                : ex[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              ex[0].frequency === "daily"
                ? "Daily"
                : ex[0].frequency === "every_other_day"
                ? "Every other Day"
                : ex[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: ex[0].is_active,
          });
          setExpensesBefore({
            timing:
              ex[0].timing === "one_week"
                ? "1 week from expiration"
                : ex[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : ex[0].timing === "one_month"
                ? "1 month from expiration"
                : ex[0].timing === "three_months"
                ? "3 months from expiration"
                : ex[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              ex[0].frequency === "daily"
                ? "Daily"
                : ex[0].frequency === "every_other_day"
                ? "Every other Day"
                : ex[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: ex[0].is_active,
          });
          let hi = res.data.filter((a) => a.category === "HealthInsurance");
          setHealthInsurance({
            timing:
              hi[0].timing === "one_week"
                ? "1 week from expiration"
                : hi[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : hi[0].timing === "one_month"
                ? "1 month from expiration"
                : hi[0].timing === "three_months"
                ? "3 months from expiration"
                : hi[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              hi[0].frequency === "daily"
                ? "Daily"
                : alter[0].frequency === "every_other_day"
                ? "Every other Day"
                : hi[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: hi[0].is_active,
          });
          setHealthInsuranceBefore({
            timing:
              hi[0].timing === "one_week"
                ? "1 week from expiration"
                : hi[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : hi[0].timing === "one_month"
                ? "1 month from expiration"
                : hi[0].timing === "three_months"
                ? "3 months from expiration"
                : hi[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              hi[0].frequency === "daily"
                ? "Daily"
                : alter[0].frequency === "every_other_day"
                ? "Every other Day"
                : hi[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: hi[0].is_active,
          });
          let im = res.data.filter((a) => a.category === "Immunization");
          setImmunization({
            timing:
              im[0].timing === "one_week"
                ? "1 week from expiration"
                : im[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : im[0].timing === "one_month"
                ? "1 month from expiration"
                : im[0].timing === "three_months"
                ? "3 months from expiration"
                : im[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              im[0].frequency === "daily"
                ? "Daily"
                : im[0].frequency === "every_other_day"
                ? "Every other Day"
                : im[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: im[0].is_active,
          });
          setImmunizationBefore({
            timing:
              im[0].timing === "one_week"
                ? "1 week from expiration"
                : im[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : im[0].timing === "one_month"
                ? "1 month from expiration"
                : im[0].timing === "three_months"
                ? "3 months from expiration"
                : im[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              im[0].frequency === "daily"
                ? "Daily"
                : im[0].frequency === "every_other_day"
                ? "Every other Day"
                : im[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: im[0].is_active,
          });
          let li = res.data.filter((a) => a.category === "LifeInsurance");
          setLifeInsurance({
            timing:
              li[0].timing === "one_week"
                ? "1 week from expiration"
                : li[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : li[0].timing === "one_month"
                ? "1 month from expiration"
                : li[0].timing === "three_months"
                ? "3 months from expiration"
                : li[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              li[0].frequency === "daily"
                ? "Daily"
                : li[0].frequency === "every_other_day"
                ? "Every other Day"
                : li[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: li[0].is_active,
          });
          setLifeInsuranceBefore({
            timing:
              li[0].timing === "one_week"
                ? "1 week from expiration"
                : li[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : li[0].timing === "one_month"
                ? "1 month from expiration"
                : li[0].timing === "three_months"
                ? "3 months from expiration"
                : li[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              li[0].frequency === "daily"
                ? "Daily"
                : li[0].frequency === "every_other_day"
                ? "Every other Day"
                : li[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: li[0].is_active,
          });
          let md = res.data.filter((a) => a.category === "Medication");
          setMedication({
            timing:
              md[0].timing === "one_week"
                ? "1 week from expiration"
                : md[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : md[0].timing === "one_month"
                ? "1 month from expiration"
                : md[0].timing === "three_months"
                ? "3 months from expiration"
                : md[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              md[0].frequency === "daily"
                ? "Daily"
                : md[0].frequency === "every_other_day"
                ? "Every other Day"
                : md[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: md[0].is_active,
          });
          setMedicationBefore({
            timing:
              md[0].timing === "one_week"
                ? "1 week from expiration"
                : md[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : md[0].timing === "one_month"
                ? "1 month from expiration"
                : md[0].timing === "three_months"
                ? "3 months from expiration"
                : md[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              md[0].frequency === "daily"
                ? "Daily"
                : md[0].frequency === "every_other_day"
                ? "Every other Day"
                : md[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: md[0].is_active,
          });
          let oi = res.data.filter((a) => a.category === "OtherInsurance");
          setOtherInsurance({
            timing:
              oi[0].timing === "one_week"
                ? "1 week from expiration"
                : oi[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : oi[0].timing === "one_month"
                ? "1 month from expiration"
                : oi[0].timing === "three_months"
                ? "3 months from expiration"
                : oi[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              oi[0].frequency === "daily"
                ? "Daily"
                : oi[0].frequency === "every_other_day"
                ? "Every other Day"
                : oi[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: oi[0].is_active,
          });
          setOtherInsuranceBefore({
            timing:
              oi[0].timing === "one_week"
                ? "1 week from expiration"
                : oi[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : oi[0].timing === "one_month"
                ? "1 month from expiration"
                : oi[0].timing === "three_months"
                ? "3 months from expiration"
                : oi[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              oi[0].frequency === "daily"
                ? "Daily"
                : oi[0].frequency === "every_other_day"
                ? "Every other Day"
                : oi[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: oi[0].is_active,
          });
          let pass = res.data.filter((a) => a.category === "Passport");
          setPassport({
            timing:
              pass[0].timing === "one_week"
                ? "1 week from expiration"
                : pass[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : pass[0].timing === "one_month"
                ? "1 month from expiration"
                : pass[0].timing === "three_months"
                ? "3 months from expiration"
                : pass[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              pass[0].frequency === "daily"
                ? "Daily"
                : pass[0].frequency === "every_other_day"
                ? "Every other Day"
                : pass[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: pass[0].is_active,
          });
          setPassportBefore({
            timing:
              pass[0].timing === "one_week"
                ? "1 week from expiration"
                : pass[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : pass[0].timing === "one_month"
                ? "1 month from expiration"
                : pass[0].timing === "three_months"
                ? "3 months from expiration"
                : pass[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              pass[0].frequency === "daily"
                ? "Daily"
                : pass[0].frequency === "every_other_day"
                ? "Every other Day"
                : pass[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: pass[0].is_active,
          });
          let pi = res.data.filter((a) => a.category === "PropertyInsurance");
          setPropertInsurance({
            timing:
              pi[0].timing === "one_week"
                ? "1 week from expiration"
                : pi[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : pi[0].timing === "one_month"
                ? "1 month from expiration"
                : pi[0].timing === "three_months"
                ? "3 months from expiration"
                : pi[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              pi[0].frequency === "daily"
                ? "Daily"
                : pi[0].frequency === "every_other_day"
                ? "Every other Day"
                : pi[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: pi[0].is_active,
          });
          setPropertInsuranceBefore({
            timing:
              pi[0].timing === "one_week"
                ? "1 week from expiration"
                : pi[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : pi[0].timing === "one_month"
                ? "1 month from expiration"
                : pi[0].timing === "three_months"
                ? "3 months from expiration"
                : pi[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              pi[0].frequency === "daily"
                ? "Daily"
                : pi[0].frequency === "every_other_day"
                ? "Every other Day"
                : pi[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: pi[0].is_active,
          });
          let sn = res.data.filter((a) => a.category === "SerialNumber");
          setSerialNumber({
            timing:
              sn[0].timing === "one_week"
                ? "1 week from expiration"
                : sn[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : sn[0].timing === "one_month"
                ? "1 month from expiration"
                : sn[0].timing === "three_months"
                ? "3 months from expiration"
                : sn[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              sn[0].frequency === "daily"
                ? "Daily"
                : sn[0].frequency === "every_other_day"
                ? "Every other Day"
                : sn[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: sn[0].is_active,
          });
          setSerialNumberBefore({
            timing:
              sn[0].timing === "one_week"
                ? "1 week from expiration"
                : sn[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : sn[0].timing === "one_month"
                ? "1 month from expiration"
                : sn[0].timing === "three_months"
                ? "3 months from expiration"
                : sn[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              sn[0].frequency === "daily"
                ? "Daily"
                : sn[0].frequency === "every_other_day"
                ? "Every other Day"
                : sn[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: sn[0].is_active,
          });
          let v = res.data.filter((a) => a.category === "Visa");
          setVisa({
            timing:
              v[0].timing === "one_week"
                ? "1 week from expiration"
                : v[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : v[0].timing === "one_month"
                ? "1 month from expiration"
                : v[0].timing === "three_months"
                ? "3 months from expiration"
                : v[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              v[0].frequency === "daily"
                ? "Daily"
                : v[0].frequency === "every_other_day"
                ? "Every other Day"
                : v[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: v[0].is_active,
          });
          setVisaBefore({
            timing:
              v[0].timing === "one_week"
                ? "1 week from expiration"
                : v[0].timing === "two_weeks"
                ? "2 weeks from expiration"
                : v[0].timing === "one_month"
                ? "1 month from expiration"
                : v[0].timing === "three_months"
                ? "3 months from expiration"
                : v[0].timing === "six_months"
                ? "6 months from expiration"
                : "6 week from expiration",
            frequency:
              v[0].frequency === "daily"
                ? "Daily"
                : v[0].frequency === "every_other_day"
                ? "Every other Day"
                : v[0].frequency === "weekly"
                ? "Weekly"
                : "Biweekly",
            active: v[0].is_active,
          });
        }
      })
      .catch((err) => {
        console.log("alerts ", err.response);
      });
  }, []);
  const classes = useStyles();

  const handleClose = () => {
    setMsg(null);
    setOpen(false);
    setOpenError(false);
  };
  const handleSubmit = (data, category) => {
    setLoading(category);
    let temp = alerts.filter((cat) => cat.category === category);
    const obj = {
      id: temp[0].id,
      timing:
        data.timing === "1 week from expiration"
          ? 0
          : data.timing === "2 weeks from expiration"
          ? 1
          : data.timing === "1 month from expiration"
          ? 2
          : data.timing === "3 months from expiration"
          ? 3
          : 4,
      frequency: data.frequency?.includes("Daily")
        ? 0
        : data.frequency?.includes("Every")
        ? 1
        : data.frequency === "Weekly"
        ? 2
        : 3,
      is_active: data.active,
    };
    const historyService = new HistoryService();
    historyService
      .alertsUpdate(obj)
      .then(() => {
        setLoading(null);
      })
      .catch((e) => {
        setLoading(null);
        setOpenError(true);
        if (e.response) {
          setMsg(e.response?.data?.errors?.full_messages);
        } else {
          setMsg("Something went wrong, try again later!");
        }
      });
  };

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="60%"
        marginTop={15}
        marginBottom={15}
      >
        {open && (
          <SuccessGreen
            open={open}
            handleClose={() => handleClose()}
            basic={true}
            message="Your alert preference has been successfully updated"
          />
        )}
        {openError && (
          <Error
            open={openError}
            basic={true}
            handleClose={() => handleClose()}
            message={msg}
          />
        )}
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          // extraClass="p-3 justify-content-start position-relative"
          // closeModal={props.handleClose}
          // headerBgColor={color.themeColorNew}
          color="white"
          // textAlign="center"
          heading="Edit Alert Preferences"
        />
        <div className={classes.modalBody + " " + "overflow_hidden mx_xs_15px"}>
          <div className="alert_main">
            <div className={"scrollWidthZero"}>
              <Grid
                container
                wrap="wrap"
                direction="row"
                justifyContent="center"
                alignItems="center"
                // spacing={3}
                // className="w_1100px"
              >
                <Grid
                  container
                  spacing={3}
                  wrap="wrap"
                  xs={12}
                  sm={12}
                  md={12}
                  className="w-auto m-auto"
                >
                  <Grid
                    item
                    xs={3}
                    sm={3}
                    md={3}
                    style={{ border: "1px solid #000" }}
                  >
                    <Typography
                      variant="h6"
                      component="p"
                      className="mb-0"
                      style={{ color: "#060d5c" }}
                    >
                      {"Category"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={4}
                    md={4}
                    style={{ border: "1px solid #000" }}
                  >
                    <Typography
                      variant="h6"
                      component="p"
                      className="mb-0"
                      style={{ color: "#060d5c" }}
                    >
                      {"Timing"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sm={3}
                    md={3}
                    style={{ border: "1px solid #000" }}
                  >
                    <Typography
                      variant="h6"
                      component="p"
                      className="mb-0"
                      style={{ color: "#060d5c" }}
                    >
                      {"Frequency"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    md={2}
                    style={{ border: "1px solid #000" }}
                  >
                    <Typography
                      variant="h6"
                      component="p"
                      className="mb-0"
                      style={{ color: "#060d5c" }}
                    >
                      {"Disabled | Active	"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div className={"overflow-auto scrollWidthZero maxHeight600px"}>
              <Grid
                container
                wrap="wrap"
                direction="row"
                justifyContent="center"
                alignItems="center"
                //   spacing={3}
                //   className="w_1100px"
              >
                {alternateId && (
                  <Grid
                    container
                    spacing={3}
                    wrap="wrap"
                    xs={12}
                    sm={12}
                    md={12}
                    className="w-auto m-auto"
                  >
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <Typography
                        variant="p"
                        component="p"
                        className="mb-0"
                        style={{ color: "#060d5c", fontWeight: "normal" }}
                      >
                        {"Alternate Identifications	"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setAlternateId({
                              ...alternateId,
                              timing: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            timing.filter((s) => s === alternateId.timing)[0]
                          }
                        >
                          {timing?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setAlternateId({
                              ...alternateId,
                              frequency: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            frequancy.filter(
                              (s) => s === alternateId.frequency
                            )[0]
                          }
                        >
                          {frequancy?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      style={{ border: "1px solid #000" }}
                    >
                      <Switch
                        // checked={state.checkedB}
                        onChange={(e) => {
                          setAlternateId({
                            ...alternateId,
                            active: e.target.checked,
                          });
                          handleSubmit(
                            {
                              ...alternateId,
                              active: e.target.checked,
                            },
                            "AlternateIdentification"
                          );
                        }}
                        color="primary"
                        defaultChecked={alternateId?.active}
                        name="checkedB"
                        style={{
                          color: alternateId?.active
                            ? "green"
                            : color.themeOrange,
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                  </Grid>
                )}
                {cerId && (
                  <Grid
                    container
                    spacing={3}
                    wrap="wrap"
                    xs={12}
                    sm={12}
                    md={12}
                    className="w-auto m-auto"
                  >
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <Typography
                        variant="p"
                        component="p"
                        className="mb-0"
                        style={{ color: "#060d5c", fontWeight: "normal" }}
                      >
                        {"Certificate"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setCerId({
                              ...cerId,
                              timing: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            timing.filter((s) => s === cerId.timing)[0]
                          }
                        >
                          {timing?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setCerId({
                              ...cerId,
                              frequency: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            frequancy.filter((s) => s === cerId.frequency)[0]
                          }
                        >
                          {frequancy?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      style={{ border: "1px solid #000" }}
                    >
                      <Switch
                        onChange={(e) => {
                          setCerId({
                            ...cerId,
                            active: e.target.checked,
                          });
                          handleSubmit(
                            {
                              ...cerId,
                              active: e.target.checked,
                            },
                            "Certificate"
                          );
                        }}
                        color="primary"
                        name="checkedB"
                        defaultChecked={cerId.active}
                        style={{
                          color: cerId?.active ? "green" : color.themeOrange,
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                  </Grid>
                )}
                {autoInsurance && (
                  <Grid
                    container
                    spacing={3}
                    wrap="wrap"
                    xs={12}
                    sm={12}
                    md={12}
                    className="w-auto m-auto"
                  >
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <Typography
                        variant="p"
                        component="p"
                        className="mb-0"
                        style={{ color: "#060d5c", fontWeight: "normal" }}
                      >
                        {"Auto Insurance"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setAutoInsurance({
                              ...autoInsurance,
                              timing: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            timing.filter((s) => s === autoInsurance.timing)[0]
                          }
                        >
                          {timing?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setAutoInsurance({
                              ...autoInsurance,
                              frequency: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            frequancy.filter(
                              (s) => s === autoInsurance.frequency
                            )[0]
                          }
                        >
                          {frequancy?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      style={{ border: "1px solid #000" }}
                    >
                      <Switch
                        onChange={(e) => {
                          setAutoInsurance({
                            ...autoInsurance,
                            active: e.target.checked,
                          });
                          handleSubmit(
                            {
                              ...autoInsurance,
                              active: e.target.checked,
                            },
                            "AutoInsurance" < Switch
                          );
                        }}
                        color="primary"
                        name="checkedB"
                        defaultChecked={autoInsurance.active}
                        style={{
                          color: autoInsurance?.active
                            ? "green"
                            : color.themeOrange,
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                  </Grid>
                )}
                {autoService && (
                  <Grid
                    container
                    spacing={3}
                    wrap="wrap"
                    xs={12}
                    sm={12}
                    md={12}
                    className="w-auto m-auto"
                  >
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <Typography
                        variant="p"
                        component="p"
                        className="mb-0"
                        style={{ color: "#060d5c", fontWeight: "normal" }}
                      >
                        {"Auto Services"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setAutoService({
                              ...autoService,
                              timing: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            timing.filter((s) => s === autoService.timing)[0]
                          }
                        >
                          {timing?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setAutoService({
                              ...autoService,
                              frequency: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            frequancy.filter(
                              (s) => s === autoService.frequency
                            )[0]
                          }
                        >
                          {frequancy?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      style={{ border: "1px solid #000" }}
                    >
                      <Switch
                        onChange={(e) => {
                          setAutoService({
                            ...autoService,
                            active: e.target.checked,
                          });
                          handleSubmit(
                            {
                              ...autoService,
                              active: e.target.checked,
                            },
                            "AutoService"
                          );
                        }}
                        color="primary"
                        name="checkedB"
                        defaultChecked={autoService.active}
                        style={{
                          color: autoService?.active
                            ? "green"
                            : color.themeOrange,
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                  </Grid>
                )}
                {disabilityInsurance && (
                  <Grid
                    container
                    spacing={3}
                    wrap="wrap"
                    xs={12}
                    sm={12}
                    md={12}
                    className="w-auto m-auto"
                  >
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <Typography
                        variant="p"
                        component="p"
                        className="mb-0"
                        style={{ color: "#060d5c", fontWeight: "normal" }}
                      >
                        {"Disability Insurance"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setDisabilityInsurance({
                              ...disabilityInsurance,
                              timing: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            timing.filter(
                              (s) => s === disabilityInsurance.timing
                            )[0]
                          }
                        >
                          {timing?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setDisabilityInsurance({
                              ...disabilityInsurance,
                              frequency: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            frequancy.filter(
                              (s) => s === disabilityInsurance.frequency
                            )[0]
                          }
                        >
                          {frequancy?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      style={{ border: "1px solid #000" }}
                    >
                      <Switch
                        defaultChecked={disabilityInsurance.active}
                        onChange={(e) => {
                          setDisabilityInsurance({
                            ...disabilityInsurance,
                            active: e.target.checked,
                          });
                          handleSubmit(
                            {
                              ...disabilityInsurance,
                              active: e.target.checked,
                            },
                            "DisabilityInsurance"
                          );
                        }}
                        color="primary"
                        name="checkedB"
                        style={{
                          color: disabilityInsurance?.active
                            ? "green"
                            : color.themeOrange,
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                  </Grid>
                )}
                {driverLicenses && (
                  <Grid
                    container
                    spacing={3}
                    wrap="wrap"
                    xs={12}
                    sm={12}
                    md={12}
                    className="w-auto m-auto"
                  >
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <Typography
                        variant="p"
                        component="p"
                        className="mb-0"
                        style={{ color: "#060d5c", fontWeight: "normal" }}
                      >
                        {"Driver Licenses"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setDriverLicense({
                              ...driverLicenses,
                              timing: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            timing.filter((s) => s === driverLicenses.timing)[0]
                          }
                        >
                          {timing?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setDriverLicense({
                              ...driverLicenses,
                              frequency: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            frequancy.filter(
                              (s) => s === driverLicenses.frequency
                            )[0]
                          }
                        >
                          {frequancy?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      style={{ border: "1px solid #000" }}
                    >
                      <Switch
                        defaultChecked={driverLicenses.active}
                        onChange={(e) => {
                          setDriverLicense({
                            ...driverLicenses,
                            active: e.target.checked,
                          });
                          handleSubmit(
                            {
                              ...driverLicenses,
                              active: e.target.checked,
                            },
                            "DriverLicense"
                          );
                        }}
                        color="primary"
                        name="checkedB"
                        style={{
                          color: driverLicenses?.active
                            ? "green"
                            : color.themeOrange,
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                  </Grid>
                )}
                {expenses && (
                  <Grid
                    container
                    spacing={3}
                    wrap="wrap"
                    xs={12}
                    sm={12}
                    md={12}
                    className="w-auto m-auto"
                  >
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <Typography
                        variant="p"
                        component="p"
                        className="mb-0"
                        style={{ color: "#060d5c", fontWeight: "normal" }}
                      >
                        {"Expenses"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setExpenses({
                              ...expenses,
                              timing: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            timing.filter((s) => s === expenses.timing)[0]
                          }
                        >
                          {timing?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setExpenses({
                              ...expenses,
                              frequency: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            frequancy.filter((s) => s === expenses.frequency)[0]
                          }
                        >
                          {frequancy?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      style={{ border: "1px solid #000" }}
                    >
                      <Switch
                        defaultChecked={expenses.active}
                        onChange={(e) => {
                          setExpenses({
                            ...expenses,
                            active: e.target.checked,
                          });
                          handleSubmit(
                            {
                              ...expenses,
                              active: e.target.checked,
                            },
                            "Expense"
                          );
                        }}
                        color="primary"
                        name="checkedB"
                        style={{
                          color: expenses?.active ? "green" : color.themeOrange,
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                  </Grid>
                )}
                {healthInsurance && (
                  <Grid
                    container
                    spacing={3}
                    wrap="wrap"
                    xs={12}
                    sm={12}
                    md={12}
                    className="w-auto m-auto"
                  >
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <Typography
                        variant="p"
                        component="p"
                        className="mb-0"
                        style={{ color: "#060d5c", fontWeight: "normal" }}
                      >
                        {"Health Insurance"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setHealthInsurance({
                              ...healthInsurance,
                              timing: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            timing.filter((s) => s === healthInsurance.timing)[0]
                          }
                        >
                          {timing?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setHealthInsurance({
                              ...healthInsurance,
                              frequency: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            frequancy.filter(
                              (s) => s === healthInsurance.frequency
                            )[0]
                          }
                        >
                          {frequancy?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      style={{ border: "1px solid #000" }}
                    >
                      <Switch
                        defaultChecked={healthInsurance.active}
                        onChange={(e) => {
                          setHealthInsurance({
                            ...healthInsurance,
                            active: e.target.checked,
                          });
                          handleSubmit(
                            {
                              ...healthInsurance,
                              active: e.target.checked,
                            },
                            "HealthInsurance"
                          );
                        }}
                        color="primary"
                        name="checkedB"
                        style={{
                          color: healthInsurance?.active
                            ? "green"
                            : color.themeOrange,
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                  </Grid>
                )}
                {immunization && (
                  <Grid
                    container
                    spacing={3}
                    wrap="wrap"
                    xs={12}
                    sm={12}
                    md={12}
                    className="w-auto m-auto"
                  >
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <Typography
                        variant="p"
                        component="p"
                        className="mb-0"
                        style={{ color: "#060d5c", fontWeight: "normal" }}
                      >
                        {"Immunizations"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setImmunization({
                              ...immunization,
                              timing: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            timing.filter((s) => s === immunization.timing)[0]
                          }
                        >
                          {timing?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setImmunization({
                              ...immunization,
                              frequency: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            frequancy.filter(
                              (s) => s === immunization.frequency
                            )[0]
                          }
                        >
                          {frequancy?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      style={{ border: "1px solid #000" }}
                    >
                      <Switch
                        defaultChecked={immunization.active}
                        onChange={(e) => {
                          setImmunization({
                            ...immunization,
                            active: e.target.checked,
                          });
                          handleSubmit(
                            {
                              ...immunization,
                              active: e.target.checked,
                            },
                            "Immunization"
                          );
                        }}
                        color="primary"
                        name="checkedB"
                        style={{
                          color: immunization?.active
                            ? "green"
                            : color.themeOrange,
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                  </Grid>
                )}
                {lifeInsurance && (
                  <Grid
                    container
                    spacing={3}
                    wrap="wrap"
                    xs={12}
                    sm={12}
                    md={12}
                    className="w-auto m-auto"
                  >
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <Typography
                        variant="p"
                        component="p"
                        className="mb-0"
                        style={{ color: "#060d5c", fontWeight: "normal" }}
                      >
                        {"Life Insurance"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setLifeInsurance({
                              ...lifeInsurance,
                              timing: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            timing.filter((s) => s === lifeInsurance.timing)[0]
                          }
                        >
                          {timing?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setLifeInsurance({
                              ...lifeInsurance,
                              frequency: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            frequancy.filter(
                              (s) => s === lifeInsurance.frequency
                            )[0]
                          }
                        >
                          {frequancy?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      style={{ border: "1px solid #000" }}
                    >
                      <Switch
                        onChange={(e) => {
                          setLifeInsurance({
                            ...lifeInsurance,
                            active: e.target.checked,
                          });
                          handleSubmit(
                            {
                              ...lifeInsurance,
                              active: e.target.checked,
                            },
                            "LifeInsurance"
                          );
                        }}
                        color="primary"
                        defaultChecked={lifeInsurance.active}
                        name="checkedB"
                        style={{
                          color: lifeInsurance?.active
                            ? "green"
                            : color.themeOrange,
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                  </Grid>
                )}
                {medication && (
                  <Grid
                    container
                    spacing={3}
                    wrap="wrap"
                    xs={12}
                    sm={12}
                    md={12}
                    className="w-auto m-auto"
                  >
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <Typography
                        variant="p"
                        component="p"
                        className="mb-0"
                        style={{ color: "#060d5c", fontWeight: "normal" }}
                      >
                        {"Medications"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setMedication({
                              ...medication,
                              timing: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            timing.filter((s) => s === medication.timing)[0]
                          }
                        >
                          {timing?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setMedication({
                              ...medication,
                              frequency: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            frequancy.filter((s) => s === medication.frequency)[0]
                          }
                        >
                          {frequancy?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      style={{ border: "1px solid #000" }}
                    >
                      <Switch
                        defaultChecked={medication.active}
                        onChange={(e) => {
                          setMedication({
                            ...medication,
                            active: e.target.checked,
                          });
                          handleSubmit(
                            {
                              ...medication,
                              active: e.target.checked,
                            },
                            "Medication"
                          );
                        }}
                        color="primary"
                        name="checkedB"
                        style={{
                          color: medication?.active ? "green" : color.themeOrange,
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                  </Grid>
                )}
                {otherInsurance && (
                  <Grid
                    container
                    spacing={3}
                    wrap="wrap"
                    xs={12}
                    sm={12}
                    md={12}
                    className="w-auto m-auto"
                  >
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <Typography
                        variant="p"
                        component="p"
                        className="mb-0"
                        style={{ color: "#060d5c", fontWeight: "normal" }}
                      >
                        {"Other Insurance"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setOtherInsurance({
                              ...otherInsurance,
                              timing: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            timing.filter((s) => s === otherInsurance.timing)[0]
                          }
                        >
                          {timing?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setOtherInsurance({
                              ...otherInsurance,
                              frequency: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            frequancy.filter(
                              (s) => s === otherInsurance.frequency
                            )[0]
                          }
                        >
                          {frequancy?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      style={{ border: "1px solid #000" }}
                    >
                      <Switch
                        defaultChecked={otherInsurance.active}
                        onChange={(e) => {
                          setOtherInsurance({
                            ...otherInsurance,
                            active: e.target.checked,
                          });
                          handleSubmit(
                            {
                              ...otherInsurance,
                              active: e.target.checked,
                            },
                            "OtherInsurance"
                          );
                        }}
                        color="primary"
                        name="checkedB"
                        style={{
                          color: otherInsurance?.active
                            ? "green"
                            : color.themeOrange,
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                  </Grid>
                )}
                {passport && (
                  <Grid
                    container
                    spacing={3}
                    wrap="wrap"
                    xs={12}
                    sm={12}
                    md={12}
                    className="w-auto m-auto"
                  >
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <Typography
                        variant="p"
                        component="p"
                        className="mb-0"
                        style={{ color: "#060d5c", fontWeight: "normal" }}
                      >
                        {"Passports"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setPassport({
                              ...passport,
                              timing: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            timing.filter((s) => s === passport.timing)[0]
                          }
                        >
                          {timing?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setPassport({
                              ...passport,
                              frequency: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            frequancy.filter((s) => s === passport.frequency)[0]
                          }
                        >
                          {frequancy?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      style={{ border: "1px solid #000" }}
                    >
                      <Switch
                        defaultChecked={passport.active}
                        onChange={(e) => {
                          setPassport({
                            ...passport,
                            active: e.target.checked,
                          });
                          handleSubmit(
                            {
                              ...passport,
                              active: e.target.checked,
                            },
                            "Passport"
                          );
                        }}
                        color="primary"
                        name="checkedB"
                        style={{
                          color: passport?.active ? "green" : color.themeOrange,
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                  </Grid>
                )}
                {propertyInsurance && (
                  <Grid
                    container
                    spacing={3}
                    wrap="wrap"
                    xs={12}
                    sm={12}
                    md={12}
                    className="w-auto m-auto"
                  >
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <Typography
                        variant="p"
                        component="p"
                        className="mb-0"
                        style={{ color: "#060d5c", fontWeight: "normal" }}
                      >
                        {"Property Insurance"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setPropertInsurance({
                              ...propertyInsurance,
                              timing: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            timing.filter(
                              (s) => s === propertyInsurance.timing
                            )[0]
                          }
                        >
                          {timing?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setPropertInsurance({
                              ...propertyInsurance,
                              frequency: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            frequancy.filter(
                              (s) => s === propertyInsurance.frequency
                            )[0]
                          }
                        >
                          {frequancy?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      style={{ border: "1px solid #000" }}
                    >
                      <Switch
                        defaultChecked={propertyInsurance.active}
                        onChange={(e) => {
                          setPropertInsurance({
                            ...propertyInsurance,
                            active: e.target.checked,
                          });
                          handleSubmit(
                            {
                              ...propertyInsurance,
                              active: e.target.checked,
                            },
                            "PropertyInsurance"
                          );
                        }}
                        color="primary"
                        name="checkedB"
                        style={{
                          color: propertyInsurance?.active
                            ? "green"
                            : color.themeOrange,
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                  </Grid>
                )}
                {serialNumber && (
                  <Grid
                    container
                    spacing={3}
                    wrap="wrap"
                    xs={12}
                    sm={12}
                    md={12}
                    className="w-auto m-auto"
                  >
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <Typography
                        variant="p"
                        component="p"
                        className="mb-0"
                        style={{ color: "#060d5c", fontWeight: "normal" }}
                      >
                        {"Serial Numbers"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setSerialNumber({
                              ...serialNumber,
                              timing: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            timing.filter((s) => s === serialNumber.timing)[0]
                          }
                        >
                          {timing?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setSerialNumber({
                              ...serialNumber,
                              frequency: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            frequancy.filter(
                              (s) => s === serialNumber.frequency
                            )[0]
                          }
                        >
                          {frequancy?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      style={{ border: "1px solid #000" }}
                    >
                      <Switch
                        defaultChecked={serialNumber.active}
                        onChange={(e) => {
                          setSerialNumber({
                            ...serialNumber,
                            active: e.target.checked,
                          });
                          handleSubmit(
                            {
                              ...serialNumber,
                              active: e.target.checked,
                            },
                            "SerialNumber"
                          );
                        }}
                        color="primary"
                        name="checkedB"
                        style={{
                          color: serialNumber?.active
                            ? "green"
                            : color.themeOrange,
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                  </Grid>
                )}
                {visa && (
                  <Grid
                    container
                    spacing={3}
                    wrap="wrap"
                    xs={12}
                    sm={12}
                    md={12}
                    className="w-auto m-auto"
                  >
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <Typography
                        variant="p"
                        component="p"
                        className="mb-0"
                        style={{ color: "#060d5c", fontWeight: "normal" }}
                      >
                        {"Visas"}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setVisa({
                              ...visa,
                              timing: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            timing.filter((s) => s === visa.timing)[0]
                          }
                        >
                          {timing?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sm={3}
                      md={3}
                      style={{ border: "1px solid #000" }}
                    >
                      <FormControl id="security-question" fullWidth={true}>
                        <Select
                          id="state"
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          required={true}
                          displayEmpty
                          onChange={(e) =>
                            setVisa({
                              ...visa,
                              frequency: e.target.value,
                            })
                          }
                          inputProps={{ "aria-label": "Without label" }}
                          MenuProps={MenuProps}
                          defaultValue={
                            frequancy.filter((s) => s === visa.frequency)[0]
                          }
                        >
                          {frequancy?.map((state, index) => {
                            return (
                              <MenuItem
                                value={state}
                                key={index}
                                style={getStyles(name, personName, theme)}
                              >
                                {state}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      style={{ border: "1px solid #000" }}
                    >
                      <Switch
                        defaultChecked={visa.active}
                        onChange={(e) => {
                          setVisa({
                            ...visa,
                            active: e.target.checked,
                          });
                          handleSubmit(
                            {
                              ...visa,
                              active: e.target.checked,
                            },
                            "Visa"
                          );
                        }}
                        color="primary"
                        name="checkedB"
                        style={{
                          color: visa?.active ? "green" : color.themeOrange,
                        }}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
}

export default AlertPrefrence;
