import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Grid } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { CustomButton } from "../../CustomButton/Button";
import SuccessGreen from "../../../containers/Modals/Alerts/SuccessGreen/SuccessGreen";
import Error from "../../../containers/Modals/Alerts/Error/Error";
import CircularProgress from "@material-ui/core/CircularProgress";
import config from "../../../config/default";
import styles from "../../../assets/jss/material-dashboard-react/components/ActivityHistoryAccordion.js";
import { PersonalService } from "../../../services/personal.service";
import EditPermissions from "../../../containers/Modals/ShareModal/EditPermissions";
import FileViewer from "../../FileViewer/FileViewer";
import { connect } from "react-redux";
import downloadDoc from "../../../Utils/downloadDocument.js";
import axios from "axios";

const useStyles = makeStyles(styles);

const personalService = new PersonalService();

function ShareHistory(props) {
  const [viewOpen, setViewOpen] = useState(false);
  const [openEditDocument, setOpenEditDocument] = React.useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [activitiesPerPage, setActivitiesPerPage] = useState(5);
  const [currDoc, setCurrDoc] = useState(null);
  const [open, setOpen] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [loading, setLoading] = useState(false);
  const [render, setRender] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [docs, setDocs] = useState([]);
  const [err, setErr] = useState(null);
  useEffect(() => {
    if (openShare === false) {
      setLoading(true);
      personalService
        .shareHistory()
        .then((res) => {
          setDocs(res.data.shared_documents ? res.data.shared_documents : []);
          setLoading(false);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setErr(
            err.response ? err.response.data.error : "something went wrong"
          );
        });
    }
  }, [render, openShare]);

  const indexOfLastActivity = currentPage * activitiesPerPage;
  const indexOfFirstActivity = indexOfLastActivity - activitiesPerPage;
  const currentDocs = docs.slice(indexOfFirstActivity, indexOfLastActivity);

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  // const handleChange = (key) => {
  //   setExpanded(key);
  // };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleOpenEditDocument = (allergy) => {
    setOpenEditDocument(true);
  };

  const download = (link) => {
    personalService
      .downloadDoc(link)
      .then((res) => {})
      .catch((err) => console.log({ resError: err }));
  };

  const handleCloseEditDocument = () => {
    setOpenEditDocument(false);
  };

  return (
    <>
      <Grid container className={classes.marginBottom18px}>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h6"
            component="h3"
            style={{ color: "#060d5c", marginBottom: "18px" }}
          >
            {"Share's History"}
          </Typography>
        </Grid>
        {openShare && (
          <EditPermissions
            open={openShare}
            docs={docs}
            id={currDoc?.share_id}
            receivers_email={currDoc?.receivers_email}
            subject={currDoc?.subject}
            personal_message={currDoc?.personal_message}
            expiration_date={currDoc?.expiration_date}
            require_authentication={currDoc?.require_authentication}
            allow_download={currDoc?.allow_download}
            terms={currDoc?.terms}
            setDoc={setDocs}
            notify_when_recipient_interacts={
              currDoc?.notify_when_recipient_interacts
            }
            notify_recipient_when_permissions_change={
              currDoc.notify_recipient_when_permissions_change
            }
            category={currDoc?.category}
            handleClose={() => {
              setOpenShare(false);
              setRender(!render);
            }}
          />
        )}
      </Grid>

      {!loading && docs?.length > 0 ? (
        <>
          <div className={classes.root}>
            {currentDocs.length > 0 &&
              currentDocs
                .sort((a, b) => {
                  if (props?.sortType === "alpha") {
                    if (props?.order === "asc") {
                      return a.title?.localeCompare(b.title);
                    } else {
                      return b.title?.localeCompare(a.title);
                    }
                  } else {
                    if (props?.order === "asc") {
                      return a.created_at?.localeCompare(b.created_at);
                    } else {
                      return b.created_at?.localeCompare(a.created_at);
                    }
                  }
                })
                .map((doc, key) => {
                  return (
                    <Accordion
                      key={key}
                      expanded={expanded === key}
                      onChange={handleChange(key)}
                      onClick={() => setCurrDoc(doc)}
                      className={classes.accordionMainBar}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        className={classes.accordionQuestion}
                      >
                        <div>
                          <Typography
                            component="h3"
                            variant="h3"
                            style={{
                              fontWeight: "bold",
                              wordBreak: "break-word",
                            }}
                            className={classes.heading}
                          >
                            <span style={{ color: "#ffb722" }}>
                              {doc.title ? doc.title : "-"}
                            </span>
                          </Typography>
                          <Typography
                            component="h3"
                            variant="h3"
                            style={{ fontWeight: "bold" }}
                            className={classes.heading}
                          >
                            {doc?.subject}
                          </Typography>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accordionAnswer}>
                        <Grid xs={12}>
                          <div
                            className="d-flex flex-row justify-content-end mb-5"
                            style={{ marginBottom: "10px" }}
                          ></div>
                          <Grid container className={classes.marginBottom18px}>
                            <Grid item xs={4} md={4}>
                              <p className="fw-bold">
                                <span style={{ color: "#ffb722" }}>
                                  Shared by
                                </span>
                              </p>
                            </Grid>
                            <Grid item xs={8} md={8}>
                              <Typography variant="p" component="p">
                                {doc?.shared_by_email}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container className={classes.marginBottom18px}>
                            <Grid item xs={4} md={4}>
                              <p className="fw-bold">
                                <span style={{ color: "#ffb722" }}>
                                  Receiver
                                </span>
                              </p>
                            </Grid>
                            <Grid item xs={8} md={8}>
                              <Typography variant="p" component="p">
                                {doc?.receivers_email}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container className={classes.marginBottom18px}>
                            <Grid item xs={4} md={4}>
                              <p className="fw-bold">
                                <span style={{ color: "#ffb722" }}>
                                  Personal Message
                                </span>
                              </p>
                            </Grid>
                            <Grid item xs={8} md={8}>
                              <Typography variant="p" component="p">
                                {doc?.personal_message}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container className={classes.marginBottom18px}>
                            <Grid item xs={4} md={4}>
                              <p className="fw-bold">
                                <span style={{ color: "#ffb722" }}>
                                  Shared on
                                </span>
                              </p>
                            </Grid>
                            <Grid item xs={8} md={8}>
                              <Typography variant="p" component="p">
                                {doc?.created_at}
                              </Typography>
                            </Grid>
                          </Grid>
                          {doc.expiration_date && (
                            <Grid
                              container
                              className={classes.marginBottom18px}
                            >
                              <Grid item xs={4} md={4}>
                                <p className="fw-bold">
                                  <span style={{ color: "#ffb722" }}>
                                    Expires on
                                  </span>
                                </p>
                              </Grid>
                              <Grid item xs={8} md={8}>
                                <Typography variant="p" component="p">
                                  {doc?.expiration_date}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          {doc?.viewed_at && (
                            <Grid
                              container
                              className={classes.marginBottom18px}
                            >
                              <Grid item xs={4} md={4}>
                                <p className="fw-bold">
                                  <span style={{ color: "#ffb722" }}>
                                    Viewed On
                                  </span>
                                </p>
                              </Grid>
                              <Grid item xs={8} md={8}>
                                <Typography variant="p" component="p">
                                  {new Date(doc?.viewed_at)
                                    ?.toISOString()
                                    ?.slice(0, 10)
                                    ?.split("-")[1] +
                                    "-" +
                                    new Date(doc?.viewed_at)
                                      ?.toISOString()
                                      ?.slice(0, 10)
                                      ?.split("-")[2] +
                                    "-" +
                                    new Date(doc?.viewed_at)
                                      ?.toISOString()
                                      ?.slice(0, 10)
                                      ?.split("-")[0]}
                                </Typography>
                              </Grid>
                              <Grid item xs={4} md={4}>
                                <p className="fw-bold">
                                  <span style={{ color: "#ffb722" }}>
                                    Viewed At
                                  </span>
                                </p>
                              </Grid>
                              <Grid item xs={8} md={8}>
                                <Typography variant="p" component="p">
                                  {new Date(
                                    doc?.viewed_at
                                  )?.toLocaleTimeString()}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                          <Grid container className={classes.marginBottom18px}>
                            <Grid item xs={4} md={4}>
                              <p className="fw-bold">
                                <span style={{ color: "#ffb722" }}>
                                  Notify on permission change
                                </span>
                              </p>
                            </Grid>
                            <Grid item xs={8} md={8}>
                              <Typography variant="p" component="p">
                                {doc.notify_recipient_when_permissions_change
                                  ? "Yes"
                                  : "No"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container className={classes.marginBottom18px}>
                            <Grid item xs={4} md={4}>
                              <p className="fw-bold">
                                <span style={{ color: "#ffb722" }}>
                                  Notify when recipient interacts
                                </span>
                              </p>
                            </Grid>
                            <Grid item xs={8} md={8}>
                              <Typography variant="p" component="p">
                                {doc.notify_when_recipient_interacts
                                  ? "Yes"
                                  : "No"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container className={classes.marginBottom18px}>
                            <Grid item xs={4} md={4}>
                              <p className="fw-bold">
                                <span style={{ color: "#ffb722" }}>
                                  Subject
                                </span>
                              </p>
                            </Grid>
                            <Grid item xs={8} md={8}>
                              <Typography variant="p" component="p">
                                {doc.subject ? doc.subject : "-"}
                              </Typography>
                            </Grid>
                            {viewOpen && (
                              <>
                                {expanded === key && (
                                  <FileViewer
                                    contentType={doc?.content_type}
                                    key={key}
                                    link={
                                      config.BASE_URL +
                                      doc.attachment +
                                      "#toolbar=0"
                                    }
                                    open={viewOpen}
                                    handleClose={() => setViewOpen(false)}
                                  />
                                )}
                              </>
                            )}
                          </Grid>

                          {!doc.attachment && (
                            <Grid
                              container
                              className={classes.marginBottom18px}
                              style={{ marginTop: "15px" }}
                            >
                              <Grid item xs={12} md={12}>
                                <Typography
                                  variant="h6"
                                  component="h6"
                                  color={"error"}
                                >
                                  Originating document or information has been
                                  deleted.
                                </Typography>
                              </Grid>
                            </Grid>
                          )}

                          {doc.attachment && (
                            <Grid
                              container
                              className={classes.marginBottom18px}
                              style={{ marginTop: "15px" }}
                            >
                              <CustomButton
                                btnText={"View"}
                                textAlign="center"
                                display={"block"}
                                background="transparent"
                                hoverBg="#0042a8"
                                border="1px solid #D5502E"
                                borderRadius="8px"
                                color="#D5502E"
                                onClick={() => {
                                  setViewOpen(true);
                                }}
                              />
                              {doc.allow_download && (
                                <CustomButton
                                  btnText={"Download"}
                                  textAlign="center"
                                  marginLeft="6px"
                                  display={"block"}
                                  background="transparent"
                                  hoverBg="#0042a8"
                                  border="1px solid #D5502E"
                                  borderRadius="8px"
                                  color="#D5502E"
                                  onClick={async () => {
                                    // downloadDoc(doc.uri, doc.title)
                                    const response = await fetch(
                                      config.BASE_URL + doc.attachment
                                    );
                                    const blob = await response.blob();
                                    const url =
                                      window.URL.createObjectURL(blob);
                                    const a = document.createElement("a");
                                    a.href = url;
                                    a.download = doc.title; // You can set the file name here
                                    document.body.appendChild(a);
                                    a.click();
                                    a.remove();
                                    window.URL.revokeObjectURL(url);
                                    // window.open(
                                    //   config.BASE_URL + doc.attachment,
                                    //   "_blank"
                                    // );
                                  }}
                                />
                              )}
                              <CustomButton
                                btnText={"Edit Permissions"}
                                textAlign="center"
                                marginLeft="6px"
                                display={"block"}
                                background="transparent"
                                hoverBg="#0042a8"
                                border="1px solid #D5502E"
                                borderRadius="8px"
                                color="#D5502E"
                                onClick={() => {
                                  setOpenShare(true);
                                }}
                              />
                              <CustomButton
                                btnText={"End Share"}
                                textAlign="center"
                                marginLeft="6px"
                                display={"block"}
                                background="transparent"
                                hoverBg="#0042a8"
                                border="1px solid #D5502E"
                                borderRadius="8px"
                                color="#D5502E"
                                onClick={() => {
                                  personalService
                                    .endShare(doc?.share_id)
                                    .then(() => {
                                      setRender(!render);
                                    })
                                    .catch(() => {});
                                }}
                              />
                            </Grid>
                          )}
                          <Grid
                            container
                            className={classes.marginBottom18px}
                          ></Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
          </div>
          {open && (
            <SuccessGreen
              open={open}
              handleClose={() => setOpen(false)}
              message="Document has successfully been deleted"
            />
          )}
          {openError && (
            <Error
              open={open}
              handleClose={() => setOpenError(false)}
              message={"Could not delete the document"}
            />
          )}
          <Pagination
            showLastButton
            showFirstButton
            page={currentPage}
            onChange={handleChangePage}
            count={Math.ceil(docs.length / activitiesPerPage)}
            color="secondary"
          />
        </>
      ) : err ? (
        <p>{err}</p>
      ) : !loading && docs.length === 0 ? (
        <p>No shares yet</p>
      ) : (
        <CircularProgress size={20} color="#DDD" />
      )}
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    sortType: state?.sort?.sort,
    order: state?.sort?.order,
  };
};

export default connect(mapStateToProps)(ShareHistory);
