import "date-fns";
import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import debounce from "lodash.debounce";
import { Box, Button, Grid, Slider, Typography } from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import { PersonalService } from "../../../services/personal.service.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import AddDocument from "../AddDocument/AddDocument";
import AvatarEditor from "react-avatar-editor";

const stateArray = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];

const visaArray = [
  "Visitor",
  "Exchange Visitor",
  "Temporary Work",
  "Student",
  "Diplomatic",
  "Relative",
  "Fiance",
  "Transit",
  "Crewmember",
  "Employer Sponsored",
  "Treaty Investor",
  "Treaty Trader",
];

const useStyles = makeStyles(styles);

function AddWebLogin(props) {
  const classes = useStyles();
  var editor = "";
  const errRef = useRef(null);
  const iconRef = useRef(null);
  const personalService = new PersonalService();
  const [err, setErr] = React.useState(null);
  const [icon, setIcon] = React.useState(null);
  const [doc, setDoc] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [maskedPassword, setMaskedPassword] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [picture, setPicture] = React.useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImg: null,
  });
  const [data, setData] = React.useState({
    description: "",
    password: "",
    site_url: "",
    notes: "",
    user_name: "",
    attachments: "",
    image: null,
    associated_documents_attributes: [],
  });

  const [documentModal, setDocumentModal] = React.useState(false);

  const handleCancel = () => {
    setPicture({
      ...picture,
      cropperOpen: false,
    });
  };

  const setEditorRef = (ed) => {
    editor = ed;
  };

  const handleSlider = (event, value) => {
    setPicture({
      ...picture,
      zoom: value,
    });
  };

  const maskPassword = (password) => {
    return "*".repeat(password.length);
  };
  const handleSave = (e) => {
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();

      setPicture({
        ...picture,
        img: null,
        cropperOpen: false,
        croppedImg: croppedImg,
      });
      setData({ ...data, image: croppedImg });
    }
  };

  const handleDocumentModal = () => {
    setDocumentModal(true);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let formData = new FormData();
    formData.append("description", data.description);
    formData.append("password", data.password);
    formData.append("site_url", data.site_url);
    formData.append("notes", data.notes);
    formData.append("user_name", data.user_name);
    data.image && formData.append("image", data.image);

    if (data.associated_documents_attributes?.length > 0) {
      for (let i = 0; i < data.associated_documents_attributes?.length; i++) {
        formData.append(
          "associated_documents_attributes[][document_id]",
          data.associated_documents_attributes[i]?.document_id ||
            data.associated_documents_attributes[i]?.id
        );
      }
    }
    setIsLoading(true);
    personalService
      .createWebLogins(formData)
      .then((response) => {
        setIsLoading(false);
        props.forceRerender();
        props.handleClose();

        console.log(response.data);
      })
      .catch((err) => {
        errRef.current.scrollIntoView();
        setIsLoading(false);
        setErr("Error occurred while creating Web Login");
        if (err.response) {
          console.log(err.response);
        } else {
          console.log("500");
        }
      });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = debounce((event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  }, 300);

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Add New Web Login"}
          color={color.white}
        />
        <div className={classes.modalBody} ref={errRef}>
          <div className="w-100 d-flex flex-row mb-4">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Personal > Web Login > New"}
            </Typography>
          </div>
          {err && (
            <div className="w-100 d-flex flex-row mb-4">
              <Typography
                variant="p"
                component="p"
                align="left"
                style={{ color: "red", fontWeight: "normal" }}
              >
                {err}
              </Typography>
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Description *"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormInput
                id="visa_type"
                name="description"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Description"}
              />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Icon"}
                {!icon && (
                  <CustomButton
                    onClick={() => {
                      iconRef.current.click();
                    }}
                    btnText={"choose icon"}
                    textAlign="center"
                    display={"block"}
                    background="transparent"
                    hoverBg="#0042a8"
                    border="1px solid #0042a8"
                    borderRadius="8px"
                    color="#0042a8"
                    style={{
                      marginLeft: "1rem",
                    }}
                  />
                )}
                {icon && (
                  <img
                    data-toggle="tooltip"
                    data-placement="top"
                    title="click to change icon"
                    style={{
                      marginLeft: "2rem",
                      border: "1px solid transparent",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                    height={"60"}
                    onClick={() => iconRef.current.click()}
                    width={"60"}
                    src={picture.croppedImg ?? icon}
                  />
                )}
                {picture.cropperOpen && (
                  <Box display="block">
                    <AvatarEditor
                      ref={setEditorRef}
                      image={icon}
                      width={200}
                      height={200}
                      border={50}
                      color={[255, 255, 255, 0.6]} // RGBA
                      rotate={0}
                      scale={picture.zoom}
                    />
                    <Slider
                      aria-label="raceSlider"
                      value={picture.zoom}
                      min={1}
                      max={10}
                      step={0.1}
                      onChange={handleSlider}
                    ></Slider>
                    <Box>
                      <Button variant="contained" onClick={handleCancel}>
                        Cancel
                      </Button>
                      <Button onClick={handleSave}>Save</Button>
                    </Box>
                  </Box>
                )}
                <input
                  ref={iconRef}
                  style={{ display: "none" }}
                  onChange={(e) => {
                    setData({ ...data, image: e.target.files[0] });
                    setIcon(URL.createObjectURL(e.target.files[0]));
                    setPicture({ ...picture, cropperOpen: true });
                  }}
                  type={"file"}
                />
              </Typography>
            </Grid> */}
            <Grid item xs={12} md={12} sm={12}></Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Username / Email"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormInput
                onChange={(event) =>
                  setData({ ...data, user_name: event.target.value })
                }
                label=""
                variant="outlined"
                autoComplete={"new-password"}
                inputProps={{
                  autoComplete: "new-password",
                }}
                fullWidth={true}
                type="text"
                name="213dd"
                margin="dense"
                required={true}
                placeholder={"Username / Email"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Password"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="txtPassword"
                onChange={(event) => {
                  console.log({ password: event.target.value });
                  setData({ ...data, password: event.target.value });
                  setMaskedPassword(maskPassword(event.target.value));
                }}
                label=""
                variant="outlined"
                fullWidth={true}
                name="323ff"
                // hard={maskedPassword}
                margin="dense"
                required={true}
                placeholder={"Password"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Website URL"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="site"
                name="site_url"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Website URL"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Notes"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="class"
                name="notes"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Notes"}
              />
              {documentModal && (
                <AddDocument
                  setData={setData}
                  setDoc={setDoc}
                  associated_documents_attributes={false}
                  doc={doc}
                  data={data}
                  open={documentModal}
                  handleClose={() => setDocumentModal(false)}
                  closeModal={() => setDocumentModal(false)}
                  category={"Personal"}
                />
              )}
            </Grid>
            {doc.length > 0 && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Document"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                  {doc.map((e, k) => (
                    <>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                        key={k}
                      >
                        {e?.title}
                      </Typography>
                      <CustomButton
                        btnText={"Remove"}
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          let newDoc = doc?.filter((d) => d.id !== e.id);
                          setDoc(newDoc);
                          setData({
                            ...data,
                            associated_documents_attributes: newDoc,
                          });
                        }}
                      />
                    </>
                  ))}
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              <CustomButton
                btnText="Add Document"
                className="mb-sm-2"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
                marginRight={"10px"}
                onClick={handleDocumentModal}
              />

              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  onClick={handleSubmit}
                  disabled={
                    data.description === "" || !data.description?.trim()
                      ? true
                      : false
                  }
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddWebLogin);
