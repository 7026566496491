import "date-fns";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import logo from "../../../assets/img/tag-logo.png";
import { DocumentService } from "../../../services/document.service";
import { store } from "../../../store/store";
import { cashFlowHtml } from "../../../ReportsHtml/cashFlow";

const useStyles = makeStyles(styles);

function ReportCashFlow(props) {
  const classes = useStyles();
  const [err, setErr] = React.useState(null);
  const [loadingDoc, setLoadingDoc] = React.useState(false);
  const [doc, setDoc] = React.useState({
    incomes: [],
    expenses: [],
    total_income: null,
    total_expense: null,
    cash_flow: null,
  });

  const docService = new DocumentService();
  React.useEffect(() => {
    docService
      .cashFlowIndex()
      .then((res) => {
        setDoc({
          ...doc,
          incomes: res?.data?.incomes ? res?.data?.incomes : [],
          expenses: res?.data?.expenses ? res?.data?.expenses : [],
          total_income: res?.data?.total_income,
          total_expense: res?.data?.total_expense,
          cash_flow: res?.data?.cash_flow,
        });
      })
      .catch((err) => {});
  }, []);

  const handleSubmit = () => {
    setLoadingDoc(true);
    const { html } = cashFlowHtml(doc);
    docService
      ?.cashFlowCreate()
      .then((res) => {
        console.log({ res });
        setLoadingDoc(false);
        props?.setAlert({
          type: "Success",
          open: true,
          btnText: "Okay",
          heading: "Success!",
          message:
            "Your Cash Flow report was stored securely in All Documents under type None",
        });
        props?.handleClose();
      })
      .catch((err) => {
        props?.setAlert({
          type: "Error",
          open: true,
          btnText: "RETRY",
          cancelText: "CANCEL",
          heading: "Something went wrong!",
          message:
            "We're sorry there was an error and your report could not be uploaded. Would you like to try again?",
        });
        setLoadingDoc(false);
        props?.handleClose();
      });
  };
  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="55%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Cash Flow"}
          color={color.white}
        />
        <div className="w-100 shadow-sm py-2 px-4 d-flex flex-row">
          <Typography
            variant="p"
            component="p"
            align="left"
            style={{ color: "#060d5c", fontWeight: "normal" }}
          >
            {"Reports > Cash Flow"}
          </Typography>
        </div>
        <div className={classes.modalBody + " pt-3"}>
          <div className="d-flex flex-sm-row flex-column align-items-center mb-4 pb-3 border-bottom">
            <img src={logo} height="56" className="me-sm-5 me-0" />
            <div className="d-flex flex-row">
              <div className="me-sm-5 me-0">
                <Typography
                  variant="p"
                  component="p"
                  align="left"
                  style={{ color: "#060d5c", fontWeight: "normal" }}
                >
                  {"Belongs To:"}
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  align="left"
                  style={{ color: "#060d5c", fontWeight: "bold" }}
                >
                  {store.getState().profile?.profileInfo?.profile
                    ?.profile_type === "person"
                    ? store.getState().profile?.profileInfo?.profile
                        ?.first_name +
                      " " +
                      store.getState().profile?.profileInfo?.profile?.last_name
                    : store.getState().profile?.profileInfo?.profile
                        ?.business_name}
                </Typography>
              </div>
            </div>
          </div>
          <Grid container wrap="wrap" direction="row" spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="h5"
                component="h3"
                style={{
                  color: color.themeYellow,
                  textDecoration: "underline",
                  textDecorationColor: "#0042A8",
                }}
              >
                {"Monthly Cash Flow Statement"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} className="mb-4">
              <Typography
                variant="h6"
                component="h3"
                className="mb-3"
                style={{ color: color.themeColorNew }}
              >
                {"Monthly Income"}
              </Typography>
              <Grid
                container
                wrap="wrap"
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
                className="mb-3 border-bottom border-2"
              >
                <Grid item xs={12} sm={6} md={6}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Income Description	"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Monthly Earnings"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                wrap="wrap"
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
                className="mb-3 border-bottom"
              >
                {doc?.incomes?.length > 0 &&
                  doc?.incomes?.map((i) => {
                    return (
                      <>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            style={{ color: color.themeColorNew }}
                          >
                            {i?.income_type}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            style={{ color: color.themeColorNew }}
                          >
                            {i?.monthly_earnings}
                          </Typography>
                        </Grid>
                      </>
                    );
                  })}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
                className="mb-3"
              >
                {"Monthly Expenses"}
              </Typography>
              <Grid
                container
                wrap="wrap"
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
                className="mb-3 border-bottom border-2"
              >
                <Grid item xs={12} sm={6} md={6}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Expense Type"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Amount"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                wrap="wrap"
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
                className="mb-3 border-bottom"
              >
                {doc?.expenses?.length > 0 &&
                  doc?.expenses?.map((i) => {
                    return (
                      <>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            style={{ color: color.themeColorNew }}
                          >
                            {i?.expense_type}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            style={{ color: color.themeColorNew }}
                          >
                            {i?.expense_amount}
                          </Typography>
                        </Grid>
                      </>
                    );
                  })}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} className="mb-4">
              <Typography
                variant="h6"
                component="h3"
                className="mb-3"
                style={{ color: color.themeColorNew }}
              >
                {"Cash Flow Summary"}
              </Typography>
              <Grid
                container
                wrap="wrap"
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
                className="mb-3 border-bottom border-2"
              >
                <Grid item xs={12} sm={6} md={6}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Incomes"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {doc?.total_income}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                wrap="wrap"
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
                className="mb-3 border-bottom"
              >
                <Grid item xs={12} sm={6} md={6}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Expenses"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {doc?.total_expense}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                wrap="wrap"
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
                className="mb-3 border-bottom"
              >
                <Grid item xs={12} sm={6} md={6}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Cash Flow	"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {doc?.cash_flow}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <small>
                The information in this document is confidential and may be
                legally privileged. Access to this document by anyone other than
                the intended addressee is unauthorized. If you are not the
                intended recipient of this message, any review, disclosure,
                copying, distribution, retention, or any action taken or omitted
                to be taken in reliance on it is prohibited and may be unlawful.
              </small>
            </Grid>
          </Grid>
        </div>
        <div
          className="bg-light text-sm-center p-2 bottomRadius"
          style={{ textAlign: "right" }}
        >
          <small className="me-sm-5 me-2">
            This is a preview of your{" "}
            <span style={{ color: color.themeYellow }}>
              <b>Cash Flow</b>
            </span>
          </small>
          {!loadingDoc ? (
            <CustomButton
              type="submit"
              btnText="Store Securely"
              textAlign="center"
              display={"block"}
              padding="8px 25px"
              background="#0042a8"
              hoverBg="#0042a8"
              border="0"
              borderRadius="8px"
              color="#fff"
              onClick={handleSubmit}
            />
          ) : (
            <CustomButton
              btnText={<CircularProgress size={20} color="#DDD" />}
              type="submit"
              textAlign="center"
              display={"block"}
              padding="8px 25px"
              background="#0042a8"
              hoverBg="#0042a8"
              border="0"
              borderRadius="8px"
              color="#fff"
            />
          )}
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportCashFlow);
