import * as types from "./types";

export function setLoading(bool) {
  return {
    type: types.LOADING,
    payload: bool,
  };
}

export function rerenderEmployer() {
  return {
    type: types.RERENDER_EMPLOYER,
  };
}

export function rerenderAward() {
  return {
    type: types.RERENDER_AWARD,
  };
}

export function isExpired(bool) {
  return {
    type: types.IS_EXPIRED,
    payload: bool
  };
}
