import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import styles from "../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import AlertSharesAndStorageInfo from "./AccountSettingTabsContent/AlertSharesAndStorageInfo.js";
import {Grid} from "@material-ui/core";
import AccountOptions from "./AccountSettingTabsContent/AccountOptions.js";
import ProfileTabContent from "./AccountSettingTabsContent/Profile.js";
import ActivityHistory from "./AccountSettingTabsContent/ActivityHistory.js";
import ShareHistory from "./AccountSettingTabsContent/ShareHistory.js";
import {connect} from "react-redux";
import {logout} from "../../store/actions/authAction";
import LinearProgress from "@material-ui/core/LinearProgress";
import {ProfileService} from "../../services/profile.service";
import Reports from "./AccountSettingTabsContent/Reports.js";
import {NavLink, Route, Switch, useHistory} from "react-router-dom";
import PaymentMethods from "./AccountSettingTabsContent/PaymentMethods";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    useEffect(() => {
    }, [props.profile]);

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(styles);

function SimpleTabs(props) {
    const history = useHistory();
    const profile = new ProfileService();
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleLogout = () => {
        props.logout(history);
    };

    const routes = ['/user/profile/account-options', '/user/profile/payment-methods', '/user/profile/activity-history',
        '/user/profile/share-history', '/user/profile/reports', '/user/profile/info'];

    return (
        <>
            <Route path={"/user/profile"} render={(h) => {
                return (
                    <AppBar position="static" color="transparent" elevation="0">
                        <Tabs
                            value={history.location.pathname}
                            aria-label="simple tabs example"
                            className={classes.tabsMain}
                        >
                            <NavLink to={routes[0]}>
                                <Tab value={routes[0]}

                                     style={{
                                         cursor: "pointer",
                                         color: window.location.pathname === routes[0] && "#fff",
                                         backgroundColor: window.location.pathname === routes[0] && "#0042a8"
                                     }} label="Account Options" {...a11yProps(0)} />
                            </NavLink>
                            <NavLink to={routes[1]}>
                                <Tab value={routes[1]}

                                     style={{
                                         cursor: "pointer",
                                         color: window.location.pathname === routes[1] && "#fff",
                                         backgroundColor: window.location.pathname === routes[1] && "#0042a8"
                                     }} label="Payment Methods" {...a11yProps(1)} />
                            </NavLink>
                            <NavLink to={routes[2]}>
                                <Tab value={routes[2]}

                                     style={{
                                         cursor: "pointer",
                                         color: window.location.pathname === routes[2] && "#fff",
                                         backgroundColor: window.location.pathname === routes[2] && "#0042a8"
                                     }} label="Activity History" {...a11yProps(2)} />
                            </NavLink>
                            <NavLink to={routes[3]}>
                                <Tab value={routes[3]}

                                     style={{
                                         cursor: "pointer",
                                         color: window.location.pathname === routes[3] && "#fff",
                                         backgroundColor: window.location.pathname === routes[3] && "#0042a8"
                                     }} label="Share History" {...a11yProps(3)} />
                            </NavLink>
                            <NavLink to={routes[4]}>
                                <Tab value={routes[4]}

                                     style={{
                                         cursor: "pointer",
                                         color: window.location.pathname === routes[4] && "#fff",
                                         backgroundColor: window.location.pathname === routes[4] && "#0042a8"
                                     }} label="Reports" {...a11yProps(4)} />
                            </NavLink>
                            <NavLink to={routes[5]}>
                                <Tab value={routes[5]}

                                     style={{
                                         cursor: "pointer",
                                         color: window.location.pathname === routes[5] && "#fff",
                                         backgroundColor: window.location.pathname === routes[5] && "#0042a8"
                                     }} label="Profile" {...a11yProps(5)} />
                            </NavLink>
                            <Tab label="Log Out" {...a11yProps(6)} onClick={handleLogout}/>
                        </Tabs>
                    </AppBar>
                )
            }}/>

            <Switch>
                <div className={classes.root}>
                    <Route path={routes[0]} render={(h) => {
                        return (
                            <TabPanel index={routes[0]} value={routes[0]}>
                                <Grid container wrap="wrap" direction="row" spacing={3}>
                                    <Grid item xs={12} sm={12} md={9} lg={6}>
                                        <AccountOptions/>
                                    </Grid>
                                    <Grid item xs={12} md={5} style={{height: "max-content"}}>
                                        <AlertSharesAndStorageInfo/>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        )
                    }}/>
                    <Route path={routes[1]} render={(h) => {
                        return (
                            <Grid container wrap="wrap" direction="row" spacing={3}>
                                <Grid item xs={12} sm={12} md={9} lg={6}>
                                    <PaymentMethods/>
                                </Grid>
                                <Grid item xs={12} md={5} style={{height: "max-content"}}></Grid>
                            </Grid>
                        )
                    }}/>
                    <Route path={routes[2]} render={(h) => {
                        return (
                            <Grid container wrap="wrap" direction="row" spacing={3}>
                                <Grid item xs={12} sm={12} md={9} lg={6}>
                                    <ActivityHistory/>
                                </Grid>
                                <Grid item xs={12} md={5} style={{height: "max-content"}}></Grid>
                            </Grid>
                        )
                    }}/>
                    <Route path={routes[3]} render={(h) => {
                        return (
                            <Grid container wrap="wrap" direction="row" spacing={3}>
                                <Grid item xs={12} sm={12} md={9} lg={6}>
                                    <ShareHistory/>
                                </Grid>
                                <Grid item xs={12} md={5} style={{height: "max-content"}}></Grid>
                            </Grid>
                        )
                    }}/>
                    <Route path={routes[4]} render={(h) => {
                        return (
                            <Grid container wrap="wrap" direction="row" spacing={3}>
                                <Grid item xs={12} sm={12} md={9} lg={6}>
                                    <Reports/>
                                </Grid>
                                <Grid item xs={12} md={5} style={{height: "max-content"}}></Grid>
                            </Grid>
                        )
                    }}/>
                    <Route path={routes[5]} render={(h) => {
                        return (
                            <Grid container wrap="wrap" direction="row" spacing={3}>
                                <Grid item xs={12} sm={12} md={9} lg={6}>
                                    <ProfileTabContent/>
                                </Grid>
                                <Grid item xs={12} md={5} style={{height: "max-content"}}></Grid>
                            </Grid>
                        )
                    }}/>
                    <TabPanel value={value} index={6}>
                        <Grid container wrap="wrap" direction="row" spacing={3}>
                            <Grid item xs={12} sm={12} md={9} lg={6}>
                                <LinearProgress color="secondary"/>
                            </Grid>
                            <Grid item xs={12} md={5} style={{height: "max-content"}}></Grid>
                        </Grid>
                    </TabPanel>
                </div>
            </Switch>
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: (history) => dispatch(logout(history)),
    };
};
const mapStateToProps = (state, ownProps) => {
    return {
        profile: state.profile.profileInfo,
        user: state.auth?.user
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SimpleTabs);
