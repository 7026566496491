import "date-fns";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Auth } from "../../../services/auth.service.js";

const useStyles = makeStyles(styles);

function DowngradeModal(props) {
  const [disable, setDisable] = React.useState(false);
  const history = useHistory();
  const classes = useStyles();
  const [alerts, setAlerts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const authService = new Auth();

  React.useEffect(() => {
    if (!props?.err?.includes("storage")) {
      setLoading(true);
      authService
        .fetchReminders()
        .then((res) => {
          setLoading(false);
          setAlerts(res.data.reminders);
        })
        .catch((err) => {});
    }
  }, []);

  const handleUpdate = () => {
    if (props?.err?.includes("reminder") && props?.err?.includes("storage")) {
      setDisable(true);
      const array = alerts.filter((a) => {
        if (a?.status === "inactive") {
          return {
            id: a?.id,
          };
        }
      });
      authService
        .updateReminders(array)
        .then(() => {
          props?.handleClose();
          history.push("/user/all-documents");
          setDisable(false);
        })
        .catch(() => {});
    } else if (
      props?.err?.includes("reminder") &&
      !props?.err?.includes("storage")
    ) {
      setDisable(true);
      const array = alerts.filter((a) => {
        if (a?.status === "inactive") {
          return {
            id: a?.id,
          };
        }
      });
      authService
        .updateReminders(array)
        .then(() => {
          props?.payload
            ? props?.updatePlan(props?.payload)
            : props?.updateSub();
          props?.handleClose();
        })
        .catch(() => {});
    } else {
      history.push("/user/all-documents");
    }
  };

  return (
    <>
    {
      console.log({downgradeProps: props})
    }
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%"
        margin="100px 0 40px"
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Active Reminders"}
          color={color.white}
        />
        <div className={classes.modalBody + ' ' + classes.modalBodyHeight }>
          <div className="w-100 d-flex flex-row mb-5">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "red", fontWeight: "normal" }}
            >
              {props?.err}
            </Typography>
          </div>

          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            {loading && <CircularProgress size={50} color="#DDD" />}
            {alerts &&
              alerts.length > 0 &&
              alerts.map((a) => {
                return (
                  <>
                    <Grid item xs={12} sm={12} md={12} className="pb-0">
                      <Typography
                        variant="h6"
                        component="h3"
                        style={{ color: color.themeColorNew }}
                      >
                        {a?.alertable_type}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} className="pt-0">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={a?.is_active}
                            onChange={() => {
                              setAlerts(
                                alerts.map((r) => {
                                  if (r?.id === a?.id) {
                                    return {
                                      ...r,
                                      status:
                                        r.status === "active"
                                          ? "inactive"
                                          : "active",
                                    };
                                  } else {
                                    return r;
                                  }
                                })
                              );
                            }}
                            name="checkedA"
                            style={{ color: "#ffb722" }}
                          />
                        }
                        label="Turn reminders on/off"
                      />
                    </Grid>
                  </>
                );
              })}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              <CustomButton
                btnText={"update"}
                type="submit"
                textAlign="center"
                disabled={disable}
                display={"block"}
                onClick={() => handleUpdate()}
                padding="8px 25px"
                marginRight="0px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
              />
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DowngradeModal);
