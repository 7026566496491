import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close, EditOutlined } from "@material-ui/icons";
// import AvatarGroup from '@mui/material/AvatarGroup';
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import jpegImage from "../../../assets/img/jpegImage.png";
import pdfImage from "../../../assets/img/pdfImage.png";
import { store } from "../../../store/store";
import { CustomButton } from "../../../components/CustomButton/Button";
import RevealPassword from "../RevealPassword/RevealPassword";
import { PersonalService } from "../../../services/personal.service.js";

const useStyles = makeStyles(styles);
export default function WebLoginInformation(props) {
  const classes = useStyles();
  const [openReveal, setOpenReveal] = useState(false);
  const personalService = new PersonalService();

  React.useEffect(() => {
    personalService
      .webLoginsShow(props?.webLogin[0]?.id)
      .then(() => {})
      .catch(() => {});
  }, []);

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%"
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          rightIcon={
            <EditOutlined
              onClick={() => props?.handleOpenEdit(props?.webLogin[0])}
              style={{
                color: color.white,
                marginLeft: "auto",
                fontSize: "36px",
                cursor: "pointer",
              }}
            />
          }
          heading={
            props?.webLogin[0]?.user_name
              ? props?.webLogin[0]?.user_name
              : "Web Login"
          }
          color={color.white}
        />
        <div className={classes.modalBody}>
          <div className="w-100 d-flex flex-row mb-5">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Personal > Web Login > Information"}
            </Typography>
          </div>
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Description"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.webLogin[0]?.description}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Username / Email"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.webLogin[0]?.user_name ? (
                  props?.webLogin[0]?.user_name
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <input style={{ position: "absolute", top: -200 }} />
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Password"}
              </Typography>
              {store.getState().auth.user.masking ? (
                <>
                  {props?.webLogin[0]?.password_ciphertext ||
                  props?.webLogin[0]?.password ? (
                    <CustomButton
                      onClick={() => {
                        setOpenReveal(true);
                        // props.handleClose()
                      }}
                      btnText={"Reveal"}
                      textAlign="center"
                      display={"block"}
                      background="transparent"
                      hoverBg="#0042a8"
                      border="1px solid #0042a8"
                      borderRadius="8px"
                      color="#0042a8"
                    />
                  ) : null}
                </>
              ) : null}
              {openReveal && (
                <RevealPassword
                  open={openReveal}
                  handleClose={() => setOpenReveal(false)}
                  subcategory_name={"WebLogin"}
                  password={
                    props?.webLogin[0]?.password_ciphertext ||
                    props?.webLogin[0]?.password
                  }
                  id={props?.webLogin[0]?.id}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.webLogin[0]?.password ||
                  props?.webLogin[0]?.password_ciphertext}
              </Typography>
              {/*{store.getState().auth.user.masking ? (*/}
              {/*  <>*/}
              {/*    <CustomButton*/}
              {/*      onClick={() => {*/}
              {/*        setOpenReveal(true);*/}
              {/*        // props.handleClose()*/}
              {/*      }}*/}
              {/*      btnText={"Reveal"}*/}
              {/*    />*/}
              {/*  </>*/}
              {/*) : null}*/}
              {/*{openReveal && (*/}
              {/*  <RevealPassword*/}
              {/*    open={openReveal}*/}
              {/*    handleClose={() => setOpenReveal(false)}*/}
              {/*    subcategory_name={"WebLogin"}*/}
              {/*    password={props?.webLogin[0]?.password}*/}
              {/*    id={props?.webLogin[0]?.id}*/}
              {/*  />*/}
              {/*)}*/}
            </Grid>
            {props?.webLogin[0]?.site_url && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Website URL"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <a
                    href={
                      props.webLogin[0].site_url.includes("http")
                        ? props.webLogin[0].site_url
                        : "https://" + props.webLogin[0].site_url
                    }
                    target="_blank"
                    style={{
                      fontSize: "18px",
                      cursor: "pointer",
                    }}
                  >
                    {props?.webLogin[0]?.site_url}
                  </a>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Notes"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.webLogin[0]?.notes ? (
                  props?.webLogin[0]?.notes
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
          </Grid>
          {props?.webLogin[0]?.documents && (
            <Grid
              container
              wrap="wrap"
              direction="row"
              spacing={3}
              className="mt-5"
            >
              <Grid item xs={12} sm={6} md={6}>
                <Typography
                  variant="h6"
                  component="h3"
                  style={{ color: color.themeColorNew }}
                >
                  {"Linked Items"}
                </Typography>
                <div className="d-flex flex-row align-items-center mt-2">
                  <img src={jpegImage} className="me-2" />
                  <img src={pdfImage} className="me-2" />
                  <Typography
                    onClick={props.handleOpenLinkedItems}
                    variant="h6"
                    component="h3"
                    align="right"
                    className="text-decoration-underline"
                    style={{ color: color.themeColorNew, cursor: "pointer" }}
                  >
                    {"See all"}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          )}
        </div>
      </CustomModal>
    </>
  );
}
