import * as actions from "../actions/types";

export const intialState = {
  profileInfo: null,
  profiles: [],
  loading: false,
  count: null,
  error: null,
};

export default function authReducer(state = intialState, action) {
  switch (action.type) {
    case actions.GET_PROFILES:
      return {
        ...state,
        profiles: action?.payload ? action?.payload : [],
      };
    case actions.LOADING_PROFILE:
      return {
        ...state,
        loading: action.payload,
      };
    case actions.GET_PROFILE_SPECIFIC:
      return {
        ...state,
        profileInfo: action.payload,
      };
    case actions.PROFILE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case actions.CLEAR_STATE:
      return {
        ...state,
        profileInfo: null,
        profiles: [],
        loading: false,
        count: null,
      };
    default:
      return state;
  }
}
