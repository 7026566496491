import "date-fns";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import { connect } from "react-redux";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles(styles);
const type = ["Personal", "Work"];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function AddEmailAddress(props) {
  const classes = useStyles();
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = () => {
    if (props?.email) {
      let temp = props?.emails?.indexOf(props?.email);
      if (temp >= 0) {
        let array = props?.emails.map((val, index) => {
          if (index === temp) {
            return data1;
          } else {
            return val;
          }
        });

        props?.setEmail(array);
        props?.setCurrEmail(null);
        props?.handleClose();
      }
    } else {
      props?.setEmail([...props?.emails, data1]);
      props?.setCurrEmail(null);
      props?.handleClose();
    }
  };

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const [data1, setData1] = useState({
    email_type: props?.email?.email_type || null,
    email: props?.email?.email || null,
    password: props?.email?.password || null,
    description: props?.email?.description || null,
    ...(props?.email && {
      id: props?.email?.id,
      contact_id: props?.email?.contact_id,
    }),
  });

  useEffect(() => {
    if (!props.email) {
      if (!data1.email_type || !data1.email) {
        setDisabled(true);
      } else {
        if (data1.email) {
          setDisabled(false);
        }
      }
    }
  }, [data1]);
  return (
    <>
      <CustomModal
        visible={props.open}
        width="35%"
        margin="150px 0"
        inset="15px!important"
      >
        <ModalHeader
          closeModal={() => {
            props.setAlert({
              open: true,
              type: "Warning",
              message: "Are you sure you want to cancel?",
              heading: "Warning!",
              btnText: "Yes",
              handleClick: () => {
                setData1(null);
                props.setAlert({ open: false });
                props.handleClose();
              },
              cancelText: "No",
              handleClose: () => props.setAlert({ open: false }),
            });
          }}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "36px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Add Email Address"}
          color={color.white}
        />
        <div className={classes.modalBody}>
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <Grid item xs={12} sm={12} md={12} className="pb-0"></Grid>
            <Grid item xs={12} md={12} sm={12}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Email type *"}
              </Typography>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={data1?.email_type}
                  onChange={(e) =>
                    setData1({ ...data1, email_type: e.target.value })
                  }
                  name="email_type"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  {type?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="topZero">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Description"}
              </Typography>
              <FormInput
                name="description"
                onChange={(e) =>
                  setData1({ ...data1, description: e.target.value })
                }
                label=""
                variant="outlined"
                fullWidth={true}
                value={data1?.description}
                margin="dense"
                required={true}
                placeholder={""}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="topZero">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Email Address *"}
              </Typography>
              <FormInput
                id="email"
                name="email"
                onChange={(e) => setData1({ ...data1, email: e.target.value })}
                value={data1?.email}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={""}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              <CustomButton
                type="submit"
                btnText="Save"
                disabled={
                  props?.email
                    ? data1?.email === props?.email?.email &&
                      data1.email_type === props?.email?.email_type &&
                      data1?.description === props?.email?.description &&
                      data1.password === props?.email?.password
                    : disabled
                }
                textAlign="center"
                display={"block"}
                padding="12px 25px"
                minWidth="113px"
                background="#0042a8"
                hoverBg="#0042a8"
                marginRight="10px"
                border="1px solid #0042a8"
                borderRadius="8px"
                color="#fff"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEmailAddress);
