import "date-fns";
import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { DocumentService } from "../../../services/document.service.js";
import Folder from "./../../../assets/img/Folder.png";
import browseFile from "../../../assets/img/browseFile.png";
import { FormInput } from "../../../components/FormInput/FormInput.js";

const useStyles = makeStyles(styles);

function AddDocument(props) {
  const classes = useStyles();
  const errRef = useRef(null);
  const pwdRef = useRef();
  const healthService = new DocumentService();
  const [err, setErr] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [docs, setDocs] = React.useState([]);
  const [file, setFile] = React.useState(null);
  const [docIsLoading, setDocIsLoading] = React.useState(false);
  const [passwordProtected, setPasswordProtected] = React.useState(false);
  const [password, setPassword] = React.useState(null);
  const [data, setData] = React.useState({
    status: "existing",
    docs: null,
  });

  const onDrop = React.useCallback((files) => {
    if (files && files.length) {
      setFile(files);
      let array = [];
      files.forEach((e) => array.push({ title: e.name }));
      if (props?.doc?.length > 0) {
        array = array.concat(props.doc);
        // props.setDoc(array);
      } else {
        // props.setDoc(array);
      }
      localStorage.setItem("payload", files);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  React.useEffect(() => {
    healthService
      .index()
      .then((res) => {
        setDocs(
          res?.data?.documents
            ? res?.data?.documents?.reduce((res, itm) => {
                // Test if the item is already in the new array
                let result = res.find((item) => item?.id == itm?.id);
                // If not lets add it
                if (!result) return res.concat(itm);
                // If it is just return what we already have
                return res;
              }, [])
            : []
        );
        console.log({ res });
      })
      .catch((err) => {
        console.log({ err });
      });
  }, []);

  const handleSubmit = () => {
    if (data.status !== "existing") {
      setDocIsLoading(true);
      const fd = new FormData();
      fd.append("attachment", file[0]);
      fd.append("title", file[0]?.name);
      fd.append("category", props?.category);
      fd.append("password_protected", JSON.stringify(passwordProtected));
      passwordProtected && fd.append("password", pwdRef.current.value);
      setErr(null);
      healthService
        ?.add(fd)
        ?.then((res) => {
          setDocIsLoading(false);
          props?.setDoc([
            ...props.doc,
            {
              title: res?.data?.document?.document_title,
              id: res?.data?.document?.id,
            },
          ]);
          props?.setData({
            ...props?.data,
            associated_documents_attributes: [
              ...props?.data?.associated_documents_attributes,
              {
                document_id: res?.data?.document?.id,
              },
            ],
          });
          // if (props?.associated_documents_attributes  ) {
          //     props?.setData({
          //         ...props?.data,
          //         associated_documents_attributes: [
          //             ...props?.data?.associated_documents_attributes  ,
          //             {
          //                 document_id: res?.data?.document?.id,
          //             },
          //         ],
          //     });
          // } else {
          //     props?.setData({
          //         ...props?.data,
          //         associated_documents_attributes: [
          //             {
          //                 document_id: res?.data?.document?.id,
          //             },
          //         ],
          //     });
          // }
          props?.handleClose();
        })
        .catch((err) => {
          errRef.current.scrollIntoView();
          setDocIsLoading(false);
          setErr(
            err?.response?.data?.message ||
              "something went wrong please try again"
          );
        });
    } else {
      // props?.setData({
      //     ...props?.data,
      //     associated_documents_attributes: [
      //         ...props?.data?.associated_documents_attributes,
      //         {
      //             document_id: file?.id,
      //         },
      //     ],
      // });
      // if (props?.associated_documents_attributes  ) {
      //     props?.setData({
      //         ...props?.data,
      //         associated_documents_attributes: [
      //             ...props?.data?.associated_documents_attributes  ,
      //             {
      //                 document_id: file?.id,
      //             },
      //         ],
      //     });
      // } else {
      //     props?.setData({
      //         ...props?.data,
      //         associated_documents_attributes: [
      //             {
      //                 document_id: file?.id,
      //             },
      //         ],
      //     });
      // }
      props?.handleClose();
    }
  };

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Add Document"}
          color={color.white}
        />
        <div className={classes.modalBody} ref={errRef}>
          <div className="w-100 d-flex flex-row mb-4">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              You can choose a document from your Documents to link with this
              record or upload a new one.
            </Typography>
          </div>
          {err && (
            <div className="w-100 d-flex flex-row mb-4">
              <Typography
                variant="p"
                component="p"
                align="left"
                style={{ color: "red", fontWeight: "normal" }}
              >
                {err}
              </Typography>
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <Grid item xs={12} sm={6} md={6}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="status"
                  value={data?.status}
                  onChange={(e) => {
                    setFile(null);
                    setData({ ...data, status: e.target.value });
                  }}
                  className="flex-row align-items-start flex-nowrap"
                >
                  <FormControlLabel
                    className="me-0"
                    value="existing"
                    control={
                      <Radio style={{ color: "#ffb722", paddingTop: 0 }} />
                    }
                  />
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Choose an existing document"}
                  </Typography>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="status"
                  value={data?.status}
                  onChange={(e) => {
                    setFile(null);
                    setData({ ...data, status: e.target.value });
                  }}
                  className="flex-row align-items-start flex-nowrap"
                >
                  <FormControlLabel
                    className="me-0"
                    value="new"
                    control={
                      <Radio style={{ color: "#ffb722", paddingTop: 0 }} />
                    }
                  />
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Create new document"}
                  </Typography>
                </RadioGroup>
              </FormControl>
            </Grid>
            {data.status && data.status === "existing" ? (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Existing Documents"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl fullWidth={true}>
                    <Select
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      required={true}
                      // value={data?.docs?.title}
                      onChange={(e) => {
                        setFile(e.target.value);
                        if (props.doc.length > 0) {
                          const temp = props?.doc?.filter(
                            (d, i) => d?.id === e.target.value.id
                          );
                          if (temp.length === 0) {
                            props?.setData({
                              ...props?.data,
                              associated_documents_attributes: [
                                ...props?.data?.associated_documents_attributes,
                                {
                                  document_id: e.target.value.id,
                                },
                              ],
                            });
                            props?.setDoc([
                              ...props.doc,
                              {
                                title: e.target.value?.document_title,
                                id: e.target.value?.id,
                              },
                            ]);
                          }
                        } else {
                          props?.setData({
                            ...props?.data,
                            associated_documents_attributes: [
                              ...props?.data?.associated_documents_attributes,
                              {
                                document_id: e.target.value.id,
                              },
                            ],
                          });
                          props?.setDoc([
                            {
                              title: e.target.value?.document_title,
                              id: e.target.value?.id,
                            },
                          ]);
                        }
                      }}
                      name="docs"
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled>Select Document</MenuItem>
                      {docs?.map((state, index) => {
                        return (
                          <MenuItem value={state} key={index}>
                            {state?.document_title}
                          </MenuItem>
                        );
                      })}
                      {}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            ) : (
              <>
                <div
                  className="w-100 drag_and_upload p-5 position-relative"
                  id={"err"}
                >
                  <div
                    {...getRootProps()}
                    className="w-75 mx-auto d-flex flex-column align-items-center px-sm-5 px-0"
                  >
                    <input {...getInputProps()} />
                    {!file ? (
                      <>
                        <img src={Folder} width={40} />
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{
                            color: color.themeColorNew,
                            textAlign: "center",
                          }}
                        >
                          {
                            "Drag your documents, photos, or videos here to start uploading"
                          }
                        </Typography>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{
                            color: color.themeYellow,
                            textAlign: "center",
                          }}
                        >
                          {"OR"}
                        </Typography>
                        <div
                          style={{
                            padding: "1rem",
                            background: "#0042A8",
                            width: "10rem",
                            borderRadius: "8px",
                            justifyContent: "center",
                            color: "white",
                            display: "flex",
                            cursor: "pointer",
                          }}
                        >
                          Browse Files
                        </div>
                      </>
                    ) : (
                      <div>
                        <div className="d-flex flex-row align-items-center position-absolute top-50 start-50 translate-middle">
                          {file?.map((f, key) => {
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "flex-start",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    variant="p"
                                    component="p"
                                    style={{
                                      color: color.themeColorNew,
                                      marginRight: "8px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {"Name: "}
                                  </Typography>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    style={{ color: color.themeColorNew }}
                                  >
                                    {f.name}
                                  </Typography>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "flex-start",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography
                                    variant="p"
                                    component="p"
                                    style={{
                                      color: color.themeColorNew,
                                      marginRight: "8px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {"Dimension: "}
                                  </Typography>
                                  <Typography
                                    variant="p"
                                    component="p"
                                    style={{ color: color.themeColorNew }}
                                  >
                                    {props.category}
                                  </Typography>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        {/* <div className="d-flex flex-row align-items-center position-absolute top-48 start-48 translate-middle">
                          <Typography
                            variant="p"
                            component="p"
                            style={{
                              color: color.themeColorNew,
                              marginRight: "8px",
                              fontWeight: "600",
                            }}
                          >
                            {"Dimension: "}
                          </Typography>
                          <Typography
                            variant="p"
                            component="p"
                            style={{ color: color.themeColorNew }}
                          >
                            {props.category}
                          </Typography>
                        </div> */}
                      </div>
                    )}
                  </div>
                </div>
                <Grid item xs={12} sm={12} md={12} className="pt-0">
                  <FormControlLabel
                    className="align-items-start"
                    control={
                      <Checkbox
                        checked={passwordProtected}
                        onChange={() =>
                          setPasswordProtected(!passwordProtected)
                        }
                        name="checkedA"
                        style={{ color: "#ffb722", paddingTop: 0 }}
                      />
                    }
                    label="Select if you want to password protect this document."
                  />
                </Grid>
                {passwordProtected && (
                  <>
                    <Grid item xs={12} sm={12} md={12} className="pb-0">
                      <Typography
                        variant="h6"
                        component="h3"
                        style={{ color: color.themeColorNew }}
                      >
                        {"Account Password *"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                      <input
                        style={{
                          padding: "10px",
                          width: "100%",
                        }}
                        autoComplete="new-password"
                        type="text"
id="txtPassword"
                        ref={pwdRef}
                      />
                      {/* <FormInput
                        label=""
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                        required={true}
                        type="text"
id="txtPassword"
                        inputProps={{
                          autoComplete: "new-password",
                        }}
                      /> */}
                    </Grid>
                  </>
                )}
              </>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              {!docIsLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  autoFocus={true}
                  color="#fff"
                  onClick={handleSubmit}
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDocument);
