import "date-fns";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";
import styles from "../../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import {CheckCircle} from "@material-ui/icons";
import color from "../../../../assets/css/color.js";
import CustomModal from "../../../../components/Modal/Modal.js";
import {CustomButton} from "../../../../components/CustomButton/Button.js";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(styles);

function PaymentSuccessfull(props) {
    const classes = useStyles();
    const history = useHistory();
    return (
        <>
            <CustomModal
                visible={props.open}
                width="35%"
                margin="250px 0 40px"
                inset="15px!important"
            >
                <div className={classes.modalBody}>
                    <Grid
                        container
                        wrap="wrap"
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                        className="px-4"
                    >
                        <div className="w-100 text-center">
                            <div className="d-flex justify-content-center align-items-center">
                                <CheckCircle
                                    className="me-2"
                                    style={{color: color.themeGreen, fontSize: "50px"}}
                                />
                                <Typography
                                    variant="h6"
                                    component="h3"
                                    className="mb-0"
                                    style={{color: "#060d5c", fontWeight: "bold"}}
                                >
                                    {"Thank you for your payment!"}
                                </Typography>
                            </div>
                            <Typography
                                variant="h6"
                                component="h3"
                                align="center"
                                className="mb-4"
                                style={{color: "#060d5c", fontWeight: "normal"}}
                            >
                                {"A confirmation email has been sent to your email address."}
                            </Typography>
                        </div>
                        <Grid item xs={12} sm={12} md={12} style={{textAlign: "center"}}>
                            <CustomButton
                                btnText="Okay"
                                textAlign="center"
                                display={"block"}
                                padding="8px 25px"
                                background="transparent"
                                hoverBg="#4DB500"
                                border="2px solid #4DB500"
                                borderRadius="8px"
                                color="#4DB500"
                                marginRight={"10px"}
                                width="100px"
                                onClick={() => {
                                    history.push("/user");
                                    props.handleClose();
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </CustomModal>
        </>
    );
}

export default PaymentSuccessfull;
