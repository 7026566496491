
import _default from "../config/default";
import {store} from "../store/store";

export class EmployerService {
    constructor() {
        this.BASE_URL = _default.BASE_URL;
        this.AUTH_ENDPOINT = _default.ENDPOINT_AUTH;
    }

    createEmplyee(data) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/employers`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "POST",
            data: data,
        });
    }

    createAward(data) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/awards`,
            headers: {
                "Content-Type": "multipart/form-data",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "POST",
            data: data,
        });
    }

    createSkills(data) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/skills`,
            headers: {
                "Content-Type": "multipart/form-data",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "POST",
            data: data,
        });
    }

    editEmplyee(data, id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/employers/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "PUT",
            data: data,
        });
    }

    editAward(data, id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/awards/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "PUT",
            data: data,
        });
    }

    editSkills(data, id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/skills/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "PUT",
            data: data,
        });
    }

    employeeIndex() {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/employers`,
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    employeeShow(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/employers/${id}`,
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    awardIndex() {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/awards`,
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    awardShow(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/awards/${id}`,
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    skillsIndex() {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/skills`,
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    skillsShow(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/skills/${id}`,
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    employeeDestroy(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/employers/${id}`,
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
        });
    }

    employeeDestroyAll(payload) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/destroy_all_employers`,
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
            data: {
                ids: payload
            }
        });
    }

    awardDestroy(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/awards/${id}`,
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
        });
    }

    awardDestroyAll(payload) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/destroy_all_awards`,
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
            data: {
                ids: payload
            }
        });
    }

    skillsDestroy(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/skills/${id}`,
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
        });
    }

    skillsDestroyAll(payload) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/destroy_all_skills`,
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
            data: {
                ids: payload
            }
        });
    }
}
