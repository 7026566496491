
import _default from "../config/default";
import { store } from "../store/store";

export class PropertyService {
  constructor() {
    this.BASE_URL = _default.BASE_URL;
    this.AUTH_ENDPOINT = _default.ENDPOINT_AUTH;
  }

  housesIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/houses`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  housesShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/houses/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  createHouses(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/houses`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  editHouses(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/houses/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  housesDestroy(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/houses/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  housesDestroyAll(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_houses`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }

  createPersonalItems(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/personal_items`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  personalItemsIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/personal_items`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  personalItemsShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/personal_items`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  editPersonalItems(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/personal_items/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  personalItemsDestroy(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/personal_items/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  personalItemsDestroyAll(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_personal_items`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }

  createSerialNumbers(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/serial_numbers`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  serialNumbersIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/serial_numbers`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  serialNumbersShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/serial_numbers`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  editSerialNumbers(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/serial_numbers/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  serialNumbersDestroy(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/serial_numbers/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  serialNumbersDestroyAll(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_serial_numbers`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }

  createRentals(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/rentals`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  rentalsIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/rentals`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  rentalsShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/rentals/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  editRentals(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/rentals/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  rentalsDestroyAll(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_rentals`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }
}
