import "date-fns";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import { Close, SettingsBluetoothRounded } from "@material-ui/icons";

const type = ["Home", "Work", "Other"];

const country = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas (the)",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia (Plurinational State of)",
  "Bonaire, Sint Eustatius and Saba",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory (the)",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cayman Islands (the)",
  "Central African Republic (the)",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands (the)",
  "Colombia",
  "Comoros (the)",
  "Congo (the Democratic Republic of the)",
  "Congo (the)",
  "Cook Islands (the)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curaçao",
  "Cyprus",
  "Czechia",
  "Côte d'Ivoire",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic (the)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Falkland Islands (the) [Malvinas]",
  "Faroe Islands (the)",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories (the)",
  "Gabon",
  "Gambia (the)",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Holy See (the)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea (the Democratic People's Republic of)",
  "Korea (the Republic of)",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People's Democratic Republic (the)",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands (the)",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia (Federated States of)",
  "Moldova (the Republic of)",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands (the)",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger (the)",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands (the)",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine, State of",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines (the)",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of North Macedonia",
  "Romania",
  "Russian Federation (the)",
  "Rwanda",
  "Réunion",
  "Saint Barthélemy",
  "Saint Helena, Ascension and Tristan da Cunha",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin (French part)",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten (Dutch part)",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan (the)",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands (the)",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates (the)",
  "United Kingdom of Great Britain and Northern Ireland (the)",
  "United States Minor Outlying Islands (the)",
  "United States of America (the)",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela (Bolivarian Republic of)",
  "Viet Nam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Åland Islands",
];

const countryStates = [
  "Outside the U.S.A.",
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virgina",
  "Wisconsin",
  "Wyoming",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles(styles);

function AddAddress(props) {
  const classes = useStyles();
  const [other, setOther] = useState(
    props?.address?.is_outside_usa ? props?.address?.is_outside_usa :
    // props?.address?.state &&
      countryStates.filter((s) => s === "Outside the U.S.A.").length <= 0
  );
  const [data1, setData1] = useState({
    country: props?.address?.country || null,
    address1: props?.address?.address1 || null,
    address2: props?.address?.address2 || null,
    city: props?.address?.city || null,
    address_type: props?.address?.address_type || null,
    state: props?.address?.state || null,
    is_outside_usa: other === true ? true : false,
    postal_code: props?.address?.postal_code || null,
    ...(props?.address && {
      id: props?.address?.id,
      contact_id: props?.address?.contact_id,
    }),
  });
  console.log('________________________________________________________',props?.address?.state )
  const handleSubmit = () => {
    let updatedArr = {
      ...data1,
      is_outside_usa : other === true ? true : false
    }
    // updatedArr?.is_outside_usa = other === true ? true : false
    if (props?.address) {
      let temp = props?.addresses?.indexOf(props?.address);
      if (temp >= 0) {
        let array = props?.addresses.map((val, index) => {
          if (index === temp) {
            return updatedArr;
          } else {
            return val;
          }
        });

        props?.setAddress(array);
        props?.setCurrAddress(null);
        props?.handleClose();
      }
    } else {
      props?.setAddress([...props?.addresses, updatedArr]);
      props?.setCurrAddress(null);
      props?.handleClose();
    }
  };

  const handleChange = (event) => {};

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!props?.address) {
      if (!data1.address1 || !data1.address_type) {
        setDisabled(true);
      } else {
        if (data1.address1) {
          setDisabled(false);
        }
      }
    }
  }, [data1]);
  return (
    <>
      <CustomModal
        visible={props.open}
        width="35%"
        margin="120px 0"
        inset="15px!important"
      >
        <ModalHeader
          closeModal={() => {
            props.setAlert({
              open: true,
              type: "Warning",
              message: "Are you sure you want to cancel?",
              heading: "Warning!",
              btnText: "Yes",
              handleClick: () => {
                setData1(null);
                props.setAlert({ open: false });
                props.handleClose();
              },
              cancelText: "No",
              handleClose: () => props.setAlert({ open: false }),
            });
          }}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "36px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Add Address"}
          color={color.white}
        />
        <div className={classes.modalBody}>
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <Grid item xs={12} sm={12} md={12} className="pb-0"></Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Country"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  // id="country"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={data1?.country || "Select Country"}
                  onChange={(event) =>
                    setData1({
                      ...data1,
                      [event.target.name]: event.target.value,
                    })
                  }
                  name="country"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={"Select Country"} disabled>
                    Select Country
                  </MenuItem>
                  {country?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {console.log(data1,"data1===>>>>")}
            <Grid item xs={12} md={12} sm={12}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Address type *"}
              </Typography>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="country"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={data1?.address_type || "Select Address Type"}
                  onChange={(event) =>
                    setData1({
                      ...data1,
                      [event.target.name]: event.target.value,
                    })
                  }
                  name="address_type"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={"Select Address Type"} disabled>
                    Select Address Type
                  </MenuItem>
                  {type?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="topZero">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Address *"}
              </Typography>
              <FormInput
                name="address1"
                onChange={(event) =>
                  setData1({
                    ...data1,
                    [event.target.name]: event.target.value,
                  })
                }
                label=""
                variant="outlined"
                value={data1?.address1}
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Address 1"}
              />
              <FormInput
                name="address2"
                onChange={(event) =>
                  setData1({
                    ...data1,
                    [event.target.name]: event.target.value,
                  })
                }
                label=""
                value={data1?.address2}
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Address 2"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="topZero">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"City"}
              </Typography>
              <FormInput
                name="city"
                onChange={(event) =>
                  setData1({
                    ...data1,
                    [event.target.name]: event.target.value,
                  })
                }
                label=""
                variant="outlined"
                value={data1?.city}
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={""}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="topZero">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"State"}
              </Typography>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  // id="country"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={other ? "Outside the U.S.A."
                      : data1?.state
                      ? data1.state
                      : "Select State"
                  }
                  onChange={(event) => {
                    if (event.target.value === "Outside the U.S.A.") {
                      setOther(true);
                    } else {
                      setOther(false);
                      setData1({
                        ...data1,
                        [event.target.name]: event.target.value,
                      });
                    }
                  }}
                  name="state"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem value={"Select State"} disabled>
                    Select State
                  </MenuItem>
                  {countryStates?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {console.log(data1?.is_outside_usa,'other===>>>>')}
              {other && (
                <FormInput
                  name="state"
                  onChange={(event) =>
                    setData1({
                      ...data1,
                      [event.target.name]: event.target.value,
                    })
                  }
                  label=""
                  variant="outlined"
                  value={data1?.state}
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  placeholder={"State "}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="topZero">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Zip/Postal Code"}
              </Typography>
              <FormInput
                id="language_name"
                name="postal_code"
                onChange={(event) =>
                  setData1({
                    ...data1,
                    [event.target.name]: event.target.value,
                  })
                }
                label=""
                variant="outlined"
                value={data1?.postal_code}
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={""}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              <CustomButton
                type="submit"
                btnText="Save"
                textAlign="center"
                display={"block"}
                padding="12px 25px"
                disabled={
                  props?.address
                    ? data1.address1 === props?.address?.address1 &&
                      data1.address2 === props?.address?.address2 &&
                      data1.address_type === props?.address?.address_type &&
                      data1.city === props?.address?.city &&
                      data1.state === props?.address?.state &&
                      data1.country === props?.address?.country &&
                      data1.postal_code === props?.address?.postal_code
                    : disabled
                }
                minWidth="113px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                marginRight="10px"
                borderRadius="8px"
                color="#fff"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

export default AddAddress;
