import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close, EditOutlined } from "@material-ui/icons";
// import AvatarGroup from '@mui/material/AvatarGroup';
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import jpegImage from "../../../assets/img/jpegImage.png";
import pdfImage from "../../../assets/img/pdfImage.png";
import { FinanceService } from "../../../services/finance.service.js";
import { store } from "../../../store/store.js";
import RevealPassword from "../RevealPassword/RevealPassword.js";
import { CustomButton } from "../../../components/CustomButton/Button.js";

const useStyles = makeStyles(styles);
export default function RetirementFundsInformation(props) {
  const classes = useStyles();
  const financeService = new FinanceService();
  const [openReveal, setOpenReveal] = useState(false);
  React.useEffect(() => {
    financeService
      .retirementFundsShow(props?.retirementFund[0]?.id)
      .then(() => {})
      .catch(() => {});
  }, []);

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%"
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          rightIcon={
            <EditOutlined
              onClick={() => props?.handleOpenEdit(props?.retirementFund[0])}
              style={{
                color: color.white,
                marginLeft: "auto",
                fontSize: "36px",
                cursor: "pointer",
              }}
            />
          }
          heading={
            props?.retirementFund[0]?.insurance_provider
              ? props?.retirementFund[0]?.insurance_provider
              : "Retirement Funds"
          }
          color={color.white}
        />
        <div className={classes.modalBody}>
          <div className="w-100 d-flex flex-row mb-5">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Finance > Retirement Funds > Information"}
            </Typography>
          </div>
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Brokerage Company *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.retirementFund[0]?.brokerage_company}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Retirement Fund Type *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.retirementFund[0]?.fund_type}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Description"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.retirementFund[0]?.description}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Account Number"}
              </Typography>
              {store.getState().auth.user.masking ? (
                <>
                  {props?.retirementFund[0]?.account_number_ciphertext ||
                  props?.retirementFund[0]?.account_number ? (
                    <CustomButton
                      onClick={() => {
                        setOpenReveal(true);
                        // props.handleClose()
                      }}
                      btnText={"Reveal"}
                      textAlign="center"
                      display={"block"}
                      background="transparent"
                      hoverBg="#0042a8"
                      border="1px solid #0042a8"
                      borderRadius="8px"
                      color="#0042a8"
                    />
                  ) : null}
                </>
              ) : null}
              {openReveal && (
                <RevealPassword
                  open={openReveal}
                  handleClose={() => setOpenReveal(false)}
                  subcategory_name={"RetirementFund"}
                  password={
                    props?.retirementFund[0]?.account_number_ciphertext ||
                    props?.retirementFund[0]?.account_number
                  }
                  id={props?.retirementFund[0]?.id}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.retirementFund[0]?.account_number ||
                  props?.retirementFund[0]?.account_number_ciphertext}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Value"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.retirementFund[0]?.value}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"YTD Rate of Return"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.retirementFund[0]?.interest_rate}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Owner"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.retirementFund[0]?.owner}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Notes"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.retirementFund[0]?.notes}
              </Typography>
            </Grid>
            {props?.retirementFund[0].web_logins ? (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Web Login"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew, fontSize: "18px" }}
                  >
                    {props?.retirementFund[0]?.web_logins[0]?.user_name}
                  </Typography>
                </Grid>
              </>
            ) : null}
          </Grid>
          <input style={{ position: "absolute", top: -200 }} />
          {props?.retirementFund[0].documents && (
            <Grid
              container
              wrap="wrap"
              direction="row"
              spacing={3}
              className="mt-5"
            >
              <Grid item xs={12} sm={6} md={6}>
                <Typography
                  variant="h6"
                  component="h3"
                  style={{ color: color.themeColorNew }}
                >
                  {"Linked Items"}
                </Typography>
                <div className="d-flex flex-row align-items-center mt-2">
                  <img src={jpegImage} className="me-2" />
                  <img src={pdfImage} className="me-2" />
                  <Typography
                    onClick={props.handleOpenLinkedItems}
                    variant="h6"
                    component="h3"
                    align="right"
                    className="text-decoration-underline"
                    style={{ color: color.themeColorNew, cursor: "pointer" }}
                  >
                    {"See all"}
                  </Typography>
                </div>
              </Grid>
              {/*<Grid item xs={12} sm={6} md={6}>*/}
              {/*  <Typography*/}
              {/*    variant="h6"*/}
              {/*    component="h3"*/}
              {/*    align="right"*/}
              {/*    style={{ color: color.themeColorNew }}*/}
              {/*  >*/}
              {/*    {"Collaborators"}*/}
              {/*  </Typography>*/}
              {/*  <div className="d-flex flex-row align-items-center justify-content-end mt-2">*/}
              {/*    <img src={jpegImage} className="me-2" />*/}
              {/*    <img src={pdfImage} className="me-2" />*/}
              {/*    <Typography*/}
              {/*      onClick={props.handleOpenCollaborators}*/}
              {/*      variant="h6"*/}
              {/*      component="h3"*/}
              {/*      align="right"*/}
              {/*      className="text-decoration-underline"*/}
              {/*      style={{ color: color.themeColorNew, cursor: "pointer" }}*/}
              {/*    >*/}
              {/*      {"See all"}*/}
              {/*    </Typography>*/}
              {/*  </div>*/}
              {/*</Grid>*/}
            </Grid>
          )}
        </div>
      </CustomModal>
    </>
  );
}
