import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
// @material-ui/core components
// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
// core components
import { useRouteName } from "../../../hooks";
import GridItem from "../../../components/Grid/GridItem.js";
import { Grid, Typography } from "@material-ui/core";
import color from "../../../assets/css/color.js";
// @material-ui/icons
import PersonalInformations from "../../../containers/Modals/PersonalInformation/PersonalInformation";
import LinkedItems from "../../../containers/Modals/LinkedItems/LinkedItems.js";
import Collaborators from "../../../containers/Modals/Collaborators/Collaborators";
import W2Form from "../../../containers/Modals/W2Form/W2Form";
import AddPersonalInformation from "../../../containers/Modals/AddPersonalInformation/AddPersonalInformation";
import { CustomButton } from "../../../components/CustomButton/Button";
import AddContactInfo from "../../../containers/Modals/AddContactInfo/AddContactInfo";
import CircularProgress from "@material-ui/core/CircularProgress";
import { store } from "../../../store/store";
import CardCategory from "../../../components/Card/CardCategory";
import EditPersonalInformation from "../../../containers/Modals/EditPersonalInformation/EditPersonalInformation";
import { PersonalService } from "../../../services/personal.service";
import { connect } from "react-redux";

function PersonalInformation({ sortType, order }) {
  const childRef = useRef();
  const personalService = new PersonalService();
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [personalInformations, setPersonalInformations] = React.useState([]);
  const [err, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [info, setInfo] = React.useState(null);
  const [currId, setCurrId] = React.useState(null);
  const [currPersonalInformation, setCurrPersonalInformation] =
    React.useState(null);
  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  React.useEffect(() => {
    setLoading(true);
    personalService
      .personalInformationIndex()
      .then((response) => {
        console.log({ response });
        setLoading(false);
        response?.data?.personal_information &&
          setPersonalInformations([response?.data?.personal_information]);
        if (
          id &&
          response?.data?.personal_information?.length > 0 &&
          response?.data?.personal_information?.filter((f) => f?.id === +id)
            .length > 0
        ) {
          setInfo(
            response?.data?.personal_information?.filter((f) => f?.id === +id)
          );
          setOpen(true);
        }
      })
      .catch((err) => {
        console.log({ err });
        setLoading(false);
        setError("error occurred");
      });
  }, [store.getState().loading.render]);

  const handleMenuItemClick = (event) => {
    // setSelectedIndex(index); +
    setOpenDropdown(false);
  };

  const handleToggle = () => {
    setOpenDropdown((prevopenDropdown) => !prevopenDropdown);
  };

  const handleOpenDropDown = (id) => {
    setCurrId(id);
    handleToggle();
  };
  const handleCloseDropdown = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenDropdown(false);
  };

  const routeName = useRouteName();

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAddNew, setOpenAddNew] = React.useState(false);
  const [openLinkedItems, setOpenLinkedItems] = React.useState(false);
  const [openCollaborators, setOpenCollaborators] = React.useState(false);
  const [openW2Form, setOpenW2Form] = React.useState(false);
  const [openAddContactInfo, setOpenAddContactInfo] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickInfo = (id) => {
    const personalInformation = personalInformations?.filter(
      (aw) => aw.id === id
    );
    setInfo(personalInformation);
    handleOpen();
  };

  const handleOpenAddNew = () => {
    setOpenAddNew(true);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
  };

  const handleOpenEdit = (personalInformation) => {
    setEdit(true);
    setOpenEdit(true);
    setCurrPersonalInformation(personalInformation);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
  };

  const handleOpenLinkedItems = () => {
    setOpenLinkedItems(true);
    setOpen(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
    setOpenAddNew(false);
  };

  const handleOpenCollaborators = () => {
    setOpenCollaborators(true);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenW2Form(false);
    setOpenAddNew(false);
  };

  const handleOpenW2Form = () => {
    setOpenW2Form(true);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenAddNew(false);
  };

  const handleOpenAddContactInfo = () => {
    setOpenAddContactInfo(true);
    setOpenW2Form(false);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenAddNew(false);
  };

  const handleCloseLinkedItems = () => {
    setOpenLinkedItems(false);
    setOpen(true);
  };

  const handleCloseCollaborators = () => {
    setOpenCollaborators(false);
  };

  const handleCloseW2Form = () => {
    setOpenW2Form(false);
  };

  const handleCloseAddNew = () => {
    setOpenAddNew(false);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setEdit(false);
  };

  const handleCloseAddContactInfo = () => {
    setOpenAddContactInfo(false);
  };

  const closeModal = () => {
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
    setOpenAddNew(false);
    setOpenAddContactInfo(false);
  };

  const closeEditModal = () => {
    setOpenEdit(false);
    setEdit(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
    setOpenAddNew(false);
    setOpenAddContactInfo(false);
  };

  const handleDelete = (id) => {
    personalService.personalInformationDestroy(id).then(() => {
      const remaining = personalInformations.filter((aw) => aw.id !== id);
      setPersonalInformations(remaining);
    });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Grid
        container
        wrap="wrap"
        direction={isMobile ? 'column' : 'row'}
        justifyContent=""
        alignItems={isMobile ? 'flex-start' : 'center'}
        style={{ margin: "25px 0" }}
      >
        <Typography
          variant="h6"
          component="h3"
          style={{ color: color.themeColorNew }}
        >
          Personal Information
        </Typography>
        {personalInformations?.length <= 0 && (
          <CustomButton
            type="submit"
            btnText="Add New"
            textAlign="center"
            display={"block"}
            padding="8px 17px"
            background="#0042a8"
            marginLeft="4rem"
            hoverBg="#0042a8"
            border="0"
            borderRadius="8px"
            color="#fff"
            onClick={() => handleOpenAddNew()}
          />
        )}
        <Grid item xs={6} md={6}></Grid>
        <Grid
          item
          xs={6}
          md={6}
          className="text-sm-center"
          style={{ textAlign: "right" }}
        ></Grid>
      </Grid>
      <Grid container>
        <GridItem xs={12} sm={12} md={12}>
          {openAddNew && (
            <AddPersonalInformation
              open={openAddNew}
              handleClose={handleCloseAddNew}
              handleOpenAddContactInfo={handleOpenAddContactInfo}
              closeModal={closeModal}
            />
          )}
          {edit && (
            <EditPersonalInformation
              open={openEdit}
              handleClose={handleCloseEdit}
              handleOpenAddContactInfo={handleOpenAddContactInfo}
              closeModal={closeEditModal}
              personalInformation={currPersonalInformation}
            />
          )}
          <AddContactInfo
            open={openAddContactInfo}
            handleCloseAddContactInfo={handleCloseAddContactInfo}
            closeModal={closeModal}
          />
          {open && (
            <PersonalInformations
              open={open}
              handleClose={handleClose}
              handleOpenEdit={handleOpenEdit}
              personalInformation={info}
              handleOpenLinkedItems={handleOpenLinkedItems}
              handleOpenCollaborators={handleOpenCollaborators}
              closeModal={closeModal}
            />
          )}
          <LinkedItems
            attachment={info}
            open={openLinkedItems}
            handleCloseLinkedItems={handleCloseLinkedItems}
            closeModal={closeModal}
            heading={"Personal Information"}
            category={"Personal"}
          />
          <Collaborators
            open={openCollaborators}
            handleCloseCollaborators={handleCloseCollaborators}
            closeModal={closeModal}
          />
          <W2Form
            open={openW2Form}
            handleCloseW2Form={handleCloseW2Form}
            handleOpenLinkedItems={handleOpenLinkedItems}
            handleOpenCollaborators={handleOpenCollaborators}
            closeModal={closeModal}
          />
        </GridItem>
        {personalInformations?.length > 0 && !loading && !err ? (
          <>
            {personalInformations?.map((dl, index) => {
              return (
                <CardCategory
                  handleClickInfo={() => handleClickInfo(dl?.id)}
                  // options={options}
                  handleOpenAddNew={handleOpenAddNew}
                  handleOpenEdit={handleOpenEdit}
                  childRef={childRef}
                  openDropdown={openDropdown}
                  handleToggle={() => handleOpenDropDown(dl?.id)}
                  anchorRef={anchorRef}
                  handleCloseDropdown={handleCloseDropdown}
                  data={dl}
                  currId={currId}
                  show={
                    !!dl?.social_security_number ||
                    !!dl?.social_security_number_ciphertext
                  }
                  selectedIndex={selectedIndex}
                  key={index}
                  subcategory={"PersonalInformation"}
                  category={"personal"}
                  handleDelete={handleDelete}
                  handleMenuItemClick={handleMenuItemClick}
                  heading={
                    dl.first_name && dl.last_name
                      ? dl.first_name + " " + dl.last_name
                      : dl.first_name
                      ? dl.first_name
                      : dl.last_name
                      ? dl.last_name
                      : "-"
                  }
                />
              );
            })}
          </>
        ) : loading ? (
          <CircularProgress size={40} color="secondary" />
        ) : err ? (
          "error occurred"
        ) : !loading && !err ? (
          "No Personal Information"
        ) : null}
      </Grid>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const mapStateToProps = (state, ownProps) => {
    return {
      sortType: state?.sort?.sort,
      order: state?.sort?.order,
    };
  };
};

export default connect(mapStateToProps)(PersonalInformation);
