import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import file from "../../assets/img/file.png";
import "bootstrap/dist/css/bootstrap.min.css";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import { Box, Grid, Typography } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import config from "../../config/default";
import MenuList from "@material-ui/core/MenuList";
import Alerts from "../../containers/Modals/Alerts/Alerts/Alerts.js";
import { CustomButton } from "../CustomButton/Button";
import color from "../../assets/css/color";
import { store } from "../../store/store";
import RevealPassword from "../../containers/Modals/RevealPassword/RevealPassword";
import { PersonalService } from "../../services/personal.service";
import ShareModal from "../../containers/Modals/ShareModal/ShareModal";
import Warning from "../../containers/Modals/Alerts/Warning/Warning";
import SpinnerModal from "../../containers/Modals/Alerts/SpinnerModal/SpinnerModal";
import moment from "moment";
import { truncateString } from "../../Utils/truncateString.js";
import { connect } from "react-redux";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";

const CardViewComponent = (props) => {
  const personalService = new PersonalService();
  const [alerts, setAlerts] = React.useState(false);
  const [maskWarning, setMaskWarning] = React.useState(false);
  const [warning, setWarning] = React.useState(false);
  const [id, setID] = React.useState(null);
  const [shareReveal, setShareReveal] = React.useState(false);
  const [msg, setMsg] = React.useState(null);
  const [openReveal, setOpenReveal] = React.useState(false);
  const [sharePayload, setSharePayload] = React.useState([]);
  const [spinner, setSpinner] = React.useState(false);
  const [openShare, setOpenShare] = React.useState(false);
  const [cat, setCat] = React.useState(null);
  const { childRef } = props;
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const handleOpenAlerts = () => {
    setAlerts(true);
  };
  const handleCloseAlerts = () => {
    setAlerts(false);
  };
  useEffect(() => {
    childRef.current = share;
  }, [props]);
  const share = () => {
    setMsg("Processing the information, kindly wait");
    setSpinner(true);
    personalService
      .checkShare()
      .then((res) => {
        if (res.data && res.data.check_share === true) {
          setMsg("Generating your pdf to share, please wait.");
          let data = props?.multiple
            ? props?.ids.map((val) => {
                return {
                  id: val,
                  subcategory: props?.subcategory,
                };
              })
            : [
                {
                  id: props?.data?.id,
                  subcategory: props?.subcategory,
                },
              ];
          personalService
            .shareSub(data)
            .then((res) => {
              setMsg(null);
              setSpinner(false);
              setSharePayload(
                res?.data?.subcategory_pdfs?.map((val) => {
                  return {
                    document_id: val?.id,
                    title: val?.title,
                    profile_id: store.getState().profile.profileInfo.profile.id,
                  };
                })
              );
              setID(res.data?.subcategory_pdfs[0]?.id);
              setCat(res.data?.subcategory_pdfs[0]?.category);
              props.setIds([]);
              setOpenShare(true);
            })
            .catch((err) => {
              setMsg(null);
              setSpinner(false);
              setMsg(
                "An unknown error occurred while sharing, please try again later"
              );
              setWarning(true);
            });
        } else {
          setMsg(null);
          setSpinner(false);
          setMsg(
            "Your monthly shares have reached their limit, please upgrade your subscription for unlimited shares!"
          );
          setWarning(true);
        }
      })
      .catch((err) => {
        setMsg(null);
        setSpinner(false);
        setMsg("An unknown error occurred. Please try again later!");
        setWarning(true);
      });
  };

  const handleCancel = () => {
    share();
    setMaskWarning(false);
  };

  const alternate = () => {
    setShareReveal(true);
    setMaskWarning(false);
  };
  const typo = useRef(null);
  const typo2 = useRef(null);
  const typo3 = useRef(null);
  useEffect(() => {
    if (props?.heading?.length >= 15) {
      typo.current?.setAttribute("data-tooltip", props?.heading);
      typo.current?.setAttribute("data-tooltip-location", "top");
    }
    if (props?.heading2?.length >= 15) {
      typo2.current?.setAttribute("data-tooltip", props?.heading2);
      typo2.current?.setAttribute("data-tooltip-location", "top");
    }
    if (props?.heading3?.length >= 15) {
      typo3.current?.setAttribute("data-tooltip", props?.heading3);
      typo3.current?.setAttribute("data-tooltip-location", "top");
    }
  }, [props?.sort]);
  let utcDate = moment(
    props?.data?.created_at?.split("-")[2] +
      "-" +
      props?.data?.created_at?.split("-")[0] +
      "-" +
      props?.data?.created_at?.split("-")[1],
    "YYYY-MM-DD"
  ).toDate();

  return (
    <div ref={props.ref} style={{ margin: "1rem", width: "100%" }}>
      <Card className="p-4 m-0 h-auto mb-3 min-height-182px">
        {shareReveal && (
          <RevealPassword
            open={shareReveal}
            share={true}
            callShare={share}
            handleClose={() => setShareReveal(false)}
            subcategory_name={
              props?.category === "web"
                ? "WebLogin"
                : props?.category === "email"
                ? "EmailAccount"
                : props?.category === "retirementFund"
                ? "RetirementFund"
                : props?.category === "personal"
                ? "PersonalInformation"
                : props?.category === "mortgage"
                ? "Mortgage"
                : props?.category === "cashAccount"
                ? "CashAccount"
                : "Expense"
            }
            password={
              props?.category === "web"
                ? props?.data?.password_ciphertext
                : props?.category === "mortgage"
                ? props?.data?.account_number_ciphertext
                : props?.category === "email"
                ? props?.data?.password_ciphertext
                : props?.category === "personal"
                ? props?.data?.social_security_number_ciphertext
                : props?.category === "cashAccount"
                ? props?.data?.account_number_ciphertext
                : props?.data?.account_number_ciphertext
            }
            id={props?.data?.id}
          />
        )}
        <div className="position-relative d-flex justify-content-between mb-3">
          {/* {props?.subcategory === "WebLogin" ||
          props?.subcategory === "LoyaltyProgram" ? (
            <>
              {props?.data?.image ? (
                <>
                  <img
                    className="category-icon mb-3"
                    width="50px"
                    height={"50px"}
                    src={config.BASE_URL + props?.data?.image}
                    loading="lazy"
                  />
                </>
              ) : (
                <>
                  <img
                    className=" mb-3"
                    src={file}
                    height={"40px"}
                    width="40px"
                    loading="lazy"
                  />
                </>
              )}
            </>
          ) : (
            <>
              <img
                src={file}
                height={"45px"}
                width="38px"
                className="mb-3"
                loading="lazy"
              />
            </>
          )} */}
          <img
            className=" mb-3"
            src={file}
            height={"40px"}
            width="40px"
            loading="lazy"
          />
        </div>

        <Typography
          variant="h6"
          component="h3"
          style={{ color: "#060d5c", cursor: "pointer" }}
          onClick={() => props.handleClickInfo(props.data?.id)}
        >
          {props?.heading?.length <= 12
            ? props?.heading
            : truncateString(props?.heading, 12)}
        </Typography>
        <Typography variant="p" component="p">
          {utcDate &&
            utcDate != undefined &&
            moment(utcDate).format("MMMM DD, YYYY")}
        </Typography>
        {spinner ? <SpinnerModal open={spinner} msg={msg} /> : null}
        <Grid container alignItems={"center"}>
          {openShare && (
            <ShareModal
              open={openShare}
              payload={sharePayload}
              multiple={props?.multiple}
              setMultiple={props.setMultiple}
              id={id}
              // title={
              //   store.getState().profile.profileInfo.profile?.first_name +
              //   "'s " +
              //   cat +
              //   " information"
              // }
              title={
                store.getState().profile?.profileInfo?.profile?.profile_type ===
                "person"
                  ? store.getState().profile.profileInfo.profile?.first_name +
                    "'s " +
                    cat +
                    " information"
                  : store.getState().profile?.profileInfo?.profile
                      ?.business_name +
                    "'s " +
                    cat +
                    " information"
              }
              category={cat ? cat : ""}
              handleClose={() => setOpenShare(false)}
            />
          )}
          <GridItem xs={12} sm={12} md={8} alignItems="center">
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} container alignItems="center">
                {props?.data &&
                  props?.data?.alert_attributes &&
                  props?.data?.alert_attributes?.status === true && (
                    <NotificationsActiveIcon
                      style={{ color: "#F50057", marginRight: "0" }}
                      className={props?.data?.upcoming ? "bell" : ""}
                    />
                  )}
              </GridItem>
            </GridContainer>
          </GridItem>
          {maskWarning && (
            <Warning
              open={maskWarning}
              handleClose={() => setMaskWarning(false)}
              heading={"Warning"}
              btnText={"No"}
              handleCancel={handleCancel}
              one={false}
              alternate={alternate}
              color={"#060D5C"}
              message={
                "Do you want to unmask the sensitive information in the share?"
              }
              onClick={() => {
                setMaskWarning(false);
              }}
            />
          )}
          <div className="position-absolute dropMenuDocumentMain">
            {props?.multiple && (
              <div className={"checkbox-round"} style={{ marginLeft: "2rem" }}>
                <input
                  id={"checkbox" + props?.data?.id}
                  type={"checkbox"}
                  checked={props?.ids.includes(props?.data?.id)}
                  style={{
                    height: "20px",
                    width: "20px",
                    cursor: "pointer",
                  }}
                  onChange={(e) => {
                    if (props?.ids.includes(props?.data?.id)) {
                      props?.setIds(
                        props?.ids?.filter((f) => f != props?.data?.id)
                      );
                      if (props?.ids?.length === 1) {
                        props?.setMultiple(false);
                      }
                    } else {
                      props?.setIds([...props?.ids, props?.data?.id]);
                    }
                  }}
                />
                <label htmlFor={"checkbox" + props?.data?.id}></label>
              </div>
            )}
            {!props.multiple && (
              <MoreVertIcon
                aria-controls={
                  props.openDropdown ? "split-button-menu" : undefined
                }
                aria-expanded={props.openDropdown ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={props.handleToggle}
                style={{
                  cursor: "pointer",
                  position: "relative",
                  margin: "0",
                  color: "#0042a8",
                }}
              />
            )}
          </div>
          {!props.multiple && (
            <>
              {props?.data?.id === props?.currId ? (
                <Popper
                  open={props.openDropdown}
                  anchorEl={props?.anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{
                    position: "absolute",
                    top: "45px",
                    right: "0",
                    left: "unset",
                    zIndex: "1",
                  }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener
                          onClickAway={props.handleCloseDropdown}
                        >
                          <MenuList id="split-button-menu">
                            {props?.subcategory !== "PersonalInformation" &&
                              props?.subcategory !== "Wellness" && (
                                <MenuItem
                                  onClick={(event) => {
                                    props?.setMultiple(true);
                                    props?.setIds([
                                      ...props?.ids,
                                      props?.data?.id,
                                    ]);
                                    props?.handleCloseDropdown();
                                  }}
                                >
                                  <div>Select</div>
                                </MenuItem>
                              )}
                            {props?.subcategory === "WebLogin" && (
                              <MenuItem
                                onClick={() => {
                                  props?.setIndexId(props?.data?.id);
                                  props?.setShowSections(true);
                                }}
                              >
                                <div>Add to</div>
                              </MenuItem>
                            )}
                            {props?.subcategory === "WebLogin" &&
                              props?.grouped && (
                                <MenuItem
                                  onClick={() => {
                                    props?.unGroup(props?.data?.id);
                                    props.handleCloseDropdown();
                                  }}
                                >
                                  <div>Ungroup</div>
                                </MenuItem>
                              )}
                            <MenuItem
                              onClick={() => {
                                if (
                                  store.getState().auth?.user?.masking === true
                                ) {
                                  props?.category === "web" ||
                                  props?.category === "email" ||
                                  props?.category === "personal" ||
                                  props?.category === "expense"
                                    ? setMaskWarning(true)
                                    : share();
                                } else {
                                  share();
                                }
                              }}
                            >
                              <div>Share</div>
                            </MenuItem>
                            <MenuItem
                              onClick={(event) => {
                                props.handleOpenEdit(props?.data);
                              }}
                            >
                              <div>Edit</div>
                            </MenuItem>
                            <MenuItem onClick={() => handleOpenAlerts()}>
                              <div>Delete</div>
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              ) : null}
            </>
          )}
        </Grid>
      </Card>
      <Alerts
        open={alerts}
        handleClose={handleCloseAlerts}
        handleCloseDropdown={props?.handleCloseDropdown}
        handleDelete={props?.handleDelete}
        currId={props?.currId}
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    sort: state.sort?.sort,
  };
};

export default connect(mapStateToProps)(CardViewComponent);
