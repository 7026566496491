import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Grid} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import {CustomButton} from "../../CustomButton/Button";
import color from "../../../assets/css/color";
import AddProfile from "../../../containers/Modals/AddProfile/AddProfile";
import SwitchProfile from "../../../containers/Modals/SwitchProfile/SwitchProfile";
import ProfileImageUpload from "../../ProfileImageUploader/ProfileImageUpload";
import CircularProgress from "@material-ui/core/CircularProgress";
import {destroy, update} from "../../../store/actions/profileAction";
import {connect} from "react-redux";
import ProfileFields from "./ProfileFields.js";
import {store} from "../../../store/store";

const useStyles = makeStyles(styles);

function ProfileTabContent(props) {
    const classes = useStyles();
    const [info, setInfo] = React.useState({
        first_name: "",
        last_name: "",
        prefix: "",
        suffix: "",
        date_of_birth: "",
        gender: "",
        attachment: null,
        profile_type: ""
    });
    React.useEffect(() => {
        setInfo({
            first_name: props?.profileInfo?.profile?.first_name
                ? props?.profileInfo?.profile?.first_name
                : "",
            last_name: props?.profileInfo?.profile?.last_name
                ? props?.profileInfo?.profile?.last_name
                : "",
            prefix: props?.profileInfo?.profile?.prefix
                ? props?.profileInfo?.profile?.prefix
                : "",
            suffix: props?.profileInfo?.profile?.suffix
                ? props?.profileInfo?.profile?.suffix
                : "",
            date_of_birth: props?.profileInfo?.profile?.date_of_birth
                || "",
            gender: props?.profileInfo?.profile?.gender
                ? props?.profileInfo?.profile?.gender
                : "",
            attachment: props?.profileInfo?.profile?.attachment
                ? props?.profileInfo?.profile?.attachment
                : null,
            profile_type: props?.profileInfo?.profile?.profile_type,
            business_name: props?.profileInfo?.profile?.business_name || null
        });
    }, [props?.profileInfo, props?.profiles]);

    const uploadedImage = React.useRef(null);
    const imageUploader = React.useRef(null);

    const handleImageUpload = (e) => {
        const [file] = e.target.files;
        if (file) {
            const reader = new FileReader();
            const {current} = uploadedImage;
            current.file = file;
            reader.onload = (e) => {
                current.src = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    };

    const [open, setOpen] = React.useState(false);
    const [openAddProfile, setOpenAddProfile] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = (id) => {
        props.delete(id);
    };
    const handleSave = () => {
        const formData = new FormData();
        info.profile_type === "person" && formData.append("first_name", info.first_name);
        info.profile_type === "person" && formData.append("last_name", info.last_name);
        info.profile_type === "person" && formData.append("prefix", info.prefix);
        info.profile_type === "person" && formData.append("suffix", info.suffix);
        info.profile_type === "person" && formData.append("date_of_birth", info.date_of_birth);
        formData.append("profile_type", info.profile_type);
        info.profile_type === "business" && formData.append("business_name", info.business_name);
        info.profile_type === "person" && formData.append("gender", info.gender);
        if (
            info.attachment &&
            info.attachment !== props.profileInfo.profile.attachment
        ) {
            formData.append("attachment", info.attachment);
        }
        props.update(formData, props.profileInfo.profile.id);
    };

    const handleOpenAddProfile = () => {
        setOpen(false);
        setOpenAddProfile(true);
    };

    const handleCloseAddProfile = () => {
        setOpenAddProfile(false);
    };

    const closeModal = () => {
        setOpen(false);
        setOpenAddProfile(false);
    };

    const handleChange = (e) => {
        setInfo({...info, [e.target.name]: e.target.value});
    };

    return (
        <>
            <SwitchProfile
                open={open}
                handleClose={handleClose}
                handleOpenAddProfile={handleOpenAddProfile}
                closeModal={closeModal}
                profiles={props.profiles}
                handleDelete={handleDelete}
            />
            <AddProfile
                open={openAddProfile}
                handleCloseAddProfile={handleCloseAddProfile}
                closeModal={closeModal}
            />
            <Grid item xs={12} sm={12} md={10} lg={8}>
                <Grid item xs={12} md={12} className={classes.marginBottom18px}>
                    <ProfileImageUpload
                        setInfo={setInfo}
                        info={info}
                        src={info?.attachment}
                        profile={props?.profileInfo?.profile}
                    />
                    <Grid item xs={12} md={12} container justify="center">
                        {store.getState().auth?.user?.account_type !== "Free" && store.getState().auth?.user?.account_type !== "Premium" ?
                            <CustomButton
                                btnText={"Switch Profile"}
                                padding="15px 25px"
                                minWidth="113px"
                                height="50px"
                                background="transparent"
                                hoverBg="#0042a8"
                                color={color.themeColorNewLight}
                                border="2px solid #0042a8"
                                marginBottom="20px"
                                onClick={handleOpen}
                            /> :
                            <Button
                                style={{
                                    padding: "15px 25px",
                                    minWidth: "113px",
                                    height: "50px",
                                    background: "transparent",
                                    border: "2px solid grey",
                                    color: "grey",
                                    marginBottom: "20px"
                                }}
                                minWidth="113px"
                                className="tooltipText px-2 px-md-4 text-capitalize"
                                data-tooltip="A Premium or Free account cannot have more than 1 profile."
                                data-tooltip-location="bottom"
                            > Switch Profile </Button>}
                    </Grid>
                </Grid>

                <form className={"w-100"}>
                    <Grid
                        container
                        wrap="wrap"
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={4}
                    >
                        <ProfileFields info={info} profile={info} handleChange={handleChange} setDate={(e) => setInfo({
                            ...info, date_of_birth: e
                        })}/>
                        <Grid item xs={12} md={12}>
                            {props.loading ? (
                                <CustomButton
                                    btnText={<CircularProgress size={20} color="#DDD"/>}
                                    padding="15px 25px"
                                    minWidth="113px"
                                    height="50px"
                                    background="#0042a8"
                                    hoverBg="#0042a8"
                                    color="#fff"
                                />
                            ) : (
                                <CustomButton
                                    btnText={"SAVE"}
                                    padding="15px 25px"
                                    disabled={
                                        props?.profileInfo?.profile?.first_name === info.first_name &&
                                        props?.profileInfo?.profile?.last_name === info.last_name &&
                                        props?.profileInfo?.profile?.gender ===
                                        info.gender &&
                                        props?.profileInfo?.profile?.date_of_birth?.split("-")[2] + "-" + props?.profileInfo?.profile?.date_of_birth?.split("-")[1] + "-" + props?.profileInfo?.profile?.date_of_birth?.split("-")[0] ===
                                        info.date_of_birth &&
                                        props?.profileInfo?.profile?.prefix === info.prefix && props?.profileInfo?.profile?.suffix === info.suffix && props.profileInfo.profile.attachment === info.attachment
                                    }
                                    minWidth="113px"
                                    height="50px"
                                    background="#0042a8"
                                    hoverBg="#0042a8"
                                    color="#fff"
                                    onClick={handleSave}
                                />
                            )}
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </>
    );
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        update: (data, id) => {
            dispatch(update(data, id));
        },
        delete: (id) => {
            dispatch(destroy(id));
        },
    };
};

const mapStateToProps = (state, ownProps) => {
    return {
        profileInfo: state.profile.profileInfo,
        loading: state.profile.loading,
        profiles: state.profile.profiles,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileTabContent);
