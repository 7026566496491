import React from "react";
import { Grid, Typography } from "@material-ui/core";
import AccountSettingTabs from "../../components/AccountSettingTabs/AccountSettingTabs.js";

export default function Profile() {
    return (
        <>
            <Grid container wrap="wrap"
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid item xs={12} sm={12} md={1}></Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "25px", }}>
                    <Typography variant="h6" component="h3" style={{ color: "#060d5c" }}>
                        {"Account Settings"}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={1}></Grid>
            </Grid>
            <Grid container wrap="wrap"
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid item xs={12} sm={12} md={1}></Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "25px", }}>
                    <AccountSettingTabs />
                </Grid>
                <Grid item xs={12} sm={12} md={1}></Grid>
            </Grid>
        </>
    );
}
