import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import collaborators from "../../../assets/img/collaboratorsHistory.png";
import backMe from "../../../assets/img/back.png";

const useStyles = makeStyles(styles);
export default function AddContactInfo(props) {
  const classes = useStyles();

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props?.handleCloseAddContactInfo}
        width="35%"
        inset= "15px!important"
      >
        <ModalHeader
          onClick={props.closeModal}
          icon={
            <img
              onClick={props.closeModal}
              style={{ cursor: "pointer" }}
              src={backMe}
              className="me-3"
            />
            // <Close
            //
            //     style={{
            //         color: color.white,
            //         marginRight: "20px",
            //         fontSize: "36px",
            //         cursor: "pointer",
            //     }}
            // />
          }
          heading={"Add Contact"}
          color={color.white}
        />
        <div className={classes.modalBody}>
          <div className="w-100 d-flex flex-row mb-4">
            <Typography
              variant="h6"
              component="h3"
              align="left"
              style={{ color: color.themeColorNew }}
            >
              {"History"}
            </Typography>
          </div>
          <div className="d-flex flex-column history_main">
            <div className="d-flex flex-row align-items-start pb-3 position-relative">
              <div
                className="position-absolute h-100"
                style={{
                  background: color.themeOrange,
                  width: "1px",
                  left: "23.5px",
                  zIndex: "0",
                }}
              ></div>
              <img
                src={collaborators}
                className="me-3"
                style={{ zIndex: "1" }}
              />
              <Typography
                variant="h6"
                component="h3"
                align="left"
                className="me-2"
              >
                <span style={{ color: color.themeYellow }}>
                  {"John Rivers"}{" "}
                </span>
                {"viewed the contact."}
                <Typography
                  variant="p"
                  component="p"
                  align="left"
                  className="me-2"
                >
                  {"July 24, 2019 at 10:30 PM"}
                </Typography>
              </Typography>
            </div>
            <div className="d-flex flex-row align-items-start pb-3 position-relative">
              <div
                className="position-absolute h-100"
                style={{
                  background: color.themeOrange,
                  width: "1px",
                  left: "23.5px",
                  zIndex: "0",
                }}
              ></div>
              <img
                src={collaborators}
                className="me-3"
                style={{ zIndex: "1" }}
              />
              <Typography
                variant="h6"
                component="h3"
                align="left"
                className="me-2"
              >
                <span style={{ color: color.themeYellow }}>
                  {"Amanda Bynes"}{" "}
                </span>
                {"viewed the contact."}
                <Typography
                  variant="p"
                  component="p"
                  align="left"
                  className="me-2"
                >
                  {"July 24, 2019 at 12:30 PM"}
                </Typography>
              </Typography>
            </div>
            <div className="d-flex flex-row align-items-start pb-3 position-relative">
              <div
                className="position-absolute h-100"
                style={{
                  background: color.themeOrange,
                  width: "1px",
                  left: "23.5px",
                  zIndex: "0",
                }}
              ></div>
              <img
                src={collaborators}
                className="me-3"
                style={{ zIndex: "1" }}
              />
              <Typography
                variant="h6"
                component="h3"
                align="left"
                className="me-2"
              >
                <span style={{ color: color.themeYellow }}>
                  {"Roger McDonald"}{" "}
                </span>
                {"added"}
                <span style={{ color: color.themeYellow }}>
                  {" "}
                  {"John Rivers"}{" "}
                </span>
                {"to the contact."}
                <Typography
                  variant="p"
                  component="p"
                  align="left"
                  className="me-2"
                >
                  {"July 24, 2019 at 2:01 PM"}
                </Typography>
              </Typography>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
}
