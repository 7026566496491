import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { CustomButton } from "../../components/CustomButton/Button";

import color from "../../assets/css/color";
import { FormInput } from "../../components/FormInput/FormInput";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";
import { store } from "../../store/store";

import {
  clearError,
  login,
  sendOtp,
  setAuthLoading,
  setCurrentUser,
  setError,
  setLoginAnimationFalse,
  logout,
  setUserCount,
} from "../../store/actions/authAction";
import { index } from "../../store/actions/profileAction";
import { sort } from "../../store/actions/sortAction";
import { isExpired } from "../../store/actions/loadingAction.js";

import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import AfterLoginAnimation from "../../containers/Modals/AfterLoginAnimation/AfterLoginAnimation";
import Lottie from "react-lottie";
import animationData from "../../assets/animations/LockLogin.json";
import { Auth } from "../../services/auth.service";
// import {clearProfileState} from "./profileAction";
import NewLogo from "./../../assets/img/tag-logo-vertical.png";
import NewLogoTag from "./../../assets/img/tag-logo-mark.png";
import { fadeOutDown, slideOutDown, zoomIn } from "react-animations";
import Radium, { StyleRoot } from "radium";
import animationVideo from "../../assets/img/newAnimation.mp4";
import { yellow } from "@material-ui/core/colors";
import ReactGA from "react-ga4";
import AlertPopup from "../../containers/Modals/Alerts/Generic/GenericAlert";
import axios from "axios";
import _default from "../../config/default.js";

const Api = new Auth();

const LoginPage = (props) => {
  const [isStopped, setIsStopped] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [animation, setAnimation] = useState(false);
  const [expired, setExpired] = useState(false);

  const [type, setType] = React.useState({
    pwd: "password",
    pwdC: "password",
  });
  const [message, setMessage] = useState(null);
  const [state, setState] = React.useState({
    checkedA: false,
    email: "",
    password: "",
  });
  const [focus, setFocus] = useState({
    email: state.email === "" ? true : false,
    password: state.password === "" && state.email ? true : false,
    btn: true,
  });
  const [bEmail, setBEmail] = useState(true);
  const [aEmail, setAEmail] = useState(false);
  const [loading, setLoading] = useState(false);

  const [AftEmail, setAftEmail] = useState(false);
  const [aftPass, setAftPass] = useState(false);
  const [alDone, setAlDone] = useState(false);
  const [duplicateEmail, setDuplicateEmail] = useState(false);
  useEffect(() => {
    setIsPaused(true);
    setMessage(localStorage.getItem("message") || null);
    // if (props.loginAnimation === true) {
    //     setBEmail(false);
    //     setAEmail(false);
    //     setAftPass(false);
    // }
    return () => {
      localStorage.removeItem("message");
      props.resetErrorState(false);
      props.setAuthLoading(false);
    };
  }, []);
  const history = useHistory();

  const handleKeyBack = (event) => {
    if (state.email.length >= 11 || event.key === "Backspace") {
      // setDuplicateEmail(true)
    }
  };

  const handleChange = (event) => {
    setBEmail(false);
    if (event.target.name === "checkedA") {
      setState({ ...state, [event.target.name]: event.target.checked });
    } else {
      event.target.name === "email"
        ? setBEmail(false) || setAftPass(false) || setAEmail(true)
        : event.target.name === "password"
        ? setAEmail(false) || setAftPass(true)
        : null;
      setState({ ...state, [event.target.name]: event.target.value });
    }

    // let validRegex =
    //   /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    // if (
    //   state.email.match(validRegex) &&
    //   state.email.length >= 10 &&
    //   state.password === "" &&
    //   event.target.name === "email" &&
    //   duplicateEmail === false
    // ) {
    //   // console.log("hello");

    //   // setIsPaused(false);
    //   // setTimeout(() => {
    //     // setIsPaused(true);
    //     setDuplicateEmail(true);
    //   // }, 1500);
    // }
    // if (
    //   state.password.length === 4 &&
    //   state.email.length > 10 &&
    //   event.target.name === "password" &&
    //   duplicateEmail === true
    // ) {
    //   // setIsPaused(false);
    //   // setTimeout(() => {
    //   //   setIsPaused(true);
    //   // }, 1500);
    // }
    // // }
  };

  const handleSubmit = () => {
    setMessage(null);
    const data = {
      email: state.email,
      password: state.password,
      remember_me: state.checkedA,
      is_login: true,
    };

    props.setAuthLoading(true);

    Api.sendOtp(data)
      .then((response) => {
        props.setAuthLoading(false);
        localStorage.setItem("email", state.email);
        localStorage.setItem("password", state.password);
        localStorage.setItem("remember_me", state.remember_me);

        setLogin(true);
        // for 2FA Flow

        props?.history.push("/auth/otp");
        // setTimeout(() => {
        //   setAnimation(true);
        //   props?.history.push("/auth/otp");
        // }, 4000);
      })
      .catch((err) => {
        console.log("errrr", err);
        let str = "";
        if (err?.response?.status === 404) {
          props.setAuthLoading(false);
          props.setErrors(["Invalid credentials"]);
        } else if (err?.response?.status === 406) {
          userLogin(data, props?.history);
        } else if (err?.response?.status === 401) {
          props.setAuthLoading(false);

          props.setErrors(["Invalid credentials"]);
        } else if (err?.response?.data && err?.response?.data?.errors) {
          props.setAuthLoading(false);

          Object.keys(err?.response?.data?.errors).forEach((key) => {
            let value = err?.response?.data?.errors[key];
            str = str?.concat(value + " ");
          });
          props.setErrors(str);
        } else if (err?.response?.data?.err) {
          props.setAuthLoading(false);

          props.setErrors([err?.response?.data?.err]);
        } else {
          props.setAuthLoading(false);

          props.setErrors(["Invalid login credentials."]);
        }
      });
  };

  const userLogin = (data, history) => {
    props.setAuthLoading(true);
    Api.login(data)
      .then((response) => {
        localStorage.setItem(
          "brow$3rCt",
          JSON.stringify(response.headers.client)
        );
        localStorage.setItem("uid", JSON.stringify(response.headers.uid));
        localStorage.setItem(
          "brow$3rAT",
          JSON.stringify(response.headers["access-token"])
        );

        props.setCurrentUser(response.data.user);
        setIsPaused(false);

        // dispatch(setCurrentUser(response.data.user));

        // if (
        //   response.data.user.sign_in_count === 1 ||
        //   response.data.user.is_subscribed === false
        // ) {
        //   setLogin(true);
        // setTimeout(() => {
        //   // props?.history.push("/user");
        //   props.history.push("/auth/subscriptions");
        // }, 4000);
        // window.location.replace("/auth/subscriptions");
        // props.history.push("/auth/subscriptions");
        // } else {
        setLogin(true);

        props.setLoginAnimationFalse(true);
        ReactGA.event({
          category: "Login",
          action: "SignIn",
          email: data.email,
        });
        // }
      })
      .catch((err) => {
        props.setAuthLoading(false);
        let str = "";
        if (err?.response?.status === 404) {
          // dispatch(setError(["error 404, not found"]));
          props.setErrors(["Invalid credentials"]);
        } else if (err?.response?.data?.errors) {
          Object.keys(err?.response?.data?.errors).forEach((key) => {
            let value = err?.response?.data?.errors[key];
            str = str?.concat(value + " ");
          });
          // dispatch(setError([str]));
          props.setErrors([str]);
        } else if (
          err?.response?.data?.message ===
          "Your 7 day free trial has been ended. Login denied."
        ) {
          // dispatch(setError([err?.response?.data?.message]))
          // props.setErrors([err?.response?.data?.message]);
          // console.log("errorrr---------->>>>",err?.response?.data?.message)
          setExpired(true)
          // props.dispatch(setErrors([err?.response?.data?.message]))
        } else {
          // props.dispatch(setErrors(["Something went wrong. Try again later"]))
          props.setErrors(["Something went wrong. Try again later"]);
          // dispatch(setError(["Something went wrong. Try again later"]));
        }
      });
  };
  const useStyles = makeStyles((theme) => ({}));
  const classes = useStyles();

  const handleNavigation = () => {
    if (props.user.sign_in_count === 1) {
      window.location.replace("/auth/subscriptions");
    } else {
      window.location.replace("/user");
    }
  };
  const [openAfterLoginAnimation, setOpenAfterLoginAnimation] =
    React.useState(false);

  const handleOpenAfterLoginAnimation = () => {
    setOpenAfterLoginAnimation(true);
  };

  const handleCloseAfterLoginAnimation = () => {
    setOpenAfterLoginAnimation(false);
  };
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [login, setLogin] = useState(false);
  const styless = {
    bounce: {
      animation: "100 5s",
      animationName: Radium.keyframes(fadeOutDown, "fadeOutDown"),
    },
    bounceImg: {
      animation: "100 5s",
      animationName: Radium.keyframes(slideOutDown, "slideOutDown"),
    },
    fadeImg: {
      animation: "100 5s",
      animationName: Radium.keyframes(fadeOutDown, "fadeOutDown"),
    },
    zoomInImg: {
      animation: "100 5s",
      animationName: Radium.keyframes(zoomIn, "zoomIn"),
    },
  };

  const reactivationReq = async () => {
    try {
      setLoading(true)
      const response = await axios.get(_default.BASE_URL + `/api/v1/reactivate_account?email=${encodeURIComponent(state.email)}`);
      localStorage.setItem(
        "brow$3rCt",
        JSON.stringify(response.headers.client)
      );
      localStorage.setItem("uid", JSON.stringify(response.headers.uid));
      localStorage.setItem(
        "brow$3rAT",
        JSON.stringify(response.headers["access-token"])
      );
      props.setCurrentUser(response.data.user);
      history.push("/auth/subscriptions")
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log({error})
    }
  }
  return (
    <StyleRoot>
      {animation && (
        <div
          className="w-100 h-100 position-absolute top-0 start-0"
          style={{
            zIndex: 1000,
            // background: "yellow",
          }}
        >
          <video
            height={"100%"}
            width={"100%"}
            autoPlay
            muted
            onEnded={() => {
              // props?.history.push("/user")
              props?.history.push("/auth/otp");
              setAnimation(false);
            }}
          >
            <source src={animationVideo} type="video/mp4" />
          </video>
        </div>
      )}

      {expired && (
        <AlertPopup
          btnText={"Yes"}
          cancelText={"No"}
          open={expired}
          type={"Warning"}
          heading={"Subscription"}
          message={
            "Your subscription has expired or your trial period is over.  You need to subscribe to continue using TagData.  Would you like to continue?"
          }
          isLoading={loading}
          handleClose={() => {
            store.dispatch(logout(history));
          }}
          handleClick={async() => {
            await reactivationReq()
          }}
        />
      )}

      <div
        id={"darkautofill"}
        onKeyPress={(e) => e.key === "Enter" && handleSubmit()}
      >
        <div
          style={
            login
              ? [styless.bounceImg, { marginTop: 190, flexDirection: "row" }]
              : {}
          }
        >
          <Box className="d-flex flex-column-reverse align-items-center text-center">
            <img
              src={NewLogo}
              height="90"
              style={login ? styless.fadeImg : {}}
            />
            {/* <img
              src={NewLogoTag}
              height="60"
              style={login ? [styless.bounceImg, styless.zoomInImg] : {}}
            /> */}
          </Box>
        </div>
        {/* <div>
        <Lottie
          options={defaultOptions}
          height={300}
          width={160}
          isStopped={isStopped}
          isPaused={isPaused}
          isClickToPauseDisabled={true}
        />
      </div> */}
        {/* {!openAfterLoginAnimation ? ( */}
        <div style={login ? styless.bounce : {}}>
          <Box mb={3} textAlign="center" className="loginAnimationWrapper">
            {/* <Typography variant="h5" component="h3" align="center">
              {"Sign In"}
            </Typography> */}
          </Box>
          {props?.authError ? (
            <Box mb={3}>
              <Typography variant="p" component="p" color="error">
                {props?.authError}
              </Typography>
            </Box>
          ) : null}
          {message && (
            <Box mb={3}>
              <Typography variant="p" component="p" color="error">
                {message}
              </Typography>
            </Box>
          )}
          <Box mb={3}>
            <FormInput
              id="email"
              onClick={() =>
                setFocus({ btn: false, password: false, email: true })
              }
              name="email"
              label="Email"
              variant="outlined"
              fullWidth={true}
              autoFocus={focus.email}
              margin="dense"
              required={true}
              autoComplete="false"
              onChange={handleChange}
              onKeyDown={handleKeyBack}
            />
          </Box>
          <Box mb={0}>
            <FormInput
              id="password"
              onClick={() =>
                setFocus({ btn: false, password: true, email: false })
              }
              name="password"
              label="Password"
              variant="outlined"
              autoFocus={focus.password}
              fullWidth={true}
              margin="dense"
              required={true}
              Icon={type.pwd === "text" ? VisibilityIcon : VisibilityOffIcon}
              iconClick={() =>
                setType({
                  ...type,
                  pwd: type.pwd === "password" ? "text" : "password",
                })
              }
              type={type.pwd}
              filled="false"
              onChange={handleChange}
            />
          </Box>
          <Box mb={2}>
            <Grid container direction="row" alignItems="center" mb>
              <Box flexGrow={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checkedA}
                      onChange={handleChange}
                      name="checkedA"
                      color="primary"
                    />
                  }
                  label="Stay logged in"
                />
              </Box>
              <Box flexGrow={1}>
                <Link to={"/auth/forgot-password"}>
                  <Typography
                    variant="p"
                    component="p"
                    align="right"
                    style={{ color: color.themeColorNew, fontWeight: "600" }}
                  >
                    {"Forgot Password"}
                  </Typography>
                </Link>
              </Box>
            </Grid>
          </Box>
          {props?.loading ? (
            <CustomButton
              type="submit"
              fullWidth={true}
              btnText={<CircularProgress size={20} color="#DDD" />}
              textAlign="center"
              display={"block"}
              marginBottom="18px"
              padding="16px 17px"
              background="#0042a8"
              hoverBg="#0042a8"
              border="0"
              borderRadius="8px"
              color="#fff"
            />
          ) : (
            <CustomButton
              type="submit"
              fullWidth={true}
              btnText="SIGN IN"
              textAlign="center"
              display={"block"}
              marginBottom="18px"
              padding="16px 17px"
              autoFocus={focus.btn}
              background="#0042a8"
              hoverBg="#0042a8"
              border="0"
              borderRadius="8px"
              color="#fff"
              // onClick={()=>setIsPaused(!isPaused)}
              onClick={handleSubmit}
            />
          )}
          {/* <Box flexGrow={1} mb={2}>
        <Typography
          variant="p"
          component="p"
          align="center"
          style={{ color: color.themeColorNew }}
        >
          {"- Or sign in with social media -"}
        </Typography>
      </Box>
      <Box justifyContent="center" align="center" mb={2}>
        <img src={google} alt="s" width={40} />
        <img src={facebook} alt="s" width={40} />
      </Box> */}
          <Box flexGrow={1} mb={2}>
            <Typography
              variant="p"
              component="p"
              align="center"
              style={{ color: color.themeColorNew }}
            >
              {"Don't have an account?" + " "}
              {
                <Link
                  to="/auth/register"
                  style={{
                    color: color.themeColorNew,
                    fontWeight: "600",
                    textDecoration: "none",
                  }}
                >
                  Sign up for free
                </Link>
              }
            </Typography>
          </Box>
        </div>
        {/* ) : (
        <AfterLoginAnimation
          open={openAfterLoginAnimation}
          handleClose={handleCloseAfterLoginAnimation}
        />
      )} */}
      </div>
    </StyleRoot>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    otp: (data, props) => dispatch(sendOtp(data, props)),
    login: (data, props) => dispatch(login(data, props)),
    resetErrorState: (msg) => dispatch(clearError(msg)),
    setErrors: (msg) => dispatch(setError(msg)),
    setAuthLoading: (bol) => dispatch(setAuthLoading(bol)),
    setCurrentUser: (res) => dispatch(setCurrentUser(res)),
    setLoginAnimationFalse: (e) => dispatch(setLoginAnimationFalse(e)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state?.auth?.loading,
    authError: state?.auth?.error,
    user: state?.auth?.user,
    loginAnimation: state.auth.loginAnimation,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
