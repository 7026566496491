
import _default from "../config/default";
import { store } from "../store/store";

export class DocumentService {
  constructor() {
    this.BASE_URL = _default.BASE_URL;
    this.AUTH_ENDPOINT = _default.ENDPOINT_AUTH;
  }

  add(data, callback) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/documents`,
      headers: {
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
      data: data,
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log({ progress });
        callback && callback(progress);
      },
    });
  }

  recentlyViewed() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/recently_viewed_items`,
      headers: {
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
        "Content-Type": "multipart/form-data",
      },
      method: "GET",
    });
  }

  update(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/documents/${data.docId}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  delete(id, password) {
    if (password) {
      return _default.Axios({
        url: `${this.BASE_URL}/api/v1/profiles/${
          store.getState().profile?.profileInfo?.profile?.id
        }/documents/${id}`,
        headers: {
          "Content-Type": "application/json",
          client: JSON.parse(localStorage.getItem("brow$3rCt")),
          uid: JSON.parse(localStorage.getItem("uid")),
          "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
        },
        params: { password: password },
        method: "DELETE",
      });
    } else {
      return _default.Axios({
        url: `${this.BASE_URL}/api/v1/profiles/${
          store.getState().profile?.profileInfo?.profile?.id
        }/documents/${id}`,
        headers: {
          "Content-Type": "application/json",
          client: JSON.parse(localStorage.getItem("brow$3rCt")),
          uid: JSON.parse(localStorage.getItem("uid")),
          "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
        },
        method: "DELETE",
      });
    }
  }

  deleteContact(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/contacts/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  index() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/documents`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  show(id, password) {
    if (password) {
      return _default.Axios({
        url: `${this.BASE_URL}/api/v1/profiles/${
          store.getState().profile?.profileInfo?.profile?.id
        }/documents/${id}`,
        headers: {
          "Content-Type": "application/json",
          client: JSON.parse(localStorage.getItem("brow$3rCt")),
          uid: JSON.parse(localStorage.getItem("uid")),
          "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
        },
        params: { password: password },
        method: "GET",
      });
    } else {
      return _default.Axios({
        url: `${this.BASE_URL}/api/v1/profiles/${
          store.getState().profile?.profileInfo?.profile?.id
        }/documents/${id}`,
        headers: {
          "Content-Type": "application/json",
          client: JSON.parse(localStorage.getItem("brow$3rCt")),
          uid: JSON.parse(localStorage.getItem("uid")),
          "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
        },
        method: "GET",
      });
    }
  }

  receivedShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/received_documents/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  received() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/received_documents`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  indexContact() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/contacts`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  resumeIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/reports/resume`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  resumeCreate(html) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/reports/resume`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: {
        html_string: html,
      },
    });
  }

  cashFlowCreate(html) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/reports/cash_flow`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: {
        html_string: html,
      },
    });
  }

  pateintRegistrationCreate(html) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/reports/patient_registration`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: {
        html_string: html,
      },
    });
  }

  cashFlowIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/reports/cash_flow`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  patientRegistrationIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/reports/patient_registration`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  contactsIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/reports/contacts`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  contactsCreate(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/contacts`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  contactsEdit(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/contacts/${id}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  contactsImport(fileName) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/imports`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: {
        file: fileName,
      },
    });
  }
}
