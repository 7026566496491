import debounce from "lodash.debounce";
import "date-fns";
import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import { dateFormat } from "../../../Utils/dateFormate.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import AddDocument from "../AddDocument/AddDocument";
import { HealthService } from "../../../services/health.service";
import ChooseWebLogins from "../ChooseWebLogins/ChooseWebLogins.js";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { getDateIgnoreTimezone } from "../../../Utils/dateHelper.js";

const useStyles = makeStyles(styles);

function AddWellness(props) {
  const classes = useStyles();
  const errRef = useRef(null);
  const personalService = new HealthService();
  const [err, setErr] = React.useState([]);
  const [doc, setDoc] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [reminder, setReminder] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [webLogin, setWebLogin] = React.useState(null);
  const [webLoginModal, setWebLoginModal] = React.useState(false);
  const [data, setData] = React.useState({
    primary_care_physician: "",
    race_ethnicity: "",
    blood_type: "",
    height: "",
    weight: "",
    ideal_weight: "",
    drinks_alcohol: false,
    tobacco: "",
    former_smoker: false,
    smokes_tobacco: false,
    years_smoking: "",
    alcohol: "",
    alcohol_frequency: "",
    quit_smoking_on: "",
    tobacco_frequency_past: "",
    pregnancy_type: "",
    pregnant: false,
    birth_control_type: "",
    number_of_partners: "",
    birth_control: false,
    hormone_therapy: false,
    hormone_therapy_type: "",
    sexually_active: false,
    sexual_preference: "",
    concerned_about_hiv_exposure: false,
    notes: "",
    attachments: "",
    associated_logins_attributes: [
      {
        web_login_id: null,
      },
    ],
    associated_documents_attributes: [],
  });
  const [ifYes, setIfYes] = React.useState(false);
  const [documentModal, setDocumentModal] = useState(false);

  const handleDocumentModal = () => {
    setDocumentModal(true);
  };

  const timing = [
    { show: "1 week from expiration", value: "one_week" },
    { show: "2 weeks from expiration", value: "two_weeks" },
    { show: "1 month from expiration", value: "one_month" },
    { show: "3 months from expiration", value: "three_months" },
    { show: "6 months from expiration", value: "six_months" },
  ];

  const frequancy = [
    { show: "Daily", value: "daily" },
    { show: "Every other Day", value: "every_other_day" },
    { show: "Weekly", value: "weekly" },
    { show: "Biweekly", value: "bi_weekly" },
  ];

  const handleSubmit = () => {
    let formData = new FormData();
    formData.append("primary_care_physician", data.primary_care_physician);
    formData.append("race_ethnicity", data.race_ethnicity);
    formData.append("blood_type", data.blood_type);
    formData.append("height", data.height);
    formData.append("weight", data.weight);
    formData.append("ideal_weight", data.ideal_weight);
    formData.append("drinks_alcohol", data.drinks_alcohol);
    formData.append("tobacco", data.tobacco);
    formData.append("former_smoker", data.former_smoker);
    formData.append("smokes_tobacco", data.smokes_tobacco);
    formData.append("years_smoking", data.years_smoking);
    formData.append("alcohol", data.alcohol);
    formData.append("alcohol_frequency", data.alcohol_frequency);
    formData.append("quit_smoking_on", data.quit_smoking_on);
    formData.append("tobacco_frequency_past", data.tobacco_frequency_past);
    formData.append("tobacco_frequency", data.tobacco_frequency);
    formData.append("pregnancy_type", data.pregnancy_type);
    formData.append("pregnant", data.pregnant);
    formData.append("birth_control_type", data.birth_control_type);
    formData.append("number_of_partners", data.number_of_partners);
    formData.append("birth_control", data.birth_control);
    formData.append("hormone_therapy", data.hormone_therapy);
    formData.append("hormone_therapy_type", data.hormone_therapy_type);
    formData.append("sexually_active", data.sexually_active);
    formData.append("sexual_preference", data.sexual_preference);
    if (data.associated_logins_attributes[0].web_login_id) {
      formData.append(
        "associated_logins_attributes[][web_login_id]",
        JSON.stringify(data.associated_logins_attributes[0].web_login_id)
      );
    }
    formData.append(
      "concerned_about_hiv_exposure",
      data.concerned_about_hiv_exposure
    );
    formData.append("notes", data.notes);

    setIsLoading(true);
    if (data.associated_documents_attributes?.length > 0) {
      for (let i = 0; i < data.associated_documents_attributes?.length; i++) {
        formData.append(
          "associated_documents_attributes[][document_id]",
          data.associated_documents_attributes[i]?.document_id ||
            data.associated_documents_attributes[i]?.id
        );
      }
    }
    personalService
      .createWellnesses(formData)
      .then((response) => {
        setIsLoading(false);
        props.forceRerender();
        props.handleClose();

        console.log(response.data);
      })
      .catch((err) => {
        errRef.current.scrollIntoView();
        setIsLoading(false);
        if (err.response && err.response.data.message) {
            let array = [];
            Object.keys(err.response.data.message).forEach((key) =>
              array.push(
                key === "height"
                  ? `Height ${err.response.data.message[key]}`
                  : key === "weight"
                  ? `Weight ${err.response.data.message[key]}`
                  : key === "ideal_weight"
                  ? `Ideal Weight ${err.response.data.message[key]}`
                  : `${key} ${err.response.data.message[key]}`
              )
            );
            setErr(array);
        } else {
          setErr(["Internal Server Error"]);
        }
      });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = debounce((event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  }, 300);

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Add New Wellness"}
          color={color.white}
        />
        <div className={classes.modalBody} id={"err"}>
          {documentModal && (
            <AddDocument
              setData={setData}
              setDoc={setDoc}
              associated_documents_attributes={false}
              doc={doc}
              data={data}
              open={documentModal}
              handleClose={() => setDocumentModal(false)}
              closeModal={() => setDocumentModal(false)}
              category={"Personal"}
            />
          )}
          {webLoginModal && (
            <ChooseWebLogins
              setData={setData}
              setWebLogin={setWebLogin}
              data={data}
              open={webLoginModal}
              handleClose={() => setWebLoginModal(false)}
              closeModal={() => setWebLoginModal(false)}
              category={"Personal"}
            />
          )}
          <div ref={errRef} className="w-100 d-flex flex-row mb-4"  id={"err"}>
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Health > Wellness > New"}
            </Typography>
          </div>
          {/* {err && (
            <div className="w-100 d-flex flex-row mb-4">
              <Typography
                variant="p"
                component="p"
                align="left"
                style={{ color: "red", fontWeight: "normal" }}
              >
                {err}
              </Typography>
            </div>
          )} */}
          {err?.length > 0 && (
            <div className="mb-4">
              {err?.map((val, key) => {
                return (
                  <div className="w-100 d-flex flex-row mb-1">
                    <Typography
                      key={key}
                      variant="p"
                      component="p"
                      align="left"
                      style={{
                        color: "red",
                        fontWeight: "normal",
                        marginBottom: "2px",
                      }}
                    >
                      {val.charAt(0).toUpperCase() + val.slice(1)}
                    </Typography>
                  </div>
                );
              })}
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Primary Care Physician*"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="primary_care_physician"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Primary Care Physician"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Race or Ethnicity*"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  // value={data?.blood_type}
                  defaultValue={"Select Race Or Ethnicity"}
                  onChange={handleChange}
                  name="race_ethnicity"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="Select Race Or Ethnicity">
                    Select Race Or Ethnicity
                  </MenuItem>
                  <MenuItem value="American Indian or Alaska Native">
                    American Indian or Alaska Native
                  </MenuItem>
                  <MenuItem value="Asian">Asian</MenuItem>
                  <MenuItem value="Black or African American">
                    Black or African American
                  </MenuItem>
                  <MenuItem value="Hispanic or Latino">
                    Hispanic or Latino
                  </MenuItem>
                  <MenuItem value="Native Hawaiian or Other Pacific Islander">
                    Native Hawaiian or Other Pacific Islander
                  </MenuItem>
                  <MenuItem value="White">White</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Blood Type"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="blood_type"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  // value={data?.blood_type}
                  defaultValue={"Select Blood Type"}
                  onChange={handleChange}
                  name="blood_type"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="Select Blood Type">
                    Select Blood Type
                  </MenuItem>
                  <MenuItem value="O+">O+</MenuItem>
                  <MenuItem value="A+">A+</MenuItem>
                  <MenuItem value="B+">B+</MenuItem>
                  <MenuItem value="AB+">AB+</MenuItem>
                  <MenuItem value="O-">O-</MenuItem>
                  <MenuItem value="A-">A-</MenuItem>
                  <MenuItem value="B-">B-</MenuItem>
                  <MenuItem value="AB-">AB-</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Height"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="height"
                onChange={handleChange}
                label=""
                type="number"
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Height eg:(ft.in)"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Weight"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="weight"
                name="weight"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                type="number"
                margin="dense"
                required={true}
                placeholder={"Weight"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Ideal Weight"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="ideal_weight"
                name="ideal_weight"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                type="number"
                margin="dense"
                required={true}
                placeholder={"Ideal weight"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Do You Smoke Tobacco?"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="tobacco"
                  name="smokes_tobacco"
                  value={data?.smokes_tobacco}
                  onChange={(e) =>
                    setData({
                      ...data,
                      smokes_tobacco: e.target.value === "true" ? true : false,
                    })
                  }
                  className="flex-row"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {data.smokes_tobacco ? (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Tobacco"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl id="id_type1" fullWidth={true}>
                    <Select
                      name="tobacco"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      required={true}
                      defaultValue={"Select Tobacco"}
                      // value={data?.blood_type}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="Select Tobacco">
                        Select Tobacco
                      </MenuItem>
                      <MenuItem value="Chewing Tobacco">
                        Chewing Tobacco
                      </MenuItem>
                      <MenuItem value="Cigar">Cigar</MenuItem>
                      <MenuItem value="Cigarette">Cigarette</MenuItem>
                      <MenuItem value="Snuff">Snuff</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Packs Per Day"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <FormControl id="id_type1" fullWidth={true}>
                    <Select
                      id="tobacco"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      required={true}
                      // value={data?.blood_type}
                      onChange={handleChange}
                      defaultValue={"Select Packs per Day"}
                      name="tobacco_frequency"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="Select Packs per Day">
                        Select Packs per Day
                      </MenuItem>
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            ) : null}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Are You a Former Smoker?"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="former_smoker"
                  name="former_smoker"
                  value={data?.former_smoker}
                  onChange={(e) =>
                    setData({
                      ...data,
                      former_smoker: e.target.value === "true" ? true : false,
                    })
                  }
                  className="flex-row"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {data.former_smoker ? (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Quit Smoking Date"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      fullWidth={true}
                      margin="dense"
                      required={true}
                      inputVariant="outlined"
                      format={dateFormat()}
                      id="start_date"
                      placeholder={dateFormat()?.toUpperCase()}
                      value={data?.quit_smoking_on}
                      onChange={(date) => {
                        setData({
                          ...data,
                          quit_smoking_on: getDateIgnoreTimezone(date)
                            .toISOString()
                            .slice(0, 10),
                        });
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Packs Per Day (Past)"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <FormControl id="id_type1" fullWidth={true}>
                    <Select
                      id="tobacco"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      required={true}
                      // value={data?.blood_type}
                      onChange={handleChange}
                      defaultValue={"Select Packs per Day"}
                      name="tobacco_frequency_past"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="Select Packs per Day">
                        Select Packs per Day
                      </MenuItem>
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            ) : null}
            {/* {data.former_smoker || data.smokes_tobacco ? (
              <Grid item xs={12} sm={12} md={12} className="pb-0">
                <Typography
                  variant="h6"
                  component="h3"
                  style={{ color: color.themeColorNew }}
                >
                  {"Number of Years Smoking"}
                </Typography>
              </Grid>
            ) : null}
            {data.former_smoker || data.smokes_tobacco ? (
              <Grid item xs={12} sm={12} md={12}>
                <FormInput
                  id="years_smoking"
                  name="years_smoking"
                  onChange={handleChange}
                  label=""
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  placeholder={"Number of Years Smoking"}
                />
              </Grid>
            ) : null} */}
            {data.former_smoker || data.smokes_tobacco ? (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Number of Years Smoking"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormInput
                    id="years_smoking"
                    name="years_smoking"
                    onChange={handleChange}
                    label=""
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    placeholder={"Number of Years Smoking"}
                  />
                </Grid>
              </>
            ) : null}

            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Do You Drink Alcohol?"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="alcohol"
                  name="drinks_alcohol"
                  value={data?.drinks_alcohol}
                  onChange={(e) =>
                    setData({
                      ...data,
                      drinks_alcohol: e.target.value === "true" ? true : false,
                    })
                  }
                  className="flex-row"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {data.drinks_alcohol ? (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Alcohol"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl id="id_type1" fullWidth={true}>
                    <Select
                      id="alcohol"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      required={true}
                      // value={data?.blood_type}
                      onChange={handleChange}
                      defaultValue={"Select Alcohol"}
                      name="alcohol"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="Select Alcohol">
                        Select Alcohol
                      </MenuItem>
                      <MenuItem value="Beer">Beer</MenuItem>
                      <MenuItem value="Liquor">Liquor</MenuItem>
                      <MenuItem value="Wine">Wine</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Average Alcohol Consumption"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <FormControl id="id_type1" fullWidth={true}>
                    <Select
                      id="tobacco"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      required={true}
                      onChange={handleChange}
                      defaultValue={"Select Average Alcohol Consumption"}
                      name="alcohol_frequency"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem
                        disabled
                        value="Select Average Alcohol Consumption"
                      >
                        Select Average Alcohol Consumption
                      </MenuItem>
                      <MenuItem value="1">3 or more drinks per day</MenuItem>
                      <MenuItem value="2">1-2 drinks per day</MenuItem>
                      <MenuItem value="3">4-5 drinks per week</MenuItem>
                      <MenuItem value="4">2-3 drinks per week</MenuItem>
                      <MenuItem value="5">2-3 drinks per month</MenuItem>
                      <MenuItem value="5">None</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            ) : null}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Pregnant?"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="pregnant"
                  name="pregnant"
                  value={data?.pregnant}
                  onChange={(e) =>
                    setData({
                      ...data,
                      pregnant: e.target.value === "true" ? true : false,
                    })
                  }
                  className="flex-row"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {data.pregnant ? (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Pregnancy type"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="tobacco"
                      name="pregnancy_type"
                      value={data?.pregnancy_type}
                      onChange={handleChange}
                      className="flex-row"
                    >
                      <FormControlLabel
                        value="pediatrician"
                        control={<Radio style={{ color: "#ffb722" }} />}
                        label="Pediatrician"
                      />
                      <FormControlLabel
                        value="insemination"
                        control={<Radio style={{ color: "#ffb722" }} />}
                        label="Insemination"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </>
            ) : null}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Birth control"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="birth_control"
                  name="birth_control"
                  value={data?.birth_control}
                  onChange={(e) =>
                    setData({
                      ...data,
                      birth_control: e.target.value === "true" ? true : false,
                    })
                  }
                  className="flex-row"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {data.birth_control ? (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Birth Control Type"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormInput
                    id="birth_control_type"
                    name="birth_control_type"
                    onChange={handleChange}
                    label=""
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    placeholder={"Birth Control Type"}
                  />
                </Grid>
              </>
            ) : null}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Sexually Active?"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="sexually_active"
                  name="sexually_active"
                  value={data?.sexually_active}
                  onChange={(e) =>
                    setData({
                      ...data,
                      sexually_active: e.target.value === "true" ? true : false,
                    })
                  }
                  className="flex-row"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {data.sexually_active ? (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {
                      "How Many Partners Have You Had During The Past 12 Months?"
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormInput
                    id="number_of_partners"
                    name="number_of_partners"
                    onChange={handleChange}
                    label=""
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    placeholder={
                      "How Many Partners Have You Had During The Past 12 Months?"
                    }
                  />
                </Grid>
              </>
            ) : null}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Sexual Preference"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl id="id_type1" fullWidth={true}>
                <Select
                  id="sexual_preference"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  // value={data?.blood_type}
                  onChange={handleChange}
                  defaultValue={"Select Sexual Preference"}
                  name="sexual_preference"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="Select Sexual Preference">
                    Select Sexual Preference
                  </MenuItem>
                  <MenuItem value="Men">Men</MenuItem>
                  <MenuItem value="Women">Women</MenuItem>
                  <MenuItem value="Both">Both</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Are You Concerned That You May Have Been Exposed to HIV?"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="concerned_about_hiv_exposure"
                  name="concerned_about_hiv_exposure"
                  value={data?.concerned_about_hiv_exposure}
                  onChange={(e) =>
                    setData({
                      ...data,
                      concerned_about_hiv_exposure:
                        e.target.value === "true" ? true : false,
                    })
                  }
                  className="flex-row"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Hormone therapy"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="hormone_therapy"
                  name="hormone_therapy"
                  value={data?.hormone_therapy}
                  onChange={(e) =>
                    setData({
                      ...data,
                      hormone_therapy: e.target.value === "true" ? true : false,
                    })
                  }
                  className="flex-row"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {data.hormone_therapy ? (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Hormone Therapy Type"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormInput
                    id="hormone_therapy_type"
                    name="hormone_therapy_type"
                    onChange={handleChange}
                    label=""
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    placeholder={"Hormone Therapy Type"}
                  />
                </Grid>
              </>
            ) : null}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Notes"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="encrypted_notes"
                name="notes"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Notes"}
              />
            </Grid>
            {/* {reminder && (
              <div className={"w-75 m-2 border m-5"}>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Start Date"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} sm={8}>
                  <FormControl id="security-question" fullWidth={true}>
                    <Select
                      id="id_type"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      required={true}
                      onChange={(e) => {
                        setData({
                          ...data,
                          alert_attributes: {
                            ...data.alert_attributes,
                            timing: e.target.value,
                          },
                        });
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {timing.map((t) => {
                        return <MenuItem value={t.value}>{t.show}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Frequency"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} sm={8}>
                  <FormControl id="security-question" fullWidth={true}>
                    <Select
                      id="id_type"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      required={true}
                      onChange={(e) => {
                        setData({
                          ...data,
                          alert_attributes: {
                            ...data.alert_attributes,
                            frequency: e.target.value,
                          },
                        });
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {frequancy.map((f) => {
                        return <MenuItem value={f.value}>{f.show}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <CustomButton
                    textAlign="center"
                    display={"block"}
                    padding="8px 25px"
                    margin={"5px"}
                    background="#0042a8"
                    hoverBg="#0042a8"
                    border="0"
                    borderRadius="8px"
                    color="#fff"
                    btnText={"Remove"}
                    onClick={() => {
                      setData({
                        ...data,
                        alert_attributes: {
                          ...data.alert_attributes,
                          status: 'inactive',
                        },
                      });
                      setReminder(false);
                    }}
                  />
                </Grid>
              </div>
            )} */}
            {webLogin && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Web Login"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {webLogin?.user_name}
                  </Typography>
                  <CustomButton
                    btnText={"Remove"}
                    textAlign="center"
                    display={"block"}
                    background="transparent"
                    hoverBg="#0042a8"
                    border="1px solid #0042a8"
                    borderRadius="8px"
                    color="#0042a8"
                    onClick={() => {
                      setWebLogin(null);
                      setData({
                        ...data,
                        associated_logins_attributes: [
                          {
                            web_login_id: null,
                          },
                        ],
                      });
                    }}
                  />
                </Grid>
              </>
            )}

            {doc.length > 0 && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Document"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                  {doc.map((e, k) => (
                    <>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                      >
                        {e?.title}
                      </Typography>
                      <CustomButton
                        btnText={"Remove"}
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          let newDoc = doc?.filter((d) => d.id !== e.id);
                          setDoc(newDoc);
                          setData({
                            ...data,
                            associated_documents_attributes: newDoc,
                          });
                        }}
                      />
                    </>
                  ))}
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              {!webLogin && (
                <CustomButton
                  className="mb-sm-2"
                  btnText="Add Web Login"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  marginRight={"10px"}
                  onClick={() => setWebLoginModal(true)}
                />
              )}
              <CustomButton
                btnText="Add Document"
                className="mb-sm-2"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
                marginRight={"10px"}
                onClick={handleDocumentModal}
              />
              <a
                ref={errRef}
                href={"#err"}
                style={{
                  display: "none",
                }}
              >
                click
              </a>
              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  disabled={
                    !data.primary_care_physician || !data.race_ethnicity
                  }
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  onClick={handleSubmit}
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddWellness);
