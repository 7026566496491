import "date-fns";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import styles from "../../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { CheckCircle, ErrorRounded, WarningRounded } from "@material-ui/icons";
import CustomModal from "../../../../components/Modal/Modal.js";
import ModalHeader from "../../../../components/Modal/ModalHeader.js";
import { CustomButton } from "../../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import color from "../../../../assets/css/color";

const useStyles = makeStyles(styles);

function AlertPopup({
  open,
  handleClose,
  handleClick,
  type,
  heading,
  message,
  btnText,
  cancelText,
  isLoading,
}) {
  const classes = useStyles();

  return (
    <>
      <CustomModal
        visible={open}
        onClose={handleClose}
        width="35%"
        margin="auto 40px"
        inset="15px!important"
      >
        <ModalHeader
          extraclass="p-2"
          headerBgColor={
            type === "Error"
              ? color.themeRed
              : type === "Warning"
              ? color.themeColorNew
              : color.themeGreen
          }
          color="white"
        />
        <div className={classes.modalBody}>
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            className="px-4"
          >
            <div className="w-100 text-center">
              <div className="d-flex justify-content-center align-items-center">
                {type === "Error" ? (
                  <WarningRounded
                    className="me-2"
                    style={{ color: color.themeRed, fontSize: "50px" }}
                  />
                ) : type === "Warning" ? (
                  <ErrorRounded
                    className="me-2"
                    style={{ color: color.themeYellow, fontSize: "50px" }}
                  />
                ) : (
                  <CheckCircle
                    className="me-2"
                    style={{ color: color.themeGreen, fontSize: "50px" }}
                  />
                )}
                <Typography
                  variant="h6"
                  component="h3"
                  className="mb-0"
                  style={{ color: "#060d5c", fontWeight: "bold" }}
                >
                  {heading}
                </Typography>
              </div>
              <Typography
                variant="h6"
                component="h3"
                align="center"
                className="mb-4"
                style={{ color: "#060d5c", fontWeight: "normal" }}
              >
                {message}
              </Typography>
            </div>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              <>
                {btnText && (
                  <>
                    {!isLoading ? (
                      <CustomButton
                        className="me-0 me-sm-2 mb-2 mb-sm-0"
                        btnText={btnText}
                        textAlign="center"
                        display={"block"}
                        padding="8px 25px"
                        background={
                          type === "Error"
                            ? "#0042a8"
                            : type === "Warning"
                            ? "#0042a8"
                            : "#4DB500"
                        }
                        hoverBg={
                          type === "Error"
                            ? "#0042a8"
                            : type === "Warning"
                            ? "#0042a8"
                            : "#4DB500"
                        }
                        border={
                          type === "Error"
                            ? "2px solid #0042a8"
                            : type === "Warning"
                            ? "2px solid #0042a8"
                            : "2px solid #4DB500"
                        }
                        borderRadius="8px"
                        color={"#fff"}
                        marginRight={"10px"}
                        width="100px"
                        onClick={handleClick}
                      />
                    ) : (
                      <CustomButton
                        btnText={<CircularProgress size={20} color="#DDD" />}
                        type="submit"
                        textAlign="center"
                        display={"block"}
                        padding="8px 25px"
                        background={
                          type === "Error"
                            ? "#0042a8"
                            : type === "Warning"
                            ? "#0042a8"
                            : "#4DB500"
                        }
                        hoverBg={
                          type === "Error"
                            ? "#0042a8"
                            : type === "Warning"
                            ? "#0042a8"
                            : "#4DB500"
                        }
                        borderRadius="8px"
                        width="100px"
                      />
                    )}
                  </>
                )}
              </>
              {cancelText && (
                <CustomButton
                  btnText={cancelText}
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background={"transparent"}
                  hoverBg={
                    type === "Error"
                      ? "#0042a8"
                      : type === "Warning"
                      ? "#0042a8"
                      : "#4DB500"
                  }
                  border={
                    type === "Error"
                      ? "2px solid #0042a8"
                      : type === "Warning"
                      ? "2px solid #0042a8"
                      : "2px solid #4DB500"
                  }
                  borderRadius="8px"
                  color={
                    type === "Error"
                      ? "#0042a8"
                      : type === "Warning"
                      ? "#0042a8"
                      : "#4DB500"
                  }
                  // marginRight={"10px"}
                  width="100px"
                  onClick={handleClose}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

export default AlertPopup;
