import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import CustomModal from "../../../components/Modal/Modal";
import color from "../../../assets/css/color";
import ProfileImageUpload from "../../../components/ProfileImageUploader/ProfileImageUpload.js";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  create,
  index,
  setProfile,
  setProfileError,
  setProfileLoading,
  update,
} from "../../../store/actions/profileAction.js";
import { connect } from "react-redux";
import { ProfileService } from "../../../services/profile.service";

const useStyles = makeStyles(styles);
const AddProfile = (props) => {
  const profileService = new ProfileService();
  const classes = useStyles();
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);

  const [info, setInfo] = React.useState({
    prefix: "",
    suffix: "",
    firstName: "",
    lastName: "",
    gender: "",
    profile_type: "person",
    business_name: "",
    date_of_birth: "",
    attachment: "",
  });

  useEffect(() => {
    props.setProfileError(null);
    if (props?.count?.sign_in_count <= 1) {
      setInfo({
        ...info,
        firstName: props?.profile?.profile?.first_name,
        lastName: props?.profile?.profile?.last_name,
      });
    }
  }, []);

  const handleSubmit = () => {
    props.setProfileLoading(true);
    props.setProfileError(null);
    let formData = new FormData();
    info.profile_type === "person" && formData.append("prefix", info.prefix);
    info.profile_type === "person" && formData.append("suffix", info.suffix);
    info.profile_type === "person" &&
      formData.append("first_name", info.firstName);
    info.profile_type === "person" &&
      formData.append("last_name", info.lastName);
    info.profile_type === "person" &&
      formData.append("gender", info?.gender?.toLowerCase());
    info.profile_type === "person" &&
      info.date_of_birth &&
      formData.append("date_of_birth", info?.date_of_birth);
    info.attachment && formData.append("attachment", info.attachment);
    info.profile_type === "business" &&
      info.business_name &&
      formData.append("business_name", info.business_name);
    formData.append("profile_type", info.profile_type?.toLowerCase());
    profileService
      .create(formData)
      .then((res) => {
        if (!res?.data) {
          props.setProfileLoading(false);
          props.setProfileError("Something went wrong. Try again later");
        } else {
          props.setProfileError(null);
          props.setProfileLoading(false);
          props.setProfile(res?.data);
          props.index();
          props.handleCloseAddProfile();
        }
      })
      .catch((err) => {
        props.setProfileLoading(false);
        if (err?.response?.data) {
          props.setProfileError(
            err?.response?.data?.errors
              ? err?.response?.data?.errors.full_messages
              : err?.response?.data?.message?.profile[0]
          );
        } else {
          props.setProfileError("Something went wrong. Try again later");
        }
      });
    // store.dispatch(create(data));
  };

  const handleUpdate = () => {
    let formData = new FormData();
    info.profile_type === "person" && formData.append("prefix", info.prefix);
    info.profile_type === "person" && formData.append("suffix", info.suffix);
    info.profile_type === "person" &&
      formData.append("first_name", info.firstName);
    info.profile_type === "person" &&
      formData.append("last_name", info.lastName);
    info.profile_type === "person" &&
      formData.append("gender", info?.gender?.toLowerCase());
    info.profile_type === "person" &&
      info.date_of_birth &&
      formData.append("date_of_birth", info?.date_of_birth);
    info.profile_type === "business" &&
      info.business_name &&
      formData.append("business_name", info.business_name);
    formData.append("profile_type", info.profile_type?.toLowerCase());
    info.attachment && formData.append("attachment", info.attachment);
    props.update(
      formData,
      props?.profile?.profile?.id,
      props?.handleCloseAddProfile
    );
    // store.dispatch(create(data));
  };

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      setInfo({ ...info, attachment: file });
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <CustomModal
      visible={props.open}
      // onClose={props?.handleCloseAddProfile}
      width="35%"
      inset="15px!important"
    >
      {console.log('props?.count?.sign_in_count',props?.count?.sign_in_count)}
      {props?.count?.sign_in_count <= 1 ? (
        <div className={classes.modalBody}>
          <Typography
            variant="h4"
            component="h2"
            align="center"
            style={{
              color: color.themeColorNew,
              fontWeight: "bold",
              marginBottom: "25px",
            }}
          >
            {"Let's Get Started!"}
          </Typography>
          <ProfileImageUpload
            setInfo={setInfo}
            info={info}
            {...props}
            add={true}
          />

          {props.profileError && (
            <div className="w-100 d-flex flex-row mb-5">
              <Typography
                variant="p"
                component="p"
                align="left"
                style={{ color: "red", fontWeight: "normal" }}
              >
                {props.profileError}
              </Typography>
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12} md={6}>
              <InputLabel style={{ marginBottom: "7px", marginTop: "-4px" }}>
                {"Profile Type"}
              </InputLabel>
              <FormControl
                id="security-question"
                className={classes.selectGender}
                fullWidth={true}
              >
                <Select
                  id="select-gender"
                  label="Profile Type"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  defaultValue={"person"}
                  onChange={(event) =>
                    setInfo({ ...info, profile_type: event.target.value })
                  }
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="person">Personal</MenuItem>
                  <MenuItem value="business">Business</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {info.profile_type === "person" && (
              <>
                <Grid item xs={12} md={6}>
                  <FormInput
                    id="prefix"
                    label="Prefix"
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    name="prefix"
                    fieldClassName={classes.profileInputs}
                    onChange={(event) =>
                      setInfo({ ...info, prefix: event.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormInput
                    id="first-name"
                    label="First Name*"
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    name="firstName"
                    autoComplete="name"
                    fieldClassName={classes.profileInputs}
                    value={props?.profile?.profile?.first_name}
                    onChange={(event) =>
                      setInfo({ ...info, firstName: event.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormInput
                    id="last-name"
                    label="Last Name*"
                    variant="outlined"
                    fullWidth={true}
                    value={props?.profile?.profile?.last_name}
                    margin="dense"
                    required={true}
                    name="lastName"
                    autoComplete="name"
                    fieldClassName={classes.profileInputs}
                    onChange={(event) =>
                      setInfo({ ...info, lastName: event.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormInput
                    id="suffix"
                    label="Suffix"
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    name="suffix"
                    fieldClassName={classes.profileInputs}
                    onChange={(event) =>
                      setInfo({ ...info, suffix: event.target.value })
                    }
                  />
                </Grid>
              </>
            )}

            {info.profile_type === "business" && (
              <Grid item xs={12} md={6}>
                <FormInput
                  id="suffix"
                  label="Business Name"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  name="business_name"
                  fieldClassName={classes.profileInputs}
                  onChange={(event) =>
                    setInfo({ ...info, business_name: event.target.value })
                  }
                />
              </Grid>
            )}
            {info.profile_type === "person" && (
              <>
                <Grid item xs={12} md={6}>
                  <InputLabel
                    style={{ marginBottom: "7px", marginTop: "-4px" }}
                  >
                    {"Select Gender"}
                  </InputLabel>
                  <FormControl
                    id="security-question"
                    className={classes.selectGender}
                    fullWidth={true}
                  >
                    <Select
                      id="select-gender"
                      label="Select Gender"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      required={true}
                      onChange={(event) =>
                        setInfo({ ...info, gender: event.target.value })
                      }
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormInput
                    id="date-of-birth"
                    label="Date of Birth"
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    type="date"
                    fieldClassName={classes.profileInputs}
                    placeholder={"Ex. 12/01/1992"}
                    onChange={(event) =>
                      setInfo({
                        ...info,
                        date_of_birth: new Date(event.target.value)
                          .toISOString()
                          .slice(0, 10),
                      })
                    }
                  />
                </Grid>
              </>
            )}
            {info.profile_type === "person" && (
              <Grid item xs={12} md={6} style={{ visibility: "hidden" }}>
                <FormInput
                  id="date-of-birth"
                  label="Date of Birth"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  type="date"
                  fieldClassName={classes.profileInputs}
                  onChange={(event) =>
                    setInfo({
                      ...info,
                      date_of_birth: new Date(event.target.value)
                        .toISOString()
                        .slice(0, 10),
                    })
                  }
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              {props.loading ? (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  padding="15px 25px"
                  fullWidth={true}
                  height="50px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  color="#fff"
                />
              ) : (
                <CustomButton
                  btnText={"SAVE"}
                  padding="15px 25px"
                  fullWidth={true}
                  height="50px"
                  disabled={
                    info.profile_type === "person"
                      ? !info.firstName && !info.lastName
                      : !info.business_name
                  }
                  background="#0042a8"
                  hoverBg="#0042a8"
                  color="#fff"
                  onClick={handleUpdate}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                onClick={props.closeModal}
                variant="p"
                component="p"
                align="left"
                style={{
                  color: color.themeYellow,
                  fontWeight: "bold",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {"I'll fill this out later"}
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}></Grid>
          </Grid>
        </div>
      ) : (
        <div className={classes.modalBody}>
          <Typography
            variant="h4"
            component="h2"
            align="center"
            style={{
              color: color.themeColorNew,
              fontWeight: "bold",
              marginBottom: "25px",
            }}
          >
            {"Let's Get Started!"}
          </Typography>
          <ProfileImageUpload
            setInfo={setInfo}
            info={info}
            {...props}
            add={true}
          />

          {props.profileError && (
            <div className="w-100 d-flex flex-row mb-5">
              <Typography
                variant="p"
                component="p"
                align="left"
                style={{ color: "red", fontWeight: "normal" }}
              >
                {props.profileError}
              </Typography>
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12} md={6}>
              <InputLabel style={{ marginBottom: "7px", marginTop: "-4px" }}>
                {"Profile Type"}
              </InputLabel>
              <FormControl
                id="security-question"
                className={classes.selectGender}
                fullWidth={true}
              >
                <Select
                  id="select-gender"
                  label="Profile Type"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  defaultValue={"person"}
                  onChange={(event) =>
                    setInfo({ ...info, profile_type: event.target.value })
                  }
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="person">Personal</MenuItem>
                  <MenuItem value="business">Business</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {info.profile_type === "person" && (
              <>
                <Grid item xs={12} md={6}>
                  <FormInput
                    id="prefix"
                    label="Prefix"
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    name="prefix"
                    fieldClassName={classes.profileInputs}
                    onChange={(event) =>
                      setInfo({ ...info, prefix: event.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormInput
                    id="first-name"
                    label="First Name*"
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    name="firstName"
                    autoComplete="name"
                    fieldClassName={classes.profileInputs}
                    onChange={(event) =>
                      setInfo({ ...info, firstName: event.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormInput
                    id="last-name"
                    label="Last Name*"
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    fieldClassName={classes.profileInputs}
                    name="lastName"
                    autoComplete="name"
                    onChange={(event) =>
                      setInfo({ ...info, lastName: event.target.value })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormInput
                    id="suffix"
                    label="Suffix"
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    name="suffix"
                    fieldClassName={classes.profileInputs}
                    onChange={(event) =>
                      setInfo({ ...info, suffix: event.target.value })
                    }
                  />
                </Grid>
              </>
            )}

            {info.profile_type === "business" && (
              <Grid item xs={12} md={6}>
                <FormInput
                  id="suffix"
                  label="Business Name"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  name="business_name"
                  fieldClassName={classes.profileInputs}
                  onChange={(event) =>
                    setInfo({ ...info, business_name: event.target.value })
                  }
                />
              </Grid>
            )}
            <>
              {info.profile_type === "person" && (
                <>
                  <Grid item xs={12} md={6}>
                    <InputLabel
                      style={{ marginBottom: "7px", marginTop: "-4px" }}
                    >
                      {"Select Gender"}
                    </InputLabel>
                    <FormControl
                      id="security-question"
                      className={classes.selectGender}
                      fullWidth={true}
                    >
                      <Select
                        id="select-gender"
                        label="Select Gender"
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                        required={true}
                        onChange={(event) =>
                          setInfo({ ...info, gender: event.target.value })
                        }
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormInput
                      id="date-of-birth"
                      label="Date of Birth"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      required={true}
                      type="date"
                      fieldClassName={classes.profileInputs}
                      onChange={(event) =>
                        setInfo({
                          ...info,
                          date_of_birth: new Date(event.target.value)
                            .toISOString()
                            .slice(0, 10),
                        })
                      }
                    />
                  </Grid>
                </>
              )}
            </>

            {info.profile_type === "person" && (
              <Grid item xs={12} md={6} style={{ visibility: "hidden" }}>
                <FormInput
                  id="date-of-birth"
                  label="Date of Birth"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  type="date"
                  fieldClassName={classes.profileInputs}
                  onChange={(event) =>
                    setInfo({
                      ...info,
                      date_of_birth: new Date(event.target.value)
                        .toISOString()
                        .slice(0, 10),
                    })
                  }
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              {props.loading ? (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  padding="15px 25px"
                  fullWidth={true}
                  height="50px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  color="#fff"
                />
              ) : (
                <>
                  <CustomButton
                    btnText={"SAVE"}
                    padding="15px 25px"
                    fullWidth={true}
                    height="50px"
                    background="#0042a8"
                    hoverBg="#0042a8"
                    disabled={
                      info.profile_type === "person"
                        ? !info.firstName || !info.lastName
                        : !info.business_name
                    }
                    color="#fff"
                    onClick={handleSubmit}
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                onClick={props.handleCloseAddProfile}
                variant="p"
                component="p"
                align="left"
                style={{
                  color: color.themeYellow,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                {"CANCEL"}
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
    </CustomModal>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    create: (data, handleCloseAddProfile) => {
      dispatch(create(data, handleCloseAddProfile));
    },
    update: (data, id, handleClose) => {
      dispatch(update(data, id, handleClose));
    },
    setProfileLoading: (bool) => {
      dispatch(setProfileLoading(bool));
    },
    setProfileError: (err) => {
      dispatch(setProfileError(err));
    },
    index: () => {
      dispatch(index());
    },
    setProfile: (profile) => {
      dispatch(setProfile(profile));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.profile.loading,
    profileError: state.profile.error,
    profile: state.profile.profileInfo,
    count: state.auth.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProfile);
