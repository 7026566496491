import "date-fns";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import logo from "../../../assets/img/tag-logo.png";
// import logo from "../../../assets/img/axieLogo.png";
import { DocumentService } from "../../../services/document.service";
import { store } from "../../../store/store";
import { resumeHtml } from "../../../ReportsHtml/resume";

const useStyles = makeStyles(styles);

function ReportResume(props) {
  const classes = useStyles();
  const docService = new DocumentService();
  const [err, setErr] = React.useState(null);
  const [loadingDoc, setLoadingDoc] = React.useState(false);

  const [doc, setDoc] = React.useState({
    employers: [],
    education: [],
    skills: [],
    awards: [],
    certificates: [],
    languages: [],
    personal_information: {},
  });

  React.useEffect(() => {
    docService
      .resumeIndex()
      .then((res) => {
        setDoc({
          ...doc,
          employers: res?.data?.employers ? res?.data?.employers : [],
          education: res?.data?.educations ? res?.data?.educations : [],
          skills: res?.data?.skills ? res?.data?.skills : [],
          awards: res?.data?.awards ? res?.data?.awards : [],
          personal_information:
            res?.data?.personal_information && res?.data?.personal_information,
          certificates: res?.data?.certificates && res?.data?.certificates,
          languages: res?.data?.languages && res?.data?.languages,
        });
      })
      .catch((err) => {});
  }, []);

  const handleSubmit = () => {
    setLoadingDoc(true);
    const { html } = resumeHtml(doc);
    docService
      ?.resumeCreate(html)
      .then((res) => {
        console.log({ res });
        setLoadingDoc(false);
        props?.setAlert({
          type: "Success",
          open: true,
          btnText: "Okay",
          heading: "Success!",
          message:
            "Your Resume was stored securely in All Documents under type None",
        });
        props?.handleClose();
      })
      .catch((err) => {
        props?.setAlert({
          type: "Error",
          open: true,
          btnText: "RETRY",
          cancelText: "CANCEL",
          heading: "Something went wrong!",
          message:
            "We're sorry there was an error and your resume could not be uploaded. Would you like to try again?",
        });
        props?.handleClose();
        setLoadingDoc(false);
      });
  };

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="55%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Resume"}
          color={color.white}
        />
        <div className="w-100 shadow-sm py-2 px-4 d-flex flex-row">
          <Typography
            variant="p"
            component="p"
            align="left"
            style={{ color: "#060d5c", fontWeight: "normal" }}
          >
            {"Reports > Resume"}
          </Typography>
        </div>
        <div className={classes.modalBody + " pt-3"}>
          <div className="d-flex flex-sm-row flex-column align-items-center mb-4 pb-3 border-bottom">
            <img src={logo} height="56" className="me-sm-5 me-0" />
            <div className="d-flex flex-row">
              <div className="me-sm-5 me-0">
                <Typography
                  variant="p"
                  component="p"
                  align="left"
                  style={{ color: "#060d5c", fontWeight: "normal" }}
                >
                  {"Belongs To:"}
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  align="left"
                  style={{ color: "#060d5c", fontWeight: "bold" }}
                >
                  {store.getState().profile?.profileInfo?.profile
                    ?.profile_type === "person"
                    ? store.getState().profile?.profileInfo?.profile
                        ?.first_name +
                      " " +
                      store.getState().profile?.profileInfo?.profile?.last_name
                    : store.getState().profile?.profileInfo?.profile
                        ?.business_name}
                </Typography>
              </div>
            </div>
          </div>
          <Grid container wrap="wrap" direction="row" spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                variant="h5"
                component="h3"
                style={{
                  color: color.themeYellow,
                  textDecoration: "underline",
                  textDecorationColor: "#0042A8",
                  textTransform: "uppercase",
                }}
              >
                {"EMPLOYERS"}
              </Typography>
              {doc?.employers?.length > 0 && (
                <>
                  {doc?.employers?.map((e) => {
                    return (
                      <>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="h6"
                            component="h3"
                            style={{ color: color.themeColorNew }}
                          >
                            {e?.company}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="p"
                            component="p"
                            style={{ color: color.themeColorNew }}
                          >
                            {e?.current_employer
                              ? e?.title +
                                " started " +
                                (e?.start_date ? e.start_date : "N/A")
                              : e?.title +
                                " started " +
                                (e?.start_date ? e.start_date : "N/A") +
                                (e?.end_date ? " to " + e?.end_date : "")}
                          </Typography>
                        </Grid>
                      </>
                    );
                  })}
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                variant="h5"
                component="h3"
                style={{
                  color: color.themeYellow,
                  textDecoration: "underline",
                  textDecorationColor: "#0042A8",
                  textTransform: "uppercase",
                }}
              >
                {"EDUCATION"}
              </Typography>
              {doc?.education?.length > 0 &&
                doc?.education?.map((e) => {
                  return (
                    <>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {e?.school}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          {e?.end_date}
                        </Typography>
                      </Grid>
                    </>
                  );
                })}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                variant="h5"
                component="h3"
                style={{
                  color: color.themeYellow,
                  textDecoration: "underline",
                  textDecorationColor: "#0042A8",
                  textTransform: "uppercase",
                }}
              >
                {"SKILLS"}
              </Typography>
              {doc?.skills?.length > 0 &&
                doc?.skills?.map((s) => {
                  return (
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography
                        variant="h6"
                        component="h3"
                        style={{ color: color.themeColorNew }}
                      >
                        {s?.skill_name}
                      </Typography>
                    </Grid>
                  );
                })}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                variant="h5"
                component="h3"
                style={{
                  color: color.themeYellow,
                  textDecoration: "underline",
                  textDecorationColor: "#0042A8",
                  textTransform: "uppercase",
                }}
              >
                {"AWARDS & RECOGNITIONS"}
              </Typography>
              {doc?.awards?.length > 0 &&
                doc?.awards?.map((a) => {
                  return (
                    <>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {a?.award_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          {a?.organization}
                        </Typography>
                      </Grid>
                    </>
                  );
                })}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                variant="h5"
                component="h3"
                style={{
                  color: color.themeYellow,
                  textDecoration: "underline",
                  textDecorationColor: "#0042A8",
                  textTransform: "uppercase",
                }}
              >
                {"Certificates"}
              </Typography>

              {doc?.certificates?.length > 0 &&
                doc?.certificates?.map((a) => {
                  return (
                    <>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {a?.certificate_name}
                        </Typography>
                      </Grid>
                    </>
                  );
                })}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                variant="h5"
                component="h3"
                style={{
                  color: color.themeYellow,
                  textDecoration: "underline",
                  textDecorationColor: "#0042A8",
                  textTransform: "uppercase",
                }}
              >
                {"Languages"}
              </Typography>

              {doc?.languages?.length > 0 &&
                doc?.languages?.map((a) => {
                  return (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        className="d-flex flex-row flex-sm-column align-items-center align-items-sm-start"
                      >
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="h6"
                            component="h3"
                            style={{ color: color.themeColorNew }}
                          >
                            {a?.language_name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography
                            variant="p"
                            component="p"
                            style={{ color: color.themeColorNew }}
                          >
                            {a?.qualification}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <small>
                The information in this document is confidential and may be
                legally privileged. Access to this document by anyone other than
                the intended addressee is unauthorized. If you are not the
                intended recipient of this message, any review, disclosure,
                copying, distribution, retention, or any action taken or omitted
                to be taken in reliance on it is prohibited and may be unlawful.
              </small>
            </Grid>
          </Grid>
        </div>
        <div
          className="bg-light text-sm-center p-2 bottomRadius"
          style={{ textAlign: "right" }}
        >
          <small className="me-sm-5 me-2">
            This is a preview of your{" "}
            <span style={{ color: color.themeYellow }}>
              <b>Resume</b>
            </span>
          </small>
          {!loadingDoc ? (
            <CustomButton
              type="submit"
              btnText="Store Securely"
              textAlign="center"
              display={"block"}
              padding="8px 25px"
              background="#0042a8"
              hoverBg="#0042a8"
              border="0"
              borderRadius="8px"
              color="#fff"
              onClick={handleSubmit}
            />
          ) : (
            <CustomButton
              btnText={<CircularProgress size={20} color="#DDD" />}
              type="submit"
              textAlign="center"
              display={"block"}
              padding="8px 25px"
              background="#0042a8"
              hoverBg="#0042a8"
              border="0"
              borderRadius="8px"
              color="#fff"
            />
          )}
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportResume);
