import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close, EditOutlined } from "@material-ui/icons";
// import AvatarGroup from '@mui/material/AvatarGroup';
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";

const useStyles = makeStyles(styles);
export default function ContactsInformation(props) {
  const classes = useStyles();

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%"
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          rightIcon={
            <EditOutlined
              onClick={() => props?.handleOpenEdit()}
              style={{
                color: color.white,
                marginLeft: "auto",
                fontSize: "36px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Contact Information "}
          color={color.white}
        />
        <div className={classes.modalBody}>
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            {props?.contact?.firstName && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"First Name"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew, fontSize: "18px" }}
                  >
                    {props?.contact?.firstName}
                  </Typography>
                </Grid>
              </>
            )}
            {props?.contact?.lastName && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Last Name"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew, fontSize: "18px" }}
                  >
                    {props?.contact?.lastName}
                  </Typography>
                </Grid>
              </>
            )}

            {props?.contact?.company_name && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Company"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew, fontSize: "18px" }}
                  >
                    {props?.contact?.company_name}
                  </Typography>
                </Grid>
              </>
            )}

            {props?.contact?.contact_addresses &&
              props?.contact?.contact_addresses?.length > 0 && (
                <>
                  {props?.contact?.contact_addresses?.length === 3 ? (
                    <>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {props?.contact?.contact_addresses[0]?.address_type +
                            " " +
                            "Address"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{
                            color: color.themeColorNew,
                            fontSize: "18px",
                          }}
                        >
                          {`${props?.contact?.contact_addresses[0]?.address1 + ","}
                            
                            ${
                              props?.contact?.contact_addresses[0]?.address2
                                ? props?.contact?.contact_addresses[0]
                                    ?.address2 + ", "
                                : ""
                            }
                            
                            ${
                              props?.contact?.contact_addresses[0]?.city
                                ? props?.contact?.contact_addresses[0]?.city +
                                  ", "
                                : ""
                            }
                            
                            ${
                              props?.contact?.contact_addresses[0]?.state
                                ? props?.contact?.contact_addresses[0]?.state +
                                  ", "
                                : ""
                            }

                            ${
                              props?.contact?.contact_addresses[0]?.postal_code
                                ? props?.contact?.contact_addresses[0]
                                    ?.postal_code + ", "
                                : ""
                            }
                            
                            ${
                              props?.contact?.contact_addresses[0]?.country
                                ? props?.contact?.contact_addresses[0]
                                    ?.country
                                : ""
                            }`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {props?.contact?.contact_addresses[1]?.address_type +
                            " " +
                            "Address"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{
                            color: color.themeColorNew,
                            fontSize: "18px",
                          }}
                        >
                          {`${props?.contact?.contact_addresses[1]?.address1 + ","}
                            
                            ${
                              props?.contact?.contact_addresses[1]?.address2
                                ? props?.contact?.contact_addresses[1]
                                    ?.address2 + ", "
                                : ""
                            }
                            
                            ${
                              props?.contact?.contact_addresses[1]?.city
                                ? props?.contact?.contact_addresses[1]?.city +
                                  ", "
                                : ""
                            }
                            
                            ${
                              props?.contact?.contact_addresses[1]?.state
                                ? props?.contact?.contact_addresses[1]?.state +
                                  ", "
                                : ""
                            }

                            ${
                              props?.contact?.contact_addresses[1]?.postal_code
                                ? props?.contact?.contact_addresses[1]?.postal_code +
                                  ", "
                                : ""
                            }
                            
                            ${
                              props?.contact?.contact_addresses[1]?.country
                                ? props?.contact?.contact_addresses[1]
                                    ?.country
                                : ""
                            }`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {props?.contact?.contact_addresses[2]?.address_type +
                            " " +
                            "Address"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{
                            color: color.themeColorNew,
                            fontSize: "18px",
                          }}
                        >
                          {`${props?.contact?.contact_addresses[2]?.address1 + ","}
                            
                            ${
                              props?.contact?.contact_addresses[2]?.address2
                                ? props?.contact?.contact_addresses[2]
                                    ?.address2 + ", "
                                : ""
                            }
                            
                            ${
                              props?.contact?.contact_addresses[2]?.city
                                ? props?.contact?.contact_addresses[2]?.city +
                                  ", "
                                : ""
                            }
                            
                            ${
                              props?.contact?.contact_addresses[2]?.state
                                ? props?.contact?.contact_addresses[2]?.state +
                                  ", "
                                : ""
                            }

                            ${
                              props?.contact?.contact_addresses[2]?.postal_code
                                ? props?.contact?.contact_addresses[2]?.postal_code +
                                  ", "
                                : ""
                            }
                            
                            ${
                              props?.contact?.contact_addresses[2]?.country
                                ? props?.contact?.contact_addresses[2]
                                    ?.country
                                : ""
                            }`}
                        </Typography>
                      </Grid>
                    </>
                  ) : props?.contact?.contact_addresses?.length === 2 ? (
                    <>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {props?.contact?.contact_addresses[0]?.address_type +
                            " " +
                            "Address"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{
                            color: color.themeColorNew,
                            fontSize: "18px",
                          }}
                        >
                          {`${props?.contact?.contact_addresses[0]?.address1 + ","}
                            
                            ${
                              props?.contact?.contact_addresses[0]?.address2
                                ? props?.contact?.contact_addresses[0]
                                    ?.address2 + ", "
                                : ""
                            }
                            
                            ${
                              props?.contact?.contact_addresses[0]?.city
                                ? props?.contact?.contact_addresses[0]?.city +
                                  ", "
                                : ""
                            }
                            
                            ${
                              props?.contact?.contact_addresses[0]?.state
                                ? props?.contact?.contact_addresses[0]?.state +
                                  ", "
                                : ""
                            }

                            ${
                              props?.contact?.contact_addresses[0]?.postal_code
                                ? props?.contact?.contact_addresses[0]
                                    ?.postal_code + ", "
                                : ""
                            }
                            
                            ${
                              props?.contact?.contact_addresses[0]?.country
                                ? props?.contact?.contact_addresses[0]
                                    ?.country
                                : ""
                            }`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {props?.contact?.contact_addresses[1]?.address_type +
                            " " +
                            "Address"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{
                            color: color.themeColorNew,
                            fontSize: "18px",
                          }}
                        >
                          {`${props?.contact?.contact_addresses[1]?.address1 + ","}
                            
                            ${
                              props?.contact?.contact_addresses[1]?.address2
                                ? props?.contact?.contact_addresses[1]
                                    ?.address2 + ", "
                                : ""
                            }
                            
                            ${
                              props?.contact?.contact_addresses[1]?.city
                                ? props?.contact?.contact_addresses[1]?.city +
                                  ", "
                                : ""
                            }
                            
                            ${
                              props?.contact?.contact_addresses[1]?.state
                                ? props?.contact?.contact_addresses[1]?.state +
                                  ", "
                                : ""
                            }

                            ${
                              props?.contact?.contact_addresses[1]?.postal_code
                                ? props?.contact?.contact_addresses[1]
                                    ?.postal_code + ", "
                                : ""
                            }
                            
                            ${
                              props?.contact?.contact_addresses[1]?.country
                                ? props?.contact?.contact_addresses[1]
                                    ?.country
                                : ""
                            }`}
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {props?.contact?.contact_addresses[0]?.address_type +
                            " " +
                            "Address"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{
                            color: color.themeColorNew,
                            fontSize: "18px",
                          }}
                        >
                          {`${props?.contact?.contact_addresses[0]?.address1}
                            
                            ${
                              props?.contact?.contact_addresses[0]?.address2
                                ? props?.contact?.contact_addresses[0]
                                    ?.address2 + ", "
                                : ""
                            }
                            
                            ${
                              props?.contact?.contact_addresses[0]?.city
                                ? props?.contact?.contact_addresses[0]?.city +
                                  ", "
                                : ""
                            }
                            
                            ${
                              props?.contact?.contact_addresses[0]?.state
                                ? props?.contact?.contact_addresses[0]?.state +
                                  ", "
                                : ""
                            }

                            ${
                              props?.contact?.contact_addresses[0]?.postal_code
                                ? props?.contact?.contact_addresses[0]
                                    ?.postal_code + ", "
                                : ""
                            }
                            
                            ${
                              props?.contact?.contact_addresses[0]?.country
                                ? props?.contact?.contact_addresses[0]
                                    ?.country + ", "
                                : ""
                            }`}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </>
              )}
            {props?.contact?.contact_emails &&
              props?.contact?.contact_emails?.length > 0 && (
                <>
                  {props?.contact?.contact_emails?.length === 3 ? (
                    <>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {props?.contact?.contact_emails[0]?.email_type +
                            " " +
                            "Email"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{
                            color: color.themeColorNew,
                            fontSize: "18px",
                          }}
                        >
                          {props?.contact?.contact_emails[0]?.email}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {props?.contact?.contact_emails[1]?.email_type +
                            " " +
                            "Email"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{
                            color: color.themeColorNew,
                            fontSize: "18px",
                          }}
                        >
                          {props?.contact?.contact_emails[1]?.emai}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {props?.contact?.contact_emails[2]?.email_type +
                            " " +
                            "Email"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{
                            color: color.themeColorNew,
                            fontSize: "18px",
                          }}
                        >
                          {props?.contact?.contact_emails[2]?.email}
                        </Typography>
                      </Grid>
                    </>
                  ) : props?.contact?.contact_emails?.length === 2 ? (
                    <>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {props?.contact?.contact_emails[0]?.email_type +
                            " " +
                            "Email"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{
                            color: color.themeColorNew,
                            fontSize: "18px",
                          }}
                        >
                          {props?.contact?.contact_emails[0]?.email}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {props?.contact?.contact_emails[1]?.email_type +
                            " " +
                            "Email"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{
                            color: color.themeColorNew,
                            fontSize: "18px",
                          }}
                        >
                          {props?.contact?.contact_emails[1]?.email}
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {props?.contact?.contact_emails[0]?.email_type +
                            " " +
                            "Email"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{
                            color: color.themeColorNew,
                            fontSize: "18px",
                          }}
                        >
                          {props?.contact?.contact_emails[0]?.email}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </>
              )}
            {props?.contact?.contact_phone_numbers &&
              props?.contact?.contact_phone_numbers?.length > 0 && (
                <>
                  {props?.contact?.contact_phone_numbers?.length === 3 ? (
                    <>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {props?.contact?.contact_phone_numbers[0]
                            ?.phone_type +
                            " " +
                            "Phone"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{
                            color: color.themeColorNew,
                            fontSize: "18px",
                          }}
                        >
                          <a
                            href={`tel:${
                              props?.contact?.contact_phone_numbers[0]
                                ?.country_code +
                              props?.contact?.contact_phone_numbers[0]
                                ?.phone_number
                            }`}
                          >
                            {
                              props?.contact?.contact_phone_numbers[0]
                                ?.phone_number
                            }
                          </a>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {props?.contact?.contact_phone_numbers[1]
                            ?.phone_type +
                            " " +
                            "Phone"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{
                            color: color.themeColorNew,
                            fontSize: "18px",
                          }}
                        >
                          <a
                            href={`tel:${
                              props?.contact?.contact_phone_numbers[1]
                                ?.country_code +
                              props?.contact?.contact_phone_numbers[1]
                                ?.phone_number
                            }`}
                          >
                            {
                              props?.contact?.contact_phone_numbers[1]
                                ?.phone_number
                            }
                          </a>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {
                            props?.contact?.contact_phone_numbers[2]
                              ?.phone_number
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{
                            color: color.themeColorNew,
                            fontSize: "18px",
                          }}
                        >
                          <a
                            href={`tel:${
                              props?.contact?.contact_phone_numbers[2]
                                ?.country_code +
                              props?.contact?.contact_phone_numbers[2]
                                ?.phone_number
                            }`}
                          >
                            {
                              props?.contact?.contact_phone_numbers[2]
                                ?.phone_number
                            }
                          </a>
                        </Typography>
                      </Grid>
                    </>
                  ) : props?.contact?.contact_phone_numbers?.length === 2 ? (
                    <>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {props?.contact?.contact_phone_numbers[0]
                            ?.phone_type +
                            " " +
                            "Phone"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{
                            color: color.themeColorNew,
                            fontSize: "18px",
                          }}
                        >
                          <a
                            href={`tel:${
                              props?.contact?.contact_phone_numbers[0]
                                ?.country_code +
                              props?.contact?.contact_phone_numbers[0]
                                ?.phone_number
                            }`}
                          >
                            {
                              props?.contact?.contact_phone_numbers[0]
                                ?.phone_number
                            }
                          </a>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {props?.contact?.contact_phone_numbers[1]
                            ?.phone_type +
                            " " +
                            "Phone"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{
                            color: color.themeColorNew,
                            fontSize: "18px",
                          }}
                        >
                          <a
                            href={`tel:${
                              props?.contact?.contact_phone_numbers[1]
                                ?.country_code +
                              props?.contact?.contact_phone_numbers[1]
                                ?.phone_number
                            }`}
                          >
                            {
                              props?.contact?.contact_phone_numbers[1]
                                ?.phone_number
                            }
                          </a>
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: color.themeColorNew }}
                        >
                          {props?.contact?.contact_phone_numbers[0]
                            ?.phone_type +
                            " " +
                            "Phone"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{
                            color: color.themeColorNew,
                            fontSize: "18px",
                          }}
                        >
                          <a
                            href={`tel:${
                              props?.contact?.contact_phone_numbers[0]
                                ?.country_code +
                              props?.contact?.contact_phone_numbers[0]
                                ?.phone_number
                            }`}
                          >
                            {
                              props?.contact?.contact_phone_numbers[0]
                                ?.phone_number
                            }
                          </a>
                        </Typography>
                      </Grid>
                    </>
                  )}
                </>
              )}
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}
