import "date-fns";
import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { DocumentService } from "../../../services/document.service";
import AddAddress from "../AddAddress/AddAddress";
import AddEmailAddress from "../AddEmailAddress/AddEmailAddress";
import AddPhoneNumber from "../AddPhoneNumber/AddPhoneNumber";
import Folder from "../../../assets/img/Folder.png";
import browseFile from "../../../assets/img/browseFile.png";
import { useDropzone } from "react-dropzone";
import { Close } from "@material-ui/icons";
import { store } from "../../../store/store";
import { rerenderEmployer } from "../../../store/actions/loadingAction";
import AlertPopup from "../Alerts/Generic/GenericAlert";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(styles);

function AddNewContact(props) {
  const classes = useStyles();
  const history = useHistory();
  const [alert, setAlert] = useState({
    open: false,
    type: null,
    heading: null,
    message: null,
    btnText: null,
    cancelText: null,
    handleClick: null,
    handleClose: null,
    isLoading: false,
  });
  const errRef = useRef(null);
  const documentService = new DocumentService();
  const [file, setFile] = React.useState(null);
  const [err, setErr] = React.useState(null);
  const [id, setId] = React.useState(null);
  const [docIsLoading, setDocIsLoading] = React.useState(false);
  const [openAddAddress, setOpenAddAddress] = React.useState(false);
  const [openAddPhoneNumber, setOpenAddPhoneNumber] = React.useState(false);
  const [openAddEmailAddress, setOpenAddEmailAddress] = React.useState(false);
  const [name, setName] = React.useState({
    firstName: null,
    lastName: null,
  });
  const [companyName, setCompanyName] = React.useState(null);
  const [choose, setChoose] = React.useState("new");
  const [address, setAddress] = React.useState([]);
  const [currAddress, setCurrAddress] = React.useState(null);
  const [contacts, setContacts] = React.useState([]);
  const [currPhone, setCurrPhone] = React.useState(null);
  const [currEmail, setCurrEmail] = React.useState(null);
  const [phone, setPhone] = React.useState([]);
  const [email, setEmail] = React.useState([]);
  const [data, setData] = React.useState({ status: "new" });

  const onDrop = React.useCallback((files) => {
    if (files && files.length) {
      const filename = files[0].name;

      var parts = filename.split(".");
      const fileType = parts[parts.length - 1];
      console.log("fileType", files[0]); //ex: zip, rar, jpg, svg etc.
      setFile(files[0]);
      localStorage.setItem("payload", files[0]);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".csv",
  });

  const handleOpenAddAddress = () => {
    setOpenAddAddress(true);
  };

  const handleCloseAddAddress = () => {
    setOpenAddAddress(false);
  };

  const handleOpenAddPhoneNumber = () => {
    setOpenAddPhoneNumber(true);
  };

  const handleCloseAddPhoneNumber = () => {
    setOpenAddPhoneNumber(false);
  };

  const handleOpenAddEmailAddress = () => {
    setOpenAddEmailAddress(true);
  };

  const handleCloseAddEmailAddress = () => {
    setOpenAddEmailAddress(false);
  };

  const closeModal = () => {
    setOpenAddAddress(false);
    setOpenAddPhoneNumber(false);
    setOpenAddEmailAddress(false);
  };
  const docService = new DocumentService();

  useEffect(() => {
    docService
      .indexContact()
      .then((res) => {
        setContacts(res.data.contacts ? res.data.contacts : []);
      })
      .catch(() => {});
  }, []);
  const handleSubmit = () => {
    const data = {
      firstName: name.firstName,
      lastName: name.lastName,
      company_name: companyName,
      contact_addresses_attributes: address,
      contact_phone_numbers_attributes: phone,
      contact_emails_attributes: email,
    };
    setDocIsLoading(true);
    documentService
      .contactsCreate(data)
      .then((response) => {
        setDocIsLoading(false);
        console.log({ response });
        setAddress([]);
        setPhone([]);
        setEmail([]);
        setCompanyName(null);
        if (props?.editContact) {
          props.setData({
            ...props.data,
            insurance_contacts_attributes: [
              ...props.data.insurance_contacts_attributes,
              { contact_id: response.data.contact.id },
            ],
          });
        } else {
          props.setData({
            ...props.data,
            insurance_contacts_attributes: [
              { contact_id: response.data.contact.id },
            ],
          });
        }
        props.setContact(response.data.contact);
        props.handleClose();
      })
      .catch((err) => {
        setDocIsLoading(false);
        setErr("Error occurred while creating contact");
        if (err.response) {
          setAlert({
            open: true,
            type: "Error",
            heading: "Error!",
            message: err?.response?.data?.errors?.full_messages,
            btnText: "Retry",
            cancelText: "Cancel",
            isLoading: false,
            handleClick: () => setAlert({ ...alert, open: false }),
            handleClose: () => {
              setAlert({ ...alert, open: false });
              props?.handleClose();
            },
          });
          console.log(err.response);
        } else {
          setAlert({
            open: true,
            type: "Error",
            heading: "Error!",
            message: "Internal server error, try again later.",
            btnText: "Retry",
            cancelText: "Cancel",
            isLoading: false,
            handleClick: () => setAlert({ ...alert, open: false }),
            handleClose: () => {
              setAlert({ ...alert, open: false });
              props?.handleClose();
            },
          });
        }
        errRef?.current.scrollIntoView();
      });
  };
  const handleUpdate = () => {
    const data = {
      firstName: name.firstName,
      lastName: name.lastName,
      company_name: companyName,
      contact_addresses_attributes: address,
      contact_phone_numbers_attributes: phone,
      contact_emails_attributes: email,
    };
    setDocIsLoading(true);
    documentService
      .contactsEdit(data, id)
      .then((response) => {
        setDocIsLoading(false);
        setAlert({
          open: true,
          type: "Success",
          heading: "Success!",
          message: "Contact has been successfully updated.",
          btnText: null,
          cancelText: "Okay",
          isLoading: false,
          handleClick: () => {
            history.push("/user/all-contacts");
            setAlert({ ...alert, open: false });
            props.handleClose();
          },
          handleClose: () => {
            setAlert({ ...alert, open: false });
            store?.dispatch(rerenderEmployer());
            props?.handleClose();
          },
        });
        setAddress([]);
        setPhone([]);
        setEmail([]);
        // props.handleClose();
      })
      .catch((err) => {
        setDocIsLoading(false);
        setErr("Error occurred while editing contact");
        if (err.response) {
          setAlert({
            open: true,
            type: "Error",
            heading: "Error!",
            message: err?.response?.data?.errors?.full_messages,
            btnText: "Retry",
            cancelText: "Cancel",
            isLoading: false,
            handleClick: () => setAlert({ ...alert, open: false }),
            handleClose: () => {
              setAlert({ ...alert, open: false });
              props?.handleClose();
            },
          });
          console.log(err.response);
        } else {
          setAlert({
            open: true,
            type: "Error",
            heading: "Error!",
            message: "Internal server error, try again later.",
            btnText: "Retry",
            cancelText: "Cancel",
            isLoading: false,
            handleClick: () => setAlert({ ...alert, open: false }),
            handleClose: () => {
              setAlert({ ...alert, open: false });
              props?.handleClose();
            },
          });
          console.log({ err });
        }
        errRef?.current.scrollIntoView();
      });
  };

  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (props.edit) {
      setId(props.contacts.id);
      setAddress(props.contacts.contact_addresses);
      setPhone(props.contacts.contact_phone_numbers);
      setEmail(props.contacts.contact_emails);
      setName({
        firstName: props.contacts.contact_first_name,
        lastName: props.contacts.contact_last_name,
      });
      setCompanyName(props.contacts.company_name);
    }
  }, []);
  return (
    <>
      {alert && (
        <AlertPopup
          type={alert.type}
          open={alert.open}
          isLoading={false}
          heading={alert.heading}
          btnText={alert.btnText}
          cancelText={alert.cancelText}
          handleClose={alert.handleClose}
          message={alert.message}
          handleClick={alert.handleClick}
        />
      )}
      {openAddAddress && (
        <AddAddress
          setAlert={setAlert}
          alert={alert}
          address={currAddress}
          setAddress={setAddress}
          addresses={address}
          open={openAddAddress}
          setCurrAddress={setCurrAddress}
          handleClose={handleCloseAddAddress}
          closeModal={closeModal}
          edit={props.edit ? true : false}
        />
      )}
      {openAddPhoneNumber && (
        <AddPhoneNumber
          setAlert={setAlert}
          alert={alert}
          setCurrPhone={setCurrPhone}
          setPhone={setPhone}
          phones={phone}
          phone={currPhone}
          open={openAddPhoneNumber}
          edit={props.edit ? true : false}
          handleClose={handleCloseAddPhoneNumber}
          closeModal={closeModal}
        />
      )}
      {openAddEmailAddress && (
        <AddEmailAddress
          setAlert={setAlert}
          alert={alert}
          setCurrEmail={setCurrEmail}
          setEmail={setEmail}
          emails={email}
          email={currEmail}
          open={openAddEmailAddress}
          edit={props.edit ? true : false}
          handleClose={handleCloseAddEmailAddress}
          closeModal={closeModal}
        />
      )}
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="50%"
        // margin="150px 0 "
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={!props.edit ? "Add Contact" : "Edit Contact"}
          color={color.white}
        />
        <div className={classes.modalBody}>
          {err && (
            <div className="w-100 d-flex flex-row mb-5">
              <Typography
                variant="p"
                component="p"
                align="left"
                style={{ color: "red", fontWeight: "normal" }}
              >
                {err}
              </Typography>
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={6} md={6}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="status"
                  value={data?.status}
                  onChange={(e) => setData({ ...data, status: e.target.value })}
                  className="flex-row align-items-center"
                >
                  <FormControlLabel
                    value="existing"
                    control={<Radio style={{ color: "#ffb722" }} />}
                  />
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Choose an existing contact"}
                  </Typography>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="status"
                  value={data?.status}
                  onChange={(e) => setData({ ...data, status: e.target.value })}
                  className="flex-row align-items-center"
                >
                  <FormControlLabel
                    className="mr-2"
                    value="new"
                    control={<Radio style={{ color: "#ffb722" }} />}
                  />
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Create new contact"}
                  </Typography>
                </RadioGroup>
              </FormControl>
            </Grid>
            <>
              {data.status === "existing" ? (
                <>
                  <Grid item xs={12} sm={4} md={4}>
                    <Typography
                      variant="h6"
                      component="h3"
                      style={{ color: color.themeColorNew }}
                    >
                      {"Existing Contacts"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} md={8}>
                    <FormControl fullWidth={true}>
                      <Select
                        variant="outlined"
                        fullWidth={true}
                        displayEmpty
                        margin="dense"
                        defaultValue={"Select Web Login"}
                        required={true}
                        onChange={(e) => {
                          if (props?.editContact) {
                            props.setData({
                              ...props.data,
                              insurance_contacts_attributes: [
                                ...props?.data?.insurance_contacts_attributes,
                                { contact_id: e.target.value },
                              ],
                            });

                            props.setContact(
                              contacts.filter((c) => c.id === e.target.value)[0]
                            );
                          } else {
                            props.setData({
                              ...props.data,
                              insurance_contacts_attributes: [
                                { contact_id: e.target.value },
                              ],
                            });

                            props.setContact(
                              contacts.filter((c) => c.id === e.target.value)[0]
                            );
                          }
                        }}
                        name="docs"
                      >
                        <MenuItem value={"Select Web Login"} disabled>
                          Select Contact
                        </MenuItem>
                        {contacts
                          ?.sort((a, b) =>
                            a?.firstName.localeCompare(b?.firstName)
                          )
                          ?.map((state, index) => {
                            return (
                              <MenuItem value={state.id} key={index}>
                                {state?.firstName + " " +
                                  state?.lastName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={12} md={12} className="topZero">
                    <Typography
                      variant="h6"
                      component="h3"
                      style={{ color: color.themeColorNew }}
                    >
                      {"First Name"}
                    </Typography>
                    <FormInput
                      id="name"
                      name="name"
                      onChange={(e) =>
                        setName({ ...name, firstName: e.target.value })
                      }
                      label=""
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      value={name.firstName}
                      required={true}
                      placeholder={""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} className="topZero">
                    <Typography
                      variant="h6"
                      component="h3"
                      style={{ color: color.themeColorNew }}
                    >
                      {"Last Name"}
                    </Typography>
                    <FormInput
                      id="name"
                      name="name"
                      onChange={(e) =>
                        setName({ ...name, lastName: e.target.value })
                      }
                      label=""
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      value={name.lastName}
                      required={true}
                      placeholder={""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} className="topZero">
                    <Typography
                      variant="h6"
                      component="h3"
                      style={{ color: color.themeColorNew }}
                    >
                      {"Company Name"}
                    </Typography>
                    <FormInput
                      id="name"
                      name="company_name"
                      onChange={(e) => setCompanyName(e.target.value)}
                      label=""
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      value={companyName}
                      required={true}
                      placeholder={""}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="h6"
                      component="h3"
                      className=""
                      style={{ color: color.themeColorNew, fontWeight: "500" }}
                    >
                      {"Addresses"}
                    </Typography>
                    {address?.length <= 0 ? (
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: "#060d5c" }}
                      >
                        {"None"}
                      </Typography>
                    ) : (
                      address?.map((a) => {
                        if (!a._destroy) {
                          return (
                            <Grid
                              container
                              className={"justify-content-between"}
                            >
                              <Typography
                                variant="p"
                                component="p"
                                style={{ color: "#060d5c" }}
                              >
                                {a?.address1 ? a?.address1 : a?.address2}
                              </Typography>
                              <div
                                style={{ marginLeft: "1em" }}
                                className="d-flex flex-row"
                              >
                                <div className="position-relative editDeleIcons">
                                  <div className="position-absolute top-15 start-50 translate-middle tooltipText hoverShow px-2 py-0">
                                    {"Edit"}
                                  </div>
                                  <CreateOutlinedIcon
                                    onClick={() => {
                                      setCurrAddress(a);
                                      handleOpenAddAddress();
                                    }}
                                    style={{
                                      color: "orange",
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                                <div className="position-relative editDeleIcons">
                                  <div className="position-absolute top-15 start-50 translate-middle tooltipText hoverShow px-2 py-0">
                                    {"Delete"}
                                  </div>
                                  <DeleteOutlineIcon
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => {
                                      if (props?.edit) {
                                        let array = [];
                                        address?.forEach((val, index) => {
                                          if (val.id === a.id) {
                                            array.push({
                                              ...val,
                                              _destroy: 1,
                                            });
                                          } else {
                                            array.push(val);
                                          }
                                        });
                                        setAddress(array);
                                      } else {
                                        let array = address.filter(
                                          (val) => val !== a
                                        );
                                        setAddress(array);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </Grid>
                          );
                        }
                      })
                    )}
                  </Grid>
                  <>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      container
                      className="justify-content-sm-end justify-content-start mt-sm-0 mt-3"
                    >
                      <CustomButton
                        btnText={"Add"}
                        padding="15px 25px"
                        minWidth="113px"
                        height="50px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="8px"
                        onClick={() => handleOpenAddAddress()}
                      />
                    </Grid>
                  </>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="h6"
                      component="h3"
                      className=""
                      style={{ color: color.themeColorNew, fontWeight: "500" }}
                    >
                      {"Phone Numbers"}
                    </Typography>
                    {phone?.length <= 0 ? (
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: "#060d5c" }}
                      >
                        {"None"}
                      </Typography>
                    ) : (
                      phone.map((p) => {
                        if (!p._destroy) {
                          return (
                            <Grid
                              container
                              className={"justify-content-between"}
                            >
                              <Typography
                                variant="p"
                                component="p"
                                style={{ color: "#060d5c" }}
                              >
                                {p?.phone_number}
                              </Typography>
                              <div
                                style={{ marginLeft: "1em" }}
                                className="d-flex flex-row"
                              >
                                <div className="position-relative editDeleIcons">
                                  <div className="position-absolute top-15 start-50 translate-middle tooltipText hoverShow px-2 py-0">
                                    {"Edit"}
                                  </div>
                                  <CreateOutlinedIcon
                                    onClick={() => {
                                      setCurrPhone(p);
                                      handleOpenAddPhoneNumber();
                                    }}
                                    style={{
                                      color: "orange",
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                                <div className="position-relative editDeleIcons">
                                  <div className="position-absolute top-15 start-50 translate-middle tooltipText hoverShow px-2 py-0">
                                    {"Delete"}
                                  </div>

                                  <DeleteOutlineIcon
                                    data-tooltip={"Delete"}
                                    data-tooltip-location="top"
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => {
                                      if (props?.edit) {
                                        let array = [];
                                        phone?.forEach((val, index) => {
                                          if (val.id === p.id) {
                                            array.push({
                                              ...val,
                                              _destroy: 1,
                                            });
                                          } else {
                                            array.push(val);
                                          }
                                        });
                                        setPhone(array);
                                      } else {
                                        let array = phone.filter(
                                          (val) => val !== p
                                        );
                                        setPhone(array);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </Grid>
                          );
                        }
                      })
                    )}
                  </Grid>
                  <>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      container
                      className="justify-content-sm-end justify-content-start mt-sm-0 mt-3"
                    >
                      <CustomButton
                        btnText={"Add"}
                        padding="15px 25px"
                        minWidth="113px"
                        height="50px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="8px"
                        onClick={() => handleOpenAddPhoneNumber()}
                      />
                    </Grid>
                  </>
                  <Grid item xs={12} md={6} ref={errRef}>
                    <Typography
                      variant="h6"
                      component="h3"
                      className=""
                      style={{ color: color.themeColorNew, fontWeight: "500" }}
                    >
                      {"Email Addresses"}
                    </Typography>
                    {email?.length <= 0 ? (
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: "#060d5c" }}
                      >
                        {"None"}
                      </Typography>
                    ) : (
                      email.map((e) => {
                        if (!e._destroy) {
                          return (
                            <Grid
                              container
                              className={"justify-content-between"}
                            >
                              <Typography
                                variant="p"
                                component="p"
                                style={{ color: "#060d5c" }}
                              >
                                {e?.email}
                              </Typography>
                              <div
                                style={{ marginLeft: "1em" }}
                                className="d-flex flex-row"
                              >
                                <div className="position-relative editDeleIcons">
                                  <div className="position-absolute top-15 start-50 translate-middle tooltipText hoverShow px-2 py-0">
                                    {"Edit"}
                                  </div>
                                  <CreateOutlinedIcon
                                    onClick={() => {
                                      setCurrEmail(e);
                                      handleOpenAddEmailAddress();
                                    }}
                                    style={{
                                      color: "orange",
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                                <div className="position-relative editDeleIcons">
                                  <div className="position-absolute top-15 start-50 translate-middle tooltipText hoverShow px-2 py-0">
                                    {"Delete"}
                                  </div>

                                  <DeleteOutlineIcon
                                    data-tooltip={"Delete"}
                                    data-tooltip-location="top"
                                    style={{ color: "red", cursor: "pointer" }}
                                    onClick={() => {
                                      if (props?.edit) {
                                        let array = [];
                                        email?.forEach((val, index) => {
                                          if (val.id === e.id) {
                                            array.push({
                                              ...val,
                                              _destroy: 1,
                                            });
                                          } else {
                                            array.push(val);
                                          }
                                        });
                                        setEmail(array);
                                      } else {
                                        let array = email.filter(
                                          (val) => val !== e
                                        );
                                        setEmail(array);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </Grid>
                          );
                        }
                      })
                    )}
                  </Grid>
                  <>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      container
                      className="justify-content-sm-end justify-content-start mt-sm-0 mt-3"
                    >
                      <CustomButton
                        btnText={"Add"}
                        padding="15px 25px"
                        minWidth="113px"
                        height="50px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        color="#fff"
                        borderRadius="8px"
                        onClick={() => handleOpenAddEmailAddress()}
                      />
                    </Grid>
                  </>
                </>
              )}
            </>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              {!docIsLoading ? (
                <>
                  {props?.edit ? (
                    <CustomButton
                      type="submit"
                      btnText="Save"
                      textAlign="center"
                      display={"block"}
                      padding="15px 25px"
                      disabled={
                        address === props?.contacts.contact_addresses &&
                        phone === props.contacts.contact_phone_numbers &&
                        email === props.contacts.contact_emails &&
                        name === props.contacts.contact_name &&
                        companyName === props.contacts.company_name
                      }
                      minWidth="113px"
                      background="#0042a8"
                      hoverBg="#0042a8"
                      border="0"
                      marginRight="0px"
                      borderRadius="8px"
                      color="#fff"
                      onClick={handleUpdate}
                      // disabled={data. === "" || !data.?.trim() ? true : false}
                    />
                  ) : data.status === "existing" ? (
                    <CustomButton
                      type="submit"
                      btnText="Save"
                      textAlign="center"
                      display={"block"}
                      padding="15px 25px"
                      disabled={!props?.contact}
                      minWidth="113px"
                      background="#0042a8"
                      hoverBg="#0042a8"
                      border="0"
                      marginRight="0px"
                      borderRadius="8px"
                      color="#fff"
                      onClick={() => props?.handleClose()}
                      // disabled={data. === "" || !data.?.trim() ? true : false}
                    />
                  ) : (
                    <CustomButton
                      type="submit"
                      btnText="Save"
                      textAlign="center"
                      display={"block"}
                      padding="15px 25px"
                      disabled={!file && !name && !companyName}
                      minWidth="113px"
                      background="#0042a8"
                      hoverBg="#0042a8"
                      border="0"
                      marginRight="0px"
                      borderRadius="8px"
                      color="#fff"
                      onClick={() => handleSubmit()}
                      // disabled={data. === "" || !data.?.trim() ? true : false}
                    />
                  )}
                </>
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="15px 25px"
                  minWidth="113px"
                  marginRight="0px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}

              {/* <CustomButton
                onClick={() => props?.handleClose()}
                btnText="Cancel"
                textAlign="center"
                display={"block"}
                padding="15px 25px"
                background="transparent"
                hoverBg="#0042a8"
                border="1px solid #0042a8"
                borderRadius="8px"
                color="#0042a8"
              /> */}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

export default AddNewContact;
