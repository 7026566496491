const ActivityHistoryAccordion = (theme) => ({
    root: {
        width: '100%',
        "& .MuiAccordion-root:before": {
            height: "0",
        },
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: "bold",
    },
    accordionMainBar: {
        margin: "0!important",
        borderRadius: "8px",
        boxShadow: "none",
        background: "transparent"
    },
    accordionQuestion: {
        background: "#fff",
        marginBottom: "12px",
        borderRadius: "8px",
        minHeight: "auto!important",
        "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "12px 0",
        },
    },
    accordionAnswer: {
        background: "#fff",
        borderRadius: "8px",
        marginBottom: '12px',
    },
});

export default ActivityHistoryAccordion;