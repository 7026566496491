import React from "react";
import { styled } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import disabled from "../../../src/assets/img/disabled.png";
export const CustomButton = (props) => {
  const MyButton = styled(Button)({
    // background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    background: [props.background],
    // background: [
    //   props?.disabled && props?.btnText !== "Add Reminder" && !props?.timeZone
    //     ? "#FFB722"
    //     : props.background,
    // ],
    border: [props.border],
    borderRadius: [props.borderRadius],
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: [props.color],
    minWidth: [props.minWidth],
    width: [props.width],
    height: [props.height],
    padding: [props.padding],
    margin: [props.margin],
    marginBottom: [props.marginBottom],
    marginLeft: [props.marginLeft],
    marginRight: [props.marginRight],
    textTransform: "capitalize",
    cursor: [props.cursor],
    "&:hover": {
      color: "#fff",
      backgroundColor: [props.hoverBg],
    },
    "@media only screen and (max-width: 768px)": {
      marginLeft: "1rem",
    },
    "@media only screen and (max-width: 389px)": {
      marginLeft: "0",
    },
  });
  return (
    <MyButton
      style={{ ...props?.style, position: "relative" }}
      focusRipple={false}
      autoFocus={props?.autoFocus}
      className={props?.className + " disabled_color"}
      disabled={props?.disabled}
      fullWidth={props.fullWidth}
      onChange={props.onChange}
      variant={props?.variant ?? "contained"}
      ref={props.ref}
      type={props.type}
      onClick={props.onClick}
    >
      {props.leftIcon} {props.btnText}{" "}
      <span style={props.rightIconStyle}>{props.rightIcon}</span>{" "}
      {props.children}
      {props?.disabled && (
        <img
          src={disabled}
          height={"18"}
          width={"18"}
          style={{
            marginLeft: "5px",
            // position: "absolute",
            // top: "50%",
            // left: "50%",
            // transform: "translate(-50%, -50%)",
          }}
        />
      )}
    </MyButton>
  );
};
