import "date-fns";
import React, {useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
// import { makeStyles } from "@material-ui/core/styles";
import {FormInput} from "../../../components/FormInput/FormInput";
import {Checkbox, FormControlLabel, Grid, Typography} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import {CustomButton} from "../../../components/CustomButton/Button.js";
import {Close} from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Auth} from "../../../services/auth.service";
import {store} from "../../../store/store";
import {clearAuthState} from "../../../store/actions/authAction";
import {clearProfileState} from "../../../store/actions/profileAction";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles(styles);

function DeleteAccountConfirmation(props) {
    const authService = new Auth();
    const classes = useStyles();
    const [otp, setOtp] = useState(false)
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState({pwd: "password"})
    const [err, setErr] = useState(null)
    const [data, setData] = useState({
        check: false,
        password: null,
    })

    const handleSubmit = () => {
        setLoading(true)
        authService.deleteAccount({
            password: data.password
        }).then((res) => {
            localStorage.clear();
            store.dispatch(clearProfileState())
            store.dispatch(clearAuthState())
            window.location.replace("/auth/user");
            setLoading(false)
        }).catch((err) => {
            setLoading(false)
            setErr(err?.response?.data?.message ? err?.response?.data?.message : "An unknown error was occurred while deleting your account please try again later")
        })
    };


    const handleChange = (event) => {
        setData({...data, [event.target.name]: event.target.value})
    };

    return (
        <>
            <CustomModal
                visible={props.open}
                onClose={props.handleClose}
                width="55%"
                margin="120px 0 40px"
                inset="15px!important"
            >
                <ModalHeader
                    closeModal={props.handleClose}
                    icon={
                        <Close
                            style={{
                                color: color.white,
                                marginRight: "20px",
                                fontSize: "30px",
                                cursor: "pointer",
                            }}
                        />
                    }
                    heading={"Delete your account"}
                    color={color.white}
                />
                <div className={classes.modalBody}>
                    <Grid
                        container
                        wrap="wrap"
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        {err && (
                            <div className="w-100 d-flex flex-row mb-5">
                                <Typography
                                    variant="p"
                                    component="p"
                                    align="left"
                                    style={{color: "red", fontWeight: "normal"}}
                                >
                                    {err}
                                </Typography>
                            </div>
                        )}

                        <div className="w-100 d-flex flex-row mb-4 mt-2">
                            <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{color: "red", fontWeight: "bold"}}
                            >
                                *TagData will not keep any information about you after you've deleted your account.
                            </Typography>
                        </div>
                        <Grid item xs={12} sm={12} md={12} className="border-bottom">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="checkedA"
                                        checked={data.check}
                                        style={{color: "#ffb722"}}
                                        onChange={(e) => setData({...data, check: e.target.checked})}
                                    />
                                }
                                label={"I understand I will not be able to recover any information from my account."}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography
                                variant="h6"
                                component="h3"
                                style={{color: color.themeColorNew}}
                            >
                                {"Password"}
                            </Typography>
                            <FormInput
                                name="password"
                                onChange={(e) => setData({...data, password: e.target.value})}
                                label=""
                                variant="outlined"
                                fullWidth={true}
                                margin="dense"
                                Icon={type.pwd === "text" ? VisibilityIcon : VisibilityOffIcon}
                                iconClick={() =>
                                    setType({
                                        pwd: type.pwd === "password" ? "text" : "password",
                                    })
                                }
                                required={true}
                                placeholder={"enter password"}
                                type={type.pwd}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="text-sm-center" style={{textAlign: "right"}}>

                            {!loading ? <CustomButton
                                type="submit"
                                btnText="Save"
                                textAlign="center"
                                display={"block"}
                                padding="12px 25px"
                                minWidth="113px"
                                background="#0042a8"
                                hoverBg="#0042a8"
                                border="0"
                                marginRight="10px"
                                disabled={!data.check}
                                borderRadius="8px"
                                color="#fff"
                                onClick={handleSubmit}
                            /> : <CustomButton
                                type="submit"
                                btnText={<CircularProgress size={20} color="#DDD"/>}
                                textAlign="center"
                                display={"block"}
                                padding="12px 25px"
                                minWidth="113px"
                                background="#0042a8"
                                hoverBg="#0042a8"
                                border="0"
                                marginRight="10px"
                                borderRadius="8px"
                                color="#fff"
                            />}
                        </Grid>
                    </Grid>
                </div>
            </CustomModal>

        </>
    );
}

export default DeleteAccountConfirmation;
