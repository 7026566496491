import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../../Card/Card";
import { Grid, Typography } from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import report_resume from "../../../assets/img/resume.svg";
import report_cash_flow from "../../../assets/img/cash.svg";
import report_patient_registration from "../../../assets/img/patient.svg";
import ReportResume from "../../../containers/Modals/ReportResume/ReportResume";
import ReportPatientRegistration from "../../../containers/Modals/ReportPatientRegistration/ReportPatientRegistration";
import ReportCashFlow from "../../../containers/Modals/ReportCashFlow/ReportCashFlow";
import { DocumentService } from "../../../services/document.service";
import AlertPopup from "../../../containers/Modals/Alerts/Generic/GenericAlert";

const useStyles = makeStyles(styles);
export default function Reports() {
  const [alert, setAlert] = React.useState({
    open: false,
    type: null,
    btnText: null,
    cancelText: null,
    heading: null,
    message: null,
  });
  const documentService = new DocumentService();
  const classes = useStyles();
  const [openResume, setOpenResume] = React.useState(false);
  const [openCashFlow, setOpenCashFlow] = React.useState(false);
  const [openPatientRegistration, setOpenPatientRegistration] =
    React.useState(false);

  const handleOpenResume = () => {
    setOpenResume(true);
    setOpenCashFlow(false);
    setOpenPatientRegistration(false);
  };

  React.useEffect(() => {
    documentService
      .patientRegistrationIndex()
      .then((res) => {})
      .catch((err) => {});
  }, []);

  const handleCloseResume = () => {
    setOpenResume(false);
  };

  const handleOpenCashFlow = () => {
    setOpenCashFlow(true);
    setOpenResume(false);
    setOpenPatientRegistration(false);
  };

  const handleCloseCashFlow = () => {
    setOpenCashFlow(false);
  };

  const handleOpenPatientRegistration = () => {
    setOpenPatientRegistration(true);
    setOpenResume(false);
    setOpenCashFlow(false);
  };

  const handleClosePatientRegistration = () => {
    setOpenPatientRegistration(false);
  };

  const closeModal = () => {
    setOpenResume(false);
    setOpenCashFlow(false);
    setOpenPatientRegistration(false);
  };
  return (
    <>
      {alert.open && (
        <AlertPopup
          btnText={alert.btnText}
          cancelText={alert.cancelText}
          open={alert.open}
          type={alert.type}
          heading={alert.heading}
          message={alert.message}
          isLoading={false}
          handleClose={() => {
            setAlert({
              open: false,
              btnText: null,
              cancelText: null,
              heading: null,
              message: null,
              type: null,
            });
          }}
          handleClick={() => {
            if (alert.type === "Error") {
              setAlert({
                open: false,
                btnText: null,
                cancelText: null,
                heading: null,
                message: null,
                type: null,
              });
            } else {
              setAlert({
                open: false,
                btnText: null,
                cancelText: null,
                heading: null,
                message: null,
                type: null,
              });
            }
          }}
        />
      )}
      <Grid container wrap="wrap" direction="row" spacing={4}>
        <Grid item xs={6} sm={4} md={4} lg={4}>
          <Card
            style={{ cursor: "pointer" }}
            className="p-3 mt-0 text-center"
            onClick={() => handleOpenResume()}
          >
            <img src={report_resume} className="mb-2" />
            <Typography
              variant="p"
              component="p"
              align="center"
              style={{ color: "#060d5c" }}
            >
              {"Resume"}
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={4}>
          <Card
            style={{ cursor: "pointer" }}
            className="p-3 mt-0 text-center"
            onClick={() => handleOpenCashFlow()}
          >
            <img src={report_cash_flow} className="mb-2" />
            <Typography
              variant="p"
              component="p"
              align="center"
              style={{ color: "#060d5c" }}
            >
              {"Cash Flow"}
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={4}>
          <Card
            style={{ cursor: "pointer" }}
            className="p-3 mt-0 text-center"
            onClick={() => handleOpenPatientRegistration()}
          >
            <img src={report_patient_registration} className="mb-2" />
            <Typography
              variant="p"
              component="p"
              align="center"
              style={{ color: "#060d5c" }}
            >
              {"Patient Registration"}
            </Typography>
          </Card>
        </Grid>
      </Grid>
      <ReportResume
        alert={alert}
        setAlert={setAlert}
        open={openResume}
        handleClose={handleCloseResume}
        closeModal={closeModal}
      />
      <ReportCashFlow
        alert={alert}
        setAlert={setAlert}
        open={openCashFlow}
        handleClose={handleCloseCashFlow}
        closeModal={closeModal}
      />
      <ReportPatientRegistration
        alert={alert}
        setAlert={setAlert}
        open={openPatientRegistration}
        handleClose={handleClosePatientRegistration}
        closeModal={closeModal}
      />
    </>
  );
}
