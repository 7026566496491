import "date-fns";
color.themeColorNew;
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, useTheme } from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles(styles);

function PasswordVerification(props) {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [type, setType] = React.useState(true);
  const theme = useTheme();
  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Password Verification"}
          color={color.white}
        />
        <div className={classes.modalBody}>
          <Grid container wrap="wrap" direction="row" spacing={3}>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.black }}
              >
                {"Account Password *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                onChange={(e) => {
                  props?.handleChange(e.target.value);
                  setPassword(e.target.value);
                }}
                type={type ? "password" : "text"}
                Icon={type ? VisibilityOffIcon :  VisibilityIcon}
                iconClick={() =>
                  setType(!type)
                }
                id="password"
                inputProps={{
                  autoComplete: "current-password",
                }}
                variant="outlined"
                fullWidth={true}
                margin="dense"
                placeholder={"xxxxxxxx"}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              <CustomButton
                type="submit"
                btnText="Verify"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
                onClick={() => props?.handleSubmit()}
                disabled={!password}
              />
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

export default PasswordVerification;
