const disableCheck = (prev, current) => {
  let disable = true;

  if (prev.current) {
    for (const [field, value] of Object.entries(current)) {
      if (value !== prev.current[field]) {
        disable = false;
      }
    }
  }
  return disable;
};

export default disableCheck;
