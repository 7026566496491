import * as types from "./types";
import { Auth } from "../../services/auth.service";
import { clearProfileState } from "./profileAction";
import { isExpired } from "./loadingAction";
import ReactGA from "react-ga4";
import { first } from "lodash";

const Api = new Auth();

export function setCurrentUser(user) {
  return {
    type: types.SET_AUTH_USER,
    payload: user,
  };
}

export function setPhone(number) {
  return {
    type: "PHONE NUMBER",
    payload: number,
  };
}

export function pauseNotification(bool) {
  return {
    type: "PAUSE_NOTIFICATION",
    payload: bool,
  };
}

export function set2FA(bool) {
  return {
    type: "2 FA",
    payload: bool,
  };
}

export function setTimeZone(val) {
  return {
    type: "TIMEZONE",
    payload: val,
  };
}

export function setUserCount(count) {
  return {
    type: "USER COUNT",
    payload: count,
  };
}

export function setPlanType(plan) {
  return {
    type: "PLAN TYPE",
    payload: plan,
  };
}

export function clearAuthState() {
  return {
    type: types.CLEAR_STATE,
  };
}

export function setError(error) {
  return {
    type: types.SET_AUTH_ERROR,
    payload: error,
  };
}

export function clearError(error) {
  return {
    type: types.CLEAR_AUTH_ERROR,
    payload: error,
  };
}

export function setLoginAnimationFalse(bool) {
  return {
    type: "LOGIN_ANIMATION",
    payload: bool,
  };
}

export function setAnimation(bool) {
  return {
    type: "ANIMATION",
    payload: bool,
  };
}

export function setLoadAnimation(bool) {
  return {
    type: "LOAD_ANIMATION",
    payload: bool,
  };
}

export function setAuthLoading(bool) {
  return {
    type: types.SET_AUTH_LOADING,
    payload: bool,
  };
}

export const register = (data, history, scrollBy) => {
  return (dispatch) => {
    dispatch(setAuthLoading(true));
    Api.register(data)
      .then((response) => {
        dispatch(setAuthLoading(false));
        localStorage.setItem(
          "brow$3rCt",
          JSON.stringify(response.headers.client)
        );
        localStorage.setItem("uid", JSON.stringify(response.headers.uid));
        localStorage.setItem(
          "brow$3rAT",
          JSON.stringify(response.headers["access-token"])
        );
        dispatch(
          setCurrentUser({
            ...response.data.user,
            remember_created_at: new Date(),
          })
        );
        console.log({ response });
        history?.push("/auth/mail-success");
        ReactGA.event({
          category: 'Registration',
          action: 'SignUp',
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
      });
      })
      .catch((err) => {
        dispatch(setAuthLoading(false));
        if (err?.response?.data) {
          dispatch(setError(err?.response?.data?.errors?.full_messages));
          scrollBy();
        } else if (err?.response?.status === 429) {
          dispatch(
            setError(["Too many requests, please try again in one hour"])
          );
        } else {
          dispatch(setError(["Something went wrong. Try again later"]));
          scrollBy();
        }
      });
  };
};

export const sendOtp = (data, history) => {
  return (dispatch) => {
    dispatch(setAuthLoading(true));
    Api.sendOtp(data)
      .then((response) => {
        dispatch(setAuthLoading(false));
        dispatch(setLoginAnimationFalse(false));
        if (response.status === 406) {
          dispatch(data, history);
        } else {
          localStorage.setItem("email", data.email);
          localStorage.setItem("password", data.password);
          localStorage.setItem("remember_me", data.remember_me);
          localStorage.setItem("is_login", data.is_login ? "true" : "false");
          history.push("/auth/otp");
        }
      })
      .catch((err) => {
        let str = "";
        dispatch(setAuthLoading(false));
        if (err?.response?.status === 404) {
          dispatch(setError(["error 404, not found"]));
        } else if (err?.response?.status === 406) {
          dispatch(login(data, history));
        } else if (err?.response?.status === 401) {
          dispatch(setError(["Invalid credentials"]));
        } else if (err?.response?.data && err?.response?.data?.errors) {
          Object.keys(err?.response?.data?.errors).forEach((key) => {
            let value = err?.response?.data?.errors[key];
            str = str?.concat(value + " ");
          });
          // dispatch(setAuthLoading(false));
          dispatch(setError(str));
        } else if (err?.response?.data?.err) {
          dispatch(setError([err?.response?.data?.err]));
        } else if (err?.response?.status === 429) {
          dispatch(
            setError(["Too many requests, please try again in one hour"])
          );
        } else {
          // dispatch(setAuthLoading(false));
          dispatch(setError(["Something went wrong. Try again later"]));
        }
      });
  };
};

export const login = (data, history) => {
  return (dispatch) => {
    dispatch(setAuthLoading(true));
    Api.login(data)
      .then((response) => {
    dispatch(setAuthLoading(false));

        localStorage.removeItem("email");
        localStorage.removeItem("password");
        localStorage.removeItem("remember_me");
        localStorage.removeItem("is_login");
        localStorage.setItem(
          "brow$3rCt",
          JSON.stringify(response.headers.client)
        );
        localStorage.setItem("uid", JSON.stringify(response.headers.uid));
        localStorage.setItem(
          "brow$3rAT",
          JSON.stringify(response.headers["access-token"])
        );
        dispatch(setCurrentUser(response.data.user));
        // dispatch(index());
        // setTimeout(() => {
        // history?.push("/user");

        if (
          response.data.user.sign_in_count === 1 ||
          response.data.user.is_subscribed === false
        ) {
          // window.location.replace("/auth/subscriptions");
          dispatch(setAnimation(true));
          setTimeout(() => {
            dispatch(setAnimation(false));
            history.push("/auth/subscriptions");
          }, 2500);
          dispatch(setLoginAnimationFalse(false));
        } else {
          dispatch(setAnimation(true));
          setTimeout(() => {
            dispatch(setAnimation(false));
            history.push("/user");
          }, 2500);
          // history.push("/user");
          // window.location.replace("/user");
          dispatch(setLoginAnimationFalse(false));
        }
        // }, 5200);
      })
      .catch((err) => {
        dispatch(setAuthLoading(false));
        let str = "";
        if (err?.response?.status === 404) {
          dispatch(setError(["error 404, not found"]));
        } else if (err?.response?.status === 429) {
          dispatch(
            setError(["Too many requests, please try again in one hour"])
          );
        } else if (
          err?.response?.data?.errors &&
          err?.response?.data?.errors[0] ===
            "Your account has been locked due to an excessive number of unsuccessful sign in attempts."
        ) {
          dispatch(
            setError([
              "Your account has been locked due to an excessive number of unsuccessful sign in attempts. Try again in one hour",
            ])
          );
        } else if (err?.response?.data?.errors) {
          Object.keys(err?.response?.data?.errors).forEach((key) => {
            let value = err?.response?.data?.errors[key];
            str = str?.concat(value + " ");
          });
          dispatch(setError([str]));
        } else if (err?.response?.data?.message) {
          dispatch(setError([err?.response?.data?.message]));
        } else {
          dispatch(setError(["Something went wrong. Try again later"]));
        }
      });
  };
};
// export const login = (data, history) => {
//   return (dispatch) => {
//     dispatch(setAuthLoading(true));
//     Api.login(data)
//       .then((response) => {
//         localStorage.setItem(
//           "brow$3rCt",
//           JSON.stringify(response.headers.client)
//         );
//         localStorage.setItem("uid", JSON.stringify(response.headers.uid));
//         localStorage.setItem(
//           "brow$3rAT",
//           JSON.stringify(response.headers["access-token"])
//         );
//         dispatch(setCurrentUser(response.data.user));
//         // dispatch(index());=
//         // history?.push("/user");
//         localStorage.removeItem("email");
//         localStorage.removeItem("password");
//         if (response.data.user.sign_in_count === 1) {
//           window.location.replace("/auth/subscriptions");
//           // dispatch(setLoginAnimationFalse(false));
//         } else {
//           window.location.replace("/user");
//           // dispatch(setLoginAnimationFalse(false));
//         }
//       })
//       .catch((err) => {
//         dispatch(setAuthLoading(false));

//         let str = "";
//         if (err?.response?.status === 404) {
//           dispatch(setError(["error 404, not found"]));
//         } else if (err?.response?.data?.message) {
//           dispatch(setError([err?.response?.data?.message]));
//         } else if (err?.response?.data) {
//           Object.keys(err?.response?.data?.errors).forEach((key) => {
//             let value = err?.response?.data?.errors[key];
//             str = str?.concat(value + " ");
//           });
//           dispatch(setError([str]));
//         } else {
//           dispatch(setError(["Something went wrong. Try again later"]));
//         }
//       });
//   };
// };

export const resetPasswordRequest = (data, history) => {
  return (dispatch) => {
    dispatch(setAuthLoading(true));
    Api.requestPasswordReset(data)
      .then((response) => {
        console.log({ response });
        dispatch(setAuthLoading(false));

        history?.push("/auth/reset-password-mail-success");
      })
      .catch((err) => {
        dispatch(setAuthLoading(false));

        console.log("error response ", err?.response);
        let str = "";
        if (err?.response?.status === 404) {
          dispatch(setAuthLoading(false));
          history?.push("/auth/reset-password-mail-success");
        } else if (err?.response?.data) {
          Object.keys(err?.response?.data?.errors).forEach((key) => {
            let value = err?.response?.data?.errors[key];
            str = str?.concat(value + " ");
          });
          dispatch(setAuthLoading(false));
          dispatch(setError(str));
        } else {
          dispatch(setAuthLoading(false));
          dispatch(setError("Internal server error."));
        }
      });
  };
};

export const resetPassword = (data, history, headers) => {
  return (dispatch) => {
    dispatch(setError(false));
    dispatch(setAuthLoading(true));
    Api.resetPassword(data, headers)
      .then((response) => {
        console.log({ response });
        dispatch(setAuthLoading(false));

        history?.push("/auth/login");
      })
      .catch((err) => {
        dispatch(setAuthLoading(false));

        console.log("error response ", err?.response);
        let str = "";
        if (err?.response?.status === 404) {
          dispatch(setAuthLoading(false));
          dispatch(setError("error 404, not found"));
        } else if (err?.response?.data?.errors) {
          Object.keys(err?.response?.data?.errors).forEach((key) => {
            let value = err?.response?.data?.errors[key];
            str = str?.concat(value + " ");
          });
          dispatch(setAuthLoading(false));
          dispatch(setError(str));
        } else if (err?.response?.data?.message) {
          dispatch(setAuthLoading(false));
          dispatch(setError(err?.response?.data?.message[0]));
        } else {
          dispatch(setAuthLoading(false));
          dispatch(setError("Something went wrong. Try again later"));
        }
      });
  };
};

export const logout = (history, bool) => {
  return (dispatch) => {
    Api.logout()
      .then((response) => {
        // window.localStorage.clear();
        dispatch(clearProfileState());
        dispatch(setLoginAnimationFalse(false));

        dispatch(clearAuthState());
        dispatch(isExpired(false));
        if (bool) {
          dispatch(setError("Signed out because of inactivity."));
        }
        history.push("/auth");
        // window.location.replace("/auth/user");
      })
      .catch((err) => {
        // window.localStorage.clear();
        dispatch(clearProfileState());
        dispatch(setLoginAnimationFalse(false));

        dispatch(clearAuthState());
        history.push("/auth");
        // window.location.replace("/auth/user");
      });
  };
};

export const logoutAll = () => {
  return (dispatch) => {
    Api.signOutAll()
      .then((response) => {
        // window.localStorage.clear();
        dispatch(clearProfileState());
        dispatch(clearAuthState());
        window.location.replace("/auth/user");
      })
      .catch((err) => {
        // window.localStorage.clear();
        dispatch(clearProfileState());
        dispatch(clearAuthState());
        window.location.replace("/auth/user");
      });
  };
};

export const deleteAccount = () => {
  return (dispatch) => {
    Api.deleteAccount()
      .then((response) => {
        console.log({ response });
        window.localStorage.clear();
        dispatch(clearProfileState());
        dispatch(clearAuthState());
        window.location.replace("/auth/user");
      })
      .catch((err) => {
        console.log({ error: err });
        // window.localStorage.clear();
        // dispatch(clearProfileState());
        // dispatch(clearAuthState());
        // window.location.replace("/auth/user");
      });
  };
};
