import "date-fns";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import styles from "../../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { ErrorRounded, WarningRounded } from "@material-ui/icons";
import color from "../../../../assets/css/color.js";
import CustomModal from "../../../../components/Modal/Modal.js";
import ModalHeader from "../../../../components/Modal/ModalHeader.js";
import { CustomButton } from "../../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(styles);

function Alerts(props) {
  const classes = useStyles();

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%"
        margin="250px 0 40px"
        inset="15px!important"
      >
        <ModalHeader
          extraclass="p-2"
          closeModal={props.handleClose}
          headerBgColor={color.themeColorNew}
          color="white"
        />
        <div className={classes.modalBody}>
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            className="px-4"
          >
            <div className="w-100 text-center">
              <div className="d-flex justify-content-center align-items-center">
                <ErrorRounded
                  className="me-2"
                  style={{ color: color.themeYellow, fontSize: "50px" }}
                />
                <Typography
                  variant="h6"
                  component="h3"
                  className="mb-0"
                  style={{ color: "#060d5c", fontWeight: "bold" }}
                >
                  {props.heading}
                </Typography>
              </div>
              <Typography
                variant="h6"
                component="h3"
                align="center"
                className="mb-4"
                style={{ color: "#060d5c", fontWeight: "normal" }}
              >
                {props.message}
              </Typography>
            </div>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              {!props.one && (
                <CustomButton
                  btnText="Yes"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="2px solid #0042a8"
                  borderRadius="8px"
                  color="#fff"
                  marginRight={"10px"}
                  width="100px"
                  onClick={() => {
                    if (props.alternate) {
                      props.alternate();
                    } else {
                      props.onClick();
                    }
                  }}
                />
              )}
              {!props.isLoading ? (
                <CustomButton
                  type="submit"
                  btnText={!props?.btnText ? "Close" : props?.btnText}
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="transparent"
                  hoverBg="#0042a8"
                  border="2px solid #0042a8"
                  borderRadius="8px"
                  color="#0042a8"
                  width="100px"
                  onClick={
                    !props?.handleCancel
                      ? props.handleClose
                      : props?.handleCancel
                  }
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="transparent"
                  hoverBg="#0042a8"
                  border="2px solid #060d5c"
                  borderRadius="8px"
                  color="#060d5c"
                  width="100px"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

export default Alerts;
