import _default from "../config/default";
import { store } from "../store/store";

export class HistoryService {
  constructor() {
    this.BASE_URL = _default.BASE_URL;
    this.AUTH_ENDPOINT = _default.ENDPOINT_AUTH;
  }

  activityHistoryIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/actions`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  activeAlertsIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/alertable-records`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  alertsIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/alert_preferences`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  alertsUpdate(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/alert_preferences/${data.id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: {
        alert_preference: data,
      },
    });
  }

  favouritesIndex(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        id
      }/favorites`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  favouritesDestroy(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/favorites/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  favouritesCreate(data) {
    console.log(data);
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/favorites`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: {
        subcategory: data,
      },
    });
  }
}
