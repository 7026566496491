import React, { useEffect } from "react";
import { persist, store } from "./store/store";
import { Elements } from "@stripe/react-stripe-js";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Admin from "./layouts/Admin";
import ViewDocument from "./views/ViewDocument/ViewDocument";
import { PersistGate } from "redux-persist/integration/react";
import AuthScreenWrapper from "./layouts/AuthScreenWrapper";
import { Provider } from "react-redux";
import { PrivateRoute, AuthRoute } from "./index";
import { loadStripe } from "@stripe/stripe-js";
import { clearError } from "./store/actions/authAction";
import _default from "./config/default";
import mixpanel from "mixpanel-browser";
import { MixpanelProvider, MixpanelConsumer } from "react-mixpanel";
import io from "socket.io-client";
import ReactGA from "react-ga4";



const App = () => {
  const key = process.env.REACT_APP_AXIEL_STRIPE_PK;
  const stripePromise = loadStripe(key);
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
  }, []);
  window.onunload = () => {
    store.dispatch(clearError(null));
    if (store.getState().auth?.user?.remember_created_at === null) {
      localStorage.clear();
    }
  };
  useEffect(() => {
    mixpanel.init(process.env.MIXPANEL_TOKEN);
  }, []);

  useEffect(() => {
    const socketServerUrl = window.location.hostname;
    console.log({ socketServerUrl });
    const socket = io(socketServerUrl);

    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    socket.on("deployment", (data) => {
      console.log("New deployment:", data);
      // Handle new deployment (e.g., reload page)
      window.location.reload();
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <Provider store={store}>
      <MixpanelProvider mixpanel={mixpanel}>
        <Elements stripe={stripePromise}>
          <PersistGate persistor={persist}>
            <BrowserRouter>
              <Switch>
                <PrivateRoute path="/user" component={Admin} />

                <Route path="/view-document" component={ViewDocument} />
                <Route path="/auth" component={AuthScreenWrapper} />
                <Redirect from="/" exact to="/user" />
              </Switch>
            </BrowserRouter>
          </PersistGate>
        </Elements>
      </MixpanelProvider>
    </Provider>
  );
};

export default App;
