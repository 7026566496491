
import _default from "../config/default";
import { store } from "../store/store";
import * as actions from "../store/actions/profileAction";

export class ProfileService {
  constructor() {
    console.log({_default})
    this.AUTH_ENDPOINT = _default.ENDPOINT_AUTH;
    this.BASE_URL = _default.BASE_URL;
  }

   create(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles`,
      headers: {
        "Content-Type": "multipart/form-data",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  index() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  notifications() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/notifications`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }
  notificationsRead(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/notifications/${id}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      data: data,
      method: "PUT",
    });
  }

  show(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${id}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  update(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${id}`,
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      data: data,
      method: "PUT",
    });
  }

  delete(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${id}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  setCurrentProfile() {
    const primary = store
      ?.getState()
      ?.profile?.profiles?.filter((info) => info.id === 1);
    store?.dispatch(actions.setProfile(primary));
  }

  dispatchCreate(data) {
    store?.dispatch(actions.create(data));
  }

  dispatchIndex() {
    store?.dispatch(actions.index());
  }

  dispatchShow(id) {
    store?.dispatch(actions.show(id));
  }

  dispatchDestroy(id) {
    store?.dispatch(actions.destroy(id));
  }
}
