import "date-fns";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { PersonalService } from "../../../services/personal.service.js";
import { FormInput } from "../../../components/FormInput/FormInput";
import { truncateString } from "../../../Utils/truncateString.js";

const useStyles = makeStyles(styles);

function ChooseWebLogins(props) {
  const classes = useStyles();
  const healthService = new PersonalService();
  const [err, setErr] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [docs, setDocs] = React.useState([]);
  const [validations, setValidations] = React.useState({
    email: null,
    url: null,
  });
  const [file, setFile] = React.useState(null);
  const [docIsLoading, setDocIsLoading] = React.useState(false);
  const [data, setData] = React.useState({
    status: "existing",
    docs: null,
  });

  const onDrop = React.useCallback((files) => {
    if (files && files.length) {
      const filename = files[0].name;

      var parts = filename.split(".");
      const fileType = parts[parts.length - 1];
      console.log("fileType", files[0]); //ex: zip, rar, jpg, svg etc.

      setFile(files[0]);
      localStorage.setItem("payload", files[0]);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const [dataa, setDataa] = React.useState({
    description: "",
    password: "",
    site_url: "",
    notes: "",
    user_name: "",
  });

  React.useEffect(() => {
    healthService
      .webLoginsIndex()
      .then((res) => {
        setDocs(res?.data?.web_logins || []);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, []);

  const personalService = new PersonalService();

  const handleSubmitt = () => {
    let formData = new FormData();
    formData.append("description", dataa.description);
    formData.append("password", dataa.password);
    formData.append("site_url", dataa.site_url);
    formData.append("notes", dataa.notes);
    formData.append("user_name", dataa.user_name);
    setDocIsLoading(true);
    personalService
      .createWebLogins(formData)
      .then((response) => {
        setDocIsLoading(false);
        props.setData({
          ...props.data,
          associated_logins_attributes: [
            { web_login_id: response.data.web_login.id },
          ],
        });
        props.setWebLogin(response.data.web_login);
        props.handleClose();
      })
      .catch((err) => {
        setDocIsLoading(false);
      });
  };

  const handleChange = (event) => {
    if (event.target.name === "site_url") {
      if (
        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi.test(
          event.target.value
        )
      ) {
        setDataa({
          ...dataa,
          [event.target.name]: event.target.value,
        });
        setValidations({
          url: null,
          email: null,
        });
      } else {
        setValidations({
          ...validations,
          url: "url link must be valid",
        });
      }
    } else {
      setDataa({
        ...dataa,
        [event.target.name]: event.target.value,
      });
    }
  };

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Add Web Login"}
          color={color.white}
        />
        <div className={classes.modalBody}>
          <div className="w-100 d-flex flex-row mb-5">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              You can choose an existing web login from your Web Logins to link
              with this record or create a new one.
            </Typography>
          </div>
          {err && (
            <div className="w-100 d-flex flex-row mb-5">
              <Typography
                variant="p"
                component="p"
                align="left"
                style={{ color: "red", fontWeight: "normal" }}
              >
                {err}
              </Typography>
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={6} md={6}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="status"
                  value={data?.status}
                  onChange={(e) => setData({ ...data, status: e.target.value })}
                  className="flex-row align-items-center"
                >
                  <FormControlLabel
                    value="existing"
                    control={<Radio style={{ color: "#ffb722" }} />}
                  />
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Choose an existing web login"}
                  </Typography>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="status"
                  value={data?.status}
                  onChange={(e) => setData({ ...data, status: e.target.value })}
                  className="flex-row align-items-center"
                >
                  <FormControlLabel
                    className="mr-2"
                    value="new"
                    control={<Radio style={{ color: "#ffb722" }} />}
                  />
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Create new web login"}
                  </Typography>
                </RadioGroup>
              </FormControl>
            </Grid>
            {data.status && data.status === "existing" ? (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Existing Web Logins"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <FormControl fullWidth={true}>
                    <Select
                      variant="outlined"
                      fullWidth={true}
                      displayEmpty
                      margin="dense"
                      defaultValue={"Select Web Login"}
                      required={true}
                      onChange={(e) => {
                        props.setData({
                          ...props.data,
                          associated_logins_attributes: [
                            { web_login_id: e.target.value },
                          ],
                        });
                        props.setWebLogin(
                          docs.filter((login) => login.id === e.target.value)[0]
                        );
                      }}
                      name="docs"
                    >
                      <MenuItem value={"Select Web Login"} disabled>
                        Select Web Login
                      </MenuItem>
                      {docs
                        ?.sort((a, b) =>
                          a?.description.localeCompare(b?.description)
                        )
                        ?.map((state, index) => {
                          return (
                            <MenuItem value={state.id} key={index}>
                              {state?.description
                                ? truncateString(state?.description, 40)
                                : state?.user_name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            ) : (
              <>
                <div className={classes.modalBody + " border-1 border"}>
                  <div className="w-100 d-flex flex-row mb-5">
                    <Typography
                      variant="p"
                      component="p"
                      align="left"
                      style={{ color: "#060d5c", fontWeight: "normal" }}
                    >
                      {"Web Login > New"}
                    </Typography>
                  </div>
                  {err && (
                    <div className="w-100 d-flex flex-row mb-5">
                      <Typography
                        variant="p"
                        component="p"
                        align="left"
                        style={{ color: "red", fontWeight: "normal" }}
                      >
                        {err}
                      </Typography>
                    </div>
                  )}
                  <Grid
                    container
                    wrap="wrap"
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={4}
                  >
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography
                        variant="h6"
                        component="h3"
                        style={{ color: color.themeColorNew }}
                      >
                        {"Description *"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8} sm={8}>
                      <FormInput
                        id="visa_type"
                        name="description"
                        onChange={handleChange}
                        label=""
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                        required={true}
                        placeholder={"Description"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography
                        variant="h6"
                        component="h3"
                        style={{ color: color.themeColorNew }}
                      >
                        {"Username / Email*"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8} sm={8}>
                      <FormInput
                        id="visa_type"
                        name="user_name"
                        onChange={handleChange}
                        label=""
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                        required={true}
                        placeholder={"Username / Email"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography
                        variant="h6"
                        component="h3"
                        style={{ color: color.themeColorNew }}
                      >
                        {"Password"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8}>
                      <FormInput
                        id="visa_type"
                        name="password"
                        type="text"
id="txtPassword"
                        onChange={handleChange}
                        label=""
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                        required={true}
                        placeholder={"Password"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography
                        variant="h6"
                        component="h3"
                        style={{ color: color.themeColorNew }}
                      >
                        {"Website URL"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8}>
                      {validations.url && (
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeRed }}
                        >
                          {validations.url}
                        </Typography>
                      )}
                      <FormInput
                        id="class"
                        name="site_url"
                        onChange={handleChange}
                        label=""
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                        required={true}
                        placeholder={"Website URL"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <Typography
                        variant="h6"
                        component="h3"
                        style={{ color: color.themeColorNew }}
                      >
                        {"Notes"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8}>
                      <FormInput
                        id="class"
                        name="notes"
                        onChange={handleChange}
                        label=""
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                        required={true}
                        placeholder={"Notes"}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className="text-sm-center"
                      style={{ textAlign: "right" }}
                    >
                      {!docIsLoading ? (
                        <CustomButton
                          type="submit"
                          btnText="Save"
                          textAlign="center"
                          display={"block"}
                          padding="8px 25px"
                          background="#0042a8"
                          hoverBg="#0042a8"
                          border="0"
                          borderRadius="8px"
                          color="#fff"
                          onClick={handleSubmitt}
                          disabled={
                            !dataa.description ||
                            !dataa.user_name ||
                            validations.url
                          }
                        />
                      ) : (
                        <CustomButton
                          btnText={<CircularProgress size={20} color="#DDD" />}
                          type="submit"
                          textAlign="center"
                          display={"block"}
                          padding="8px 25px"
                          background="#0042a8"
                          hoverBg="#0042a8"
                          border="0"
                          borderRadius="8px"
                          color="#fff"
                        />
                      )}
                    </Grid>
                  </Grid>
                </div>
              </>
            )}
            {data.status === "existing" && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                className="text-sm-center"
                style={{ textAlign: "right" }}
              >
                {!docIsLoading ? (
                  <CustomButton
                    type="submit"
                    btnText="Save"
                    textAlign="center"
                    display={"block"}
                    padding="8px 25px"
                    background="#0042a8"
                    hoverBg="#0042a8"
                    border="0"
                    borderRadius="8px"
                    color="#fff"
                    onClick={props.handleClose}
                  />
                ) : (
                  <CustomButton
                    btnText={<CircularProgress size={20} color="#DDD" />}
                    type="submit"
                    textAlign="center"
                    display={"block"}
                    padding="8px 25px"
                    background="#0042a8"
                    hoverBg="#0042a8"
                    border="0"
                    borderRadius="8px"
                    color="#fff"
                  />
                )}
              </Grid>
            )}
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseWebLogins);
