import React, { useEffect } from "react";

const DisableAutoFill = () => {
  useEffect(() => {
    document.autocomplete = "new-password";
  }, []);

  return null;
};

export default DisableAutoFill;
