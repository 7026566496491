import "date-fns";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField, Typography } from "@material-ui/core";
import styles from "../../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close, ErrorRounded, WarningRounded } from "@material-ui/icons";
import color from "../../../../assets/css/color.js";
import CustomModal from "../../../../components/Modal/Modal.js";
import ModalHeader from "../../../../components/Modal/ModalHeader.js";
import {
  setLoading,
  rerenderEmployer,
} from "../../../../store/actions/loadingAction";
import { CustomButton } from "../../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";

const useStyles = makeStyles(styles);
function SureToAdd(props) {
  const classes = useStyles();

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%"
        margin="250px 0 40px"
        inset="15px!important"
      >
        <ModalHeader
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          extraclass="p-3"
          closeModal={props.handleClose}
          headerBgColor={color.themeColorNew}
          color="white"
        />
        <div className={classes.modalBody}>
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            className="px-4"
          >
            <div className="w-100 text-center">
              <div className="d-flex justify-content-center align-items-center">
                <ErrorRounded
                  className="me-2"
                  style={{ color: color.themeYellow, fontSize: "50px" }}
                />
                <Typography
                  variant="h6"
                  component="h3"
                  className="mb-0"
                  style={{ color: "#060d5c", fontWeight: "bold" }}
                >
                  {"Alert"}
                </Typography>
              </div>
              <Typography
                variant="h6"
                component="h3"
                align="center"
                className="mb-4"
                style={{ color: "#060d5c", fontWeight: "normal" }}
              >
                {props.alertMessage ? props.alertMessage : "Are you sure you want to make these changes."}
              </Typography>
            </div>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              <CustomButton
                className="me-0 me-sm-2 mb-2 mb-sm-0"
                btnText="Yes"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="2px solid #0042a8"
                borderRadius="8px"
                color="#fff"
                width="100px"
                onClick={() => props.handleSubmit()}
              />
              {!props.isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Cancel"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="transparent"
                  hoverBg="#0042a8"
                  border="2px solid #0042a8"
                  borderRadius="8px"
                  color="#0042a8"
                  width="100px"
                  onClick={props.handleClose}
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="transparent"
                  hoverBg="#0042a8"
                  border="2px solid #060d5c"
                  borderRadius="8px"
                  color="#060d5c"
                  width="100px"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

export default SureToAdd;
