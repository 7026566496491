import React, { useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CustomButton } from "../../components/CustomButton/Button";
import taglogo from "../../assets/img/tag-logo.png";
import { FormInput } from "../../components/FormInput/FormInput";
import {
  clearError,
  login,
  sendOtp,
  setLoadAnimation,
} from "../../store/actions/authAction";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../../assets/animations/2FAPart1.json";
import {
  fadeOutDown,
  slideOutDown,
  zoomIn,
  fadeOutUp,
  slideOutRight,
} from "react-animations";
import Radium, { StyleRoot } from "radium";

const Otp = (props) => {
  const history = useHistory();
  const [animation, setAnimation] = React.useState(false);
  const [loadAnimation, setLoadAnimation] = React.useState(false);
  const [radius, setRadius] = React.useState(false);
  const [finalAnimation, setFinalAnimation] = React.useState(false);
  // const [classNames, setClassNames] = useState("");
  const [state, setState] = React.useState({
    otp: null,
  });

  React.useEffect(() => {
    props.setLoadAnimation(true);
    setTimeout(() => {
      props.setLoadAnimation(false);
      setRadius(true);
    }, 1500);
  }, []);

  React.useEffect(() => {});

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleSubmit = () => {
    const data = {
      email: localStorage.getItem("email"),
      password: localStorage.getItem("password"),
      remember_me: localStorage.getItem("remember_me"),
      otp: state.otp,
    };
    props?.login(data, history);
  };

  React.useEffect(() => {
    return () => {
      props.resetErrorState(false);
    };
  }, []);
  const useStyles = makeStyles((theme) => ({}));
  const classes = useStyles();

  useEffect(() => {
    // setAnimation(true);
    // setTimeout(() => {
    //   setAnimation(false);
    // }, 1500);
  }, []);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const styless = {
    bounce: {
      animation: "100 6s",
      animationName: Radium.keyframes(fadeOutUp, "fadeOutDown"),
    },
    bounceImg: {
      animation: "100 10s",
      animationName: Radium.keyframes(slideOutDown, "slideOutDown"),
    },
    fadeImg: {
      animation: "100 10s",
      animationName: Radium.keyframes(fadeOutDown, "fadeOutDown"),
    },
    zoomInImg: {
      animation: "100 10s",
      animationName: Radium.keyframes(zoomIn, "zoomIn"),
    },
    slideOutRight: {
      animation: "100 2.5s",
      animationName: Radium.keyframes(slideOutRight, "slideOutRight"),
    },
  };

  return (
    <div>
      {/* <StyleRoot> */}
      {/* <Lottie
            style={{position:'absolute',top:'0',left:'0'}}
            options={defaultOptions}
            height={'100%'}
            width={'100%'}
            // speed={0.001}
            // isStopped={isStopped}
            // isPaused={isPaused}
            isClickToPauseDisabled={true}
          /> */}
      <div
      // style={props.animation ? styless.slideOutRight : {}}
      >
        <div
        // style={radius ? { borderRadius: 0 } : null}
        // className={
        //   !props.loadAnimation ? "background-yellow" : "zoom zoomIn"
        // }
        ></div>

        <div
          className="d-flex flex-column"
          // style={[
          //   animation
          //     ? [styless.bounce, { zIndex: 100000, marginTop: 50 }]
          //     : { marginTop: -300 },
          // ]}
        >
          <img
            src={taglogo}
            alt="s"
            width={80}
            style={{
              margin: "0 auto",
              marginBottom: "25px",
              // zIndex: 100000,
              // position: "absolute",
              // top: "30%",
              // left: "47%",
            }}
          />
          <div
          // style={{ zIndex: 100000 }}
          >
            <Box
              mb={1}
              textAlign="center"
              //  style={{ zIndex: 100000 }}
            >
              {/* {animation && */}
              {/* <img
                    src={taglogo}
                    alt="s"
                    width={80}
                    style={{marginBottom: "25px"}}
                /> */}
              {/* } */}
              <Typography variant="h5" component="h3" align="center">
                {"Two Factor Authentication"}
              </Typography>
            </Box>
            {props?.authError?.length > 0 ? (
              props?.authError?.map((e, k) => {
                return (
                  <Box
                    mb={3}
                    // style={{ zIndex: 100000 }}
                  >
                    <Typography variant="p" component="p" color="error">
                      {e}
                    </Typography>
                  </Box>
                );
              })
            ) : props?.authError ? (
              <Box
                mb={3}
                // style={{ zIndex: 100000 }}
              >
                <Typography variant="p" component="p" color="error">
                  {props?.authError}
                </Typography>
              </Box>
            ) : null}
            <Box
              mb={3}
              //  style={{ zIndex: 100000 }}
            >
              <FormInput
                id="email"
                name="otp"
                // label="Enter OTP"
                variant="outlined"
                fullWidth={true}
                style={{ width: "100%", color: "#000" }}
                margin="dense"
                required={true}
                onChange={handleChange}
                fieldClassName="fieldTopZero mt-0 otp-bg-white"
              />
              <Typography variant="p" component="p" align="left">
                {"Please enter OTP code sent to your device."}
              </Typography>
            </Box>
            <CustomButton
              type="submit"
              fullWidth={true}
              btnText="Send"
              disabled={props?.loading}
              textAlign="center"
              display={"block"}
              marginBottom="18px"
              padding="16px 17px"
              background="#0042a8"
              hoverBg="#0042a8"
              border="0"
              borderRadius="8px"
              color="#fff"
              onClick={handleSubmit}
            />
            <button
              style={{
                background: "none",
                border: "none",
              }}
              onClick={() => {
                props.sendOtp(
                  {
                    email: localStorage.getItem("email"),
                    password: localStorage.getItem("password"),
                    remember_me: localStorage.getItem("remember_me"),
                    is_login:
                      localStorage.getItem("is_login") === "true"
                        ? true
                        : false,
                  },
                  props.history
                );
              }}
              disabled={props?.loading}
            >
              Resend OTP
            </button>
          </div>
        </div>
      </div>
      {/* </StyleRoot> */}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data, props) => dispatch(login(data, props)),
    sendOtp: (data, props) => dispatch(sendOtp(data, props)),
    resetErrorState: (msg) => dispatch(clearError(msg)),
    setLoadAnimation: (bool) => dispatch(setLoadAnimation(bool)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state?.auth?.loading,
    authError: state?.auth?.error,
    animation: state?.auth?.animation,
    loadAnimation: state?.auth?.loadAnimation,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Otp);
