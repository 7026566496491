import "date-fns";
import React from "react";
import debounce from "lodash.debounce";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useTheme,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { FinanceService } from "../../../services/finance.service.js";
import AddDocument from "../AddDocument/AddDocument";
import ChooseWebLogins from "../ChooseWebLogins/ChooseWebLogins";

const stateArray = [
  "Bi-Weekly",
  "Monthly",
  "Quarterly",
  "Semi-Annually",
  "Annually",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const cashTypes = [
  "CD",
  "Checking",
  "Currency",
  "Money Market",
  "Savings",
  "Other",
];
const useStyles = makeStyles(styles);

function AddCashAccount(props) {
  const classes = useStyles();
  const financeService = new FinanceService();
  const [webLogin, setWebLogin] = React.useState(null);
  const [webLoginModal, setWebLoginModal] = React.useState(false);
  const [err, setErr] = React.useState([]);
  const [doc, setDoc] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [data, setData] = React.useState({
    description: "",
    institution: "",
    account_number: "",
    cash_type: "",
    balance: "",
    domestic_wire_transfer: "",
    international_wire_transfer: "",
    direct_deposit: "",
    owner: "self",
    ownership_description: "",
    notes: "",

    associated_documents_attributes: [],
    associated_logins_attributes: [
      {
        web_login_id: null,
      },
    ],
  });
  const [documentModal, setDocumentModal] = React.useState(false);

  const handleDocumentModal = () => {
    setDocumentModal(true);
  };
  const handleSubmit = () => {
    let formData = new FormData();
    formData.append("description", data.description);
    formData.append("institution", data.institution);
    data.account_number &&
      formData.append("account_number", data.account_number);
    formData.append("cash_type", data.cash_type);
    formData.append("balance", data.balance);
    formData.append("direct_deposit", data.direct_deposit);
    formData.append(
      "international_wire_transfer",
      data.international_wire_transfer
    );
    formData.append("domestic_wire_transfer", data.domestic_wire_transfer);
    formData.append("notes", data.notes);
    if (data.associated_documents_attributes?.length > 0) {
      for (let i = 0; i < data.associated_documents_attributes?.length; i++) {
        formData.append(
          "associated_documents_attributes[][document_id]",
          data.associated_documents_attributes[i]?.document_id ||
            data.associated_documents_attributes[i]?.id
        );
      }
    }
    if (data.associated_logins_attributes[0].web_login_id) {
      formData.append(
        "associated_logins_attributes[][web_login_id]",
        JSON.stringify(data.associated_logins_attributes[0].web_login_id)
      );
    }

    setIsLoading(true);
    financeService
      .createCashAcounts(formData)
      .then((response) => {
        setIsLoading(false);
        props.forceRerender();
        props.handleClose();

        console.log(response.data);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response && err.response.data.message) {
          if (err.response?.data?.message === "Please enter a valid date") {
            setErr([err.response?.data?.message]);
          } else {
            let array = [];
            Object.keys(err.response.data.message).forEach((key) =>
              array.push(
                key === "institution"
                  ? `Institution ${err.response.data.message[key]}`
                  : key === "description"
                  ? `Description ${err.response.data.message[key]}`
                  : `${key} ${err.response.data.message[key]}`
              )
            );
            setErr(array);
          }
        } else {
          setErr(["Internal Server Error"]);
        }
      });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = debounce((event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  }, 300);

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChangeCountry = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Add New Cash Account"}
          color={color.white}
        />
        <div className={classes.modalBody}>
          <div className="w-100 d-flex flex-row mb-4">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Finance > Cash Accounts > New"}
            </Typography>
          </div>
          {err?.length > 0 && (
            <div className="w-100 d-flex flex-column mb-4">
              {err?.map((val, key) => {
                return (
                  <Typography
                    key={key}
                    variant="p"
                    component="p"
                    align="left"
                    style={{
                      color: "red",
                      fontWeight: "normal",
                      marginBottom: "2px",
                    }}
                  >
                    {val}
                  </Typography>
                );
              })}
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            {documentModal && (
              <AddDocument
                setData={setData}
                setDoc={setDoc}
                associated_documents_attributes={false}
                doc={doc}
                data={data}
                open={documentModal}
                handleClose={() => setDocumentModal(false)}
                closeModal={() => setDocumentModal(false)}
                category={"Finance"}
              />
            )}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Cash Type *"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="id_type"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  onChange={(e) => {
                    setData({
                      ...data,
                      cash_type: e.target.value,
                    });
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled>Select Cash Type</MenuItem>
                  {cashTypes.map((t) => {
                    return <MenuItem value={t}>{t}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Institution *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="institution"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Institution"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Description *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="description"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Description"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Balance"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="balance"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                type="number"
                margin="dense"
                required={true}
                placeholder={"0.00"}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Account Number"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormInput
                name="account_number"
                onChange={handleChange}
                label=""
                variant="outlined"
                type="number"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Account number"}
              />
            </Grid>
            <div className={"w-100 m-2 border p-4"}>
              <Typography
                variant="h6"
                component="h3"
                style={{
                  color: color.themeColorNew,
                  textAlign: "center",
                  padding: "1rem",
                }}
              >
                {"Routing Numbers"}
              </Typography>
              <Grid item xs={12} sm={12} md={12} className="pb-0">
                <Typography
                  variant="h6"
                  component="h3"
                  style={{
                    color: color.themeColorNew,
                  }}
                >
                  {"Domestic Wire Transfer"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <FormInput
                  name="domestic_wire_transfer"
                  onChange={handleChange}
                  label=""
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  placeholder={"Domestic Wire Transfer"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} className="pb-0">
                <Typography
                  variant="h6"
                  component="h3"
                  style={{ color: color.themeColorNew }}
                >
                  {"International Wire Transfer"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <FormInput
                  name="international_wire_transfer"
                  onChange={handleChange}
                  label=""
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  placeholder={"International Wire Transfer"}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} className="pb-0">
                <Typography
                  variant="h6"
                  component="h3"
                  style={{ color: color.themeColorNew }}
                >
                  {"Direct Deposit"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} sm={12}>
                <FormInput
                  name="direct_deposit"
                  onChange={handleChange}
                  label=""
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  placeholder={"Direct Deposit"}
                />
              </Grid>
            </div>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Owner"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="owner"
                  name="owner"
                  value={data?.owner}
                  onChange={handleChange}
                  className="flex-row"
                >
                  <FormControlLabel
                    value="Self"
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Self"
                  />
                  <FormControlLabel
                    value="Joint"
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Joint"
                  />
                  <FormControlLabel
                    value="Other"
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {data.owner === "Other" && (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Owner Description"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormInput
                    id="class"
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    name="ownership_description"
                    placeholder={"Ownership description"}
                    onChange={handleChange}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Notes"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="class"
                name="notes"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Notes"}
              />
            </Grid>
            {webLoginModal && (
              <ChooseWebLogins
                setData={setData}
                setWebLogin={setWebLogin}
                data={data}
                open={webLoginModal}
                webLogin={data?.associated_logins_attributes[0]?.web_login_id}
                handleClose={() => setWebLoginModal(false)}
                closeModal={() => setWebLoginModal(false)}
                category={"Personal"}
              />
            )}
            {webLogin && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Web Login"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {webLogin?.user_name}
                  </Typography>
                  <CustomButton
                    btnText={"Remove"}
                    textAlign="center"
                    display={"block"}
                    background="transparent"
                    hoverBg="#0042a8"
                    border="1px solid #0042a8"
                    borderRadius="8px"
                    color="#0042a8"
                    onClick={() => {
                      setWebLogin(null);
                      setData({
                        ...data,
                        associated_logins_attributes: [
                          {
                            web_login_id: null,
                          },
                        ],
                      });
                    }}
                  />
                </Grid>
              </>
            )}
            {doc.length > 0 && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Document"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                  {doc.map((e, k) => (
                    <>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                        key={k}
                      >
                        {e?.title}
                      </Typography>
                      <CustomButton
                        btnText={"Remove"}
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          let newDoc = doc?.filter((d) => d.id !== e.id);
                          setDoc(newDoc);
                          setData({
                            ...data,
                            associated_documents_attributes: newDoc,
                          });
                        }}
                      />
                    </>
                  ))}
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              {!webLogin && (
                <CustomButton
                  className="mb-sm-2"
                  btnText="Add Web Login"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  marginRight={"10px"}
                  onClick={() => setWebLoginModal(true)}
                />
              )}
              <CustomButton
                btnText="Add Document"
                className="mb-sm-2"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
                marginRight={"10px"}
                onClick={handleDocumentModal}
              />
              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  onClick={handleSubmit}
                  disabled={
                    data.description === "" &&
                    !data.cash_type &&
                    !data.institution
                      ? true
                      : false
                  }
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCashAccount);
