import "date-fns";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { CheckBox, Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { DocumentService } from "../../../services/document.service";
import AlertPopup from "../Alerts/Generic/GenericAlert";
import { useHistory } from "react-router-dom";
import Progress from "../../../components/Progress/Progress.js";

const array = [
  "Personal",
  "Health",
  "Employment",
  "Finance",
  "Insurance",
  "Vehicle",
  "Property",
  "Education",
];
const useStyles = makeStyles(styles);

function DocumentCategory(props) {
  const history = useHistory();
  const classes = useStyles();
  const documentService = new DocumentService();
  const [err, setErr] = React.useState(null);
  const [loadingValue, setLoadingValue] = useState(0);
  const [category, setCategory] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [passwordProtected, setPasswordProtected] = React.useState(false);
  const [alert, setAlert] = React.useState({
    open: false,
    type: null,
    btnText: null,
    cancelText: null,
    heading: null,
    message: null,
  });
  const [title, setTitle] = React.useState(null);
  const [docs, setDocs] = React.useState(null);
  const [documentModal, setDocumentModal] = useState(false);
  const [docIsLoading, setDocIsLoading] = React.useState(false);
  const handleDocumentModal = () => {
    setDocumentModal(true);
  };

  React.useEffect(() => {
    setTitle(props?.file?.name);
  }, [props?.file]);

  const handleSubmit = () => {
    const fd = new FormData();
    fd.append("attachment", props.file);
    fd.append("title", title);
    fd.append("category", category);
    fd.append("password_protected", JSON.stringify(passwordProtected));
    passwordProtected && fd.append("password", password);
    const data = {
      title: props.file?.name,
      category: category,
      attachment: props?.file,
    };
    console.log({ data });
    setDocIsLoading(true);
    documentService
      .add(fd, (progress) => {
        setLoadingValue(progress);
      })
      .then((response) => {
        setDocIsLoading(false);
        setLoadingValue(0);
        setAlert({
          type: "Success",
          open: true,
          btnText: "VIEW",
          cancelText: "OKAY",
          heading: "Success!",
          message: "Your file was successfully uploaded",
        });
      })
      .catch((err) => {
        setDocIsLoading(false);
        setLoadingValue(0);
        setAlert({
          type: "Error",
          open: true,
          btnText: "RETRY",
          cancelText: "CANCEL",
          heading:
            err?.response && err?.response?.status === 422
              ? "Unauthorized!"
              : "Something went wrong!",
          message:
            err?.response && err?.response?.status === 422
              ? "Wrong password! please try again."
              : "We're sorry there was an error and your file could not be uploaded. Would you like to try again?",
        });
      });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%"
        margin="auto"
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Add Document"}
          color={color.white}
        />
        <div className={classes.modalBody}>
          {err && (
            <div className="w-100 d-flex flex-row mb-5">
              <Typography
                variant="p"
                component="p"
                align="left"
                style={{ color: "red", fontWeight: "normal" }}
              >
                {err}
              </Typography>
            </div>
          )}
          {alert.open && (
            <AlertPopup
              btnText={alert.btnText}
              cancelText={alert.cancelText}
              open={alert.open}
              type={alert.type}
              heading={alert.heading}
              message={alert.message}
              isLoading={false}
              handleClose={() => {
                setAlert({
                  open: false,
                  btnText: null,
                  cancelText: null,
                  heading: null,
                  message: null,
                  type: null,
                });
                props.handleClose();
              }}
              handleClick={() => {
                if (alert.type === "Error") {
                  setAlert({
                    open: false,
                    btnText: null,
                    cancelText: null,
                    heading: null,
                    message: null,
                    type: null,
                  });
                } else {
                  setAlert({
                    open: false,
                    btnText: null,
                    cancelText: null,
                    heading: null,
                    message: null,
                    type: null,
                  });
                  props.setFile(null);
                  props.forceRerender();
                  props.handleClose();
                  history.push("/user/all-documents");
                }
              }}
            />
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Document Name"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormInput
                onChange={(e) => setTitle(e.target.value)}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={props?.file?.name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Dimension"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="id_type"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  onChange={handleChange}
                  name="category"
                  defaultValue={"Select Dimension"}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={"Select Dimension"} disabled>
                    Select Dimension
                  </MenuItem>
                  {array.map((cat) => {
                    return <MenuItem value={cat}>{cat}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pt-0">
              {/* <FormControlLabel
                control={
                  <CheckBox
                    checked={passwordProtected}
                    onChange={() => setPasswordProtected(!passwordProtected)}
                    style={{ color: "#ffb722" }}
                    name={"checkedA"}
                  />
                }
                label="Select if you want to password protect this document."
              /> */}
              <FormControlLabel
                className="align-items-start"
                control={
                  <Checkbox
                    name="checkedA"
                    checked={passwordProtected}
                    style={{ color: "#ffb722", paddingTop: 0 }}
                    onChange={(e) => setPasswordProtected(e.target.checked)}
                  />
                }
                label={"Select if you want to password protect this document."}
              />
            </Grid>
            {passwordProtected && (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Account Password *"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <FormInput
                    onChange={(e) => setPassword(e.target.value)}
                    label=""
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    type="text"
                    id="txtPassword"
                    inputProps={{
                      autoComplete: "new-password",
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center d-flex flex-row"
              style={{ textAlign: "right" }}
            >
              <CustomButton
                type="submit"
                btnText="Back"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                marginRight="10px"
                borderRadius="8px"
                color="#fff"
                onClick={() => props.back()}
              />
              {!docIsLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  marginRight="0px"
                  disabled={!passwordProtected ? !title : !password}
                  borderRadius="8px"
                  color="#fff"
                  onClick={handleSubmit}
                />
              ) : (
                <Progress value={loadingValue} />
              )}

              {/* <CustomButton
                onClick={() => props.handleClose()}
                btnText="Cancel"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="transparent"
                hoverBg="#0042a8"
                border="1px solid #0042a8"
                borderRadius="8px"
                color="#0042a8"
              /> */}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentCategory);
