import phone from "phone";
import { store } from "../store/store";

const countries = [
  { name: "Afghanistan", code: "AF", format: "dd/MM/yyyy" },
  { name: "land Islands", code: "AX", format: "MM/dd/yyyy" },
  { name: "Albania", code: "AL", format: "dd/MM/yyyy" },
  { name: "Algeria", code: "DZ", format: "dd/MM/yyyy" },
  { name: "American Samoa", code: "AS", format: "MM/dd/yyyy" },
  { name: "AndorrA", code: "AD", format: "dd/MM/yyyy" },
  { name: "Angola", code: "AO", format: "dd/MM/yyyy" },
  { name: "Anguilla", code: "AI", format: "dd/MM/yyyy" },
  { name: "Antarctica", code: "AQ", format: "dd/MM/yyyy" },
  { name: "Antigua and Barbuda", code: "AG", format: "dd/MM/yyyy" },
  { name: "Argentina", code: "AR", format: "dd/MM/yyyy" },
  { name: "Armenia", code: "AM", format: "dd/MM/yyyy" },
  { name: "Aruba", code: "AW", format: "dd/MM/yyyy" },
  { name: "Australia", code: "AU", format: "dd/MM/yyyy" },
  { name: "Austria", code: "AT", format: "dd/MM/yyyy" },
  { name: "Azerbaijan", code: "AZ", format: "dd/MM/yyyy" },
  { name: "Bahamas", code: "BS", format: "dd/MM/yyyy" },
  { name: "Bahrain", code: "BH", format: "dd/MM/yyyy" },
  { name: "Bangladesh", code: "BD", format: "dd/MM/yyyy" },
  { name: "Barbados", code: "BB", format: "dd/MM/yyyy" },
  { name: "Belarus", code: "BY", format: "dd/MM/yyyy" },
  { name: "Belgium", code: "BE", format: "dd/MM/yyyy" },
  { name: "Belize", code: "BZ", format: "dd/MM/yyyy" },
  { name: "Benin", code: "BJ", format: "dd/MM/yyyy" },
  { name: "Bermuda", code: "BM", format: "dd/MM/yyyy" },
  { name: "Bhutan", code: "BT", format: "dd/MM/yyyy" },
  { name: "Bolivia", code: "BO", format: "dd/MM/yyyy" },
  { name: "Bosnia and Herzegovina", code: "BA", format: "dd/MM/yyyy" },
  { name: "Botswana", code: "BW", format: "dd/MM/yyyy" },
  { name: "Bouvet Island", code: "BV", format: "dd/MM/yyyy" },
  { name: "Brazil", code: "BR", format: "dd/MM/yyyy" },
  { name: "British Indian Ocean Territory", code: "IO", format: "dd/MM/yyyy" },
  { name: "Brunei Darussalam", code: "BN", format: "dd/MM/yyyy" },
  { name: "Bulgaria", code: "BG", format: "dd/MM/yyyy" },
  { name: "Burkina Faso", code: "BF", format: "dd/MM/yyyy" },
  { name: "Burundi", code: "BI", format: "dd/MM/yyyy" },
  { name: "Cambodia", code: "KH", format: "dd/MM/yyyy" },
  { name: "Cameroon", code: "CM", format: "dd/MM/yyyy" },
  { name: "Canada", code: "CA", format: "MM/dd/yyyy" },
  { name: "Cape Verde", code: "CV", format: "dd/MM/yyyy" },
  { name: "Cayman Islands", code: "KY", format: "MM/dd/yyyy" },
  { name: "Central African Republic", code: "CF", format: "dd/MM/yyyy" },
  { name: "Chad", code: "TD", format: "dd/MM/yyyy" },
  { name: "Chile", code: "CL", format: "dd/MM/yyyy" },
  { name: "China", code: "CN", format: "dd/MM/yyyy" },
  { name: "Christmas Island", code: "CX", format: "dd/MM/yyyy" },
  { name: "Cocos (Keeling) Islands", code: "CC", format: "dd/MM/yyyy" },
  { name: "Colombia", code: "CO", format: "dd/MM/yyyy" },
  { name: "Comoros", code: "KM", format: "dd/MM/yyyy" },
  { name: "Congo", code: "CG", format: "dd/MM/yyyy" },
  {
    name: "Congo, The Democratic Republic of the",
    code: "CD",
    format: "dd/MM/yyyy",
  },
  { name: "Cook Islands", code: "CK", format: "dd/MM/yyyy" },
  { name: "Costa Rica", code: "CR", format: "dd/MM/yyyy" },
  { name: "Cote D'Ivoire", code: "CI", format: "dd/MM/yyyy" },
  { name: "Croatia", code: "HR", format: "dd/MM/yyyy" },
  { name: "Cuba", code: "CU", format: "dd/MM/yyyy" },
  { name: "Cyprus", code: "CY", format: "dd/MM/yyyy" },
  { name: "Czech Republic", code: "CZ", format: "dd/MM/yyyy" },
  { name: "Denmark", code: "DK", format: "dd/MM/yyyy" },
  { name: "Djibouti", code: "DJ", format: "dd/MM/yyyy" },
  { name: "Dominica", code: "DM", format: "dd/MM/yyyy" },
  { name: "Dominican Republic", code: "DO", format: "dd/MM/yyyy" },
  { name: "Ecuador", code: "EC", format: "dd/MM/yyyy" },
  { name: "Egypt", code: "EG", format: "dd/MM/yyyy" },
  { name: "El Salvador", code: "SV", format: "dd/MM/yyyy" },
  { name: "Equatorial Guinea", code: "GQ", format: "dd/MM/yyyy" },
  { name: "Eritrea", code: "ER", format: "MM/dd/yyyy" },
  { name: "Estonia", code: "EE", format: "dd/MM/yyyy" },
  { name: "Ethiopia", code: "ET", format: "dd/MM/yyyy" },
  { name: "Falkland Islands (Malvinas)", code: "FK", format: "dd/MM/yyyy" },
  { name: "Faroe Islands", code: "FO", format: "dd/MM/yyyy" },
  { name: "Fiji", code: "FJ", format: "dd/MM/yyyy" },
  { name: "Finland", code: "FI", format: "MM/dd/yyyy" },
  { name: "France", code: "FR", format: "dd/MM/yyyy" },
  { name: "French Guiana", code: "GF", format: "dd/MM/yyyy" },
  { name: "French Polynesia", code: "PF", format: "dd/MM/yyyy" },
  { name: "French Southern Territories", code: "TF", format: "dd/MM/yyyy" },
  { name: "Gabon", code: "GA", format: "dd/MM/yyyy" },
  { name: "Gambia", code: "GM", format: "dd/MM/yyyy" },
  { name: "Georgia", code: "GE", format: "dd/MM/yyyy" },
  { name: "Germany", code: "DE", format: "dd/MM/yyyy" },
  { name: "Ghana", code: "GH", format: "MM/dd/yyyy" },
  { name: "Gibraltar", code: "GI", format: "dd/MM/yyyy" },
  { name: "Greece", code: "GR", format: "dd/MM/yyyy" },
  { name: "Greenland", code: "GL", format: "MM/dd/yyyy" },
  { name: "Grenada", code: "GD", format: "dd/MM/yyyy" },
  { name: "Guadeloupe", code: "GP", format: "dd/MM/yyyy" },
  { name: "Guam", code: "GU", format: "MM/dd/yyyy" },
  { name: "Guatemala", code: "GT", format: "dd/MM/yyyy" },
  { name: "Guernsey", code: "GG", format: "dd/MM/yyyy" },
  { name: "Guinea", code: "GN", format: "MM/dd/yyyy" },
  { name: "Guinea-Bissau", code: "GW", format: "dd/MM/yyyy" },
  { name: "Guyana", code: "GY", format: "dd/MM/yyyy" },
  { name: "Haiti", code: "HT", format: "dd/MM/yyyy" },
  {
    name: "Heard Island and Mcdonald Islands",
    code: "HM",
    format: "dd/MM/yyyy",
  },
  { name: "Holy See (Vatican City State)", code: "VA", format: "dd/MM/yyyy" },
  { name: "Honduras", code: "HN", format: "dd/MM/yyyy" },
  { name: "Hong Kong", code: "HK", format: "dd/MM/yyyy" },
  { name: "Hungary", code: "HU", format: "dd/MM/yyyy" },
  { name: "Iceland", code: "IS", format: "dd/MM/yyyy" },
  { name: "India", code: "IN", format: "dd/MM/yyyy" },
  { name: "Indonesia", code: "ID", format: "dd/MM/yyyy" },
  { name: "Iran, Islamic Republic Of", code: "IR", format: "dd/MM/yyyy" },
  { name: "Iraq", code: "IQ", format: "dd/MM/yyyy" },
  { name: "Ireland", code: "IE", format: "dd/MM/yyyy" },
  { name: "Isle of Man", code: "IM", format: "dd/MM/yyyy" },
  { name: "Israel", code: "IL", format: "dd/MM/yyyy" },
  { name: "Italy", code: "IT", format: "dd/MM/yyyy" },
  { name: "Jamaica", code: "JM", format: "dd/MM/yyyy" },
  { name: "Japan", code: "JP", format: "dd/MM/yyyy" },
  { name: "Jersey", code: "JE", format: "dd/MM/yyyy" },
  { name: "Jordan", code: "JO", format: "dd/MM/yyyy" },
  { name: "Kazakhstan", code: "KZ", format: "dd/MM/yyyy" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI", format: "dd/MM/yyyy" },
  {
    name: "Korea, Democratic People'S Republic of",
    code: "KP",
    format: "dd/MM/yyyy",
  },
  { name: "Korea, Republic of", code: "KR", format: "dd/MM/yyyy" },
  { name: "Kuwait", code: "KW", format: "dd/MM/yyyy" },
  { name: "Kyrgyzstan", code: "KG", format: "dd/MM/yyyy" },
  {
    name: "Lao People'S Democratic Republic",
    code: "LA",
    format: "dd/MM/yyyy",
  },
  { name: "Latvia", code: "LV", format: "dd/MM/yyyy" },
  { name: "Lebanon", code: "LB", format: "dd/MM/yyyy" },
  { name: "Lesotho", code: "LS", format: "dd/MM/yyyy" },
  { name: "Liberia", code: "LR", format: "dd/MM/yyyy" },
  { name: "Libyan Arab Jamahiriya", code: "LY", format: "dd/MM/yyyy" },
  { name: "Liechtenstein", code: "LI", format: "dd/MM/yyyy" },
  { name: "Lithuania", code: "LT", format: "dd/MM/yyyy" },
  { name: "Luxembourg", code: "LU", format: "dd/MM/yyyy" },
  { name: "Macao", code: "MO", format: "dd/MM/yyyy" },
  {
    name: "Macedonia, The Former Yugoslav Republic of",
    code: "MK",
    format: "dd/MM/yyyy",
  },
  { name: "Madagascar", code: "MG", format: "dd/MM/yyyy" },
  { name: "Malawi", code: "MW", format: "dd/MM/yyyy" },
  { name: "Malaysia", code: "MY", format: "dd/MM/yyyy" },
  { name: "Maldives", code: "MV", format: "dd/MM/yyyy" },
  { name: "Mali", code: "ML", format: "dd/MM/yyyy" },
  { name: "Malta", code: "MT", format: "dd/MM/yyyy" },
  { name: "Marshall Islands", code: "MH", format: "MM/dd/yyyy" },
  { name: "Martinique", code: "MQ", format: "dd/MM/yyyy" },
  { name: "Mauritania", code: "MR", format: "dd/MM/yyyy" },
  { name: "Mauritius", code: "MU", format: "dd/MM/yyyy" },
  { name: "Mayotte", code: "YT", format: "dd/MM/yyyy" },
  { name: "Mexico", code: "MX", format: "dd/MM/yyyy" },
  { name: "Micronesia, Federated States of", code: "FM", format: "dd/MM/yyyy" },
  { name: "Moldova, Republic of", code: "MD", format: "dd/MM/yyyy" },
  { name: "Monaco", code: "MC", format: "dd/MM/yyyy" },
  { name: "Mongolia", code: "MN", format: "dd/MM/yyyy" },
  { name: "Montenegro", code: "ME", format: "dd/MM/yyyy" },
  { name: "Montserrat", code: "MS", format: "dd/MM/yyyy" },
  { name: "Morocco", code: "MA", format: "dd/MM/yyyy" },
  { name: "Mozambique", code: "MZ", format: "dd/MM/yyyy" },
  { name: "Myanmar", code: "MM", format: "dd/MM/yyyy" },
  { name: "Namibia", code: "NA", format: "dd/MM/yyyy" },
  { name: "Nauru", code: "NR", format: "dd/MM/yyyy" },
  { name: "Nepal", code: "NP", format: "dd/MM/yyyy" },
  { name: "Netherlands", code: "NL", format: "dd/MM/yyyy" },
  { name: "Netherlands Antilles", code: "AN", format: "dd/MM/yyyy" },
  { name: "New Caledonia", code: "NC", format: "dd/MM/yyyy" },
  { name: "New Zealand", code: "NZ", format: "dd/MM/yyyy" },
  { name: "Nicaragua", code: "NI", format: "dd/MM/yyyy" },
  { name: "Niger", code: "NE", format: "dd/MM/yyyy" },
  { name: "Nigeria", code: "NG", format: "dd/MM/yyyy" },
  { name: "Niue", code: "NU", format: "dd/MM/yyyy" },
  { name: "Norfolk Island", code: "NF", format: "dd/MM/yyyy" },
  { name: "Northern Mariana Islands", code: "MP", format: "dd/MM/yyyy" },
  { name: "Norway", code: "NO", format: "dd/MM/yyyy" },
  { name: "Oman", code: "OM", format: "dd/MM/yyyy" },
  { name: "Pakistan", code: "PK", format: "dd/MM/yyyy" },
  { name: "Palau", code: "PW", format: "dd/MM/yyyy" },
  { name: "Palestinian Territory, Occupied", code: "PS", format: "dd/MM/yyyy" },
  { name: "Panama", code: "PA", format: "dd/MM/yyyy" },
  { name: "Papua New Guinea", code: "PG", format: "dd/MM/yyyy" },
  { name: "Paraguay", code: "PY", format: "dd/MM/yyyy" },
  { name: "Peru", code: "PE", format: "dd/MM/yyyy" },
  { name: "Philippines", code: "PH", format: "dd/MM/yyyy" },
  { name: "Pitcairn", code: "PN", format: "dd/MM/yyyy" },
  { name: "Poland", code: "PL", format: "dd/MM/yyyy" },
  { name: "Portugal", code: "PT", format: "dd/MM/yyyy" },
  { name: "Puerto Rico", code: "PR", format: "dd/MM/yyyy" },
  { name: "Qatar", code: "QA", format: "dd/MM/yyyy" },
  { name: "Reunion", code: "RE", format: "dd/MM/yyyy" },
  { name: "Romania", code: "RO", format: "dd/MM/yyyy" },
  { name: "Russian Federation", code: "RU", format: "dd/MM/yyyy" },
  { name: "RWANDA", code: "RW", format: "dd/MM/yyyy" },
  { name: "Saint Helena", code: "SH", format: "dd/MM/yyyy" },
  { name: "Saint Kitts and Nevis", code: "KN", format: "dd/MM/yyyy" },
  { name: "Saint Lucia", code: "LC", format: "dd/MM/yyyy" },
  { name: "Saint Pierre and Miquelon", code: "PM", format: "dd/MM/yyyy" },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    format: "dd/MM/yyyy",
  },
  { name: "Samoa", code: "WS", format: "dd/MM/yyyy" },
  { name: "San Marino", code: "SM", format: "dd/MM/yyyy" },
  { name: "Sao Tome and Principe", code: "ST", format: "dd/MM/yyyy" },
  { name: "Saudi Arabia", code: "SA", format: "dd/MM/yyyy" },
  { name: "Senegal", code: "SN", format: "dd/MM/yyyy" },
  { name: "Serbia", code: "RS", format: "dd/MM/yyyy" },
  { name: "Seychelles", code: "SC", format: "dd/MM/yyyy" },
  { name: "Sierra Leone", code: "SL", format: "dd/MM/yyyy" },
  { name: "Singapore", code: "SG", format: "dd/MM/yyyy" },
  { name: "Slovakia", code: "SK", format: "dd/MM/yyyy" },
  { name: "Slovenia", code: "SI", format: "dd/MM/yyyy" },
  { name: "Solomon Islands", code: "SB", format: "dd/MM/yyyy" },
  { name: "Somalia", code: "SO", format: "dd/MM/yyyy" },
  { name: "South Africa", code: "ZA", format: "dd/MM/yyyy" },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "GS",
    format: "dd/MM/yyyy",
  },
  { name: "Spain", code: "ES", format: "dd/MM/yyyy" },
  { name: "Sri Lanka", code: "LK", format: "dd/MM/yyyy" },
  { name: "Sudan", code: "SD", format: "dd/MM/yyyy" },
  { name: "Suriname", code: "SR", format: "dd/MM/yyyy" },
  { name: "Svalbard and Jan Mayen", code: "SJ", format: "dd/MM/yyyy" },
  { name: "Swaziland", code: "SZ", format: "dd/MM/yyyy" },
  { name: "Sweden", code: "SE", format: "dd/MM/yyyy" },
  { name: "Switzerland", code: "CH", format: "dd/MM/yyyy" },
  { name: "Syrian Arab Republic", code: "SY", format: "dd/MM/yyyy" },
  { name: "Taiwan, Province of China", code: "TW", format: "dd/MM/yyyy" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ", format: "dd/MM/yyyy" },
  { name: "Thailand", code: "TH", format: "dd/MM/yyyy" },
  { name: "Timor-Leste", code: "TL", format: "dd/MM/yyyy" },
  { name: "Togo", code: "TG", format: "dd/MM/yyyy" },
  { name: "Tokelau", code: "TK", format: "dd/MM/yyyy" },
  { name: "Tonga", code: "TO", format: "dd/MM/yyyy" },
  { name: "Trinidad and Tobago", code: "TT", format: "dd/MM/yyyy" },
  { name: "Tunisia", code: "TN", format: "dd/MM/yyyy" },
  { name: "Turkey", code: "TR", format: "dd/MM/yyyy" },
  { name: "Turkmenistan", code: "TM", format: "dd/MM/yyyy" },
  { name: "Turks and Caicos Islands", code: "TC", format: "dd/MM/yyyy" },
  { name: "Tuvalu", code: "TV", format: "dd/MM/yyyy" },
  { name: "Uganda", code: "UG", format: "dd/MM/yyyy" },
  { name: "Ukraine", code: "UA", format: "dd/MM/yyyy" },
  { name: "United Arab Emirates", code: "AE", format: "dd/MM/yyyy" },
  { name: "United Kingdom", code: "GB", format: "dd/MM/yyyy" },
  { name: "United States", code: "US", format: "MM/dd/yyyy" },
  {
    name: "United States Minor Outlying Islands",
    code: "UM",
    format: "MM/dd/yyyy",
  },
  { name: "Uruguay", code: "UY", format: "dd/MM/yyyy" },
  { name: "Uzbekistan", code: "UZ", format: "dd/MM/yyyy" },
  { name: "Vanuatu", code: "VU", format: "dd/MM/yyyy" },
  { name: "Venezuela", code: "VE", format: "dd/MM/yyyy" },
  { name: "Viet Nam", code: "VN", format: "MM/dd/yyyy" },
  { name: "Virgin Islands, British", code: "VG", format: "dd/MM/yyyy" },
  { name: "Virgin Islands, U.S.", code: "VI", format: "MM/dd/yyyy" },
  { name: "Wallis and Futuna", code: "WF", format: "dd/MM/yyyy" },
  { name: "Western Sahara", code: "EH", format: "dd/MM/yyyy" },
  { name: "Yemen", code: "YE", format: "dd/MM/yyyy" },
  { name: "Zambia", code: "ZM", format: "dd/MM/yyyy" },
  { name: "Zimbabwe", code: "ZW", format: "dd/MM/yyyy" },
];

export const dateFormat = () => {
  if (`+${store?.getState().auth?.user?.phone}`?.slice(0, 3) === "+44") {
    return "dd/MM/yyyy";
  } else {
    return (
      countries?.filter(
        (country) =>
          country.code ===
          phone(`+${store?.getState().auth.user.phone}`).countryIso2
      )[0]?.format || "MM/dd/yyyy"
    );
  }
};
