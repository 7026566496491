import React from "react";
import { useHistory } from "react-router-dom";
import { HistoryService } from "../../services/history.service";

const RightClickMenu = (
  { x, y, showMenu, name, option, id, setScrollVisible },
  props
) => {
  const history = useHistory();
  const historyService = new HistoryService();
  const handleClick = () => {
    historyService
      .favouritesCreate(name)
      .then((res) => {
        setScrollVisible();
        history.push("/");
      })
      .catch((err) => {
        setScrollVisible();
        console.log("====================================");
        console.log({ error: err.response.data });
        console.log("====================================");
      });
  };
  const handleDelete = () => {
    historyService
      .favouritesDestroy(id)
      .then((res) => {
        setScrollVisible();
        history.push("/");
      })
      .catch((err) => {
        setScrollVisible();
        console.log("====================================");
        console.log({ error: err.response.data });
        console.log("====================================");
      });
  };
  const style = () => {
    return {
      width: 150,
      borderRadius: 10,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      backgroundColor: "#f3f3f3",
      color: "#FCD2D1",
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: y,
      left: x,
      display: showMenu ? "flex" : "none",
      zIndex: "1000",
    };
  };
  return (
    <div style={style()}>
      <div style={stylee.div} onClick={id ? handleDelete : handleClick}>
        {id ? "Remove from favorites" : "Add to favorites"}
      </div>
      {props?.children}
    </div>
  );
};
const stylee = {
  div: {
    color: "#000000",
    fontWeight: "normal",
    cursor: "pointer",
    padding: "10px 0",
    // border: "1px solid #0042a8",
    // borderRadius: 8,
    // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    // // cursor: "pointer",
  },
  borderBottomZero: {
    borderBottom: "0",
  },
};

export default RightClickMenu;
