import "date-fns";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";
import styles from "../../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import CustomModal from "../../../../components/Modal/Modal.js";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(styles);

function SpinnerModal(props) {
    const classes = useStyles();

    return (
        <>
            <CustomModal
                visible={props.open}
                onClose={props.handleClose}
                width="35%"
                margin="250px 0 40px"
                inset="15px!important"
            >
                <div className={classes.modalBody}>
                    <Grid
                        container
                        wrap="wrap"
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                        className="px-4"
                    >
                        <div className="w-100 text-center">
                            <CircularProgress size={50} color="#DDD"/>
                        </div>
                        <div className="w-100 text-center">
                            <Typography
                                variant="h6"
                                component="h3"
                                align="center"
                                className="mb-4"
                                style={{color: "#060d5c", fontWeight: "normal"}}
                            >
                                {props.msg}
                            </Typography>
                        </div>
                    </Grid>
                </div>
            </CustomModal>
        </>
    );
}

export default SpinnerModal;
