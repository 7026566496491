import React, { useEffect, useState } from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { PersonalService } from "../../../services/personal.service";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs";

const useStyles = makeStyles(styles);

function RevealPassword(props) {
  const pwdRef = React.useRef();
  const personalService = new PersonalService();
  const classes = useStyles();

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  useEffect(() => {}, []);

  const [password, setPassword] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [reveal, setReveal] = useState(null);
  const [err, setErr] = useState(null);

  const handleSubmit = async () => {
    setErr(null);
    setIsLoading(true);
    const temp = {
      id: props.id,
      password: password,
      subcategory_name: props.subcategory_name,
    };
    try {
      let response = await personalService.masking(temp);
      if (!props?.share) {
        setReveal(response.data.message);
        setIsLoading(false);
      } else {
        localStorage.setItem("reveal", response.data.message);
        props?.callShare();
        setIsLoading(false);
        props?.handleClose();
      }
    } catch (e) {
      setIsLoading(false);
      setErr(e.response ? e?.response?.data?.message : "something went wrong");
    }
  };

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={
            props?.share
              ? "Unmask"
              : props.subcategory_name === "PersonalInformation"
              ? "Reveal Social Security Number"
              : props.subcategory_name === "Expense" ||
                props?.subcategory_name === "Mortgage" ||
                props?.subcategory_name === "CashAccount"
              ? "Reveal Account Number"
              : "Reveal Password"
          }
          color={color.white}
        />
        <div className={classes.modalBody} aria-autocomplete="none">
          {err && (
            <div className="w-100 d-flex flex-row mb-5">
              <Typography
                variant="p"
                component="p"
                align="left"
                style={{ color: "red", fontWeight: "normal" }}
              >
                {err}
              </Typography>
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            {!props.share ? (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {props.subcategory_name === "PersonalInformation"
                      ? "Social Security Number"
                      : props.subcategory_name === "Expense" ||
                        props?.subcategory_name === "Mortgage" ||
                        props?.subcategory_name === "MarginAccount" ||
                        props?.subcategory_name === "NonmarketableSecurity" ||
                        props?.subcategory_name === "MarketableSecurity" ||
                        props?.subcategory_name === "CashAccount"
                      ? "Account Number"
                      : "Password"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  {reveal ? (
                    <Typography
                      variant="h6"
                      component="h3"
                      style={{ color: "#00BDD5" }}
                    >
                      {reveal}
                    </Typography>
                  ) : (
                    <Typography variant="h6" component="h3">
                      {props.password}
                    </Typography>
                  )}
                </Grid>
              </>
            ) : (
              <div className="w-100 d-flex flex-row mb-3">
                <Typography
                  variant="p"
                  component="p"
                  align="left"
                  style={{ color: "red", fontWeight: "normal" }}
                >
                  Please enter your password to unmask the sensitive information
                  in share.
                </Typography>
              </div>
            )}
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Account Password*"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <FormInput
                onChange={(e) => setPassword(e.target.value)}
                // value={password}
                autoComplete={"new-password"}
                name="reveal-password"
                type="text"
                id="txtPassword"
                inputProps={{
                  autoComplete: "new-password",
                }}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Reveal"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  onClick={handleSubmit}
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

export default RevealPassword;
