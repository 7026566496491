import React, { useEffect, useState } from "react";
// @material-ui/icons
import { HashRouter as Router, NavLink, Route, Switch, useHistory } from "react-router-dom";
import { useRouteName } from "../../hooks";
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import { HistoryService } from "../../services/history.service";
import { store } from "../../store/store";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import { connect } from "react-redux";
import Favorite from "../../components/AccountSettingTabs/AccountSettingTabsContent/Favorite";
import RecentlyViewed from "../../components/TabPanel/RecentlyViewed/RecentlyViewed";
import Documents from "../../components/AccountSettingTabs/AccountSettingTabsContent/Documents";
import ReceivedDocuments from "../../components/AccountSettingTabs/AccountSettingTabsContent/ReceivedDocuments";
import Contacts from "../../components/AccountSettingTabs/AccountSettingTabsContent/Contacts";
import { CustomButton } from "../../components/CustomButton/Button";
import AddFavorite from "../../containers/Modals/AddFavorite/AddFavorite";
import ActiveAlerts from "../../components/AccountSettingTabs/AccountSettingTabsContent/ActiveAlerts";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  indicator: {
    background: "none",
  },

  tabsMain: {
    marginBottom: "50px",
    "& .MuiTabs-flexContainer": {
      overflow: "auto",
    },
    "& ::-webkit-scrollbar": {
      height: "0",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: "#0042a8",
      color: "white",
    },
    "& Button": {
      background: "#fff",
      borderRadius: "10px",
      marginRight: "15px",
      backgroundColor: "#fff",
      minWidth: "160px",
      maxWidth: "250px",
      width: "max-content",
      color: "black",
      opacity: 1,
      textTransform: "capitalize",
      "&:hover, &:active, &:focus": {
        backgroundColor: "#0042a8",
        color: "white",
        opacity: 1,
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
}));

const options = ["Edit", "View", "Download", "Share", "Delete"];

const Dashboard = (props) => {
  const [loading, setLoading] = useState(false);

  const [fav, setFav] = useState([]);
  const historyService = new HistoryService();
  const [addFav, setAddFav] = useState(false);
  const history = useHistory();
  const routeName = useRouteName();
  const classes = useStyles();

  const handleDelete = (id) => {
    historyService
      .favouritesDestroy(id)
      .then((res) => {
        setFav(fav.filter((f) => f.id !== id));
      })
      .catch((err) => {});
  };
  const changeRoute = (route) => {
    history.push(route);
  };
  // useEffect(() => {}, [store.getState().profile.profileInfo, props?.profile]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const anchorRef = React.useRef(null);

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };


  const routes = [
    "/user/favorite",
    "/user/recently-viewed",
    "/user/all-documents",
    "/user/received-documents",
    "/user/all-contacts",
    "/user/active-alerts",
  ];
  // const [value, setValue] = useState(0);

  useEffect(() => {
    // Update value based on current route
    const currentRouteIndex = routes.findIndex(
      (route) => route === history.location.pathname
    );
    setValue(currentRouteIndex === -1 ? 0 : currentRouteIndex);
  }, [history.location.pathname]);
  return (
    <div>
      <Grid
        container
        wrap="wrap"
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          md={12}
          style={{
            marginTop: "12px",
          }}
        >
          {
            props.profile.profileInfo ? <>
            {/* <Route
              path={"/user"}
              render={(h) => {
                return (
                  <AppBar position="static" color="transparent" elevation="0">
                    <Tabs
                      value={value}
                      // onChange={handleChange}
                      aria-label="simple tabs example"
                      // className="tabsMain"
                      className={classes.tabsMain}
                    >
                      <NavLink to={routes[0]}>
                        <Tab
                          value={routes[0]}
                          style={{
                            cursor: "pointer",
                            color:
                              window.location.pathname === routes[0] && "#fff",
                            backgroundColor:
                              window.location.pathname === routes[0] &&
                              "#0042a8",
                          }}
                          label="Favorites"
                          // onClick={() => history.push(routes[0])}
                        />
                      </NavLink>
                      <NavLink to={routes[1]}>
                        <Tab
                          style={{
                            cursor: "pointer",
                            color:
                              window.location.pathname === routes[1] && "#fff",
                            backgroundColor:
                              window.location.pathname === routes[1] &&
                              "#0042a8",
                          }}
                          value={routes[1]}
                          // to={routes[1]}
                          // component={Link}
                          label="Recently Viewed"
                        />
                      </NavLink>
                      <NavLink to={routes[2]}>
                        <Tab
                          value={routes[2]}
                          // to={routes[2]}
                          style={{
                            cursor: "pointer",
                            color:
                              window.location.pathname === routes[2] && "#fff",
                            backgroundColor:
                              window.location.pathname === routes[2] &&
                              "#0042a8",
                          }}
                          // component={Link}
                          // className="dashboardTabsBtn smallCircle"
                          // onClick={() => history.push(routes[2])}

                          label="All Documents"
                        />
                      </NavLink>

                      <NavLink to={routes[3]}>
                        <Tab
                          value={routes[3]}
                          // to={routes[3]}
                          style={{
                            cursor: "pointer",
                            color:
                              window.location.pathname === routes[3] && "#fff",
                            backgroundColor:
                              window.location.pathname === routes[3] &&
                              "#0042a8",
                          }}
                          // component={Link}

                          // className="dashboardTabsBtn smallCircle"
                          label="Received Documents"
                        />
                      </NavLink>
                      <NavLink to={routes[4]}>
                        <Tab
                          value={routes[4]}
                          style={{
                            cursor: "pointer",
                            color:
                              window.location.pathname === routes[4] && "#fff",
                            backgroundColor:
                              window.location.pathname === routes[4] &&
                              "#0042a8",
                          }}
                          // to={routes[4]}
                          // component={Link}
                          // className="dashboardTabsBtn smallCircle"
                          label="All Contacts"
                        />
                      </NavLink>
                      <NavLink to={routes[5]}>
                        <Tab
                          value={routes[5]}
                          style={{
                            cursor: "pointer",
                            color:
                              window.location.pathname === routes[5] && "#fff",
                            backgroundColor:
                              window.location.pathname === routes[5] &&
                              "#0042a8",
                          }}
                          // to={routes[4]}
                          // component={Link}
                          // className="dashboardTabsBtn smallCircle"
                          label="Active Alerts"
                        />
                      </NavLink>
                    </Tabs>
                  </AppBar>
                );
              }}
            /> */}
            <AppBar position="static" color="transparent" elevation="0">
                    <Tabs
                      value={value}
                      // onChange={handleChange}
                      aria-label="simple tabs example"
                      // className="tabsMain"
                      className={classes.tabsMain}
                    >
                      <NavLink to={routes[0]}>
                        <Tab
                          value={routes[0]}
                          style={{
                            cursor: "pointer",
                            color:
                              window.location.pathname === routes[0] && "#fff",
                            backgroundColor:
                              window.location.pathname === routes[0] &&
                              "#0042a8",
                          }}
                          label="Favorites"
                          // onClick={() => history.push(routes[0])}
                        />
                      </NavLink>
                      <NavLink to={routes[1]}>
                        <Tab
                          style={{
                            cursor: "pointer",
                            color:
                              window.location.pathname === routes[1] && "#fff",
                            backgroundColor:
                              window.location.pathname === routes[1] &&
                              "#0042a8",
                          }}
                          value={routes[1]}
                          // to={routes[1]}
                          // component={Link}
                          label="Recently Viewed"
                        />
                      </NavLink>
                      <NavLink to={routes[2]}>
                        <Tab
                          value={routes[2]}
                          // to={routes[2]}
                          style={{
                            cursor: "pointer",
                            color:
                              window.location.pathname === routes[2] && "#fff",
                            backgroundColor:
                              window.location.pathname === routes[2] &&
                              "#0042a8",
                          }}
                          // component={Link}
                          // className="dashboardTabsBtn smallCircle"
                          // onClick={() => history.push(routes[2])}

                          label="All Documents"
                        />
                      </NavLink>

                      <NavLink to={routes[3]}>
                        <Tab
                          value={routes[3]}
                          // to={routes[3]}
                          style={{
                            cursor: "pointer",
                            color:
                              window.location.pathname === routes[3] && "#fff",
                            backgroundColor:
                              window.location.pathname === routes[3] &&
                              "#0042a8",
                          }}
                          // component={Link}

                          // className="dashboardTabsBtn smallCircle"
                          label="Received Documents"
                        />
                      </NavLink>
                      <NavLink to={routes[4]}>
                        <Tab
                          value={routes[4]}
                          style={{
                            cursor: "pointer",
                            color:
                              window.location.pathname === routes[4] && "#fff",
                            backgroundColor:
                              window.location.pathname === routes[4] &&
                              "#0042a8",
                          }}
                          // to={routes[4]}
                          // component={Link}
                          // className="dashboardTabsBtn smallCircle"
                          label="All Contacts"
                        />
                      </NavLink>
                      <NavLink to={routes[5]}>
                        <Tab
                          value={routes[5]}
                          style={{
                            cursor: "pointer",
                            color:
                              window.location.pathname === routes[5] && "#fff",
                            backgroundColor:
                              window.location.pathname === routes[5] &&
                              "#0042a8",
                          }}
                          // to={routes[4]}
                          // component={Link}
                          // className="dashboardTabsBtn smallCircle"
                          label="Active Alerts"
                        />
                      </NavLink>
                    </Tabs>
                  </AppBar>
            <Switch>
              <Route
              exact
                path={routes[0]}
                render={() => {
                  return (
                    <div className="tabsContent">
                      <AddFavorite
                        open={addFav}
                        handleClose={() => setAddFav(false)}
                        closeModal={() => setAddFav(false)}
                      />
                      <Grid
                        container
                        wrap="wrap"
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        style={{ margin: "25px 0" }}
                      >
                        <Grid
                          item
                          xs={6}
                          md={6}
                          style={{ margin: "25px 0" }}
                          container
                          direction="row"
                          className="flex_col"
                        >
                          <Typography
                            variant="h6"
                            component="h3"
                            style={{ color: "#060d5c" }}
                          >
                            Favorites
                          </Typography>
                          <CustomButton
                            type="submit"
                            btnText="Add"
                            textAlign="center"
                            display={"block"}
                            padding="8px 17px"
                            marginLeft="4rem"
                            background="#0042a8"
                            hoverBg="#0042a8"
                            border="0"
                            borderRadius="8px"
                            color="#fff"
                            onClick={() => setAddFav(true)}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          md={6}
                          style={{ textAlign: "right" }}
                        ></Grid>
                      </Grid>
                      <Favorite push={changeRoute} />
                    </div>
                  );
                }}
              />
              <Route
              exact
                path={routes[1]}
                render={() => {
                  return (
                    <div className="tabsContent">
                      <RecentlyViewed />
                    </div>
                  );
                }}
              />
              <Route
              exact
                path={routes[2]}
                render={() => {
                  return (
                    <div className="tabsContent">
                      <Grid item xs={12} md={12} style={{ margin: "25px 0" }}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: "#060d5c" }}
                        >
                          All Documents
                        </Typography>
                      </Grid>
                      <Documents
                        handleMenuItemClick={handleMenuItemClick}
                        selectedIndex={selectedIndex}
                        handleClose={handleClose}
                        // anchoRef={anchorRef.current}
                        open={open}
                        handleToggle={handleToggle}
                        options={options}
                      />
                    </div>
                  );
                }}
              />
              <Route
              exact
                path={routes[3]}
                render={() => {
                  return (
                    <div className="tabsContent">
                      <Grid item xs={12} md={12} style={{ margin: "25px 0" }}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: "#060d5c" }}
                        >
                          Received Documents
                        </Typography>
                      </Grid>
                      <ReceivedDocuments
                        handleMenuItemClick={handleMenuItemClick}
                        selectedIndex={selectedIndex}
                        handleClose={handleClose}
                        anchoRef={anchorRef.current}
                        open={open}
                        handleToggle={handleToggle}
                        options={options}
                      />
                    </div>
                  );
                }}
              />
              <Route
              exact
                path={routes[4]}
                render={() => {
                  return (
                    <div className="tabsContent">
                      <Grid item xs={12} md={12} style={{ margin: "25px 0" }}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: "#060d5c" }}
                        >
                          All Contacts
                        </Typography>
                      </Grid>
                      <Contacts
                        handleMenuItemClick={handleMenuItemClick}
                        selectedIndex={selectedIndex}
                        handleClose={handleClose}
                        anchoRef={anchorRef.current}
                        open={open}
                        handleToggle={handleToggle}
                        options={options}
                      />
                    </div>
                  );
                }}
              />
              <Route
              exact
                path={routes[5]}
                render={() => {
                  return (
                    <div className="tabsContent">
                      <Grid item xs={12} md={12} style={{ margin: "25px 0" }}>
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: "#060d5c" }}
                        >
                          Active Alerts
                        </Typography>
                      </Grid>
                      <ActiveAlerts push={changeRoute} />
                    </div>
                  );
                }}
              />
            </Switch>
          </> :
          <CircularProgress size={20} color="#DDD" />
          }
        </Grid>
        <Grid item xs={12} md={12}></Grid>
      </Grid>
      <Grid
        container
        wrap="wrap"
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={12} style={{ margin: "25px 0" }}></Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    sort: state.sort?.sort,
    profile: state?.profile,
  };
};

export default connect(mapStateToProps)(Dashboard);
