import "date-fns";
import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useTheme,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { dateFormat } from "../../../Utils/dateFormate.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { HealthService } from "../../../services/health.service.js";
import AddDocument from "../AddDocument/AddDocument";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { getDateIgnoreTimezone } from "../../../Utils/dateHelper.js";

const stateArray = ["Mild", "Moderate", "Severe", "Debilitating"];

const relationship = [
  "1/2 Brother (Father)",
  "1/2 Brother (Mother)",
  "1/2 Sister (Father)",
  "1/2 Sister (Mother)",
  "Brother",
  "Cousin (maternal)",
  "Cousin (paternal)",
  "Daughter",
  "Father",
  "Maternal 1/2 Aunt (Grandfather)",
  "Maternal 1/2 Aunt (Grandmother)",
  "Maternal 1/2 Uncle (Grandfather)",
  "Maternal 1/2 Uncle (Grandmother)",
  "Maternal Aunt",
  "Maternal Grandfather",
  "Maternal Grandmother",
  "Maternal Uncle",
  "Mother",
  "Nephew",
  "Niece",
  "Paternal 1/2 Aunt (Grandmother)",
  "Paternal 1/2 Aunt (Grandfather)",
  "Paternal 1/2 Uncle (Grandfather)",
  "Paternal 1/2 Uncle (Grandmother)",
  "Paternal Aunt",
  "Paternal Grandfather",
  "Paternal Grandmother",
  "Paternal Uncle",
  "Sister",
  "Son",
  "Other",
];
const ancestries = [
  "African",
  "African American",
  "Albanian",
  "American",
  "American Indian",
  "Arab",
  "Armenian",
  "Ashkenazi Jewish",
  "Asian",
  "Asian Indian",
  "Austrian",
  "Belgian",
  "Black/African",
  "Brazilian",
  "British",
  "Cajun",
  "Cambodian",
  "Canadian",
  "Chinese",
  "Colombian",
  "Croatian",
  "Cuban",
  "Czech",
  "Czechoslovakian",
  "Danish",
  "Dominican",
  "Dutch",
  "Ecuadorian",
  "Egyptian",
  "English",
  "European",
  "Filipino",
  "Finnish",
  "French",
  "French Canadian",
  "German",
  "Greek",
  "Guamanian",
  "Guatemalan",
  "Guyanese",
  "Haitian",
  "Hawaiian",
  "Hispanic",
  "Hmong",
  "Honduran",
  "Hungarian",
  "Iranian",
  "Irish",
  "Israeli",
  "Italian",
  "Jamaican",
  "Japanese",
  "Korean",
  "Laotian",
  "Latin American",
  "Lebanese",
  "Lithuanian",
  "Mexican",
  "Native American",
  "Nicaraguan",
  "Nigerian",
  "Northern European",
  "Norwegian",
  "Other/Mixed",
  "Pacific Islander",
  "Pakistani",
  "Panamanian",
  "Pennsylvania German",
  "Peruvian",
  "Polish",
  "Portuguese",
  "Puerto Rican",
  "Romanian",
  "Russian",
  "Salvadoran",
  "Samoans",
  "Scandinavian",
  "Scotch-Irish",
  "Scottish",
  "Sephardic Jewish",
  "Serbian",
  "Slavic",
  "Slovak",
  "Slovene",
  "Southeast Asian",
  "Spaniard",
  "Spanish",
  "Swedish",
  "Swiss",
  "Syrian",
  "Taiwanese",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Vietnamese",
  "Welsh",
  "West Indian",
  "Western European",
  "White/Caucasian",
  "Yugoslavian",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles(styles);

function AddFamilyCondition(props) {
  const classes = useStyles();
  const healthService = new HealthService();
  const [err, setErr] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [doc, setDoc] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [other, setOther] = React.useState(false);
  const errRef = useRef(null);
  const [data, setData] = React.useState({
    family_condition_name: "",
    severity: "",
    relative_name: "",
    relation: "",
    gender: "male",
    region: "",
    birth_date: null,
    start_date: null,
    born_with_twin: false,
    adopted: false,
    conceived_by_donor: false,
    alive: true,
    attachments: "",
    notes: "",
    associated_documents_attributes: [],
  });
  const [documentModal, setDocumentModal] = React.useState(false);

  const handleDocumentModal = () => {
    setDocumentModal(true);
  };

  const handleSubmit = () => {
    let formData = new FormData();
    formData.append("family_condition_name", data.family_condition_name);
    formData.append("severity", data.severity);
    formData.append("relative_name", data.relative_name);
    formData.append("relation", data.relation);
    formData.append("gender", data.gender);
    formData.append("region", data.region);
    data.birth_date &&
      formData.append(
        "birth_date",
        getDateIgnoreTimezone(data.birth_date).toISOString().slice(0, 10)
      );
    formData.append("born_with_twin", data.born_with_twin);
    formData.append("adopted", data.adopted);
    data.start_date &&
      formData.append(
        "start_date",
        getDateIgnoreTimezone(data.start_date).toISOString().slice(0, 10)
      );
    formData.append("conceived_by_donor", data.conceived_by_donor);
    formData.append("alive", data.alive);
    formData.append("notes", data.notes);
    if (data.associated_documents_attributes?.length > 0) {
      for (let i = 0; i < data.associated_documents_attributes?.length; i++) {
        formData.append(
          "associated_documents_attributes[][document_id]",
          data.associated_documents_attributes[i]?.document_id ||
            data.associated_documents_attributes[i]?.id
        );
      }
    }

    setIsLoading(true);
    healthService
      .createFamilyConditions(formData)
      .then((response) => {
        setIsLoading(false);
        props.forceRerender();
        props.handleClose();

        console.log(response.data);
      })
      .catch((err) => {
        errRef.current.scrollIntoView();
        setIsLoading(false);
        if (err.response && err.response.data.message) {
          if (err.response?.data?.message === "Please enter a valid date") {
            setErr([err.response?.data?.message]);
          } else {
            let array = [];
            Object.keys(err.response.data.message).forEach((key) => {
              const arr = err.response.data.message[key];
              arr.forEach((val) => {
                array.push(`${key} ${val}`);
              });
            });
            setErr(array);
          }
        } else {
          setErr(["Internal Server Error"]);
        }
      });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = (event) => {
    if (event.target.name === "relation1") {
      if (event.target.value !== "Other") {
        setOther(false);
        setData({ ...data, relation: event.target.value });
      } else {
        setOther(true);
      }
    } else {
      setData({ ...data, [event.target.name]: event.target.value });
    }
  };

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChangeCountry = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Add New Family Condition"}
          color={color.white}
        />
        <div className={classes.modalBody} id="err">
          <div className="w-100 d-flex flex-row mb-4">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Health > Family Conditions > New"}
            </Typography>
          </div>
          {err?.length > 0 && (
            <div className="w-100 d-flex flex-column mb-4">
              {err?.map((val, key) => {
                return (
                  <Typography
                    key={key}
                    variant="p"
                    component="p"
                    align="left"
                    style={{
                      color: "red",
                      fontWeight: "normal",
                      marginBottom: "2px",
                    }}
                  >
                    {val.charAt(0).toUpperCase() + val.slice(1)}
                  </Typography>
                );
              })}
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Health Condition *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="family_condition_name"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Health Condition"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Severity"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="state"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  // value={"Select Severity"}
                  onChange={handleChange}
                  name="severity"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled>Select Severity</MenuItem>
                  {stateArray?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Relative Name"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="relative_name"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Relative Name"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Relationship"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="state"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  // value={data?.relation}
                  onChange={handleChange}
                  name="relation"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled>Select Relationship</MenuItem>
                  {relationship?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {other ? (
                <Grid item xs={12} sm={8} md={8}>
                  <FormInput
                    name="relation"
                    onChange={handleChange}
                    label=""
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    required={true}
                    placeholder={""}
                  />
                </Grid>
              ) : null}
            </Grid>

            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Date Diagnosed"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  inputVariant="outlined"
                  format={dateFormat()}
                  id="start_date"
                  placeholder={dateFormat()?.toUpperCase()}
                  value={data?.start_date}
                  onChange={(date) => {
                    setData({
                      ...data,
                      start_date: date,
                    });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Gender"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="gender"
                  value={data?.gender}
                  onChange={handleChange}
                  className="flex-row"
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Male"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Ethnicity"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="state"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={data?.region}
                  onChange={handleChange}
                  name="region"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  {ancestries?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Relative's Date of Birth"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  inputVariant="outlined"
                  format={dateFormat()}
                  id="start_date"
                  placeholder={dateFormat()?.toUpperCase()}
                  value={data?.birth_date}
                  onChange={(date) => {
                    setData({
                      ...data,
                      birth_date: date,
                    });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Was relative born a twin?"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="born_with_twin"
                  name="born_with_twin"
                  value={data?.born_with_twin}
                  onChange={(e) =>
                    setData({
                      ...data,
                      born_with_twin: e.target.value === "true" ? true : false,
                    })
                  }
                  className="flex-row"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Was relative adopted?"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="adopted"
                  name="adopted"
                  value={data?.adopted}
                  onChange={(e) =>
                    setData({
                      ...data,
                      adopted: e.target.value === "true" ? true : false,
                    })
                  }
                  className="flex-row"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Was relative conceived by a donor?"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="conceived_by_donor"
                  name="conceived_by_donor"
                  value={data?.conceived_by_donor}
                  onChange={(e) =>
                    setData({
                      ...data,
                      conceived_by_donor:
                        e.target.value === "true" ? true : false,
                    })
                  }
                  className="flex-row"
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Yes"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Existence"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="alive"
                  name="alive"
                  value={data?.alive}
                  onChange={(e) =>
                    setData({
                      ...data,
                      alive: e.target.value === "true" ? true : false,
                    })
                  }
                  className="flex-row"
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Deceased"
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Living"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Notes"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="notes"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Notes"}
              />
              {documentModal && (
                <AddDocument
                  setData={setData}
                  setDoc={setDoc}
                  associated_documents_attributes={false}
                  doc={doc}
                  data={data}
                  open={documentModal}
                  handleClose={() => setDocumentModal(false)}
                  closeModal={() => setDocumentModal(false)}
                  category={"Health"}
                />
              )}
            </Grid>

            {doc.length > 0 && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Document"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                  {doc.map((e, k) => (
                    <>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                        key={k}
                      >
                        {e?.title}
                      </Typography>
                      <CustomButton
                        btnText={"Remove"}
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          let newDoc = doc?.filter((d) => d.id !== e.id);
                          setDoc(newDoc);
                          setData({
                            ...data,
                            associated_documents_attributes: newDoc,
                          });
                        }}
                      />
                    </>
                  ))}
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              <CustomButton
                btnText="Add Document"
                className="mb-sm-2"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
                marginRight={"10px"}
                onClick={handleDocumentModal}
              />
              <a
                ref={errRef}
                href={"#err"}
                style={{
                  display: "none",
                }}
              >
                click
              </a>
              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  onClick={handleSubmit}
                  disabled={
                    data.family_condition_name === "" ||
                    !data.family_condition_name?.trim()
                      ? true
                      : false
                  }
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFamilyCondition);
