/*eslint-disable*/
import React, { useRef, useState } from "react";
import classNames from "classnames";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PropTypes from "prop-types";
import { NavLink, useHistory, useLocation } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { HistoryService } from "../../services/history.service.js";

import styles from "../../assets/jss/material-dashboard-react/components/sidebarStyle.js";
import useRightClickMenu from "../../hooks/useRightClickMenu.js";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { store } from "../../store/store";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import RightClickMenu from "../RightClickMenu/RightClickMenu.js";
import { Favorite } from "@material-ui/icons";
import Lottie from "react-lottie";
import animationData from "../../assets/animations/LogoAnimation.json";
import { useDispatch } from "react-redux";
import { setSearchQuery } from "../../store/actions/searchAction";
import { connect } from "react-redux";
import Tour from "../Tour/Tour.js";
import { CustomButton } from "../CustomButton/Button.js";
import NewLogo from "./../../assets/img/tag-logo.png";
import NewLogoWhite from "./../../assets/img/tag-logo-white.png";
import {
  bounce,
  fadeInDownBig,
  fadeOutDown,
  slideOutDown,
  slideOutUp,
  zoomIn,
  fadeInLeft,
} from "react-animations";
import Radium, { StyleRoot } from "radium";
import { setUserCount } from "../../store/actions/authAction.js";
import { colors } from "@material-ui/core";
import { useCallback } from "react";
import { useEffect } from "react";

const useStyles = makeStyles(styles);

const animationStyles = {
  fadeInLeft: {
    animation: "100 3s",
    animationName: Radium.keyframes(fadeInLeft, "fadeInLeft"),
  },
};
function Sidebar(props) {
  const drawerRef = useRef();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [subMenu, setsubMenu] = React.useState(false);
  const sRef = useRef();
  const anchorRef = React.useRef(null);
  const history = useHistory();
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [sub, setSub] = useState(null);
  const [currPath, setCurrPath] = useState(history.location.pathname);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const [favs, setFavs] = useState([]);
  const [matchPath, setMatchPath] = React.useState("");
  const [animation, setAnimation] = useState(false);
  const [subMenuItems, setSubMenuItems] = useState(null);
  const [renderCount, setRenderCount] = useState(
    props.sign_in_count === 2 ? 0 : 2
  );
  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };
  const ref = useRef(null);
  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setSubMenuItems(null);
    if (!props.rtlActivee) {
      setOpen(false);
      setsubMenu(false);
    } else {
      props.handleDrawerToggle();
    }
  };
  const [dimension, setDimension] = useState(null);

  const handleToggle = (event, name, prop) => {
    drawerRef.current.scrollIntoView();
    setDimension(prop);
    setSubMenuItems(prop.submenu);
    dispatch(setSearchQuery(""));
    // if (props.rtlActivee && subMenu) {
    //   if (currPath !== history.location.pathname) {
    //     setCurrPath(history.location.pathname);
    //     setTimeout(() => {
    //       setsubMenu(false);
    //       setOpen(false);
    //     }, 10);
    //   }
    // }
    // // prop?.name?.toLowerCase() === window.location.pathname.split("/")[2] ?? setOpen((prevOpen) => !prevOpen);
    // // console.log(prop?.name?.toLowerCase() === window.location.pathname.split("/")[2]);
    // // setOpen((prevOpen) => !prevOpen);
    // else {
    //   setsubMenu(true);
    // }
  };

  const historyService = new HistoryService();

  React.useEffect(() => {
    setAnimation(true);
    setTimeout(() => {
      setAnimation(false);
    }, 3000);
  }, []);

  React.useEffect(() => {
    if (!props.viewOnly && props.profile) {
      historyService
        .favouritesIndex(props.profile.id)
        .then((res) => {
          setFavs(res?.data?.favorites);
        })
        .catch((err) => {});
    }
  }, [open, props?.profile]);
  React.useEffect(() => {
    setRenderCount(props.sign_in_count === 2 ? 0 : 2);
  }, [props.sign_in_count]);
  const handleClose = (event, name) => {
    dispatch(setSearchQuery(""));

    if (!props.rtlActivee) {
      // if (anchorRef.current && anchorRef.current.contains(event.target)) {
      //     return;
      // }

      setOpen(false);
      setsubMenu(false);
    } else {
      // if (currPath !== history.location.pathname) {
      //     setOpen(false);
      //     setsubMenu(false);
      // }
      if (
        currPath !== history.location.pathname ||
        name?.toLowerCase() === matchPath?.split("/")[2]
      ) {
        setTimeout(() => {
          setsubMenu(false);
          setOpen(false);
        }, 100);
      }
    }
  };

  const [scrollVisible, setScrollVisible] = useState(false);

  const { x, y, showMenu } = useRightClickMenu();
  const classes = useStyles();
  let location = useLocation();

  const setScroll = useCallback(() => {
    if (!showMenu) {
      setScrollVisible(false);
    }
  }, [showMenu]);

  useEffect(() => {
    setScroll();
  }, [setScroll]);

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return location.pathname === routeName;
  }

  const { color, logo, image, routes } = props;

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const favIcon = (subb) => {
    if (favs && favs.length > 0) {
      let temp = favs?.filter((sub) => sub.subcategory === subb);
      if (temp.length > 0) {
        return <Favorite className="text-center text-danger favoriteCat" />;
      } else {
        return null;
      }
    }
  };

  const favId = (subb) => {
    let temp = favs?.filter((sub) => sub.subcategory === subb);
    if (temp && temp.length > 0) {
      return temp[0]?.id;
    } else {
      return null;
    }
  };
  var links = (
    <List className={classes.list + " sidebarmenu"}>
      {showTopBtn && <button>top</button>}
      {routes.map((prop, key) => {
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path),
        });
        return (
          <>
            <section
              // to={prop.layout + prop.path}
              className={classes.item}
              id={"sidebarid"}
              // activeClassName="active"

              ref={ref.current}
              onClick={() => {
                dispatch(setSearchQuery(""));

                setMatchPath(prop.layout + prop.path);
                if (props.plusOpen) {
                  props.plusToggle();
                  props.plusAchor(null);
                }
              }}
              key={key}
            >
              <ListItem
                button
                className={classes.itemLink}
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                value={prop.name}
                key={key}
                onClick={(event) => handleToggle(event, prop.name, prop)}
              >
                <img
                  style={{ marginBottom: "8px" }}
                  src={prop.iconImage}
                  height="25px"
                  alt=""
                />
                <ListItemText
                  key={key}
                  primary={prop.name}
                  className={classNames(classes.itemText, whiteFontClasses, {
                    [classes.itemTextRTL]: props.rtlActive,
                  })}
                  // onClick={() => }
                  disableTypography={true}
                />
              </ListItem>
            </section>
          </>
        );
      })}
    </List>
  );
  var subMenuItemsList = (
    <List className={classes.list + " sidebarmenu"}>
      {showTopBtn && <button>top</button>}
      {subMenuItems &&
        subMenuItems.map((option, index) => {
          const whiteFontClasses = classNames({
            [" " + classes.whiteFont]: activeRoute(
              dimension.layout + dimension.path
            ),
          });
          return (
            <>
              <section
                // to={prop.layout + prop.path}
                className={classes.item}
                id={"sidebarid"}
              >
                <ListItem
                  button
                  className={classes.itemLink}
                  aria-controls={open ? "split-button-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  value={option.name}
                  key={index}
                  onClick={() => props.openMobile ? props.handleDrawerToggleMobile() : props.handleDrawerToggle()}
                >
                  <div className="m-2">{favIcon(option.name)}</div>
                  {store.getState().auth?.isAuthenticated ? (
                    <NavLink
                      to={dimension.layout + dimension.path + option?.path}
                      onClick={() => setSubMenuItems(null)}
                    >
                      <div
                        onContextMenu={() => {
                          setScrollVisible(true);
                          setSub(option.sub);
                        }}
                      >
                        <div className="position-relative">
                          {sub === option?.sub && (
                            <RightClickMenu
                              setScrollVisible={() => setScrollVisible(false)}
                              x={"20px"}
                              y={"45px"}
                              key={index}
                              showMenu={showMenu}
                              name={sub}
                              id={favId(option.name)}
                            />
                          )}
                        </div>
                        <img
                          style={{ marginBottom: "8px" }}
                          src={option.subIconImage}
                          alt=""
                          height="25px"
                        />
                        <ListItemText
                          key={index}
                          primary={option.name}
                          className={classNames(
                            classes.itemText,
                            whiteFontClasses,
                            {
                              [classes.itemTextRTL]: props.rtlActive,
                            }
                          )}
                          disableTypography={true}
                        />
                      </div>
                    </NavLink>
                  ) : (
                    <div
                      onContextMenu={() => {
                        setScrollVisible(true);
                        setSub(option.sub);
                      }}
                    >
                      <div
                        // className="position-relative"
                        className="d-flex flex-column"
                        key={index}
                        // selected={index === selectedIndex}
                        onClick={(event) => {
                          handleMenuItemClick(event, index);
                        }}
                      >
                        <div className="position-relative ">
                          {sub === option?.sub && (
                            <RightClickMenu
                              x={"20px"}
                              y={"45px"}
                              key={index}
                              showMenu={showMenu}
                              name={sub}
                              id={favId(option.name)}
                            />
                          )}
                        </div>
                      </div>
                      <img
                        style={{ marginBottom: "8px" }}
                        src={option.subIconImage}
                        alt=""
                        height="25px"
                      />
                      <ListItemText
                        key={index}
                        primary={option.name}
                        className={classNames(
                          classes.itemText,
                          whiteFontClasses,
                          {
                            [classes.itemTextRTL]: props.rtlActive,
                          }
                        )}
                        disableTypography={true}
                      />
                    </div>
                  )}
                </ListItem>
              </section>
            </>
          );
        })}
    </List>
  );
  var brand = (
    <div className={classes.logo + " py-0"}>
      <NavLink
        to={"/user"}
        className={classNames(
          classes.logoLink,
          {
            [classes.logoLinkRTL]: props.rtlActive,
          },
          "logo"
        )}
      >
        <div
          className={
            classes.logoImage +
            " d-flex align-items-center justify-content-center"
          }
        >
          {/* <Lottie
            className="d-flex align-items-center"
            height={100}
            options={defaultOptions}
            isStopped={store.getState().auth.loginAnimation}
          /> */}
          {/* <img src={NewLogo} height="50" /> */}
          <StyleRoot>
            <img
              src={NewLogo}
              height="70"
              className="mt-2"
              style={animation ? animationStyles.fadeInLeft : {}}
            />
          </StyleRoot>
        </div>
      </NavLink>
    </div>
  );
  return (
    <div ref={drawerRef}>
      {image !== undefined ? (
        <div
          className={classes.background + " " + classes.displayNoneInMobile}
          style={{ backgroundImage: "url(" + image + ")" }}
        />
      ) : (
        <div className={classes.background} />
      )}
      {subMenuItems && (
        <div className="position-relative">
          {props.sign_in_count === 2 && renderCount === 0 && (
            // <Tour
            //   steps={[
            //     {
            //       element: ".categorylist",
            //       intro:
            //         "Right-click on an Information Type to favorite it and add it to your dashboard!",
            //       position: "right",
            //     },
            //   ]}
            // />
            <div
              style={{
                borderRadius: "20px",
              }}
            >
              <div className="toolTipSubMenu">
                <div className="p-2 text-white tooltipHeader">
                  <h6 className="fw-bold mb-0 text-capitalize">ToolTip</h6>
                </div>
                <div className="p-4 bg-white pb-2">
                  <p className="text-start">
                    {
                      "Right-click on an Information Type to favorite it and add it to your dashboard!"
                    }
                  </p>
                  <CustomButton
                    type=""
                    btnText="OKAY"
                    textAlign="center"
                    display={"block"}
                    onClick={() => {
                      dispatch(setUserCount(props.sign_in_count + 1));
                      setRenderCount(renderCount + 1);
                      setTimeout(() => {
                        setRenderCount(renderCount + 2);
                      }, 200);
                    }}
                    marginBottom="18px"
                    padding="7px 25px"
                    background="transparent"
                    hoverBg="#0042a8"
                    border="1px solid #0042a8"
                    borderRadius="8px"
                    color="#0042a8"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <Hidden mdUp implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="temporary"
          onClose={props.handleDrawerToggleMobile}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          open={props.openMobile}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive,
            }),
          }}
        >
          {brand}
          {subMenuItems && (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <ArrowBackIcon
                color="primary"
                fontSize="medium"
                style={{
                  cursor: "pointer",
                  zIndex: "10000",
                }}
                onClick={() => setSubMenuItems(null)}
              />
            </div>
          )}

          {subMenuItems && (
            <p
              // onMouseOver={() => setScrollVisible(true)}
              // onMouseLeave={() => setScrollVisible(false)}
              style={{
                fontWeigth: "bolder",
                color: "#0042A8",
                fontSize: "25px",
                textAlign: "center",
                marginTop: "2rem",
                marginBottom: "2rem",
                zIndex: "10000",
              }}
              data-tooltip={
                "Right-click on an Information Type to favorite it and add it to your dashboard!"
              }
              data-tooltip-location="left"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              ⓘ
            </p>
          )}
          <div
            className={
              subMenuItems && scrollVisible
                ? classes.sidebarWrapper + " " + classes.widthHundred
                : scrollVisible
                ? classes.sidebarWrapper +
                  " " +
                  classes.scrollSet +
                  " " +
                  classes.scrollVisible
                : classes.sidebarWrapper + " " + classes.scrollSet
            }
          >
            {subMenuItems ? subMenuItemsList : links}
          </div>
          {image !== undefined ? (
            <div
              className={
                classes.background +
                " " +
                classes.displayNoneInDesktop +
                " " +
                classes.zIndexThree
              }
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : (
            <div className={classes.background} />
          )}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        {/* {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : (
            <div className={classes.background} />
          )} */}
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open={props.open}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          classes={{
            paper: classNames(
              subMenuItems && scrollVisible
                ? classes.drawerPaper + " " + classes.widthHundred
                : classes.drawerPaper,
              {
                [classes.drawerPaperRTL]: props.rtlActive,
              }
            ),
          }}
        >
          {brand}
          {subMenuItems && (
            <div
              className=""
              style={{
                padding: "10px",
                width: "max-content",
                maxWidth: "106px",
                minWidth: "106px",
                textAlign: "center",
              }}
            >
              <ArrowBackIcon
                color="primary"
                fontSize="medium"
                style={{
                  cursor: "pointer",
                  zIndex: "10000",
                }}
                onClick={() => setSubMenuItems(null)}
              />
              <p
                // onMouseOver={() => setScrollVisible(true)}
                // onMouseLeave={() => setScrollVisible(false)}
                style={{
                  fontWeigth: "bolder",
                  color: "#0042A8",
                  fontSize: "25px",
                  marginTop: "2rem",
                  marginBottom: "2rem",
                  width: "100%",
                  zIndex: "10000",
                }}
                data-tooltip={
                  "Right-click on an Information Type to favorite it and add it to your dashboard!"
                }
                data-tooltip-location="left"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                ⓘ
              </p>
            </div>
          )}
          <div
            ref={sRef}
            onScroll={(e) => {
              console.log({
                target: e.target,
                currentTarget: e.currentTarget.top,
                details: e.detail,
              });
            }}
            className={
              subMenuItems && scrollVisible
                ? classes.sidebarWrapper + " " + classes.widthHundred
                : scrollVisible
                ? classes.sidebarWrapper +
                  " " +
                  classes.scrollSet +
                  " " +
                  classes.scrollVisible
                : classes.sidebarWrapper + " " + classes.scrollSet
            }
          >
            {subMenuItems ? subMenuItemsList : links}
          </div>
        </Drawer>
      </Hidden>
    </div>
  );
}

Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  handleDrawerToggleMobile: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  openMobile: PropTypes.bool,

};

const mapStateToProps = (state, ownProps) => {
  return {
    sign_in_count: state.auth?.user?.sign_in_count,
    profile: state.profile?.profileInfo,
  };
};

export default connect(mapStateToProps)(Sidebar);
