import { useDrag } from "react-dnd";

const Draggable = ({ id, children }) => {
  const [{ opacity }, dragRef] = useDrag({
    type: "YOUR_COMPONENT",
    item: { id },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
  });
  return (
    <div className="fav_width mx-2" ref={dragRef}>
      {children}
    </div>
  );
};

export default Draggable;
