
import _default from "../config/default";
import {store} from "../store/store";

export class InsuranceService {
    constructor() {
        this.BASE_URL = _default.BASE_URL;
        this.AUTH_ENDPOINT = _default.ENDPOINT_AUTH;
    }

    autoInsuranceIndex() {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/auto_insurances`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    autoInsuranceShow(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/auto_insurances/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    createAutoInsurance(data) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/auto_insurances`,
            headers: {
                "Content-Type": "multipart/form-data",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "POST",
            data: data,
        });
    }

    editAutoInsurance(data, id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/auto_insurances/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "PUT",
            data: data,
        });
    }

    autoInsuranceDestroy(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/auto_insurances/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
        });
    }

    autoInsuranceDestroyAll(payload) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/destroy_all_auto_insurances`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
            data: {
                ids: payload
            }
        });
    }

    createDisabilityInsurance(data) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/disability_insurances`,
            headers: {
                "Content-Type": "multipart/form-data",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "POST",
            data: data,
        });
    }

    disabilityInsuranceIndex() {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/disability_insurances`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    disabilityInsuranceShow(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/disability_insurances/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    editDisabilityInsurance(data, id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/disability_insurances/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "PUT",
            data: data,
        });
    }

    disabilityInsuranceDestroy(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/disability_insurances/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
        });
    }

    disabilityInsuranceDestroyAll(payload) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/destroy_all_disability_insurances`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
            data: {
                ids: payload
            }
        });
    }

    createHealthInsurance(data) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/health_insurances`,
            headers: {
                "Content-Type": "multipart/form-data",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "POST",
            data: data,
        });
    }

    healthInsuranceIndex() {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/health_insurances`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    healthInsuranceShow(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/health_insurances/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    editHealthInsurance(data, id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/health_insurances/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "PUT",
            data: data,
        });
    }

    healthInsuranceDestroy(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/health_insurances/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
        });
    }

    healthInsuranceDestroyAll(payload) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/destroy_all_health_insurances`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
            data: {
                ids: payload
            }
        });
    }

    createLifeInsurance(data) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/life_insurances`,
            headers: {
                "Content-Type": "multipart/form-data",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "POST",
            data: data,
        });
    }

    lifeInsuranceIndex() {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/life_insurances`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    lifeInsuranceShow(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/life_insurances/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    editLifeInsurance(data, id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/life_insurances/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "PUT",
            data: data,
        });
    }

    lifeInsuranceDestroy(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/life_insurances/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
        });
    }

    lifeInsuranceDestroyAll(payload) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/destroy_all_life_insurances`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
            data: {
                ids: payload
            }
        });
    }

    createOtherInsurance(data) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/other_insurances`,
            headers: {
                "Content-Type": "multipart/form-data",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "POST",
            data: data,
        });
    }

    otherInsuranceIndex() {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/other_insurances`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    otherInsuranceShow(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/other_insurances/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    editOtherInsurance(data, id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/other_insurances/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "PUT",
            data: data,
        });
    }

    otherInsuranceDestroy(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/other_insurances/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
        });
    }

    otherInsuranceDestroyAll(payload) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/destroy_all_other_insurances`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
            data: {
                ids: payload
            }
        });
    }

    createPropertyInsurance(data) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/property_insurances`,
            headers: {
                "Content-Type": "multipart/form-data",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "POST",
            data: data,
        });
    }

    propertyInsuranceIndex() {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/property_insurances`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    propertyInsuranceShow(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/property_insurances/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    editPropertyInsurance(data, id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/property_insurances/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "PUT",
            data: data,
        });
    }

    propertyInsuranceDestroy(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/property_insurances/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
        });
    }

    propertyInsuranceDestroyAll(payload) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/destroy_all_property_insurances`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
            data: {
                ids: payload
            }
        });
    }
}
