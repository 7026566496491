import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import { Redirect, Route } from "react-router-dom";
import { store } from "./store/store";
import "./assets/css/material-dashboard-react.css";
import { PricingPlans } from "./components/PricingPlans/PricingPlans";

export function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        store?.getState()?.auth?.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/auth/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

export function AuthRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        !store?.getState()?.auth?.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/user/favorite", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
