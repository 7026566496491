import "date-fns";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { store } from "../../../store/store";
import { Auth } from "../../../services/auth.service";
import { setCurrentUser } from "../../../store/actions/authAction";
import { FormInput } from "../../../components/FormInput/FormInput";

const useStyles = makeStyles(styles);
const authService = new Auth();

function AddMasking(props) {
  const classes = useStyles();
  const [check, setCheck] = useState(false);
  const [err, setErr] = useState(null);
  const [password, setPassword] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async () => {
    setIsLoading(true);
    let data = null;
    if (store.getState().auth.user?.masking === true) {
      data = {
        current_password: password,
        masking: !store.getState().auth.user.masking,
      };
    } else {
      data = {
        password: password,
        masking: !store.getState().auth.user.masking,
      };
    }
    setErr(null);
    try {
      let response = await authService.updateMasking(data);
      setIsLoading(false);

      if (response.data.message === "Updated") {
        setPassword(null);
        store.dispatch(
          setCurrentUser({
            ...store.getState().auth.user,
            masking: !store.getState().auth.user?.masking,
          })
        );
        props.handleClose();
      } else if (!response.data.message) {
        setPassword(null);
        store.dispatch(setCurrentUser(response.data.data));
        props.handleClose();
      } else {
        setErr("Password is invalid");
      }
    } catch (e) {
      setIsLoading(false);
      if (!e.response.data) {
        setErr("Internal Server Error");
      } else {
        setErr("Password invalid");
      }
    }
  };
  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Masking Preferences"}
          color={color.white}
        />
        <div className={classes.modalBody}>
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            {err && (
              <div className="w-100 d-flex flex-row mb-4">
                <Typography
                  variant="p"
                  component="p"
                  align="left"
                  style={{ color: "red", fontWeight: "normal" }}
                >
                  {err}
                </Typography>
              </div>
            )}
            {store.getState().auth?.user?.masking ? (
              <>
                <div className="w-100 d-flex flex-row mb-4">
                  <Typography
                    variant="p"
                    component="p"
                    align="left"
                    style={{ color: "#060d5c", fontWeight: "normal" }}
                  >
                    {
                      "TagData masks sensitive information in your profile for your safety. You can turn this off below, if you trust that this computer is secure."
                    }
                  </Typography>
                </div>
                <Grid item xs={12} sm={12} md={12} className="border-bottom">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedA"
                        style={{ color: "#ffb722" }}
                        onChange={(e) => setCheck(e.target.checked)}
                      />
                    }
                    label="I verify that this computer is secure and that I do not want to mask my sensitive information"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Password"}
                  </Typography>
                  <FormInput
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    label=""
                    variant="outlined"
                    fullWidth={true}
                    value={password}
                    margin="dense"
                    required={true}
                    placeholder={""}
                    type="text"
                    id="txtPassword"
                  />
                </Grid>
                {/*<Grid item xs={12} sm={4} md={4}>*/}
                {/*    <Typography*/}
                {/*        variant="h6"*/}
                {/*        component="h3"*/}
                {/*        style={{ color: color.themeColorNew }}*/}
                {/*    >*/}
                {/*        {"Password"}*/}
                {/*    </Typography>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12} sm={8} md={8}>*/}
                {/*    <FormInput*/}
                {/*        id="class"*/}
                {/*        name="account_description"*/}
                {/*        label=""*/}
                {/*        type="text"
id="txtPassword"*/}
                {/*        variant="outlined"*/}
                {/*        fullWidth={true}*/}
                {/*        margin="dense"*/}
                {/*        required={true}*/}
                {/*        placeholder={"Password"}*/}
                {/*    />*/}
                {/*</Grid>*/}
              </>
            ) : (
              <>
                <div className="w-100 d-flex flex-row mb-5">
                  <Typography
                    variant="p"
                    component="p"
                    align="left"
                    style={{ color: "#060d5c", fontWeight: "normal" }}
                  >
                    {
                      "You have verified that this computer is secure. For your convenience, Axiel is not masking any of your sensitive information."
                    }
                  </Typography>
                </div>
                <Grid item xs={12} sm={12} md={12} className="border-bottom">
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkedA"
                        style={{ color: "#ffb722" }}
                        onChange={(e) => setCheck(e.target.checked)}
                      />
                    }
                    label="Start masking my information again"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Password"}
                  </Typography>
                  <FormInput
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    label=""
                    variant="outlined"
                    fullWidth={true}
                    value={password}
                    margin="dense"
                    required={true}
                    placeholder={""}
                    type="text"
                    id="txtPassword"
                  />
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  disabled={!check || !password}
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  onClick={handleSubmit}
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

export default AddMasking;
