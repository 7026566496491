import React, { useCallback, useEffect, useMemo, useState } from "react";
import _default from "../../config/default";
import classNames from "classnames";
import business from "../../assets/img/business.jpeg";
import businessImage from "../../assets/img/business-image.png";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import user from "../../assets/img/user.png";
import man from "../../assets/img/man.png";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Search from "@material-ui/icons/Search";
// core components
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";

import styles from "../../assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import { connect, useDispatch } from "react-redux";
import { logout } from "../../store/actions/authAction";
import add from "../../assets/img/Add-Button-neww.png";
import { NavLink, useHistory } from "react-router-dom";
import { searchApi, setSearchQuery } from "../../store/actions/searchAction";
import Permission from "../../assets/img/permission.png";
import NotificationsImage from "../../assets/img/Notifications.svg";
import ChatImage from "../../assets/img/chat.png";
import Calender from "../../assets/img/calender.png";
import { Avatar, Box } from "@material-ui/core";
// import Button from '@material-ui/core/Button';
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import AddAttachment from "../../containers/Modals/AddAttachment/AddAttachment.js";
import DocumentCategory from "../../containers/Modals/DocumentCategory/DocumentCategory.js";
import AddContact from "../../containers/Modals/AddContact/AddContact.js";
import AddInformation from "../../containers/Modals/AddInformation/AddInformation.js";
import { ProfileService } from "../../services/profile.service";
import { FormInput } from "../FormInput/FormInput";
import AlertPopup from "../../containers/Modals/Alerts/Generic/GenericAlert";
import { setProfile } from "../../store/actions/profileAction";

const useStyles = makeStyles(styles);

function AdminNavbarLinks(props) {
  const profileService = new ProfileService();
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogout = () => {
    props.logout(history);
  };
  const classes = useStyles();
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const [isOpened, setIsOpened] = useState(false);
  const [dot, setDot] = useState(false);
  const [openAttachment, setOpenAttachment] = React.useState(false);
  const [openDocumentCat, setOpenDocumentCat] = React.useState(false);
  const [openAddContact, setOpenAddContact] = React.useState(false);
  const [path, setPath] = React.useState(null);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [profileId, setProfileId] = React.useState(null);
  const [openAddAddress, setOpenAddAddress] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);
  const [yesterday, setYesterday] = React.useState([]);
  const [today, setToday] = React.useState([]);
  const [older, setOlder] = React.useState([]);
  const [openContact, setOpenContact] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [openAddPhoneNumber, setOpenAddPhoneNumber] = React.useState(false);
  const [openInformation, setOpenInformation] = React.useState(false);
  const [openAddEmailAddress, setOpenAddEmailAddress] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const handleOpenDocumentCat = () => {
    if (props.isOpened) {
      props.toggle();
      props.setAnchorEl(null);
    }
    setOpenDocumentCat(true);
    setOpenAttachment(false);
    setOpenContact(false);
  };

  const handleOpenInformation = () => {
    if (props.isOpened) {
      props.toggle();
      props.setAnchorEl(null);
    }
    setOpenInformation(true);
  };

  const handleCloseInformation = () => {
    if (props.isOpened) {
      props.toggle();
      props.setAnchorEl(null);
    }
    setOpenInformation(false);
  };

  const handleCloseDocumentCat = () => {
    if (props.isOpened) {
      props.toggle();
      props.setAnchorEl(null);
    }
    setOpenDocumentCat(false);
  };

  const setFileFn = (file) => {
    setFile(file);
  };

  const handleOpenAttachment = () => {
    if (props.isOpened) {
      props.toggle();
      props.setAnchorEl(null);
    }
    setOpenAttachment(true);
    setOpenDocumentCat(false);
  };

  const handleCloseAttachment = () => {
    if (props.isOpened) {
      props.toggle();
      props.setAnchorEl(null);
    }
    setOpenAttachment(false);
  };

  const handleOpenAddContact = () => {
    if (props.isOpened) {
      props.toggle();
      props.setAnchorEl(null);
    }
    setOpenAddContact(true);
    setOpenAttachment(false);
    setOpenDocumentCat(false);
  };

  const handleCloseAddContact = () => {
    if (props.isOpened) {
      props.toggle();
      props.setAnchorEl(null);
    }
    setOpenAddContact(false);
  };

  const handleOpenAddAddress = () => {
    if (props.isOpened) {
      props.toggle();
      props.setAnchorEl(null);
    }
    setOpenAddAddress(true);
    setOpenAddContact(false);
    setOpenAttachment(false);
    setOpenDocumentCat(false);
  };

  const handleCloseAddAddress = () => {
    if (props.isOpened) {
      props.toggle();
      props.setAnchorEl(null);
    }
    setOpenAddAddress(false);
  };

  const handleOpenAddPhoneNumber = () => {
    if (props.isOpened) {
      props.toggle();
      props.setAnchorEl(null);
    }
    setOpenAddPhoneNumber(true);
    setOpenAddEmailAddress(false);
    setOpenAddAddress(false);
    setOpenAddContact(false);
    setOpenAttachment(false);
    setOpenDocumentCat(false);
  };

  const handleCloseAddPhoneNumber = () => {
    if (props.isOpened) {
      props.toggle();
      props.setAnchorEl(null);
    }
    setOpenAddPhoneNumber(false);
  };

  const handleOpenAddEmailAddress = () => {
    if (props.isOpened) {
      props.toggle();
      props.setAnchorEl(null);
    }
    setOpenAddEmailAddress(true);
    setOpenAddPhoneNumber(false);
    setOpenAddAddress(false);
    setOpenAddContact(false);
    setOpenAttachment(false);
    setOpenDocumentCat(false);
  };

  const handleCloseAddEmailAddress = () => {
    if (props.isOpened) {
      props.toggle();
      props.setAnchorEl(null);
    }
    setOpenAddEmailAddress(false);
  };

  const closeModal = () => {
    if (props.isOpened) {
      props.toggle();
      props.setAnchorEl(null);
    }
    setOpenAttachment(false);
    setOpenDocumentCat(false);
    setOpenAddContact(false);
    setOpenAddAddress(false);
    setOpenAddPhoneNumber(false);
    setOpenAddEmailAddress(false);
  };

  function toggle() {
    setIsOpened(!isOpened);
  }

  const handleClickNotification = (event) => {
    dispatch(setSearchQuery(""));
    if (props.isOpened) {
      props.toggle();
      props.setAnchorEl(null);
    }
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    if (props.isOpened) {
      props.toggle();
      props.setAnchorEl(null);
    }
    setOpenNotification(null);
  };
  const handleChange = (e) => {
    setSearchValue(e.target.value);
    dispatch(setSearchQuery(e.target.value));
    dispatch(searchApi(e.target.value));
  };
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const open = Boolean(anchorEl);

  const handleClick = (event) => {
    dispatch(setSearchQuery(""));
    props.toggle();
    props.setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    toggle();
    setAnchorEl(null);
  };

  const handleOpenNotification = (notification) => {
    if (notification.path) {
      setPath(notification.path);
      setProfileId(notification.profile_id);
      if (notification.profile_id === props.profile.profile.id) {
        setPath(null);
        setProfileId(null);
        history.push(notification.path);
      } else {
        setOpenAlert(true);
      }
    }
  };

  const fetch = useCallback(() => {
    profileService
      .notifications()
      .then((res) => {
        setToday(res?.data?.todays_notifications ?? []);
        setYesterday(res?.data?.yesterday_notifications ?? []);
        setOlder(res?.data?.older_notifications ?? []);
        res?.data?.notifications?.filter((e) => e.read_at === null)?.length > 0
          ? setDot(true)
          : setDot(false);
        // res?.data?.notifications?.forEach((e) => {
        //   if (!e.read_at) {
        //     setDot(true);
        //   }
        // });
      })
      .catch((err) => {});
  }, [openNotification]);

  const readNotification = async (id) => {
    try {
      await profileService.notificationsRead(
        {
          read_at: new Date(),
        },
        id
      );
      // const newArray = notifications.filter((n) => n.id !== id);
      // newArray?.forEach((e) => {
      //   if (!e.read_at) {
      //     setDot(true);
      //   }
      // });
      // setNotifications(newArray);
      fetch();
    } catch (e) {
      fetch();
      // const newArray = notifications.filter((n) => n.id !== id);
      // newArray?.forEach((e) => {
      //   if (!e.read_at) {
      //     setDot(true);
      //   }
      // });
      // setNotifications(newArray);
    }
  };

  useEffect(() => {
    fetch();
  }, [props.profile, fetch]);
  return (
    <div className={classes.searchWrapperOuter}>
      <div className={classes.leftSide}>
        <div className={classes.searchWrapper}>
          {openAlert && (
            <AlertPopup
              btnText={"Yes"}
              cancelText={"No"}
              open={openAlert}
              type={"Warning"}
              heading={"Warning"}
              message={
                "This notification belongs to different profile, click Yes if you want to switch profile to see this notification."
              }
              isLoading={false}
              handleClose={() => {
                setProfileId(null);
                setPath(null);
                setOpenAlert(false);
              }}
              handleClick={() => {
                const profile = props.profiles.filter((p) => p.id == profileId);
                props.setProfile({ profile: profile[0] });
                history.push(path);
                setProfileId(null);
                setPath(null);
                setOpenAlert(false);
              }}
            />
          )}
          <CustomInput
            formControlProps={{
              className: classes.margin + " " + classes.search,
            }}
            inputProps={{
              placeholder: "Search",
              "aria-label": "Search",
              type: "text",
              value: searchValue,
              autoComplete: "new-password",
              autoFocus: false,
            }}
            name="searchValue"
            onChange={(e) => handleChange(e)}
          />
          <Button
            color="white"
            aria-label="edit"
            justIcon
            style={{ boxShadow: "none" }}
          >
            <Search color="black" />
          </Button>
        </div>
        {openAddContact && (
          <AddContact
            open={openAddContact}
            handleClose={handleCloseAddContact}
            handleOpenAddAddress={handleOpenAddAddress}
            handleOpenAddPhoneNumber={handleOpenAddPhoneNumber}
            handleOpenAddEmailAddress={handleOpenAddEmailAddress}
            closeModal={closeModal}
          />
        )}
        {openDocumentCat && (
          <DocumentCategory
            open={openDocumentCat}
            handleClose={() => {
              handleCloseDocumentCat();
              setFileFn(null);
            }}
            back={() => {
              setOpenDocumentCat(false);
              setOpenAttachment(true);
            }}
            closeModal={closeModal}
            openAttachment={() => setOpenAttachment(true)}
            file={file}
            setFile={setFile}
          />
        )}
        {openInformation && (
          <AddInformation
            open={openInformation}
            handleClose={handleCloseInformation}
            closeModal={closeModal}
          />
        )}
        {openAttachment && (
          <AddAttachment
            open={openAttachment}
            handleClose={() => {
              handleCloseAttachment();
              setFileFn(null);
            }}
            handleOpenDocumentCat={handleOpenDocumentCat}
            closeModal={closeModal}
            file={file}
            setFile={setFileFn}
          />
        )}
        <Button
          className="bg-transparent shadow-none p-0 ms-sm-4 ms-3"
          aria-controls="fade-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <img
            src={add}
            width="45"
            style={
              props.isOpened
                ? { transform: "rotate(45deg)" }
                : { transform: "rotate(0deg)" }
            }
          />
        </Button>
        <Menu
          id="fade-menu"
          style={{ top: "45px" }}
          anchorEl={props.anchorEl}
          open={props.open}
          onClose={props.handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={handleOpenInformation}>Information</MenuItem>
          <MenuItem onClick={() => handleOpenAttachment()}>Document</MenuItem>
          <MenuItem onClick={() => handleOpenAddContact()}>Contact</MenuItem>
        </Menu>
      </div>
      <div className={classes.topBarRightLink}>
        <Box sx={{ mx: 2 }}>
          <div className={classes.manager}>
            <NavLink
              to="/user/profile"
              onClick={() => dispatch(setSearchQuery(""))}
            >
              <Button
                color={window.innerWidth > 959 ? "transparent" : "white"}
                justIcon={window.innerWidth > 959}
                simple={!(window.innerWidth > 959)}
                aria-owns={openProfile ? "profile-menu-list-grow" : null}
                aria-haspopup="true"
                // onClick={handleClickProfile}
                style={{ borderRadius: "50%" }}
                className={classes.buttonLink + " px-2 px-md-4 account-options"}
              >
                <img
                  src={
                    props?.profile?.profile.attachment
                      ? _default.BASE_URL + props?.profile?.profile?.attachment
                      : props?.profile?.profile?.profile_type === "business"
                      ? businessImage
                      : props?.profile?.profile?.gender === "male"
                      ? man
                      : user
                  }
                  height={window.innerWidth > 767 ? 40 : 30}
                  width={window.innerWidth > 767 ? 40 : 30}
                  style={{ objectFit: "cover", borderRadius: "50%" }}
                />
                {/* <Hidden mdUp implementation="css">
                  <p className={classes.linkText}>Profile</p>
                </Hidden> */}
              </Button>
            </NavLink>
          </div>
        </Box>
        <Box sx={{ mx: 2 }}>
          <div className={classes.manager}>
            <Button
              color={window.innerWidth > 959 ? "transparent" : "white"}
              justIcon={window.innerWidth > 959}
              simple={!(window.innerWidth > 959)}
              aria-owns={
                openNotification ? "notification-menu-list-grow" : null
              }
              aria-haspopup="true"
              onClick={handleClickNotification}
              className={classes.buttonLink + " " + " px-2 px-md-4 alert"}
            >
              {dot ? (
                <img
                  src={NotificationsImage}
                  height={window.innerWidth > 767 ? 40 : 30}
                />
              ) : (
                <img
                  src={ChatImage}
                  height={window.innerWidth > 767 ? 40 : 30}
                />
              )}
              {/* <Hidden mdUp implementation="css">
                <p
                  onClick={handleCloseNotification}
                  className={classes.linkText}
                >
                  Notification
                </p>
              </Hidden> */}
            </Button>
            <Poppers
              open={Boolean(openNotification)}
              anchorEl={openNotification}
              transition
              disablePortal
              className={
                classNames({ [classes.popperClose]: !openNotification }) +
                " zIndex translate3d"
              }
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="notification-menu-list-grow"
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseNotification}>
                      <MenuList role="menu" className="max-height-250px">
                        {today.length > 0 ||
                        yesterday.length > 0 ||
                        older.length > 0 ? (
                          <>
                            <div>
                              {today.length > 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "5px",
                                  }}
                                >
                                  <h6 style={{ fontWeight: "500" }}>Today's</h6>
                                  {today
                                    .sort((a, b) =>
                                      b.read_at?.localeCompare(a.read_at)
                                    )
                                    .map((n, k) => {
                                      return (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                          }}
                                        >
                                          {n.profile_name && (
                                            <Avatar className="my-1">
                                              {n.profile_name}
                                            </Avatar>
                                          )}
                                          <MenuItem
                                            key={k}
                                            onClick={() => {
                                              if (n.path) {
                                                readNotification(n.id);
                                                handleOpenNotification(n);
                                              } else {
                                                readNotification(n.id);
                                              }
                                            }}
                                            style={{
                                              backgroundColor:
                                                n.read_at && "#F4F5F7",
                                            }}
                                            value={n.message}
                                            className={
                                              n.read_at
                                                ? classes.dropdownItemV2
                                                : classes.dropdownItem
                                            }
                                          >
                                            {n?.message}
                                          </MenuItem>
                                        </div>
                                      );
                                    })}
                                </div>
                              )}
                            </div>
                            <div>
                              {yesterday.length > 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "5px",
                                  }}
                                >
                                  <h6 style={{ fontWeight: "500" }}>
                                    Yesterday's
                                  </h6>
                                  {yesterday
                                    .sort((a, b) =>
                                      b.read_at?.localeCompare(a.read_at)
                                    )
                                    .map((n, k) => {
                                      return (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                          }}
                                        >
                                          {n.profile_name && (
                                            <Avatar className="my-1">
                                              {n.profile_name}
                                            </Avatar>
                                          )}
                                          <MenuItem
                                            key={k}
                                            onClick={() => {
                                              if (n.path) {
                                                readNotification(n.id);
                                                handleOpenNotification(n);
                                              } else {
                                                readNotification(n.id);
                                              }
                                            }}
                                            style={{
                                              backgroundColor:
                                                n.read_at && "#F4F5F7",
                                            }}
                                            value={n.message}
                                            className={
                                              n.read_at
                                                ? classes.dropdownItemV2
                                                : classes.dropdownItemv
                                            }
                                          >
                                            {n?.message}
                                          </MenuItem>
                                        </div>
                                      );
                                    })}
                                </div>
                              )}
                            </div>
                            <div>
                              {older.length > 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "5px",
                                  }}
                                >
                                  <h6 style={{ fontWeight: "500" }}>Older</h6>
                                  {older
                                    .sort((a, b) =>
                                      b.read_at?.localeCompare(a.read_at)
                                    )
                                    .map((n, k) => {
                                      return (
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                          }}
                                        >
                                          {n.profile_name && (
                                            <Avatar className="my-1">
                                              {n.profile_name}
                                            </Avatar>
                                          )}
                                          <MenuItem
                                            key={k}
                                            onClick={() => {
                                              if (n.path) {
                                                readNotification(n.id);
                                                handleOpenNotification(n);
                                              } else {
                                                readNotification(n.id);
                                              }
                                            }}
                                            style={{
                                              backgroundColor:
                                                n.read_at && "#F4F5F7",
                                            }}
                                            value={n.message}
                                            className={
                                              n.read_at
                                                ? classes.dropdownItemV2
                                                : classes.dropdownItem
                                            }
                                          >
                                            {n?.message}
                                          </MenuItem>
                                        </div>
                                      );
                                    })}
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          <MenuItem
                            onClick={handleCloseNotification}
                            className={classes.dropdownItem}
                          >
                            No Notifications Yet
                          </MenuItem>
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Poppers>
          </div>
        </Box>

        {/* <NavLink to="/user/permission"> */}
        {/*<Box sx={{ mx: 2 }} className="fadeOpacity">
          <Button
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-label="Dashboard"
            className={classes.buttonLink + " " + " px-2 px-md-4"}
            data-tooltip="Feature coming soon"
            data-tooltip-location="bottom"
          >
            <img src={Permission} height={window.innerWidth > 767 ? 40 : 30} />
            <div className="position-absolute top-0 start-0 translate-middle showOnHover">Feature coming soon</div>
            <Hidden mdUp implementation="css">
                <p className={classes.linkText}>Dashboard</p>
              </Hidden>
          </Button>
                        </Box> */}
        {/* </NavLink> */}
        {/* <Box sx={{ mx: 2 }} className="fadeOpacity">
          <Button
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-label="Dashboard"
            className={classes.buttonLink + " " + "tooltipText px-2 px-md-4"}
            data-tooltip="Feature coming soon"
            data-tooltip-location="bottom"
          >
            <img src={Calender} height={window.innerWidth > 767 ? 40 : 30} />
            <Hidden mdUp implementation="css">
              <p className={classes.linkText}>Dashboard</p>
            </Hidden>
          </Button>
        </Box> */}
      </div>
    </div>
  );
}

const mapStateTopProps = (state, ownProps) => {
  return {
    profile: state.profile.profileInfo,
    profiles: state.profile.profiles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    setProfile: (profile) => dispatch(setProfile(profile)),
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(AdminNavbarLinks);
