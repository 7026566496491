import "date-fns";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import logo from "../../../assets/img/tag-logo.png";
import { store } from "../../../store/store";
import { DocumentService } from "../../../services/document.service";
import { pateintRegistrationHtml } from "../../../ReportsHtml/patientRegistrationHtml";

const useStyles = makeStyles(styles);

function ReportPatientRegistration(props) {
  const classes = useStyles();
  const [err, setErr] = React.useState(null);
  const [loadingDoc, setLoadingDoc] = React.useState(false);
  const [doc, setDoc] = React.useState({
    personal_information: null,
    life_insurances: [],
    disability_insurances: [],
    health_insurances: [],
    medications: [],
    family_conditions: [],
    health_conditions: [],
    procedures: [],
  });

  const docService = new DocumentService();

  React.useEffect(() => {
    docService
      ?.patientRegistrationIndex()
      .then((res) => {
        setDoc({
          ...doc,
          personal_information:
            res?.data?.personal_information && res?.data?.personal_information,
          life_insurances:
            res?.data?.life_insurances && res?.data?.life_insurances,
          disability_insurances:
            res?.data?.disability_insurances &&
            res?.data?.disability_insurances,
          health_insurances:
            res?.data?.health_insurances && res?.data?.health_insurances,
          medications: res?.data?.medications && res?.data?.medications,
          family_conditions:
            res?.data?.family_conditions && res?.data?.family_conditions,
          health_conditions:
            res?.data?.health_conditions && res?.data?.health_conditions,
          procedures: res?.data?.procedures && res?.data?.procedures,
        });
      })
      .catch((err) => {});
  }, []);

  const handleSubmit = () => {
    setLoadingDoc(true);
    const { html } = pateintRegistrationHtml(doc);
    docService
      ?.pateintRegistrationCreate(html)
      .then((res) => {
        console.log({ res });
        setLoadingDoc(false);
        props?.setAlert({
          type: "Success",
          open: true,
          btnText: "Okay",
          heading: "Success!",
          message:
            "Your Patient Registration report was stored securely in All Documents under type None",
        });
        props?.handleClose();
      })
      .catch((err) => {
        props?.setAlert({
          type: "Error",
          open: true,
          btnText: "RETRY",
          cancelText: "CANCEL",
          heading: "Something went wrong!",
          message:
            "We're sorry there was an error and your report could not be uploaded. Would you like to try again?",
        });
        setLoadingDoc(false);
        props?.handleClose();
      });
  };
  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="55%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Patient Registration"}
          color={color.white}
        />
        <div className="w-100 shadow-sm py-2 px-4 d-flex flex-row">
          <Typography
            variant="p"
            component="p"
            align="left"
            style={{ color: "#060d5c", fontWeight: "normal" }}
          >
            {"Reports > Patient Registration"}
          </Typography>
        </div>
        <div className={classes.modalBody + " pt-3"}>
          <div className="d-flex flex-sm-row flex-column align-items-center mb-4 pb-3 border-bottom">
            <img src={logo} height="56" className="me-sm-5 me-0" />
            <div className="d-flex flex-row">
              <div className="me-sm-5 me-0">
                <Typography
                  variant="p"
                  component="p"
                  align="left"
                  style={{ color: "#060d5c", fontWeight: "normal" }}
                >
                  {"Belongs To:"}
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  align="left"
                  style={{ color: "#060d5c", fontWeight: "bold" }}
                >
                  {store.getState().profile?.profileInfo?.profile
                    ?.profile_type === "person"
                    ? store.getState().profile?.profileInfo?.profile
                        ?.first_name +
                      " " +
                      store.getState().profile?.profileInfo?.profile?.last_name
                    : store.getState().profile?.profileInfo?.profile
                        ?.business_name}
                </Typography>
              </div>
            </div>
          </div>
          <Grid container wrap="wrap" direction="row" spacing={3}>
            {store.getState().profile?.profileInfo?.profile?.profile_type ===
              "person" && (
              <>
                <Grid item xs={12} sm={12} md={12}>
                  <ul className="p-0 m-0 list-unstyled d-flex flex-wrap detailList">
                    <li>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                      >
                        <span className="fw-bold">Name: </span>
                        {doc?.personal_information?.first_name +
                          " " +
                          doc?.personal_information?.last_name}
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                      >
                        <span className="fw-bold">Birth Date: </span>
                        {doc?.personal_information?.birthdate}
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                      >
                        <span className="fw-bold">Gender: </span>
                        {doc?.personal_information?.gender
                          ? doc.personal_information.gender
                              .charAt(0)
                              .toUpperCase() +
                            doc.personal_information.gender.slice(1)
                          : ""}
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                      >
                        <span className="fw-bold">Blood Type: </span>
                        {doc?.personal_information?.blood_type}
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                      >
                        <span className="fw-bold">Relationship Status: </span>
                        {doc?.personal_information?.relationship_status}
                      </Typography>
                    </li>
                    <li>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                      >
                        <span className="fw-bold">Employment Status: </span>
                        {doc?.personal_information?.employment_status}
                      </Typography>
                    </li>
                  </ul>
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={12} md={6}>
              <Typography
                variant="h5"
                component="h3"
                style={{
                  color: color.themeYellow,
                  textDecoration: "underline",
                  textDecorationColor: "#0042A8",
                  textTransform: "uppercase",
                }}
              >
                {"Life Insurances"}
              </Typography>
              {doc?.life_insurances?.length > 0 &&
                doc?.life_insurances?.map((i, key) => {
                  return (
                    <div key={key} style={{ marginBottom: "1rem" }}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="h6"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Life Insurance - {key + 1} </strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Provider </strong>
                          {/* <strong>{i?.provider + " "}</strong> */}
                          {i?.provider}
                          <br></br>
                          <strong>Policy Type</strong>
                          {i?.policy_type}
                        </Typography>
                      </Grid>
                    </div>
                  );
                })}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                variant="h5"
                component="h3"
                style={{
                  color: color.themeYellow,
                  textDecoration: "underline",
                  textDecorationColor: "#0042A8",
                  textTransform: "uppercase",
                }}
              >
                {"Disability Insurance"}
              </Typography>
              {doc?.disability_insurances?.length > 0 &&
                doc?.disability_insurances?.map((d, key) => {
                  return (
                    <div key={key} style={{ marginBottom: "1rem" }}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="h6"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Disability Insurance - {key + 1} </strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Provider </strong>
                          {d?.provider}
                        </Typography>
                      </Grid>
                    </div>
                  );
                })}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                variant="h5"
                component="h3"
                style={{
                  color: color.themeYellow,
                  textDecoration: "underline",
                  textDecorationColor: "#0042A8",
                  textTransform: "uppercase",
                }}
              >
                {"Health Insurance"}
              </Typography>
              {doc?.health_insurances?.length > 0 &&
                doc?.health_insurances?.map((h, key) => {
                  return (
                    <div key={key} style={{ marginBottom: "1rem" }}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="h6"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Health Insurance - {key + 1} </strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Provider </strong>
                          {h?.provider}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Policy Type </strong>
                          {h?.policy_type}
                        </Typography>
                      </Grid>
                    </div>
                  );
                })}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                variant="h5"
                component="h3"
                style={{
                  color: color.themeYellow,
                  textDecoration: "underline",
                  textDecorationColor: "#0042A8",
                  textTransform: "uppercase",
                }}
              >
                {"Medications"}
              </Typography>
              {doc?.medications?.length > 0 &&
                doc?.medications?.map((m, key) => {
                  return (
                    <div key={key} style={{ marginBottom: "1rem" }}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="h6"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Medication - {key + 1} </strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Medicine </strong>
                          {m?.medication}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Medication Type </strong>
                          {m?.medication_type}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Clinician </strong>
                          {m?.clinician}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Frequency </strong>
                          {m?.frequency}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Start Date </strong>
                          {m?.start_date}
                        </Typography>
                      </Grid>
                    </div>
                  );
                })}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                variant="h5"
                component="h3"
                style={{
                  color: color.themeYellow,
                  textDecoration: "underline",
                  textDecorationColor: "#0042A8",
                  textTransform: "uppercase",
                }}
              >
                {"Family Conditions"}
              </Typography>
              {doc?.family_conditions?.length > 0 &&
                doc?.family_conditions?.map((f, key) => {
                  return (
                    <div key={key} style={{ marginBottom: "1rem" }}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="h6"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Family Condition - {key + 1} </strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Condition </strong>
                          {f?.family_condition}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Severity </strong>
                          {f?.severity}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Relative Name </strong>
                          {f?.relative_name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Relation </strong>
                          {f?.relation}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Region </strong>
                          {f?.region}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Birthdate </strong>
                          {f?.birth_date}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Date Diagnosed </strong>
                          {f?.date_deceased}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Gender </strong>
                          {f?.gender?.charAt(0).toUpperCase() +
                            f?.gender.slice(1)}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Was he/she adopted? </strong>
                          {f?.adopted ? "Yes" : "No"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>
                            Was he/she concieved by donor egg or sperm?{" "}
                          </strong>
                          {f?.conceived_by_donor ? "Yes" : "No"}
                        </Typography>
                      </Grid>
                    </div>
                  );
                })}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                variant="h5"
                component="h3"
                style={{
                  color: color.themeYellow,
                  textDecoration: "underline",
                  textDecorationColor: "#0042A8",
                  textTransform: "uppercase",
                }}
              >
                {"Health Conditions"}
              </Typography>
              {doc?.health_conditions?.length > 0 &&
                doc?.health_conditions?.map((f, key) => {
                  return (
                    <div key={key} style={{ marginBottom: "1rem" }}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="h6"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Health Condition - {key + 1} </strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Condition </strong>
                          {f?.health_condition}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Clinician </strong>
                          {f?.clinician}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Start Date </strong>
                          {f?.start_date}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>End Date </strong>
                          {f?.end_date}
                        </Typography>
                      </Grid>
                    </div>
                  );
                })}
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography
                variant="h5"
                component="h3"
                style={{
                  color: color.themeYellow,
                  textDecoration: "underline",
                  textDecorationColor: "#0042A8",
                  textTransform: "uppercase",
                }}
              >
                {"Procedures"}
              </Typography>
              {doc?.procedures?.length > 0 &&
                doc?.procedures?.map((f, key) => {
                  return (
                    <div key={key} style={{ marginBottom: "1rem" }}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="h6"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Procedure - {key + 1} </strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Encrypted Procedure </strong>
                          {f?.procedure}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Hospital Name </strong>
                          {f?.hospital}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>Start Date </strong>
                          {f?.start_date}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{ color: color.themeColorNew }}
                        >
                          <strong>End Date </strong>
                          {f?.end_date}
                        </Typography>
                      </Grid>
                    </div>
                  );
                })}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <small>
                The information in this document is confidential and may be
                legally privileged. Access to this document by anyone other than
                the intended addressee is unauthorized. If you are not the
                intended recipient of this message, any review, disclosure,
                copying, distribution, retention, or any action taken or omitted
                to be taken in reliance on it is prohibited and may be unlawful.
              </small>
            </Grid>
          </Grid>
        </div>
        <div
          className="bg-light text-sm-center p-2 bottomRadius"
          style={{ textAlign: "right" }}
        >
          <small className="me-sm-5 me-2">
            This is a preview of your{" "}
            <span style={{ color: color.themeYellow }}>
              <b>Patient Registration</b>
            </span>
          </small>
          {!loadingDoc ? (
            <CustomButton
              type="submit"
              btnText="Store Securely"
              textAlign="center"
              display={"block"}
              padding="8px 25px"
              background="#0042a8"
              hoverBg="#0042a8"
              border="0"
              borderRadius="8px"
              color="#fff"
              onClick={handleSubmit}
            />
          ) : (
            <CustomButton
              btnText={<CircularProgress size={20} color="#DDD" />}
              type="submit"
              textAlign="center"
              display={"block"}
              padding="8px 25px"
              background="#0042a8"
              hoverBg="#0042a8"
              border="0"
              borderRadius="8px"
              color="#fff"
            />
          )}
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportPatientRegistration);
