import React, { useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { makeStyles } from "@material-ui/core/styles";
import Sidebar from "../../components/Sidebar/Sidebar.js";
import routes from "../../routes.js";
import moment from "moment";
import styles from "../../assets/jss/material-dashboard-react/layouts/adminStyle.js";
import { store } from "../../store/store";
import bgImage from "../../assets/img/sidebar-2.jpg";
import logo from "../../assets/img/reactlogo.png";
import FileViewer from "../../components/FileViewer/FileViewer.js";
import file from "../../assets/img/file.png";
import { useIdleTimer } from "react-idle-timer";
import _default from "../../config/default";
import { logout } from "../../store/actions/authAction";
import { LoggerService } from "../../services/logger.service";
import { CustomButton } from "../../components/CustomButton/Button";
import { Auth } from "../../services/auth.service";
import config from "../../config/default";
import { Grid, Typography } from "@material-ui/core";
import Card from "../../components/Card/Card.js";
import { truncateString } from "../../Utils/truncateString.js";
import { connect, useDispatch } from "react-redux";
import { clearProfileState } from "../../store/actions/profileAction.js";
import { clearAuthState } from "../../store/actions/authAction.js";

let ps;
const useStyles = makeStyles(styles);
const loggerService = new LoggerService();
const { Axios } = _default;

function ViewDocument(
  { sort, user, profiles, sortType, isAuthenticated, ...rest },
  props
) {
  const history = useHistory();
  const search = useLocation().search;
  const [agree, setAgree] = useState(false);
  const params = new URLSearchParams(search);
  const [viewOpen, setViewOpen] = useState(true);
  const [expanded, setExpanded] = useState(null);
  const [multiDocs, setMultiDocs] = useState([]);
  const [err, setErr] = useState(null);
  const [options, setOptions] = useState({
    share_require_authentication: params.get("share_require_authentication"),
    axiel_user: params.get("axiel_user"),
    profile_id: params.get("profile_id"),
    share_id: params.get("share_id"),
    shared_document_id: params.get("shared_document_id"),
    token: params?.get("access_token"),
    multi: params?.get("multi"),
  });
  const [loading, setLoading] = useState(false);

  const [attachment, setAttachment] = useState(null);
  Axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        store.dispatch(logout(history));
      }
    }
  );
  const handleOnIdle = (event) => {
    store.dispatch(logout(history, { bool: true }));
    // store.dispatch(setError("Signed out because of inactivity."));
  };
  const [openAddProfile, setOpenAddProfile] = React.useState(false);
  // const [openAddProfile, setOpenAddProfile] = React.useState(
  //   user?.sign_in_count === 1 ? true : false
  // );

  const handleOnActive = (event) => {
    console.log("user is active", event);
    console.log("time remaining", getRemainingTime());
  };

  const handleOnAction = (event) => {
    console.log("user did something", event);
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 60000 * 180,
    onIdle: handleOnIdle,
    // onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 500,
  });
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function toggle() {
    setIsOpened(!isOpened);
  }

  const handleClose = () => {
    toggle();
    setAnchorEl(null);
  };
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isAuthenticated) {
      authService
        .logout()
        .then(() => {
          dispatch(clearProfileState());
          dispatch(clearAuthState());
        })
        .catch(() => {
          dispatch(clearProfileState());
          dispatch(clearAuthState());
        });
    }
  }, []);

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    (process.env.NODE_ENV === "production" ||
      process.env.REACT_APP_ENV === "STAGING") &&
      loggerService.GlobalDebug(false);

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [props?.search, sortType]);

  const authService = new Auth();
  const handleAgree = () => {
    setLoading(true);
    authService
      .license(true, +options?.profile_id, +options?.share_id)
      .then((res) => {
        if (options.multi === "true") {
          if (options.axiel_user === "true" || options.axiel_user === true) {
            if (
              options.share_require_authentication === "false" ||
              options.share_require_authentication === false
            ) {
              authService
                .receivedMultiDocuments(options.share_id, options.token)
                .then((res) => {
                  //hit viewd at on viewing specific doc
                  setMultiDocs(res?.data?.shared_documents);
                  setAgree(true);
                  setLoading(false);
                })
                .catch((err) => {
                  setErr(
                    err?.response?.status === 401
                      ? "Unauthorized Document"
                      : "Internal Server Error."
                  );
                });
            } else {
              if (store.getState().auth?.isAuthenticated) {
                history.push("/user/received-documents");
              } else {
                localStorage.setItem(
                  "message",
                  `${params.get(
                    "name"
                  )} has shared information with you, please login to find the document under the received documents tab on the main dashboard`
                );
                // history.push("/auth/login")
                window.location.replace("/auth/login");
              }
            }
          } else {
            if (
              options.share_require_authentication === "false" ||
              options.share_require_authentication === false
            ) {
              authService
                .receivedMultiDocuments(options.share_id, options.token)
                .then((res) => {
                  //hit viewd at on viewing specific doc
                  setMultiDocs(res?.data?.shared_documents);
                  setAgree(true);
                  setLoading(false);
                })
                .catch((err) => {
                  setErr(
                    err?.response?.status === 401
                      ? "Unauthorized Document"
                      : "Internal Server Error."
                  );
                });
            } else {
              localStorage.setItem(
                "message",
                `${params.get(
                  "name"
                )} has shared information with you, please complete the registration and after signing in you can find the shared document under the received documents tab on the main dashboard`
              );
              window.location.replace("/auth/register");
            }
          }
        } else {
          if (options.axiel_user === "true" || options.axiel_user === true) {
            if (
              options.share_require_authentication === "false" ||
              options.share_require_authentication === false
            ) {
              authService
                .receivedDocumentShow(
                  options.profile_id,
                  options.shared_document_id,
                  options.token
                )
                .then((res) => {
                  authService
                    .viewedAt(options.profile_id, options.shared_document_id)
                    .then(() => console.log("viewed"))
                    .catch(() => console.log("error"));
                  setAttachment(res?.data?.attachment);
                  setAgree(true);
                  setLoading(false);
                })
                .catch((err) => {
                  setErr(
                    err?.response?.status === 401
                      ? "Unauthorized Document"
                      : "Internal Server Error."
                  );
                });
            } else {
              if (store.getState().auth?.isAuthenticated) {
                history.push("/user");
              } else {
                localStorage.setItem(
                  "message",
                  `${params.get(
                    "name"
                  )} has shared a information with you, please login to find the document under the received documents tab on the main dashboard`
                );
                // history.push("/auth/login")
                window.location.replace("/auth/login");
              }
            }
          } else {
            if (
              options.share_require_authentication === "false" ||
              options.share_require_authentication === false
            ) {
              authService
                .receivedDocumentShow(
                  options.profile_id,
                  options.shared_document_id,
                  options.token
                )
                .then((res) => {
                  authService
                    .viewedAt(options.profile_id, options.shared_document_id)
                    .then(() => console.log("viewed"))
                    .catch(() => console.log("error"));
                  setAttachment(res?.data?.attachment);
                  setAgree(true);
                  setLoading(false);
                })
                .catch((err) => {
                  setErr(
                    err?.response?.status === 401
                      ? "Unauthorized Document"
                      : "Internal Server Error."
                  );
                });
            } else {
              localStorage.setItem(
                "message",
                `${params.get(
                  "name"
                )} has shared a information with you, please complete the registration and after signing in you can find the shared document under the received documents tab on the main dashboard`
              );
              window.location.replace("/auth/register");
            }
          }
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className={classes.wrapper}>
        <Sidebar
          viewOnly={true}
          routes={routes}
          logo={logo}
          image={image}
          plusToggle={toggle}
          plusAchor={setAnchorEl}
          plusOpen={isOpened}
          // image={undefined}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
          {...rest}
        />
        <div className={classes.mainPanel} ref={mainPanel}>
          <div className="w-100 px-5 py-4 d-flex flex-row justify-content-end border-bottom">
            <div className="mx-5 my-2">
              <NavLink
                to="/auth/login"
                className="mx-2"
                style={{ color: "#060d5c" }}
              >
                <CustomButton
                  btnText="Login"
                  className="mb-sm-2"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="25em"
                  color="#fff"
                  marginRight={"10px"}
                />
              </NavLink>
              <NavLink
                to="/auth/register"
                className="mx-2"
                style={{ color: "#060d5c" }}
              >
                <CustomButton
                  btnText="Register"
                  className="mb-sm-2"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="25em"
                  color="#fff"
                  marginRight={"10px"}
                />
              </NavLink>
            </div>
          </div>
          {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {!err ? (
            <div className="px-5 mx-5 py-5 text-center">
              {!agree ? (
                <div className="text-start">
                  <h3 className="fw-bold">License Agreement</h3>
                  <p>Hi,</p>
                  <p>
                    {params.get("name")} has granted you access to{" "}
                    {params.get("name")}'s{" "}
                    {params.get("title") ?? "Information"}.
                  </p>
                  <p>
                    In order to view this information you must accept{" "}
                    {params.get("name")}'s License Agreement
                  </p>
                  <div className="bg-light p-3 mb-3">
                    <p>License Agreement</p>
                    <ul>
                      <li>
                        Access to this information expires on{" "}
                        {params.get("expiration_date")}
                      </li>
                      <li>
                        {params.get("allow_download") === "true" ||
                        params.get("allow_download") === true
                          ? "You can view and download this information"
                          : "Access to this information is restricted to Read Only"}
                      </li>
                      <li>Additional terms: {params.get("terms")}</li>
                    </ul>
                  </div>
                  <CustomButton
                    type="submit"
                    btnText="I agree"
                    disabled={loading}
                    textAlign="center"
                    display={"block"}
                    padding="12px 25px"
                    minWidth="113px"
                    background="#0042a8"
                    hoverBg="#0042a8"
                    border="0"
                    marginRight="10px"
                    borderRadius="8px"
                    color="#fff"
                    onClick={handleAgree}
                  />
                </div>
              ) : (
                <>
                  {attachment ? (
                    <>
                      {params.get("allow_download") === "true" ||
                      params.get("allow_download") === true ? (
                        <embed
                          src={config.BASE_URL + attachment}
                          title="description"
                          style={{
                            objectFit: "contain",
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      ) : (
                        <embed
                          src={config.BASE_URL + attachment + "#toolbar=0"}
                          title="description"
                          style={{
                            objectFit: "contain",
                            width: "100%",
                            height: "100%",
                          }}
                        />
                      )}
                    </>
                  ) : multiDocs.length > 0 ? (
                    <div className="tabsContent">
                      <Grid container spacing="5">
                        {multiDocs.map((doc, key) => {
                          return (
                            <Grid container>
                              <div
                                className="fav_width"
                                style={{ margin: "1rem" }}
                              >
                                <Card
                                  className="p-4 m-0 h-auto mb-3 min-height-182px"
                                  data-tooltip={doc?.title}
                                  data-tooltip-location="top"
                                >
                                  <img
                                    src={file}
                                    width="38px"
                                    className="mb-3"
                                  />
                                  <Typography
                                    variant="h6"
                                    component="h3"
                                    style={{ color: "#060d5c", cursor: "pointer" }}
                                    onClick={() => {
                                      authService
                                        .viewedAt(options.profile_id, doc.id)
                                        .then(() => console.log("viewed"))
                                        .catch(() => console.log("error"));
                                      setExpanded(key);
                                    }}
                                  >
                                    {doc.title && truncateString(doc.title, 12)}
                                  </Typography>
                                  <Typography variant="p" component="p">
                                    {moment(
                                      moment(
                                        doc?.created_at?.split("-")[2] +
                                          "-" +
                                          doc?.created_at?.split("-")[0] +
                                          "-" +
                                          doc?.created_at?.split("-")[1],
                                        "YYYY-MM-DD"
                                      ).toDate()
                                    ).format("MMMM DD, YYYY")}
                                  </Typography>
                                  {viewOpen && (
                                    <>
                                      {expanded === key && (
                                        <FileViewer
                                          contentType={doc?.content_type}
                                          key={key}
                                          link={
                                            config.BASE_URL + doc.attachment
                                          }
                                          open={viewOpen}
                                          handleClose={() => setViewOpen(false)}
                                        />
                                      )}
                                    </>
                                  )}
                                </Card>
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                  ) : (
                    history.push("/auth")
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="px-5 mx-5 py-5 text-center">
              <div className="text-start">
                <h3 className="fw-bold">{err}</h3>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(ViewDocument);
