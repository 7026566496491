import * as React from "react";
import { useEffect, useState } from "react";
import { Card, CardHeader, Grid, Typography } from "@material-ui/core";
import { CustomButton } from "../../components/CustomButton/Button";
import color from "../../assets/css/color";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import AddNewCard from "../../containers/Modals/AddNewCard/AddNewCard";
import PaymentSuccessfull from "../../containers/Modals/Alerts/ThankYouForPayment/PaymentSuccessfull";
import { useHistory } from "react-router-dom";
import { store } from "../../store/store";
import { setPlanType } from "../../store/actions/authAction";
import { Auth } from "../../services/auth.service";
import DowngradeModal from "../../containers/Modals/DowngradeModal/downgradeModal";
import PromoCode from "../../containers/Modals/PromoCode/PromoCodeModal";
import AlertPopup from "../../containers/Modals/Alerts/Generic/GenericAlert";
import SubscriptionCheckout from "../../containers/Modals/Alerts/Subscription/SubscriptionCheckout";
import SpinnerModal from "../../containers/Modals/Alerts/SpinnerModal/SpinnerModal";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { ReactGA } from "react-ga4";

export const PricingPlans = (props) => {
  const history = useHistory();
  const authService = new Auth();
  const [selectedPlan, setSelectedPlan] = React.useState("");
  const [openAddNewCard, setOpenAddNewCard] = React.useState(false);
  const [type, setType] = React.useState("Monthly");
  const [price, setPrice] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [alertPopup, setAlertPopup] = React.useState({
    open: false,
    type: null,
    btnText: null,
    cancelText: null,
    heading: null,
    message: null,
  });
  const [methods, setMethods] = React.useState([]);
  const [data, setData] = React.useState(null);
  const [alert, setAlert] = React.useState({
    open: false,
    type: null,
    btnText: null,
    cancelText: null,
    heading: null,
    message: null,
  });
  const [plans, setPlans] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [payload, setPayload] = React.useState(null);
  const [plan, setPlan] = React.useState([]);
  const [error, setError] = React.useState([]);
  const [promoCode, setPromoCode] = React.useState(null);
  const [promoCodeOpen, setPromoCodeOpen] = React.useState(false);
  const [errArrayForAlerts, setErrArrayForAlerts] = React.useState([]);
  const handleOpenAddNewCard = (price, plan) => {
    setPrice(price);
    setPlan(plan);
    setPayload(plan);
    setOpenAddNewCard(true);
    setOpenPaymentSuccessfull(false);
  };
  const [shouldUpdatePlan, setShouldUpdatePlan] = React.useState(true);

  const updatePlan = (selectedPlan) => {
    const existingPlan = store.getState().auth.user.account_type;

    if (existingPlan === selectedPlan) {
      setAlertPopup({
        open: true,
        btnText: "Close",
        type: "Error",
        heading: "Plan Update",
        message: "You are already subscribed to this plan.",
      });
      return;
    }

    setLoading(true);

    const subData = {
      is_annual: type === "Annual",
      plan: selectedPlan,
      ...(promoCode && {
        discount_code: promoCode,
      }),
    };

    authService
      .updateSubscription(subData)
      .then((res) => {
        store.dispatch(setPlanType(selectedPlan));
        setData(res.data);
        setLoading(false);
        setAlert({ ...alert, open: true });
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.status === 422) {
          if (err.response.data.errors?.message[0] === "Invalid code") {
            setAlertPopup({
              ...alertPopup,
              open: true,
              btnText: "Close",
              type: "Warning",
              heading: "Invalid Promo Code",
              message:
              "Entered Promo Code was not valid, payment is not processed. Please try again with either a valid code or continue without one.",
              });
              } else {
            setErrArrayForAlerts(err.response.data.errors?.message[0]);
            setOpen(true);
          }
        } else {
          if (err.response && err.response.data.errors) {
            let array = [];
            Object.keys(err.response.data.errors).forEach((key) => {
              array.push(`${err.response.data.errors[key]}`);
            });
            setError(array);
          } else {
            setError(["Internal Server Error"]);
          }
        }
      });
    setPromoCode(null);
  };

  const handleFree = () => {
    setLoading(true);
    authService
      .createSubscription({ plan: "Free" })
      .then((res) => {
        store.dispatch(setPlanType("Free"));
        setLoading(false);
        history.push("/user");
        ReactGA.event({
          category: "Subscription",
          action: "Free",
          email: store.getState().auth.user.email,
          Name: store.getState().auth.user.name
        });
      })
      .catch((err) => {
        setLoading(false);
        alert("Internal Server Error");
      });
  };

  const handleCloseAddNewCard = () => {
    setOpenAddNewCard(false);
  };

  const [openPaymentSuccessfull, setOpenPaymentSuccessfull] =
    React.useState(false);

  const handleOpenPaymentSuccessfull = () => {
    setOpenPaymentSuccessfull(true);
    setOpenAddNewCard(false);
  };

  const handleClosePaymentSuccessfull = () => {
    setOpenPaymentSuccessfull(false);
  };

  const handleSubmit = (p) => {
    // console.log({p})
    if (!store.getState().auth?.user?.is_subscribed) {
      if (p.name?.toLowerCase() === "free") {
        handleFree();
      } else {
        const temp =
          type === "Monthly"
            ? `$${p.display_price}/Month`
            : `$${p.annual_display_price}/Year`;
        handleOpenAddNewCard(temp, p.name);
      }
    } else if (methods?.length <= 0) {
      const temp =
        type === "Monthly"
          ? `$${p.display_price}/Month`
          : `$${p.annual_display_price}/Year`;
      handleOpenAddNewCard(temp, p.name);
    } else {
      const existingPlan = plans.filter(
        (p) => p.name === store.getState().auth?.user?.account_type
      )[0];
      setPayload(p.name);
      if (existingPlan && +existingPlan.display_price < +p.display_price) {
        setPromoCodeOpen(true);
      } else {
        updatePlan(p.name);
      }
    }
  };

  const handlePromoCode = (p) => {
    // console.log({p})
    if (!store.getState().auth?.user?.is_subscribed) {
      if (p.name?.toLowerCase() === "free") {
        handleFree();
      } else {
        const temp =
          type === "Monthly"
            ? `$${p.display_price}/Month`
            : `$${p.annual_display_price}/Year`;
        handleOpenAddNewCard(temp, p.name);
      }
    } else if (methods?.length <= 0) {
      const temp =
        type === "Monthly"
          ? `$${p.display_price}/Month`
          : `$${p.annual_display_price}/Year`;
      handleOpenAddNewCard(temp, p.name);
    } else {
      const existingPlan = plans.filter(
        (p) => p.name === store.getState().auth?.user?.account_type
      )[0];
      setPayload(p.name);
    }
    if (shouldUpdatePlan) {
      updatePlan(p.name);
    }
  };

  useEffect(() => {
    setLoading(true);
    authService
      .indexPaymentMethod()
      .then((res) => {
        setMethods(res.data?.payment_method || []);
        console.log(res?.data.plans);
        return authService.indexPlans();
      })
      .then((res) => {
        setLoading(false);
        setPlans(res?.data?.plans);
      })
      .catch((err) => {
        setLoading(false);
        console.log({ err });
      });
  }, []);
  React.useEffect(() => {
    if (!promoCodeOpen && payload && shouldUpdatePlan) {
      updatePlan(payload);
    }
  }, [promoCodeOpen, shouldUpdatePlan]);
  return (
    <>
      {alertPopup.open && (
        <AlertPopup
          btnText={alertPopup.btnText}
          // cancelText={alertPopup.cancelText}
          open={alertPopup.open}
          type={alertPopup.type}
          heading={alertPopup.heading}
          message={alertPopup.message}
          isLoading={false}
          handleClose={() => {
            setAlertPopup({
              open: false,
              btnText: null,
              cancelText: null,
              heading: null,
              message: null,
              type: null,
            });
          }}
          handleClick={() => {
            setAlertPopup({
              open: false,
              btnText: null,
              cancelText: null,
              heading: null,
              message: null,
              type: null,
            });
          }}
        />
      )}
      {alert.open && (
        <SubscriptionCheckout
          data={data}
          open={alert.open}
          handleClose={() => {
            history.push("/user");
          }}
          handleClick={() => {
            history.push("/user");
          }}
        />
      )}
      {promoCodeOpen && (
        <PromoCode
          open={promoCodeOpen}
          handleSubmit={() => {
            setShouldUpdatePlan(false); // Prevent immediate updatePlan call
            handlePromoCode(selectedPlan);
          }}
          handleClick={(pc) => {
            setPromoCodeOpen(false);
            setPromoCode(pc);
            setShouldUpdatePlan(true); // Allow updatePlan call after returning from modal
          }}
          handleClose={() => {
            setPayload(null);
            setPromoCode(null);
            setPromoCodeOpen(false);
          }}
          handleChange={(val) => setPromoCode(val)}
        />
      )}
      {open && (
        <DowngradeModal
          payload={payload}
          updatePlan={updatePlan}
          open={open}
          handleClose={() => setOpen(false)}
          err={errArrayForAlerts}
        />
      )}
      <Grid
        container
        spacing={2}
        direction="column"
        alignItems="center"
        style={{ marginBottom: "7rem" }}
      >
        <Grid item>
          <ToggleButtonGroup
            size="large"
            value={type}
            exclusive
            onChange={(event, newVal) => setType(newVal)}
          >
            <ToggleButton
              value="Monthly"
              style={{
                color: type === "Monthly" ? "white" : "black",
                backgroundColor: type === "Monthly" ? "#0042A8" : "white",
              }}
            >
              Monthly
            </ToggleButton>
            <ToggleButton
              value="Annual"
              style={{
                color: type === "Annual" ? "white" : "black",
                backgroundColor: type === "Annual" ? "#0042A8" : "white",
              }}
            >
              Annual
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
      <Grid
        container
        wrap="wrap"
        direction="row"
        // justifyContent="center"
        alignItems="center"
        spacing={3}
        className="py-5 py-lg-0 px-3 px-lg-0"
      >
        {error?.length > 0 && (
          <div className="w-100 d-flex flex-column mb-5">
            {error?.map((val, key) => {
              return (
                <Typography
                  key={key}
                  variant="p"
                  component="p"
                  align="left"
                  style={{
                    color: "red",
                    fontWeight: "normal",
                    marginBottom: "2px",
                  }}
                >
                  {val}
                </Typography>
              );
            })}
          </div>
        )}
        {plans.length === 0 && loading && (
          <SpinnerModal
            open={loading}
            msg={"Loading subscriptions, please wait..."}
          />
        )}

        {plans.length > 0 &&
          plans
            ?.sort((a, b) => {
              return +a.display_price - +b.display_price;
            })
            .map((p, index) => {
              return (
                <Grid
                  key={index}
                  item
                  // className={plans.length >= 3 ? "" : "h_100" }
                  xs={store.getState().auth.user.is_subscribed ? 12 : 12}
                  sm={
                    store.getState().auth.user.is_subscribed
                      ? 6
                      : plans.length == 3
                      ? 4
                      : 3
                  }
                  md={
                    store.getState().auth.user.is_subscribed
                      ? 6
                      : plans.length == 3
                      ? 4
                      : 3
                  }
                  lg={
                    store.getState().auth.user.is_subscribed
                      ? 6
                      : plans.length == 3
                      ? 4
                      : 3
                  }
                >
                  <Card
                    className={
                      plans.length >= 3
                        ? "d-flex flex-column justify-content-between"
                        : "h_100 d-flex flex-column justify-content-between"
                    }
                    style={{
                      border: `1px solid ${color.themeColorNew}`,
                      borderRadius: "12px",
                    }}
                  >
                    <CardHeader
                      style={{
                        background:
                          type === "Monthly"
                            ? color.themeColorNewLight
                            : color.themeYellow,
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "25px",
                        borderBottom: `1px solid ${color.themeColorNewLight}`,
                      }}
                    >
                      <Typography
                        variant="h4"
                        component="p"
                        align="left"
                        style={{
                          color: color.themeColorNew,
                          fontWeight: "500",
                        }}
                      >
                        {type === "Monthly"
                          ? `$${p.display_price}/Month`
                          : `$${p.annual_display_price}/Year`}
                      </Typography>
                      {type != "Monthly" && p.discount && (
                        <span
                          style={{
                            background: color.themeYellow,
                            color: color.themeColorNew,
                            fontSize: "16px",
                            border: `1px solid ${color.themeColorNewLight}`,
                            padding: "10px",
                            borderRadius: "12px",
                          }}
                        >
                          Save {p.discount}%
                        </span>
                      )}
                    </div>
                    <div style={{ padding: "25px" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <Typography
                            variant="p"
                            component="p"
                            align="left"
                            style={{
                              color: color.themeYellow,
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            }}
                          >
                            {p.name}
                          </Typography>
                          <Typography
                            variant="h5"
                            component="p"
                            align="left"
                            style={{
                              color: color.themeColorNew,
                              fontWeight: "500",
                              // marginBottom: "20px",
                            }}
                          >
                            {type === "Monthly"
                              ? `$${p.display_price}/Month`
                              : `$${
                                  p.per_month_price ?? p.display_price
                                }/Month`}
                          </Typography>
                          <small
                            style={{ color: "#919193", marginBottom: "5px" }}
                          >
                            {type === "Annual"
                              ? `$${p.per_month_price ?? p.display_price}/Month`
                              : `$${p.display_price * 12}/Year`}
                          </small>
                        </div>
                        <span
                          style={{
                            background: color.LightGrey,
                            color: color.themeColorNew,
                            fontSize: "16px",
                            fontWeight: "500",
                            border: `1px solid ${color.themeColorNewLight}`,
                            padding: "10px 15px",
                            borderRadius: "12px",
                          }}
                        >
                          {type === "Monthly"
                            ? "Billed Monthly"
                            : "Billed Yearly"}
                        </span>
                      </div>
                      <div className="d-flex flex-row align-items-center mb-3">
                        <CheckCircleIcon
                          value="feature"
                          fontSize="large"
                          style={{ color: color.themeColorNew }}
                        />
                        <Typography
                          variant="p"
                          component="p"
                          align="left"
                          style={{
                            color: color.themeColorNew,
                            fontWeight: "500",
                            marginBottom: "0",
                            marginLeft: "8px",
                          }}
                        >
                          Access to all templates
                        </Typography>
                      </div>
                      <div className="d-flex flex-row align-items-center mb-3">
                        {p.max_profiles !== "unlimited" ? (
                          <CancelIcon
                            value="feature"
                            fontSize="large"
                            style={{ color: color.themeYellow }}
                          />
                        ) : (
                          <CheckCircleIcon
                            value="feature"
                            fontSize="large"
                            style={{ color: color.themeColorNew }}
                          />
                        )}
                        <Typography
                          variant="p"
                          component="p"
                          align="left"
                          style={{
                            color: color.themeColorNew,
                            fontWeight: "500",
                            marginBottom: "0",
                            marginLeft: "8px",
                          }}
                        >
                          Multi profile
                        </Typography>
                      </div>
                      <div className="d-flex flex-row align-items-center mb-3">
                        {p.max_shares !== "unlimited" ? (
                          <CancelIcon
                            value="feature"
                            fontSize="large"
                            style={{ color: color.themeYellow }}
                          />
                        ) : (
                          <CheckCircleIcon
                            value="feature"
                            fontSize="large"
                            style={{ color: color.themeColorNew }}
                          />
                        )}
                        <Typography
                          variant="p"
                          component="p"
                          align="left"
                          style={{
                            color: color.themeColorNew,
                            fontWeight: "500",
                            marginBottom: "0",
                            marginLeft: "8px",
                          }}
                        >
                          Unlimited Shares
                        </Typography>
                      </div>
                      <div className="d-flex flex-row align-items-center mb-3">
                        <CheckCircleIcon
                          value="feature"
                          fontSize="large"
                          style={{ color: color.themeColorNew }}
                        />
                        <Typography
                          variant="p"
                          component="p"
                          align="left"
                          style={{
                            color: color.themeColorNew,
                            fontWeight: "500",
                            marginBottom: "0",
                            marginLeft: "8px",
                          }}
                        >
                          {p.alerts === "unlimited" ? "Unlimited" : p.alerts}{" "}
                          Alerts
                        </Typography>
                      </div>
                      <div className="d-flex flex-row align-items-center mb-3">
                        <CheckCircleIcon
                          value="feature"
                          fontSize="large"
                          style={{ color: color.themeColorNew }}
                        />
                        <Typography
                          variant="p"
                          component="p"
                          align="left"
                          style={{
                            color: color.themeColorNew,
                            fontWeight: "500",
                            marginBottom: "0",
                            marginLeft: "8px",
                          }}
                        >
                          {p.storage} GB Storage
                        </Typography>
                      </div>
                      {/* <CustomButton
                        type="submit"
                        fullWidth={true}
                        btnText="I'D LIKE TO TRY TAG MONTHLY"
                        disabled={loading}
                        marginBottom="0"
                        height="52px"
                        // padding="16px"
                        background="transparent"
                        hoverBg="#0042a8"
                        border={`1px solid ${color.themeColorNew}`}
                        borderRadius="8px"
                        color={color.themeColorNew}
                        onClick={() => {
                          setSelectedPlan(p);
                          handleSubmit(p);
                        }}
                        rightIcon={
                          <ChevronRightIcon
                            style={{ borderLeft: "1px solid red" }}
                          />
                        }
                      /> */}
                      <button
                        type="submit"
                        onClick={() => {
                          setSelectedPlan(p);
                          handleSubmit(p);
                        }}
                        disabled={loading}
                        style={{
                          padding: 0,
                          color: "#060d5c",
                          border: "1px solid #060d5c",
                          height: "52px",
                          borderRadius: "8px",
                          textTransform: "capitalize",
                          width: "100%",
                          display: "flex",
                          ...(loading && {
                            opacity: "0.5",
                          }),
                          alignItems: "center",
                          marginBottom: "15px",
                          background:
                            type === "Monthly"
                              ? "transparent"
                              : color.themeYellow,
                        }}
                      >
                        <div
                          style={{
                            padding: "20px",
                            width: "-webkit-fill-available",
                          }}
                        >
                          {type === "Monthly"
                            ? "I'D LIKE TO TRY TAG MONTHLY"
                            : "I'D LIKE TO TRY TAG YEARLY"}
                        </div>
                        <div
                          style={{
                            borderLeft: "1px solid #060d5c",
                            padding: "15px",
                            height: "100%",
                            display: "flex",
                            justifyItems: "center",
                            alignItems: "center",
                          }}
                        >
                          <ChevronRightIcon />
                        </div>
                      </button>
                      <Typography
                        variant="p"
                        component="p"
                        align="left"
                        style={{
                          fontSize: "18px",
                          lineHeight: "21px",
                          color: color.themeColorNewLight,
                          fontWeight: "500",
                          marginBottom: "0",
                          marginLeft: "8px",
                        }}
                      >
                        {type === "Monthly" &&
                          "Everything you need to  store, share, and secure your data  and information. Per Member, Per Month."}
                        {type === "Annual" &&
                          "Keep billing simple! Make one payment and start tagging your data today. Per Member, Per Year."}
                      </Typography>
                    </div>
                    {/* {props?.loading ? (
                      <CustomButton
                        type="submit"
                        fullWidth={false}
                        btnText={<CircularProgress size={20} color="#DDD" />}
                        marginBottom="0"
                        height="52px"
                        padding="16px 120px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        border="0"
                        borderRadius="8px"
                        color="#fff"
                      />
                    ) : (
                      <CustomButton
                        type="submit"
                        fullWidth={true}
                        btnText="Select"
                        disabled={loading}
                        marginBottom="0"
                        height="52px"
                        // padding="16px"
                        background="#0042a8"
                        hoverBg="#0042a8"
                        border="0"
                        borderRadius="8px"
                        color="#fff"
                        onClick={() => {
                          setSelectedPlan(p);
                          handleSubmit(p);
                        }}
                      />
                    )} */}
                  </Card>
                </Grid>
              );
            })}
        {/* <Grid
          item
          className="h_100"
          xs={store.getState().auth.user.is_subscribed ? 6 : 12}
          sm={store.getState().auth.user.is_subscribed ? 6 : 6}
          md={store.getState().auth.user.is_subscribed ? 6 : 4}
          lg={store.getState().auth.user.is_subscribed ? 6 : 4}
        >
          <Card
            className="h_100 d-flex flex-column justify-content-between"
            style={{ padding: "25px" }}
          >
            <div>
              <Typography
                variant="p"
                component="p"
                align="left"
                style={{
                  color: color.themeYellow,
                  textTransform: "uppercase",
                  fontWeight: "bold",
                }}
              >
                Elite
              </Typography>
              <Typography
                variant="h5"
                component="p"
                align="left"
                style={{
                  color: color.themeColorNew,
                  fontWeight: "500",
                  // marginBottom: "20px",
                }}
              >
                $17/Month
              </Typography>
              <small style={{ color: "#919193", marginBottom: "5px" }}>
                $204/year
              </small>
              <div className="d-flex flex-row align-items-center mb-3">
                <CheckCircleIcon
                  value="feature"
                  fontSize="large"
                  style={{ color: color.themeColorNew }}
                />
                <Typography
                  variant="p"
                  component="p"
                  align="left"
                  style={{
                    color: color.themeColorNew,
                    fontWeight: "500",
                    marginBottom: "0",
                    marginLeft: "8px",
                  }}
                >
                  Access to all templates
                </Typography>
              </div>
              <div className="d-flex flex-row align-items-center mb-3">
                <CheckCircleIcon
                  value="feature"
                  fontSize="large"
                  style={{ color: color.themeColorNew }}
                />
                <Typography
                  variant="p"
                  component="p"
                  align="left"
                  style={{
                    color: color.themeColorNew,
                    fontWeight: "500",
                    marginBottom: "0",
                    marginLeft: "8px",
                  }}
                >
                  Multi profile
                </Typography>
              </div>
              <div className="d-flex flex-row align-items-center mb-3">
                <CheckCircleIcon
                  value="feature"
                  fontSize="large"
                  style={{ color: color.themeColorNew }}
                />
                <Typography
                  variant="p"
                  component="p"
                  align="left"
                  style={{
                    color: color.themeColorNew,
                    fontWeight: "500",
                    marginBottom: "0",
                    marginLeft: "8px",
                  }}
                >
                  Unlimited Shares
                </Typography>
              </div>
              <div className="d-flex flex-row align-items-center mb-3">
                <CheckCircleIcon
                  value="feature"
                  fontSize="large"
                  style={{ color: color.themeColorNew }}
                />
                <Typography
                  variant="p"
                  component="p"
                  align="left"
                  style={{
                    color: color.themeColorNew,
                    fontWeight: "500",
                    marginBottom: "0",
                    marginLeft: "8px",
                  }}
                >
                  Unlimited Alerts
                </Typography>
              </div>
              <div className="d-flex flex-row align-items-center mb-3">
                <CheckCircleIcon
                  value="feature"
                  fontSize="large"
                  style={{ color: color.themeColorNew }}
                />
                <Typography
                  variant="p"
                  component="p"
                  align="left"
                  style={{
                    color: color.themeColorNew,
                    fontWeight: "500",
                    marginBottom: "0",
                    marginLeft: "8px",
                  }}
                >
                  50 GB Storage
                </Typography>
              </div>
            </div>
            {props?.loading ? (
              <CustomButton
                type="submit"
                fullWidth={false}
                btnText={<CircularProgress size={20} color="#DDD" />}
                marginBottom="0"
                height="52px"
                padding="16px 120px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
              />
            ) : (
              <CustomButton
                type="submit"
                fullWidth={true}
                disabled={
                  loading ||
                  store.getState().auth?.user?.account_type === "Elite"
                }
                btnText="Select"
                marginBottom="0"
                height="52px"
                // padding="16px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
                onClick={() => {
                  if (!store.getState().auth?.user?.is_subscribed) {
                    handleOpenAddNewCard("$17/mo", "Elite");
                  } else if (
                    store.getState().auth?.user?.account_type === "Free" &&
                    methods?.length <= 0
                  ) {
                    handleOpenAddNewCard("$17/mo", "Elite");
                  } else {
                    setPayload("Elite");
                    updatePlan("Elite");
                  }
                }}
              />
            )}
          </Card>
        </Grid> */}
      </Grid>
      {!store.getState()?.auth?.user?.is_subscribed && (
        <Grid
          container
          wrap="wrap"
          direction="row"
          // justifyContent="center"
          alignItems="center"
          spacing={3}
          className="py-5 py-lg-0 px-3 px-lg-0"
        >
          <Grid item md={12}>
            <Typography
              variant="p"
              component="p"
              className="mb-3"
              align="left"
              style={{
                color: color.themeColorNew,
                fontWeight: "500",
                marginLeft: "0",
              }}
            >
              *All Premium features for 30 days. Account will require upgrading
              or will be frozen at 30 days. Account will be deleted after 60
              days
            </Typography>
          </Grid>
        </Grid>
      )}
      {openAddNewCard && (
        <AddNewCard
          open={openAddNewCard}
          handleClose={handleCloseAddNewCard}
          handleOpenPaymentSuccessfull={handleOpenPaymentSuccessfull}
          plan={plan}
          price={price}
          type={type}
          openAlert={() => setOpenPaymentSuccessfull(true)}
        />
      )}
      <PaymentSuccessfull
        open={openPaymentSuccessfull}
        handleClose={handleClosePaymentSuccessfull}
      />
    </>
  );
};
