import React from "react";
import {Grid, Typography} from "@material-ui/core";
import {CustomButton} from "../../components/CustomButton/Button";

export const RegisterEmail = (props) => {
    return (
        <Grid container direction="column" alignItems="center">
            <Typography
                variant="h5"
                component="h3"
                align="center"
                style={{margin: "0 0 10px"}}
            >
                Thank you for signing up!
            </Typography>
            <Typography
                variant="p"
                component="p"
                align="center"
                style={{margin: "0 0 15px"}}
            >
                An email has been sent out to verify your account.
            </Typography>
            {/* <CustomButton
                type="submit"
                path={"/auth/login"}
                fullWidth={false}
                btnText="RETURN TO LOGIN"
                margin="0 auto"
                padding="14px 20px"
                background="transparent"
                hoverBg="#0042a8"
                border="2px solid #0042a8"
                borderRadius="8px"
                color="#0042a8"
                className="d_xs_none"
                onClick={() => props?.history?.push("/auth/login")}
            /> */}
        </Grid>
    );
};
