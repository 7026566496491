import {
  container,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
} from "../../material-dashboard-react.js";

const headerStyle = (theme) => ({
  appBar: {
    [theme.breakpoints.down("sm")]: {
      padding: "20px 0",
    },
    backgroundColor: "#EDF1F4",
    boxShadow: "none",
    borderBottom: "2px solid #D6D6D6",
    marginBottom: "0",
    position: "sticky",
    width: "100%",
    paddingTop: "35px",
    zIndex: "1000",
    color: grayColor[7],
    border: "0",
    borderRadius: "3px",
    padding: "30px 0 20px",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block",
  },
  container: {
    ...container,
    minHeight: "50px",
    padding: "0 70px!important",
    [theme.breakpoints.down("sm")]: {
      padding: "0!important",
      flexDirection: "column",
    },
  },
  flex: {
    flex: 1,
  },
  title: {
    ...defaultFont,
    letterSpacing: "unset",
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    margin: "0",
    "&:hover,&:focus": {
      background: "transparent",
    },
  },
  appResponsive: {
    top: "8px",
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  floatRight: {
    float: "right",
  },
});

export default headerStyle;
