import "date-fns";
import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useTheme,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { dateFormat } from "../../../Utils/dateFormate.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { HealthService } from "../../../services/health.service.js";
import SureToAdd from "../Alerts/SureToAdd/SureToAdd.js";
import AddDocument from "../AddDocument/AddDocument.js";
import EditDocument from "../EditDocument/EditDocument.js";
import DateFnsUtils from "@date-io/date-fns";
import disableCheck from "../../../Utils/disableCheck.js";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { getDateIgnoreTimezone } from "../../../Utils/dateHelper.js";
import PasswordVerification from "../PasswordVerification/PasswordVerification.js";
import SpinnerModal from "../Alerts/SpinnerModal/SpinnerModal.js";
import AlertPopup from "../Alerts/Generic/GenericAlert.js";
import { DocumentService } from "../../../services/document.service.js";
import FileViewer from "../../../components/FileViewer/FileViewer.js";
import _default from "../../../config/default.js";

const stateArray = ["Mild", "Moderate", "Severe", "Debilitating"];

const treatmentMedication = ["Other"];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles(styles);

function EditAllergy(props) {
  const classes = useStyles();
  const errRef = useRef(null);
  const documentService = new DocumentService();
  const healthService = new HealthService();
  const [err, setErr] = React.useState([]);
  const [documentModal, setDocumentModal] = React.useState(false);
  const [editDoc, setEditDoc] = React.useState(false);
  const [currentDocument, setCurrentDocument] = React.useState(null);
  const [showDoc, setShowDoc] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [spinner, setSpinner] = React.useState(false);
  const [alert, setAlert] = React.useState({
    open: false,
    type: null,
    btnText: null,
    cancelText: null,
    heading: null,
    message: null,
  });
  const [password, setPassword] = React.useState(null);
  const [showPasswordVerification, setShowPasswordVerification] =
    React.useState(false);
  const obj = props?.allergy?.associated_documents_attributes
    ? props?.allergy?.associated_documents_attributes
    : [];
  const [doc, setDoc] = React.useState(
    props?.allergy?.documents ? props?.allergy?.documents : []
  );
  const [medications, setMedications] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [other, setOther] = React.useState(false);
  const [docId, setDocId] = React.useState(null);
  const [data, setData] = React.useState({
    associated_documents_attributes: obj,
    allergy_name: props?.allergy?.allergy_name,
    reaction: props?.allergy?.reaction,
    severity: props?.allergy?.severity,
    medication_id: props?.allergy?.medication_id,
    other_medication: props?.allergy?.other_medication,
    status: props?.allergy?.status,
    start_date: props?.allergy?.start_date
      ? props?.allergy?.start_date?.replaceAll("-", "/")
      : null,
    end_date: props?.allergy?.end_date
      ? props?.allergy?.end_date?.replaceAll("-", "/")
      : null,
    notes: props?.allergy?.notes,
  });
  const [sureToAdd, setSureToAdd] = React.useState({ open: false, str: null });

  useEffect(() => {
    healthService
      .medicationsIndex()
      .then((res) => {
        if (res.data && res.data.medications) {
          let array = res.data.medications;
          setMedications(array);
        }
      })
      .catch((e) => {
        setMedications([]);
      });
  }, []);
  const handleOpenSureToAdd = (str) => {
    setSureToAdd({ open: true, str: str ?? null });
  };
  const handleCloseSureToAdd = () => {
    if (sureToAdd.str !== null) {
      props?.handleClose();
    }
    setSureToAdd({ open: false, str: null });
  };

  const handleSubmit = () => {
    handleCloseSureToAdd();
    console.log({ data });
    const temp = data?.associated_documents_attributes?.filter(
      (e) => e.document_id !== null
    );
    if (props?.allergy?.associated_documents_attributes) {
      setData({ ...data, associated_documents_attributes: temp });
    } else {
      if (temp && temp.length > 0) {
        setData({ ...data, associated_documents_attributes: temp });
      } else {
        let temp2 = delete data["associated_documents_attributes"];
        setData(data);
      }
    }
    let payload = { ...data };
    if (payload.end_date) {
      payload = {
        ...payload,
        end_date: getDateIgnoreTimezone(new Date(payload.end_date))
          .toISOString()
          .slice(0, 10),
      };
    }
    if (payload.start_date) {
      payload = {
        ...payload,
        start_date: getDateIgnoreTimezone(new Date(payload.start_date))
          .toISOString()
          .slice(0, 10),
      };
    }
    setIsLoading(true);
    healthService
      .editAllergies(
        {
          ...payload,
          medication_id: data.medication_id === null ? "" : data.medication_id,
        },
        props?.allergy?.id
      )
      .then((response) => {
        setIsLoading(false);
        props.forceRerender();
        props.handleClose();
        console.log(response.data);
      })
      .catch((err) => {
        errRef.current.scrollIntoView();
        setIsLoading(false);
        if (err.response && err.response.data.message) {
          if (err.response?.data?.message === "Please enter a valid date") {
            setErr([err.response?.data?.message]);
          } else {
            let array = [];
            Object.keys(err.response.data.message).forEach((key) =>
            {
              const arr = err.response.data.message[key]
              arr.forEach((val) => {
                array.push(`${key} ${val}`)
              })
            }
            );
            setErr(array);
          }
        } else {
          setErr(["Internal Server Error"]);
        }
      });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = (event) => {
    if (event.target.name === "medication_id1") {
      if (event.target.value !== "Other") {
        setOther(false);
        setData({ ...data, medication_id: event.target.value });
      } else {
        setOther(true);
      }
    } else {
      setData({ ...data, [event.target.name]: event.target.value });
    }
  };

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChangeCountry = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const show = (docId) => {
    const documentId = docId ?? id;
    setSpinner(true);
    documentService
      .show(documentId, password)
      .then((res) => {
        setSpinner(false);
        setLink({
          attachment: res.data.document.attachment,
          contentType: res.data.document.content_type,
        });
        setShowDoc(true);
        setShowPasswordVerification(false);
        setPassword(null);
      })
      .catch((err) => {
        setSpinner(false);
        setPassword(null);
        setAlert({
          type: "Error",
          open: true,
          cancelText: "CANCEL",
          heading:
            err?.response && err?.response?.status === 422
              ? "Unauthorized!"
              : "Error!",
          message:
            err?.response && err?.response?.status === 422
              ? "Wrong password! please try again."
              : "An unknown error occurred, please try later.",
        });
      });
  };
  const initialRef = useRef(null);
  React.useEffect(() => {
    initialRef.current = data;
  }, []);

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={() => {
          !disableCheck(initialRef, data)
            ? handleOpenSureToAdd("exit")
            : props?.handleClose();
        }}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        {alert.open && (
          <AlertPopup
            btnText={alert.btnText}
            cancelText={alert.cancelText}
            open={alert.open}
            type={alert.type}
            heading={alert.heading}
            message={alert.message}
            isLoading={false}
            handleClose={() => {
              setAlert({
                open: false,
                btnText: null,
                cancelText: null,
                heading: null,
                message: null,
                type: null,
              });
            }}
            handleClick={() => {
              setAlert({
                open: false,
                btnText: null,
                cancelText: null,
                heading: null,
                message: null,
                type: null,
              });
            }}
          />
        )}
        {spinner && <SpinnerModal open={spinner} msg={"Loading"} />}
        {showDoc && (
          <FileViewer
            contentType={link?.contentType}
            link={link?.attachment}
            open={showDoc}
            handleClose={() => setShowDoc(false)}
          />
        )}
        {showPasswordVerification && (
          <PasswordVerification
            open={showPasswordVerification}
            handleClose={() => {
              setShowPasswordVerification(false);
              setId(null);
            }}
            handleChange={(e) => setPassword(e)}
            handleSubmit={() => {
              show();
            }}
          />
        )}
        <ModalHeader
          closeModal={() => {
            !disableCheck(initialRef, data)
              ? handleOpenSureToAdd("exit")
              : props?.handleClose();
          }}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Edit " + data.allergy_name}
          color={color.white}
        />
        <div className={classes.modalBody} ref={errRef}>
          <div className="w-100 d-flex flex-row mb-4">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Health > Allergies > Edit"}
            </Typography>
          </div>
          {err?.length > 0 && (
            <div className="w-100 d-flex flex-column mb-4">
              {err?.map((val, key) => {
                return (
                  <Typography
                    key={key}
                    variant="p"
                    component="p"
                    align="left"
                    style={{
                      color: "red",
                      fontWeight: "normal",
                      marginBottom: "2px",
                    }}
                  >
                    {val.charAt(0).toUpperCase() + val.slice(1)}
                  </Typography>
                );
              })}
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            {editDoc && (
              <EditDocument
                password_protected={currentDocument?.password_protected}
                docs={doc}
                category={currentDocument?.category}
                id={currentDocument.id}
                title={currentDocument.title}
                open={editDoc}
                setDocs={setDoc}
                handleClose={() => setEditDoc(false)}
              />
            )}
            {documentModal && (
              <AddDocument
                setData={setData}
                setDoc={setDoc}
                data={data}
                associated_documents_attributes={
                  props?.allergy?.associated_documents_attributes ? true : false
                }
                doc={doc}
                open={documentModal}
                handleClose={() => setDocumentModal(false)}
                closeModal={() => setDocumentModal(false)}
                category={"Personal"}
              />
            )}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Allergy *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="allergy_name"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.allergy_name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Reaction"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="reaction"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.reaction}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Severity"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="state"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={data?.severity}
                  onChange={handleChange}
                  name="severity"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled>Select Severity</MenuItem>
                  {stateArray?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Treatment Medication"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="state"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={
                    data?.medication_id === null ? "other" : data?.medication_id
                  }
                  onChange={(e) =>
                    e.target.value === "other"
                      ? setData({ ...data, medication_id: null })
                      : setData({ ...data, medication_id: e.target.value })
                  }
                  name="medication_id"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled>Select Medication</MenuItem>
                  {medications?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state?.id}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state?.medicine}
                      </MenuItem>
                    );
                  })}
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              </FormControl>
              {data?.medication_id === null ? (
                <FormInput
                  name="other_medication"
                  onChange={handleChange}
                  label=""
                  variant="outlined"
                  fullWidth={true}
                  value={data?.other_medication}
                  margin="dense"
                  required={true}
                  placeholder={"Specify Other Medication"}
                />
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Status"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="status"
                  value={data?.status}
                  onChange={handleChange}
                  className="flex-row"
                >
                  <FormControlLabel
                    value="current"
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Current"
                  />
                  <FormControlLabel
                    value="previous"
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Previous"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Start Date"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  inputVariant="outlined"
                  format={dateFormat()}
                  id="start_date"
                  placeholder={dateFormat()?.toUpperCase()}
                  value={data?.start_date}
                  onChange={(date) => {
                    setData({
                      ...data,
                      start_date: date,
                    });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"End Date"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  inputVariant="outlined"
                  format={dateFormat()}
                  id="start_date"
                  placeholder={dateFormat()?.toUpperCase()}
                  value={data?.end_date}
                  onChange={(date) => {
                    setData({
                      ...data,
                      end_date: date,
                    });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Notes"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="notes"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.notes}
              />
            </Grid>
            {doc.length > 0 && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Document"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                  {doc.map((e, k) => (
                    <>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                        key={k}
                      >
                        {e?.title}
                      </Typography>
                      <CustomButton
                        btnText={"Remove"}
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          let temp = data.associated_documents_attributes?.map(
                            (d) => {
                              if (d.document_id === e.id) {
                                return {
                                  ...d,
                                  _destroy: 1,
                                };
                              } else {
                                return d;
                              }
                            }
                          );
                          let newDoc = doc?.filter((d) => d.id !== e.id);
                          setDoc(newDoc);
                          setData({
                            ...data,
                            associated_documents_attributes: temp,
                          });
                        }}
                      />

                      <CustomButton
                        btnText={"Edit"}
                        className="ms-2"
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          setCurrentDocument(e);
                          setEditDoc(true);
                        }}
                      />

                      <CustomButton
                        btnText={"View"}
                        className="ms-2"
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          if (!props?.allergy?.documents) {
                            show(e?.id);
                          } else {
                            if (e?.attachment) {
                              setLink({
                                attachment: _default.BASE_URL + e.attachment,
                                contentType: e.content_type,
                              });
                              setShowDoc(true);
                            } else {
                              setId(e?.id);
                              setShowPasswordVerification(true);
                            }
                          }
                        }}
                      />
                    </>
                  ))}
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              <CustomButton
                btnText="Add Document"
                className="mb-sm-2"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
                marginRight={"10px"}
                onClick={() => setDocumentModal(true)}
              />

              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  disabled={disableCheck(initialRef, data)}
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  onClick={() => handleOpenSureToAdd(null)}
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
      <SureToAdd
        open={sureToAdd.open}
        handleClose={handleCloseSureToAdd}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAllergy);
