export const pateintRegistrationHtml = (doc) => {
  const lifeInsuranceFn = () => {
    let lifeInsurances = "";
    doc?.life_insurances?.length > 0 ? (
      doc?.life_insurances?.forEach((e) => {
        lifeInsurances += `<div>
            <p><strong>Provider Type - </strong>${e?.provider_type}</p>
    </div>`;
      })
    ) : (
      <div></div>
    );
    return { lifeInsurances };
  };
  const disabilityInsuranceFn = () => {
    let disabilityInsurances = "";
    doc?.disability_insurances?.length > 0 ? (
      doc?.disability_insurances?.forEach((e) => {
        disabilityInsurances += `<div>
         <p><strong>Provider - </strong>${e?.provider}</p>
         </div>`;
      })
    ) : (
      <div></div>
    );
    return { disabilityInsurances };
  };
  const healthInsuranceFn = () => {
    let healthInsurances = "";
    doc?.health_insurances?.length > 0 ? (
      doc?.health_insurances?.forEach((e) => {
        healthInsurances += `<div>
            <p><strong>Provider - </strong>${e?.provider}</p>
             <p><strong>Policy Type - </strong>${e?.policy_type}</p>

        </div`;
      })
    ) : (
      <div></div>
    );
    return { healthInsurances };
  };

  const medicationFn = () => {
    let medications = "";
    doc?.medications?.length > 0 ? (
      doc?.medications?.forEach((e) => {
        medications += `<div>
            <p><strong>Medication Type - </strong>${e?.medication_type}</p>
            <p><strong>Clinician - </strong>${e?.clinician}</p>
            <p><strong>Frequency - </strong>${e?.frequency}</p>
            <p><strong>Start Date - </strong>${e?.start_date}</p>
        </div>`;
      })
    ) : (
      <div></div>
    );
    return { medications };
  };
  const familyConditionFn = () => {
    let familyConditions = "";
    doc?.family_conditions?.length > 0 ? (
      doc?.family_conditions?.forEach((e) => {
        familyConditions += `<div>
            <p><strong>Severity- </strong>${e?.severity}</p>
            <p><strong>Relative Name - </strong>${e?.relative_name}</p>
            <p><strong>Relation - </strong>${e?.relation}</p>
            <p><strong>Region - </strong>${e?.region}</p>
            <p><strong>Birthdate - </strong>${e?.birth_date}</p>
            <p><strong>Date Diagnosed - </strong>${e?.date_deceased}</p>
            <p><strong>Gender - </strong>${e?.gender}</p>
            <p><strong>Was he/she adopted? - </strong>${e?.adopted}</p>
            <p><strong>Was he/she concieved by donor egg or sperm? - </strong>${e?.conceived_by_donor}</p>
            
        </div>`;
      })
    ) : (
      <div></div>
    );
    return { familyConditions };
  };
  const healthConditionFn = () => {
    let healthConditions = "";
    doc?.health_conditions?.length > 0 ? (
      doc?.health_conditions?.forEach((e) => {
        healthConditions += `<div>
            <p><strong>Condition- </strong>${e?.health_condition}</p>
            <p><strong>Clinician - </strong>${e?.clinician}</p>
            <p><strong>Start Date - </strong>${e?.start_date}</p>
            <p><strong>End Date - </strong>${e?.end_date}</p>
            
        </div>`;
      })
    ) : (
      <div></div>
    );
    return { healthConditions };
  };
  const procedureFn = () => {
    let procedures = "";
    doc?.procedures?.length > 0 ? (
      doc?.procedures?.forEach((e) => {
        procedures += `<div>
            <p><strong>Encrypted Procedure- </strong>${e?.procedure}</p>
            <p><strong>Clinician - </strong>${e?.clinician}</p>
            <p><strong>Start Date - </strong>${e?.start_date}</p>
            <p><strong>End Date - </strong>${e?.end_date}</p>
            
        </div>`;
      })
    ) : (
      <div></div>
    );
    return { procedures };
  };
  const { lifeInsurances } = lifeInsuranceFn();
  const { disabilityInsurances } = disabilityInsuranceFn();
  const { healthInsurances } = healthInsuranceFn();
  const { medications } = medicationFn();
  const { familyConditions } = familyConditionFn();
  const { healthConditions } = healthConditionFn();
  const { procedures } = procedureFn();
  let html = `<h1>Personal Information</h1>
    <p><strong>Name </strong>${
      doc?.personal_information?.first_name +
      " " +
      doc?.personal_information?.last_name
    }</p>
        <p><strong>Birthdate </strong>${
          doc?.personal_information?.birthdate
        }</p>

    <p><strong>Gender </strong>${doc?.personal_information?.gender}</p>

    <p><strong>Blood Type </strong>${doc?.personal_information?.blood_type}</p>

    <p><strong>Relationship Status </strong>${
      doc?.personal_information?.relationship_status
    }</p>
        <p><strong>Employment Status </strong>${
          doc?.personal_information?.employment_status
        }</p>


<br>
<h1>Life Insurances</h1>
 ${lifeInsurances}
<h1>Disability Insurance</h1>
${disabilityInsurances}
<h1>Health Insurance</h1>
${healthInsurances}
<h1>Medication</h1>
${medications}
<h1>Family Condition</h1>
${familyConditions}
<h1>Health Condition</h1>
${healthConditions}
<h1>Procedure</h1>
${procedures}

`;
  console.log({ html });
  return { html };
};
