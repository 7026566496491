import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { DocumentService } from "../../../services/document.service";
import { Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "../../../assets/jss/material-dashboard-react/components/ActivityHistoryAccordion.js";
import Card from "../../Card/Card";
import file from "../../../assets/img/file.png";
import { MoreVert } from "@material-ui/icons";
import { connect } from "react-redux";
import color from "../../../assets/css/color";
import ShareModal from "../../../containers/Modals/ShareModal/ShareModal";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import SuccessGreen from "../../../containers/Modals/Alerts/SuccessGreen/SuccessGreen";
import Error from "../../../containers/Modals/Alerts/Error/Error";
import AddContact from "../../../containers/Modals/AddContact/AddContact";
import { Converter } from "../../../services/dataToHTML.service";
import { PersonalService } from "../../../services/personal.service";
import Warning from "../../../containers/Modals/Alerts/Warning/Warning";
import ContactsInformation from "../../../containers/Modals/ContactInformation/ContactInformation";
import moment from "moment/moment";

const useStyles = makeStyles(styles);

const documentService = new DocumentService();

function Contacts(props) {
  const [openEditDocument, setOpenEditDocument] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [activitiesPerPage, setActivitiesPerPage] = useState(5);
  const [openError, setOpenError] = useState(false);
  const [open, setOpen] = useState(false);
  const [openn, setOpenn] = React.useState(false);
  const [docs, setDocs] = useState([]);
  const [err, setErr] = useState(null);
  const [warning, setWarning] = useState(false);
  const [message, setMessage] = useState(null);
  const [id, setId] = useState(null);
  const handleToggle = (key) => {
    setExpanded(key);
    setOpenn((prevOpen) => !prevOpen);
  };
  const personalService = new PersonalService();
  const [openShare, setOpenShare] = useState(false);
  const options = ["Edit", "View", "Share", "Delete"];
  const handleShare = async () => {
    const converter = new Converter(docs[expanded], "Contact");
    let html_string = converter.convertToHtml();
    let data = {
      html_string,
      id: docs[expanded].id,
      subcategory: "Contact",
    };
    personalService
      .shareSub(data)
      .then((res) => {
        localStorage.setItem("id", res.data.subcategory_pdf.id);
        setOpenShare(true);
      })
      .catch((err) => {
        alert("share failed");
      });
  };
  const handleMenuItemClick = (option, id, key) => {
    setExpanded(key);
    setOpenn(false);
    if (option === "Edit") {
      setOpenEditDocument(true);
    } else if (option === "Share") {
      personalService
        .checkShare()
        .then((res) => {
          if (res.data && res.data.check_share === true) {
            handleShare();
          } else {
            setMessage(
              "Your monthly shares have reached their limit, please upgrade your subscription for unlimited shares!"
            );
            setWarning(true);
          }
        })
        .catch((err) => {
          setMessage("An unknown error occurred. Please try again later!");
          setWarning(true);
        });
    } else if (option === "View") {
      setOpenInfo(true);
    } else {
      documentService
        .deleteContact(id)
        .then(() => {
          const array = docs.filter((d) => d.id !== id);
          setDocs(array);
        })
        .catch(() => {});
    }
    // setOpen(false);
  };
  useEffect(() => {
    setLoading(true);
    documentService
      .indexContact()
      .then((res) => {
        setLoading(false);
        setDocs(res.data.contacts ? res.data.contacts : []);
      })
      .catch((err) => {
        setLoading(false);
        setErr(err.response ? err.response.data.error : "something went wrong");
      });
  }, [props?.forceRender]);

  const indexOfLastActivity = currentPage * activitiesPerPage;
  const indexOfFirstActivity = indexOfLastActivity - activitiesPerPage;
  const currentDocs = docs.slice(indexOfFirstActivity, indexOfLastActivity);

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleCloseEditDocument = () => {
    setOpenEditDocument(false);
  };

  return (
    <Grid container spacing="5">
      {!loading && docs?.length > 0 ? (
        docs
          .sort((a, b) => {
            const name1 = a.firstName + a.lastName;
            const name = b.firstName + b.lastName;
            if (props?.sortType === "alpha") {
              if (props?.order === "asc") {
                return name1?.localeCompare(name);
              } else {
                return name?.localeCompare(name1);
              }
            } else if (props?.sortType === "date") {
              if (props?.order === "asc") {
                return new Date(b?.created_at) - new Date(a?.created_at);
              } else {
                return new Date(a?.created_at) - new Date(b?.created_at);
              }
            }
          })
          .map((doc, key) => (
            <div
              className="fav_width"
              style={{
                cursor: "pointer",
              }}
            >
              {warning && (
                <Warning
                  open={warning}
                  handleClose={() => setWarning(false)}
                  heading={"Error"}
                  one={true}
                  message={message}
                  onClick={() => {
                    setWarning(false);
                  }}
                />
              )}
              <Card
                className="p-4 h-auto mb-3 min-height-182px"
                data-tooltip={doc?.firstName}
                data-tooltip-location="top"
              >
                <img
                  src={file}
                  width="30"
                  className="mb-3"
                  onClick={() => {
                    setExpanded(key);
                  }}
                />
                <Typography
                  variant="h6"
                  component="h3"
                  style={{ color: "#060d5c" }}
                  onClick={() => {
                    setExpanded(key);
                    setOpenInfo(true);
                  }}
                >
                  {doc.firstName && doc.firstName?.split(" ")[0]} {doc.lastName && doc.lastName?.split(" ")[0]}
                  {console.log(doc,'doc======>')}
                </Typography>
                <Typography
                  variant="p"
                  component="p"
                  style={{ color: "#060d5c" }}
                >
                  {moment(
                    moment(doc?.created_at, "YYYY-MM-DD").toDate()
                  ).format("MMMM DD, YYYY")}
                </Typography>
                <div className="position-absolute dropMenuDocumentMain">
                  <MoreVert
                    color="primary"
                    aria-controls={open ? "split-button-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={() => handleToggle(key)}
                    style={{
                      cursor: "pointer",
                      color: color.themeYellow,
                    }}
                  />
                </div>

                {expanded === key && openEditDocument ? (
                  <AddContact
                    edit={true}
                    contacts={doc}
                    open={openEditDocument}
                    handleClose={handleCloseEditDocument}
                  />
                ) : null}
                <>
                  {expanded === key && openShare ? (
                    <ShareModal
                      open={openShare}
                      id={JSON.parse(localStorage.getItem("id"))}
                      title={doc?.firstName || doc?.firstName}
                      category={"Contact"}
                      handleClose={() => setOpenShare(false)}
                    />
                  ) : null}
                </>
                <>
                  {expanded === key && openInfo ? (
                    <ContactsInformation
                      open={openInfo}
                      contact={doc}
                      handleOpenEdit={() => {
                        setOpenInfo(false);
                        setOpenEditDocument(true);
                      }}
                      handleClose={() => setOpenInfo(false)}
                    />
                  ) : null}
                </>
                {expanded === key ? (
                  <Popper
                    className="position-absolute dropMenuDocument"
                    style={{ zIndex: 10000 }}
                    open={openn}
                    anchorEl={props.anchorRef}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener
                            onClickAway={() => setOpenn(false)}
                          >
                            <MenuList id="split-button-menu">
                              {options.map((option, index) => (
                                <MenuItem
                                  key={option}
                                  // selected={index === selectedIndex}

                                  onClick={(event) =>
                                    handleMenuItemClick(option, doc.id, key)
                                  }
                                >
                                  {option}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                ) : null}
              </Card>
              {open && (
                <SuccessGreen
                  open={open}
                  handleClose={() => setOpen(false)}
                  message="Document has successfully been deleted"
                />
              )}
              {openError && (
                <Error
                  open={open}
                  handleClose={() => setOpenError(false)}
                  message={"Could not delete the document"}
                />
              )}
            </div>
          ))
      ) : err ? (
        <p>{err}</p>
      ) : !loading && docs.length === 0 ? (
        <Grid item xs={12} md={3}>
          <p>No Contacts yet</p>
        </Grid>
      ) : (
        <Grid item xs={12} md={3}>
          <CircularProgress size={20} color="#DDD" />
        </Grid>
      )}
    </Grid>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    forceRender: state.loading.render,
    sortType: state?.sort?.sort,
    order: state?.sort?.order,
  };
};

export default connect(mapStateToProps)(Contacts);
