import "date-fns";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import {FormInput} from "../../../components/FormInput/FormInput.js";
import {CustomButton} from "../../../components/CustomButton/Button.js";
import {Close} from "@material-ui/icons";
import {Auth} from "../../../services/auth.service";

const useStyles = makeStyles(styles);

const authService = new Auth()

function EditEmailAddress(props) {
    const [newEmail, setNewEmail] = useState("")
    const [confirmEmail, setConfirmEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState(null)
    const classes = useStyles();

    const handleSubmit = () => {
        setLoading(true)
        const data = {
            new_email: newEmail,
            confirm_email: confirmEmail,
            password
        }
        props?.handleClose()
    };


    return (
        <>
            <CustomModal
                visible={props.open}
                onClose={props.handleClose}
                width="55%"
                margin="120px 0 40px"
                inset="15px!important"
            >
                <ModalHeader
                    closeModal={props.handleClose}
                    icon={
                        <Close
                            style={{
                                color: color.white,
                                marginRight: "20px",
                                fontSize: "30px",
                                cursor: "pointer",
                            }}
                        />
                    }
                    heading={"Edit | salmanhaseeb@gmail.com"}
                    color={color.white}
                />
                <div className={classes.modalBody}>
                    <Grid
                        container
                        wrap="wrap"
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <Grid item xs={12} sm={3} md={3}>
                            <Typography
                                variant="h6"
                                component="h3"
                                style={{color: color.themeColorNew}}
                            >
                                {"New Email *"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} md={9}>
                            <FormInput
                                onChange={(e) => setNewEmail(e.target.value)}
                                label=""
                                variant="outlined"
                                fullWidth={true}
                                margin="dense"
                                required={true}
                                placeholder={"New Email"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <Typography
                                variant="h6"
                                component="h3"
                                style={{color: color.themeColorNew}}
                            >
                                {"Confirm New Email *"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} md={9}>
                            <FormInput
                                onChange={(e) => setConfirmEmail(e.target.value)}
                                label=""
                                variant="outlined"
                                fullWidth={true}
                                margin="dense"
                                required={true}
                                placeholder={"Confirm New Email"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                        </Grid>
                        <Grid item xs={12} sm={9} md={9}>
                            <Typography
                                variant="p"
                                component="p"
                                style={{color: color.themeColorNew}}
                            >
                                {"Please enter your TagData's master pasword to save your new email address."}
                            </Typography>
                            <FormInput
                                onChange={(e) => setPassword(e.target.value)}
                                label=""
                                variant="outlined"
                                fullWidth={true}
                                margin="dense"
                                required={true}
                                placeholder={"password"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="text-sm-center" style={{textAlign: "right"}}>
                            <CustomButton
                                type="submit"
                                btnText="Save"
                                textAlign="center"
                                display={"block"}
                                padding="12px 25px"
                                minWidth="113px"
                                background="#0042a8"
                                hoverBg="#0042a8"
                                border="0"
                                marginRight="10px"
                                borderRadius="8px"
                                color="#fff"
                                onClick={handleSubmit}
                            />

                            <CustomButton
                                onClick={() => props.handleClose()}
                                btnText="Cancel"
                                textAlign="center"
                                display={"block"}
                                padding="12px 25px"
                                minWidth="113px"
                                background="transparent"
                                hoverBg="#0042a8"
                                border="1px solid #0042a8"
                                borderRadius="8px"
                                color="#0042a8"
                            />
                        </Grid>
                    </Grid>
                </div>
            </CustomModal>
        </>
    );
}

export default EditEmailAddress;
