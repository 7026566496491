import React, { useCallback, useEffect, useState } from "react";
import dashboardRoutes from "../../../routes";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { connect } from "react-redux";
import Folder from "../../../assets/img/Folder.svg";
import file from "../../../assets/img/file.png";
import CardFooter from "../../Card/CardFooter";
import { Grid, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { HistoryService } from "../../../services/history.service";
import { truncateString } from "../../../Utils/truncateString";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import moment from "moment/moment";
import { sort } from "../../../store/actions/sortAction";
import color from "../../../assets/css/color";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  indicator: {
    background: "none",
  },

  tabsMain: {
    marginBottom: "50px",
    "& .MuiTabs-flexContainer": {
      overflow: "auto",
    },
    "& ::-webkit-scrollbar": {
      height: "0",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: "#0042a8",
      color: "white",
    },
    "& Button": {
      background: "#fff",
      borderRadius: "25em",
      marginRight: "15px",
      backgroundColor: "#fff",
      minWidth: "160px",
      maxWidth: "250px",
      width: "max-content",
      color: "black",
      opacity: 1,
      "&:hover, &:active, &:focus": {
        backgroundColor: "#0042a8",
        color: "white",
        opacity: 1,
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
}));

const Favorite = (props) => {
  const historyService = new HistoryService();
  const handleDelete = (id) => {
    historyService
      .favouritesDestroy(id)
      .then((res) => {
        setFav(fav.filter((f) => f.id !== id));
      })
      .catch((err) => {});
  };

  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [bell, setBell] = useState(false);
  const [fav, setFav] = useState([]);

  const fetchFavorites = () => {
    if (props?.profile?.profileInfo !== null) {
      setLoading(true);
      historyService
        .favouritesIndex(props?.profile?.profileInfo?.profile.id)
        .then((res) => {
          setLoading(false);
          setFav(res?.data?.favorites);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }
  useEffect(() => {
    console.log("favorites => ", props.profile)
    fetchFavorites();
    return () => {
      if (props?.sort === "count") {
        props.sortfn("alpha", "asc");
      }
    };
  }, []);
  return (
    <Grid container>
      {fav?.length > 0 ? (
        fav
          ?.sort((a, b) => {
            if (props?.sort === "alpha") {
              if (props?.order === "asc") {
                return a.subcategory?.localeCompare(b.subcategory);
              } else {
                return b.subcategory?.localeCompare(a.subcategory);
              }
            } else if (props?.sort === "date") {
              if (props?.order === "asc") {
                return a.last_modified?.localeCompare(b.last_modified);
              } else {
                return b.last_modified?.localeCompare(a.last_modified);
              }
            } else {
              if (props?.order === "asc") {
                return a?.subcategories_count - b?.subcategories_count;
              } else {
                return b?.subcategories_count - a?.subcategories_count;
              }
            }
          })
          .map((f) => {
            let route;
            dashboardRoutes?.forEach((r) => {
              r.submenu.forEach((s) => {
                if (s.name === f.subcategory) {
                  route = `${r.layout + r.path + s.path}`;
                }
              });
            });
            return (
              <div className="fav_width">
                <Card
                  animation={true}
                  extracslass="mb-3"
                  data-tooltip={f?.subcategory}
                  data-tooltip-location="top"
                >
                  <CardHeader color="warning" stats icon>
                    <div
                      className={
                        "position-relative d-flex mb-3 justify-content-between"
                      }
                    >
                      <div>
                        {f?.alert_check && (
                          <NotificationsActiveIcon
                            style={{ color: "#F50057", margin: "0" }}
                            className={f?.upcoming_check ? "bell" : ""}
                          />
                        )}
                      </div>
                      <div className="position-relative">
                        <FavoriteIcon
                          style={{
                            cursor: "pointer",
                            margin: "0",
                            color: color.themeYellow,
                          }}
                          // color="secondary"
                          onClick={() => handleDelete(f?.id)}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {/* <FolderOutlined style={{ color: "black" }} /> */}
                      <div className="position-relative mb12px">
                        <img src={Folder} className="" />
                        <div className="position-absolute top-50 start-50 translate-middle text-black fs-5 lh-1">
                          {f?.subcategories_count}
                        </div>
                      </div>
                      <div>
                        {/* <p className={classes.cardCategory}>Used Space</p> */}
                        {/* <h3 className={classes && classes?.cardTitle}>
                                                <small>Count: </small>{f?.subcategories_count}
                                            </h3>
                                            <h3 className={classes && classes?.cardTitle}>
                                                <small>Document Check:  </small>{f?.documents_check? "true" : "false"}
                                            </h3> */}
                      </div>
                    </div>
                  </CardHeader>
                  <CardFooter
                    stats
                    onClick={() => {
                      props.push(route);
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className={classes && classes?.stats + " w-100"}>
                      <div className="w-100 d-flex flex-row justify-content-between align-items-center">
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: "#060d5c" }}
                        >
                          {/* {props.heading} */}
                          {truncateString(f?.subcategory, 8)}
                        </Typography>
                        {f?.documents_check ? (
                          <img src={file} width="20" />
                        ) : (
                          ""
                        )}
                      </div>
                      <Typography variant="p" component="p">
                        {/* {props.paragraph} */}
                        {moment(
                          moment(f?.last_modified, "YYYY-MM-DD").toDate()
                        ).format("MMMM DD, YYYY")}
                      </Typography>
                    </div>
                  </CardFooter>
                </Card>
              </div>
            );
          })
      ) : loading ? (
        <Grid item xs={12} md={3}>
          <CircularProgress size={20} color="#DDD" />
        </Grid>
      ) : (
        <Grid item xs={12} md={3}>
          <p>No Favorites yet</p>
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    sort: state.sort?.sort,
    order: state?.sort?.order,
    profile: state?.profile,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sortfn: (str, ord) => dispatch(sort(str, ord)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Favorite);
