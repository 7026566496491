import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CustomButton } from "../../components/CustomButton/Button";
import exiellogo from "../../assets/img/exiel-logo.png";
import NewLogo from "./../../assets/img/tag-logo-horizontal.png";
import { FormInput } from "../../components/FormInput/FormInput";
import { useParams } from "react-router-dom";
import { resetPassword } from "../../store/actions/authAction";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";

const ResetPassword = (props) => {
  const [err, setErr] = React.useState(null);
  const [state, setState] = React.useState({
    password_confirmation: "",
    password: "",
  });
  const [type, setType] = React.useState({
    pwd: "password",
    pwdC: "password",
  });
  const [headers, setHeader] = React.useState(null);

  const params = useParams();

  React.useEffect(() => {
    const url = new URL(window.location.href);
    console.log(url.searchParams.get("uid"));
    setHeader({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "access-token": url.searchParams.get("access-token"),
      client: url.searchParams.get("client"),
      uid: url.searchParams.get("uid"),
    });
    setState({ ...state, reset_password_token: params?.token });
  }, []);
  const handleChange = (event) => {
    if (event.target.name === "checkedA") {
      setState({ ...state, [event.target.name]: event.target.checked });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  };

  const handleSubmit = () => {
    let regex = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/;
    if (!regex.test(state.password)) {
      setErr(
        "Must contain 1 uppercase, 1 lowercase and 1 special character and should atleast be 8 characters long"
      );
    } else {
      if (state.password !== state.password_confirmation) {
        setErr("Entered passwords did not match, Try again");
      } else {
        const data = {
          password: state.password,
          password_confirmation: state.password_confirmation,
        };
        props?.resetPassword(data, props?.history, headers);
      }
    }
  };
  const useStyles = makeStyles((theme) => ({}));
  const classes = useStyles();
  return (
    <>
      {/* {console.log({ props })} */}
      <Box mb={5} textAlign="center">
        <img
          src={NewLogo}
          alt="s"
          width={120}
          style={{ marginBottom: "15px" }}
        />
        <Typography variant="h5" component="h3" align="center">
          {"Reset Password"}
        </Typography>
      </Box>
      {props?.authError ? (
        <Box mb={3}>
          <Typography variant="p" component="p" color="error">
            {props?.authError}
          </Typography>
        </Box>
      ) : null}
      {err ? (
        <Box mb={3}>
          <Typography variant="p" component="p" color="error">
            {err}
          </Typography>
        </Box>
      ) : null}
      <Box mb={3}>
        <FormInput
          id="password"
          name="password"
          label="Password"
          Icon={type.pwd === "text" ? VisibilityIcon : VisibilityOffIcon}
          iconClick={() =>
            setType({
              ...type,
              pwd: type.pwd === "password" ? "text" : "password",
            })
          }
          type={type.pwd}
          variant="outlined"
          fullWidth={true}
          margin="dense"
          required={true}
          onChange={handleChange}
        />
      </Box>
      <Box mb={3}>
        <FormInput
          id="password_confirmation"
          name="password_confirmation"
          label="Confirm Password"
          Icon={type.pwdC === "text" ? VisibilityIcon : VisibilityOffIcon}
          iconClick={() =>
            setType({
              ...type,
              pwdC: type.pwdC === "password" ? "text" : "password",
            })
          }
          type={type.pwdC}
          variant="outlined"
          fullWidth={true}
          margin="dense"
          required={true}
          onChange={handleChange}
        />
      </Box>
      {props?.loading ? (
        <CustomButton
          type="submit"
          fullWidth={true}
          btnText={<CircularProgress size={20} color="#DDD" />}
          textAlign="center"
          display={"block"}
          marginBottom="18px"
          padding="16px 17px"
          background="#0042a8"
          hoverBg="#0042a8"
          border="0"
          borderRadius="8px"
          color="#fff"
        />
      ) : (
        <CustomButton
          type="submit"
          fullWidth={true}
          btnText="Reset"
          textAlign="center"
          display={"block"}
          marginBottom="18px"
          padding="16px 17px"
          background="#0042a8"
          hoverBg="#0042a8"
          border="0"
          borderRadius="8px"
          color="#fff"
          disabled={!state.password || !state.password_confirmation}
          onClick={handleSubmit}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (data, props, headers) =>
      dispatch(resetPassword(data, props, headers)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state?.auth?.loading,
    authError: state?.auth?.error,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
