// import bg from '../../../../assets/img/a'
import background from "../../../img/authbg11.png";
const authScreenWrapper = (theme) => ({
    logo: {
        marginTop: "50px",
        marginLeft: "50px",
      
    },
    AuthScrBg: {
        height: '100%',
        // background: `url(${background})`,
        backgroundRepeat: "no-repeat",
        // backgroundSize: "cover",
        backgroundPosition: "top center",
        backgroundColor: "#fff",
        [theme.breakpoints.down("md")]: {
            height: '100%',
          },
          [theme.breakpoints.down("sm")]: {
            background: "#fff",
            height: 'auto',
          },
          [theme.breakpoints.down("xs")]: {
            paddingLeft: "25px",
            paddingRight: "25px",
            height: 'auto',
          },
    },
});

export default authScreenWrapper;
