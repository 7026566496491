import React from "react";
import { InputLabel, TextField } from "@material-ui/core";
export const FormInput = React.memo((props) => {
  return (
    <>
      <InputLabel>{props.label}</InputLabel>
      <div className="position-relative ">
        <TextField
          onClick={props?.onClick}
          autoFocus={props?.autoFocus}
          id={props.id}
          value={props?.hard}
          onContextMenu={(e) => e.stopPropagation()}
          onWheelCapture={(e) => e.target.blur()}
          key={props?.key}
          defaultValue={props?.value}
          format={props?.inputFormat}
          label={props.lable}
          variant={props.variant}
          fullWidth={props.fullWidth}
          InputProps={{
            autoComplete: "new-password",
            "aria-autocomplete": "none",
          }}
          autoComplete={"new-password"}
          margin={props.margin}
          aria-autocomplete={props?.auto}
          required={props.required}
          disabled={props?.disabled}
          name={props.name}
          onChange={props?.onChange}
          type={props.type}
          // autoFill={props.autoFill}
          inputProps={props?.inputProps}
          className={props.fieldClassName + " " + props.placeholderColor}
          placeholder={props.placeholder}
          onKeyDown={props.onKeyDown}
          ref={props.ref}
        />
        <input
          type="text"
          style={{ display: "none" }}
          autoComplete="new-passsword"
        />
        {props.Icon && (
          <props.Icon
            onClick={props.iconClick}
            className="position-absolute top-50 end-0 translate-middle-y me-3"
            fontSize="small"
          />
        )}
      </div>
    </>
  );
});
