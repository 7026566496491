import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { PersonalService } from "../../../services/personal.service";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs";

const useStyles = makeStyles(styles);

function AddSection(props) {
  const personalService = new PersonalService();
  const [name, setName] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [err, setErr] = React.useState(null);
  const classes = useStyles();

  const handleSubmit = async () => {
    setErr(null);
    setIsLoading(true);
    try {
      let response = await personalService.sectionsCreate({ name });
      setIsLoading(false);
      props?.fetchData();
      props?.handleClose();
    } catch (e) {
      setIsLoading(false);
      setErr(e.response ? e?.response?.data?.message : "something went wrong");
    }
  };

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Add Folder"}
          color={color.white}
        />
        <div className={classes.modalBody}>
          {err && (
            <div className="w-100 d-flex flex-row mb-5">
              <Typography
                variant="p"
                component="p"
                align="left"
                style={{ color: "red", fontWeight: "normal" }}
              >
                {err}
              </Typography>
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Folder Name *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <FormInput
                inputProps={{
                  autoComplete: "new-password",
                  placeholder: "Name",
                  onChange: (e) => setName(e.target.value),
                }}
                // onChange={(e) => setPassword(e.target.value)}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Create"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  disabled={!name}
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  onClick={handleSubmit}
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

export default AddSection;
