import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
// @material-ui/core components
// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
// core components
import { useRouteName } from "../../../hooks";
import GridItem from "../../../components/Grid/GridItem.js";
import { Grid, Typography } from "@material-ui/core";
import color from "../../../assets/css/color.js";
// @material-ui/icons
import VisaInformations from "../../../containers/Modals/VisaInformation/VisaInformation";
import LinkedItems from "../../../containers/Modals/LinkedItems/LinkedItems.js";
import Collaborators from "../../../containers/Modals/Collaborators/Collaborators";
import W2Form from "../../../containers/Modals/W2Form/W2Form";
import AddVisa from "../../../containers/Modals/AddVisa/AddVisa";
import { CustomButton } from "../../../components/CustomButton/Button";
import AddContactInfo from "../../../containers/Modals/AddContactInfo/AddContactInfo";
import CircularProgress from "@material-ui/core/CircularProgress";
import { store } from "../../../store/store";
import CardCategory from "../../../components/Card/CardCategory";
import EditVisa from "../../../containers/Modals/EditVisa/EditVisa";
import { PersonalService } from "../../../services/personal.service";
import { connect } from "react-redux";
import { HistoryService } from "../../../services/history.service";

function Visas({ sortType, order }) {
  const childRef = useRef();
  const personalService = new PersonalService();
  const historyService = new HistoryService();
  const [ids, setIds] = React.useState([]);
  const [multiple, setMultiple] = React.useState(false);
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [preference, setPreference] = useState(null);
  const [visas, setVisas] = React.useState([]);
  const [err, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [info, setInfo] = React.useState(null);
  const [currId, setCurrId] = React.useState(null);
  const [currVisa, setCurrVisa] = React.useState(null);
  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  React.useEffect(() => {
    setLoading(true);
    personalService
      .visasIndex()
      .then((response) => {
        console.log({ response });
        setLoading(false);
        setVisas(response?.data?.visas);
        if (
          id &&
          response?.data?.visas?.length > 0 &&
          response?.data?.visas?.filter((f) => f?.id === +id).length > 0
        ) {
          setInfo(response?.data?.visas?.filter((f) => f?.id === +id));
          setOpen(true);
        }
      })
      .then(() => {
        return historyService.alertsIndex();
      })
      .then((response) => {
        if (response.data && response.data?.length > 0) {
          let temp = response.data?.filter((p, key) => p.category === "Visa");
          setPreference(temp[0]);
        }
      })
      .catch((err) => {
        // console.log({ err });
        setLoading(false);
        setError("error occurred");
      });
  }, [store.getState().loading.render]);

  const handleMenuItemClick = (event) => {
    // setSelectedIndex(index); +
    setOpenDropdown(false);
  };

  const handleToggle = () => {
    setOpenDropdown((prevopenDropdown) => !prevopenDropdown);
  };

  const handleOpenDropDown = (id) => {
    setCurrId(id);
    handleToggle();
  };
  const handleCloseDropdown = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenDropdown(false);
  };

  const routeName = useRouteName();

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openAddNew, setOpenAddNew] = React.useState(false);
  const [openLinkedItems, setOpenLinkedItems] = React.useState(false);
  const [openCollaborators, setOpenCollaborators] = React.useState(false);
  const [openW2Form, setOpenW2Form] = React.useState(false);
  const [openAddContactInfo, setOpenAddContactInfo] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickInfo = (id) => {
    const visa = visas?.filter((aw) => aw.id === id);
    setInfo(visa);
    handleOpen();
  };

  const handleOpenAddNew = () => {
    setOpenAddNew(true);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
  };

  const handleOpenEdit = (visa) => {
    setEdit(true);
    setOpenEdit(true);
    setCurrVisa(visa);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
  };

  const handleOpenLinkedItems = () => {
    setOpenLinkedItems(true);
    setOpen(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
    setOpenAddNew(false);
  };

  const handleOpenCollaborators = () => {
    setOpenCollaborators(true);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenW2Form(false);
    setOpenAddNew(false);
  };

  const handleOpenW2Form = () => {
    setOpenW2Form(true);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenAddNew(false);
  };

  const handleOpenAddContactInfo = () => {
    setOpenAddContactInfo(true);
    setOpenW2Form(false);
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenAddNew(false);
  };

  const handleCloseLinkedItems = () => {
    setOpenLinkedItems(false);
    setOpen(true);
  };

  const handleCloseCollaborators = () => {
    setOpenCollaborators(false);
  };

  const handleCloseW2Form = () => {
    setOpenW2Form(false);
  };

  const handleCloseAddNew = () => {
    setOpenAddNew(false);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
    setEdit(false);
  };

  const handleCloseAddContactInfo = () => {
    setOpenAddContactInfo(false);
  };

  const closeModal = () => {
    setOpen(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
    setOpenAddNew(false);
    setOpenAddContactInfo(false);
  };

  const closeEditModal = () => {
    setOpenEdit(false);
    setEdit(false);
    setOpenLinkedItems(false);
    setOpenCollaborators(false);
    setOpenW2Form(false);
    setOpenAddNew(false);
    setOpenAddContactInfo(false);
  };

  const handleDelete = (id) => {
    personalService.visaDestroy(id).then(() => {
      const remaining = visas.filter((aw) => aw.id !== id);
      setVisas(remaining);
    });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Grid
        container
        wrap="wrap"
        direction={isMobile ? 'column' : 'row'}
        justifyContent=""
        alignItems={isMobile ? 'flex-start' : 'center'}
        style={{ margin: "25px 0" }}
      >
        <Typography
          variant="h6"
          component="h3"
          style={{ color: color.themeColorNew }}
        >
          Visa
        </Typography>
        <CustomButton
          type="submit"
          btnText="Add New"
          textAlign="center"
          display={"block"}
          marginLeft="4rem"
          padding="8px 17px"
          background="#0042a8"
          hoverBg="#0042a8"
          border="0"
          borderRadius="8px"
          color="#fff"
          onClick={() => handleOpenAddNew()}
        />
        <Grid item xs={6} md={6}></Grid>
        <Grid item xs={6} md={6} style={{ textAlign: "right" }}></Grid>
      </Grid>
      <Grid container>
        <GridItem xs={12} sm={12} md={12}>
          {openAddNew && (
            <AddVisa
              preference={preference}
              open={openAddNew}
              handleClose={handleCloseAddNew}
              handleOpenAddContactInfo={handleOpenAddContactInfo}
              closeModal={closeModal}
            />
          )}
          {edit && (
            <EditVisa
              preference={preference}
              open={openEdit}
              handleClose={handleCloseEdit}
              handleOpenAddContactInfo={handleOpenAddContactInfo}
              closeModal={closeEditModal}
              visa={currVisa}
            />
          )}
          <AddContactInfo
            open={openAddContactInfo}
            handleCloseAddContactInfo={handleCloseAddContactInfo}
            closeModal={closeModal}
          />
          {open && (
            <VisaInformations
              open={open}
              handleClose={handleClose}
              handleOpenEdit={handleOpenEdit}
              visa={info}
              handleOpenLinkedItems={handleOpenLinkedItems}
              handleOpenCollaborators={handleOpenCollaborators}
              closeModal={closeModal}
            />
          )}
          <LinkedItems
            attachment={info}
            open={openLinkedItems}
            handleCloseLinkedItems={handleCloseLinkedItems}
            closeModal={closeModal}
            heading={"Visa"}
            category={"Personal"}
          />
          <Collaborators
            open={openCollaborators}
            handleCloseCollaborators={handleCloseCollaborators}
            closeModal={closeModal}
          />
          <W2Form
            open={openW2Form}
            handleCloseW2Form={handleCloseW2Form}
            handleOpenLinkedItems={handleOpenLinkedItems}
            handleOpenCollaborators={handleOpenCollaborators}
            closeModal={closeModal}
          />
          {visas?.length > 0 && (
            <button
              style={{
                borderRadius: "16px",
                border: "none",
                backgroundColor: "transparent",
                color: "#0042a8",
                fontSize: "14px",
                fontWeight: "500",
                margin: "4px",
              }}
              onClick={() => {
                setMultiple(true);
                let array = [];
                visas?.forEach((e) => array.push(e.id));
                setIds(array);
              }}
            >
              Select All
            </button>
          )}
          {multiple && (
            <>
              <button
                style={{
                  borderRadius: "16px",
                  border: "none",
                  backgroundColor: "transparent",
                  color: "#0042a8",
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: "4px",
                }}
                onClick={() => {
                  setMultiple(false);
                  setIds([]);
                }}
              >
                Unselect All
              </button>
              <button
                style={{
                  borderRadius: "16px",
                  border: "none",
                  backgroundColor: "transparent",
                  color: "#0042a8",
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: "4px",
                }}
                onClick={() => {
                  childRef.current();
                }}
              >
                Share Selected
              </button>
              <button
                style={{
                  borderRadius: "16px",
                  border: "none",
                  backgroundColor: "transparent",
                  color: "#0042a8",
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: "4px",
                }}
                onClick={() => {
                  personalService.visaDestroyAll(ids).then(() => {
                    const remaining = visas.filter(
                      (aw) => !ids.includes(aw.id)
                    );
                    setVisas(remaining);
                    setIds([]);
                    setMultiple(false);
                  });
                }}
              >
                Delete Selected
              </button>
              <button
                style={{
                  borderRadius: "16px",
                  border: "none",
                  backgroundColor: "transparent",
                  color: "#0042a8",
                  fontSize: "14px",
                  fontWeight: "500",
                  margin: "4px",
                }}
                onClick={() => {
                  setMultiple(false);
                  setIds([]);
                }}
              >
                Cancel
              </button>
            </>
          )}
        </GridItem>
        {visas?.length > 0 && !loading && !err ? (
          <>
            {visas
              ?.sort((a, b) => {
                if (sortType === "alpha") {
                  if (order === "asc") {
                    return a.visa_category?.localeCompare(b.visa_category);
                  } else {
                    return b.visa_category?.localeCompare(a.visa_category);
                  }
                } else if (sortType === "date") {
                  if (order === "asc") {
                    return a.created_at?.localeCompare(b.created_at);
                  } else {
                    return b.created_at?.localeCompare(a.created_at);
                  }
                }
              })
              .map((dl, index) => {
                return (
                  <CardCategory
                    handleClickInfo={() => handleClickInfo(dl?.id)}
                    // options={options}
                    childRef={childRef}
                    handleOpenAddNew={handleOpenAddNew}
                    handleOpenEdit={handleOpenEdit}
                    openDropdown={openDropdown}
                    handleToggle={() => handleOpenDropDown(dl?.id)}
                    anchorRef={anchorRef}
                    handleCloseDropdown={handleCloseDropdown}
                    data={dl}
                    currId={currId}
                    selectedIndex={selectedIndex}
                    subcategory={"Visa"}
                    key={index}
                    handleDelete={handleDelete}
                    handleMenuItemClick={handleMenuItemClick}
                    heading={dl.visa_category ? dl.visa_category : "-"}
                    heading2={dl.country ? dl.country : "-"}
                    heading3={
                      dl.visa_category
                        ? dl.visa_category +
                          ` ${
                            new Date(dl.expiration_date).getTime() <
                            new Date().getTime()
                              ? "(Inactive)"
                              : ""
                          }`
                        : "-"
                    }
                    multiple={multiple}
                    setMultiple={setMultiple}
                    setIds={setIds}
                    ids={ids}
                  />
                );
              })}
          </>
        ) : loading ? (
          <CircularProgress size={40} color="secondary" />
        ) : err ? (
          "error occurred"
        ) : !loading && !err ? (
          "No Visas Yet"
        ) : null}
      </Grid>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    sortType: state?.sort?.sort,
    order: state?.sort?.order,
  };
};

export default connect(mapStateToProps)(Visas);
