import React, { useCallback, useEffect, useState } from "react";
import dashboardRoutes from "../../../routes";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { connect } from "react-redux";
import Folder from "../../../assets/img/Folder.svg";
import file from "../../../assets/img/file.png";
import CardFooter from "../../Card/CardFooter";
import { Grid, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { HistoryService } from "../../../services/history.service";
import { truncateString } from "../../../Utils/truncateString";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import moment from "moment/moment";
import { sort } from "../../../store/actions/sortAction";
import color from "../../../assets/css/color";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  indicator: {
    background: "none",
  },

  tabsMain: {
    marginBottom: "50px",
    "& .MuiTabs-flexContainer": {
      overflow: "auto",
    },
    "& ::-webkit-scrollbar": {
      height: "0",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: "#0042a8",
      color: "white",
    },
    "& Button": {
      background: "#fff",
      borderRadius: "25em",
      marginRight: "15px",
      backgroundColor: "#fff",
      minWidth: "160px",
      maxWidth: "250px",
      width: "max-content",
      color: "black",
      opacity: 1,
      "&:hover, &:active, &:focus": {
        backgroundColor: "#0042a8",
        color: "white",
        opacity: 1,
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
}));

const ActiveAlerts = (props) => {
  const historyService = new HistoryService();

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [fav, setFav] = useState([]);

  const fetchActiveAlerts = useCallback(() => {
    if (props?.profile?.profileInfo?.profile) {
      setLoading(true);
      historyService
        .activeAlertsIndex()
        .then((res) => {
          setLoading(false);
          setFav(res?.data?.alertable_records);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, []);
  useEffect(() => {
    fetchActiveAlerts();
  }, [fetchActiveAlerts]);
  return (
    <Grid container>
      {fav?.length > 0 ? (
        fav
          ?.sort((a, b) => {
            if (props?.sort === "alpha") {
                const propA = a.category?.toLowerCase();
                const propB = b.category?.toLowerCase();
                return props?.order === "asc" ? propA.localeCompare(propB) : propB.localeCompare(propA);
            } else {
                const propA = a.updated_at;
                const propB = b.updated_at;
                return props?.order === "asc" ? propA.localeCompare(propB) : propB.localeCompare(propA);
            }
        })
          .map((f) => {
            return (
              <div className="fav_width">
                <Card
                  animation={true}
                  extracslass="mb-3"
                  data-tooltip={f?.subcategory}
                  data-tooltip-location="top"
                >
                  <CardHeader color="warning" stats icon>
                    <div
                      className={
                        "position-relative d-flex mb-3 justify-content-between"
                      }
                    >
                      <div>
                        <NotificationsActiveIcon
                          style={{ color: "#F50057", margin: "0" }}
                          className={"bell"}
                        />
                      </div>
                      <div className="position-relative"></div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div></div>
                    </div>
                  </CardHeader>
                  <CardFooter
                    stats
                    onClick={() => {
                      props.push(f.path);
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className={classes && classes?.stats + " w-100"}>
                      <div className="w-100 d-flex flex-row justify-content-between align-items-center">
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{ color: "#060d5c" }}
                        >
                          {truncateString(f?.category, 8)}
                        </Typography>
                        {f?.documents_check ? (
                          <img src={file} width="20" />
                        ) : (
                          ""
                        )}
                      </div>
                      <Typography variant="p" component="p">
                        {moment(
                          moment(f?.updated_at, "YYYY-MM-DD").toDate()
                        ).format("MMMM DD, YYYY")}
                      </Typography>
                    </div>
                  </CardFooter>
                </Card>
              </div>
            );
          })
      ) : loading ? (
        <Grid item xs={12} md={3}>
          <CircularProgress size={20} color="#DDD" />
        </Grid>
      ) : (
        <Grid item xs={12} md={3}>
          <p>No records with active alerts yet</p>
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    sort: state.sort?.sort,
    order: state?.sort?.order,
    profile: state?.profile,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sortfn: (str, ord) => dispatch(sort(str, ord)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveAlerts);
