import React, {useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import {DocumentService} from "../../../services/document.service";
import {Grid} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "../../Card/Card";
import file from "../../../assets/img/file.png";
import config from "../../../config/default";
import FileViewer from "../../FileViewer/FileViewer";
import TermsModal from "../../../containers/Modals/TermsModal/TermsModal";
import {connect} from "react-redux";
import moment from "moment/moment";

const documentService = new DocumentService();

function ReceivedDocuments(props) {
    const [loading, setLoading] = useState(false);
    const [docs, setDocs] = useState([]);
    const [err, setErr] = useState(null);
    const [viewOpen, setViewOpen] = useState(false)
    const [expanded, setExpanded] = useState(null)
    const [openTerms, setOpenTerms] = useState(false)
    useEffect(() => {
        if (viewOpen === false) {
            setLoading(true);
            documentService
                .received()
                .then((res) => {
                    setDocs(res.data.received_documents ? res.data.received_documents : []);
                    setLoading(false);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    setErr(err.response ? err.response.data.error : "something went wrong");
                });
        }
    }, [viewOpen]);

    return (
        <Grid container spacing="5">
            {!loading && docs?.length > 0 ? (
                docs.sort((a, b) =>
                    props?.sortType === "alpha"
                        ? a.title?.localeCompare(
                            b.title
                        )
                        : b.created_at?.localeCompare(a.created_at)
                ).map((doc, key) => (
                    <div className="fav_width" style={{cursor: "pointer"}}
                        // item
                        // xs={12}
                        // md={3}
                        // key={key}

                    >
                        {viewOpen && (
                            <>
                                {expanded === key && (
                                    <FileViewer
                                        contentType={doc?.content_type}
                                        key={key}
                                        link={doc?.allow_download ? config.BASE_URL + doc.attachment : config.BASE_URL + doc.attachment + "#toolbar=0"}
                                        open={viewOpen}
                                        handleClose={() => setViewOpen(!viewOpen)}
                                    />
                                )}
                            </>
                        )}
                        {openTerms && (
                            <>
                                {expanded === key && (
                                    <TermsModal
                                        content_type={doc?.content_type}
                                        shared_by_name={doc?.shared_by_name}
                                        allow_download={doc?.allow_download}
                                        additional_terms={doc?.additional_terms}
                                        expiration_date={doc?.expiration_date}
                                        id={doc?.id}
                                        personal_message={doc?.personal_message}
                                        attachment={doc?.attachment}
                                        profile_id={doc?.profile_id}
                                        link={doc?.allow_download ? config.BASE_URL + doc.attachment : config.BASE_URL + doc.attachment + "#toolbar=0"}
                                        share_id={doc?.share_id}
                                        key={key}
                                        title={doc?.title}
                                        open={openTerms}
                                        setOpenFile={setViewOpen}
                                        handleClose={() => setOpenTerms(false)}
                                    />
                                )}
                            </>
                        )}
                        <Card className="p-4 m-0 h-auto mb-3"
                              data-tooltip={doc?.title}
                              data-tooltip-location="top"
                              onClick={() => {
                                  setExpanded(key)
                                  if (doc?.is_accepted) {
                                      documentService.receivedShow(doc?.id).then(() => {
                                          setViewOpen(true)
                                      }).catch(() => console.log("error"))
                                  } else {
                                      setOpenTerms(true)
                                  }
                              }
                              }>
                            <img src={file} width="30" className="mb-3"/>
                            <Typography variant="h6" component="h3" style={{color: "#060d5c"}}>
                                {doc.title && doc.title?.split(" ")[0]}
                            </Typography>
                            <Typography variant="p" component="p">
                                {moment(moment(doc?.created_at?.split("-")[2] + "-" + doc?.created_at?.split("-")[0] + "-" + doc?.created_at?.split("-")[1], "YYYY-MM-DD").toDate()).format('MMMM DD, YYYY')}
                            </Typography>
                            <Typography variant="p" component="p">
                                {`Shared by: ${
                                    doc?.shared_by_name
                                        ? doc?.shared_by_name
                                        : doc?.shared_by_email
                                }`}
                            </Typography>
                        </Card>
                    </div>
                ))
            ) : err ? (
                <Grid item xs={12} md={3}>
                    <p>{err}</p>
                </Grid>
            ) : !loading && docs.length === 0 ? (
                <Grid item xs={12} md={3}>
                    <p>No Documents received yet</p>
                </Grid>
            ) : (
                <Grid item xs={12} md={3}>
                    <CircularProgress size={20} color="#DDD"/>
                </Grid>
            )}
        </Grid>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
        sortType: state?.sort?.sort
    }
}

export default connect(mapStateToProps)(ReceivedDocuments)
