import { combineReducers } from "redux";
import authReducer from "./authReducer";
import profileReducer from "./profileReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import loadingReducer from "./loadingReducer";
import searchReducer from "./searchReducer";
import sortReducer from "./sortReducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "profile", "sort", "loading"],
  blacklist: [""],
};

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  loading: loadingReducer,
  search: searchReducer,
  sort: sortReducer,
});

export default persistReducer(persistConfig, rootReducer);
