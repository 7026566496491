import * as React from "react";
import { useEffect } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { makeStyles } from "@material-ui/core/styles";
import { CustomButton } from "../../components/CustomButton/Button";
import color from "../../assets/css/color";
import { FormInput } from "../../components/FormInput/FormInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { clearError, register } from "../../store/actions/authAction";
import { connect } from "react-redux";
import { Validation } from "../../services/validators";
import CircularProgress from "@material-ui/core/CircularProgress";

const steps = ["Basic Details", "Account Type"];

const validator = new Validation();
const RegisterPage = (props) => {
  const [message, setMessage] = React.useState(null);
  const ref = React.useRef(null);
  const [type, setType] = React.useState({
    pwd: "password",
    pwdC: "password",
  });
  const [state, setState] = React.useState({
    checkedA: false,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    passwordConfirmation: "",
    q1: "In what city were you born?",
    q2: "What was your childhood nickname?",
    a1: "",
    a2: "",
  });
  const goToTop = () => {
    ref.current.click();
  };
  const [error, setError] = React.useState({
    firstNameError: null,
    lastNameError: null,
    emailError: null,
    phoneError: null,
    passwordError: null,
    passwordConfirmationError: null,
    q1Error: null,
    q2Error: null,
    a1Error: null,
    a2Error: null,
    show: false,
    account_type: null,
  });
  const handleSubmit = () => {
    setMessage(null);
    if (
      !state.a2 ||
      !state.a1 ||
      !state.q1 ||
      !state.q2 ||
      !state.firstName ||
      !state.lastName ||
      !state.phone ||
      error.emailError ||
      error.passwordConfirmationError ||
      error.passwordError
    ) {
      handleValidate();
      // handleNext();
    } else {
      const data = {
        firstname: state.firstName,
        lastname: state.lastName,
        email: state.email,
        password: state.password,
        password_confirmation: state.passwordConfirmation,
        q1: state.q1,
        q2: state.q2,
        a1: state.a1,
        a2: state.a2,
        phone: state.phone,
        terms_and_conditions: state?.checkedA,
        time_zone: "Central Time (US & Canada)",
      };

      props.register(data, props.history, goToTop);
    }
  };
  const handleValidate = () => {
    const re = /^[0-9\b]+$/;
    setError({
      ...error,
      firstNameError: state.firstName.length > 0 ? null : "Can't be blank",
      lastNameError: state.lastName.length > 0 ? null : "Can't be blank",
      q1Error: state?.q1.length > 0 ? null : "Must select a question",
      q2Error: state?.q2.length > 0 ? null : "Must select a question",
      a1Error: state.a1.length > 0 ? null : "Can't be blank",
      a2Error: state.a2.length > 0 ? null : "Can't be blank",
      phoneError:
        state.phone.length > 0 && re.test(state.phone)
          ? null
          : state.phone?.length <= 0
          ? "Can't be blank"
          : "Must be numeric values only",
      account_type: state?.account_type?.length > 0 ? null : "Can't be blank",
    });
  };
  const handleChange = (event) => {
    if (!error.passwordError && state.password === state.passwordConfirmation) {
      setError({
        ...error,
        passwordError: null,
        passwordConfirmationError: null,
      });
    }
    if (event.target.name === "firstName") {
      setError({ ...error, firstNameError: null });
      setState({ ...state, [event.target.name]: event.target.value });
    }
    if (event.target.name === "lastName") {
      setError({ ...error, lastNameError: null });
      setState({ ...state, [event.target.name]: event.target.value });
    }
    if (event.target.name === "phone") {
      setError({ ...error, phoneError: null });
      setState({ ...state, [event.target.name]: event.target.value });
    }
    if (event.target.name === "q1") {
      setError({ ...error, q1Error: null });
      setState({ ...state, [event.target.name]: event.target.value });
    }
    if (event.target.name === "account_type") {
      setError({ ...error, account_type: null });
      setState({ ...state, [event.target.name]: event.target.value });
    }
    if (event.target.name === "q2") {
      setError({ ...error, q2Error: null });
      setState({ ...state, [event.target.name]: event.target.value });
    }
    if (event.target.name === "a1") {
      setError({ ...error, a1Error: null });
      setState({ ...state, [event.target.name]: event.target.value });
    }
    if (event.target.name === "a2") {
      setError({ ...error, a2Error: null });
      setState({ ...state, [event.target.name]: event.target.value });
    }
    if (event.target.name === "checkedA") {
      setState({
        ...state,
        [event.target.name]: event.target.checked ? true : false,
      });
    }
    if (event.target.name === "email") {
      setError({
        ...error,
        emailError: !validator.email(event.target.value)
          ? "Please enter a valid email address"
          : null,
      });
      setState({ ...state, email: event.target.value });
    }
    if (event.target.name === "password") {
      let regex = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/;
      setState({ ...state, password: event.target.value });
      setError({
        ...error,
        passwordError: regex.test(event.target.value)
          ? null
          : "Must contain 1 uppercase, 1 lowercase and 1 special character and should atleast be 8 characters long",
      });
    }
    if (event.target.name === "passwordConfirmation") {
      setError({
        ...error,
        passwordConfirmationError:
          event.target.value !== state.password
            ? "Passwords do not match"
            : null,
      });
      setState({ ...state, passwordConfirmation: event.target.value });
    }
  };
  const useStyles = makeStyles((theme) => ({}));
  const classes = useStyles();
  useEffect(() => {
    setMessage(localStorage.getItem("message") || null);
    return () => {
      localStorage.removeItem("message");
      props.resetErrorState(false);
    };
  }, []);
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // const Features = {
  //   color: "#27C8DC",
  // };

  return (
    <Grid
      container
      wrap="wrap"
      id={"darkautofill"}
      direction="row"
      justifyContent="center"
      alignItems="center"
      className="h-100 bgWhiteMobile px-4 px-lg-0 pb-5 pb-lg-0"
    >
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            component="h3"
            align="left"
            style={{ marginRight: "30px", fontWeight: "bold" }}
            className="mb-3"
          >
            Sign Up
          </Typography>
        </Box>
        <Box mb={3}>
          {props?.authError ? (
            props?.authError?.map((e, k) => {
              return (
                <Typography variant="p" component="p" color="error">
                  {e}
                </Typography>
              );
            })
          ) : (
            <Typography id="error" variant="p" component="p" color="error">
              {props?.authError}
            </Typography>
          )}
        </Box>
        {message && (
          <Box mb={3}>
            <Typography variant="p" component="p" color="error">
              {message}
            </Typography>
          </Box>
        )}
        <Grid container wrap="wrap" direction="row" spacing={3}>
          <Grid item xs={12} md={6} className="position-relative mb-1">
            <FormInput
              id="first-name"
              label="First Name*"
              variant="outlined"
              fullWidth={true}
              margin="dense"
              required={true}
              name="firstName"
              autoComplete="name"
              onChange={handleChange}
            />
            <Typography
              color="error"
              // className="errorPositioning"
              component="p"
              variant="p"
            >
              {error.firstNameError ?? error.firstNameError}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className="position-relative mb-1">
            <FormInput
              id="last-name"
              label="Last Name*"
              variant="outlined"
              fullWidth={true}
              margin="dense"
              required={true}
              name="lastName"
              autoComplete="name"
              onChange={handleChange}
            />
            <Typography
              color="error"
              // className="errorPositioning"
              component="p"
              variant="p"
            >
              {error.lastNameError ?? error.lastNameError}
            </Typography>
          </Grid>
        </Grid>
        <Grid container wrap="wrap" direction="row" spacing={3}>
          <Grid item xs={12} md={6} className="position-relative mb-1">
            <FormInput
              id="email"
              label="Email*"
              variant="outlined"
              fullWidth={true}
              margin="dense"
              required={true}
              name="email"
              autoComplete="email"
              onChange={handleChange}
            />
            <Typography
              color="error"
              // className="errorPositioning"
              component="p"
              variant="p"
            >
              {error.emailError ?? error.emailError}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className="position-relative mb-1">
            <InputLabel style={{ marginBottom: "4px" }}>
              Phone Number*
            </InputLabel>
            {/* <TextField
                id="phone"
                name="phone"
                variant="outlined"
                fullWidth={true}
                margin="dense"
                type={"tel"}
                required={true}
                onChange={handleChange}
              /> */}
            <PhoneInput
              name="phone"
              country={"us"}
              className="w-100 phoneInput"
              onChange={(e) => setState({ ...state, phone: e })}
            />

            <Typography
              color="error"
              // className="errorPositioning"
              component="p"
              variant="p"
            >
              {error.phoneError ?? error.phoneError}
            </Typography>
          </Grid>
        </Grid>
        <Grid container wrap="wrap" direction="row" spacing={3}>
          <Grid item xs={12} md={6} className="position-relative mb-1">
            <FormInput
              id="password"
              name="password"
              label="Password*"
              Icon={type.pwd === "text" ? VisibilityIcon : VisibilityOffIcon}
              iconClick={() =>
                setType({
                  ...type,
                  pwd: type.pwd === "password" ? "text" : "password",
                })
              }
              type={type.pwd}
              variant="outlined"
              fullWidth={true}
              autoFill="new-password"
              margin="dense"
              required={true}
              onChange={handleChange}
            />
            <Typography
              color="error"
              // className="errorPositioning"
              component="p"
              variant="p"
            >
              {error.passwordError ?? error.passwordError}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className="position-relative mb-1">
            <FormInput
              id="confirm-password"
              label="Confirm Password*"
              name="passwordConfirmation"
              Icon={type.pwdC === "text" ? VisibilityIcon : VisibilityOffIcon}
              iconClick={() =>
                setType({
                  ...type,
                  pwdC: type.pwdC === "password" ? "text" : "password",
                })
              }
              variant="outlined"
              fullWidth={true}
              margin="dense"
              required={true}
              type={type.pwdC}
              onChange={handleChange}
            />
            <Typography
              color="error"
              // className="errorPositioning"
              component="p"
              variant="p"
            >
              {error.passwordConfirmationError ??
                error.passwordConfirmationError}
            </Typography>
          </Grid>
        </Grid>
        <Grid container wrap="wrap" direction="row" spacing={3}>
          <Grid item xs={12} md={6} className="position-relative mb-1">
            <InputLabel style={{ marginBottom: "5.5px", marginTop: "-4px" }}>
              {"Security Question #1*"}
            </InputLabel>
            <FormControl id="security-question" fullWidth={true}>
              <Select
                style={{ height: "45px" }}
                id="security-question-1"
                label="Security Question #1*"
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                defaultValue="Please select security question #1"
                value={state.q1}
                onChange={handleChange}
                name="q1"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="Please select security question #1" disabled>
                  Please select security question #1
                </MenuItem>
                <MenuItem value="In what city were you born?">
                  In what city were you born?
                </MenuItem>
                <MenuItem value="What is the name of your favorite pet?">
                  What is the name of your favorite pet?
                </MenuItem>
                <MenuItem value="What is your mother's maiden name?">
                  What is your mother's maiden name?
                </MenuItem>
                <MenuItem value="What high school did you attend?">
                  What high school did you attend?
                </MenuItem>
                <MenuItem value="What was the make of your first car?">
                  What was the make of your first car?
                </MenuItem>
                <MenuItem value="What is the name of your first school?">
                  What is the name of your first school?
                </MenuItem>
              </Select>
            </FormControl>
            <Typography
              color="error"
              // className="errorPositioning"
              component="p"
              variant="p"
            >
              {error.q1Error ?? error.q1Error}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className="position-relative mb-1">
            <FormInput
              id="answer-1"
              label="Answer #1*"
              variant="outlined"
              fullWidth={true}
              margin="dense"
              required={true}
              type="text"
              name="a1"
              onChange={handleChange}
            />
            <Typography
              color="error"
              // className="errorPositioning"
              component="p"
              variant="p"
            >
              {error.a1Error ?? error.a1Error}
            </Typography>
          </Grid>
        </Grid>
        <Grid container wrap="wrap" direction="row" spacing={3}>
          <Grid item xs={12} md={6} className="position-relative mb-1">
            <InputLabel style={{ marginBottom: "5.5px", marginTop: "-4px" }}>
              {"Security Question #2*"}
            </InputLabel>
            <FormControl id="security-question" fullWidth={true}>
              <Select
                style={{ height: "45px" }}
                id="security-question-2"
                label="Security Question #2"
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                defaultValue="Please select security question #2"
                value={state.q2}
                onChange={handleChange}
                name="q2"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="Please select security question #2" disabled>
                  Please select security question #2
                </MenuItem>
                <MenuItem value="What was your childhood nickname?">
                  What was your childhood nickname?
                </MenuItem>
                <MenuItem value="What is your oldest sibling's middle name?">
                  What is your oldest sibling's middle name?
                </MenuItem>
                <MenuItem value="What was the name of your first stuffed animal?">
                  What was the name of your first stuffed animal?
                </MenuItem>
                <MenuItem value="In what city or town was your first job?">
                  In what city or town was your first job?
                </MenuItem>
                <MenuItem value="In what city does your nearest sibling live?">
                  In what city does your nearest sibling live?
                </MenuItem>
                <MenuItem value="Where were you when you first heard about 9/11?">
                  Where were you when you first heard about 9/11?
                </MenuItem>
              </Select>
            </FormControl>
            <Typography
              color="error"
              // className="errorPositioning"
              component="p"
              variant="p"
            >
              {error.q2Error ?? error.q2Error}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className="position-relative mb-1">
            <FormInput
              id="answer-2"
              label="Answer #2*"
              variant="outlined"
              fullWidth={true}
              margin="dense"
              required={true}
              type="text"
              name="a2"
              onChange={handleChange}
            />
            <Typography
              color="error"
              // className="errorPositioning"
              component="p"
              variant="p"
            >
              {error.a2Error ?? error.a2Error}
            </Typography>
          </Grid>
        </Grid>

        <Box mb={2} className="mt-2">
          <Grid container direction="row" alignItems="center" mb>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.checkedA}
                  onChange={handleChange}
                  name="checkedA"
                  color="primary"
                />
              }
              label={"Yes, I agree to the"}
              style={{ marginRight: "4px" }}
            />
            <Typography
              variant="p"
              component="p"
              align="center"
              style={{ color: color.themeColorNew }}
            >
              {
                <a
                  href=""
                  style={{
                    color: color.themeColorNew,
                    fontWeight: "600",
                    textDecoration: "none",
                  }}
                >
                  {"Terms of Agreement"}
                </a>
              }
              {" and "}
              {
                <a
                  href=""
                  style={{
                    color: color.themeColorNew,
                    fontWeight: "600",
                    textDecoration: "none",
                  }}
                >
                  {"Privacy Policy"}
                </a>
              }
            </Typography>
          </Grid>
        </Box>
        {props?.loading ? (
          <CustomButton
            type="submit"
            fullWidth={false}
            btnText={<CircularProgress size={20} color="#DDD" />}
            marginBottom="18px"
            height="52px"
            padding="16px 120px"
            background="#0042a8"
            hoverBg="#0042a8"
            border="0"
            borderRadius="8px"
            color="#fff"
          />
        ) : (
          <>
            <a href="#darkautofill" ref={ref} style={{ display: "none" }}>
              s
            </a>
            <CustomButton
              type="submit"
              fullWidth={false}
              btnText="CONTINUE"
              marginBottom="18px"
              height="52px"
              padding="16px 120px"
              background="#0042a8"
              hoverBg="#0042a8"
              border="0"
              disabled={
                !state.firstName ||
                !state.lastName ||
                !state.email ||
                !state.password ||
                !state.checkedA ||
                !state.passwordConfirmation ||
                !state.phone ||
                !state.q1 ||
                !state.q2 ||
                !state.a1 ||
                !state.a2
              }
              borderRadius="8px"
              color="#fff"
              onClick={handleSubmit}
            />
          </>
        )}
        {/* </>
          ) : activeStep === steps.length - 1 ? (
            <>
              <Typography sx={{ mt: 2, mb: 1 }}>
                Step {activeStep + 1}
              </Typography> */}
        {/* <AddNewCard
                open={openAddNewCard}
                handleClose={handleCloseAddNewCard}
                handleOpenPaymentSuccessfull={handleOpenPaymentSuccessfull}
              />
              <PaymentSuccessfull
                open={openPaymentSuccessfull}
                handleClose={handleClosePaymentSuccessfull}
              /> */}
        {/* </>
          ) : null}
        </React.Fragment>
      )} */}
      </Box>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    register: (data, props, fn) => dispatch(register(data, props, fn)),
    resetErrorState: (msg) => dispatch(clearError(msg)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state?.auth?.loading,
    authError: state?.auth?.error,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
