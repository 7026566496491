import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, Grid, InputLabel, MenuItem } from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { FormInput } from "../../FormInput/FormInput";
import Select from "@material-ui/core/Select";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { dateFormat } from "../../../Utils/dateFormate.js";

const useStyles = makeStyles(styles);

const ProfileFields = (props) => {
  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2014-08-18T21:11:54")
  );

  const handleDateChange = (date) => {
    console.log({ date: date?.toLocaleString()?.slice(0, 10) });
    setSelectedDate(date);
  };
  const classes = useStyles();

  useEffect(() => {}, [props.profile]);

  return (
    <>
      {props?.profile?.profile_type === "person" && (
        <>
          <Grid item xs={12} md={6} id="security-question">
            <FormInput
              label="Prefix"
              variant="outlined"
              hard={props.profile.prefix === null ? "" : props.profile.prefix}
              fullWidth={true}
              margin="dense"
              required={true}
              name="prefix"
              fieldClassName={classes.profileInputs}
              // hard={props.profile.firstName ? props.profile.firstName : "first name"}
              onChange={props.handleChange}
            />
            {/* <Typography color="error" className="" component="p" variant="p"> */}
          </Grid>
          <Grid item xs={12} md={6} id="security-question">
            <FormInput
              id="first-name"
              label="First Name"
              variant="outlined"
              hard={
                props.profile.first_name === null
                  ? ""
                  : props.profile.first_name
              }
              fullWidth={true}
              margin="dense"
              required={true}
              name="first_name"
              autoComplete="name"
              fieldClassName={classes.profileInputs}
              // hard={props.profile.firstName ? props.profile.firstName : "first name"}
              onChange={props.handleChange}
            />
            {/* <Typography color="error" className="" component="p" variant="p"> */}
          </Grid>
          <Grid item xs={12} md={6} id="security-question">
            <FormInput
              hard={
                props.profile.last_name === null ? "" : props.profile.last_name
              }
              label="Last Name"
              variant="outlined"
              fullWidth={true}
              margin="dense"
              required={true}
              name="last_name"
              autoComplete="name"
              fieldClassName={classes.profileInputs}
              // hard={props.profile.lastName ? props.profile.lastName : "last name"}
              onChange={props.handleChange}
            />
            {/* <Typography color="error" className="" component="p" variant="p">
                      {error.lastNameError ?? error.lastNameError}
                    </Typography> */}
          </Grid>
          <Grid item xs={12} md={6} id="security-question">
            <FormInput
              hard={props.profile.suffix === null ? "" : props.profile.suffix}
              label="Suffix"
              variant="outlined"
              fullWidth={true}
              margin="dense"
              required={true}
              name="suffix"
              fieldClassName={classes.profileInputs}
              // hard={props.profile.lastName ? props.profile.lastName : "last name"}
              onChange={props.handleChange}
            />
            {/* <Typography color="error" className="" component="p" variant="p">
                      {error.lastNameError ?? error.lastNameError}
                    </Typography> */}
          </Grid>
          <Grid item xs={12} md={12} id="security-question">
            <InputLabel style={{ marginBottom: "5px", marginTop: "-4px" }}>
              {"Date of Birth"}
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justifyContent="space-around">
                <KeyboardDatePicker
                  fullWidth={true}
                  inputVariant="outlined"
                  format={dateFormat()}
                  id="date-picker-inline"
                  className={classes.profileInputs + " datepicker"}
                  value={props.profile?.date_of_birth || null}
                  onChange={(date) => {
                    if (date != "Invalid Date") {
                      props?.setDate(date);
                    }
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
            {/*<FormInput*/}
            {/*    hard={*/}
            {/*        props.profile?.date_of_birth?.split("-")[0] +*/}
            {/*        "-" +*/}
            {/*        props.profile?.date_of_birth?.split("-")[2] +*/}
            {/*        "-" +*/}
            {/*        props.profile?.date_of_birth?.split("-")[1]*/}

            {/*    }*/}
            {/*    label="Date of Birth"*/}
            {/*    variant="outlined"*/}
            {/*    fullWidth={true}*/}
            {/*    margin="dense"*/}
            {/*    required={true}*/}
            {/*    type={"date"}*/}
            {/*    name="date_of_birth"*/}
            {/*    fieldClassName={classes.profileInputs}*/}
            {/*    // hard={props.profile.email ? props.profile.email : "email"}*/}
            {/*    onChange={(e) => props.setDate(new Date(e.target.value).toISOString()*/}
            {/*        .slice(0, 10))}*/}
            {/*/>*/}
            {/* <Typography color="error" className="" component="p" variant="p">
                        {error.emailError ?? error.emailError}
                    </Typography> */}
          </Grid>
          <Grid item xs={12} md={12} id="security-question">
            <InputLabel style={{ marginBottom: "5px", marginTop: "-4px" }}>
              {"Gender"}
            </InputLabel>
            <FormControl className={classes.profileInputs} fullWidth={true}>
              <Select
                id="select-gender"
                label="Select Gender"
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={props.profile.gender}
                onChange={props.handleChange}
                displayEmpty
                name={"gender"}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>

            {/* <Typography color="error" className="" component="p" variant="p">
                        {error.phoneError ?? error.phoneError}
                    </Typography> */}
          </Grid>
        </>
      )}
      <Grid item xs={12} md={12} id="security-question">
        {props?.profile?.profile_type === "business" && (
          <Grid item xs={12} md={12} id="security-question" className={"mt-4"}>
            <FormInput
              label="Business Name"
              variant="outlined"
              hard={
                props.profile.business_name === null
                  ? ""
                  : props.profile.business_name
              }
              fullWidth={true}
              margin="dense"
              required={true}
              name="business_name"
              fieldClassName={classes.profileInputs}
              // hard={props.profile.firstName ? props.profile.firstName : "first name"}
              onChange={props.handleChange}
            />
            {/* <Typography color="error" className="" component="p" variant="p"> */}
          </Grid>
        )}

        {/* <Typography color="error" className="" component="p" variant="p">
                        {error.phoneError ?? error.phoneError}
                    </Typography> */}
      </Grid>
    </>
  );
};

export default ProfileFields;
