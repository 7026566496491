import "date-fns";
import React, { useRef } from "react";
import debounce from "lodash.debounce";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useTheme,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import { InsuranceService } from "../../../services/insurance.service.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import AddDocument from "../AddDocument/AddDocument";
import ChooseWebLogins from "../ChooseWebLogins/ChooseWebLogins.js";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { getDateIgnoreTimezone } from "../../../Utils/dateHelper.js";
import { upperCaseErrorKeys } from "../../../Utils/stringManipulation.js";
import { dateFormat } from "../../../Utils/dateFormate.js";
import AddNewContact from "../AddNewContact/AddNewContact.js";

const stateArray = [
  "Bi-Weekly",
  "Monthly",
  "Quarterly",
  "Semi-Annually",
  "Annually",
];

const timing = [
  { show: "1 week from expiration", value: "one_week" },
  { show: "2 weeks from expiration", value: "two_weeks" },
  { show: "1 month from expiration", value: "one_month" },
  { show: "3 months from expiration", value: "three_months" },
  { show: "6 months from expiration", value: "six_months" },
];

const frequancy = [
  { show: "Daily", value: "daily" },
  { show: "Every other Day", value: "every_other_day" },
  { show: "Weekly", value: "weekly" },
  { show: "Biweekly", value: "bi_weekly" },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles(styles);

function AddLifeInsurance(props) {
  const [addContact, setAddContact] = React.useState(false);
  const [contact, setContact] = React.useState(null);
  const classes = useStyles();
  const errRef = useRef(null);
  const insuranceService = new InsuranceService();
  const [err, setErr] = React.useState([]);
  const [doc, setDoc] = React.useState([]);
  const [reminder, setReminder] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [documentModal, setDocumentModal] = React.useState(false);
  const [webLogin, setWebLogin] = React.useState(null);
  const [webLoginModal, setWebLoginModal] = React.useState(false);

  const handleDocumentModal = () => {
    setDocumentModal(true);
  };
  const [data, setData] = React.useState({
    provider: "",
    policy_type: "",
    description: "",
    policy_number: "",
    face_value: "",
    insurance_status: true,
    cash_value: "",
    policy_loans: "",
    amount_pledged: "",
    premium: "",
    premium_frequency: "",
    alert_attributes: {
      timing: props?.preference?.timing,
      frequency: props?.preference?.frequency,
      status: "inactive",
      profile_id: props?.profile?.profileInfo?.profile.id,
    },
    start_date: null,
    expiration_date: null,
    notes: "",
    attachments: "",
    associated_logins_attributes: [
      {
        web_login_id: null,
      },
    ],
    associated_documents_attributes: [],
    insurance_contacts_attributes: [
      {
        contact_id: null,
      },
    ],
  });

  const handleSubmit = () => {
    let formData = new FormData();
    if (data.insurance_contacts_attributes[0].contact_id) {
      formData.append(
        "insurance_contacts_attributes[][contact_id]",
        JSON.stringify(data.insurance_contacts_attributes[0].contact_id)
      );
    }
    formData.append("provider", data.provider);
    formData.append("policy_type", data.policy_type);
    formData.append("description", data.description);
    formData.append("insurance_status", data.insurance_status);
    formData.append("policy_number", data.policy_number);
    formData.append("face_value", data.face_value);
    formData.append("cash_value", data.cash_value);
    formData.append("policy_loans", data.policy_loans);
    formData.append("amount_pledged", data.amount_pledged);
    formData.append("premium", data.premium);
    formData.append("premium_frequency", data.premium_frequency);
    if (data.associated_logins_attributes[0].web_login_id) {
      formData.append(
        "associated_logins_attributes[][web_login_id]",
        JSON.stringify(data.associated_logins_attributes[0].web_login_id)
      );
    }
    if (data.alert_attributes.status === "active" && data.expiration_date) {
      formData.append("alert_attributes[timing]", data.alert_attributes.timing);
      formData.append(
        "alert_attributes[frequency]",
        data.alert_attributes.frequency
      );
      formData.append("alert_attributes[status]", data.alert_attributes.status);
      formData.append(
        "alert_attributes[profile_id]",
        data.alert_attributes.profile_id
      );
    }
    data.start_date &&
      formData.append(
        "start_date",
        getDateIgnoreTimezone(data.start_date).toISOString().slice(0, 10)
      );
    data.expiration_date &&
      formData.append(
        "expiration_date",
        getDateIgnoreTimezone(data.expiration_date).toISOString().slice(0, 10)
      );
    formData.append("notes", data.notes);

    if (data.associated_documents_attributes?.length > 0) {
      for (let i = 0; i < data.associated_documents_attributes?.length; i++) {
        formData.append(
          "associated_documents_attributes[][document_id]",
          data.associated_documents_attributes[i]?.document_id ||
            data.associated_documents_attributes[i]?.id
        );
      }
    }
    setIsLoading(true);
    insuranceService
      .createLifeInsurance(formData)
      .then((response) => {
        setIsLoading(false);
        props.forceRerender();
        props.handleClose();

        console.log(response.data);
      })
      .catch((err) => {
        errRef.current.scrollIntoView();
        setIsLoading(false);
        if (err.response && err.response.data.message) {
          if (err.response?.data?.message === "Please enter a valid date") {
            setErr([err.response?.data?.message]);
          } else {
            let array = [];
            Object.keys(err.response.data.message).forEach((key) => {
              let temp =
                key === "alert.alert" ? "alert" : upperCaseErrorKeys(key);
              array.push(`${temp} ${err.response.data.message[key]}`);
            });
            setErr(array);
          }
        } else {
          setErr(["Internal Server Error"]);
        }
      });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = debounce((event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  }, 300);

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChangeCountry = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%"
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Add New Life Insurance"}
          color={color.white}
        />
        <div className={classes.modalBody} ref={errRef}>
          <div className="w-100 d-flex flex-row mb-4">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Insurance > Life Insurance > New"}
            </Typography>
          </div>
          {err?.length > 0 && (
            <div className="w-100 d-flex flex-row mb-4">
              {err?.map((val, key) => {
                return (
                  <Typography
                    key={key}
                    variant="p"
                    component="p"
                    align="left"
                    style={{
                      color: "red",
                      fontWeight: "normal",
                      marginBottom: "2px",
                    }}
                  >
                    {val.charAt(0).toUpperCase() + val.slice(1)}
                  </Typography>
                );
              })}
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            {addContact && (
              <AddNewContact
                data={data}
                setData={setData}
                contact={contact}
                setContact={setContact}
                open={addContact}
                handleClose={() => setAddContact(false)}
                closeModal={() => setAddContact(false)}
              />
            )}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Provider *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="provider"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Provider"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Policy Type"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="policy_type"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Policy Type"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Description"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="description"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Description"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Policy Number"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="policy_number"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Policy Number"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Insurance Status"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  name="insurance_status"
                  value={data.insurance_status ? "current" : "previous"}
                  onChange={(e) => {
                    setData({
                      ...data,
                      insurance_status:
                        e.target.value === "current" ? true : false,
                    });
                  }}
                  className="flex-row"
                >
                  <FormControlLabel
                    value={"current"}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="Current"
                  />
                  <FormControlLabel
                    value={"previous"}
                    control={<Radio style={{ color: "#ffb722" }} />}
                    label="previous"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Face Value"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="face_value"
                onChange={handleChange}
                label=""
                variant="outlined"
                type="number"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Face Value"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Cash Value"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="cash_value"
                onChange={handleChange}
                label=""
                variant="outlined"
                type="number"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Cash Value"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Policy Loans"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="policy_loans"
                onChange={handleChange}
                label=""
                variant="outlined"
                type="number"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Policy Loans"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Amount Pledged"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="amount_pledged"
                onChange={handleChange}
                label=""
                type="number"
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Amount Pledged"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Premium"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="premium"
                onChange={handleChange}
                label=""
                variant="outlined"
                type="number"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Premium"}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Premium Frequency"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  onChange={handleChange}
                  name="premium_frequency"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled>Select Premium Frequency</MenuItem>
                  {stateArray?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Start Date"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  inputVariant="outlined"
                  format={dateFormat()}
                  id="start_date"
                  placeholder={dateFormat()?.toUpperCase()}
                  value={data?.start_date}
                  onChange={(date) => {
                    setData({
                      ...data,
                      start_date: date,
                    });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"End Date"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  inputVariant="outlined"
                  format={dateFormat()}
                  id="start_date"
                  placeholder={dateFormat()?.toUpperCase()}
                  value={data?.expiration_date}
                  onChange={(date) => {
                    setData({
                      ...data,
                      expiration_date: date,
                    });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              {props?.preference?.is_active && (
                <>
                  {!reminder ? (
                    <CustomButton
                      btnText={"Add Reminder"}
                      disabled={!data?.expiration_date}
                      textAlign="center"
                      display={"block"}
                      background="transparent"
                      hoverBg="#0042a8"
                      border="1px solid #0042a8"
                      borderRadius="8px"
                      color="#0042a8"
                      onClick={() => {
                        setReminder(true);
                        setData({
                          ...data,
                          alert_attributes: {
                            ...(data.alert_attributes
                              ? {
                                  ...data.alert_attributes,
                                }
                              : {
                                  timing: "one_week",
                                  frequency: "daily",

                                  profile_id:
                                    props?.profile?.profileInfo?.profile.id,
                                }),
                            status: "active",
                          },
                        });
                      }}
                    />
                  ) : null}
                </>
              )}
            </Grid>
            {reminder && (
              <div className={"w-75 m-2 border m-5"}>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Start Date"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} sm={8}>
                  <FormControl id="security-question" fullWidth={true}>
                    <Select
                      id="id_type"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      required={true}
                      defaultValue={props?.preference?.timing}
                      onChange={(e) => {
                        setData({
                          ...data,
                          alert_attributes: {
                            ...data.alert_attributes,
                            timing: e.target.value,
                          },
                        });
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled>Select Timing</MenuItem>
                      {timing.map((t) => {
                        return <MenuItem value={t.value}>{t.show}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Frequency"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} sm={8}>
                  <FormControl id="security-question" fullWidth={true}>
                    <Select
                      id="id_type"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      required={true}
                      defaultValue={props?.preference?.frequency}
                      onChange={(e) => {
                        setData({
                          ...data,
                          alert_attributes: {
                            ...data.alert_attributes,
                            frequency: e.target.value,
                          },
                        });
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled>Select Frequency</MenuItem>
                      {frequancy.map((f) => {
                        return <MenuItem value={f.value}>{f.show}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <CustomButton
                    textAlign="center"
                    display={"block"}
                    padding="8px 25px"
                    margin={"5px"}
                    background="#0042a8"
                    hoverBg="#0042a8"
                    border="0"
                    borderRadius="8px"
                    color="#fff"
                    btnText={"Remove"}
                    onClick={() => {
                      setData({
                        ...data,
                        alert_attributes: {
                          ...data.alert_attributes,
                          status: "inactive",
                        },
                      });
                      setReminder(false);
                    }}
                  />
                </Grid>
              </div>
            )}

            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Notes"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="class"
                name="notes"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Notes"}
              />
              {documentModal && (
                <AddDocument
                  setData={setData}
                  setDoc={setDoc}
                  associated_documents_attributes={false}
                  doc={doc}
                  data={data}
                  open={documentModal}
                  handleClose={() => setDocumentModal(false)}
                  closeModal={() => setDocumentModal(false)}
                  category={"Insurance"}
                />
              )}
              {webLoginModal && (
                <ChooseWebLogins
                  setData={setData}
                  setWebLogin={setWebLogin}
                  data={data}
                  open={webLoginModal}
                  webLogin={data?.associated_logins_attributes[0]?.web_login_id}
                  handleClose={() => setWebLoginModal(false)}
                  closeModal={() => setWebLoginModal(false)}
                  category={"Personal"}
                />
              )}
            </Grid>
            {webLogin && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Web Login"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {webLogin?.user_name}
                  </Typography>
                  <CustomButton
                    btnText={"Remove"}
                    textAlign="center"
                    display={"block"}
                    background="transparent"
                    hoverBg="#0042a8"
                    border="1px solid #0042a8"
                    borderRadius="8px"
                    color="#0042a8"
                    onClick={() => {
                      setWebLogin(null);
                      setData({
                        ...data,
                        associated_logins_attributes: [
                          {
                            web_login_id: null,
                          },
                        ],
                      });
                    }}
                  />
                </Grid>
              </>
            )}
            {contact && (
              <div className={"w-75 m-2 border m-5 p-4"}>
                <Typography
                  variant="h6"
                  component="h3"
                  style={{
                    color: color.themeColorNew,
                    textAlign: "center",
                    padding: "1rem",
                  }}
                >
                  {"Agent"}
                </Typography>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{
                      color: color.themeColorNew,
                    }}
                  >
                    {"Name"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{
                      color: color.themeColorNew,
                    }}
                  >
                    {contact?.firstName + " " + contact?.lastName}
                  </Typography>
                </Grid>
                {contact?.contact_phone_numbers?.map((p, index) => {
                  return (
                    <>
                      <Grid item xs={12} sm={12} md={12} className="pb-0">
                        <Typography
                          variant="h6"
                          component="h3"
                          style={{
                            color: color.themeColorNew,
                          }}
                        >
                          {`Phone Number (${p.phone_type})`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} sm={12}>
                        <Typography
                          variant="p"
                          component="p"
                          style={{
                            color: color.themeColorNew,
                          }}
                        >
                          {p?.country_code + p?.phone_number}
                        </Typography>
                      </Grid>
                    </>
                  );
                })}
                <div style={{ textAlign: "center", margin: "1rem" }}>
                  <CustomButton
                    btnText={"Remove"}
                    textAlign="center"
                    display={"block"}
                    background="transparent"
                    hoverBg="#0042a8"
                    border="1px solid #0042a8"
                    borderRadius="8px"
                    color="#0042a8"
                    onClick={() => {
                      setContact(null);
                      setData({
                        ...data,
                        insurance_contacts_attributes: [
                          {
                            contact_id: null,
                          },
                        ],
                      });
                    }}
                  />
                </div>
              </div>
            )}
            {doc.length > 0 && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Document"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                  {doc.map((e, k) => (
                    <>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                        key={k}
                      >
                        {e?.title}
                      </Typography>
                      <CustomButton
                        btnText={"Remove"}
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          let newDoc = doc?.filter((d) => d.id !== e.id);
                          setDoc(newDoc);
                          setData({
                            ...data,
                            associated_documents_attributes: newDoc,
                          });
                        }}
                      />
                    </>
                  ))}
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              {!contact && (
                <CustomButton
                  btnText="Add Agent"
                  className="mb-sm-2"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  marginRight={"10px"}
                  onClick={() => setAddContact(true)}
                />
              )}
              {!webLogin && (
                <CustomButton
                  className="mb-sm-2"
                  btnText="Add Web Login"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  marginRight={"10px"}
                  onClick={() => setWebLoginModal(true)}
                />
              )}
              <CustomButton
                btnText="Add Document"
                className="mb-sm-2"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
                marginRight={"10px"}
                onClick={handleDocumentModal}
              />
              <a
                ref={errRef}
                href={"#err"}
                style={{
                  display: "none",
                }}
              >
                click
              </a>
              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  onClick={handleSubmit}
                  disabled={
                    data.provider === "" && !data.provider?.trim()
                      ? true
                      : false
                  }
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
    profile: state.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLifeInsurance);
