import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import _default from "../../../config/default.js";
import { Typography } from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { store } from "../../../store/store";
// import AvatarGroup from '@mui/material/AvatarGroup';
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import jpegImage from "../../../assets/img/jpegImage.png";
import pdfImage from "../../../assets/img/pdfImage.png";
import docsimage from "../../../assets/img/docsimage.png";
import pngimage from "../../../assets/img/pngimage.png";
import backMe from "../../../assets/img/back.png";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import { DocumentService } from "../../../services/document.service";
import PasswordVerification from "../PasswordVerification/PasswordVerification.js";
import FileViewer from "../../../components/FileViewer/FileViewer.js";
import SpinnerModal from "../Alerts/SpinnerModal/SpinnerModal.js";
import AlertPopup from "../Alerts/Generic/GenericAlert.js";
import config from "../../../config/default";
const documentService = new DocumentService();
const useStyles = makeStyles(styles);
export default function LinkedItems(props) {
  const [docs, setDocs] = React.useState([]);
  const [link, setLink] = React.useState(null);
  const [showDoc, setShowDoc] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [spinner, setSpinner] = React.useState(false);
  const [alert, setAlert] = React.useState({
    open: false,
    type: null,
    btnText: null,
    cancelText: null,
    heading: null,
    message: null,
  });
  const [password, setPassword] = useState(null);
  const [showPasswordVerification, setShowPasswordVerification] =
    useState(false);
  const classes = useStyles();
  const documentService = new DocumentService();
  React.useEffect(() => {
    props.attachment &&
      setDocs(
        props?.attachment[0]?.documents ? props?.attachment[0]?.documents : []
      );
    console.log({ props });
  }, [props.attachment]);
  const show = () => {
    setSpinner(true);
    documentService
      .show(id, password)
      .then((res) => {
        setSpinner(false);
        setLink({
          attachment: res.data.document.attachment,
          contentType: res.data.document.content_type,
        });
        setShowDoc(true);
        setShowPasswordVerification(false);
        setPassword(null);
      })
      .catch((err) => {
        setSpinner(false);
        setPassword(null);
        setAlert({
          type: "Error",
          open: true,
          cancelText: "CANCEL",
          heading:
            err?.response && err?.response?.status === 422
              ? "Unauthorized!"
              : "Error!",
          message:
            err?.response && err?.response?.status === 422
              ? "Wrong password! please try again."
              : "An unknown error occurred, please try later.",
        });
      });
  };
  React.useEffect(() => {
    if (link) {
      setShowDoc(true);
    }
  }, [link]);
  return (
    <>
      {alert.open && (
        <AlertPopup
          btnText={alert.btnText}
          cancelText={alert.cancelText}
          open={alert.open}
          type={alert.type}
          heading={alert.heading}
          message={alert.message}
          isLoading={false}
          handleClose={() => {
            setAlert({
              open: false,
              btnText: null,
              cancelText: null,
              heading: null,
              message: null,
              type: null,
            });
          }}
          handleClick={() => {
            setAlert({
              open: false,
              btnText: null,
              cancelText: null,
              heading: null,
              message: null,
              type: null,
            });
          }}
        />
      )}
      {spinner && <SpinnerModal open={spinner} msg={"Loading"} />}
      {showDoc && (
        <FileViewer
          contentType={link?.contentType}
          link={
            link?.attachment?.includes("http")
              ? link?.attachment
              : config.BASE_URL + link?.attachment
          }
          open={showDoc}
          handleClose={() => setShowDoc(false)}
        />
      )}
      {showPasswordVerification && (
        <PasswordVerification
          open={showPasswordVerification}
          handleClose={() => {
            setShowPasswordVerification(false);
            setId(null);
          }}
          handleChange={(e) => setPassword(e)}
          handleSubmit={() => {
            show();
          }}
        />
      )}
      <CustomModal
        visible={props.open}
        onClose={props?.handleCloseLinkedItems}
        width="35%"
        inset="15px!important"
      >
        <ModalHeader
          onClick={props.handleCloseLinkedItems}
          icon={
            <img
              onClick={props.handleCloseLinkedItems}
              style={{ cursor: "pointer" }}
              src={backMe}
              className="me-3"
            />
            // <Close
            //
            //     style={{
            //         color: color.white,
            //         marginRight: "20px",
            //         fontSize: "36px",
            //         cursor: "pointer",
            //     }}
            // />
          }
          heading={
            props?.heading ??
            store?.getState()?.profile?.profileInfo?.profile?.first_name +
              " " +
              store?.getState()?.profile?.profileInfo?.profile?.last_name
          }
          color={color.white}
        />
        <div className={classes.modalBody}>
          <div className="w-100 d-flex flex-row mb-4">
            <Typography
              variant="h6"
              component="h3"
              align="left"
              style={{ color: color.themeColorNew }}
            >
              {"Linked Files"}
            </Typography>
          </div>
          {docs?.length > 0 ? (
            docs?.map((doc) => {
              return (
                <div
                  className="cursor-pointer d-flex flex-row align-items-center mb-3"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={
                    () => {
                      if (doc?.attachment) {
                        setLink({
                          attachment: doc.attachment,
                          contentType: doc.content_type,
                        });
                      } else {
                        setId(doc?.id);
                        setShowPasswordVerification(true);
                      }
                    }
                    //   window.open(_default.BASE_URL + doc?.attachment, "_blank")
                  }
                >
                  {doc.extension === ".jpg" ? (
                    <img src={jpegImage} className="me-2" />
                  ) : doc.extension === ".pdf" ? (
                    <img src={pdfImage} className="me-2" />
                  ) : doc.extension === ".png" ? (
                    <img src={pngimage} className="me-2" />
                  ) : (
                    <img src={docsimage} className="me-2" />
                  )
                  }
                  <Typography
                    variant="h6"
                    component="h3"
                    align="left"
                    className="me-2"
                    style={{ color: color.themeYellow }}
                  >
                    {doc?.title}
                  </Typography>
                  <InsertLinkIcon
                    style={{
                      transform: "rotate(320deg)",
                      color: color.themeColorNew,
                    }}
                  />
                </div>
              );
            })
          ) : (
            <div
              className="cursor-pointer d-flex flex-row align-items-center mb-3"
              style={{
                cursor: "pointer",
              }}
            >
              <Typography
                variant="h6"
                component="h3"
                align="left"
                className="me-2"
                style={{ color: color.themeYellow }}
              >
                No linked items
              </Typography>
            </div>
          )}
        </div>
      </CustomModal>
      <input style={{ position: "absolute", top: -200 }} />
    </>
  );
}
