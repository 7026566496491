import "date-fns";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import { connect } from "react-redux";
import { DocumentService } from "../../../services/document.service.js";
import Folder from "./../../../assets/img/Folder.png";
import browseFile from "./../../../assets/img/browseFile.png";
import { useDropzone } from "react-dropzone";
import { Close } from "@material-ui/icons";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import AlertPopup from "../Alerts/Generic/GenericAlert";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(styles);

function AddAttachment(props) {
  const classes = useStyles();
  const history = useHistory();
  const healthService = new DocumentService();
  const [err, setErr] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const [docs, setDocs] = React.useState([]);
  const [file, setFile] = React.useState(null);
  const [docIsLoading, setDocIsLoading] = React.useState(false);

  const onDrop = React.useCallback((files) => {
    if (files && files.length) {
      const filename = files[0].name;

      var parts = filename.split(".");
      const fileType = parts[parts.length - 1];
      console.log("fileType", fileType); //ex: zip, rar, jpg, svg etc.

      props.setFile(files[0]);
      console.log({ filesDocs: files[0] });
      localStorage.setItem("payload", files[0]);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });
  React.useEffect(() => {
    healthService
      .index()
      .then((res) => {
        setDocs(res?.data?.documents);
        console.log({ res });
      })
      .catch((err) => {
        console.log({ err });
      });
  }, []);

  const handleSubmit = () => {
    const fd = new FormData();
    fd.append("attachment", file);
    fd.append("title", file.name);
    fd.append("category", props.category);
    if (!docs) {
      const payload = {
        title: file.name,
        category: props.category,
        attachment: file,
      };
      setDocIsLoading(true);
      healthService
        .add(fd)
        .then((response) => {
          setFile(null);
          props.setFile(null);
          setDocIsLoading(false);
        })
        .catch((err) => {
          setDocIsLoading(false);
        });
    } else {
      setDocIsLoading(true);
      healthService
        .add(fd)
        .then((response) => {
          setDocs([]);
          setDocIsLoading(false);
          props.handleClose();
        })
        .catch((err) => {
          setDocIsLoading(false);
        });
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
    console.log(event.target.value);
  };

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%"
        // margin="250px 0 40px"
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Add Document"}
          color={color.white}
        />
        <div className={classes.modalBody}>
          {err && (
            <div className="w-100 d-flex flex-row mb-5">
              <Typography
                variant="p"
                component="p"
                align="left"
                style={{ color: "red", fontWeight: "normal" }}
              >
                {err}
              </Typography>
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            {alert.open && (
              <AlertPopup
                btnText={alert.btnText}
                cancelText={alert.cancelText}
                open={alert.open}
                type={alert.type}
                heading={alert.heading}
                message={alert.message}
                handleClose={() => {
                  props?.handleClose();
                  setAlert({
                    open: false,
                    btnText: null,
                    cancelText: null,
                    heading: null,
                    message: null,
                    type: null,
                  });
                }}
                handleClick={() => {
                  if (alert.type === "Error") {
                    setAlert({
                      open: false,
                      btnText: null,
                      cancelText: null,
                      heading: null,
                      message: null,
                      type: null,
                    });
                  } else {
                    setAlert({
                      open: false,
                      btnText: null,
                      cancelText: null,
                      heading: null,
                      message: null,
                      type: null,
                    });
                    history.push("/user/all-documents");
                  }
                }}
              />
            )}
            <div
              {...getRootProps()}
              className="w-100 drag_and_upload p-5 position-relative"
            >
              <input {...getInputProps()} />
              {!props.file ? (
                <div className="w-75 mx-auto d-flex flex-column align-items-center px-sm-5 px-0 position-relative">
                  <div className="text-center">
                    <img src={Folder} width={40} />
                    <Typography
                      variant="h6"
                      component="h3"
                      style={{
                        color: color.themeColorNew,
                        textAlign: "center",
                      }}
                    >
                      {
                        "Drag your documents, photos, or videos here to start uploading"
                      }
                    </Typography>
                    <Typography
                      variant="h6"
                      component="h3"
                      style={{
                        color: color.themeYellow,
                        textAlign: "center",
                      }}
                    >
                      {"OR"}
                    </Typography>
                    {/* <img src={browseFile} height={50}/> */}
                    {/* <CustomButton
                      btnText="BROWSE FILES"
                      textAlign="center"
                      display={"block"}
                      padding="12px 27px"
                      background="#0042a8"
                      hoverBg="#0042a8"
                      border="0"
                      borderRadius="8px"
                      color="#fff"
                    /> */}
                  </div>
                  <div
                    style={{
                      padding: "1rem",
                      background: "#0042A8",
                      width: "10rem",
                      borderRadius: "8px",
                      justifyContent: "center",
                      color: "white",
                      display: "flex",
                      cursor: "pointer",
                    }}
                  >
                    Browse Files
                  </div>
                  {/*<FilesUpload setFile={props.setFile} />*/}
                </div>
              ) : (
                <>
                  <div className="d-flex flex-row align-items-center position-absolute top-50 start-50 translate-middle">
                    <Typography
                      variant="h6"
                      component="h3"
                      style={{ color: color.themeColorNew, marginRight: "8px" }}
                    >
                      {"Name: "}
                    </Typography>
                    <Typography
                      variant="p"
                      component="p"
                      style={{ color: color.themeColorNew }}
                    >
                      {props?.file?.name}
                    </Typography>
                  </div>
                </>
              )}
            </div>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center d-flex flex-row"
              style={{ textAlign: "right" }}
            >
              {props.file && (
                <>
                  <CustomButton
                    type="submit"
                    btnText="Clear"
                    textAlign="center"
                    display={"block"}
                    padding="8px 25px"
                    background="#0042a8"
                    autoFocus={true}
                    hoverBg="#0042a8"
                    border="0"
                    borderRadius="8px"
                    color="#fff"
                    marginRight="12px"
                    onClick={() => props.setFile(null)}
                  />
                  <CustomButton
                    type="submit"
                    btnText="Next"
                    textAlign="center"
                    display={"block"}
                    padding="8px 25px"
                    background="#0042a8"
                    autoFocus={true}
                    hoverBg="#0042a8"
                    border="0"
                    borderRadius="8px"
                    color="#fff"
                    marginRight="12px"
                    onClick={() => props.handleOpenDocumentCat()}
                  />
                </>
              )}
              {/* <CustomButton
                onClick={() => props.handleClose()}
                btnText="Cancel"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="transparent"
                hoverBg="#0042a8"
                border="1px solid #0042a8"
                borderRadius="8px"
                color="#0042a8"
              /> */}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAttachment);
