import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { connect } from "react-redux";
import styles from "../../../assets/jss/material-dashboard-react/components/ActivityHistoryAccordion.js";

const useStyles = makeStyles(styles);

function ActivityHistoryAccordion({ activities, sort, order }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  let array = [];
  let temp;
  const handleChange = (panel) => (event, isExpanded) => {
    array = [];
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    console.log({ activities });
  }, []);
  return (
    <div className={classes.root}>
      {activities &&
        activities?.map((activity, index) => {
          return (
            <Accordion
              className={classes.accordionMainBar}
              expanded={expanded === index}
              onChange={handleChange(index)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className={classes.accordionQuestion}
              >
                <Typography
                  className={classes.heading}
                  style={{
                    color:
                      activity?.action === "created"
                        ? "green"
                        : activity?.action === "deleted"
                        ? "red"
                        : activity?.action === "updated"
                        ? "blue"
                        : "purple",
                  }}
                >
                  <span style={{ color: "#ffb722", }}>
                    {activity?.actionable_type}
                  </span>{" "}
                  <span style={{ color: "black", fontWeight: "normal" }}>
                    was{" "}
                  </span>
                  {`${activity?.action}`}
                </Typography>
              </AccordionSummary>
              {activity?.actionable && (
                <AccordionDetails
                  className={classes.accordionAnswer + " flex-column"}
                >
                  {Object.keys(activity?.actionable).forEach((key) => {
                    if (
                      key !== "imported_on" &&
                      key !== "import_from_app_id" &&
                      key !== "id" &&
                      key !== "profile_id" &&
                      key !== "created_at" &&
                      key !== "updated_at" &&
                      activity?.actionable[key] !== null
                    ) {
                      array.push({
                        key: key?.replaceAll("_", " "),
                        value: activity?.actionable[key],
                      });
                    }
                  })}
                  {array
                    .filter(
                      (value, index, self) =>
                        index ===
                        self.findIndex(
                          (t) =>
                            t?.key === value?.key && t?.value === value?.value
                        )
                    )
                    .map((text, key) => {
                      return (
                        <div key={key} className={"d-flex flex-row"}>
                          <Typography className={"me-2"}>
                            <span
                              style={{
                                color: "#ffb722",
                                fontWeight: "bold",
                              }}
                            >
                              {text?.key?.charAt(0)?.toUpperCase() +
                                text?.key?.slice(1)}
                              :{" "}
                            </span>
                          </Typography>
                          <Typography>
                            {text?.key?.includes("date")
                              ? text?.value?.split("-")[1] +
                                "-" +
                                text?.value?.split("-")[2] +
                                "-" +
                                text?.value?.split("-")[0]
                              : text?.value}
                          </Typography>
                        </div>
                      );
                    })}
                  <Typography>
                    <span
                      style={{
                        color: "#ffb722",
                        fontWeight: "bold",
                      }}
                    >
                      Performed on
                    </span>{" "}
                    -{" "}
                    {activity?.created_at}
                  </Typography>
                  <Typography>
                    <span
                      style={{
                        color: "#ffb722",
                        fontWeight: "bold",
                      }}
                    >
                      IP Address
                    </span>{" "}
                    -{" "}
                    {activity?.ip
                      ? activity?.ip?.charAt(0).toUpperCase() +
                        activity?.ip?.slice(1)
                      : "IP not found"}
                  </Typography>
                </AccordionDetails>
              )}
            </Accordion>
          );
        })}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    sort: state.sort.sort,
    order: state.sort.order,
  };
};

export default connect(mapStateToProps)(ActivityHistoryAccordion);
