import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const Droppable = ({ id, onDropItem, children }) => {
  const [, dropRef] = useDrop({
    accept: "YOUR_COMPONENT",
    drop: async (item, monitor) => {
      if (monitor.didDrop()) {
        return;
      }
      console.log({ id });
      await onDropItem(id, item.id);
    },
  });

  return <div ref={dropRef}>{children}</div>;
};

export default Droppable;
