import "date-fns";
import React, {useState} from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import { makeStyles } from "@material-ui/core/styles";
import {Checkbox, FormControlLabel, Grid, Typography} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import { Close } from "@material-ui/icons";
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from "@material-ui/core/CircularProgress";
import {Auth} from "../../../services/auth.service";
import {store} from "../../../store/store";
import {set2FA} from "../../../store/actions/authAction";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";


const useStyles = makeStyles(styles);
function OtpModal(props) {
    const authService = new Auth();
    const classes = useStyles();
    const [otp, setOtp] = useState(false)
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState({pwd: "password"})
    const [err, setErr] = useState(null)
    const [data, setData] = useState({
        check: false,
        password: null,
    })
    const handleSubmit = () => {
        let temp = {
            twofa_enabled: !store.getState()?.auth?.user?.twofa_enabled,
            otp: data.password
        }
        setErr(null)
        setLoading(true)
        authService.twofa(temp).then(() => {
            store.dispatch(set2FA(!store.getState().auth?.user?.twofa_enabled))
            setLoading(false)
            props.handleClose()
        }).catch((e) => {
            setLoading(false)
            if(e.response)
            {
                setErr(e?.response?.data?.errors?.full_messages && e?.response?.data?.errors?.full_messages[0] === "Current password is invalid" ? "Invalid password" : e?.response?.data?.errors?.full_messages ? e?.response?.data?.errors?.full_messages[0] : e?.response?.data?.errors[0])
            }
            else {
                setErr("Internal server error, try again later")
            }
        })

    };


    const handleChange = (event) => {
        setData({...data, [event.target.name]: event.target.value})
    };

    return (
        <>
            <CustomModal
                visible={props.open}
                width="55%"
                margin="120px 0 40px"
                inset= "15px!important"
            >
                <ModalHeader
                    closeModal={props.handleClose}
                    icon={
                        <Close
                            style={{
                                color: color.white,
                                marginRight: "20px",
                                fontSize: "30px",
                                cursor: "pointer",
                            }}
                        />
                    }
                    heading={"Two-Factor Authentication (2FA) Preferences"}
                    color={color.white}
                />
                <div className={classes.modalBody}>
                    <Grid
                        container
                        wrap="wrap"
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        {err && (
                            <div className="w-100 d-flex flex-row mb-5">
                                <Typography
                                    variant="p"
                                    component="p"
                                    align="left"
                                    style={{ color: "red", fontWeight: "normal" }}
                                >
                                    {err}
                                </Typography>
                            </div>
                        )}
                        <div className="w-100 d-flex flex-row mb-4 mt-2">
                            <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{fontWeight: "bold"}}
                            >
                                *We have sent you an otp code on your phone number, please fill in the code below to continue.

                            </Typography>
                        </div>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography
                                variant="h6"
                                component="h3"
                                style={{ color: color.themeColorNew }}
                            >
                                {"Enter OTP"}
                            </Typography>
                            <FormInput
                                name="password"
                                onChange={(e) => setData({...data, password:e.target.value})}
                                label=""
                                variant="outlined"
                                fullWidth={true}
                                margin="dense"
                                Icon={type.pwd === "text" ? VisibilityIcon : VisibilityOffIcon}
                                iconClick={() =>
                                    setType({
                                        pwd: type.pwd === "password" ? "text" : "password",
                                    })
                                }
                                required={true}
                                placeholder={"enter password"}
                                type={type.pwd}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="text-sm-center" style={{ textAlign: "right" }}>

                            {!loading ? <CustomButton
                                type="submit"
                                btnText="Confirm"
                                textAlign="center"
                                display={"block"}
                                padding="12px 25px"
                                minWidth="113px"
                                background="#0042a8"
                                hoverBg="#0042a8"
                                border="0"
                                marginRight="10px"
                                disabled={data.password === null}
                                borderRadius="8px"
                                color="#fff"
                                onClick={handleSubmit}
                            /> : <CustomButton
                                type="submit"
                                btnText={<CircularProgress size={20} color="#DDD" />}
                                textAlign="center"
                                display={"block"}
                                padding="12px 25px"
                                minWidth="113px"
                                background="#0042a8"
                                hoverBg="#0042a8"
                                border="0"
                                marginRight="10px"
                                borderRadius="8px"
                                color="#fff"
                            />}

                        </Grid>
                    </Grid>
                </div>
            </CustomModal>
        </>
    );
}

export default OtpModal;
