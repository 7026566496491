import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    display: "flex",
    alignItems: "center",
    background: "#0042a8",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    padding: theme.spacing(3, 4),
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      // flexDirection: 'COLUMN',
    },
  },
  spaceBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

export default function ModalHeader(props) {
  const classes = useStyles();

  return (
    <div
      className={`${classes.modalHeader} ${classes.spaceBetween} ${props.extraClass}`}
      style={{ background: props.headerBgColor }}
    >
      <div className={classes.flexRow}>
        <div onClick={props.closeModal}>{props.icon}</div>
        <Typography
          variant="h5"
          component="h3"
          style={{ color: props.color, textAlign: props.textAlign, wordBreak: "break-word" }}
        >
          {props.heading}
        </Typography>
      </div>
      <div onClick={props.onClick}>{props.rightIcon}</div>
    </div>
  );
}
