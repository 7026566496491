import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close, EditOutlined } from "@material-ui/icons";
// import AvatarGroup from '@mui/material/AvatarGroup';
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import jpegImage from "../../../assets/img/jpegImage.png";
import pdfImage from "../../../assets/img/pdfImage.png";
import { VehicleService } from "../../../services/vehicle.service.js";
import { dateFormat } from "../../../Utils/dateFormate.js";
import moment from "moment";

const useStyles = makeStyles(styles);
export default function VehicleInformation(props) {
  const classes = useStyles();
  const vehicleService = new VehicleService();

  React.useEffect(() => {
    vehicleService
      .vehicleShow(props?.vehicle[0]?.id)
      .then(() => {})
      .catch(() => {});
  }, []);

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%"
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          rightIcon={
            <EditOutlined
              onClick={() => props?.handleOpenEdit(props?.vehicle[0])}
              style={{
                color: color.white,
                marginLeft: "auto",
                fontSize: "36px",
                cursor: "pointer",
              }}
            />
          }
          heading={
            props?.vehicle[0]?.providerr
              ? props?.vehicle[0]?.providerr
              : "Vehicle"
          }
          color={color.white}
        />
        <div className={classes.modalBody}>
          <div className="w-100 d-flex flex-row mb-5">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Vehicle > Vehicle > Information"}
            </Typography>
          </div>
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Vehicle Type *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.vehicle[0]?.vehicle_type}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Make *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.vehicle[0]?.make ? (
                  props?.vehicle[0]?.make
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Model *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.vehicle[0]?.model ? (
                  props?.vehicle[0]?.model
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Year"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.vehicle[0]?.year ? (
                  props?.vehicle[0]?.year
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            {props?.vehicle[0]?.linked_items &&
              props?.vehicle[0]?.linked_items?.auto_insurance && (
                <>
                  <Grid item xs={12} sm={4} md={4}>
                    <Typography
                      variant="h6"
                      component="h3"
                      style={{ color: color.themeColorNew }}
                    >
                      {"Auto Insurance"}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} md={8}>
                    <Typography
                      variant="p"
                      component="p"
                      style={{ color: color.themeColorNew, fontSize: "18px" }}
                    >
                      {
                        props?.vehicle[0]?.linked_items?.auto_insurance
                          ?.insurance_provider
                      }
                    </Typography>
                  </Grid>
                </>
              )}
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Description"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.vehicle[0]?.description ? (
                  props?.vehicle[0]?.description
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Title Holder"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.vehicle[0]?.title_holder ? (
                  props?.vehicle[0]?.title_holder
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Cash or Loan"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.vehicle[0]?.cash === true ? "Cash" : "Loan"}
              </Typography>
            </Grid>
            {props?.vehicle[0]?.cash === false && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Monthly Payment"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew, fontSize: "18px" }}
                  >
                    {props?.vehicle[0]?.monthly_payment ? (
                      props?.vehicle[0]?.monthly_payment
                    ) : (
                      <small>-</small>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Loan Account Number"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew, fontSize: "18px" }}
                  >
                    {props?.vehicle[0]?.loan_account_number ? (
                      props?.vehicle[0]?.loan_account_number
                    ) : (
                      <small>-</small>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Lease Holder"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew, fontSize: "18px" }}
                  >
                    {props?.vehicle[0]?.lease_holder ? (
                      props?.vehicle[0]?.lease_holder
                    ) : (
                      <small>-</small>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Lease Account Number"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew, fontSize: "18px" }}
                  >
                    {props?.vehicle[0]?.lease_account_number ? (
                      props?.vehicle[0]?.lease_account_number
                    ) : (
                      <small>-</small>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Lease Base Payment"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew, fontSize: "18px" }}
                  >
                    {props?.vehicle[0]?.lease_base_payment ? (
                      props?.vehicle[0]?.lease_base_payment
                    ) : (
                      <small>-</small>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Lease Tax Payment"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew, fontSize: "18px" }}
                  >
                    {props?.vehicle[0]?.lease_tax_payment ? (
                      props?.vehicle[0]?.lease_tax_payment
                    ) : (
                      <small>-</small>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Lease Expiration Date"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew, fontSize: "18px" }}
                  >
                    {props?.vehicle[0]?.lease_expiration_date ? (
                      moment(
                        props?.vehicle[0]?.lease_expiration_date?.replaceAll(
                          "-",
                          "/"
                        )
                      ).format(dateFormat()?.toUpperCase())
                    ) : (
                      <small>-</small>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Lease Termination Fee"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew, fontSize: "18px" }}
                  >
                    {props?.vehicle[0]?.lease_termination_fee ? (
                      props?.vehicle[0]?.lease_termination_fee
                    ) : (
                      <small>-</small>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Balance"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew, fontSize: "18px" }}
                  >
                    {props?.vehicle[0]?.balance ? (
                      props?.vehicle[0]?.balance
                    ) : (
                      <small>-</small>
                    )}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Value"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.vehicle[0]?.value ? (
                  props?.vehicle[0]?.value
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Owner"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.vehicle[0]?.owner ? (
                  props?.vehicle[0]?.owner
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Ownership Description"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.vehicle[0]?.ownership_description ? (
                  props?.vehicle[0]?.ownership_description
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"TagData Number"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.vehicle[0]?.tag_number ? (
                  props?.vehicle[0]?.tag_number
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Registration County"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.vehicle[0]?.registration_county ? (
                  props?.vehicle[0]?.registration_county
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Registration State"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.vehicle[0]?.registration_state ? (
                  props?.vehicle[0]?.registration_state
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Registration Expiration"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.vehicle[0]?.registration_expiration ? (
                  moment(
                    props?.vehicle[0]?.registration_expiration?.replaceAll(
                      "-",
                      "/"
                    )
                  ).format(dateFormat()?.toUpperCase())
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Color"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.vehicle[0]?.color ? (
                  props?.vehicle[0]?.color
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"VIN Number"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.vehicle[0]?.vin_number ? (
                  props?.vehicle[0]?.vin_number
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Mileage"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.vehicle[0]?.mileage ? (
                  props?.vehicle[0]?.mileage
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Date Of Mileage"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.vehicle[0]?.date_of_mileage ? (
                  moment(
                    props?.vehicle[0]?.date_of_mileage?.replaceAll("-", "/")
                  ).format(dateFormat()?.toUpperCase())
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Notes"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.vehicle[0]?.notes ? (
                  props?.vehicle[0]?.notes
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
          </Grid>
          {props?.vehicle[0]?.documents && (
            <Grid
              container
              wrap="wrap"
              direction="row"
              spacing={3}
              className="mt-5"
            >
              <Grid item xs={12} sm={6} md={6}>
                <Typography
                  variant="h6"
                  component="h3"
                  style={{ color: color.themeColorNew }}
                >
                  {"Linked Items"}
                </Typography>
                <div className="d-flex flex-row align-items-center mt-2">
                  <img src={jpegImage} className="me-2" />
                  <img src={pdfImage} className="me-2" />
                  <Typography
                    onClick={props.handleOpenLinkedItems}
                    variant="h6"
                    component="h3"
                    align="right"
                    className="text-decoration-underline"
                    style={{ color: color.themeColorNew, cursor: "pointer" }}
                  >
                    {"See all"}
                  </Typography>
                </div>
              </Grid>
              {/*<Grid item xs={12} sm={6} md={6}>*/}
              {/*  <Typography*/}
              {/*    variant="h6"*/}
              {/*    component="h3"*/}
              {/*    align="right"*/}
              {/*    style={{ color: color.themeColorNew }}*/}
              {/*  >*/}
              {/*    {"Collaborators"}*/}
              {/*  </Typography>*/}
              {/*  <div className="d-flex flex-row align-items-center justify-content-end mt-2">*/}
              {/*    <img src={jpegImage} className="me-2" />*/}
              {/*    <img src={pdfImage} className="me-2" />*/}
              {/*    <Typography*/}
              {/*      onClick={props.handleOpenCollaborators}*/}
              {/*      variant="h6"*/}
              {/*      component="h3"*/}
              {/*      align="right"*/}
              {/*      className="text-decoration-underline"*/}
              {/*      style={{ color: color.themeColorNew, cursor: "pointer" }}*/}
              {/*    >*/}
              {/*      {"See all"}*/}
              {/*    </Typography>*/}
              {/*  </div>*/}
              {/*</Grid>*/}
            </Grid>
          )}
        </div>
      </CustomModal>
    </>
  );
}
