import * as types from "./types";
import {ProfileService} from "../../services/profile.service.js";
import {store} from "../store";

const profile = new ProfileService();

export function clearProfileState() {
    return {
        type: types.CREATE_PROFILE,
    };
}

export function setProfilesArray(profiles) {
    return {
        type: types.GET_PROFILES,
        payload: profiles,
    };
}

export function setProfileLoading(bool) {
    return {
        type: types.LOADING_PROFILE,
        payload: bool,
    };
}

export function setProfileError(error) {
    return {
        type: types.PROFILE_ERROR,
        payload: error,
    };
}

export function setProfile(profile) {
    return {
        type: types.GET_PROFILE_SPECIFIC,
        payload: profile,
    };
}

export const create = (data, hide) => {
    return (dispatch) => {
        dispatch(setProfileLoading(true));
        dispatch(setProfileError(null));
        profile
            .create(data)
            .then((response) => {
                dispatch(setProfileLoading(false));
                dispatch(setProfileError(null));
                dispatch(setProfile(response?.data));
                dispatch(index());
                hide();
            })
            .catch((err) => {
                if (err?.response?.data) {
                    dispatch(setProfileLoading(false));
                    let str = "";
                    dispatch(setProfileError(err?.response?.data?.errors?.full_messages));
                    Object.keys(err?.response?.data?.message).forEach((key) => {
                        let value = err?.response?.data?.message[key];
                        str += key?.replace("_", " ") + ": " + value;
                    });
                    dispatch(setProfileError(str));
                } else {
                    dispatch(setProfileLoading(false));
                    dispatch(setProfileError("Something went wrong. Try again later"));
                }
            });
    };
};

export const index = () => {
    return (dispatch) => {
        dispatch(setProfileLoading(false));
        profile
            .index()
            .then((response) => {
                console.log({response});
                dispatch(setProfileLoading(false));
                dispatch(setProfilesArray(response?.data?.profiles));
                if (
                    store.getState().auth.user?.sign_in_count === 1 ||
                    store.getState().profile.profileInfo === null
                ) {
                    const temp = response?.data?.profiles?.filter(
                        (profile) => profile.is_primary === true
                    );
                    const profile = {
                        profile: temp[0],
                    };
                    dispatch(setProfile(profile));
                    if (!store.getState().profile.profileInfo.profile) {
                        dispatch(setProfile({profile: response?.data?.profiles[0]}));
                    }
                }
            })
            .catch((err) => {
                if (err?.response?.data) {
                    dispatch(setProfileLoading(false));
                } else {
                    dispatch(setProfileLoading(false));
                }
            });
    };
};

export const show = (id) => {
    return (dispatch) => {
        dispatch(setProfileLoading(false));
        profile
            .show(id)
            .then((response) => {
                dispatch(setProfileLoading(false));
                dispatch(setProfile(response?.data));
            })
            .catch((err) => {
                if (err?.response?.data) {
                    dispatch(setProfileLoading(false));
                } else {
                    dispatch(setProfileLoading(false));
                }
            });
    };
};

export const destroy = (id) => {
    return (dispatch) => {
        dispatch(setProfileLoading(false));
        profile
            .delete(id)
            .then((response) => {
                dispatch(setProfileLoading(false));
                const temp = store
                    .getState()
                    .profile.profiles?.filter((p) => p.id !== id);
                dispatch(setProfilesArray(temp));
                const current = temp.filter((p) => p.is_primary === true);
                const profile = {
                    profile: current[0],
                };
                dispatch(setProfile(profile));
            })
            .catch((err) => {
                if (err?.response?.data) {
                    dispatch(setProfileLoading(false));
                    dispatch(setProfileError(err?.response?.data?.errors?.full_messages));
                } else {
                    dispatch(setProfileLoading(false));
                    dispatch(setProfileError("Something went wrong. Try again later"));
                }
            });
    };
};

export const update = (data, id, close) => {
    return (dispatch) => {
        dispatch(setProfileLoading(true));
        profile
            .update(data, id)
            .then((response) => {
                dispatch(setProfileLoading(false));
                dispatch(index());
                dispatch(setProfile(response?.data));
                close();
            })
            .catch((err) => {
                if (err?.response?.data) {
                    dispatch(setProfileLoading(false));
                    dispatch(setProfileError(err?.response?.data?.errors ? err?.response?.data?.errors.full_messages : err?.response?.data?.message?.profile[0]));
                } else {
                    dispatch(setProfileLoading(false));
                    dispatch(setProfileError("Something went wrong. Try again later"));
                }
            });
    };
};
