
import _default from "../config/default";
import { store } from "../store/store";

export class FinanceService {
  constructor() {
    this.BASE_URL = _default.BASE_URL;
    this.AUTH_ENDPOINT = _default.ENDPOINT_AUTH;
  }

  cashAcountsIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/cash_accounts`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  cashAcountsShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/cash_accounts/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  createCashAcounts(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/cash_accounts`,
      headers: {
        "Content-Type": "multipart/form-data",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  editCashAcounts(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/cash_accounts/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  cashAccountsDestroy(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/cash_accounts/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  cashAcountsDestroyAll(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_cash_accounts`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }

  mortageIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/mortgages`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  mortgageShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/mortgages/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  createMortgage(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/mortgages`,
      headers: {
        "Content-Type": "multipart/form-data",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  editMortgage(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/mortgages/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  mortgageDestroy(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/mortgages/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  mortgageDestroyAll(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_mortgages`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }

  createExpenses(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/expenses`,
      headers: {
        "Content-Type": "multipart/form-data",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  expenseIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/expenses`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  expenseShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/expenses/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  editExpenses(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/expenses/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  expensesDestroy(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/expenses/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  expensesDestroyAll(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_expenses`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }

  createIncome(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/incomes`,
      headers: {
        "Content-Type": "multipart/form-data",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  incomeIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/incomes`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  incomeShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/incomes/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  editIncome(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/incomes/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  incomeDestroy(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/incomes/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  incomeDestroyAll(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_incomes`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }

  createMarginAccount(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/margin_accounts`,
      headers: {
        "Content-Type": "multipart/form-data",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  marginAccountsIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/margin_accounts`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  marginAccountsShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/margin_accounts/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  editMarginAccount(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/margin_accounts/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  marginAccountDestroy(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/margin_accounts/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  marginAccountDestroyAll(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_margin_accounts`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }

  createMarketableSecurities(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/marketable_securities`,
      headers: {
        "Content-Type": "multipart/form-data",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  marketableSecuritiesIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/marketable_securities`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  marketableSecuritiesShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/marketable_securities/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  editMarketableSecurities(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/marketable_securities/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  marketableSecuritiesDestroy(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/marketable_securities/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  marketableSecuritiesDestroyAll(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_marketable_securities/`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }

  createNonmarketableSecurities(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/nonmarketable_securities`,
      headers: {
        "Content-Type": "multipart/form-data",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  nonmarketableSecuritiesIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/nonmarketable_securities`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  nonmarketableSecuritiesShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/nonmarketable_securities/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  editNonmarketableSecurities(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/nonmarketable_securities/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  nonmarketableSecuritiesDestroy(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/nonmarketable_securities/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  nonmarketableSecuritiesDestroyAll(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_nonmarketable_securities`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }

  createRetirementFunds(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/retirement_funds`,
      headers: {
        "Content-Type": "multipart/form-data",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  retirementFundsIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/retirement_funds`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  retirementFundsShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/retirement_funds/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  editRetirementFunds(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/retirement_funds/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  retirementFundsDestroy(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/retirement_funds/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  retirementFundsDestroyAll(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_retirement_funds`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }

  createTaxReturns(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/tax_returns`,
      headers: {
        "Content-Type": "multipart/form-data",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  taxReturnsIndex() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/tax_returns`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  taxReturnsShow(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/tax_returns/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  editTaxReturns(data, id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/tax_returns/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  taxReturnsDestroy(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/tax_returns/${id}`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  taxReturnsDestroyAll(payload) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${
        store.getState().profile?.profileInfo?.profile?.id
      }/destroy_all_tax_returns`,
      headers: {
        "Content-Type": "application/json",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
      data: {
        ids: payload,
      },
    });
  }
}
