import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Avatar, Typography} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import {Close, MoreVert} from "@material-ui/icons";
import color from "../../../assets/css/color";
import dashPlusIcon from "../../../assets/img/dashPlusIcon.png";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import {store} from "../../../store/store.js";
import {setProfile} from "../../../store/actions/profileAction.js";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

import _default from "../../../config/default";
import Warning from "../Alerts/Warning/Warning";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';

const options = ["Delete"];

const useStyles = makeStyles(styles);
export default function SwithcProfile(props) {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [alert, setAlert] = React.useState(false)
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [key, setKey] = React.useState(null);
    const handleClick = () => {
        console.info(`You clicked ${options[selectedIndex]}`);
    };

    useEffect(() => {
    }, [props.profiles]);

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = (key) => {
        setKey(key);
        setOpen(!open);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <>
            <CustomModal
                visible={props.open}
                onClose={props.handleClose}
                inset="15px!important"
                width="45%"
            >
                <ModalHeader
                    closeModal={props.closeModal}
                    icon={
                        <Close
                            style={{
                                color: color.white,
                                marginRight: "20px",
                                fontSize: "26px",
                                cursor: "pointer",
                            }}
                        />
                    }
                    heading={"Switch Profile"}
                    color={color.white}
                />
                <div
                    className={props.profiles?.length < 5 ? `${classes.modalBody} ${classes.centerCenter} ` : `${classes.modalBody} ${classes.centerCenter}`}
                    style={{justifyContent: "center"}}>
                    {props.profiles?.length > 0 &&
                        props.profiles.map((profile, index) => {
                            return (
                                <div className={classes.w_30}>
                                    <div
                                        className={classes.profileHere}
                                        key={index}
                                        style={{cursor: "pointer", marginBottom: '12px', justifyContent: "center"}}
                                    >
                                        <Avatar
                                            onClick={() => {
                                                store.dispatch(setProfile({profile: profile}));
                                                props?.handleClose();
                                                // props.handleClose();
                                            }}
                                            // sx={{ bgcolor: deepOrange }}
                                            alt={profile.first_name}
                                            src={_default.BASE_URL + profile?.attachment}
                                            className={`${classes.avatar150px} ${classes.marginBottom18px}`}
                                        />
                                        <div className="d-flex flex-row">
                                            <Typography
                                                variant="p"
                                                component="p"
                                                align="center"
                                                style={{color: color.themeColorNew, fontWeight: "bold"}}
                                            >
                                                {profile?.profile_type === "person" ? profile.first_name + " " + profile.last_name : profile?.business_name}
                                            </Typography>
                                            {!profile?.is_primary && (
                                                <div className="position-relative">
                                                    <MoreVert
                                                        color="primary"
                                                        aria-controls={
                                                            open ? "split-button-menu" : undefined
                                                        }
                                                        aria-expanded={open ? "true" : undefined}
                                                        aria-label="select merge strategy"
                                                        aria-haspopup="menu"
                                                        onClick={() => handleToggle(index)}
                                                        style={{
                                                            cursor: "pointer",
                                                            position: "relative",
                                                            color: color.themeYellow,
                                                        }}
                                                    />
                                                    {key === index && alert &&
                                                        <Warning open={alert} handleClose={() => setAlert(false)}
                                                                 heading={"Warning"}
                                                                 message={"Are you sure you want to delete this profile?"}
                                                                 onClick={() => {
                                                                     props.handleDelete(profile.id)
                                                                     setAlert(false)
                                                                 }}/>}

                                                    {key === index ? (
                                                        <Popper
                                                            className="position-absolute dropMenu zIndex"
                                                            open={open}
                                                            anchorEl={anchorRef.current}
                                                            role={undefined}
                                                            transition
                                                            disablePortal
                                                        >
                                                            {({TransitionProps, placement}) => (
                                                                <Grow
                                                                    {...TransitionProps}
                                                                    style={{
                                                                        transformOrigin:
                                                                            placement === "bottom"
                                                                                ? "center top"
                                                                                : "center bottom",
                                                                    }}
                                                                >
                                                                    <Paper>
                                                                        <ClickAwayListener
                                                                            onClickAway={handleClose}
                                                                        >
                                                                            <MenuList id="split-button-menu">
                                                                                {options.map((option, index) => (
                                                                                    <MenuItem
                                                                                        key={option}
                                                                                        onClick={() =>
                                                                                            setAlert(true)
                                                                                        }
                                                                                    >
                                                                                        {option}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </MenuList>
                                                                        </ClickAwayListener>
                                                                    </Paper>
                                                                </Grow>
                                                            )}
                                                        </Popper>
                                                    ) : null}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    {
                        store.getState().auth?.user?.account_type !== "Free" || store.getState().auth?.user?.account_type !== "Premium" ? <>
                            {
                                <div className={classes.w_30}>
                                    <div
                                        className={classes.profileHere}
                                        onClick={props.handleOpenAddProfile}
                                    >
                                        <AddCircleOutlineOutlinedIcon
                                            style={{
                                                color: color.themeColorNewLight,
                                                fontSize: "49px",
                                                cursor: "pointer",
                                                marginBottom: "8px"
                                            }}
                                        />
                                        {/* <img src={dashPlusIcon} style={{marginBottom: "8px"}}/> */}
                                        <Typography
                                            variant="p"
                                            component="p"
                                            align="center"
                                            style={{color: color.themeYellow, fontWeight: "bold", cursor: "pointer",}}
                                        >
                                            {"Add"}
                                            <Typography
                                                variant="p"
                                                component="p"
                                                align="center"
                                                style={{color: color.themeYellow, fontWeight: "bold", cursor: "pointer",}}
                                            >
                                                {"Profile"}
                                            </Typography>
                                        </Typography>
                                    </div>
                                </div>
                            }
                        </> : null
                    }
                </div>
            </CustomModal>
        </>
    );
}
