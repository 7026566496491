import "date-fns";
import React, { useRef } from "react";
import debounce from "lodash.debounce";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import disableCheck from "../../../Utils/disableCheck.js";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { dateFormat } from "../../../Utils/dateFormate.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import { PropertyService } from "../../../services/property.service.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import SureToAdd from "../Alerts/SureToAdd/SureToAdd.js";
import ChooseWebLogins from "../ChooseWebLogins/ChooseWebLogins.js";
import EditWebLogin from "../EditWebLogin/EditWebLogin.js";
import AddDocument from "../AddDocument/AddDocument.js";
import EditDocument from "../EditDocument/EditDocument.js";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { getDateIgnoreTimezone } from "../../../Utils/dateHelper.js";
import { upperCaseErrorKeys } from "../../../Utils/stringManipulation.js";
import PasswordVerification from "../PasswordVerification/PasswordVerification.js";
import FileViewer from "../../../components/FileViewer/FileViewer.js";
import SpinnerModal from "../Alerts/SpinnerModal/SpinnerModal.js";
import AlertPopup from "../Alerts/Generic/GenericAlert.js";
import { DocumentService } from "../../../services/document.service.js";

const stateArray = [
  "Artwork",
  "Appliances",
  "Bedding/Drapes/Linen",
  "Books/CDs/DVDs/Tapes",
  "Camera",
  "Clothing",
  "Collectible",
  "Cooking",
  "Cutlery/Silverware",
  "Decorations",
  "Dishes/Fine China",
  "Electronics",
  "Furniture",
  "Gardening",
  "Glass/Crystal",
  "Gold",
  "Jewelery",
  "Miscelleous Items",
  "Musical Instrument",
  "Rug",
  "Sporting Goods",
  "Tools",
  "Toys",
  "Others",
];

const timing = [
  { show: "1 week from expiration", value: "one_week" },
  { show: "2 weeks from expiration", value: "two_weeks" },
  { show: "1 month from expiration", value: "one_month" },
  { show: "3 months from expiration", value: "three_months" },
  { show: "6 months from expiration", value: "six_months" },
];

const frequancy = [
  { show: "Daily", value: "daily" },
  { show: "Every other Day", value: "every_other_day" },
  { show: "Weekly", value: "weekly" },
  { show: "Biweekly", value: "bi_weekly" },
];

const useStyles = makeStyles(styles);

function EditSerialNumber(props) {
  const classes = useStyles();
  const errRef = useRef(null);
  const propertyService = new PropertyService();
  const [err, setErr] = React.useState([]);
  const [link, setLink] = React.useState(null);
  const [showDoc, setShowDoc] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [spinner, setSpinner] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({
    open: false,
    type: null,
    btnText: null,
    cancelText: null,
    heading: null,
    message: null,
  });
  const [password, setPassword] = React.useState(null);
  const [showPasswordVerification, setShowPasswordVerification] =
    React.useState(false);
  const [documentModal, setDocumentModal] = React.useState(false);
  const [editDoc, setEditDoc] = React.useState(false);
  const [currentDocument, setCurrentDocument] = React.useState(null);
  const obj = props?.serialNumber?.associated_documents_attributes
    ? props?.serialNumber?.associated_documents_attributes
    : [];
  const [doc, setDoc] = React.useState(
    props?.serialNumber?.documents ? props?.serialNumber?.documents : []
  );
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [reminder, setReminder] = React.useState(
    props?.serialNumber?.alert_attributes?.status === "active"
  );
  const [editWebLogin, setEditWebLogin] = React.useState(false);
  const [webLogin, setWebLogin] = React.useState(
    (props?.serialNumber?.web_logins && props?.serialNumber?.web_logins[0]) ||
      null
  );
  const [webLoginModal, setWebLoginModal] = React.useState(false);
  const [data, setData] = React.useState({
    associated_documents_attributes: obj,
    product_name: props?.serialNumber?.product_name,
    number: props?.serialNumber?.number,
    expiration_date: props?.serialNumber?.expires
      ? props?.serialNumber?.expires?.replaceAll("-", "/")
      : null,
    notes: props?.serialNumber?.notes,
    ...(props?.serialNumber?.alert_attributes && {
      alert_attributes: {
        timing: props?.serialNumber?.alert_attributes?.timing,
        frequency: props?.serialNumber?.alert_attributes?.frequency,
        is_active: props?.serialNumber?.alert_attributes?.status,
        id: props?.serialNumber?.alert_attributes?.id,
        profile_id: props?.profile?.profileInfo?.profile.id,
      },
    }),
    associated_logins_attributes: [
      {
        web_login_id:
          props?.serialNumber?.web_logins &&
          props?.serialNumber?.web_logins[0]?.id,
        id:
          props?.serialNumber?.associated_logins &&
          props?.serialNumber?.associated_logins[0]?.id,
        _destroy: 0,
      },
    ],
  });
  const [sureToAdd, setSureToAdd] = React.useState({ open: false, str: null });

  const handleOpenSureToAdd = (str) => {
    setSureToAdd({ open: true, str: str ?? null });
  };
  const handleCloseSureToAdd = () => {
    if (sureToAdd.str !== null) {
      props?.handleClose();
    }
    setSureToAdd({ open: false, str: null });
  };

  const handleSubmit = () => {
    handleCloseSureToAdd();
    if (
      !data.associated_logins_attributes[0].web_login_id &&
      data.associated_logins_attributes[0]._destroy === 0
    ) {
      delete data["associated_logins_attributes"];
    }
    const temp = data?.associated_documents_attributes?.filter(
      (e) => e.document_id !== null
    );
    if (props?.wellness?.associated_documents_attributes) {
      setData({ ...data, associated_documents_attributes: temp });
    } else {
      if (temp && temp.length > 0) {
        setData({ ...data, associated_documents_attributes: temp });
      } else {
        let temp2 = delete data["associated_documents_attributes"];
        setData(data);
      }
    }
    console.log({ data });

    let payload = { ...data };
    console.log({ payload });
    payload = {
      ...payload,
      expiration_date: payload.expiration_date
        ? getDateIgnoreTimezone(new Date(payload.expiration_date))
            .toISOString()
            .slice(0, 10)
        : null,
    };

    setIsLoading(true);
    propertyService
      .editSerialNumbers(payload, props?.serialNumber?.id)
      .then((response) => {
        setIsLoading(false);
        props.forceRerender();
        props.handleClose();

        console.log(response.data);
      })
      .catch((err) => {
        errRef.current.scrollIntoView();
        setIsLoading(false);
        if (err.response && err.response.data.message) {
          if (err.response?.data?.message === "Please enter a valid date") {
            setErr([err.response?.data?.message]);
          } else {
            let array = [];
            Object.keys(err.response.data.message).forEach((key) => {
              let temp =
                key === "alert.alert" ? "alert" : upperCaseErrorKeys(key);
              array.push(`${temp}: ${err.response.data.message[key]}`);
            });
            setErr(array);
          }
        } else {
          setErr(["Internal Server Error"]);
        }
      });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = debounce((event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  }, 300);

  const documentService = new DocumentService();

  const show = (docId) => {
    const documentId = docId ?? id;
    setSpinner(true);
    documentService
      .show(documentId, password)
      .then((res) => {
        setSpinner(false);
        setLink({
          attachment: res.data.document.attachment,
          contentType: res.data.document.content_type,
        });
        setShowDoc(true);
        setShowPasswordVerification(false);
        setPassword(null);
      })
      .catch((err) => {
        setSpinner(false);
        setPassword(null);
        setAlert({
          type: "Error",
          open: true,
          cancelText: "CANCEL",
          heading:
            err?.response && err?.response?.status === 422
              ? "Unauthorized!"
              : "Error!",
          message:
            err?.response && err?.response?.status === 422
              ? "Wrong password! please try again."
              : "An unknown error occurred, please try later.",
        });
      });
  };

  const initialRef = useRef(null);
  React.useEffect(() => {
    initialRef.current = data;
  }, []);

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={() => {
          !disableCheck(initialRef, data)
            ? handleOpenSureToAdd("exit")
            : props?.handleClose();
        }}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        {alert.open && (
          <AlertPopup
            btnText={alert.btnText}
            cancelText={alert.cancelText}
            open={alert.open}
            type={alert.type}
            heading={alert.heading}
            message={alert.message}
            isLoading={false}
            handleClose={() => {
              setAlert({
                open: false,
                btnText: null,
                cancelText: null,
                heading: null,
                message: null,
                type: null,
              });
            }}
            handleClick={() => {
              setAlert({
                open: false,
                btnText: null,
                cancelText: null,
                heading: null,
                message: null,
                type: null,
              });
            }}
          />
        )}
        {spinner && <SpinnerModal open={spinner} msg={"Loading"} />}
        {showDoc && (
          <FileViewer
            contentType={link?.contentType}
            link={link?.attachment}
            open={showDoc}
            handleClose={() => setShowDoc(false)}
          />
        )}
        {showPasswordVerification && (
          <PasswordVerification
            open={showPasswordVerification}
            handleClose={() => {
              setShowPasswordVerification(false);
              setId(null);
            }}
            handleChange={(e) => setPassword(e)}
            handleSubmit={() => {
              show();
            }}
          />
        )}
        <ModalHeader
          closeModal={() => {
            !disableCheck(initialRef, data)
              ? handleOpenSureToAdd("exit")
              : props?.handleClose();
          }}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Edit " + data.product_name}
          color={color.white}
        />
        <div className={classes.modalBody} ref={errRef}>
          <div className="w-100 d-flex flex-row mb-4">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Property > Serial Number > Edit"}
            </Typography>
          </div>
          {err?.length > 0 && (
            <div className="w-100 d-flex flex-row mb-4">
              {err?.map((val, key) => {
                return (
                  <Typography
                    key={key}
                    variant="p"
                    component="p"
                    align="left"
                    style={{
                      color: "red",
                      fontWeight: "normal",
                      marginBottom: "2px",
                    }}
                  >
                    {val.charAt(0).toUpperCase() + val.slice(1)}
                  </Typography>
                );
              })}
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            {editDoc && (
              <EditDocument
                category={doc[0]?.category}
                id={doc[0].id}
                title={doc[0].title}
                open={editDoc}
                handleClose={() => setEditDoc(false)}
              />
            )}
            {documentModal && (
              <AddDocument
                setData={setData}
                setDoc={setDoc}
                data={data}
                associated_documents_attributes={
                  props?.serialNumber?.associated_documents_attributes
                    ? true
                    : false
                }
                doc={doc}
                open={documentModal}
                handleClose={() => setDocumentModal(false)}
                closeModal={() => setDocumentModal(false)}
                category={"Personal"}
              />
            )}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Product Name *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="product_name"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.product_name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Serial Number"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="number"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.number}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Warranty Expiration"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  inputVariant="outlined"
                  format={dateFormat()}
                  id="start_date"
                  placeholder={dateFormat()?.toUpperCase()}
                  value={data?.expiration_date}
                  onChange={(date) => {
                    setData({
                      ...data,
                      expiration_date: date,
                    });
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              {props?.preference?.is_active && (
                <>
                  {!reminder && (
                    <CustomButton
                      btnText={"Add Reminder"}
                      disabled={!data?.expiration_date}
                      textAlign="center"
                      display={"block"}
                      background="transparent"
                      hoverBg="#0042a8"
                      border="1px solid #0042a8"
                      borderRadius="8px"
                      color="#0042a8"
                      onClick={() => {
                        setReminder(true);
                        setData({
                          ...data,
                          alert_attributes: {
                            ...(data.alert_attributes
                              ? {
                                  ...data.alert_attributes,
                                }
                              : {
                                  timing: "one_week",
                                  frequency: "daily",

                                  profile_id:
                                    props?.profile?.profileInfo?.profile.id,
                                }),
                            status: "active",
                          },
                        });
                      }}
                    />
                  )}
                </>
              )}
            </Grid>
            {reminder && (
              <div className={"w-75 m-2 border m-5"}>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Start Date"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} sm={8}>
                  <FormControl id="security-question" fullWidth={true}>
                    <Select
                      id="id_type"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      value={
                        timing.filter(
                          (i) => i.value === data?.alert_attributes?.timing
                        )[0]?.value || props?.preference?.timing
                      }
                      required={true}
                      onChange={(e) => {
                        setData({
                          ...data,
                          alert_attributes: {
                            ...data.alert_attributes,
                            timing: e.target.value,
                          },
                        });
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled>Select Timing</MenuItem>
                      {timing.map((t) => {
                        return <MenuItem value={t.value}>{t.show}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Frequency"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} sm={8}>
                  <FormControl id="security-question" fullWidth={true}>
                    <Select
                      id="id_type"
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      value={
                        frequancy.filter(
                          (i) => i.value === data?.alert_attributes?.frequency
                        )[0]?.value || props?.preference?.frequency
                      }
                      required={true}
                      onChange={(e) => {
                        setData({
                          ...data,
                          alert_attributes: {
                            ...data.alert_attributes,
                            frequency: e.target.value,
                          },
                        });
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled>Select Frequency</MenuItem>
                      {frequancy.map((f) => {
                        return <MenuItem value={f.value}>{f.show}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  <CustomButton
                    textAlign="center"
                    display={"block"}
                    padding="8px 25px"
                    margin={"5px"}
                    background="#0042a8"
                    hoverBg="#0042a8"
                    border="0"
                    borderRadius="8px"
                    color="#fff"
                    btnText={"Remove"}
                    onClick={() => {
                      setData({
                        ...data,
                        alert_attributes: {
                          ...data.alert_attributes,
                          status: "inactive",
                        },
                      });
                      setReminder(false);
                    }}
                  />
                </Grid>
              </div>
            )}

            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Notes"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                name="notes"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.notes}
              />
            </Grid>
            {webLoginModal && (
              <ChooseWebLogins
                setData={setData}
                setWebLogin={setWebLogin}
                data={data}
                open={webLoginModal}
                webLogin={data?.associated_logins_attributes[0]?.web_login_id}
                handleClose={() => setWebLoginModal(false)}
                closeModal={() => setWebLoginModal(false)}
                category={"Personal"}
              />
            )}
            {editWebLogin && (
              <EditWebLogin
                open={editWebLogin}
                handleClose={() => setEditWebLogin(false)}
                webLogin={webLogin}
              />
            )}
            {editWebLogin && (
              <EditWebLogin
                open={editWebLogin}
                handleClose={() => setEditWebLogin(false)}
                webLogin={webLogin}
              />
            )}
            {webLogin && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Web Login"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {webLogin?.user_name}
                  </Typography>
                  <CustomButton
                    btnText={"Remove"}
                    textAlign="center"
                    display={"block"}
                    background="transparent"
                    hoverBg="#0042a8"
                    border="1px solid #0042a8"
                    borderRadius="8px"
                    color="#0042a8"
                    onClick={() => {
                      setWebLogin(null);
                      setData({
                        ...data,
                        associated_logins_attributes: [
                          {
                            web_login_id:
                              data.associated_logins_attributes[0].web_login_id,
                            id: data.associated_logins_attributes[0].id,
                            _destroy: 1,
                          },
                        ],
                      });
                    }}
                  />
                  <CustomButton
                    btnText={"Edit"}
                    textAlign="center"
                    marginLeft="8px"
                    display={"block"}
                    background="transparent"
                    hoverBg="#0042a8"
                    border="1px solid #0042a8"
                    borderRadius="8px"
                    color="#0042a8"
                    onClick={() => {
                      setEditWebLogin(true);
                    }}
                  />
                </Grid>
              </>
            )}
            {doc.length > 0 && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Document"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                  {doc.map((e, k) => (
                    <>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                        key={k}
                      >
                        {e?.title}
                      </Typography>
                      <CustomButton
                        btnText={"Remove"}
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          let temp = data.associated_documents_attributes?.map(
                            (d) => {
                              if (d.document_id === e.id) {
                                return {
                                  ...d,
                                  _destroy: 1,
                                };
                              } else {
                                return d;
                              }
                            }
                          );
                          let newDoc = doc?.filter((d) => d.id !== e.id);
                          setDoc(newDoc);
                          setData({
                            ...data,
                            associated_documents_attributes: temp,
                          });
                        }}
                      />

                      <CustomButton
                        btnText={"Edit"}
                        className="ms-2"
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          setCurrentDocument(e);
                          setEditDoc(true);
                        }}
                      />
                      <CustomButton
                        btnText={"View"}
                        className="ms-2"
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          if (!props?.serialNumber?.documents) {
                            show(e?.id);
                          } else {
                            if (e?.attachment) {
                              setLink({
                                attachment: _default.BASE_URL + e.attachment,
                                contentType: e.content_type,
                              });
                              setShowDoc(true);
                            } else {
                              setId(e?.id);
                              setShowPasswordVerification(true);
                            }
                          }
                        }}
                      />
                    </>
                  ))}
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              {doc?.length <= 0 && (
                <CustomButton
                  btnText="Add Document"
                  className="mb-sm-2"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  marginRight={"10px"}
                  onClick={() => setDocumentModal(true)}
                />
              )}
              {!webLogin && (
                <CustomButton
                  className="mb-sm-2"
                  btnText="Add Web Login"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  marginRight={"10px"}
                  onClick={() => setWebLoginModal(true)}
                />
              )}

              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  onClick={() => handleOpenSureToAdd(null)}
                  disabled={disableCheck(initialRef, data)}
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
      <SureToAdd
        open={sureToAdd.open}
        handleClose={handleCloseSureToAdd}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
    profile: state.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditSerialNumber);
