import "date-fns";
import React from "react";
import CustomModal from "../../../components/Modal/Modal.js";
import afterLoginAnimation from "../../../assets/img/logo/afterLogin.mp4";
import afterLoginAnimationMobile from "../../../assets/img/logo/after-Login-mobile.mp4";
// import afterLoginAnimationMobile from "../../../assets/img/logo/after-Login-mobile.gif";
// import afterLoginAnimation from "../../assets/img/logo/after-Login.mp4";

function AfterLoginAnimation(props) {
  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="100%!important"
        zIndex="100000000000!important"
        inset="0px!important"
        height="fit-content"
        background="#00AAD9"
        innerbackgruond="#00AAD9!important"
        extraclass="shadow-none backgroundDelay"
        bgOpacity="bgTransparent scrollWidthZero"
      >
        <video
          autoPlay
          muted
          width="-webkit-fill-available"
          disablePictureInPicture={true}
          controlsList="nodownload"
          className="d-lg-block d-md-none d-sm-none d-xs-none wFill_available"
        >
          <source src={afterLoginAnimation} type="video/mp4" />
          {/* <source src={afterEmail} type="video/mp4" /> */}
          {/* <source src="movie.ogg" type="video/ogg" /> */}
          Your browser does not support the video tag.
        </video>
        <video
          autoPlay
          muted
          width="400px"
          disablePictureInPicture={true}
          controlsList="nodownload"
          className="d-lg-none d-md-block d-sm-block d-xs-block mx-5 px-5 w-auto"
        >
          <source src={afterLoginAnimationMobile} type="video/mp4" />
          {/* <source src={afterEmail} type="video/mp4" /> */}
          {/* <source src="movie.ogg" type="video/ogg" /> */}
          Your browser does not support the video tag.
        </video>
        {/* <img src={afterLoginAnimationMobile} /> */}
      </CustomModal>
    </>
  );
}

export default AfterLoginAnimation;
