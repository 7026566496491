import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import { useRouteName } from "../../hooks";
import { Link } from "react-router-dom";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
// @material-ui/icons
import { FolderOutlined } from "@material-ui/icons";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import facebook from "../../assets/img/facebook"
import google from "../../assets/img/google"
import color from "../../assets/css/color";

const styles = {
  marginBottomZero: {
    marginBottom: "0",
  },
};

const useStyles = makeStyles(styles);

export default function Notifications() {
  const routeName = useRouteName();
  return (
    <>
      <Grid container wrap="wrap"
        direction="row"
        justifyContent="center"
        alignItems="center">
        <Grid item xs={12} md={12} style={{ marginTop: "25px", }}>
          <Typography variant="h6" component="h3" style={{ color: color.themeColorNew }}>
            {routeName}
          </Typography>
        </Grid>
      </Grid>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={styles.marginBottomZero}>
            <CardHeader color="warning" stats icon>
              <GridContainer >
                <GridItem xs={12} sm={12} md={1} align="left" container alignItems="center">
                  <FolderOutlined style={{ color: "black" }} />
                </GridItem>
                <GridItem xs={12} sm={12} md={7} container alignItems="center">
                  <Typography variant="h6" component="h3" align="left" style={{ color: color.themeColorNew }}>
                    {"Awards"}
                  </Typography>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} container alignItems="center">
                  <Box flexGrow={4} flexDirection="row" alignItems="center">
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                      <img src={facebook} alt="s" width={40} />
                      <img src={google} alt="s" width={40} style={{ marginLeft: "-12px", }} />
                      <Link to="/" style={{ marginLeft: "-20px", }}><AddCircleOutlineIcon style={{ fontSize: 40, }} color="disabled" /></Link>
                      <Typography variant="p" component="p" align="left" style={{ color: color.themeColorNew, fontWeight: "bold", marginLeft: "-8px", }}>
                        {"+2"}
                      </Typography>
                    </div>
                  </Box>
                  <Box flexGrow={2}>
                    <Typography variant="h6" component="h3" align="left" style={{ color: color.themeColorNew, fontWeight: "normal", }}>
                      {"March 5, 2021"}
                    </Typography>
                  </Box>
                  <Box flexGrow={1}>
                    <Link to="/"><InsertLinkIcon color="primary" style={{ transform: "rotate(320deg)", }} /></Link>
                  </Box>
                  <Box flexGrow={1}>
                    <Link to="/"><MoreVertIcon color="primary" /></Link>
                  </Box>
                </GridItem>
              </GridContainer>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={styles.marginBottomZero}>
            <CardHeader color="warning" stats icon>
              <GridContainer >
                <GridItem xs={12} sm={1} md={1} align="left" container alignItems="center">
                  <FolderOutlined style={{ color: "black" }} />
                </GridItem>
                <GridItem xs={12} sm={12} md={7} container alignItems="center">
                  <Typography variant="h6" component="h3" align="left" style={{ color: color.themeColorNew }}>
                    {"Awards"}
                  </Typography>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} container alignItems="center">
                  <Box flexGrow={4} flexDirection="row" alignItems="center">
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                      <img src={facebook} alt="s" width={40} />
                      <img src={google} alt="s" width={40} style={{ marginLeft: "-12px", }} />
                      <Link to="/" style={{ marginLeft: "-20px", }}><AddCircleOutlineIcon style={{ fontSize: 40, }} color="disabled" /></Link>
                      <Typography variant="p" component="p" align="left" style={{ color: color.themeColorNew, fontWeight: "bold", marginLeft: "-8px", }}>
                        {"+2"}
                      </Typography>
                    </div>
                  </Box>
                  <Box flexGrow={2}>
                    <Typography variant="h6" component="h3" align="left" style={{ color: color.themeColorNew, fontWeight: "normal", }}>
                      {"March 5, 2021"}
                    </Typography>
                  </Box>
                  <Box flexGrow={1}>
                    <Link to="/"><InsertLinkIcon color="primary" style={{ transform: "rotate(320deg)", }} /></Link>
                  </Box>
                  <Box flexGrow={1}>
                    <Link to="/"><MoreVertIcon color="primary" /></Link>
                  </Box>
                </GridItem>
              </GridContainer>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={styles.marginBottomZero}>
            <CardHeader color="warning" stats icon>
              <GridContainer >
                <GridItem xs={12} sm={1} md={1} align="left" container alignItems="center">
                  <FolderOutlined style={{ color: color.themeColorNew }} />
                </GridItem>
                <GridItem xs={12} sm={12} md={7} container alignItems="center">
                  <Typography variant="h6" component="h3" align="left" style={{ color: color.themeColorNew }}>
                    {"Awards"}
                  </Typography>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} container alignItems="center">
                  <Box flexGrow={4} flexDirection="row" alignItems="center">
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                      <img src={facebook} alt="s" width={40} />
                      <img src={google} alt="s" width={40} style={{ marginLeft: "-12px", }} />
                      <Link to="/" style={{ marginLeft: "-20px", }}><AddCircleOutlineIcon style={{ fontSize: 40, }} color="disabled" /></Link>
                      <Typography variant="p" component="p" align="left" style={{ color: color.themeColorNew, fontWeight: "bold", marginLeft: "-8px", }}>
                        {"+2"}
                      </Typography>
                    </div>
                  </Box>
                  <Box flexGrow={2}>
                    <Typography variant="h6" component="h3" align="left" style={{ color: color.themeColorNew, fontWeight: "normal", }}>
                      {"March 5, 2021"}
                    </Typography>
                  </Box>
                  <Box flexGrow={1}>
                    <Link to="/"><InsertLinkIcon color="primary" style={{ transform: "rotate(320deg)", }} /></Link>
                  </Box>
                  <Box flexGrow={1}>
                    <Link to="/"><MoreVertIcon color="primary" /></Link>
                  </Box>
                </GridItem>
              </GridContainer>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
