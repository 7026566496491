import * as actions from "../actions/types";
import isEmpty from "lodash/isEmpty";
import localStorage from "redux-persist/es/storage";

export const intialState = {
    sort: "alpha",
    order: "asc"
};

export default function sortReducer(state = intialState, action) {
    switch (action.type) {
        case actions.SET_SORT:
            return {
                sort: action.sort,
                order: action.order
            };
        default:
            return state;
    }
}