import "date-fns";
import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import dashboardRoutes from "../../../routes.js";
import { HistoryService } from "../../../services/history.service.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.js";

const useStyles = makeStyles(styles);

function AddFavorite(props) {
  const classes = useStyles();
  const errRef = useRef(null);
  const history = useHistory();
  const [fav, setFav] = React.useState(null);
  const historyService = new HistoryService();
  const categoies = [];
  dashboardRoutes.forEach((route) => {
    route.submenu.forEach((cat) => {
      categoies.push(cat);
    });
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const handleSubmit = () => {
    setIsLoading(true);
    historyService
      .favouritesCreate(fav)
      .then(() => {
        setIsLoading(false);
        history.push("/");
        props?.handleClose();
      })
      .catch(() => {
        setIsLoading(false);
        history.push("/");
        props?.handleClose();
      });
  };

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Add Favorites"}
          color={color.white}
        />
        <div className={classes.modalBody} ref={errRef}>
          <div className="w-100 d-flex flex-row mb-4">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              You can choose a Category from the dropdown to add it to your
              favorites.
            </Typography>
          </div>
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Categories"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl fullWidth={true}>
                <Select
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={fav ?? "Select Category"}
                  // value={data?.docs?.title}
                  onChange={(e) => {
                    console.log({ value: e.target.value });
                    setFav(e.target.value);
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="Select Category">
                    Select Category
                  </MenuItem>

                  {categoies
                    .sort((a, b) => a?.name?.localeCompare(b?.name))
                    .map((cat, key) => {
                      return <MenuItem value={cat.sub}>{cat.name}</MenuItem>;
                    })}
                  {}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              {!isLoading  ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  disabled={!fav}
                  autoFocus={true}
                  color="#fff"
                  onClick={handleSubmit}
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFavorite);
