import "date-fns";
import React, {useState} from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import { makeStyles } from "@material-ui/core/styles";
import {Checkbox, FormControlLabel, Grid, Typography} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import { Close } from "@material-ui/icons";
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from "@material-ui/core/CircularProgress";
import {Auth} from "../../../services/auth.service";
import {store} from "../../../store/store";
import {clearAuthState, set2FA} from "../../../store/actions/authAction";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import {clearProfileState} from "../../../store/actions/profileAction";


const useStyles = makeStyles(styles);
function ProfileDeletionConfirmed(props) {
    const authService = new Auth();
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState({pwd: "password"})
    const [err, setErr] = useState(null)
    const [data, setData] = useState({
        check: false,
        password: null,
    })
    const handleSubmit = () => {

        setErr(null)
        setLoading(true)
        authService.deleteAccount({password: data.password}).then((res) => {
            setLoading(false)
            console.log({res})
            if(!res?.data?.message){
                window.localStorage.clear();
                store?.dispatch(clearProfileState())
                store?.dispatch(clearAuthState())
                window.location.replace("/auth/user");
                props.handleClose()
            }
            else
            {
                setErr(res?.data?.message)
            }
        }).catch((e) => {
            setLoading(false)
            if(e.response)
            {
                setErr(e?.response?.data?.errors?.full_messages[0])
            }
            else {
                setErr("Internal server error, try again later")
            }
        })
    };


    const handleChange = (event) => {
        setData({...data, [event.target.name]: event.target.value})
    };

    return (
        <>
            <CustomModal
                visible={props.open}
                onClose={props.handleClose}
                width="55%"
                margin="120px 0 40px"
                inset= "15px!important"
            >
                <ModalHeader
                    closeModal={props.handleClose}
                    icon={
                        <Close
                            style={{
                                color: color.white,
                                marginRight: "20px",
                                fontSize: "30px",
                                cursor: "pointer",
                            }}
                        />
                    }
                    heading={"Account Deletion Confirmation"}
                    color={color.white}
                />
                <div className={classes.modalBody}>
                    <Grid
                        container
                        wrap="wrap"
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        {err && (
                            <div className="w-100 d-flex flex-row mb-5">
                                <Typography
                                    variant="p"
                                    component="p"
                                    align="left"
                                    style={{ color: "red", fontWeight: "normal" }}
                                >
                                    {err}
                                </Typography>
                            </div>
                        )}

                      <div className="w-100 d-flex flex-row mb-4 mt-2">
                            <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{color: "red", fontWeight: "bold"}}
                            >
                                *Are you sure you want to delete your TagData account? You won't be receiving alerts or able to use any of our services.

                            </Typography>
                        </div>
                        <Grid item xs={12} sm={12} md={12} className="border-bottom">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="checkedA"
                                        checked={data.check}
                                        style={{ color: "#ffb722" }}
                                        onChange={(e) => setData({...data, check:e.target.checked})}
                                    />
                                }
                                label="I verify that I want to delete my account"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography
                                variant="h6"
                                component="h3"
                                style={{ color: color.themeColorNew }}
                            >
                                {"Password"}
                            </Typography>
                            <FormInput
                                name="password"
                                onChange={(e) => setData({...data, password:e.target.value})}
                                label=""
                                variant="outlined"
                                fullWidth={true}
                                margin="dense"
                                Icon={type.pwd === "text" ? VisibilityIcon : VisibilityOffIcon}
                                iconClick={() =>
                                    setType({
                                        pwd: type.pwd === "password" ? "text" : "password",
                                    })
                                }
                                required={true}
                                placeholder={"enter password"}
                                type={type.pwd}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="text-sm-center" style={{ textAlign: "right" }}>

                            {!loading ? <CustomButton
                                className="me-0 me-sm-2 mb-2 mb-sm-0"
                                type="submit"
                                btnText="Save"
                                textAlign="center"
                                display={"block"}
                                padding="12px 25px"
                                minWidth="113px"
                                background="#0042a8"
                                hoverBg="#0042a8"
                                border="0"
                                disabled={!data.check || data.password === null}
                                borderRadius="8px"
                                color="#fff"
                                onClick={handleSubmit}
                            /> : <CustomButton
                                className="me-0 me-sm-2 mb-2 mb-sm-0"
                                type="submit"
                                btnText={<CircularProgress size={20} color="#DDD" />}
                                textAlign="center"
                                display={"block"}
                                padding="12px 25px"
                                minWidth="113px"
                                background="#0042a8"
                                hoverBg="#0042a8"
                                border="0"
                                borderRadius="8px"
                                color="#fff"
                            />}

                            <CustomButton
                                onClick={() => props.handleClose()}
                                btnText="Cancel"
                                textAlign="center"
                                display={"block"}
                                padding="12px 25px"
                                minWidth="113px"
                                background="transparent"
                                hoverBg="#0042a8"
                                border="1px solid #0042a8"
                                borderRadius="8px"
                                color="#0042a8"
                            />
                        </Grid>
                    </Grid>
                </div>
            </CustomModal>
        </>
    );
}

export default ProfileDeletionConfirmed;
