import "date-fns";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import styles from "../../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { CheckCircle, ErrorRounded, WarningRounded } from "@material-ui/icons";
import CustomModal from "../../../../components/Modal/Modal.js";
import ModalHeader from "../../../../components/Modal/ModalHeader.js";
import { CustomButton } from "../../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import color from "../../../../assets/css/color";
import ReactGA from "react-ga4";

const useStyles = makeStyles(styles);

function SubscriptionCheckout({ open, handleClose, handleClick, data }) {
  const classes = useStyles();

  return (
    <>
      <CustomModal
        visible={open}
        onClose={handleClose}
        width="35%"
        margin="auto"
        inset="15px!important"
      >
        <ModalHeader
          extraclass="p-2"
          headerBgColor={color.themeGreen}
          color="white"
        />
        <div className={classes.modalBody}>
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            className="px-4"
          >
            <div className="w-100 text-center">
              <div className="d-flex justify-content-center align-items-center">
                <Typography
                  variant="h6"
                  component="h3"
                  className="mb-0"
                  style={{ color: "#060d5c", fontWeight: "bold" }}
                >
                  {"Subscription Info"}
                </Typography>
              </div>
              <Grid container wrap="wrap" direction="row" spacing={3}>
                <Grid item xs={12} sm={12} md={6} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Plan:"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className="pb-0">
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {data.subscription.subscription_type}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Status:"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className="pb-0">
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {data.subscription.subscription_status}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Price:"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className="pb-0">
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {`$${data.subscription.due_amount}`}
                  </Typography>
                </Grid>
                {data.subscription.discount_id && (
                  <>
                    <Grid item xs={12} sm={12} md={6} className="pb-0">
                      <Typography
                        variant="h6"
                        component="h3"
                        style={{ color: color.themeColorNew }}
                      >
                        {"Discount Applied:"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className="pb-0">
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                      >
                        {data.discount.discount_applied}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className="pb-0">
                      <Typography
                        variant="h6"
                        component="h3"
                        style={{ color: color.themeColorNew }}
                      >
                        {"Discount Code:"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className="pb-0">
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                      >
                        {data.discount.discount_code}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className="pb-0">
                      <Typography
                        variant="h6"
                        component="h3"
                        style={{ color: color.themeColorNew }}
                      >
                        {"Discount name:"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className="pb-0">
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                      >
                        {data.discount.name}
                      </Typography>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={12} md={6} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Renewal Date:"}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className="pb-0">
                  <Typography
                    variant="p"
                    component="p"
                    style={{ color: color.themeColorNew }}
                  >
                    {new Date(data.subscription.will_renew_at).toLocaleString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              <CustomButton
                btnText={"okay"}
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background={"#4DB500"}
                hoverBg={"#4DB500"}
                border={"2px solid #4DB500"}
                borderRadius="8px"
                color={"#fff"}
                marginRight={"10px"}
                width="100px"
                onClick={handleClick}
              />
            </Grid>
          </Grid>
          {ReactGA.event({
            category: "Subscription",
            action: "Subscribe",
            email: data.email,
            plan: data.subscription.subscription_type,
            name: data.discount ? data.discount.name : "N/A", // Check if data.discount exists
            discount_code: data.discount ? data.discount.discount_code : "N/A", // Check if data.discount exists
            will_renew_at: data.subscription.will_renew_at,
          })}
        </div>
      </CustomModal>
    </>
  );
}

export default SubscriptionCheckout;
