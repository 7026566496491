export const SET_AUTH_USER = "SET_AUTH_USER";
export const SET_AUTH_ERROR = "SET_AUTH_ERROR";
export const CLEAR_AUTH_ERROR = "CLEAR_AUTH_ERROR";
export const SET_AUTH_LOADING = "SET_AUTH_LOADING";
export const GET_PROFILES = "GET_PROFILES";
export const GET_PROFILE_SPECIFIC = "GET_PROFILE_SPECIFIC";
export const CREATE_PROFILE = "CREATE_PROFILE";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const DELETE_PROFILE = "DELETE_PROFILE";
export const LOADING_PROFILE = "LOADING_PROFILE";
export const CLEAR_STATE = "CLEAR_STATE";
export const LOADING = "LOADING";
export const RERENDER_EMPLOYER = "RERENDER_EMPLOYER";
export const RERENDER_AWARD = "RERENDER_AWARD";
export const SEARCH = "SEARCH";
export const SEARCH_QUERY = "SEARCH_QUERY";
export const SEARCH_ERROR = "SEARCH_ERROR";
export const SET_SORT = "SET_SORT";
export const SET_GENDER = "SET_GENDER";
export const IS_EXPIRED = "IS_EXPIRED";
