import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useRef,
} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import { Box, Grid, Typography } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import config from "../../config/default";
import MenuList from "@material-ui/core/MenuList";
import Alerts from "../../containers/Modals/Alerts/Alerts/Alerts.js";
import { CustomButton } from "../CustomButton/Button";
import color from "../../assets/css/color";
import { store } from "../../store/store";
import RevealPassword from "../../containers/Modals/RevealPassword/RevealPassword";
import { PersonalService } from "../../services/personal.service";
import ShareModal from "../../containers/Modals/ShareModal/ShareModal";
import Warning from "../../containers/Modals/Alerts/Warning/Warning";
import SpinnerModal from "../../containers/Modals/Alerts/SpinnerModal/SpinnerModal";
import moment from "moment";
import { truncateString } from "../../Utils/truncateString.js";
import { connect } from "react-redux";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { DocumentService } from "../../services/document.service.js";
import PasswordVerification from "../../containers/Modals/PasswordVerification/PasswordVerification.js";
import FileViewer from "../FileViewer/FileViewer.js";
import AlertPopup from "../../containers/Modals/Alerts/Generic/GenericAlert.js";
import { useHistory } from "react-router-dom";

const CardCategory = (props) => {
  const documentService = new DocumentService();
  const [link, setLink] = React.useState(null);
  const [showDoc, setShowDoc] = React.useState(false);
  const [docId, setDocId] = React.useState(null);
  const [alert, setAlert] = React.useState({
    open: false,
    type: null,
    btnText: null,
    cancelText: null,
    heading: null,
    message: null,
  });
  const [password, setPassword] = useState(null);
  const [showPasswordVerification, setShowPasswordVerification] =
    useState(false);
  const personalService = new PersonalService();
  const [alerts, setAlerts] = React.useState(false);
  const [maskWarning, setMaskWarning] = React.useState(false);
  const [warning, setWarning] = React.useState(false);
  const [id, setID] = React.useState(null);
  const [shareReveal, setShareReveal] = React.useState(false);
  const [msg, setMsg] = React.useState(null);
  const [openReveal, setOpenReveal] = React.useState(false);
  const [sharePayload, setSharePayload] = React.useState([]);
  const [spinner, setSpinner] = React.useState(false);
  const [openShare, setOpenShare] = React.useState(false);
  const [linkedItems, setLinkedItems] = React.useState(false);
  const [cat, setCat] = React.useState(null);
  const { childRef } = props;
  const linkFeatureRef = useRef(null);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const handleOpenAlerts = () => {
    setAlerts(true);
  };
  function humanize(str) {
    var i,
      frags = str.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        linkFeatureRef.current &&
        !linkFeatureRef.current.contains(event.target)
      ) {
        setLinkedItems(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleCloseAlerts = () => {
    setAlerts(false);
  };
  const history = useHistory();
  useEffect(() => {
    childRef.current = share;
  }, [props]);
  const show = () => {
    setSpinner(true);
    documentService
      .show(docId, password)
      .then((res) => {
        setSpinner(false);
        setLink({
          attachment: res.data.document.attachment,
          contentType: res.data.document.content_type,
        });
        setShowDoc(true);
        setShowPasswordVerification(false);
        setPassword(null);
      })
      .catch((err) => {
        setSpinner(false);
        setPassword(null);
        setAlert({
          type: "Error",
          open: true,
          cancelText: "CANCEL",
          heading:
            err?.response && err?.response?.status === 422
              ? "Unauthorized!"
              : "Error!",
          message:
            err?.response && err?.response?.status === 422
              ? "Wrong password! please try again."
              : "An unknown error occurred, please try later.",
        });
      });
  };
  const share = () => {
    setMsg("Processing the information, kindly wait");
    setSpinner(true);
    personalService
      .checkShare()
      .then((res) => {
        if (res.data && res.data.check_share === true) {
          setMsg("Generating your pdf to share, please wait.");
          let data = props?.multiple
            ? props?.ids.map((val) => {
                return {
                  id: val,
                  subcategory: props?.subcategory,
                };
              })
            : [
                {
                  id: props?.data?.id,
                  subcategory: props?.subcategory,
                },
              ];
          personalService
            .shareSub(data)
            .then((res) => {
              setMsg(null);
              setSpinner(false);
              setSharePayload(
                res?.data?.subcategory_pdfs?.map((val) => {
                  return {
                    document_id: val?.id,
                    title: val?.title,
                    profile_id: store.getState().profile.profileInfo.profile.id,
                  };
                })
              );
              setID(res.data?.subcategory_pdfs[0]?.id);
              setCat(res.data?.subcategory_pdfs[0]?.category);
              props.setIds && props.setIds([]);
              setOpenShare(true);
            })
            .catch((err) => {
              console.log({ error: err });
              setMsg(null);
              setSpinner(false);
              setMsg(
                "An unknown error occurred while sharing, please try again later"
              );
              setWarning(true);
            });
        } else {
          setMsg(null);
          setSpinner(false);
          setMsg(
            "Your monthly shares have reached their limit, please upgrade your subscription for unlimited shares!"
          );
          setWarning(true);
        }
      })
      .catch((err) => {
        setMsg(null);
        setSpinner(false);
        setMsg("An unknown error occurred. Please try again later!");
        setWarning(true);
      });
  };

  const handleCancel = () => {
    share();
    setMaskWarning(false);
  };

  const alternate = () => {
    setShareReveal(true);
    setMaskWarning(false);
  };
  const typo = useRef(null);
  const typo2 = useRef(null);
  const desc = useRef(null);
  const typo3 = useRef(null);
  useEffect(() => {
    if (props?.heading?.length >= 25) {
      typo.current.setAttribute("data-tooltip", props?.heading);
      typo.current.setAttribute("data-tooltip-location", "top");
    }
    if (props?.heading2?.length >= 25) {
      typo2.current.setAttribute("data-tooltip", props?.heading2);
      typo2.current.setAttribute("data-tooltip-location", "top");
    }
    if (props?.heading3?.length >= 25) {
      typo3.current.setAttribute("data-tooltip", props?.heading3);
      typo3.current.setAttribute("data-tooltip-location", "top");
    }
    if (props?.desc?.length >= 25) {
      desc.current.setAttribute("data-tooltip", props?.description);
      desc.current.setAttribute("data-tooltip-location", "top");
    }
  }, [props?.sort]);
  let utcDate = props?.data?.updated_at
    ? moment(
        props?.data?.updated_at?.split("-")[2] +
          "-" +
          props?.data?.updated_at?.split("-")[0] +
          "-" +
          props?.data?.updated_at?.split("-")[1],
        "YYYY-MM-DD"
      ).toDate()
    : moment(
        props?.data?.created_at?.split("-")[2] +
          "-" +
          props?.data?.created_at?.split("-")[0] +
          "-" +
          props?.data?.created_at?.split("-")[1],
        "YYYY-MM-DD"
      ).toDate();

  return (
    <>
      {alert.open && (
        <AlertPopup
          btnText={alert.btnText}
          cancelText={alert.cancelText}
          open={alert.open}
          type={alert.type}
          heading={alert.heading}
          message={alert.message}
          isLoading={false}
          handleClose={() => {
            setAlert({
              open: false,
              btnText: null,
              cancelText: null,
              heading: null,
              message: null,
              type: null,
            });
          }}
          handleClick={() => {
            setAlert({
              open: false,
              btnText: null,
              cancelText: null,
              heading: null,
              message: null,
              type: null,
            });
          }}
        />
      )}
      {spinner && <SpinnerModal open={spinner} msg={"Loading"} />}
      {showDoc && (
        <FileViewer
          contentType={link?.contentType}
          link={
            link?.attachment?.includes("http")
              ? link?.attachment
              : config.BASE_URL + link?.attachment
          }
          open={showDoc}
          handleClose={() => setShowDoc(false)}
        />
      )}
      {showPasswordVerification && (
        <PasswordVerification
          open={showPasswordVerification}
          handleClose={() => {
            setShowPasswordVerification(false);
            setId(null);
          }}
          handleChange={(e) => setPassword(e)}
          handleSubmit={() => {
            show();
          }}
        />
      )}
      {openReveal || shareReveal ? (
        <input style={{ position: "absolute", top: -200 }} />
      ) : null}
      <Card
        extraclass="mb-3 mb-lg-0 py-1 mt-3"
        style={{
          minHeight: "4rem",
          justifyContent: "center",
        }}
      >
        <CardHeader color="warning" stats icon>
          {warning && (
            <Warning
              open={warning}
              handleClose={() => setWarning(false)}
              heading={"Error"}
              one={true}
              message={msg}
              onClick={() => {
                setWarning(false);
              }}
            />
          )}
          {maskWarning && (
            <Warning
              open={maskWarning}
              handleClose={() => setMaskWarning(false)}
              heading={"Warning"}
              btnText={"No"}
              handleCancel={handleCancel}
              one={false}
              alternate={alternate}
              color={"#060D5C"}
              message={
                "Do you want to unmask the sensitive information in the share?"
              }
              onClick={() => {
                setMaskWarning(false);
              }}
            />
          )}

          {shareReveal && (
            <RevealPassword
              open={shareReveal}
              share={true}
              callShare={share}
              handleClose={() => setShareReveal(false)}
              subcategory_name={
                props?.category === "web"
                  ? "WebLogin"
                  : props?.category === "email"
                  ? "EmailAccount"
                  : props?.category === "retirementFund"
                  ? "RetirementFund"
                  : props?.category === "personal"
                  ? "PersonalInformation"
                  : props?.category === "mortgage"
                  ? "Mortgage"
                  : props?.category === "cashAccount"
                  ? "CashAccount"
                  : props?.category === "marginAccount"
                  ? "MarginAccount"
                  : props?.category === "marketableSecurity"
                  ? "MarketableSecurity"
                  : props?.category === "nonmarketableSecurity"
                  ? "NonmarketableSecurity"
                  : "Expense"
              }
              password={
                props?.category === "web"
                  ? props?.data?.password_ciphertext
                  : props?.category === "mortgage"
                  ? props?.data?.account_number_ciphertext
                  : props?.category === "email"
                  ? props?.data?.password_ciphertext
                  : props?.category === "personal"
                  ? props?.data?.social_security_number_ciphertext
                  : props?.category === "cashAccount"
                  ? props?.data?.account_number_ciphertext
                  : props?.data?.account_number_ciphertext
              }
              id={props?.data?.id}
            />
          )}
          {spinner ? <SpinnerModal open={spinner} msg={msg} /> : null}
          <Grid
            container
            alignItems={"center"}
            justifyContent="space-between"
            className="position-relative"
          >
            {openShare && (
              <ShareModal
                open={openShare}
                payload={sharePayload}
                multiple={props?.multiple}
                setMultiple={props.setMultiple}
                id={id}
                // title={
                //   store.getState().profile.profileInfo.profile?.first_name +
                //   "'s " +
                //   cat +
                //   " information"
                // }
                title={
                  store.getState().profile?.profileInfo?.profile
                    ?.profile_type === "person"
                    ? store.getState().profile.profileInfo.profile?.first_name +
                      "'s " +
                      cat +
                      " information"
                    : store.getState().profile?.profileInfo?.profile
                        ?.business_name +
                      "'s " +
                      cat +
                      " information"
                }
                category={cat ? cat : ""}
                handleClose={() => setOpenShare(false)}
              />
            )}
            <GridItem xs={12} sm={12} md={8} alignItems="center">
              <GridContainer>
                {/* <NotificationsActiveIcon
                  style={{ color: "#F50057", marginRight: "0" }}
                  className={props?.data?.upcoming ? "bell" : ""}
                  fontSize="small"
                /> */}
                <GridItem
                  xs={12}
                  sm={12}
                  md={props?.description ? 3 : !props?.heading2 ? 3 : 4}
                  container
                  alignItems="center"
                >
                  {props?.data &&
                    props?.data?.alert_attributes &&
                    props?.data?.alert_attributes.status === "active" && (
                      <NotificationsActiveIcon
                        style={{
                          color: "#F50057",
                          marginRight: "4",
                          marginLeft: "0",
                        }}
                        className={props?.data?.upcoming ? "bell" : ""}
                      />
                    )}
                  <Typography
                    variant="p"
                    onClick={() => props.handleClickInfo(props.data?.id)}
                    component="p"
                    ref={typo}
                    className=""
                    id="heading"
                    align="left"
                    style={{
                      color: "#060d5c",
                      cursor: "pointer",
                      fontWeight: "500",
                    }}
                  >
                    {props?.heading?.length <= 10
                      ? props?.heading
                      : truncateString(props?.heading, 10)}
                  </Typography>
                  {props?.subcategory === "WebLogin" ||
                  props?.subcategory === "LoyaltyProgram" ? (
                    <>
                      {props?.data?.image && (
                        <img
                          className="category-icon ms-2"
                          height={"40"}
                          width={"40"}
                          src={config.BASE_URL + props?.data?.image}
                        />
                      )}
                    </>
                  ) : null}
                </GridItem>
                {props?.description && (
                  <GridItem
                    xs={12}
                    sm={12}
                    md={3}
                    container
                    alignItems="center"
                  >
                    <Typography
                      variant="p"
                      onClick={() => props.handleClickInfo(props.data?.id)}
                      component="p"
                      ref={typo2}
                      className=""
                      id="heading"
                      align="left"
                      style={{
                        color: "#060d5c",
                        cursor: "pointer",
                        fontWeight: "500",
                      }}
                    >
                      {props?.description?.length <= 25
                        ? props?.description
                        : truncateString(props?.description, 25)}
                    </Typography>
                  </GridItem>
                )}
                {props?.heading2 && (
                  <GridItem
                    xs={12}
                    sm={12}
                    md={props?.description ? 3 : !props?.heading3 ? 4 : 3}
                    container
                    alignItems="center"
                  >
                    {props?.heading2 && (
                      <Typography
                        variant="p"
                        onClick={() => props.handleClickInfo(props.data?.id)}
                        component="p"
                        ref={typo2}
                        className=""
                        id="heading"
                        align="left"
                        style={{
                          color: "#060d5c",
                          cursor: "pointer",
                          fontWeight: "500",
                        }}
                      >
                        {props?.heading2?.length <= 15
                          ? props?.heading2
                          : truncateString(props?.heading2, 15)}
                      </Typography>
                    )}
                  </GridItem>
                )}
                {props?.heading3 && (
                  <GridItem
                    xs={12}
                    sm={12}
                    md={3}
                    container
                    alignItems="center"
                  >
                    {props?.heading3 && (
                      <Typography
                        variant="p"
                        onClick={() => props.handleClickInfo(props.data?.id)}
                        component="p"
                        ref={typo3}
                        className=""
                        id="heading"
                        align="left"
                        style={{
                          color: "#060d5c",
                          cursor: "pointer",
                          fontWeight: "500",
                        }}
                      >
                        {props?.heading3?.length <= 25
                          ? props?.heading3
                          : truncateString(props?.heading3, 25)}
                      </Typography>
                    )}
                  </GridItem>
                )}
                {props?.show && (
                  <GridItem
                    xs={8}
                    sm={8}
                    md={props?.heading2 && props?.heading3 ? 3 : 4}
                  >
                    <>
                      {store.getState().auth.user.masking ? (
                        <>
                          {props?.category === "web" ||
                          props?.category === "email" ||
                          props?.category === "personal" ||
                          props?.category === "mortgage" ||
                          props?.category === "cashAccount" ||
                          props?.category === "marginAccount" ||
                          props?.category === "retirementFund" ||
                          props?.category === "marketableSecurity" ||
                          props?.category === "nonmarketableSecurity" ||
                          props?.category === "expense" ? (
                            <div className="border border-1 rounded-pill d-flex justify-content-center align-items-center h-100 overflow-hidden">
                              <input
                                disabled
                                className="border-0 w-100 placholderText ps-3 h-100"
                                value={
                                  props?.category === "web"
                                    ? props?.data?.password_ciphertext
                                    : props?.category === "email"
                                    ? props?.data?.password_ciphertext
                                    : props?.category === "personal"
                                    ? props?.data
                                        ?.social_security_number_ciphertext
                                    : props?.data?.account_number_ciphertext
                                }
                              />
                              <CustomButton
                                type="submit"
                                fullWidth={false}
                                btnText="Reveal"
                                textAlign="center"
                                display={"block"}
                                marginBottom="0"
                                padding="8px 25px"
                                onClick={() => {
                                  setOpenReveal(true);
                                  // props.handleClose()
                                }}
                                background="#0042a8"
                                hoverBg="#0042a8"
                                border="0"
                                borderRadius="50rem"
                                color="#fff"
                                className="shadow-none"
                              />
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <>
                          {props?.category === "web" ||
                          props?.category === "email" ||
                          props?.category === "cashAccount" ||
                          props?.category === "personal" ||
                          props?.category === "mortgage" ||
                          props?.category === "marginAccount" ||
                          props?.category === "retirementFund" ||
                          props?.category === "marketableSecurity" ||
                          props?.category === "nonmarketableSecurity" ||
                          props?.category === "expense" ? (
                            <div className="ps-3 border border-1 rounded-pill d-flex justify-content-center align-items-center w-auto">
                              <input
                                disabled
                                className="border-0 w-100 placeholderText"
                                value={
                                  props?.category === "web"
                                    ? props?.data?.password_ciphertext ||
                                      props?.data?.password
                                    : props?.category === "email"
                                    ? props?.data?.password_ciphertext ||
                                      props?.data?.password
                                    : props?.category === "personal"
                                    ? props?.data
                                        ?.social_security_number_ciphertext ||
                                      props?.data?.social_security_number
                                    : props?.data?.account_number_ciphertext ||
                                      props?.data?.account_number
                                }
                              />
                            </div>
                          ) : null}
                        </>
                      )}
                      {openReveal && (
                        <RevealPassword
                          closeParent={props.handleClose}
                          open={openReveal}
                          handleClose={() => setOpenReveal(false)}
                          subcategory_name={
                            props?.category === "web"
                              ? "WebLogin"
                              : props?.category === "email"
                              ? "EmailAccount"
                              : props?.category === "personal"
                              ? "PersonalInformation"
                              : props?.category === "marginAccount"
                              ? "MarginAccount"
                              : props?.category === "cashAccount"
                              ? "CashAccount"
                              : props?.category === "retirementFund"
                              ? "RetirementFund"
                              : props?.category === "mortgage"
                              ? "Mortgage"
                              : props?.category === "marketableSecurity"
                              ? "MarketableSecurity"
                              : props?.category === "nonmarketableSecurity"
                              ? "NonmarketableSecurity"
                              : "Expense"
                          }
                          password={
                            props?.category === "web"
                              ? props?.data?.password_ciphertext
                              : props?.category === "email"
                              ? props?.data?.password_ciphertext
                              : props?.category === "personal"
                              ? props?.data?.social_security_number_ciphertext
                              : props?.data?.account_number_ciphertext
                          }
                          id={props?.data?.id}
                        />
                      )}
                    </>
                  </GridItem>
                )}
              </GridContainer>
            </GridItem>

            <GridItem xs={12} sm={12} md={4} container alignItems="center">
              <Box
                flexGrow={2}
                onClick={() => props.handleClickInfo(props.data?.id)}
              >
                <Typography
                  variant="p"
                  component="p"
                  align="left"
                  style={{
                    color: "#060d5c",
                    fontWeight: "500",
                    cursor: "pointer",
                  }}
                >
                  {utcDate &&
                    utcDate != undefined &&
                    moment(utcDate).format("MMMM DD, YYYY")}
                </Typography>
              </Box>
              <div className="dotsInMobile d-flex flex-row align-items-center end-0">
                {props?.data?.linked_items || props?.data.documents ? (
                  <Box
                    flexGrow={1}
                    className="position-relative"
                    ref={linkFeatureRef}
                    style={{ marginRight: "-15px" }}
                  >
                    {/* <Link to="/"> */}
                    <InsertLinkIcon
                      // color="primary"
                      onClick={() => setLinkedItems(!linkedItems)}
                      style={{
                        transform: "rotate(320deg)",
                        color: "#0042a8",
                        cursor: "pointer",
                      }}
                    />
                    {linkedItems && (
                      <div className="position-absolute linked_document_main text-center">
                        <div className="linked_document_header p-1">
                          <p className="m-0 fw-bold">Linked Items</p>
                        </div>
                        <div className="pb-3">
                          <div className="linked_document_content me-3">
                            <p
                              className="m-0 text-start px-3 border-bottom"
                              style={{ color: "#D6D6D6" }}
                            >
                              Documents
                            </p>
                            <div className="px-3 py-2">
                              <div
                                className="d-flex flex-column mb-1"
                                style={{ justifyContent: "flex-start" }}
                              >
                                {props?.data?.documents &&
                                  props?.data?.documents?.map((doc, index) => {
                                    return (
                                      <div
                                        key={index}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          if (doc?.attachment) {
                                            setLink({
                                              attachment: doc.attachment,
                                              contentType: doc.content_type,
                                            });
                                            setShowDoc(true);
                                          } else {
                                            setDocId(doc?.id);
                                            setShowPasswordVerification(true);
                                          }
                                        }}
                                      >
                                        <p
                                          className="m-0 small"
                                          style={{
                                            color: "#060d5c",
                                            fontWeight: 400,
                                          }}
                                        >
                                          {doc?.title}
                                        </p>
                                        <InsertLinkIcon
                                          className="m-0"
                                          style={{
                                            transform: "rotate(320deg)",
                                            color: "#0042a8",
                                            height: 24,
                                          }}
                                        />
                                      </div>
                                    );
                                  })}
                              </div>
                              <div className="d-flex flex-row justify-content-between"></div>
                            </div>
                            <p
                              className="m-0 text-start px-3 border-bottom"
                              style={{ color: "#D6D6D6" }}
                            >
                              Items
                            </p>
                            <div className="px-3 py-2">
                              <div
                                className="d-flex flex-column mb-1"
                                style={{ justifyContent: "flex-start" }}
                              >
                                {props?.data?.linked_items &&
                                  Object.keys(props?.data?.linked_items)?.map(
                                    (item, index) => {
                                      return (
                                        <div
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            // let url = new URL(
                                            //   props?.data?.linked_items[item].url
                                            // );
                                            let path = props?.data
                                              ?.linked_items[item].length
                                              ? props?.data?.linked_items[
                                                  item
                                                ][0]?.url
                                              : props?.data?.linked_items[item]
                                                  ?.url;
                                            history.push("/" + path);
                                          }}
                                        >
                                          {" "}
                                          <p
                                            className="m-0 small"
                                            style={{
                                              color: "#060d5c",
                                              fontWeight: 400,
                                            }}
                                          >
                                            {humanize(item)} -{" "}
                                            {props?.data?.linked_items?.[item]
                                              ?.insurance_provider ||
                                              props?.data?.linked_items?.[
                                                item
                                              ][0]?.brokerage_company ||
                                              props?.data?.linked_items?.[
                                                item
                                              ][0]?.mortgage_type ||
                                              props?.data?.linked_items?.[
                                                item
                                              ][0]?.description ||
                                              props?.data?.linked_items?.[
                                                item
                                              ][0]?.service_type ||
                                              ""}
                                          </p>
                                          <InsertLinkIcon
                                            className="m-0"
                                            style={{
                                              transform: "rotate(320deg)",
                                              color: "#0042a8",
                                              height: 24,
                                            }}
                                          />
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* </Link> */}
                  </Box>
                ) : null}
                <Box
                  flexGrow={1}
                  style={{ zIndex: "100000000!important" }}
                  // sx={{ position: "absolute", top: "0", right: "0" }}
                >
                  <MoreVertIcon
                    aria-controls={
                      props.openDropdown ? "split-button-menu" : undefined
                    }
                    aria-expanded={props.openDropdown ? "true" : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={props.handleToggle}
                    style={{
                      cursor: "pointer",
                      position: "relative",
                      margin: "0",
                      color: "#0042a8",
                    }}
                  />
                  {props?.data?.id === props?.currId && (
                    <Popper
                      open={props.openDropdown}
                      anchorEl={props?.anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                      style={{
                        position: "absolute",
                        top: "45px",
                        right: "0",
                        left: "unset",
                        zIndex: "1",
                      }}
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom",
                            zIndex: 1,
                          }}
                        >
                          <Paper>
                            <ClickAwayListener
                              onClickAway={props.handleCloseDropdown}
                            >
                              <MenuList id="split-button-menu">
                                {props?.subcategory !== "PersonalInformation" &&
                                  props?.subcategory !== "Wellness" && (
                                    <MenuItem
                                      onClick={(event) => {
                                        props?.setMultiple(true);
                                        props?.setIds([
                                          ...props?.ids,
                                          props?.data?.id,
                                        ]);
                                        props?.handleCloseDropdown();
                                      }}
                                    >
                                      <div>Select</div>
                                    </MenuItem>
                                  )}
                                <MenuItem
                                  onClick={() => {
                                    if (
                                      store.getState().auth?.user?.masking ===
                                      true
                                    ) {
                                      props?.category === "web" ||
                                      props?.category === "email" ||
                                      props?.category === "cashAccount" ||
                                      props?.category === "mortgage" ||
                                      props?.category === "marginAccount" ||
                                      props?.category ===
                                        "marketableSecurity" ||
                                      props?.category === "retirementFund" ||
                                      props?.category ===
                                        "nonmarketableSecurity" ||
                                      props?.category === "personal" ||
                                      props?.category === "expense"
                                        ? setMaskWarning(true)
                                        : share();
                                    } else {
                                      share();
                                    }
                                  }}
                                >
                                  <div>Share</div>
                                </MenuItem>
                                <MenuItem
                                  onClick={(event) => {
                                    props.handleOpenEdit(props?.data);
                                  }}
                                >
                                  <div>Edit</div>
                                </MenuItem>
                                <MenuItem onClick={() => handleOpenAlerts()}>
                                  <div>Delete</div>
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  )}
                </Box>
              </div>
              {props?.multiple && (
                <div
                  className={"checkbox-round"}
                  style={{ marginLeft: "2rem" }}
                >
                  <input
                    id={"checkbox" + props?.data?.id}
                    type={"checkbox"}
                    checked={props?.ids.includes(props?.data?.id)}
                    style={{
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                    }}
                    onChange={(e) => {
                      if (props?.ids.includes(props?.data?.id)) {
                        props?.setIds(
                          props?.ids?.filter((f) => f != props?.data?.id)
                        );
                        if (props?.ids?.length === 1) {
                          props?.setMultiple(false);
                        }
                      } else {
                        props?.setIds([...props?.ids, props?.data?.id]);
                      }
                    }}
                  />
                  <label htmlFor={"checkbox" + props?.data?.id}></label>
                </div>
              )}
            </GridItem>
          </Grid>
        </CardHeader>
      </Card>
      <Alerts
        open={alerts}
        handleClose={handleCloseAlerts}
        handleCloseDropdown={props?.handleCloseDropdown}
        handleDelete={props?.handleDelete}
        currId={props?.currId}
      />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    sort: state.sort?.sort,
  };
};

export default connect(mapStateToProps)(CardCategory);
