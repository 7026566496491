import React, {useState} from "react";
import {Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography,} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import {FormInput} from "../../../components/FormInput/FormInput.js";
import {CustomButton} from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import {PersonalService} from "../../../services/personal.service";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs";

const useStyles = makeStyles(styles);

function EditPermissions(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [allowDownload, setAllowDownload] = React.useState("yes");
    const [checkA, setCheckA] = React.useState(false);
    const [checkB, setCheckB] = React.useState(false);
    const [emailNotification, setEmailNotification] = React.useState("yes");
    const [data, setData] = React.useState({
        expiration_date:
            props?.expiration_date?.split("-")[2] +
            "-" +
            props?.expiration_date?.split("-")[0] +
            "-" +
            props?.expiration_date?.split("-")[1],
        allow_download: props.allow_download,
        terms: props.terms,
        notify_recipient_when_permissions_change:
        props.notify_recipient_when_permissions_change,
        notify_when_recipient_interacts: props.notify_when_recipient_interacts,
        require_authentication: props.require_authentication,
    });
    const personalService = new PersonalService();
    const classes = useStyles();

    const [err, setErr] = useState(null);

    const handleSubmit = async () => {
        setErr(null);
        setIsLoading(true);
        try {
            let response = await personalService.editShare(data, props.id);

            props.handleClose();
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setErr(
                e.response.data && e.response.data.error
                    ? e.response.data.error
                    : e.response.data.message.share
                        ? e.response.data.message.share[0]
                        : "something went wrong, please try later"
            );
            console.log({shareError: e.response});
        }
    };

    return (
        <>
            <CustomModal
                visible={props.open}
                onClose={props.handleClose}
                width="35%" /*margin="250px 0 40px"*/
                inset="15px!important"
            >
                <ModalHeader
                    closeModal={props.handleClose}
                    icon={
                        <Close
                            style={{
                                color: color.white,
                                marginRight: "20px",
                                fontSize: "30px",
                                cursor: "pointer",
                            }}
                        />
                    }
                    heading={"Edit Share Permissions"}
                    color={color.white}
                />
                <div className={classes.modalBody}>
                    {err && (
                        <div className="w-100 d-flex flex-row mb-5">
                            <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{color: "red", fontWeight: "normal"}}
                            >
                                {err}
                            </Typography>
                        </div>
                    )}
                    <Grid
                        container
                        wrap="wrap"
                        direction="row"
                        justifyContent="center"
                        spacing={3}
                    >
                        <Grid item xs={12} sm={4} md={4}>
                            <Typography
                                variant="h6"
                                component="h3"
                                style={{color: color.themeColorNew}}
                            >
                                Require Authentication
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                            <FormControl component="fieldset" className="d-flex flex-row">
                                <RadioGroup
                                    value={data.require_authentication}
                                    onChange={() => {
                                        setAllowDownload("yes");
                                        setData({...data, require_authentication: true});
                                    }}
                                    className="flex-row align-items-center me-3"
                                >
                                    <FormControlLabel
                                        className="m-0"
                                        value={true}
                                        control={<Radio style={{color: "#ffb722"}}/>}
                                    />
                                    <Typography
                                        variant="p"
                                        component="p"
                                        style={{color: color.themeColorNew}}
                                    >
                                        {"Yes"}
                                    </Typography>
                                </RadioGroup>
                                <RadioGroup
                                    aria-label="gender"
                                    name="status"
                                    value={data.require_authentication}
                                    onChange={() => {
                                        setAllowDownload("no");
                                        setData({...data, require_authentication: false});
                                    }}
                                    className="flex-row align-items-center"
                                >
                                    <FormControlLabel
                                        className="m-0"
                                        value={false}
                                        control={<Radio style={{color: "#ffb722"}}/>}
                                    />
                                    <Typography
                                        variant="p"
                                        component="p"
                                        style={{color: color.themeColorNew}}
                                    >
                                        {"No"}
                                    </Typography>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Typography
                                variant="h6"
                                component="h3"
                                style={{color: color.themeColorNew}}
                            >
                                Expiration Date
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                            <FormInput
                                id="start_date"
                                variant="outlined"
                                fullWidth={true}
                                margin="dense"
                                required={true}
                                type="date"
                                value={data.expiration_date}
                                placeholder={"Ex. 12/01/1992"}
                                onChange={(event) =>
                                    setData({
                                        ...data,
                                        expiration_date: new Date(event.target.value)
                                            .toISOString()
                                            .slice(0, 10),
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Typography
                                variant="h6"
                                component="h3"
                                style={{color: color.themeColorNew}}
                            >
                                Allow Downloads
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                            <FormControl component="fieldset" className="d-flex flex-row">
                                <RadioGroup
                                    value={data.allow_download}
                                    onChange={() => {
                                        setAllowDownload("yes");
                                        setData({...data, allow_download: true});
                                    }}
                                    className="flex-row align-items-center me-3"
                                >
                                    <FormControlLabel
                                        className="m-0"
                                        value={true}
                                        control={<Radio style={{color: "#ffb722"}}/>}
                                    />
                                    <Typography
                                        variant="p"
                                        component="p"
                                        style={{color: color.themeColorNew}}
                                    >
                                        {"Yes"}
                                    </Typography>
                                </RadioGroup>
                                <RadioGroup
                                    aria-label="gender"
                                    name="status"
                                    value={data.allow_download}
                                    onChange={() => {
                                        setAllowDownload("no");
                                        setData({...data, allow_download: false});
                                    }}
                                    className="flex-row align-items-center"
                                >
                                    <FormControlLabel
                                        className="m-0"
                                        value={false}
                                        control={<Radio style={{color: "#ffb722"}}/>}
                                    />
                                    <Typography
                                        variant="p"
                                        component="p"
                                        style={{color: color.themeColorNew}}
                                    >
                                        {"No"}
                                    </Typography>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Typography
                                variant="h6"
                                component="h3"
                                style={{color: color.themeColorNew}}
                            >
                                Additional Terms
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                            <FormInput
                                onChange={(e) => setData({...data, terms: e.target.value})}
                                variant="outlined"
                                fullWidth={true}
                                margin="dense"
                                value={data.terms}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Typography
                                variant="h6"
                                component="h3"
                                style={{color: color.themeColorNew}}
                            >
                                Email Notifications
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.notify_recipient_when_permissions_change}
                                        name="checkedA"
                                        onChange={(e) => {
                                            setCheckA(e.target.checked);
                                            setData({
                                                ...data,
                                                notify_recipient_when_permissions_change:
                                                e.target.checked,
                                            });
                                        }}
                                        style={{color: "#ffb722"}}
                                    />
                                }
                                label="Notify Recepient When Permissions Change"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={data.notify_when_recipient_interacts}
                                        name="checkedA"
                                        onChange={(e) => {
                                            setCheckB(e.target.checked);
                                            setData({
                                                ...data,
                                                notify_when_recipient_interacts: e.target.checked,
                                            });
                                        }}
                                        style={{color: "#ffb722"}}
                                    />
                                }
                                label="Notify me when the recipient interacts with this document"
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            className="text-sm-center"
                            style={{textAlign: "right"}}
                        >
                            {!isLoading ? (
                                <CustomButton
                                    type="submit"
                                    btnText="Save"
                                    textAlign="center"
                                    display={"block"}
                                    padding="8px 25px"
                                    background="#0042a8"
                                    hoverBg="#0042a8"
                                    border="0"
                                    borderRadius="8px"
                                    color="#fff"
                                    onClick={handleSubmit}
                                />
                            ) : (
                                <CustomButton
                                    btnText={<CircularProgress size={20} color="#DDD"/>}
                                    type="submit"
                                    textAlign="center"
                                    display={"block"}
                                    padding="8px 25px"
                                    background="#0042a8"
                                    hoverBg="#0042a8"
                                    border="0"
                                    borderRadius="8px"
                                    color="#fff"
                                />
                            )}
                        </Grid>
                    </Grid>
                </div>
            </CustomModal>
        </>
    );
}

export default EditPermissions;
