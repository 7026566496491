import React from "react";
import { Grid } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import color from "../../assets/css/color";
import CustomModal from "../Modal/Modal";
import ModalHeader from "../Modal/ModalHeader.js";
import { makeStyles } from "@material-ui/core/styles";
import config from "../../config/default";
import styles from "../../assets/jss/material-dashboard-react/components/AccountSettingTabs";

const useStyles = makeStyles(styles);

function FileViewer(props) {
  const classes = useStyles();

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="65%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Document Viewer"}
          color={color.white}
        />
        <div className={classes.modalBody}>
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            {props?.contentType?.includes("image") ? (
              <img src={props?.link} className="img-fluid" />
            ) : (
              <embed
                // allowfullscreen
                src={props.link}
                title="description"
                width="100%"
                height="800"
              />
            )}
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

export default FileViewer;
