import React, { useEffect, useState } from "react";
import { Grid, MenuItem, Select, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { PersonalService } from "../../../services/personal.service";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs";
import {
  FormControlLabel,
  RadioGroup,
  FormControl,
  Radio,
  useTheme,
} from "@material-ui/core";
const useStyles = makeStyles(styles);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function SectionIndex(props) {
  const theme = useTheme();
  function getStyles(theme) {
    return {
      fontWeight: theme.typography.fontWeightMedium,
    };
  }
  const classes = useStyles();
  const personalService = new PersonalService();
  const [section, setSection] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [err, setErr] = React.useState(null);

  const handleSubmit = async () => {
    setErr(null);
    setIsLoading(true);
    const data = {
      section_id: section,
    };
    try {
      let response = await personalService.editWebLogins(data, props?.id);
      setIsLoading(false);
      props?.fetchData();
      props?.handleClose();
    } catch (e) {
      setIsLoading(false);
      setErr(e.response ? e?.response?.data?.message : "something went wrong");
    }
  };

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Select Folder"}
          color={color.white}
        />
        <div className={classes.modalBody}>
          {err && (
            <div className="w-100 d-flex flex-row mb-5">
              <Typography
                variant="p"
                component="p"
                align="left"
                style={{ color: "red", fontWeight: "normal" }}
              >
                {err}
              </Typography>
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Folders *"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="state"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  // value={data?.relation}
                  onChange={(e) => setSection(e.target.value)}
                  name="relation"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled>Select Folder</MenuItem>
                  {props?.sections.map((sec, key) => {
                    return (
                      <MenuItem
                        value={sec.id}
                        key={key}
                        style={getStyles(theme)}
                      >
                        {sec.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Add"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  disabled={!section}
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  onClick={handleSubmit}
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

export default SectionIndex;
