export const cashFlowHtml = (doc) => {
    const monthlyExpenseFn = () => {

        let incomes= ""
        doc?.incomes?.length > 0 ? doc?.incomes?.forEach((e) => {
            incomes += `<div>
            <p><strong>Income Description - </strong>${e?.income_type}</p>
        <p><strong>Monthly Earning - </strong>${e?.monthly_earnings}</p>
    </div>`



        }) : <div></div>
        return {incomes}
    }
    const monthlyIncomeFn = () => {

        let expenses= ""
        doc?.expenses?.length > 0 ? doc?.expenses?.forEach((e) => {
            expenses += `<div>
         <p><strong>Expense Type - </strong>${e?.expense_type}</p>
        <p><strong>Amount - </strong>${e?.expense_amount}</p>
         </div>`



        }) : <div></div>
        return {expenses}
    }
    const {expenses} = monthlyExpenseFn()
    const {incomes} = monthlyIncomeFn()
    let html = `<h1>Monthly Income</h1>
 ${incomes}
<h1>Monthly Expense</h1>
${expenses}

`
    return {html}
}