import "date-fns";
import React from "react";
import {makeStyles} from '@material-ui/core/styles';
// import { makeStyles } from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import {CustomButton} from "../../../components/CustomButton/Button.js";
import {Close} from "@material-ui/icons";
import {Auth} from "../../../services/auth.service";
import {DocumentService} from "../../../services/document.service";

const useStyles = makeStyles(styles);

function TermsModal(props) {
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)
    const authService = new Auth();
    const documentService = new DocumentService()
    const handleClick = async () => {
        setError(null)
        setLoading(true)
        try {
            const response = await authService.license(true, props?.profile_id, props?.share_id)
            const res = await documentService.receivedShow(props?.id)
            setLoading(false)

            props.handleClose()
            if (props?.content_type?.includes("image") || props?.content_type?.includes("pdf")) {
                props.setOpenFile(true)
            } else {
                window.open(props?.link, "_blank")
            }
        } catch (e) {
            setLoading(false)
            setError("An unknown error has occurred, please try again in few minutes")
        }
    }
    const classes = useStyles();
    return (
        <>
            <CustomModal
                visible={props.open}
                onClose={props.handleClose}
                width="55%"
                margin="120px 0 40px"
                inset="15px!important"
            >
                <ModalHeader
                    closeModal={props.handleClose}
                    icon={
                        <Close
                            style={{
                                color: color.white,
                                marginRight: "20px",
                                fontSize: "30px",
                                cursor: "pointer",
                            }}
                        />
                    }
                    heading={"License Agreement"}
                    color={color.white}
                />
                <div className={classes.modalBody}>
                    <Grid
                        container
                        wrap="wrap"
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >

                        <div className="w-100 d-flex flex-column mb-4 mt-2">
                            <Typography
                                variant="h6"
                                component="p"
                                align="left"
                                style={{color: "red", fontWeight: "bold"}}
                            >
                                Hi, {props?.shared_by_name} has granted you the access
                                to {props?.shared_by_name}'s {props?.title}.
                            </Typography>
                            <Typography
                                variant="h6"
                                component="p"
                                align="left"
                                style={{color: "red", fontWeight: "bold"}}
                            >
                                In order to view this information you must accept {props?.shared_by_name}'s
                                license agreement.
                            </Typography>
                        </div>
                        {error && <div className="w-100 d-flex flex-row mb-2 mt-2">
                            <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{color: "red", fontWeight: "bold"}}
                            >
                                {error}
                            </Typography>
                        </div>}
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography
                                variant="h6"
                                component="h3"
                                style={{color: color.themeColorNew}}
                            >
                                {"Can you download?"}
                            </Typography>
                            <Typography
                                variant="p"
                                component="p"
                                style={{color: color.themeColorNew}}
                            >
                                {props?.allow_download ? "Yes you can download this information" : "Access to this information is Read Only."}
                            </Typography>
                        </Grid>
                        {props?.additional_terms && <Grid item xs={12} sm={12} md={12}>
                            <Typography
                                variant="h6"
                                component="h3"
                                style={{color: color.themeColorNew}}
                            >
                                {"Additional Terms"}
                            </Typography>
                            <Typography
                                variant="p"
                                component="p"
                                style={{color: color.themeColorNew}}
                            >
                                {props?.additional_terms}
                            </Typography>
                        </Grid>}
                        {
                            props?.expiration_date && (
                                <Grid item xs={12} sm={12} md={12}>
                                    <Typography
                                        variant="h6"
                                        component="h3"
                                        style={{color: color.themeColorNew}}
                                    >
                                        {"Information will expire on"}
                                    </Typography>
                                    <Typography
                                        variant="p"
                                        component="p"
                                        style={{color: color.themeColorNew}}
                                    >
                                        {props?.expiration_date}
                                    </Typography>
                                </Grid>
                            )
                        }
                        <Grid item xs={12} sm={12} md={12}>
                            <Typography
                                variant="h6"
                                component="h3"
                                style={{color: color.themeColorNew}}
                            >
                                {"Personal Message"}
                            </Typography>
                            <Typography
                                variant="p"
                                component="p"
                                style={{color: color.themeColorNew}}
                            >
                                {props?.personal_message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="text-sm-center" style={{textAlign: "right"}}>
                            <CustomButton
                                type="submit"
                                btnText="Agree"
                                textAlign="center"
                                display={"block"}
                                padding="12px 25px"
                                minWidth="113px"
                                background="#0042a8"
                                hoverBg="#0042a8"
                                disabled={loading}
                                border="0"
                                onClick={() => {
                                    handleClick()
                                }}
                                marginRight="10px"
                                borderRadius="8px"
                                color="#fff"
                            />
                        </Grid>
                    </Grid>
                </div>
            </CustomModal>

        </>
    );
}

export default TermsModal;
