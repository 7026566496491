import "date-fns";
import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Checkbox, FormControlLabel, Grid} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import {CustomButton} from "../../../components/CustomButton/Button.js";
import {Close} from "@material-ui/icons";
import {Auth} from "../../../services/auth.service";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(styles);

function EditEmailPrefrence(props) {
    const [checkedA, setCheckedA] = React.useState(false);
    const [checkedB, setCheckedB] = React.useState(false);
    const [id, setId] = React.useState(null);

    const [checkedC, setCheckedC] = React.useState(false);
    const [loading, setLoading] = React.useState(false)
    const authService = new Auth()

    useEffect(() => {
        authService.getEmailPreferences().then((res) => {
            setId(res.data.email_preference.id)
            setCheckedA(res?.data?.email_preference.daily_summary)
            setCheckedB(res?.data?.email_preference.weekly_summary)
            setCheckedC(res?.data?.email_preference.news)
        }).catch((err) => {
        })
    }, [])

    const classes = useStyles();

    const handleSubmit = async () => {
        setLoading(true)
        let data = {
            id: id,
            object: {
                daily_summary: checkedA,
                weekly_summary: checkedB,
                news: checkedC
            }
        }
        console.log({data})
        try {
            let response = await authService.updateEmailPreferences(data)
            setLoading(false)
            props?.handleClose()
        } catch (e) {
            setLoading(false)
            console.log({error: e.response})
        }
    };


    return (
        <>
            <CustomModal
                visible={props.open}
                onClose={props.handleClose}
                width="55%"
                margin="120px 0 40px"
                inset="15px!important"
            >
                <ModalHeader
                    closeModal={props.handleClose}
                    icon={
                        <Close
                            style={{
                                color: color.white,
                                marginRight: "20px",
                                fontSize: "30px",
                                cursor: "pointer",
                            }}
                        />
                    }
                    heading={"Email Preferences"}
                    color={color.white}
                />
                <div className={classes.modalBody}>
                    <Grid
                        container
                        wrap="wrap"
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <Grid item xs={12} sm={12} md={12} className="border-bottom">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkedA}
                                        onChange={() => setCheckedA(!checkedA)}
                                        name="checkedA"
                                        style={{color: "#ffb722"}}
                                    />
                                }
                                label="I would like to receive a daily summary of my recent account activity"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="border-bottom">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkedB}
                                        onChange={() => setCheckedB(!checkedB)}
                                        name="checkedA"
                                        style={{color: "#ffb722"}}
                                    />
                                }
                                label="I would like to receive a weekly summary of my activity"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="border-bottom">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkedC}
                                        onChange={() => setCheckedC(!checkedC)}
                                        name="checkedA"
                                        style={{color: "#ffb722"}}
                                    />
                                }
                                label="I would like to receive TagData's news and product update emails"
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} className="text-sm-center" style={{textAlign: "right"}}>
                            {!loading ? <CustomButton
                                type="submit"
                                btnText="Save"
                                textAlign="center"
                                display={"block"}
                                padding="12px 25px"
                                minWidth="113px"
                                background="#0042a8"
                                hoverBg="#0042a8"
                                disabled={!checkedA && !checkedC && !checkedB}
                                border="0"
                                marginRight="10px"
                                borderRadius="8px"
                                color="#fff"
                                onClick={handleSubmit}
                            /> : <CustomButton
                                type="submit"
                                btnText={<CircularProgress size={20} color="#DDD"/>}
                                textAlign="center"
                                display={"block"}
                                padding="12px 25px"
                                minWidth="113px"
                                background="#0042a8"
                                hoverBg="#0042a8"
                                border="0"
                                marginRight="10px"
                                borderRadius="8px"
                                color="#fff"
                            />}

                        </Grid>
                    </Grid>
                </div>
            </CustomModal>
        </>
    );
}

export default EditEmailPrefrence;
