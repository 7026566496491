import "date-fns";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { Close, CreditCard } from "@material-ui/icons";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import color from "../../../assets/css/color.js";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import { usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import _default from "../../../config/default.js";
import { Auth } from "../../../services/auth.service.js";
import { store } from "../../../store/store.js";
import { setPlanType } from "../../../store/actions/authAction.js";
import PromoCode from "../PromoCode/PromoCodeModal.js";
import SubscriptionCheckout from "../Alerts/Subscription/SubscriptionCheckout.js";
import { useHistory } from "react-router-dom";
import { isExpired } from "../../../store/actions/loadingAction.js";
import DowngradeModal from "../DowngradeModal/downgradeModal.js";

const useStyles = makeStyles(styles);
const authService = new Auth();
const { Axios } = _default;

function AddNewCard(props) {
  const {
    meta,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();
  const [cardNo, setCardNo] = useState("");
  const [promoCode, setPromoCode] = useState(null);
  const [promoCodeOpen, setPromoCodeOpen] = useState(false);
  const [err, setErr] = useState({
    cvc: false,
    expiryDate: false,
    name: false,
    cardNo: false,
  });
  const [error, setError] = useState([]);
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [alert, setAlert] = useState(false);
  const [data, setData] = useState(null);
  const classes = useStyles();
  const [methods, setMethods] = useState(null);
  const [disabled, setDisabled] = React.useState(false);
  const [state, setState] = React.useState({ checkedA: false });
  const handleChange = (event) => {
    if (event.target.name === "checkedA") {
      setState({
        ...state,
        [event.target.name]: event.target.checked ? true : false,
      });
    }
  };
  const history = useHistory();
  const handleOpenPaymentSuccessfull = () => {
    !meta.isTouched && !meta.error && setPromoCodeOpen(true);
  };
  const handleOpenPaymentSuccessfullAdd = () => {
    !meta.isTouched && !meta.error && addCard();
  };

  const addCard = async () => {
    setLoading(true);
    const month = expiryDate.split("/")[0]?.trim();
    const year = expiryDate.split("/")[1]?.trim();
    let details = {
      "card[number]": cardNo,
      "card[exp_month]": +month,
      "card[exp_year]": +year,
      "card[name]": name,
      "card[cvc]": cvv,
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    console.log({ formBody });

    try {
      let response = await Axios({
        url: `https://api.stripe.com/v1/tokens`,
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer  ${process.env.REACT_APP_AXIEL_STRIPE_PK}`,
          Accept: "application/json",
        },
        data: formBody,
      });

      const data = await response.data.id;
      authService
        .createPaymentMethod(data)
        .then((res) => {
          props.handleClose();
        })
        .catch((err) => {
          setLoading(false);
          if (err.response && err.response.data.message) {
            let array = [];
            Object.keys(err.response.data.message).forEach((key) =>
              array.push(`${key} ${err.response.data.message[key]}`)
            );
            setError(array);
          } else {
            if(err.response && err.response.data.errors.message)
              {
                let array = [];
            Object.keys(err.response.data.errors.message).forEach((key) =>
              array.push(`${err.response.data.errors.message[key]}`)
            );
            setError(array);
              }
              else
              {
            setError(["Internal Server Error"]);
              }
          }
        });
    } catch (error) {
      setLoading(false);
      setError([error?.response?.data?.error?.message]);
    }
  };

  const getToken = async (promo) => {
    // console.log('discount_codediscount_code',discount_code)
    const subData = {
      is_annual: props?.type === "Annual",
      plan: props?.plan,
      ...(promo && {
        discount_code: promo,
      }),
    };
    setLoading(true);
    const month = expiryDate.split("/")[0]?.trim();
    const year = expiryDate.split("/")[1]?.trim();
    let details = {
      "card[number]": cardNo,
      "card[exp_month]": +month,
      "card[exp_year]": +year,
      "card[name]": name,
      "card[cvc]": cvv,
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    try {
      let response = await Axios({
        url: `https://api.stripe.com/v1/tokens`,
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer  ${process.env.REACT_APP_AXIEL_STRIPE_PK}`,
          Accept: "application/json",
        },
        data: formBody,
      });

      const data = await response.data.id;
      if (
        store.getState()?.auth?.user?.is_subscribed === false &&
        store.getState()?.loading?.expired === false
      ) {
        console.log("!@#!@!#!@#", subData);
        authService
          .createPaymentMethod(data)
          .then((res) => {
            setMethods(res.data.payment_method);
            return authService.createSubscription(subData);
          })
          .then((res) => {
            setData(res.data.subscription);
            setAlert(true);
            store.dispatch(setPlanType(props.plan));
            setLoading(false);
            // props.handleClose();
          })
          .catch((err) => {
            setLoading(false);
            if (err.response && err.response.data.message) {
              let array = [];
              Object.keys(err.response.data.message).forEach((key) =>
                array.push(`${key} ${err.response.data.message[key]}`)
              );
              setError(array);
            } else {
              if(err.response && err.response.data.errors.message)
                {
                  let array = [];
              Object.keys(err.response.data.errors.message).forEach((key) =>
                array.push(`${err.response.data.errors.message[key]}`)
              );
              setError(array);
                }
                else
                {
              setError(["Internal Server Error"]);
                }
            }
          });
      } else {
        console.log("123123123!@#!@!#!@#", subData);
        authService
          .createPaymentMethod(data)
          .then((res) => {
            setMethods(res.data.payment_method);
            return authService.updateSubscription(subData);
          })
          .then((res) => {
            setData(res.data);
            setAlert(true);
            store.dispatch(isExpired(false));
            store.dispatch(setPlanType(props.plan));
            setLoading(false);
            // props.handleClose();
          })
          .catch((err) => {
            setLoading(false);
            if (err.response && err.response.data) {
              if (err.response.data.subscription) {
                let array = [];
                Object.keys(err.response.data.subscription).forEach((key) =>
                  array.push(`${key}: ${err.response.data.subscription[key]}`)
                );
                setError(array);
              } else if (
                err.response.data.errors &&
                err.response.data.errors.message &&
                err.response.data.errors.message[0].includes("Reminder")
              ) {
                setErrArrayForAlerts(err.response.data.errors.message[0]);
                setOpenDowngrade(true);
              } else {
                if(err.response && err.response.data.errors.message)
                  {
                    let array = [];
                Object.keys(err.response.data.errors.message).forEach((key) =>
                  array.push(`${err.response.data.errors.message[key]}`)
                );
                setError(array);
                  }
                  else
                  {
                setError(["Internal Server Error"]);
                  }
              }
            }
          });
      }
    } catch (error) {
      setLoading(false);
      setError([error?.response?.data?.error?.message]);
    }
  };

  const updateSub = () => {
    const subData = {
      is_annual: props?.type === "Annual",
      plan: props?.plan,
      ...(promoCode && {
        discount_code: promoCode,
      }),
    };
  
    authService
      .updateSubscription(subData)
      .then((res) => {
        setData(res.data);
        setAlert(true);
        store.dispatch(setPlanType(props.plan));
        setLoading(false);
        // props.handleClose();
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.data) {
          if (err.response.data.subscription) {
            let array = [];
            Object.keys(err.response.data.subscription).forEach((key) =>
              array.push(`${key}: ${err.response.data.subscription[key]}`)
            );
            setError(array);
          } else if (
            err.response.data.errors &&
            err.response.data.errors.message &&
            err.response.data.errors.message.includes("Reminder")
          ) {
            setErrArrayForAlerts(err.response.data.errors.message[0]);
            setOpenDowngrade(true);
          } else if (
            err.response.data.errors &&
            err.response.data.errors.message &&
            err.response.data.errors.message[0]
          ) {
            setError(err.response.data.errors.message[0]);
          } else {
            if(err.response && err.response.data.errors.message)
              {
                let array = [];
            Object.keys(err.response.data.errors.message).forEach((key) =>
              array.push(`${err.response.data.errors.message[key]}`)
            );
            setError(array);
              }
              else
              {
            setError(["Internal Server Error"]);
              }
          }
        } else {
          setError(["Internal Server Error"]);
        }
      });
  };
  

  const createSub = () => {
    const subData = {
      plan: props?.plan,
      ...(promoCode && {
        discount_code: promoCode,
      }),
    };
    authService
      .createSubscription(subData)
      .then((res) => {
        setData(res.data.subscription);
        setAlert(true);
        store.dispatch(setPlanType(props.plan));
        setLoading(false);
        // props.handleClose();
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.data.subscription) {
          let array = [];
          Object.keys(err.response.data.subscription).forEach((key) =>
            array.push(`${key}: ${err.response.data.subscription[key]}`)
          );
          setError(array);
        } 
        
        else {
          if(err.response && err.response.data.errors.message)
            {
              let array = [];
          Object.keys(err.response.data.errors.message).forEach((key) =>
            array.push(`${err.response.data.errors.message[key]}`)
          );
          setError(array);
            }
            else
            {
          setError(["Internal Server Error"]);
            }
        }
      });
  };

  const [openDowngrade, setOpenDowngrade] = useState(false);
  const [errArrayForAlerts, setErrArrayForAlerts] = useState([]);

  return (
    <>
      {alert && data && (
        <SubscriptionCheckout
          data={data}
          open={alert}
          handleClose={() => {
            history.push("/user");
          }}
          handleClick={() => {
            history.push("/user");
          }}
        />
      )}
      {openDowngrade && (
        <DowngradeModal
          updateSub={updateSub}
          open={openDowngrade}
          handleClose={() => setOpenDowngrade(false)}
          err={errArrayForAlerts}
        />
      )}
      {promoCodeOpen && (
        <PromoCode
          open={promoCodeOpen}
          handleClick={(pc) => {
            // setPromoCode(pc);
            setTimeout(() => {
              getToken(pc);
            }, 1500);
            setPromoCodeOpen(false);
          }}
          handleClose={() => {
            setPromoCode(null);
            if (!methods) {
              getToken();
              setPromoCodeOpen(false);
            } else {
              if (
                store.getState()?.auth?.user?.is_subscribed === false &&
                store.getState()?.loading?.expired === false
              ) {
                createSub();
                setPromoCodeOpen(false);
              } else {
                updateSub();
                setPromoCodeOpen(false);
              }
            }
          }}
          handleChange={(val) => setPromoCode(val)}
        />
      )}
      <CustomModal
        visible={props.open}
        width="35%"
        margin="100px 0 40px"
        inset="15px!important"
      >
        <ModalHeader
          extraClass="p-3 justify-content-start"
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          headerBgColor={color.themeColorNew}
          color="white"
          textAlign="center"
          heading="Add New Card"
        />
        <div className={classes.modalBody}>
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            {props.price ? (
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  variant="p"
                  component="p"
                  className="mb-0"
                  style={{ color: "#060d5c", fontWeight: "normal" }}
                >
                  {"Price"}
                </Typography>
                <Typography
                  variant="h5"
                  component="h3"
                  className="mb-0"
                  style={{ color: color.themeYellow, fontWeight: "bold" }}
                >
                  {props.price}
                </Typography>
              </Grid>
            ) : null}
            {meta.error ? (
              <div className="w-100 d-flex flex-row mb-4">
                <Typography
                  variant="p"
                  component="p"
                  align="left"
                  style={{ color: "red", fontWeight: "normal" }}
                >
                  Error: {meta.error}
                </Typography>
              </div>
            ) : null}
            {error?.length > 0 && (
              <div className="w-100 d-flex flex-column mb-5">
                {error?.map((val, key) => {
                  return (
                    <Typography
                      key={key}
                      variant="p"
                      component="p"
                      align="left"
                      style={{
                        color: "red",
                        fontWeight: "normal",
                        marginBottom: "2px",
                      }}
                    >
                      {val}
                    </Typography>
                  );
                })}
              </div>
            )}

            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Card Number"}
              </Typography>
              <div className="position-relative">
                <FormInput
                  id="iban"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  value={cardNo}
                  inputProps={{
                    ...getCardNumberProps({
                      onChange: (event) => setCardNo(event.target.value),
                    }),
                  }}
                  type="numeric"
                  // onChange={(e) => handleCardNo(e) || setErr({ cardNo: false })}
                  required={true}
                  placeholder={"0000 0000 0000 0000"}
                />
                {cardNo ? (
                  <svg
                    {...getCardImageProps({ images })}
                    className="position-absolute"
                    fontSize="large"
                    style={{
                      color: color.themeYellow,
                      right: "10px",
                      top: "8px",
                      zIndex: "10000",
                      height: "2em",
                    }}
                  />
                ) : (
                  <CreditCard
                    className="position-absolute"
                    fontSize="large"
                    style={{
                      color: color.themeYellow,
                      right: "10px",
                      top: "8px",
                      zIndex: "10000",
                    }}
                    inputProps={{
                      ...getCardImageProps({
                        images,
                      }),
                    }}
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Expiration Date"}
              </Typography>
              <FormInput
                id="class"
                name="notes"
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"MM/YY"}
                inputProps={{
                  ...getExpiryDateProps({
                    onChange: (event) => {
                      setExpiryDate(event.target.value);
                      console.log(event.target.value);
                    },
                  }),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"CVC"}
              </Typography>
              <FormInput
                id="class"
                name="notes"
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"xxx"}
                inputProps={{
                  ...getCVCProps({
                    onChange: (event) => setCvv(event.target.value),
                  }),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Cardholder's Name"}
              </Typography>
              <FormInput
                id="class"
                name="notes"
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                placeholder={"Enter cardholder's name as show on the card "}
                onChange={(e) =>
                  setName(e.target.value) || setErr({ name: false })
                }
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              {props.price ? (
                <>
                  {!loading ? (
                    <CustomButton
                      btnText="Purchase"
                      textAlign="center"
                      display={"block"}
                      padding="8px 25px"
                      background="#0042a8"
                      hoverBg="#0042a8"
                      border="2px solid #0042a8"
                      borderRadius="8px"
                      color="#fff"
                      disabled={
                        !name || !cvv || !cardNo || !expiryDate ? true : false
                      }
                      marginRight={"10px"}
                      width="100px"
                      onClick={() => handleOpenPaymentSuccessfull()}
                    />
                  ) : (
                    <CustomButton
                      btnText={<CircularProgress size={20} color="#DDD" />}
                      type="submit"
                      textAlign="center"
                      display={"block"}
                      padding="8px 25px"
                      background="transparent"
                      hoverBg="#0042a8"
                      border="2px solid #0042a8"
                      borderRadius="8px"
                      color="#0042a8"
                      marginRight={"10px"}
                      width="100px"
                    />
                  )}
                </>
              ) : (
                <>
                  {!loading ? (
                    <CustomButton
                      btnText="Add"
                      textAlign="center"
                      display={"block"}
                      padding="8px 25px"
                      background="#0042a8"
                      hoverBg="#0042a8"
                      border="2px solid #0042a8"
                      borderRadius="8px"
                      color="#fff"
                      disabled={!name || !cvv || !cardNo || !expiryDate}
                      marginRight={"10px"}
                      width="100px"
                      onClick={() => handleOpenPaymentSuccessfullAdd()}
                    />
                  ) : (
                    <CustomButton
                      btnText={<CircularProgress size={20} color="#DDD" />}
                      type="submit"
                      textAlign="center"
                      display={"block"}
                      padding="8px 25px"
                      background="transparent"
                      hoverBg="#0042a8"
                      border="2px solid #0042a8"
                      borderRadius="8px"
                      color="#0042a8"
                      marginRight={"10px"}
                      width="100px"
                    />
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

export default AddNewCard;
