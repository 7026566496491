import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { DocumentService } from "../../../services/document.service";
import { Grid } from "@material-ui/core";
import EditDocument from "../../../containers/Modals/EditDocument/EditDocument";
import SuccessGreen from "../../../containers/Modals/Alerts/SuccessGreen/SuccessGreen";
import Error from "../../../containers/Modals/Alerts/Error/Error";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect, useDispatch } from "react-redux";
import config from "../../../config/default";
import styles from "../../../assets/jss/material-dashboard-react/components/ActivityHistoryAccordion.js";
import ShareModal from "../../../containers/Modals/ShareModal/ShareModal";
import Card from "../../Card/Card";
import file from "../../../assets/img/file.png";
import protected_doc_file from "../../../assets/img/protected_doc_file.png";
import { MoreVert } from "@material-ui/icons";
import color from "../../../assets/css/color";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import FileViewer from "../../FileViewer/FileViewer";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import { PersonalService } from "../../../services/personal.service";
import Warning from "../../../containers/Modals/Alerts/Warning/Warning";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import { truncateString } from "../../../Utils/truncateString";
import SpinnerModal from "../../../containers/Modals/Alerts/SpinnerModal/SpinnerModal";
import PasswordVerification from "../../../containers/Modals/PasswordVerification/PasswordVerification";
import AlertPopup from "../../../containers/Modals/Alerts/Generic/GenericAlert";
import { sort } from "../../../store/actions/sortAction";
import downloadDoc from "../../../Utils/downloadDocument.js";

const useStyles = makeStyles(styles);

const documentService = new DocumentService();

function Documents(props) {
  const [openEditDocument, setOpenEditDocument] = React.useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const myRef = useRef();
  const [alert, setAlert] = React.useState({
    open: false,
    type: null,
    btnText: null,
    cancelText: null,
    heading: null,
    message: null,
  });
  const [activitiesPerPage, setActivitiesPerPage] = useState(5);
  const [open, setOpen] = useState(false);
  const [openn, setOpenn] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const [option, setOption] = React.useState(null);
  const [warning, setWarning] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [spinner, setSpinner] = useState(false);
  const [id, setId] = useState(null);
  const [openShare, setOpenShare] = useState(false);
  const [msg, setMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState(null);
  const [showPasswordVerification, setShowPasswordVerification] =
    useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [docs, setDocs] = useState([]);
  const [currDoc, setCurrDoc] = useState(null);
  const [disclaimer, setDisclaimer] = useState({
    open: false,
  });
  const [err, setErr] = useState(null);
  const [download, setDownload] = useState(false)
  const [attachment, setAttachment] = useState(null);
  const personalService = new PersonalService();
  const handleToggle = (key) => {
    setExpanded(key);
    setOpenn((prevOpen) => !prevOpen);
  };
  const dispatch = useDispatch();
  const view = (id, download) => {
    setSpinner(true);
    documentService
      .show(id, password)
      .then((res) => {
        setSpinner(false);
        if(!download)
          {
            setAttachment(res.data.document.attachment);
        const temp = docs.filter((d) => d.id === id)[0];
      
        if (
          temp.content_type.includes("pdf") ||
          temp.content_type.includes("image")
        ) {
          // window.open(res.data.document.attachment, "_blank");
          setViewOpen(true);
        } else {
          myRef.current.click();
        }
        setPassword(null);
          }
          else
          {
            downloadDoc(res.data.document.attachment, res.data.document.document_title)
            setDownload(false)
          }
      })
      .catch((err) => {
        console.log({ err });
        setSpinner(false);
        setPassword(null);
        setAlert({
          type: "Error",
          open: true,
          cancelText: "CANCEL",
          heading:
            err?.response && err?.response?.status === 422
              ? "Unauthorized!"
              : "Error!",
          message:
            err?.response && err?.response?.status === 422
              ? "Wrong password! please try again."
              : "An unknown error occurred, please try later.",
        });
      });
  };
  const handleMenuItemClick = (option, id, link, passwordProtected, currDoc) => {
    setDownload(option === "Download")
    setOption(option);
    setOpenn(false);
    if (option === "Edit") {
      setOpenEditDocument(true);
    } else if (option === "Share") {
      setSpinner(true);
      setMsg("Processing");
      personalService
        .checkShare()
        .then((res) => {
          setSpinner(false);
          setMsg(null);
          if (res.data && res.data.check_share === true) {
            setOpenShare(true);
          } else {
            setMessage(
              "Your monthly shares have reached their limit, please upgrade your subscription for unlimited shares!"
            );
            setWarning(true);
          }
        })
        .catch((err) => {
          setMsg(null);
          setSpinner(false);
          setMessage("An unknown error occurred. Please try again later!");
          setWarning(true);
        });
    } else if (option === "View") {
      console.log({currDoc})
      currDoc.content_type.includes("pdf") ||
      currDoc.content_type.includes("image")
        ? view(id)
        : setDisclaimer({ open: true });
    }
    else if(option === "Download") {
      if(passwordProtected)
        {
          setShowPasswordVerification(true)
        }
        else
        {
          view(id, true)
        }
    }
     else {
      if (passwordProtected) {
        setAlert({
          open: true,
          btnText: "Yes",
          cancelText: "No",
          heading: "Confirmation",
          message: "Are you sure you want to Delete this document?",
          type: "Error",
          handleClick: () => setShowPasswordVerification(true),
        });
      } else {
        setAlert({
          open: true,
          btnText: "Yes",
          cancelText: "No",
          heading: "Confirmation",
          message: "Are you sure you want to Delete this document?",
          type: "Error",
          handleClick: () => handleDelete(id),
        });
        // handleDelete(id);
      }
    }
    // setOpen(false);
  };
  const handleDelete = (id) => {
    documentService
      .delete(id, password)
      .then(() => {
        let temp = docs.filter((doc) => doc.id !== id);
        setDocs(temp);
        setAlert({
          open: false,
          btnText: null,
          cancelText: null,
          heading: null,
          message: null,
          type: null,
        });
      })
      .catch((err) => {
        // openError(true);
        setAlert({
          open: false,
          btnText: null,
          cancelText: null,
          heading: null,
          message: null,
          type: null,
        });
      });
  };
  useEffect(() => {
    if (!openEditDocument) {
      setLoading(true);
      documentService
        .index()
        .then((res) => {
          setDocs(res.data.documents ? res.data.documents : []);
          setLoading(false);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setErr(
            err.response ? err.response.data.error : "something went wrong"
          );
        });
    }
    return () => {
      dispatch(sort("date", "asc"));
    };
  }, [openEditDocument, props.forceRender]);

  const indexOfLastActivity = currentPage * activitiesPerPage;
  const indexOfFirstActivity = indexOfLastActivity - activitiesPerPage;
  const currentDocs = docs.slice(indexOfFirstActivity, indexOfLastActivity);

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (key) => {
    setExpanded(key);
  };
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleOpenEditDocument = (allergy) => {
    setOpenEditDocument(true);
  };

  const handleCloseEditDocument = () => {
    setOpenEditDocument(false);
  };

  return (
    <Grid container spacing="5">
      {alert.open && (
        <AlertPopup
          btnText={alert.btnText}
          cancelText={alert.cancelText}
          open={alert.open}
          type={alert.type}
          heading={alert.heading}
          message={alert.message}
          isLoading={false}
          handleClose={() => {
            setAlert({
              open: false,
              btnText: null,
              cancelText: null,
              heading: null,
              message: null,
              type: null,
            });
          }}
          handleClick={() => {
            if (alert.handleClick) {
              alert.handleClick();
            } else {
              setAlert({
                open: false,
                btnText: null,
                cancelText: null,
                heading: null,
                message: null,
                type: null,
              });
            }
          }}
        />
      )}
      {disclaimer.open && (
        <AlertPopup
          btnText={"okay"}
          open={disclaimer.open}
          type={"Warning"}
          heading={"Disclaimer"}
          message={
            "This document type can not be viewed in browser, it is only downloadable"
          }
          isLoading={false}
          handleClose={() => {
            if (currDoc.password_protected) {
              setShowPasswordVerification(true);
              setDisclaimer({
                open: false,
              });
            } else {
              view(currDoc.id, true);
              setDisclaimer({
                open: false,
              });
              setDownload(false)
            }
          }}
          handleClick={() => {
            if (currDoc.password_protected) {
              setShowPasswordVerification(true);
              setDisclaimer({
                open: false,
              });
            } else {
              view(currDoc.id, true);
              setDisclaimer({
                open: false,
              });
              setDownload(false)
            }
          }}
        />
      )}
      {showPasswordVerification && (
        <PasswordVerification
          open={showPasswordVerification}
          handleClose={() => setShowPasswordVerification(false)}
          handleChange={(e) => setPassword(e)}
          handleSubmit={() => {
            if (option === "View") {
              setShowPasswordVerification(false);
              view(id);
            }
            else if(option === "Download"){
              setShowPasswordVerification(false)
              view(id, true)
            }
             else {
              setShowPasswordVerification(false);
              handleDelete(id);
            }
          }}
        />
      )}
      {spinner && <SpinnerModal open={spinner} msg={msg || "Loading"} />}
      {!loading && docs?.length > 0 ? (
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          <TreeItem
            style={{ padding: "1rem" }}
            nodeId="1"
            label={`None (${
              docs?.filter(
                (d) =>
                  d.category !== "Personal" &&
                  d?.category !== "Health" &&
                  d?.category !== "Employment" &&
                  d.category !== "Finance" &&
                  d?.category !== "Insurance" &&
                  d?.category !== "Vehicle" &&
                  d?.category !== "Property" &&
                  d?.category !== "Education"
              )?.length
            })`}
          >
            <Grid container>
              {docs
                ?.filter(
                  (d) =>
                    d.category !== "Personal" &&
                    d?.category !== "Health" &&
                    d?.category !== "Employment" &&
                    d.category !== "Finance" &&
                    d?.category !== "Insurance" &&
                    d?.category !== "Vehicle" &&
                    d?.category !== "Property" &&
                    d?.category !== "Education"
                )
                .sort((a, b) => {
                  if (props.sortType === "alpha") {
                    if (props.order === "asc") {
                      return a.document_title?.localeCompare(b.document_title);
                    } else {
                      return b.document_title?.localeCompare(a.document_title);
                    }
                  } else if (props.sortType === "date") {
                    if (props.order === "asc") {
                      return a.created_at?.localeCompare(b.created_at);
                    } else {
                      return b.created_at?.localeCompare(a.created_at);
                    }
                  } else {
                    if (props.order === "asc") {
                      return (
                        +b.document_size?.split(" ")[0] -
                        +a.document_size?.split(" ")[0]
                      );
                    } else {
                      return (
                        +a.document_size?.split(" ")[0] -
                        +b.document_size?.split(" ")[0]
                      );
                    }
                  }
                })
                .map((doc, key) => (
                  <div className="fav_width" style={{ margin: "1rem" }}>
                    {warning && (
                      <Warning
                        open={warning}
                        handleClose={() => setWarning(false)}
                        heading={"Error"}
                        one={true}
                        message={message}
                        onClick={() => {
                          setWarning(false);
                        }}
                      />
                    )}
                    <Card
                      className="p-4 m-0 h-auto mb-3 min-height-182px"
                      data-tooltip={doc?.document_title}
                      data-tooltip-location="top"
                    >
                      {doc?.password_protected ? (
                        <img
                          src={protected_doc_file}
                          width="50px"
                          className="mb-3"
                        />
                      ) : (
                        <img src={file} width="38px" className="mb-3" />
                      )}
                      <Typography
                        variant="h6"
                        component="h3"
                        style={{ color: "#060d5c", cursor: "pointer" }}
                        onClick={() => {
                          setId(doc?.id);
                          setCurrDoc(doc);
                          setOption("View");
                          setExpanded(key + doc?.id + doc?.created_at);
                          if (
                            doc.content_type.includes("pdf") ||
                            doc.content_type.includes("image")
                          ) {
                            doc?.password_protected
                              ? setShowPasswordVerification(true)
                              : view(doc?.id);
                          } else {
                            setDisclaimer({ open: true });
                          }
                        }}
                      >
                        {doc.document_title &&
                          truncateString(doc.document_title, 12)}
                      </Typography>
                      <Typography variant="p" component="p">
                        {moment(
                          moment(
                            doc?.created_at?.split("-")[2] +
                              "-" +
                              doc?.created_at?.split("-")[0] +
                              "-" +
                              doc?.created_at?.split("-")[1],
                            "YYYY-MM-DD"
                          ).toDate()
                        ).format("MMMM DD, YYYY")}
                      </Typography>
                      <Typography variant="p" component="p">
                        {doc.document_size}
                      </Typography>

                      <div className="position-absolute dropMenuDocumentMain">
                        <MoreVert
                          color="primary"
                          aria-controls={open ? "split-button-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-label="select merge strategy"
                          aria-haspopup="menu"
                          onClick={() =>
                            handleToggle(key + doc?.id + doc?.created_at)
                          }
                          style={{
                            cursor: "pointer",
                            color: color.themeYellow,
                          }}
                        />
                      </div>
                      {expanded === key + doc?.id + doc?.created_at ? (
                        <EditDocument
                          category={doc.category}
                          basic={true}
                          id={doc.id}
                          title={doc.document_title}
                          open={openEditDocument}
                          password_protected={doc?.password_protected}
                          handleClose={handleCloseEditDocument}
                        />
                      ) : null}
                      <>
                        {expanded === key + doc?.id + doc?.created_at ? (
                          <ShareModal
                            open={openShare}
                            passwordProtected={doc?.password_protected}
                            id={doc?.id}
                            title={doc?.document_title}
                            category={doc?.category ? doc?.category : ""}
                            handleClose={() => setOpenShare(false)}
                          />
                        ) : null}
                      </>
                      {viewOpen && (
                        <>
                          {expanded === key + doc?.id + doc?.created_at && (
                            <FileViewer
                              contentType={doc?.content_type}
                              key={key}
                              link={attachment}
                              open={viewOpen}
                              handleClose={() => setViewOpen(false)}
                            />
                          )}
                        </>
                      )}
                      {expanded === key + doc?.id + doc?.created_at ? (
                        <Popper
                          className="position-absolute dropMenuDocument"
                          style={{ zIndex: 10000 }}
                          open={openn}
                          anchorEl={props.anchorRef}
                          role={undefined}
                          transition
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                              }}
                            >
                              <Paper>
                                <ClickAwayListener
                                  onClickAway={() => setOpenn(false)}
                                >
                                  <MenuList id="split-button-menu">
                                    {props.options.map((option, index) => (
                                      <MenuItem
                                        key={option}
                                        // selected={index === selectedIndex}

                                        onClick={(event) => {
                                          setOption(option);
                                          setId(doc?.id);
                                          setCurrDoc(doc);
                                          if (
                                            option === "View" &&
                                            doc?.password_protected
                                          ) {
                                            !doc.content_type.includes("pdf") ||
                                            !doc.content_type.includes("image")
                                              ? setDisclaimer({ open: true })
                                              : setShowPasswordVerification(
                                                  true
                                                );
                                          } else {
                                            handleMenuItemClick(
                                              option,
                                              doc.id,
                                              config.BASE_URL + doc?.attachment,
                                              doc?.password_protected,
                                              doc
                                            );
                                          }
                                        }}
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      ) : null}
                    </Card>
                    {open && (
                      <SuccessGreen
                        open={open}
                        handleClose={() => setOpen(false)}
                        message="Document has successfully been deleted"
                      />
                    )}
                    {openError && (
                      <Error
                        open={open}
                        handleClose={() => setOpenError(false)}
                        message={"Could not delete the Document"}
                      />
                    )}
                  </div>
                ))}
            </Grid>
          </TreeItem>
          <TreeItem
            style={{ padding: "1rem" }}
            nodeId="2"
            label={`Personal (${
              docs?.filter((d) => d?.category === "Personal")?.length
            })`}
          >
            <Grid container>
              {docs
                ?.filter((d) => d.category === "Personal")
                .sort((a, b) => {
                  if (props.sortType === "alpha") {
                    if (props.order === "asc") {
                      return a.document_title?.localeCompare(b.document_title);
                    } else {
                      return b.document_title?.localeCompare(a.document_title);
                    }
                  } else if (props.sortType === "date") {
                    if (props.order === "asc") {
                      return a.created_at?.localeCompare(b.created_at);
                    } else {
                      return b.created_at?.localeCompare(a.created_at);
                    }
                  } else {
                    if (props.order === "asc") {
                      return (
                        +b.document_size?.split(" ")[0] -
                        +a.document_size?.split(" ")[0]
                      );
                    } else {
                      return (
                        +a.document_size?.split(" ")[0] -
                        +b.document_size?.split(" ")[0]
                      );
                    }
                  }
                })
                .map((doc, key) => (
                  <div className="fav_width" style={{ margin: "1rem" }}>
                    {warning && (
                      <Warning
                        open={warning}
                        handleClose={() => setWarning(false)}
                        heading={"Error"}
                        one={true}
                        message={message}
                        onClick={() => {
                          setWarning(false);
                        }}
                      />
                    )}
                    <Card
                      className="p-4 m-0 h-auto mb-3 min-height-182px"
                      data-tooltip={doc?.document_title}
                      data-tooltip-location="top"
                    >
                      {doc?.password_protected ? (
                        <img
                          src={protected_doc_file}
                          width="50px"
                          className="mb-3"
                        />
                      ) : (
                        <img src={file} width="38px" className="mb-3" />
                      )}
                      <Typography
                        variant="h6"
                        component="h3"
                        style={{ color: "#060d5c", cursor: "pointer" }}
                        onClick={() => {
                          setId(doc?.id);
                          setCurrDoc(doc);
                          setOption("View");
                          setExpanded(key + doc?.id + doc?.created_at);
                          if (
                            doc.content_type.includes("pdf") ||
                            doc.content_type.includes("image")
                          ) {
                            doc?.password_protected
                              ? setShowPasswordVerification(true)
                              : view(doc?.id);
                          } else {
                            setDisclaimer({ open: true });
                          }
                        }}
                      >
                        {doc.document_title &&
                          truncateString(doc.document_title, 12)}
                      </Typography>
                      <Typography variant="p" component="p">
                        {moment(
                          moment(
                            doc?.created_at?.split("-")[2] +
                              "-" +
                              doc?.created_at?.split("-")[0] +
                              "-" +
                              doc?.created_at?.split("-")[1],
                            "YYYY-MM-DD"
                          ).toDate()
                        ).format("MMMM DD, YYYY")}
                      </Typography>
                      <Typography variant="p" component="p">
                        {doc.document_size}
                      </Typography>

                      <div className="position-absolute dropMenuDocumentMain">
                        <MoreVert
                          color="primary"
                          aria-controls={open ? "split-button-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-label="select merge strategy"
                          aria-haspopup="menu"
                          onClick={() =>
                            handleToggle(key + doc?.id + doc?.created_at)
                          }
                          style={{
                            cursor: "pointer",
                            color: color.themeYellow,
                          }}
                        />
                      </div>
                      {expanded === key + doc?.id + doc?.created_at ? (
                        <EditDocument
                          category={doc.category}
                          basic={true}
                          id={doc.id}
                          title={doc.document_title}
                          open={openEditDocument}
                          password_protected={doc?.password_protected}
                          handleClose={handleCloseEditDocument}
                        />
                      ) : null}
                      <>
                        {expanded === key + doc?.id + doc?.created_at ? (
                          <ShareModal
                            open={openShare}
                            id={doc?.id}
                            passwordProtected={doc?.password_protected}
                            title={doc?.document_title}
                            category={doc?.category ? doc?.category : ""}
                            handleClose={() => setOpenShare(false)}
                          />
                        ) : null}
                      </>
                      {viewOpen && (
                        <>
                          {expanded === key + doc?.id + doc?.created_at && (
                            <FileViewer
                              contentType={doc?.content_type}
                              key={key}
                              link={attachment}
                              open={viewOpen}
                              handleClose={() => setViewOpen(false)}
                            />
                          )}
                        </>
                      )}
                      {expanded === key + doc?.id + doc?.created_at ? (
                        <Popper
                          className="position-absolute dropMenuDocument"
                          style={{ zIndex: 10000 }}
                          open={openn}
                          anchorEl={props.anchorRef}
                          role={undefined}
                          transition
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                              }}
                            >
                              <Paper>
                                <ClickAwayListener
                                  onClickAway={() => setOpenn(false)}
                                >
                                  <MenuList id="split-button-menu">
                                    {props.options.map((option, index) => (
                                      <MenuItem
                                        key={option}
                                        // selected={index === selectedIndex}

                                        onClick={(event) => {
                                          setOption(option);
                                          setId(doc?.id);
                                          setCurrDoc(doc);
                                          if (
                                            option === "View" &&
                                            doc?.password_protected
                                          ) {
                                            !doc.content_type.includes("pdf") ||
                                            !doc.content_type.includes("image")
                                              ? setDisclaimer({ open: true })
                                              : setShowPasswordVerification(
                                                  true
                                                );
                                          } else {
                                            handleMenuItemClick(
                                              option,
                                              doc.id,
                                              config.BASE_URL + doc?.attachment,
                                              doc?.password_protected,
                                              doc
                                            );
                                          }
                                        }}
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      ) : null}
                    </Card>
                    {open && (
                      <SuccessGreen
                        open={open}
                        handleClose={() => setOpen(false)}
                        message="Document has successfully been deleted"
                      />
                    )}
                    {openError && (
                      <Error
                        open={open}
                        handleClose={() => setOpenError(false)}
                        message={"Could not delete the Document"}
                      />
                    )}
                  </div>
                ))}
            </Grid>
          </TreeItem>
          <TreeItem
            style={{ padding: "1rem" }}
            nodeId="3"
            label={`Health (${
              docs?.filter((d) => d?.category === "Health")?.length
            })`}
          >
            <Grid container>
              {docs
                ?.filter((d) => d.category === "Health")
                .sort((a, b) => {
                  if (props.sortType === "alpha") {
                    if (props.order === "asc") {
                      return a.document_title?.localeCompare(b.document_title);
                    } else {
                      return b.document_title?.localeCompare(a.document_title);
                    }
                  } else if (props.sortType === "date") {
                    if (props.order === "asc") {
                      return a.created_at?.localeCompare(b.created_at);
                    } else {
                      return b.created_at?.localeCompare(a.created_at);
                    }
                  } else {
                    if (props.order === "asc") {
                      return (
                        +b.document_size?.split(" ")[0] -
                        +a.document_size?.split(" ")[0]
                      );
                    } else {
                      return (
                        +a.document_size?.split(" ")[0] -
                        +b.document_size?.split(" ")[0]
                      );
                    }
                  }
                })
                .map((doc, key) => (
                  <div className="fav_width" style={{ margin: "1rem" }}>
                    {warning && (
                      <Warning
                        open={warning}
                        handleClose={() => setWarning(false)}
                        heading={"Error"}
                        one={true}
                        message={message}
                        onClick={() => {
                          setWarning(false);
                        }}
                      />
                    )}
                    <Card
                      className="p-4 m-0 h-auto mb-3 min-height-182px"
                      data-tooltip={doc?.document_title}
                      data-tooltip-location="top"
                    >
                      {doc?.password_protected ? (
                        <img
                          src={protected_doc_file}
                          width="50px"
                          className="mb-3"
                        />
                      ) : (
                        <img src={file} width="38px" className="mb-3" />
                      )}
                      <Typography
                        variant="h6"
                        component="h3"
                        style={{ color: "#060d5c", cursor: "pointer" }}
                        onClick={() => {
                          setId(doc?.id);
                          setCurrDoc(doc);
                          setOption("View");
                          setExpanded(key + doc?.id + doc?.created_at);
                          if (
                            doc.content_type.includes("pdf") ||
                            doc.content_type.includes("image")
                          ) {
                            doc?.password_protected
                              ? setShowPasswordVerification(true)
                              : view(doc?.id);
                          } else {
                            setDisclaimer({ open: true });
                          }
                        }}
                      >
                        {doc.document_title &&
                          truncateString(doc.document_title, 12)}
                      </Typography>
                      <Typography variant="p" component="p">
                        {moment(
                          moment(
                            doc?.created_at?.split("-")[2] +
                              "-" +
                              doc?.created_at?.split("-")[0] +
                              "-" +
                              doc?.created_at?.split("-")[1],
                            "YYYY-MM-DD"
                          ).toDate()
                        ).format("MMMM DD, YYYY")}
                      </Typography>
                      <Typography variant="p" component="p">
                        {doc.document_size}
                      </Typography>

                      <div className="position-absolute dropMenuDocumentMain">
                        <MoreVert
                          color="primary"
                          aria-controls={open ? "split-button-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-label="select merge strategy"
                          aria-haspopup="menu"
                          onClick={() =>
                            handleToggle(key + doc?.id + doc?.created_at)
                          }
                          style={{
                            cursor: "pointer",
                            color: color.themeYellow,
                          }}
                        />
                      </div>
                      {expanded === key + doc?.id + doc?.created_at ? (
                        <EditDocument
                          category={doc.category}
                          basic={true}
                          id={doc.id}
                          title={doc.document_title}
                          open={openEditDocument}
                          password_protected={doc?.password_protected}
                          handleClose={handleCloseEditDocument}
                        />
                      ) : null}
                      <>
                        {expanded === key + doc?.id + doc?.created_at ? (
                          <ShareModal
                            open={openShare}
                            passwordProtected={doc?.password_protected}
                            id={doc?.id}
                            title={doc?.document_title}
                            category={doc?.category ? doc?.category : ""}
                            handleClose={() => setOpenShare(false)}
                          />
                        ) : null}
                      </>
                      {viewOpen && (
                        <>
                          {expanded === key + doc?.id + doc?.created_at && (
                            <FileViewer
                              contentType={doc?.content_type}
                              key={key}
                              link={attachment}
                              open={viewOpen}
                              handleClose={() => setViewOpen(false)}
                            />
                          )}
                        </>
                      )}
                      {expanded === key + doc?.id + doc?.created_at ? (
                        <Popper
                          className="position-absolute dropMenuDocument"
                          style={{ zIndex: 10000 }}
                          open={openn}
                          anchorEl={props.anchorRef}
                          role={undefined}
                          transition
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                              }}
                            >
                              <Paper>
                                <ClickAwayListener
                                  onClickAway={() => setOpenn(false)}
                                >
                                  <MenuList id="split-button-menu">
                                    {props.options.map((option, index) => (
                                      <MenuItem
                                        key={option}
                                        // selected={index === selectedIndex}

                                        onClick={(event) => {
                                          setOption(option);
                                          setId(doc?.id);
                                          setCurrDoc(doc);
                                          if (
                                            option === "View" &&
                                            doc?.password_protected
                                          ) {
                                            !doc.content_type.includes("pdf") ||
                                            !doc.content_type.includes("image")
                                              ? setDisclaimer({ open: true })
                                              : setShowPasswordVerification(
                                                  true
                                                );
                                          } else {
                                            handleMenuItemClick(
                                              option,
                                              doc.id,
                                              config.BASE_URL + doc?.attachment,
                                              doc?.password_protected,
                                              doc
                                            );
                                          }
                                        }}
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      ) : null}
                    </Card>
                    {open && (
                      <SuccessGreen
                        open={open}
                        handleClose={() => setOpen(false)}
                        message="Document has successfully been deleted"
                      />
                    )}
                    {openError && (
                      <Error
                        open={open}
                        handleClose={() => setOpenError(false)}
                        message={"Could not delete the Document"}
                      />
                    )}
                  </div>
                ))}
            </Grid>
          </TreeItem>
          <TreeItem
            style={{ padding: "1rem" }}
            nodeId="4"
            label={`Employment (${
              docs?.filter((d) => d?.category === "Employment")?.length
            })`}
          >
            <Grid container>
              {docs
                ?.filter((d) => d.category === "Employment")
                .sort((a, b) => {
                  if (props.sortType === "alpha") {
                    if (props.order === "asc") {
                      return a.document_title?.localeCompare(b.document_title);
                    } else {
                      return b.document_title?.localeCompare(a.document_title);
                    }
                  } else if (props.sortType === "date") {
                    if (props.order === "asc") {
                      return a.created_at?.localeCompare(b.created_at);
                    } else {
                      return b.created_at?.localeCompare(a.created_at);
                    }
                  } else {
                    if (props.order === "asc") {
                      return (
                        +b.document_size?.split(" ")[0] -
                        +a.document_size?.split(" ")[0]
                      );
                    } else {
                      return (
                        +a.document_size?.split(" ")[0] -
                        +b.document_size?.split(" ")[0]
                      );
                    }
                  }
                })
                .map((doc, key) => (
                  <div className="fav_width" style={{ margin: "1rem" }}>
                    {warning && (
                      <Warning
                        open={warning}
                        handleClose={() => setWarning(false)}
                        heading={"Error"}
                        one={true}
                        message={message}
                        onClick={() => {
                          setWarning(false);
                        }}
                      />
                    )}
                    <Card
                      className="p-4 m-0 h-auto mb-3 min-height-182px"
                      data-tooltip={doc?.document_title}
                      data-tooltip-location="top"
                    >
                      {doc?.password_protected ? (
                        <img
                          src={protected_doc_file}
                          width="50px"
                          className="mb-3"
                        />
                      ) : (
                        <img src={file} width="38px" className="mb-3" />
                      )}
                      <Typography
                        variant="h6"
                        component="h3"
                        style={{ color: "#060d5c", cursor: "pointer" }}
                        onClick={() => {
                          setId(doc?.id);
                          setCurrDoc(doc);
                          setOption("View");
                          setExpanded(key + doc?.id + doc?.created_at);
                          if (
                            doc.content_type.includes("pdf") ||
                            doc.content_type.includes("image")
                          ) {
                            doc?.password_protected
                              ? setShowPasswordVerification(true)
                              : view(doc?.id);
                          } else {
                            setDisclaimer({ open: true });
                          }
                        }}
                      >
                        {doc.document_title &&
                          truncateString(doc.document_title, 12)}
                      </Typography>
                      <Typography variant="p" component="p">
                        {moment(
                          moment(
                            doc?.created_at?.split("-")[2] +
                              "-" +
                              doc?.created_at?.split("-")[0] +
                              "-" +
                              doc?.created_at?.split("-")[1],
                            "YYYY-MM-DD"
                          ).toDate()
                        ).format("MMMM DD, YYYY")}
                      </Typography>
                      <Typography variant="p" component="p">
                        {doc.document_size}
                      </Typography>

                      <div className="position-absolute dropMenuDocumentMain">
                        <MoreVert
                          color="primary"
                          aria-controls={open ? "split-button-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-label="select merge strategy"
                          aria-haspopup="menu"
                          onClick={() =>
                            handleToggle(key + doc?.id + doc?.created_at)
                          }
                          style={{
                            cursor: "pointer",
                            color: color.themeYellow,
                          }}
                        />
                      </div>
                      {expanded === key + doc?.id + doc?.created_at ? (
                        <EditDocument
                          category={doc.category}
                          basic={true}
                          id={doc.id}
                          title={doc.document_title}
                          open={openEditDocument}
                          password_protected={doc?.password_protected}
                          handleClose={handleCloseEditDocument}
                        />
                      ) : null}
                      <>
                        {expanded === key + doc?.id + doc?.created_at ? (
                          <ShareModal
                            passwordProtected={doc?.password_protected}
                            open={openShare}
                            id={doc?.id}
                            title={doc?.document_title}
                            category={doc?.category ? doc?.category : ""}
                            handleClose={() => setOpenShare(false)}
                          />
                        ) : null}
                      </>
                      {viewOpen && (
                        <>
                          {expanded === key + doc?.id + doc?.created_at && (
                            <FileViewer
                              contentType={doc?.content_type}
                              key={key}
                              link={attachment}
                              open={viewOpen}
                              handleClose={() => setViewOpen(false)}
                            />
                          )}
                        </>
                      )}
                      {expanded === key + doc?.id + doc?.created_at ? (
                        <Popper
                          className="position-absolute dropMenuDocument"
                          style={{ zIndex: 10000 }}
                          open={openn}
                          anchorEl={props.anchorRef}
                          role={undefined}
                          transition
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                              }}
                            >
                              <Paper>
                                <ClickAwayListener
                                  onClickAway={() => setOpenn(false)}
                                >
                                  <MenuList id="split-button-menu">
                                    {props.options.map((option, index) => (
                                      <MenuItem
                                        key={option}
                                        // selected={index === selectedIndex}

                                        onClick={(event) => {
                                          setOption(option);
                                          setId(doc?.id);
                                          setCurrDoc(doc);
                                          if (
                                            option === "View" &&
                                            doc?.password_protected
                                          ) {
                                            !doc.content_type.includes("pdf") ||
                                            !doc.content_type.includes("image")
                                              ? setDisclaimer({ open: true })
                                              : setShowPasswordVerification(
                                                  true
                                                );
                                          } else {
                                            handleMenuItemClick(
                                              option,
                                              doc.id,
                                              config.BASE_URL + doc?.attachment,
                                              doc?.password_protected,
                                              doc
                                            );
                                          }
                                        }}
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      ) : null}
                    </Card>
                    {open && (
                      <SuccessGreen
                        open={open}
                        handleClose={() => setOpen(false)}
                        message="Document has successfully been deleted"
                      />
                    )}
                    {openError && (
                      <Error
                        open={open}
                        handleClose={() => setOpenError(false)}
                        message={"Could not delete the Document"}
                      />
                    )}
                  </div>
                ))}
            </Grid>
          </TreeItem>
          <TreeItem
            style={{ padding: "1rem" }}
            nodeId="5"
            label={`Finance (${
              docs?.filter((d) => d?.category === "Finance")?.length
            })`}
          >
            <Grid container>
              {docs
                ?.filter((d) => d.category === "Finance")
                .sort((a, b) => {
                  if (props.sortType === "alpha") {
                    if (props.order === "asc") {
                      return a.document_title?.localeCompare(b.document_title);
                    } else {
                      return b.document_title?.localeCompare(a.document_title);
                    }
                  } else if (props.sortType === "date") {
                    if (props.order === "asc") {
                      return a.created_at?.localeCompare(b.created_at);
                    } else {
                      return b.created_at?.localeCompare(a.created_at);
                    }
                  } else {
                    if (props.order === "asc") {
                      return (
                        +b.document_size?.split(" ")[0] -
                        +a.document_size?.split(" ")[0]
                      );
                    } else {
                      return (
                        +a.document_size?.split(" ")[0] -
                        +b.document_size?.split(" ")[0]
                      );
                    }
                  }
                })
                .map((doc, key) => (
                  <div className="fav_width" style={{ margin: "1rem" }}>
                    {warning && (
                      <Warning
                        open={warning}
                        handleClose={() => setWarning(false)}
                        heading={"Error"}
                        one={true}
                        message={message}
                        onClick={() => {
                          setWarning(false);
                        }}
                      />
                    )}
                    <Card
                      className="p-4 m-0 h-auto mb-3 min-height-182px"
                      data-tooltip={doc?.document_title}
                      data-tooltip-location="top"
                    >
                      {doc?.password_protected ? (
                        <img
                          src={protected_doc_file}
                          width="50px"
                          className="mb-3"
                        />
                      ) : (
                        <img src={file} width="38px" className="mb-3" />
                      )}
                      <Typography
                        variant="h6"
                        component="h3"
                        style={{ color: "#060d5c", cursor: "pointer" }}
                        onClick={() => {
                          setId(doc?.id);
                          setCurrDoc(doc);
                          setOption("View");
                          setExpanded(key + doc?.id + doc?.created_at);
                          if (
                            doc.content_type.includes("pdf") ||
                            doc.content_type.includes("image")
                          ) {
                            doc?.password_protected
                              ? setShowPasswordVerification(true)
                              : view(doc?.id);
                          } else {
                            setDisclaimer({ open: true });
                          }
                        }}
                      >
                        {doc.document_title &&
                          truncateString(doc.document_title, 12)}
                      </Typography>
                      <Typography variant="p" component="p">
                        {moment(
                          moment(
                            doc?.created_at?.split("-")[2] +
                              "-" +
                              doc?.created_at?.split("-")[0] +
                              "-" +
                              doc?.created_at?.split("-")[1],
                            "YYYY-MM-DD"
                          ).toDate()
                        ).format("MMMM DD, YYYY")}
                      </Typography>
                      <Typography variant="p" component="p">
                        {doc.document_size}
                      </Typography>

                      <div className="position-absolute dropMenuDocumentMain">
                        <MoreVert
                          color="primary"
                          aria-controls={open ? "split-button-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-label="select merge strategy"
                          aria-haspopup="menu"
                          onClick={() =>
                            handleToggle(key + doc?.id + doc?.created_at)
                          }
                          style={{
                            cursor: "pointer",
                            color: color.themeYellow,
                          }}
                        />
                      </div>
                      {expanded === key + doc?.id + doc?.created_at ? (
                        <EditDocument
                          category={doc.category}
                          basic={true}
                          id={doc.id}
                          title={doc.document_title}
                          open={openEditDocument}
                          password_protected={doc?.password_protected}
                          handleClose={handleCloseEditDocument}
                        />
                      ) : null}
                      <>
                        {expanded === key + doc?.id + doc?.created_at ? (
                          <ShareModal
                            open={openShare}
                            id={doc?.id}
                            title={doc?.document_title}
                            passwordProtected={doc?.password_protected}
                            category={doc?.category ? doc?.category : ""}
                            handleClose={() => setOpenShare(false)}
                          />
                        ) : null}
                      </>
                      {viewOpen && (
                        <>
                          {expanded === key + doc?.id + doc?.created_at && (
                            <FileViewer
                              contentType={doc?.content_type}
                              key={key}
                              link={attachment}
                              open={viewOpen}
                              handleClose={() => setViewOpen(false)}
                            />
                          )}
                        </>
                      )}
                      {expanded === key + doc?.id + doc?.created_at ? (
                        <Popper
                          className="position-absolute dropMenuDocument"
                          style={{ zIndex: 10000 }}
                          open={openn}
                          anchorEl={props.anchorRef}
                          role={undefined}
                          transition
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                              }}
                            >
                              <Paper>
                                <ClickAwayListener
                                  onClickAway={() => setOpenn(false)}
                                >
                                  <MenuList id="split-button-menu">
                                    {props.options.map((option, index) => (
                                      <MenuItem
                                        key={option}
                                        // selected={index === selectedIndex}

                                        onClick={(event) => {
                                          setOption(option);
                                          setId(doc?.id);
                                          setCurrDoc(doc);
                                          if (
                                            option === "View" &&
                                            doc?.password_protected
                                          ) {
                                            !doc.content_type.includes("pdf") ||
                                            !doc.content_type.includes("image")
                                              ? setDisclaimer({ open: true })
                                              : setShowPasswordVerification(
                                                  true
                                                );
                                          } else {
                                            handleMenuItemClick(
                                              option,
                                              doc.id,
                                              config.BASE_URL + doc?.attachment,
                                              doc?.password_protected,
                                              doc
                                            );
                                          }
                                        }}
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      ) : null}
                    </Card>
                    {open && (
                      <SuccessGreen
                        open={open}
                        handleClose={() => setOpen(false)}
                        message="Document has successfully been deleted"
                      />
                    )}
                    {openError && (
                      <Error
                        open={open}
                        handleClose={() => setOpenError(false)}
                        message={"Could not delete the Document"}
                      />
                    )}
                  </div>
                ))}
            </Grid>
          </TreeItem>
          <TreeItem
            style={{ padding: "1rem" }}
            nodeId="6"
            label={`Insurance (${
              docs?.filter((d) => d?.category === "Insurance")?.length
            })`}
          >
            <Grid container>
              {docs
                ?.filter((d) => d.category === "Insurance")
                .sort((a, b) => {
                  if (props.sortType === "alpha") {
                    if (props.order === "asc") {
                      return a.document_title?.localeCompare(b.document_title);
                    } else {
                      return b.document_title?.localeCompare(a.document_title);
                    }
                  } else if (props.sortType === "date") {
                    if (props.order === "asc") {
                      return a.created_at?.localeCompare(b.created_at);
                    } else {
                      return b.created_at?.localeCompare(a.created_at);
                    }
                  } else {
                    if (props.order === "asc") {
                      return (
                        +b.document_size?.split(" ")[0] -
                        +a.document_size?.split(" ")[0]
                      );
                    } else {
                      return (
                        +a.document_size?.split(" ")[0] -
                        +b.document_size?.split(" ")[0]
                      );
                    }
                  }
                })
                .map((doc, key) => (
                  <div className="fav_width" style={{ margin: "1rem" }}>
                    {warning && (
                      <Warning
                        open={warning}
                        handleClose={() => setWarning(false)}
                        heading={"Error"}
                        one={true}
                        message={message}
                        onClick={() => {
                          setWarning(false);
                        }}
                      />
                    )}
                    <Card
                      className="p-4 m-0 h-auto mb-3 min-height-182px"
                      data-tooltip={doc?.document_title}
                      data-tooltip-location="top"
                    >
                      {doc?.password_protected ? (
                        <img
                          src={protected_doc_file}
                          width="50px"
                          className="mb-3"
                        />
                      ) : (
                        <img src={file} width="38px" className="mb-3" />
                      )}
                      <Typography
                        variant="h6"
                        component="h3"
                        style={{ color: "#060d5c", cursor: "pointer" }}
                        onClick={() => {
                          setId(doc?.id);
                          setCurrDoc(doc);
                          setOption("View");
                          setExpanded(key + doc?.id + doc?.created_at);
                          if (
                            doc.content_type.includes("pdf") ||
                            doc.content_type.includes("image")
                          ) {
                            doc?.password_protected
                              ? setShowPasswordVerification(true)
                              : view(doc?.id);
                          } else {
                            setDisclaimer({ open: true });
                          }
                        }}
                      >
                        {doc.document_title &&
                          truncateString(doc.document_title, 12)}
                      </Typography>
                      <Typography variant="p" component="p">
                        {moment(
                          moment(
                            doc?.created_at?.split("-")[2] +
                              "-" +
                              doc?.created_at?.split("-")[0] +
                              "-" +
                              doc?.created_at?.split("-")[1],
                            "YYYY-MM-DD"
                          ).toDate()
                        ).format("MMMM DD, YYYY")}
                      </Typography>
                      <Typography variant="p" component="p">
                        {doc.document_size}
                      </Typography>

                      <div className="position-absolute dropMenuDocumentMain">
                        <MoreVert
                          color="primary"
                          aria-controls={open ? "split-button-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-label="select merge strategy"
                          aria-haspopup="menu"
                          onClick={() =>
                            handleToggle(key + doc?.id + doc?.created_at)
                          }
                          style={{
                            cursor: "pointer",
                            color: color.themeYellow,
                          }}
                        />
                      </div>
                      {expanded === key + doc?.id + doc?.created_at ? (
                        <EditDocument
                          category={doc.category}
                          basic={true}
                          id={doc.id}
                          title={doc.document_title}
                          open={openEditDocument}
                          password_protected={doc?.password_protected}
                          handleClose={handleCloseEditDocument}
                        />
                      ) : null}
                      <>
                        {expanded === key + doc?.id + doc?.created_at ? (
                          <ShareModal
                            open={openShare}
                            id={doc?.id}
                            title={doc?.document_title}
                            passwordProtected={doc?.password_protected}
                            category={doc?.category ? doc?.category : ""}
                            handleClose={() => setOpenShare(false)}
                          />
                        ) : null}
                      </>
                      {viewOpen && (
                        <>
                          {expanded === key + doc?.id + doc?.created_at && (
                            <FileViewer
                              contentType={doc?.content_type}
                              key={key}
                              link={attachment}
                              open={viewOpen}
                              handleClose={() => setViewOpen(false)}
                            />
                          )}
                        </>
                      )}
                      {expanded === key + doc?.id + doc?.created_at ? (
                        <Popper
                          className="position-absolute dropMenuDocument"
                          style={{ zIndex: 10000 }}
                          open={openn}
                          anchorEl={props.anchorRef}
                          role={undefined}
                          transition
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                              }}
                            >
                              <Paper>
                                <ClickAwayListener
                                  onClickAway={() => setOpenn(false)}
                                >
                                  <MenuList id="split-button-menu">
                                    {props.options.map((option, index) => (
                                      <MenuItem
                                        key={option}
                                        // selected={index === selectedIndex}

                                        onClick={(event) => {
                                          setOption(option);
                                          setId(doc?.id);
                                          setCurrDoc(doc);
                                          if (
                                            option === "View" &&
                                            doc?.password_protected
                                          ) {
                                            !doc.content_type.includes("pdf") ||
                                            !doc.content_type.includes("image")
                                              ? setDisclaimer({ open: true })
                                              : setShowPasswordVerification(
                                                  true
                                                );
                                          } else {
                                            handleMenuItemClick(
                                              option,
                                              doc.id,
                                              config.BASE_URL + doc?.attachment,
                                              doc?.password_protected,
                                              doc
                                            );
                                          }
                                        }}
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      ) : null}
                    </Card>
                    {open && (
                      <SuccessGreen
                        open={open}
                        handleClose={() => setOpen(false)}
                        message="Document has successfully been deleted"
                      />
                    )}
                    {openError && (
                      <Error
                        open={open}
                        handleClose={() => setOpenError(false)}
                        message={"Could not delete the Document"}
                      />
                    )}
                  </div>
                ))}
            </Grid>
          </TreeItem>
          <TreeItem
            style={{ padding: "1rem" }}
            nodeId="7"
            label={`Vehicle (${
              docs?.filter((d) => d?.category === "Vehicle")?.length
            })`}
          >
            <Grid container>
              {docs
                ?.filter((d) => d.category === "Vehicle")
                .sort((a, b) => {
                  if (props.sortType === "alpha") {
                    if (props.order === "asc") {
                      return a.document_title?.localeCompare(b.document_title);
                    } else {
                      return b.document_title?.localeCompare(a.document_title);
                    }
                  } else if (props.sortType === "date") {
                    if (props.order === "asc") {
                      return a.created_at?.localeCompare(b.created_at);
                    } else {
                      return b.created_at?.localeCompare(a.created_at);
                    }
                  } else {
                    if (props.order === "asc") {
                      return (
                        +b.document_size?.split(" ")[0] -
                        +a.document_size?.split(" ")[0]
                      );
                    } else {
                      return (
                        +a.document_size?.split(" ")[0] -
                        +b.document_size?.split(" ")[0]
                      );
                    }
                  }
                })
                .map((doc, key) => (
                  <div className="fav_width" style={{ margin: "1rem" }}>
                    {warning && (
                      <Warning
                        open={warning}
                        handleClose={() => setWarning(false)}
                        heading={"Error"}
                        one={true}
                        message={message}
                        onClick={() => {
                          setWarning(false);
                        }}
                      />
                    )}
                    <Card
                      className="p-4 m-0 h-auto mb-3 min-height-182px"
                      data-tooltip={doc?.document_title}
                      data-tooltip-location="top"
                    >
                      {doc?.password_protected ? (
                        <img
                          src={protected_doc_file}
                          width="50px"
                          className="mb-3"
                        />
                      ) : (
                        <img src={file} width="38px" className="mb-3" />
                      )}
                      <Typography
                        variant="h6"
                        component="h3"
                        style={{ color: "#060d5c", cursor: "pointer" }}
                        onClick={() => {
                          setId(doc?.id);
                          setCurrDoc(doc);
                          setOption("View");
                          setExpanded(key + doc?.id + doc?.created_at);
                          if (
                            doc.content_type.includes("pdf") ||
                            doc.content_type.includes("image")
                          ) {
                            doc?.password_protected
                              ? setShowPasswordVerification(true)
                              : view(doc?.id);
                          } else {
                            setDisclaimer({ open: true });
                          }
                        }}
                      >
                        {doc.document_title &&
                          truncateString(doc.document_title, 12)}
                      </Typography>
                      <Typography variant="p" component="p">
                        {moment(
                          moment(
                            doc?.created_at?.split("-")[2] +
                              "-" +
                              doc?.created_at?.split("-")[0] +
                              "-" +
                              doc?.created_at?.split("-")[1],
                            "YYYY-MM-DD"
                          ).toDate()
                        ).format("MMMM DD, YYYY")}
                      </Typography>
                      <Typography variant="p" component="p">
                        {doc.document_size}
                      </Typography>

                      <div className="position-absolute dropMenuDocumentMain">
                        <MoreVert
                          color="primary"
                          aria-controls={open ? "split-button-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-label="select merge strategy"
                          aria-haspopup="menu"
                          onClick={() =>
                            handleToggle(key + doc?.id + doc?.created_at)
                          }
                          style={{
                            cursor: "pointer",
                            color: color.themeYellow,
                          }}
                        />
                      </div>
                      {expanded === key + doc?.id + doc?.created_at ? (
                        <EditDocument
                          category={doc.category}
                          basic={true}
                          id={doc.id}
                          title={doc.document_title}
                          open={openEditDocument}
                          password_protected={doc?.password_protected}
                          handleClose={handleCloseEditDocument}
                        />
                      ) : null}
                      <>
                        {expanded === key + doc?.id + doc?.created_at ? (
                          <ShareModal
                            open={openShare}
                            passwordProtected={doc?.password_protected}
                            id={doc?.id}
                            title={doc?.document_title}
                            category={doc?.category ? doc?.category : ""}
                            handleClose={() => setOpenShare(false)}
                          />
                        ) : null}
                      </>
                      {viewOpen && (
                        <>
                          {expanded === key + doc?.id + doc?.created_at && (
                            <FileViewer
                              contentType={doc?.content_type}
                              key={key}
                              link={attachment}
                              open={viewOpen}
                              handleClose={() => setViewOpen(false)}
                            />
                          )}
                        </>
                      )}
                      {expanded === key + doc?.id + doc?.created_at ? (
                        <Popper
                          className="position-absolute dropMenuDocument"
                          style={{ zIndex: 10000 }}
                          open={openn}
                          anchorEl={props.anchorRef}
                          role={undefined}
                          transition
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                              }}
                            >
                              <Paper>
                                <ClickAwayListener
                                  onClickAway={() => setOpenn(false)}
                                >
                                  <MenuList id="split-button-menu">
                                    {props.options.map((option, index) => (
                                      <MenuItem
                                        key={option}
                                        // selected={index === selectedIndex}

                                        onClick={(event) => {
                                          setOption(option);
                                          setId(doc?.id);
                                          setCurrDoc(doc);
                                          if (
                                            option === "View" &&
                                            doc?.password_protected
                                          ) {
                                            !doc.content_type.includes("pdf") ||
                                            !doc.content_type.includes("image")
                                              ? setDisclaimer({ open: true })
                                              : setShowPasswordVerification(
                                                  true
                                                );
                                          } else {
                                            handleMenuItemClick(
                                              option,
                                              doc.id,
                                              config.BASE_URL + doc?.attachment,
                                              doc?.password_protected,
                                              doc
                                            );
                                          }
                                        }}
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      ) : null}
                    </Card>
                    {open && (
                      <SuccessGreen
                        open={open}
                        handleClose={() => setOpen(false)}
                        message="Document has successfully been deleted"
                      />
                    )}
                    {openError && (
                      <Error
                        open={open}
                        handleClose={() => setOpenError(false)}
                        message={"Could not delete the Document"}
                      />
                    )}
                  </div>
                ))}
            </Grid>
          </TreeItem>
          <TreeItem
            style={{ padding: "1rem" }}
            nodeId="8"
            label={`Property (${
              docs?.filter((d) => d?.category === "Property")?.length
            })`}
          >
            <Grid container>
              {docs
                ?.filter((d) => d.category === "Property")
                .sort((a, b) => {
                  if (props.sortType === "alpha") {
                    if (props.order === "asc") {
                      return a.document_title?.localeCompare(b.document_title);
                    } else {
                      return b.document_title?.localeCompare(a.document_title);
                    }
                  } else if (props.sortType === "date") {
                    if (props.order === "asc") {
                      return a.created_at?.localeCompare(b.created_at);
                    } else {
                      return b.created_at?.localeCompare(a.created_at);
                    }
                  } else {
                    if (props.order === "asc") {
                      return (
                        +b.document_size?.split(" ")[0] -
                        +a.document_size?.split(" ")[0]
                      );
                    } else {
                      return (
                        +a.document_size?.split(" ")[0] -
                        +b.document_size?.split(" ")[0]
                      );
                    }
                  }
                })
                .map((doc, key) => (
                  <div className="fav_width" style={{ margin: "1rem" }}>
                    {warning && (
                      <Warning
                        open={warning}
                        handleClose={() => setWarning(false)}
                        heading={"Error"}
                        one={true}
                        message={message}
                        onClick={() => {
                          setWarning(false);
                        }}
                      />
                    )}
                    <Card
                      className="p-4 m-0 h-auto mb-3 min-height-182px"
                      data-tooltip={doc?.document_title}
                      data-tooltip-location="top"
                    >
                      {doc?.password_protected ? (
                        <img
                          src={protected_doc_file}
                          width="50px"
                          className="mb-3"
                        />
                      ) : (
                        <img src={file} width="38px" className="mb-3" />
                      )}
                      <Typography
                        variant="h6"
                        component="h3"
                        style={{ color: "#060d5c", cursor: "pointer" }}
                        onClick={() => {
                          setId(doc?.id);
                          setCurrDoc(doc);
                          setOption("View");
                          setExpanded(key + doc?.id + doc?.created_at);
                          if (
                            doc.content_type.includes("pdf") ||
                            doc.content_type.includes("image")
                          ) {
                            doc?.password_protected
                              ? setShowPasswordVerification(true)
                              : view(doc?.id);
                          } else {
                            setDisclaimer({ open: true });
                          }
                        }}
                      >
                        {doc.document_title &&
                          truncateString(doc.document_title, 12)}
                      </Typography>
                      <Typography variant="p" component="p">
                        {moment(
                          moment(
                            doc?.created_at?.split("-")[2] +
                              "-" +
                              doc?.created_at?.split("-")[0] +
                              "-" +
                              doc?.created_at?.split("-")[1],
                            "YYYY-MM-DD"
                          ).toDate()
                        ).format("MMMM DD, YYYY")}
                      </Typography>
                      <Typography variant="p" component="p">
                        {doc.document_size}
                      </Typography>

                      <div className="position-absolute dropMenuDocumentMain">
                        <MoreVert
                          color="primary"
                          aria-controls={open ? "split-button-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-label="select merge strategy"
                          aria-haspopup="menu"
                          onClick={() =>
                            handleToggle(key + doc?.id + doc?.created_at)
                          }
                          style={{
                            cursor: "pointer",
                            color: color.themeYellow,
                          }}
                        />
                      </div>
                      {expanded === key + doc?.id + doc?.created_at ? (
                        <EditDocument
                          category={doc.category}
                          basic={true}
                          id={doc.id}
                          title={doc.document_title}
                          open={openEditDocument}
                          password_protected={doc?.password_protected}
                          handleClose={handleCloseEditDocument}
                        />
                      ) : null}
                      <>
                        {expanded === key + doc?.id + doc?.created_at ? (
                          <ShareModal
                            passwordProtected={doc?.password_protected}
                            open={openShare}
                            id={doc?.id}
                            title={doc?.document_title}
                            category={doc?.category ? doc?.category : ""}
                            handleClose={() => setOpenShare(false)}
                          />
                        ) : null}
                      </>
                      {viewOpen && (
                        <>
                          {expanded === key + doc?.id + doc?.created_at && (
                            <FileViewer
                              contentType={doc?.content_type}
                              key={key}
                              link={attachment}
                              open={viewOpen}
                              handleClose={() => setViewOpen(false)}
                            />
                          )}
                        </>
                      )}
                      {expanded === key + doc?.id + doc?.created_at ? (
                        <Popper
                          className="position-absolute dropMenuDocument"
                          style={{ zIndex: 10000 }}
                          open={openn}
                          anchorEl={props.anchorRef}
                          role={undefined}
                          transition
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                              }}
                            >
                              <Paper>
                                <ClickAwayListener
                                  onClickAway={() => setOpenn(false)}
                                >
                                  <MenuList id="split-button-menu">
                                    {props.options.map((option, index) => (
                                      <MenuItem
                                        key={option}
                                        // selected={index === selectedIndex}

                                        onClick={(event) => {
                                          setOption(option);
                                          setId(doc?.id);
                                          setCurrDoc(doc);
                                          if (
                                            option === "View" &&
                                            doc?.password_protected
                                          ) {
                                            !doc.content_type.includes("pdf") ||
                                            !doc.content_type.includes("image")
                                              ? setDisclaimer({ open: true })
                                              : setShowPasswordVerification(
                                                  true
                                                );
                                          } else {
                                            handleMenuItemClick(
                                              option,
                                              doc.id,
                                              config.BASE_URL + doc?.attachment,
                                              doc?.password_protected,
                                              doc
                                            );
                                          }
                                        }}
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      ) : null}
                    </Card>
                    {open && (
                      <SuccessGreen
                        open={open}
                        handleClose={() => setOpen(false)}
                        message="Document has successfully been deleted"
                      />
                    )}
                    {openError && (
                      <Error
                        open={open}
                        handleClose={() => setOpenError(false)}
                        message={"Could not delete the Document"}
                      />
                    )}
                  </div>
                ))}
            </Grid>
          </TreeItem>
          <TreeItem
            style={{ padding: "1rem" }}
            nodeId="9"
            label={`Education (${
              docs?.filter((d) => d?.category === "Education")?.length
            })`}
          >
            <Grid container>
              {docs
                ?.filter((d) => d.category === "Education")
                .sort((a, b) => {
                  if (props.sortType === "alpha") {
                    if (props.order === "asc") {
                      return a.document_title?.localeCompare(b.document_title);
                    } else {
                      return b.document_title?.localeCompare(a.document_title);
                    }
                  } else if (props.sortType === "date") {
                    if (props.order === "asc") {
                      return a.created_at?.localeCompare(b.created_at);
                    } else {
                      return b.created_at?.localeCompare(a.created_at);
                    }
                  } else {
                    if (props.order === "asc") {
                      return (
                        +b.document_size?.split(" ")[0] -
                        +a.document_size?.split(" ")[0]
                      );
                    } else {
                      return (
                        +a.document_size?.split(" ")[0] -
                        +b.document_size?.split(" ")[0]
                      );
                    }
                  }
                })
                .map((doc, key) => (
                  <div className="fav_width" style={{ margin: "1rem" }}>
                    {warning && (
                      <Warning
                        open={warning}
                        handleClose={() => setWarning(false)}
                        heading={"Error"}
                        one={true}
                        message={message}
                        onClick={() => {
                          setWarning(false);
                        }}
                      />
                    )}
                    <Card
                      className="p-4 m-0 h-auto mb-3 min-height-182px"
                      data-tooltip={doc?.document_title}
                      data-tooltip-location="top"
                    >
                      {doc?.password_protected ? (
                        <img
                          src={protected_doc_file}
                          width="50px"
                          className="mb-3"
                        />
                      ) : (
                        <img src={file} width="38px" className="mb-3" />
                      )}
                      <Typography
                        variant="h6"
                        component="h3"
                        style={{ color: "#060d5c", cursor: "pointer" }}
                        onClick={() => {
                          setId(doc?.id);
                          setCurrDoc(doc);
                          setOption("View");
                          setExpanded(key + doc?.id + doc?.created_at);
                          if (
                            doc.content_type.includes("pdf") ||
                            doc.content_type.includes("image")
                          ) {
                            doc?.password_protected
                              ? setShowPasswordVerification(true)
                              : view(doc?.id);
                          } else {
                            setDisclaimer({ open: true });
                          }
                        }}
                      >
                        {doc.document_title &&
                          truncateString(doc.document_title, 12)}
                      </Typography>
                      <Typography variant="p" component="p">
                        {moment(
                          moment(
                            doc?.created_at?.split("-")[2] +
                              "-" +
                              doc?.created_at?.split("-")[0] +
                              "-" +
                              doc?.created_at?.split("-")[1],
                            "YYYY-MM-DD"
                          ).toDate()
                        ).format("MMMM DD, YYYY")}
                      </Typography>
                      <Typography variant="p" component="p">
                        {doc.document_size}
                      </Typography>

                      <div className="position-absolute dropMenuDocumentMain">
                        <MoreVert
                          color="primary"
                          aria-controls={open ? "split-button-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-label="select merge strategy"
                          aria-haspopup="menu"
                          onClick={() =>
                            handleToggle(key + doc?.id + doc?.created_at)
                          }
                          style={{
                            cursor: "pointer",
                            color: color.themeYellow,
                          }}
                        />
                      </div>
                      {expanded === key + doc?.id + doc?.created_at ? (
                        <EditDocument
                          category={doc.category}
                          basic={true}
                          id={doc.id}
                          title={doc.document_title}
                          open={openEditDocument}
                          password_protected={doc?.password_protected}
                          handleClose={handleCloseEditDocument}
                        />
                      ) : null}
                      <>
                        {expanded === key + doc?.id + doc?.created_at ? (
                          <ShareModal
                            open={openShare}
                            id={doc?.id}
                            title={doc?.document_title}
                            passwordProtected={doc?.password_protected}
                            category={doc?.category ? doc?.category : ""}
                            handleClose={() => setOpenShare(false)}
                          />
                        ) : null}
                      </>
                      {viewOpen && (
                        <>
                          {expanded === key + doc?.id + doc?.created_at && (
                            <FileViewer
                              contentType={doc?.content_type}
                              key={key}
                              link={attachment}
                              open={viewOpen}
                              handleClose={() => setViewOpen(false)}
                            />
                          )}
                        </>
                      )}
                      {expanded === key + doc?.id + doc?.created_at ? (
                        <Popper
                          className="position-absolute dropMenuDocument"
                          style={{ zIndex: 10000 }}
                          open={openn}
                          anchorEl={props.anchorRef}
                          role={undefined}
                          transition
                          disablePortal
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                              }}
                            >
                              <Paper>
                                <ClickAwayListener
                                  onClickAway={() => setOpenn(false)}
                                >
                                  <MenuList id="split-button-menu">
                                    {props.options.map((option, index) => (
                                      <MenuItem
                                        key={option}
                                        // selected={index === selectedIndex}

                                        onClick={(event) => {
                                          setOption(option);
                                          setId(doc?.id);
                                          setCurrDoc(doc);
                                          if (
                                            option === "View" &&
                                            doc?.password_protected
                                          ) {
                                            !doc.content_type.includes("pdf") ||
                                            !doc.content_type.includes("image")
                                              ? setDisclaimer({ open: true })
                                              : setShowPasswordVerification(
                                                  true
                                                );
                                          } else {
                                            handleMenuItemClick(
                                              option,
                                              doc.id,
                                              config.BASE_URL + doc?.attachment,
                                              doc?.password_protected,
                                              doc
                                            );
                                          }
                                        }}
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      ) : null}
                    </Card>
                    {open && (
                      <SuccessGreen
                        open={open}
                        handleClose={() => setOpen(false)}
                        message="Document has successfully been deleted"
                      />
                    )}
                    {openError && (
                      <Error
                        open={open}
                        handleClose={() => setOpenError(false)}
                        message={"Could not delete the Document"}
                      />
                    )}
                  </div>
                ))}
            </Grid>
          </TreeItem>
        </TreeView>
      ) : err ? (
        <p>{err}</p>
      ) : !loading && docs.length === 0 ? (
        <Grid item xs={12} md={3}>
          <p>No Documents yet</p>
        </Grid>
      ) : (
        <Grid item xs={12} md={3}>
          <CircularProgress size={20} color="#DDD" />
        </Grid>
      )}
      <input style={{ position: "absolute", top: -200 }} />
    </Grid>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    forceRender: state.loading.render,
    sortType: state.sort?.sort,
    order: state.sort.order,
  };
};

export default connect(mapStateToProps)(Documents);
