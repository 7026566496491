import "date-fns";
import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import debounce from "lodash.debounce";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { DocumentService } from "../../../services/document.service";
import AlertPopup from "../Alerts/Generic/GenericAlert";

const category = [
  "Education",
  "Employment",
  "Finance",
  "Health",
  "Insurance",
  "Personal",
  "Property",
  "Vehicle",
];

const useStyles = makeStyles(styles);
const documentService = new DocumentService();

function EditDocument(props) {
  const [password, setPassword] = React.useState(null);
  const classes = useStyles();
  const [data, setData] = useState({
    title: props.title,
    category: props.category,
    password_protected: props?.password_protected,
  });
  const [alert, setAlert] = React.useState({
    open: false,
    type: null,
    btnText: null,
    cancelText: null,
    heading: null,
    message: null,
  });
  const ref = useRef();
  const passwordRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);

  const handleSubmit = () => {
    setIsLoading(true);
    setErr(null);
    let payload = null;
    if (props?.password_protected) {
      payload = {
        title: ref.current.value || props?.title,
        docId: props.id,
        password_protected: data.password_protected,
        category: data.category,
        password: passwordRef.current.value,
      };
    } else if (!props?.password_protected && data.password_protected) {
      payload = {
        title: ref.current.value || props?.title,
        docId: props.id,
        password_protected: data.password_protected,
        category: data.category,
        password: passwordRef.current.value,
      };
    } else {
      payload = {
        title: ref.current.value || props?.title,
        docId: props.id,
        password_protected: data.password_protected,
        category: data.category,
      };
    }
    documentService
      .update(payload)
      .then((res) => {
        setData({ ...data, title: null, category: null });
        if (!props.basic) {
          let temp = props?.docs?.map((d) => {
            if (d.id === props?.id) {
              return {
                ...d,
                title: res.data?.document?.document_title,
                category: res?.data?.document?.category,
              };
            } else {
              return d;
            }
          });
          props?.setDocs(temp);
        }
        setIsLoading(false);
        setAlert({
          type: "Success",
          open: true,
          cancelText: "OKAY",
          heading: "Success!",
          message: "Your file was successfully updated",
        });
      })
      .catch((err) => {
        setAlert({
          type: "Error",
          open: true,
          btnText: "RETRY",
          cancelText: "CANCEL",
          heading:
            err?.response && err?.response?.status === 422
              ? "Unauthorized!"
              : "Something went wrong!",
          message:
            err?.response && err?.response?.status === 422
              ? "Wrong password! please try again."
              : "We're sorry there was an error and your file could not be uploaded. Would you like to try again?",
        });
        setIsLoading(false);
      });
  };

  const handleChange = debounce((event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  }, 300);

  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Edit | " + props?.title}
          color={color.white}
        />
        <div className={classes.modalBody}>
          {err && (
            <div className="w-100 d-flex flex-row mb-5">
              <Typography
                variant="p"
                component="p"
                align="left"
                style={{ color: "red", fontWeight: "normal" }}
              >
                {err}
              </Typography>
            </div>
          )}
          {alert.open && (
            <AlertPopup
              btnText={alert.btnText}
              cancelText={alert.cancelText}
              open={alert.open}
              type={alert.type}
              heading={alert.heading}
              message={alert.message}
              isLoading={false}
              handleClose={() => {
                setAlert({
                  open: false,
                  btnText: null,
                  cancelText: null,
                  heading: null,
                  message: null,
                  type: null,
                });
                props?.handleClose();
              }}
              handleClick={() => {
                if (alert.type === "Error") {
                  setAlert({
                    open: false,
                    btnText: null,
                    cancelText: null,
                    heading: null,
                    message: null,
                    type: null,
                  });
                } else {
                  setAlert({
                    open: false,
                    btnText: null,
                    cancelText: null,
                    heading: null,
                    message: null,
                    type: null,
                  });
                  props.setFile(null);
                  props.forceRerender();
                  props.handleClose();
                  history.push("/user/all-documents");
                }
              }}
            />
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Title *"}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={8}
              md={8}
              className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-marginDense MuiOutlinedInput-marginDense"
            >
              <input
                ref={ref}
                className="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense"
                defaultValue={props?.title}
                style={{ border: "1px solid rgba(0, 0, 0, 0.23)" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Dimension"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8} sm={8}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="id_type"
                  variant="outlined"
                  fullWidth={true}
                  style={{
                    color:
                      !category.includes(props?.category) &&
                      !category.includes(data.category)
                        ? "grey"
                        : "",
                  }}
                  margin="dense"
                  name={"category"}
                  required={true}
                  defaultValue={
                    category.includes(props?.category)
                      ? props?.category
                      : "Select Dimension"
                  }
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value={"Select Dimension"}>
                    Select Dimension
                  </MenuItem>
                  {category.map((t) => {
                    return <MenuItem value={t}>{t}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pt-0">
              <FormControlLabel
                className="align-items-start"
                control={
                  <Checkbox
                    checked={data.password_protected}
                    onChange={() =>
                      setData({
                        ...data,
                        password_protected: !data.password_protected,
                      })
                    }
                    name="checkedA"
                    style={{ color: "#ffb722", paddingTop: 0 }}
                  />
                }
                label="Select if you want to password protect this document."
              />
            </Grid>
            {props?.password_protected || data.password_protected ? (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Password *"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  md={8}
                  className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-marginDense MuiOutlinedInput-marginDense"
                >
                  <input
                    ref={passwordRef}
                    type="text"
                    id="txtPassword"
                    autoComplete={"new-password"}
                    className="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense"
                    style={{ border: "1px solid rgba(0, 0, 0, 0.23)" }}
                  />
                </Grid>
              </>
            ) : null}
            <Grid item md={12}>
              {!isLoading  ? (
                <CustomButton
                  type="submit"
                  btnText="Okay"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  disabled={!props.title && !data.category}
                  border="0"
                  borderRadius="8px"
                  autoFocus={true}
                  color="#fff"
                  onClick={handleSubmit}
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDocument);
