import React from "react";
import { Grid, Typography } from "@material-ui/core";

export const MailSuccessResetPassword = (props) => {
    return (
        <Grid container direction="column" alignItems="center">
            <Typography
                variant="h5"
                component="h3"
                align="center"
                style={{ margin: "0 0 10px" }}
            >
                Verification Success!
            </Typography>
            <Typography
                variant="p"
                component="p"
                align="center"
                style={{ margin: "0 0 15px" }}
            >
                Success! If we have a matching email address, you will receive an email with a link to reset your password.
            </Typography>
        </Grid>
    );
};
