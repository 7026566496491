import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { authRoutes } from "../routes.js";
import { Redirect, Route, Switch } from "react-router-dom";
import Logo from "../assets/img/tag-logo-white.png";
import LogoGreen from "../assets/img/logogreen.png";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../assets/jss/material-dashboard-react/layouts/authScreenWrapper";
import { AuthRoute, PrivateRoute } from "../index.js";
import { PricingPlans } from "../components/PricingPlans/PricingPlans.js";
import { LoggerService } from "../services/logger.service.js";
import Otp from "../views/Otp/Otp.js";
import { connect } from "react-redux";
import DisableAutoFill from "../components/DisableAutoFill.js";
import _default from "../config/default.js";
import AlertPopup from "../containers/Modals/Alerts/Generic/GenericAlert.js";
import { store } from "../store/store.js";
import { logout, setCurrentUser } from "../store/actions/authAction.js";
import { useHistory } from "react-router-dom";
import { isExpired } from "../store/actions/loadingAction.js";

const useStyles = makeStyles(styles);
const loggerService = new LoggerService();
const { Axios } = _default;
// Axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response.status === 403) {
//       const user = {
//         ...store.getState().auth?.user,
//         is_subscribed: false,
//       };
//       store.dispatch(setCurrentUser(user));
//       store.dispatch(isExpired(true))
//       setOpenAlert(true);
//     } else {
//       return Promise.reject(error);
//     }
//   }
// );
function AuthScreenWrapper(props) {
  const [openAlert, setOpenAlert] = useState(false);
  const history = useHistory;

  React.useEffect(() => {
    // (process.env.NODE_ENV === "production" ||
    //   process.env.REACT_APP_ENV === "STAGING") &&
    //   loggerService.GlobalDebug(false);
    return () => {
      // store.dispatch(isExpired(false))
    };
  }, []);
  const classes = useStyles();
  const switchRoutes = (
    <Switch>
      {authRoutes.map((prop, key) => {
        if (prop.layout === "/auth") {
          if (prop.path !== "/mail-success") {
            return (
              <AuthRoute
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          } else {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          }
        }
        return null;
      })}
      <PrivateRoute to="/auth/subscriptions" component={PricingPlans} />
      <AuthRoute to="/auth/otp" component={Otp} />
    </Switch>
  );
  return (
    <>
      <Grid
        container
        wrap="wrap"
        direction="row"
        justifyContent="center"
        className="h-100 background-yellow"
        // className={
        //     props?.location?.pathname === "/auth/login" ||
        //     props?.location?.pathname === "/auth/mail-success" ||
        //     props?.location?.pathname === "/auth/forgot-password/" ||
        //     props?.location?.pathname == "/auth/reset-password"
        //         ? classes.AuthScrBg +
        //         " hieghtInMobile alignContentStart align-content-sm-start align-content-md-start align-content-lg-center h-100 p-0 bgWhiteMobile"
        //         : classes.AuthScrBg +
        //         " alignContentStart align-content-sm-start align-content-md-start align-content-lg-center h-100 p-0 bgWhiteMobile"
        // }
        // className="h_100per authBg"
      >
        <DisableAutoFill />
        {/* <Grid item xs={12} sm={12} md={12} className="mb-5 d-xs-block d-sm-block d-md-block d-lg-none">
                    <div className="w-100 d-xs-block d-sm-block d-md-block d-lg-none px-3 py-4 theme_bg">
                        <img src={LogoGreen}/>
                    </div>
                </Grid> */}
        {/* <Grid
                    item
                    xs={12} sm={12} md={12}
                    className="h-100 px-xs-5 px-sm-5 px-md-5 px-lg-0 d-xs-none d-sm-none d-md-none d-lg-block"
                >
                    <img
                        src={Logo}
                        className={
                            classes.logo + " " + "d-xs-none d-sm-none d-md-none d-lg-block"
                        }
                        height='60'
                    />
                </Grid> */}
        {props?.location?.pathname === "/auth/login" ||
        props?.location?.pathname === "/auth/mail-success" ||
        props?.location?.pathname === "/auth/forgot-password/" ||
        props?.location?.pathname == "/auth/reset-password" ? (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              flex
              flexDirection
              className="d-flex align-items-center px-xs-5 px-sm-5 px-md-5 px-lg-0 h-100 bg-white"
            >
              <Grid container>
                {/* {props.expired && (
                  <AlertPopup
                    btnText={"Yes"}
                    cancelText={"No"}
                    open={props.expired}
                    type={"Warning"}
                    heading={"Subscription"}
                    message={
                      "Subscription expired or trial period is over. You need to subscribe to conitnue using TagData. Would you like to continue?"
                    }
                    isLoading={false}
                    handleClose={() => {
                      store.dispatch(isExpired(false))
                      store.dispatch(logout(history));
                    }}
                    handleClick={() => {
                      history.push("/auth/subscriptions");
                    }}
                  />
                )} */}
                <Grid item xs={12} md={4}></Grid>
                <Grid item xs={12} md={4} className="px-4 bgWhiteMobile">
                  {switchRoutes}
                </Grid>
                <Grid item xs={12} md={4}></Grid>
              </Grid>
            </Grid>
          </>
        ) : props?.location?.pathname == "/auth/otp" ? (
          <>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              className={
                props?.loadAnimation === false
                  ? "d-flex px-5 background-yellow"
                  : "d-flex px-5"
              }
              direction="column"
              justifyContent="center"
            >
              <Grid container>
                <Grid item xs={12} md={4}></Grid>
                <Grid item xs={12} md={4}>
                  {switchRoutes}
                </Grid>
                <Grid item xs={12} md={4}></Grid>
              </Grid>
            </Grid>
          </>
        ) : props?.location?.pathname == "/auth/subscriptions" ? (
          <>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              className="d-flex px-xs-5 px-sm-5 px-md-5 px-lg-0 bgWhiteMobile bg-white overflow-scroll"
              direction="column"
              justifyContent="center"
            >
              <Grid container>
                <Grid item xs={12} md={2}></Grid>
                <Grid item xs={12} md={8} className="my-5">
                  {switchRoutes}
                </Grid>
                <Grid item xs={12} md={2}></Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              className="d-flex px-xs-5 px-sm-5 px-md-5 px-lg-0 bgWhiteMobile bg-white"
              direction="column"
              justifyContent="center"
            >
              <Grid container>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={6} className="my-5">
                  {switchRoutes}
                </Grid>
                <Grid item xs={12} md={3}></Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state?.auth?.loading,
    expired: state?.loading?.expired,
    authError: state?.auth?.error,
    animation: state?.auth?.animation,
    loadAnimation: state?.auth?.loadAnimation,
  };
};

export default connect(mapStateToProps)(AuthScreenWrapper);
