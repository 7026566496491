
import _default from "../config/default";
import {store} from "../store/store";

export class Search {
    constructor() {
        this.BASE_URL = _default.BASE_URL;
        this.AUTH_ENDPOINT = _default.ENDPOINT_AUTH;
    }

    search(query) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/search?q=${query}`,
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    searchIndex(type, id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/index`,
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
            params: {
                searchable_type: type,
                searchable_id: id
            }
        });
    }
}
