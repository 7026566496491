
import _default from "../config/default";
import {store} from "../store/store";

export class VehicleService {
    constructor() {
        this.BASE_URL = _default.BASE_URL;
        this.AUTH_ENDPOINT = _default.ENDPOINT_AUTH;
    }

    autoServiceIndex() {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/auto_services`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    autoServiceShow(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/auto_services/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    autoServiceCreate(data) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/auto_services`,
            headers: {
                "Content-Type": "multipart/form-data",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "POST",
            data: data,
        });
    }

    autoServiceDelete(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/auto_services/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "Delete",
        });
    }

    autoServiceDeleteAll(payload) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/destroy_all_auto_services`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "Delete",
            data: {
                ids: payload
            }
        });
    }

    autoServiceEdit(data, id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/auto_services/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "PUT",
            data: data,
        });
    }

    vehicleIndex() {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/vehicles`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    vehicleShow(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/vehicles/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    createVehicle(data) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/vehicles`,
            headers: {
                "Content-Type": "multipart/form-data",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "POST",
            data: data,
        });
    }

    editVehicle(data, id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/vehicles/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "PUT",
            data: data,
        });
    }

    vehicleDestroy(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/vehicles/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
        });
    }

    vehicleDestroyAll(payload) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/destroy_all_vehicles`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
            data: {
                ids: payload
            }
        });
    }
}
