import "date-fns";
import React, {useState} from "react";
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import {FormInput} from "../../../components/FormInput/FormInput.js";
import {CustomButton} from "../../../components/CustomButton/Button.js";
import {Close} from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Auth} from "../../../services/auth.service";
import {store} from "../../../store/store";


const useStyles = makeStyles(styles);

function EditEmail(props) {
    const authService = new Auth();
    const classes = useStyles();
    const [token, setToken] = useState(null)
    const [otp, setOtp] = useState(false)
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState([])
    const [data, setData] = useState({
        new_email: null,
        confirm_email: null,
        password: null,
    })

    const handleSubmit = () => {
        if (data.new_email !== data.confirm_email) {
            setErr(["Emails do not match"])
        } else {
            if (store?.getState()?.auth?.user?.twofa_enabled) {
                const temp = {
                    new_email: data.new_email,
                    confirm_email: data.confirm_email,
                    password: data.password,
                    otp: token
                }
                setErr([])
                setLoading(true)
                authService.updateEmail(temp).then(() => {
                    setLoading(false)
                    props.handleClose()
                }).catch((e) => {
                    setLoading(false)
                    if (e.response) {
                        setErr([e?.response?.data?.message] || e?.response?.data?.errors?.full_messages)
                    } else {
                        setErr(["Internal server error, try again later"])
                    }
                })
            } else {
                setErr([])
                setLoading(true)
                authService.updateEmail(data).then(() => {
                    setLoading(false)
                    props.handleClose()
                }).catch((e) => {
                    setLoading(false)
                    if (e.response) {
                        setErr(e?.response?.data?.errors?.full_messages || [e?.response?.data?.message])
                    } else {
                        setErr(["Internal server error, try again later"])
                    }
                })
            }
        }
    };
    const sendToken = () => {
        if (data.new_email !== data.confirm_email) {
            setErr(["Emails do not match"])
        } else {
            setErr([])
            setLoading(true)
            authService.sendOtp({
                is_login: true,
                email: store?.getState()?.auth?.user?.email,
                password: data.password
            }).then((res) => {
                console.log({otp: res.data})
                setOtp(true)
                setLoading(false)
            }).catch((e) => {
                setLoading(false)
                if (e.response) {
                    setErr([e?.response?.data?.err] || ["An error occurred while requesting OTP, please try again later"])
                } else {
                    setErr(["Internal server error, try again later"])
                }
            })
        }
    };


    const handleChange = (event) => {
        setData({...data, [event.target.name]: event.target.value})
    };

    return (
        <>
            <CustomModal
                visible={props.open}
                onClose={props.handleClose}
                width="55%"
                margin="120px 0 40px"
                inset="15px!important"
            >
                <ModalHeader
                    closeModal={props.handleClose}
                    icon={
                        <Close
                            style={{
                                color: color.white,
                                marginRight: "20px",
                                fontSize: "30px",
                                cursor: "pointer",
                            }}
                        />
                    }
                    heading={"Edit " + store.getState().auth?.user?.email}
                    color={color.white}
                />
                <div className={classes.modalBody}>
                    <Grid
                        container
                        wrap="wrap"
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        {err?.length > 0 && (
                            <div className="w-100 d-flex flex-row mb-5">
                                {
                                    err?.map((msg, key) => {
                                        return (
                                            <Typography
                                                key={key}
                                                variant="p"
                                                component="p"
                                                align="left"
                                                style={{color: "red", fontWeight: "normal"}}
                                            >
                                                {msg}
                                            </Typography>
                                        )
                                    })
                                }
                            </div>
                        )}
                        {
                            !otp ? <>
                                <Grid item xs={12} sm={3} md={3}>
                                    <Typography
                                        variant="h6"
                                        component="h3"
                                        style={{color: color.themeColorNew}}
                                    >
                                        {"New Email*"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={9} md={9}>
                                    <FormInput
                                        name="new_email"
                                        onChange={handleChange}
                                        label=""
                                        variant="outlined"
                                        type={"email"}
                                        fullWidth={true}
                                        margin="dense"
                                        required={true}
                                        placeholder={"New Email"}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}>
                                    <Typography
                                        variant="h6"
                                        component="h3"
                                        style={{color: color.themeColorNew}}
                                    >
                                        {"Confirm Email*"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={9} md={9}>
                                    <FormInput
                                        name="confirm_email"
                                        onChange={handleChange}
                                        label=""
                                        variant="outlined"
                                        fullWidth={true}
                                        margin="dense"
                                        type={"email"}
                                        required={true}
                                        placeholder={"Confirm Email"}
                                    />
                                </Grid>
                                <div className="w-100 d-flex flex-row m-3">
                                    <Typography
                                        variant="p"
                                        component="p"
                                        align="left"
                                        style={{color: "red", fontWeight: "normal"}}
                                    >
                                        Please enter your TagData's master pasword to save your new email address.
                                    </Typography>
                                </div>
                                <Grid item xs={12} sm={3} md={3}>
                                    <Typography
                                        variant="h6"
                                        component="h3"
                                        style={{color: color.themeColorNew}}
                                    >
                                        {"Password*"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={9} md={9}>
                                    <FormInput
                                        name="password"
                                        onChange={handleChange}
                                        label=""
                                        variant="outlined"
                                        fullWidth={true}
                                        type="text"
id="txtPassword"
                                        margin="dense"
                                        required={true}
                                        placeholder={"Password"}
                                    />
                                </Grid></> : <>
                                <div className="w-100 d-flex flex-row m-3">
                                    <Typography
                                        variant="p"
                                        component="p"
                                        align="left"
                                        style={{color: "red", fontWeight: "normal"}}
                                    >
                                        Please enter the code sent on your phone number to continue.
                                    </Typography>
                                </div>
                                <Grid item xs={12} sm={3} md={3}>
                                    <Typography
                                        variant="h6"
                                        component="h3"
                                        style={{color: color.themeColorNew}}
                                    >
                                        {"OTP*"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={9} md={9}>
                                    <FormInput
                                        name="otp"
                                        onChange={(e) => setToken(e.target.value)}
                                        label=""
                                        variant="outlined"
                                        fullWidth={true}
                                        margin="dense"
                                        required={true}
                                        placeholder={"OTP"}
                                    />
                                </Grid></>
                        }
                        <Grid item xs={12} sm={12} md={12} className="text-sm-center" style={{textAlign: "right"}}>
                            {!loading && !otp ? <CustomButton
                                type="submit"
                                btnText={store?.getState()?.auth?.user?.twofa_enabled ? "Request OTP" : "Save"}
                                textAlign="center"
                                display={"block"}
                                padding="12px 25px"
                                minWidth="113px"
                                background="#0042a8"
                                hoverBg="#0042a8"
                                border="0"
                                marginRight="10px"
                                disabled={!data.new_email || !data.confirm_email || !data.password}
                                borderRadius="8px"
                                color="#fff"
                                onClick={store?.getState()?.auth?.user?.twofa_enabled ? sendToken : handleSubmit}
                            /> : !loading && otp ? <CustomButton
                                type="submit"
                                btnText={"Save"}
                                textAlign="center"
                                display={"block"}
                                padding="12px 25px"
                                minWidth="113px"
                                background="#0042a8"
                                hoverBg="#0042a8"
                                border="0"
                                marginRight="10px"
                                disabled={!token}
                                borderRadius="8px"
                                color="#fff"
                                onClick={handleSubmit}
                            /> : <CustomButton
                                type="submit"
                                btnText={<CircularProgress size={20} color="#DDD"/>}
                                textAlign="center"
                                display={"block"}
                                padding="12px 25px"
                                minWidth="113px"
                                background="#0042a8"
                                hoverBg="#0042a8"
                                border="0"
                                marginRight="10px"
                                borderRadius="8px"
                                color="#fff"
                            />}


                        </Grid>
                    </Grid>
                </div>
            </CustomModal>
        </>
    );
}

export default EditEmail;
