import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import styles from "../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { logout } from "../../store/actions/authAction";
import { useEffect } from "react";
import Administrators from "./PermissionTabsContent/Administrators.js";
import IndividualAccess from "./PermissionTabsContent/IndividualAccess.js";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  useEffect(() => {}, [props.profile]);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(styles);

const options = ["Edit", "View", "Share", "Delete"];

function SimpleTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <AppBar position="static" color="transparent" elevation="0">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          className={classes.tabsMain}
        >
          <Tab label="Administrators" {...a11yProps(0)} />
          <Tab label="Individual Access" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <Grid container wrap="wrap" direction="row">
        <Grid item xs={12} sm={12} md={9} lg={6}>
          <div className={classes.root}>
            <TabPanel value={value} index={0}>
              <Administrators />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <IndividualAccess />
            </TabPanel>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};
const mapStateToProps = (state, ownProps) => {
  return {
    profile: state.profile.profileInfo,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SimpleTabs);
