import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "../../Card/Card";
import { FormControl, Grid, MenuItem, Typography } from "@material-ui/core";
import { store } from "../../../store/store";
import Select from "@material-ui/core/Select";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { CustomButton } from "../../CustomButton/Button";
import AlertPrefrence from "../../../containers/Modals/AlertPrefrence/AlertPrefrence";
import alertPrefrence from "../../../containers/Modals/AlertPrefrence/AlertPrefrence";
import { useHistory } from "react-router-dom";
import EditEmailAddress from "../../../containers/Modals/EditEmailAddress/EditEmailAddress";
import EditPassword from "../../../containers/Modals/EditPassword/EditPassword";
import EditEmailPrefrence from "../../../containers/Modals/EditEmailPrefrence/EditEmailPrefrence";
import AddMasking from "../../../containers/Modals/AddMasking/AddMasking";
import { Auth } from "../../../services/auth.service";
import SuccessGreen from "../../../containers/Modals/Alerts/SuccessGreen/SuccessGreen";
import Error from "../../../containers/Modals/Alerts/Error/Error";
import {
  logoutAll,
  pauseNotification,
  setTimeZone,
} from "../../../store/actions/authAction";
import PasswordAuthentication from "../../../containers/Modals/PasswordAuthentication/PasswordAuthentication";
import ProfileDeletionConfirmed from "../../../containers/Modals/ProfileDeletionConfirmed/ProfileDeletionConfirmed";
import OtpModal from "../../../containers/Modals/OtpModal/OtpModal";
import DeleteAccountConfirmation from "../../../containers/Modals/DeleteAccountConfirmation/DeleteAccountConfirmation";
import EditEmail from "../../../containers/Modals/EditEmail/EditEmail";
import EditPhoneNumber from "../../../containers/Modals/EditPhoneNumber/EditPhoneNumber";
import { connect, useDispatch } from "react-redux";

const useStyles = makeStyles(styles);
const timezonee = [
  "International Date Line West",
  "American Samoa",
  "Midway Island",
  "Hawaii",
  "Alaska",
  "Pacific Time (US & Canada)",
  "Tijuana",
  "Arizona",
  "Chihuahua",
  "Mazatlan",
  "Mountain Time (US & Canada)",
  "Central America",
  "Central Time (US & Canada)",
  "Guadalajara",
  "Mexico City",
  "Monterrey",
  "Saskatchewan",
  "Bogota",
  "Eastern Time (US & Canada)",
  "Indiana (East)",
  "Lima",
  "Quito",
  "Atlantic Time (Canada)",
  "Caracas",
  "Georgetown",
  "La Paz",
  "Puerto Rico",
  "Santiago",
  "Newfoundland",
  "Brasilia",
  "Buenos Aires",
  "Greenland",
  "Montevideo",
  "Mid-Atlantic",
  "Azores",
  "Cape Verde Is.",
  "Edinburgh",
  "Lisbon",
  "London",
  "Monrovia",
  "UTC",
  "Amsterdam",
  "Belgrade",
  "Berlin",
  "Bern",
  "Bratislava",
  "Brussels",
  "Budapest",
  "Casablanca",
  "Copenhagen",
  "Dublin",
  "Ljubljana",
  "Madrid",
  "Paris",
  "Prague",
  "Rome",
  "Sarajevo",
  "Skopje",
  "Stockholm",
  "Vienna",
  "Warsaw",
  "West Central Africa",
  "Zagreb",
  "Zurich",
  "Athens",
  "Bucharest",
  "Cairo",
  "Harare",
  "Helsinki",
  "Jerusalem",
  "Kaliningrad",
  "Kyiv",
  "Pretoria",
  "Riga",
  "Sofia",
  "Tallinn",
  "Vilnius",
  "Baghdad",
  "Istanbul",
  "Kuwait",
  "Minsk",
  "Moscow",
  "Nairobi",
  "Riyadh",
  "St. Petersburg",
  "Volgograd",
  "Tehran",
  "Abu Dhabi",
  "Baku",
  "Muscat",
  "Samara",
  "Tbilisi",
  "Yerevan",
  "Kabul",
  "Ekaterinburg",
  "Islamabad",
  "Karachi",
  "Tashkent",
  "Chennai",
  "Kolkata",
  "Mumbai",
  "New Delhi",
  "Sri Jayawardenepura",
  "Kathmandu",
  "Almaty",
  "Astana",
  "Dhaka",
  "Urumqi",
  "Rangoon",
  "Bangkok",
  "Hanoi",
  "Jakarta",
  "Krasnoyarsk",
  "Novosibirsk",
  "Beijing",
  "Chongqing",
  "Hong Kong",
  "Irkutsk",
  "Kuala Lumpur",
  "Perth",
  "Singapore",
  "Taipei",
  "Ulaanbaatar",
  "Osaka",
  "Sapporo",
  "Seoul",
  "Tokyo",
  "Yakutsk",
  "Adelaide",
  "Darwin",
  "Brisbane",
  "Canberra",
  "Guam",
  "Hobart",
  "Melbourne",
  "Port Moresby",
  "Sydney",
  "Vladivostok",
  "Magadan",
  "New Caledonia",
  "Solomon Is.",
  "Srednekolymsk",
  "Auckland",
  "Fiji",
  "Kamchatka",
  "Marshall Is.",
  "Wellington",
  "Chatham Is.",
  "Nuku'alofa",
  "Samoa",
  "Tokelau Is.",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AccountOptions({ notification }) {
  const authService = new Auth();
  const history = useHistory();
  const [password, setPassword] = useState(false);
  const classes = useStyles();
  const [editPhone, setEditPhone] = useState(false);
  const [otp, setOtp] = useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [msg, setMsg] = React.useState(null);
  const [timezone, setTimezone] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openAlertPrefrence, setOpenAlertPrefrence] = React.useState(false);
  const [openMasking, setOpenMasking] = React.useState(false);
  const [openEmailAddress, setOpenEmailAddress] = React.useState(false);
  const [openPassword, setOpenPassword] = React.useState(false);
  const [editEmail, setEditEmail] = React.useState(false);
  const [deletion, setDeletion] = useState(false);
  const [openEmailPrefrence, setOpenEmailPrefrence] = React.useState(false);
  const [openDeleteAccountConfirmation, setOpenDeleteAccountConfirmation] =
    React.useState(false);

  useEffect(() => {
    setTimezone(store.getState().auth?.user?.time_zone);
  }, []);
  const handleOpenAlertPrefrence = () => {
    setOpenAlertPrefrence(true);
  };
  const handleCloseAlertPrefrence = () => {
    setOpenAlertPrefrence(false);
  };

  const handleTimeZone = (e) => {
    setTimezone(e.target.value);
  };

  const handleOpenEmailAddress = () => {
    setOpenEmailAddress(true);
  };
  const handleCloseEmailAddress = () => {
    setOpenEmailAddress(false);
  };

  const handleUpdateTimezone = async () => {
    try {
      let res = await authService.updateTimeZone(timezone);
      store.dispatch(setTimeZone(timezone));
      setOpen(true);
    } catch (e) {}
  };
  const handleOpenPassword = () => {
    setOpenPassword(true);
  };
  const handleClosePassword = () => {
    setOpenPassword(false);
  };

  const dispatch = useDispatch();

  const handleOpenEmailPrefrence = () => {
    setOpenEmailPrefrence(true);
  };
  const handleCloseEmailPrefrence = () => {
    setOpenEmailPrefrence(false);
  };
  return (
    <>
      <Card className={classes.accountCard}>
        <Grid item xs={12} md={12} className={classes.marginBottom18px}>
          <Typography variant="h5" component="h3" style={{ color: "#060d5c" }}>
            {"Account Information"}
          </Typography>
        </Grid>
        <Grid container className={classes.marginBottom18px}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="p"
              component="p"
              className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
              style={{ color: "#ffb722", fontWeight: "bold" }}
            >
              {"Account Type"}
            </Typography>
            <Typography variant="p" component="p" style={{ color: "#060d5c" }}>
              {store.getState().auth.user?.account_type}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            className="justify-content-sm-end justify-content-start mt-sm-0 mt-3"
          >
            <CustomButton
              onClick={() => history.push("/auth/subscriptions")}
              btnText={"Upgrade"}
              padding="15px 25px"
              minWidth="113px"
              height="50px"
              background="#0042a8"
              hoverBg="#0042a8"
              color="#fff"
            />
          </Grid>
        </Grid>
        <Grid container className={classes.marginBottom18px}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="p"
              component="p"
              className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
              style={{ color: "#ffb722", fontWeight: "bold" }}
            >
              {"Password"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            className="justify-content-sm-end justify-content-start mt-sm-0 mt-3"
          >
            <CustomButton
              btnText={"Edit"}
              padding="15px 25px"
              minWidth="113px"
              height="50px"
              background="#fff"
              hoverBg="#0042a8"
              color="#0042a8"
              border="2px solid #0042a8"
              onClick={() => handleOpenPassword()}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.marginBottom18px}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="p"
              component="p"
              className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
              style={{ color: "#ffb722", fontWeight: "bold" }}
            >
              {"Email"}
            </Typography>
            <Typography variant="p" component="p" style={{ color: "#060d5c" }}>
              {store.getState().auth.user?.email}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            className="justify-content-sm-end justify-content-start mt-sm-0 mt-3"
          >
            <CustomButton
              btnText={"Edit"}
              padding="15px 25px"
              minWidth="113px"
              height="50px"
              background="#fff"
              hoverBg="#0042a8"
              color="#0042a8"
              border="2px solid #0042a8"
              onClick={() => setEditEmail(true)}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.marginBottom18px}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="p"
              component="p"
              className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
              style={{ color: "#ffb722", fontWeight: "bold" }}
            >
              {"Phone Number"}
            </Typography>
            <Typography variant="p" component="p" style={{ color: "#060d5c" }}>
              {store.getState().auth.user?.phone}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            className="justify-content-sm-end justify-content-start mt-sm-0 mt-3"
          >
            <CustomButton
              btnText={"Edit"}
              padding="15px 25px"
              minWidth="113px"
              height="50px"
              background="#fff"
              hoverBg="#0042a8"
              color="#0042a8"
              border="2px solid #0042a8"
              onClick={() => setEditPhone(true)}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.marginBottom18px}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="p"
              component="p"
              className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
              style={{ color: "#ffb722", fontWeight: "bold" }}
            >
              {"Email Preference"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            className="justify-content-sm-end justify-content-start mt-sm-0 mt-3"
          >
            <CustomButton
              btnText={"Edit"}
              padding="15px 25px"
              minWidth="113px"
              height="50px"
              background="#fff"
              hoverBg="#0042a8"
              color="#0042a8"
              border="2px solid #0042a8"
              onClick={() => handleOpenEmailPrefrence()}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.marginBottom18px}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="p"
              component="p"
              className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
              style={{ color: "#ffb722", fontWeight: "bold" }}
            >
              {"Alert Preferences"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            className="justify-content-sm-end justify-content-start mt-sm-3 mt-md-0 mt-0"
          >
            <CustomButton
              btnText={"Edit"}
              padding="15px 25px"
              minWidth="113px"
              height="50px"
              background="#fff"
              hoverBg="#0042a8"
              color="#0042a8"
              border="2px solid #0042a8"
              onClick={() => handleOpenAlertPrefrence()}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.marginBottom18px}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="p"
              component="p"
              className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
              style={{ color: "#ffb722", fontWeight: "bold" }}
            >
              {"Notifications"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            className="justify-content-sm-end justify-content-start mt-sm-3 mt-md-0 mt-0"
          >
            <CustomButton
              btnText={notification === true ? "Resume" : "Pause"}
              padding="15px 25px"
              minWidth="113px"
              height="50px"
              background="#fff"
              hoverBg="#0042a8"
              color="#0042a8"
              border="2px solid #0042a8"
              onClick={() => {
                authService
                  .axielNotifications(!notification)
                  .then((res) => {
                    setMsg(
                      !notification
                        ? "Notifications have been paused"
                        : "You have resumed the notifications successfully"
                    );
                    dispatch(pauseNotification(!notification));
                    setOpen(true);
                  })
                  .catch((err) => {});
              }}
            />
          </Grid>
        </Grid>
        <Grid container className="">
          <Grid item xs={12} md={6}>
            <Typography
              variant="p"
              component="p"
              className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
              style={{ color: "#ffb722", fontWeight: "bold" }}
            >
              {"Time Zone"}
            </Typography>
            <FormControl
              id="security-question"
              fullWidth={true}
              className="mb-3"
            >
              <Select
                id="security-question-2"
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                name="q2"
                onChange={handleTimeZone}
                defaultValue={store.getState().auth?.user?.time_zone}
                displayEmpty
                placeholder={"(GMT-5:00) Eastern Time (US & Canada)"}
                inputProps={{ "aria-label": "Without label" }}
                MenuProps={MenuProps}
              >
                {timezonee?.map((tz, key) => {
                  return (
                    <MenuItem key={key} value={tz}>
                      <em>{tz}</em>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            className="justify-content-sm-end justify-content-start"
          >
            <CustomButton
              btnText={"Save"}
              onClick={handleUpdateTimezone}
              padding="15px 25px"
              timeZone={true}
              minWidth="113px"
              height="50px"
              disabled={timezone === store.getState().auth?.user?.time_zone}
              background="#0042a8"
              hoverBg="#0042a8"
              color="#fff"
            />
          </Grid>
        </Grid>
      </Card>
      <Card className={classes.accountCard}>
        <Grid item xs={12} md={12} className={classes.marginBottom18px}>
          <Typography variant="h5" component="h3" style={{ color: "#060d5c" }}>
            {"Privacy Settings"}
          </Typography>
        </Grid>
        <Grid container className={classes.marginBottom18px}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="p"
              component="p"
              className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
              style={{ color: "#ffb722", fontWeight: "bold" }}
            >
              {"Mask sensitive information?"}
            </Typography>
            <Typography
              variant="p"
              component="p"
              style={{
                color: store.getState().auth?.user?.masking ? "#28A745" : "red",
              }}
            >
              {store.getState().auth?.user?.masking ? "On" : "Off"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            className="justify-content-sm-end justify-content-start mt-sm-0 mt-3"
          >
            <CustomButton
              onClick={() => setOpenMasking(true)}
              btnText={"Edit"}
              padding="15px 25px"
              minWidth="113px"
              height="50px"
              background="#fff"
              hoverBg="#0042a8"
              color="#0042a8"
              border="2px solid #0042a8"
            />
          </Grid>
        </Grid>
        {store.getState().auth?.user?.us_or_canadian_user && (
          <Grid container className={classes.marginBottom18px}>
            <Grid item xs={12} md={6}>
              <Typography
                variant="p"
                component="p"
                className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
                style={{ color: "#ffb722", fontWeight: "bold" }}
              >
                {"Two-Factor Authentication"}
              </Typography>
              <Typography
                variant="p"
                component="p"
                style={{
                  color: store.getState().auth?.user?.twofa_enabled
                    ? "#28A745"
                    : "red",
                }}
              >
                {store.getState().auth?.user?.twofa_enabled ? "On" : "Off"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              container
              className="justify-content-sm-end justify-content-start mt-sm-0 mt-3"
            >
              <CustomButton
                onClick={() => setPassword(true)}
                btnText={"Edit"}
                padding="15px 25px"
                minWidth="113px"
                height="50px"
                background="#fff"
                hoverBg="#0042a8"
                color="#0042a8"
                border="2px solid #0042a8"
              />
            </Grid>
          </Grid>
        )}
        <Grid container className={classes.marginBottom18px}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="p"
              component="p"
              className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
              style={{ color: "#ffb722", fontWeight: "bold" }}
            >
              {"Logout of all devices"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            container
            className="justify-content-sm-end justify-content-start mt-sm-0 mt-3"
          >
            <CustomButton
              onClick={() => store.dispatch(logoutAll())}
              btnText={"Logout"}
              padding="15px 25px"
              minWidth="113px"
              height="50px"
              background="#fff"
              hoverBg="#0042a8"
              color="#0042a8"
              border="2px solid #0042a8"
            />
          </Grid>
        </Grid>
      </Card>
      <Card className={classes.accountCard}>
        <Grid item xs={12} md={12} className={classes.marginBottom18px}>
          <Typography variant="h5" component="h3" style={{ color: "#060d5c" }}>
            {"Account Deletion"}
          </Typography>
        </Grid>
        <Grid container className={classes.marginBottom18px}>
          <Grid item xs={12} md={10}>
            <Typography
              variant="p"
              component="p"
              className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
            >
              {
                "Deleting a TagData account is irreversible. Please contact support@tagdata.co with any questions."
              }
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <CustomButton
              onClick={() => setOpenDeleteAccountConfirmation(true)}
              btnText={"Delete"}
              padding="15px 25px"
              minWidth="113px"
              height="50px"
              background="#0042a8"
              hoverBg="#0042a8"
              color="#fff"
            />
          </Grid>
        </Grid>
      </Card>

      {alertPrefrence && (
        <AlertPrefrence
          open={openAlertPrefrence}
          handleClose={handleCloseAlertPrefrence}
        />
      )}

      {openError && (
        <Error
          open={openError}
          handleClose={() => setOpenError(false)}
          message="Error occurred while updating the timezone"
        />
      )}

      {open && (
        <SuccessGreen
          open={open}
          handleClose={() => {
            setOpen(false);
            setMsg(null);
          }}
          message={msg ?? "Your timezone has been successfully updated"}
        />
      )}

      {openDeleteAccountConfirmation && (
        <DeleteAccountConfirmation
          open={openDeleteAccountConfirmation}
          handleClose={() => setOpenDeleteAccountConfirmation(false)}
        />
      )}

      <EditEmailAddress
        open={openEmailAddress}
        handleClose={handleCloseEmailAddress}
      />
      {openPassword && (
        <EditPassword open={openPassword} handleClose={handleClosePassword} />
      )}
      {editEmail && (
        <EditEmail open={editEmail} handleClose={() => setEditEmail(false)} />
      )}
      {editPhone && (
        <EditPhoneNumber
          open={editPhone}
          handleClose={() => setEditPhone(false)}
        />
      )}
      <PasswordAuthentication
        open={password}
        handleClose={() => setPassword(false)}
      />
      <AddMasking
        open={openMasking}
        handleClose={() => setOpenMasking(false)}
      />

      {openPassword && (
        <EditPassword open={openPassword} handleClose={handleClosePassword} />
      )}
      {password && (
        <PasswordAuthentication
          setOtp={setOtp}
          open={password}
          handleClose={() => setPassword(false)}
        />
      )}
      {deletion && (
        <ProfileDeletionConfirmed
          open={deletion}
          handleClose={() => setDeletion(false)}
        />
      )}
      {openMasking && (
        <AddMasking
          open={openMasking}
          handleClose={() => setOpenMasking(false)}
        />
      )}
      {otp && <OtpModal open={otp} handleClose={() => setOtp(false)} />}
      {openEmailPrefrence && (
        <EditEmailPrefrence
          open={openEmailPrefrence}
          handleClose={handleCloseEmailPrefrence}
        />
      )}
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    notification: state?.auth?.user?.pause_notification,
  };
};

export default connect(mapStateToProps)(AccountOptions);
