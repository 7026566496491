import {blackColor, hexToRgb, whiteColor,} from "../../material-dashboard-react.js";

const cardStyle = (theme) => ({
    card: {
        padding: "20px 0",
        border: "1px solid #0042a8",
        marginBottom: "30px",
        marginTop: "30px",
        borderRadius: "30px",
        color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
        background: whiteColor,
        width: "100%",
        boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        minWidth: "0",
        wordWrap: "break-word",
        // fontSize: ".875rem",
        fontSize: "12px",
        transition: "0.3s",
        [theme.breakpoints.down("sm")]: {
            marginTop: "0px",
        },
    },
    cardFavorites: {
        "&:hover": {
            transform: "scale(1.04) translateY(-4%)",
        }
    },
    cardPlain: {
        background: "transparent",
        boxShadow: "none",
    },
    cardProfile: {
        marginTop: "30px",
        textAlign: "center",
    },
    cardChart: {
        "& p": {
            marginTop: "0px",
            paddingTop: "0px",
        },
    },
});

export default cardStyle;
