import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "../../Card/Card";
import { Grid, Typography } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { CustomButton } from "../../CustomButton/Button";
import { Auth } from "../../../services/auth.service";
import AddNewCard from "../../../containers/Modals/AddNewCard/AddNewCard";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(styles);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const styless = (theme) => ({
  margin: {
    margin: theme.spacing.unit * 2,
  },
  customBadge: {
    backgroundColor: "#00d768",
    color: "white",
    right: "10px",
  },
});

function PaymentMethods(props) {
  const classess = useStyles();
  const authServive = new Auth();

  const [methods, setMethods] = useState([]);
  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const { classes } = props;
  const [openAddNewCard, setOpenAddNewCard] = React.useState(false);
  const handleOpenAddNewCard = () => {
    setOpenAddNewCard(true);
  };

  const handleDelete = async (id) => {
    setError([]);
    try {
      await authServive.deletePaymentMethod(id);
      const temp = methods.filter((card) => card.id !== id);
      setMethods(temp);
    } catch (err) {
      if (err.response && err.response.data.message) {
        let array = [];
        Object.keys(err.response.data.message).forEach((key) =>
          array.push(`${key} ${err.response.data.message[key]}`)
        );
        setError(array);
      } else {
        setError(["Internal Server Error"]);
      }
    }
  };
  const handleSetActive = async (id) => {
    setLoading(true);
    setError([]);
    try {
      await authServive.updatePaymentMethod(id, true);
      authServive
        .indexPaymentMethod()
        .then((res) => {
          setError([]);
          setLoading(false);
          setMethods(res.data?.payment_method || []);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response && err.response.data.message) {
            let array = [];
            Object.keys(err.response.data.message).forEach((key) =>
              array.push(`${key} ${err.response.data.message[key]}`)
            );
            setError(array);
          } else {
            setError(["Internal Server Error"]);
          }
        });
    } catch (err) {
      if (err.response && err.response.data.message) {
        let array = [];
        Object.keys(err.response.data.message).forEach((key) =>
          array.push(`${key} ${err.response.data.message[key]}`)
        );
        setError(array);
      } else {
        setError(["Internal Server Error"]);
      }
    }
  };

  useEffect(() => {
    if (!openAddNewCard) {
      setLoading(true);
      authServive
        .indexPaymentMethod()
        .then((res) => {
          setError([]);
          setLoading(false);
          setMethods(res.data?.payment_method || []);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response && err.response.data.message) {
            let array = [];
            Object.keys(err.response.data.message).forEach((key) =>
              array.push(`${key} ${err.response.data.message[key]}`)
            );
            setError(array);
          } else {
            setError(["Internal Server Error"]);
          }
        });
    }
  }, [openAddNewCard]);
  return (
    <>
      {loading ? (
        <>
          <Grid
            container
            xs={12}
            md={12}
            alignItems="center"
            className={classess.marginBottom18px + " mb-5"}
          >
            <Grid item xs={12} md={6} className={classess.marginBottom18px}>
              <Typography
                variant="h5"
                component="h3"
                style={{ color: "#060d5c" }}
              >
                <CircularProgress size={20} color={"#DDD"} />
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {methods.length > 0 ? (
            <>
              <Grid
                container
                xs={12}
                md={12}
                alignItems="center"
                className={classess.marginBottom18px + " mb-5"}
              >
                <Grid item xs={12} md={6} className={classess.marginBottom18px}>
                  <Typography
                    variant="h5"
                    component="h3"
                    style={{ color: "#060d5c" }}
                  >
                    {"Payment Info"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  container
                  className="justify-content-sm-end justify-content-start mt-sm-0 mt-3"
                >
                  <CustomButton
                    onClick={() => setOpenAddNewCard(true)}
                    btnText={"Add New"}
                    padding="8px 15px"
                    minWidth="113px"
                    // height="50px"
                    background="#0042a8"
                    hoverBg="#0042a8"
                    color="#fff"
                    borderRadius="25em"
                  />
                </Grid>
              </Grid>
              {methods.map((card, index) => {
                if (card.is_primary) {
                  return (
                    <Badge
                      badgeContent={"Active"}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      classes={{ badge: classes.customBadge }}
                      className="w-100"
                    >
                      <Card className={classess.accountCard + " w-100 py-4"}>
                        <Grid
                          container
                          xs={12}
                          md={12}
                          className={classess.marginBottom18px}
                        >
                          <Grid item xs={12} md={6}>
                            <Typography
                              variant="p"
                              component="p"
                              className={`${classess.normalFontSize} mb-0`}
                              style={{ color: "#ffb722", fontWeight: "bold" }}
                            >
                              {"Card Number"}
                            </Typography>
                          </Grid>
                          <Grid container xs={12} md={12} className="mb-0 mt-2">
                            <Grid item xs={12} md={12}>
                              <Typography
                                variant="p"
                                component="p"
                                style={{ color: "#060d5c" }}
                              >
                                **** **** **** {card?.last4}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            className="justify-content-start mt-3"
                          >
                            {methods.length > 1 && (
                              <CustomButton
                                onClick={() => handleDelete(card?.id)}
                                btnText={"Delete"}
                                textAlign="center"
                                display={"block"}
                                background="transparent"
                                hoverBg="#0042a8"
                                border="1px solid #0042a8"
                                borderRadius="8px"
                                color="#0042a8"
                                padding="2px 4px"
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Card>
                    </Badge>
                  );
                } else {
                  return (
                    <Card className={classess.accountCard + " w-100 py-4"}>
                      <Grid
                        container
                        xs={12}
                        md={12}
                        className={classess.marginBottom18px}
                      >
                        <Grid item xs={12} md={6}>
                          <Typography
                            variant="p"
                            component="p"
                            className={`${classess.normalFontSize} mb-0`}
                            style={{ color: "#ffb722", fontWeight: "bold" }}
                          >
                            {"Card Number"}
                          </Typography>
                        </Grid>
                        <Grid container xs={12} md={12} className="mb-0 mt-2">
                          <Grid item xs={12} md={12}>
                            <Typography
                              variant="p"
                              component="p"
                              style={{ color: "#060d5c" }}
                            >
                              **** **** **** {card?.last4}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={12}
                          className="d-flex flex-row justify-content-start mt-3"
                        >
                          <CustomButton
                            onClick={() => handleSetActive(card?.id)}
                            btnText={"Set Active"}
                            textAlign="center"
                            display={"block"}
                            background="transparent"
                            hoverBg="#0042a8"
                            border="1px solid #0042a8"
                            borderRadius="8px"
                            color="#0042a8"
                            padding="2px 4px"
                            marginRight="5px"
                          />
                          {methods.length > 1 && (
                            <CustomButton
                              onClick={() => handleDelete(card?.id)}
                              btnText={"Delete"}
                              textAlign="center"
                              display={"block"}
                              background="transparent"
                              hoverBg="#0042a8"
                              border="1px solid #0042a8"
                              borderRadius="8px"
                              color="#0042a8"
                              padding="2px 4px"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Card>
                  );
                }
              })}
            </>
          ) : error?.length > 0 ? (
            <div className="w-100 d-flex flex-column mb-5">
              {error?.map((val, key) => {
                return (
                  <Typography
                    key={key}
                    variant="p"
                    component="p"
                    align="left"
                    style={{
                      color: "red",
                      fontWeight: "normal",
                      marginBottom: "2px",
                    }}
                  >
                    {val}
                  </Typography>
                );
              })}
            </div>
          ) : (
            <>
              <Grid
                container
                xs={12}
                md={12}
                alignItems="center"
                className={classess.marginBottom18px + " mb-5"}
              >
                <Grid item xs={12} md={6} className={classess.marginBottom18px}>
                  <Typography
                    variant="h5"
                    component="h3"
                    style={{ color: "#060d5c" }}
                  >
                    {"Payment Info"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  container
                  className="justify-content-sm-end justify-content-start mt-sm-0 mt-3"
                >
                  <CustomButton
                    onClick={() => setOpenAddNewCard(true)}
                    btnText={"Add New"}
                    padding="8px 15px"
                    minWidth="113px"
                    // height="50px"
                    background="#0042a8"
                    hoverBg="#0042a8"
                    color="#fff"
                    borderRadius="25em"
                  />
                </Grid>
              </Grid>
              <div className="w-100 d-flex flex-column mb-5">
                <Typography
                  variant="p"
                  component="p"
                  align="left"
                  style={{
                    fontWeight: "normal",
                    marginBottom: "2px",
                  }}
                >
                  No payment methods yet
                </Typography>
              </div>
            </>
          )}
        </>
      )}
      {openAddNewCard && (
        <AddNewCard
          open={openAddNewCard}
          handleClose={() => setOpenAddNewCard(false)}
        />
      )}
    </>
  );
}

export default withStyles(styless)(PaymentMethods);
