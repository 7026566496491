
export class Validation {
    constructor() {}

    email(data) {
        const validateEmail =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return validateEmail.test(data)
    }

    password(data) {
        const strong =
            /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{12,})/;
        const medium =
            /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))/;
        const length = /(?=.{8,})/;
        const digit = /(?=.*[0-9])/;
        const sChar = /(?=.*[^A-Za-z0-9])/;

        if (!length.test(data)) {
            return "Must be at least 8 characters long.";
        }

        if (!digit.test(data)) {
            return "Must contain at least one digit.";
        }

        if (!sChar.test(data)) {
            return "Must contain at least one special character.";
        }

        if (!length.test(data) && !digit.test(data) && sChar.test(data)) {
            return "Must be contain 8 characters long and one digit.";
        }

        if (!length.test(data) && digit.test(data) && !sChar.test(data)) {
            return "Must be contain 8 characters long and one special character.";
        }

        if (length.test(data) && !digit.test(data) && !sChar.test(data)) {
            return "Must be contain one special character and a digit";
        }

        if (length.test(data) && digit.test(data) && sChar.test(data)) {
            return null;
        }

        if (digit.test(data) && sChar.test(data)) {
            if (strong.test(data) && length.test(data)) {
                return "strong";
            } else if (medium.test(data) && length.test(data)) {
                return "medium";
            } else {
                if (length.test(data) && digit.test(data) && sChar.test(data)) {
                    return "weak";
                }
            }
        }
    }

    // email(data) {
    //   return Axios({
    //     url: `${this.BASE_URL}/${this.AUTH_ENDPOINT}`,
    //     method: "POST",
    //     data: data,
    //   });
    // }
}