
import _default from "../config/default";
import {store} from "../store/store";

export class HealthService {
    constructor() {
        this.BASE_URL = _default.BASE_URL;
        this.AUTH_ENDPOINT = _default.ENDPOINT_AUTH;
    }

    allergiesIndex() {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/allergies`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    allergiesShow(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/allergies/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    createAllergies(data) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/allergies`,
            headers: {
                "Content-Type": "multipart/form-data",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "POST",
            data: data,
        });
    }

    editAllergies(data, id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/allergies/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "PUT",
            data: data,
        });
    }

    allergiesDestroy(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/allergies/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
        });
    }

    allergiesDestroyAll(payload) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/destroy_all_allergies`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
            data: {
                ids: payload
            }
        });
    }

    createFamilyConditions(data) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/family_conditions`,
            headers: {
                "Content-Type": "multipart/form-data",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "POST",
            data: data,
        })
    }

    createTestResults(data) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/test_results`,
            headers: {
                "Content-Type": "multipart/form-data",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "POST",
            data: data,
        });
    }

    familyConditionsIndex() {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/family_conditions`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        })
    }

    familyConditionsShow(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/family_conditions/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        })
    }

    testResultsIndex() {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/test_results`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    testResultsShow(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/test_results/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    editFamilyConditions(data, id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/family_conditions/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "PUT",
            data: data,
        })
    }

    editTestResults(data, id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/test_results/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "PUT",
            data: data,
        });
    }

    familyConditionsDestroy(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/family_conditions/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
        })
    }

    familyConditionsDestroyAll(payload) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/destroy_all_family_conditions`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
            data: {
                ids: payload
            }
        })
    }

    testResultsDestroy(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/test_results/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
        });
    }

    testResultsDestroyAll(payload) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/destroy_all_test_results`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
            data: {
                ids: payload
            }
        });
    }

    createProcedures(data) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/procedures`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "POST",
            data: data,
        });
    }

    proceduresIndex() {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/procedures`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    proceduresShow(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/procedures/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    editProcedures(data, id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/procedures/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "PUT",
            data: data,
        });
    }

    proceduresDestroy(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/procedures/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
        });
    }

    proceduresDestroyAll(payload) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/destroy_all_procedures`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
            data: {
                ids: payload
            }
        });
    }

    createImmunizations(data) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/immunizations`,
            headers: {
                "Content-Type": "multipart/form-data",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "POST",
            data: data,
        });
    }

    immunizationsIndex() {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/immunizations`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    immunizationsShow(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/immunizations/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    editImmunizations(data, id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/immunizations/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "PUT",
            data: data,
        });
    }

    immunizationsDestroyAll(payload) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/destroy_all_immunizations`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
            data: {
                ids: payload
            }
        });
    }

    createHospitalizations(data) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/hospitalizations`,
            headers: {
                "Content-Type": "multipart/form-data",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "POST",
            data: data,
        });
    }

    hospitalizationsIndex() {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/hospitalizations`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    hospitalizationsShow(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/hospitalizations/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    editHospitalizations(data, id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/hospitalizations/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "PUT",
            data: data,
        });
    }

    hospitalizationsDestroy(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/hospitalizations/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
        });
    }

    hospitalizationsDestroyAll(payload) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/destroy_all_hospitalizations`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
            data: {
                ids: payload
            }
        });
    }

    createHealthConditions(data) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/health_conditions`,
            headers: {
                "Content-Type": "multipart/form-data",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "POST",
            data: data,
        });
    }

    healthConditionsIndex() {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/health_conditions`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    healthConditionsShow(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/health_conditions/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    editHealthConditions(data, id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/health_conditions/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "PUT",
            data: data,
        });
    }

    healthConditionsDestroy(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/health_conditions/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
        });
    }

    healthConditionsDestroyAll(payload) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/destroy_all_health_conditions`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
            data: {
                ids: payload
            }
        });
    }

    createMedications(data) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/medications`,
            headers: {
                "Content-Type": "multipart/form-data",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "POST",
            data: data,
        });
    }

    medicationsIndex() {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/medications`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    medicationsShow(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/medications/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    editMedications(data, id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/medications/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "PUT",
            data: data,
        });
    }

    medicationsDestroy(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/medications/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
        });
    }

    medicationsDestroyAll(payload) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/destroy_all_medications`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
            data: {
                ids: payload
            }
        });
    }

    createTaxReturns(data) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/tax_returns`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "POST",
            data: data,
        });
    }

    taxReturnsIndex() {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/tax_returns`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    taxReturnsShow(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/tax_returns/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    editTaxReturns(data, id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/tax_returns/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "PUT",
            data: data,
        });
    }

    taxReturnsDestroy(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/tax_returns/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
        });
    }

    createWellnesses(data) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/wellness`,
            headers: {
                "Content-Type": "multipart/form-data",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "POST",
            data: data,
        });
    }

    wellnessesIndex() {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/wellness`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    wellnessesShow(id) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/wellness/${id}`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "GET",
        });
    }

    editWellnesses(data) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/wellness`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "PUT",
            data: data,
        });
    }

    wellnessesDestroy() {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/wellness`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
        });
    }

    wellnessesDestroyAll(payload) {
        return _default.Axios({
            url: `${this.BASE_URL}/api/v1/profiles/${
                store.getState().profile?.profileInfo?.profile?.id
            }/destroy_all_wellness`,
            headers: {
                "Content-Type": "application/json",
                client: JSON.parse(localStorage.getItem("brow$3rCt")),
                uid: JSON.parse(localStorage.getItem("uid")),
                "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
            },
            method: "DELETE",
            data: {
                ids: payload
            }
        });
    }
}
