import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// core components
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import { Box, Typography } from "@material-ui/core";
import { Search } from "../../services/search.service";
import CertificateInformation from "../../containers/Modals/CertificateInformation/CertificateInformation";
import EditCertificate from "../../containers/Modals/EditCertificate/EditCertificate";
import LanguageInformation from "../../containers/Modals/LanguageInformation/LanguageInformation";
import EditLanguage from "../../containers/Modals/EditLanguage/EditLanguage";
import EducationInformation from "../../containers/Modals/EducationInformation/EducationInformation";
import EditEducation from "../../containers/Modals/EditEducation/EditEducation";
import AwardInformation from "../../containers/Modals/AwardInformation/AwardInformation";
import EditAward from "../../containers/Modals/EditAward/EditAward";
import EmployerInformation from "../../containers/Modals/EmployerInformation/EmployerInformation";
import EditEmployer from "../../containers/Modals/EditEmployer/EditEmployer";
import SkillsInformation from "../../containers/Modals/SkillsInformation/SkillsInformation";
import EditSkills from "../../containers/Modals/EditSkills/EditSkills";
import CashAccountInformation from "../../containers/Modals/CashAccountsInformation/CashAccountsInformation";
import EditCashAccount from "../../containers/Modals/EditCashAccounts/EditCashAccounts";
import ExpensesInformation from "../../containers/Modals/ExpensesInformation/ExpensesInformation";
import EditExpenses from "../../containers/Modals/EditExpenses/EditExpenses";
import IncomesInformation from "../../containers/Modals/IncomesInformation/IncomesInformation";
import EditIncomes from "../../containers/Modals/EditIncomes/EditIncomes";
import MarginAccountsInformation from "../../containers/Modals/MarginAccountsInformation/MarginAccountsInformation";
import EditMarginAccounts from "../../containers/Modals/EditMarginAccounts/EditMarginAccounts";
import MarketableSecuritiesInformation from "../../containers/Modals/MarketableSecuritiesInformation/MarketableSecuritiesInformation";
import EditMarketableSecurities from "../../containers/Modals/EditMarketableSecurities/EditMarketableSecurities";
import NonmarketableSecuritiesInformation from "../../containers/Modals/NonmarketableSecuritiesInformation/NonmarketableSecuritiesInformation";
import EditNonmarketableSecurities from "../../containers/Modals/EditNonmarketableSecurities/EditNonmarketableSecurities";
import RetirementFundsInformation from "../../containers/Modals/RetirementFundsInformation/RetirementFundsInformation";
import EditRetirementFunds from "../../containers/Modals/EditRetirementFunds/EditRetirementFunds";
import TaxReturnsInformation from "../../containers/Modals/TaxReturnsInformation/TaxReturnsInformation";
import EditTaxReturns from "../../containers/Modals/EditTaxReturns/EditTaxReturns";
import AllergyInformation from "../../containers/Modals/AllergyInformation/AllergyInformation";
import EditAllergy from "../../containers/Modals/EditAllergy/EditAllergy";
import FamilyConditionInformation from "../../containers/Modals/FamilyConditionInformation/FamilyConditionInformation";
import EditFamilyCondition from "../../containers/Modals/EditFamilyCondition/EditFamilyCondition";
import HealthConditionInformation from "../../containers/Modals/HealthConditionInformation/HealthConditionInformation";
import EditHealthCondition from "../../containers/Modals/EditHealthCondition/EditHealthCondition";
import HospitalizationInformation from "../../containers/Modals/HospitalizationInformation/HospitalizationInformation";
import EditHospitalization from "../../containers/Modals/EditHospitalization/EditHospitalization";
import ImmunizationInformation from "../../containers/Modals/ImmunizationInformation/ImmunizationInformation";
import EditImmunization from "../../containers/Modals/EditImmunization/EditImmunization";
import MedicationInformation from "../../containers/Modals/MedicationInformation/MedicationInformation";
import EditMedication from "../../containers/Modals/EditMedication/EditMedication";
import ProcedureInformation from "../../containers/Modals/ProcedureInformation/ProcedureInformation";
import EditProcedure from "../../containers/Modals/EditProcedure/EditProcedure";
import TestResultInformation from "../../containers/Modals/TestResultInformation/TestResultInformation";
import EditTestResult from "../../containers/Modals/EditTestResult/EditTestResult";
import EditWellness from "../../containers/Modals/EditWellness/EditWellness";
import AutoInsuranceInformation from "../../containers/Modals/AutoInsuranceInformation/AutoInsuranceInformation";
import EditAutoInsurance from "../../containers/Modals/EditAutoInsurance/EditAutoInsurance";
import DisabilityInsuranceInformation from "../../containers/Modals/DisabilityInsuranceInformation/DisabilityInsuranceInformation";
import EditDisabilityInsurance from "../../containers/Modals/EditDisabilityInsurance/EditDisabilityInsurance";
import HealthInsuranceInformation from "../../containers/Modals/HealthInsuranceInformation/HealthInsuranceInformation";
import EditHealthInsurance from "../../containers/Modals/EditHealthInsurance/EditHealthInsurance";
import OtherInsuranceInformation from "../../containers/Modals/OtherInsuranceInformation/OtherInsuranceInformation";
import EditOtherInsurance from "../../containers/Modals/EditOtherInsurance copy/EditOtherInsurance";
import PropertyInsuranceInformation from "../../containers/Modals/PropertyInsuranceInformation/PropertyInsuranceInformation";
import EditPropertyInsurance from "../../containers/Modals/EditPropertyInsurance/EditPropertyInsurance";
import AlternateIdInformation from "../../containers/Modals/AlternateIdInformation/AlternateIdInformation";
import EditAlternateId from "../../containers/Modals/EditAlternateId/EditAlternateId";
import DriverLicenses from "../../views/Personal/DriverLicenses/DriverLicenses";
import EditDriverLicense from "../../containers/Modals/EditDriverLicense/EditDriverLicense";
import EmailAccountInformation from "../../containers/Modals/EmailAccountInformation/EmailAccountInformation";
import EditEmailAccount from "../../containers/Modals/EditEmailAccount/EditEmailAccount";
import LoyaltyProgramInformation from "../../containers/Modals/LoyaltyProgramInformation/LoyaltyProgramInformation";
import EditLoyaltyProgram from "../../containers/Modals/EditLoyaltyProgram/EditLoyaltyProgram";
import PassportInformation from "../../containers/Modals/PassportInformation/PassportInformation";
import EditPassport from "../../containers/Modals/EditPassport/EditPassport";
import PersonalInformations from "../../containers/Modals/PersonalInformation/PersonalInformation";
import EditPersonalInformation from "../../containers/Modals/EditPersonalInformation/EditPersonalInformation";
import VisaInformation from "../../containers/Modals/VisaInformation/VisaInformation";
import EditVisa from "../../containers/Modals/EditVisa/EditVisa";
import WebLoginInformation from "../../containers/Modals/WebLoginInformation/WebLoginInformation";
import EditWebLogin from "../../containers/Modals/EditWebLogin/EditWebLogin";
import LifeInsuranceInformation from "../../containers/Modals/LifeInsuranceInformation/LifeInsuranceInformation";
import EditLifeInsurance from "../../containers/Modals/EditLifeInsurance/EditLifeInsurance";
import HouseInformation from "../../containers/Modals/HouseInformation/HouseInformation";
import EditHouse from "../../containers/Modals/EditHouse/EditHouse";
import PersonalItemInformation from "../../containers/Modals/PersonalItemInformation/PersonalItemInformation";
import EditPersonalItem from "../../containers/Modals/EditPersonalItem/EditPersonalItem";
import RentalInformation from "../../containers/Modals/RentalInformation/RentalInformation";
import EditRental from "../../containers/Modals/EditRental/EditRental";
import SerialNumberInformation from "../../containers/Modals/SerialNumberInformation/SerialNumberInformation";
import EditSerialNumber from "../../containers/Modals/EditSerialNumber/EditSerialNumber";
import AutoServiceInformation from "../../containers/Modals/AutoServiceInformation/AutoServiceInformation";
import EditAutoService from "../../containers/Modals/EditAutoService/EditAutoService";
import VehicleInformation from "../../containers/Modals/VehicleInformation/VehicleInformation";
import EditVehicle from "../../containers/Modals/EditVehicle/EditVehicle";
import WellnessInformation from "../../containers/Modals/WellnessInformation/WellnessInformation.js";
import LinkedItems from "../../containers/Modals/LinkedItems/LinkedItems.js";

const SearchResult = ({ results }) => {
  const searchService = new Search();
  const [category, setCategory] = useState(null);
  const [data, setData] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [openLinkedItems, setOpenLinkedItems] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const pullSearchableRecord = async (type, id) => {
    setCategory(type);
    try {
      const response = await searchService.searchIndex(type, id);
      setData(response.data.searchable);
      setOpenInfo(true);
    } catch (e) {
      console.log({ error: e.response || e.message });
    }
  };
  const handleCloseLinkedItems = () => {
    setOpenLinkedItems(false);
  };
  const closeModal = () => {
    setOpenLinkedItems(false);
  };
  const handleOpenLinkedItems = () => {
    setOpenLinkedItems(true);
  };

  return (
    <>
      {openLinkedItems && (
        <LinkedItems
          attachment={[data]}
          open={openLinkedItems}
          handleCloseLinkedItems={handleCloseLinkedItems}
          closeModal={closeModal}
          heading={"Linked Documents"}
          category={category}
        />
      )}
      {openInfo && category === "Certificate" && (
        <CertificateInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          certificate={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}{" "}
      {openEdit && category === "Certificate" && (
        <EditCertificate
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          certificate={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Language" && (
        <LanguageInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          language={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Language" && (
        <EditLanguage
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          language={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Education" && (
        <EducationInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          education={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Education" && (
        <EditEducation
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          education={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Award" && (
        <AwardInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          award={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Award" && (
        <EditAward
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          award={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Employer" && (
        <EmployerInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          info={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Employer" && (
        <EditEmployer
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          employer={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Skill" && (
        <SkillsInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          skills={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Skill" && (
        <EditSkills
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          skill={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Cash Account" && (
        <CashAccountInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          cashAccount={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Cash Account" && (
        <EditCashAccount
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          cashAccount={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Expense" && (
        <ExpensesInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          expenses={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Expense" && (
        <EditExpenses
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          expenses={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Income" && (
        <IncomesInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          income={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Income" && (
        <EditIncomes
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          income={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Margin Account" && (
        <MarginAccountsInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          marginAccount={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Margin Account" && (
        <EditMarginAccounts
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          marginAccount={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Marketable Security" && (
        <MarketableSecuritiesInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          marketableSecuritie={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Marketable Security" && (
        <EditMarketableSecurities
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          marketableSecuritie={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Nonmarketable Security" && (
        <NonmarketableSecuritiesInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          nonmarketableSecuritie={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Nonmarketable Security" && (
        <EditNonmarketableSecurities
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          nonmarketableSecuritie={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Retirement Fund" && (
        <RetirementFundsInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          retirementFund={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Retirement Fund" && (
        <EditRetirementFunds
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          retirementFund={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Tax Return" && (
        <TaxReturnsInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          taxReturn={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Tax Return" && (
        <EditTaxReturns
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          taxReturn={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Allergy" && (
        <AllergyInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          allergy={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Allergy" && (
        <EditAllergy
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          allergy={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Family Condition" && (
        <FamilyConditionInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          familyCondition={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Family Condition" && (
        <EditFamilyCondition
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          familyCondition={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Health Condition" && (
        <HealthConditionInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          healthCondition={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Health Condition" && (
        <EditHealthCondition
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          healthCondition={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Hospitalization" && (
        <HospitalizationInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          hospitalization={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Hospitalization" && (
        <EditHospitalization
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          hospitalization={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Immunization" && (
        <ImmunizationInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          immunization={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Immunization" && (
        <EditImmunization
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          immunization={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Medication" && (
        <MedicationInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          medication={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Medication" && (
        <EditMedication
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          medication={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Procedure" && (
        <ProcedureInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          procedure={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Procedure" && (
        <EditProcedure
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          procedure={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Test Result" && (
        <TestResultInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          testResult={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Test Result" && (
        <EditTestResult
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          testResult={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Wellness" && (
        <WellnessInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          wellness={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Wellness" && (
        <EditWellness
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          wellness={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Auto Insurance" && (
        <AutoInsuranceInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          autoInsurance={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Auto Insurance" && (
        <EditAutoInsurance
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          autoInsurance={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Disability Insurance" && (
        <DisabilityInsuranceInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          disabilityInsurance={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Disability Insurance" && (
        <EditDisabilityInsurance
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          disabilityInsurance={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Health Insurance" && (
        <HealthInsuranceInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          healthInsurance={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Health Insurance" && (
        <EditHealthInsurance
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          healthInsurance={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Other Insurance" && (
        <OtherInsuranceInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          otherInsurance={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Other Insurance" && (
        <EditOtherInsurance
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          otherInsurance={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Property Insurance" && (
        <PropertyInsuranceInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          propertyInsurance={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Property Insurance" && (
        <EditPropertyInsurance
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          propertyInsurance={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Alternate Identification" && (
        <AlternateIdInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          alternateIdentification={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Alternate Identification" && (
        <EditAlternateId
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          alternateIdentification={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Driver License" && (
        <DriverLicenses
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          driverLicense={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Driver License" && (
        <EditDriverLicense
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          driverLicense={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Email Account" && (
        <EmailAccountInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          emailAccountInformation={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Email Account" && (
        <EditEmailAccount
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          emailAccountInformation={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Loyalty Program" && (
        <LoyaltyProgramInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          loyaltyProgram={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Loyalty Program" && (
        <EditLoyaltyProgram
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          loyaltyProgram={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Passport" && (
        <PassportInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          passport={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Passport" && (
        <EditPassport
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          passport={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Personal Information" && (
        <PersonalInformations
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          personalInformation={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Personal Information" && (
        <EditPersonalInformation
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          personalInformation={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Visa" && (
        <VisaInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          visa={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Visa" && (
        <EditVisa
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          visa={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Web Login" && (
        <WebLoginInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          webLogin={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Web Login" && (
        <EditWebLogin
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          webLogin={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Life Insurance" && (
        <LifeInsuranceInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          lifeInsurance={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Life Insurance" && (
        <EditLifeInsurance
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          lifeInsurance={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "House" && (
        <HouseInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          house={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "House" && (
        <EditHouse
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          house={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Personal Item" && (
        <PersonalItemInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          personalItem={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Personal Item" && (
        <EditPersonalItem
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          personalItem={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Rental" && (
        <RentalInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          rental={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Rental" && (
        <EditRental
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          rental={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Serial Number" && (
        <SerialNumberInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          serialNumber={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Serial Number" && (
        <EditSerialNumber
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          serialNumber={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Auto Service" && (
        <AutoServiceInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          autoService={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Auto Service" && (
        <EditAutoService
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          autoService={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {openInfo && category === "Vehicle" && (
        <VehicleInformation
          handleOpenLinkedItems={handleOpenLinkedItems}
          open={openInfo}
          handleClose={() => {
            setOpenInfo(false);
            setOpenEdit(false);
            setCategory(null);
          }}
          handleOpenEdit={() => {
            setOpenInfo(false);
            setOpenEdit(true);
          }}
          vehicle={[data]}
          closeModal={() => setOpenInfo(false)}
        />
      )}
      {openEdit && category === "Vehicle" && (
        <EditVehicle
          open={openEdit}
          handleClose={() => setOpenEdit(false)}
          vehicle={data}
          closeModal={() => setOpenEdit(false)}
        />
      )}
      {results &&
        results?.map((r) => {
          return (
            <Card
              style={{ marginBottom: "0", cursor: "pointer" }}
              onClick={() =>
                pullSearchableRecord(r?.searchable_type, r?.searchable_id)
              }
            >
              <CardHeader color="warning" stats icon>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={1}
                    md={1}
                    align="left"
                    container
                    alignItems="center"
                  ></GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={7}
                    container
                    alignItems="center"
                  >
                    <Typography
                      variant="h6"
                      component="h3"
                      align="left"
                      style={{ color: "#060d5c" }}
                    >
                      {r?.searchable_type}
                    </Typography>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    container
                    alignItems="center"
                  >
                    <Box flexGrow={2}>
                      <Typography
                        variant="h6"
                        component="h3"
                        align="left"
                        style={{ color: "#060d5c", fontWeight: "normal" }}
                      >
                        {r?.content}
                      </Typography>
                    </Box>
                  </GridItem>
                </GridContainer>
              </CardHeader>
            </Card>
          );
        })}
    </>
  );
};

export default SearchResult;
