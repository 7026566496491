import "date-fns";
import React, {useState, useRef} from "react";
import {makeStyles} from '@material-ui/core/styles';
// import { makeStyles } from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import {FormInput} from "../../../components/FormInput/FormInput.js";
import {CustomButton} from "../../../components/CustomButton/Button.js";
import {Close} from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Auth} from "../../../services/auth.service";


const useStyles = makeStyles(styles);

function EditPassword(props) {
    const authService = new Auth();
    const classes = useStyles();
    const errRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const [err, setErr] = useState(null)
    const [data, setData] = useState({
        current_password: null,
        password: null,
        password_confirmation: null
    })
    const handleSubmit = () => {
        let regex = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/;
        if (!regex.test(data.password)) {
            setErr("Must contain 1 uppercase, 1 lowercase and 1 special character and should atleast be 8 characters long")
        } else {
            if (data.password !== data.password_confirmation) {
                setErr("Passwords do not match")
            } else {
                setErr(null)
                setLoading(true)
                authService.updatePassword(data).then(() => {
                    setLoading(false)
                    props.handleClose()
                }).catch((e) => {
                    errRef.current.scrollIntoView()
                    setLoading(false)
                    if (e.response) {
                        setErr(e?.response?.data?.errors)
                    } else {
                        setErr("Internal server error, try again later")
                    }
                })
            }
        }
    };


    const handleChange = (event) => {
        setData({...data, [event.target.name]: event.target.value})
    };

    return (
        <>
            <CustomModal
                visible={props.open}
                onClose={props.handleClose}
                width="55%"
                margin="120px 0 40px"
                inset="15px!important"
            >
                <ModalHeader
                    closeModal={props.handleClose}
                    icon={
                        <Close
                            style={{
                                color: color.white,
                                marginRight: "20px",
                                fontSize: "30px",
                                cursor: "pointer",
                            }}
                        />
                    }
                    heading={"Change Password"}
                    color={color.white}
                />
                <div className={classes.modalBody}>
                    <Grid
                        container
                        wrap="wrap"
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        {err && (
                            <div className="w-100 d-flex flex-row mb-5">
                                <Typography
                                    variant="p"
                                    component="p"
                                    align="left"
                                    style={{color: "red", fontWeight: "normal"}}
                                >
                                    {err}
                                </Typography>
                            </div>
                        )}
                        <Grid item xs={12} sm={3} md={3}>
                            <Typography
                                variant="h6"
                                component="h3"
                                style={{color: color.themeColorNew}}
                                ref={errRef}
                            >
                                {"Current Password*"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} md={9}>
                            <FormInput
                                name="current_password"
                                onChange={handleChange}
                                label=""
                                variant="outlined"
                                type="text"
id="txtPassword"
                                fullWidth={true}
                                margin="dense"
                                required={true}
                                placeholder={"Current Password"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <Typography
                                variant="h6"
                                component="h3"
                                style={{color: color.themeColorNew}}
                            >
                                {"New Password *"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} md={9}>
                            <FormInput
                                name="password"
                                onChange={handleChange}
                                label=""
                                variant="outlined"
                                fullWidth={true}
                                margin="dense"
                                type="text"
id="txtPassword"
                                required={true}
                                placeholder={"New Password"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <Typography
                                variant="h6"
                                component="h3"
                                style={{color: color.themeColorNew}}
                            >
                                {"Confirm New Password *"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} md={9}>
                            <FormInput
                                name="password_confirmation"
                                onChange={handleChange}
                                label=""
                                variant="outlined"
                                fullWidth={true}
                                type="text"
id="txtPassword"
                                margin="dense"
                                required={true}
                                placeholder={"Confirm New Password"}
                            />
                        </Grid>
                        <a ref={errRef} href={"#err"} style={{
                                    display: "none"
                                }}>click</a>
                        <Grid item xs={12} sm={12} md={12} className="text-sm-center" style={{textAlign: "right"}}>
                            {!loading ? <CustomButton
                                type="submit"
                                btnText="Save"
                                textAlign="center"
                                display={"block"}
                                padding="12px 25px"
                                minWidth="113px"
                                background="#0042a8"
                                hoverBg="#0042a8"
                                border="0"
                                marginRight="10px"
                                disabled={data.current_password === null || data.password_confirmation === null || data.password === null}
                                borderRadius="8px"
                                color="#fff"
                                onClick={handleSubmit}
                            /> : <CustomButton
                                type="submit"
                                btnText={<CircularProgress size={20} color="#DDD"/>}
                                textAlign="center"
                                display={"block"}
                                padding="12px 25px"
                                minWidth="113px"
                                background="#0042a8"
                                hoverBg="#0042a8"
                                border="0"
                                marginRight="10px"
                                borderRadius="8px"
                                color="#fff"
                            />}

                           
                        </Grid>
                    </Grid>
                </div>
            </CustomModal>
        </>
    );
}

export default EditPassword;
