import "date-fns";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { connect } from "react-redux";
import routes from "../../../routes";
import AddAlternateId from "../AddAlternateId/AddAlternateId.js";
import AddAllergy from "../AddAllergy/AddAllergy.js";
import AddAutoInsurance from "../AddAutoInsurance/AddAutoInsurance.js";
import AddAward from "../AddAward/AddAward.js";
import AddCashAccounts from "../AddCashAccounts/AddCashAccounts.js";
import AddCertificate from "../AddCertificate/AddCertificate.js";
import AddDriverLicense from "../AddDriverLicense/AddDriverLicense.js";
import AddEmailAccount from "../AddEmailAccount/AddEmailAccount.js";
import AddLoyaltyProgram from "../AddLoyaltyProgram/AddLoyaltyProgram.js";
import AddPassport from "../AddPassport/AddPassport.js";
import AddPersonalInformation from "../AddPersonalInformation/AddPersonalInformation.js";
import AddVisa from "../AddVisa/AddVisa.js";
import AddWebLogin from "../AddWebLogin/AddWebLogin.js";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddFamilyCondition from "../AddFamilyCondition/AddFamilyCondition.js";
import AddHealthCondition from "../AddHealthCondition/AddHealthCondition.js";
import AddHospitalization from "../AddHospitalization/AddHospitalization.js";
import AddImmunization from "../AddImmunization/AddImmunization.js";
import AddMedication from "../AddMedication/AddMedication.js";
import AddProcedure from "../AddProcedure/AddProcedure.js";
import AddTestResult from "../AddTestResult/AddTestResult.js";
import AddSkills from "../AddSkills/AddSkills.js";
import AddEmployer from "../AddEmployer/AddEmployer.js";
import AddExpenses from "../AddExpenses/AddExpenses.js";
import AddIncomes from "../AddIncomes/AddIncomes.js";
import AddMarginAccounts from "../AddMarginAccounts/AddMarginAccounts.js";
import AddMarketableSecurities from "../AddMarketableSecurities/AddMarketableSecurities.js";
import AddNonmarketableSecurities from "../AddNonmarketableSecurities/AddNonmarketableSecurities.js";
import AddRetirementFunds from "../AddRetirementFunds/AddRetirementFunds.js";
import AddTaxReturns from "../AddTaxReturns/AddTaxReturns.js";
import AddDisabilityInsurance from "../AddDisabilityInsurance/AddDisabilityInsurance.js";
import AddHealthInsurance from "../AddHealthInsurance/AddHealthInsurance.js";
import AddLifeInsurance from "../AddLifeInsurance/AddLifeInsurance.js";
import AddOtherInsurance from "../AddOtherInsurance/AddOtherInsurance.js";
import AddPropertyInsurance from "../AddPropertyInsurance/AddPropertyInsurance.js";
import AddVehicle from "../AddVehicle/AddVehicle.js";
import AddHouse from "../AddHouse/AddHouse.js";
import AddPersonalItem from "../AddPersonalItem/AddPersonalItem.js";
import AddRental from "../AddRental/AddRental.js";
import AddSerialNumber from "../AddSerialNumber/AddSerialNumber.js";
import AddLanguage from "../AddLanguage/AddLanguage.js";
import AddEducation from "../AddEducation/AddEducation.js";
import AddAutoService from "../AddAutoService/AddAutoService";
import AddMortgage from "../AddMortgage/AddMortgage.js";
import AddEmergencyContact from "../AddEmergencyContact/AddEmergencyContact.js";
import AddWellness from "../AddWellness/AddWellness.js";

const array = routes.map((cat) => {
  return {
    ...cat,
  };
});
const useStyles = makeStyles(styles);

function AddInformation(props) {
  const classes = useStyles();
  const [category, setCategory] = React.useState(null);
  const [subArray, setSubArray] = React.useState([]);
  const [openAddAlternateId, setopenAddAlternateId] = React.useState(false);
  const [openAddDriverLicense, setopenAddDriverLicense] = React.useState(false);
  const [openAddEmailAccount, setopenAddEmailAccount] = React.useState(false);
  const [openAddLoyaltyProgram, setopenAddLoyaltyProgram] =
    React.useState(false);
  const [openAddPassport, setopenAddPassport] = React.useState(false);
  const [openAddPersonalInformation, setopenAddPersonalInformation] =
    React.useState(false);
  const [openAddVisas, setopenAddVisas] = React.useState(false);
  const [openAddWebLogin, setopenAddWebLogin] = React.useState(false);
  const [openAddFamilyCondition, setopenAddFamilyCondition] =
    React.useState(false);
  const [openAddHealthCondition, setopenAddHealthCondition] =
    React.useState(false);
  const [openAddHospitalization, setopenAddHospitalization] =
    React.useState(false);
  const [openAddImmunization, setopenAddImmunization] = React.useState(false);
  const [openAddMedication, setopenAddMedication] = React.useState(false);
  const [openAddMortgage, setopenAddMortgage] = React.useState(false);
  const [openAddProcedure, setopenAddProcedure] = React.useState(false);
  const [openAddTestResult, setopenAddTestResult] = React.useState(false);
  const [openAddWellness, setopenAddWellness] = React.useState(false);
  const [openAddAllergy, setopenAddAllergy] = React.useState(false);
  const [openAddSkill, setopenAddSkill] = React.useState(false);
  const [openAddEmployer, setopenAddEmployer] = React.useState(false);
  const [openAddAward, setopenAddAward] = React.useState(false);
  const [openAddEmergencyContacts, setOpenAddEmergencyContacts] =
    React.useState(false);
  const [openAddCashAccounts, setopenAddCashAccounts] = React.useState(false);
  const [openAddExpense, setopenAddExpense] = React.useState(false);
  const [openAddIncome, setopenAddIncome] = React.useState(false);
  const [openAddMarginAccount, setopenAddMarginAccount] = React.useState(false);
  const [subCategories, setSubCategories] = React.useState(null);
  const [openAddMarketableSecurity, setopenAddMarketableSecurity] =
    React.useState(false);
  const [openAddNonMarketableSecurity, setopenAddNonMarketableSecurity] =
    React.useState(false);
  const [openAddRetirementFund, setopenAddRetirementFund] =
    React.useState(false);
  const [openAddTaxReturn, setopenAddTaxReturn] = React.useState(false);
  const [openAddAutoInsurance, setopenAddAutoInsurance] = React.useState(false);
  const [openAddDisabilityInsurance, setopenAddDisabilityInsurance] =
    React.useState(false);
  const [openAddHealthInsurance, setopenAddHealthInsurance] =
    React.useState(false);
  const [openAddLifeInsurance, setopenAddLifeInsurance] = React.useState(false);
  const [openAddOtherInsurance, setopenAddOtherInsurance] =
    React.useState(false);
  const [openAddPropertyInsurance, setopenAddPropertyInsurance] =
    React.useState(false);
  const [openAddAutoService, setopenAddAutoService] = React.useState(false);
  const [openAddVehicle, setopenAddVehicle] = React.useState(false);
  const [openAddHouse, setopenAddHouse] = React.useState(false);
  const [openAddPersonalItem, setopenAddPersonalItem] = React.useState(false);
  const [openAddRental, setopenAddRental] = React.useState(false);
  const [openAddSerialNumber, setopenAddSerialNumber] = React.useState(false);
  const [openAddCertificate, setopenAddCertificate] = React.useState(false);
  const [openAddLanguage, setopenAddLanguage] = React.useState(false);
  const [openAddEducation, setopenAddEducation] = React.useState(false);
  const [subCategory, setSubCategory] = React.useState(null);

  const handleChange = (event) => {
    setCategory(event.target.value);
    const temp = routes.filter((name) => name.name === event.target.value);
    setSubArray(temp[0].submenu);
  };

  const handleChangeSub = (event) => {
    setSubCategory(event.target.value);
  };

  const handleopenAddAlternateId = () => {
    setopenAddAlternateId(true);
    // props.handleClose();
  };
  const handleopenAddDriverLicense = () => {
    setopenAddDriverLicense(true);
    // props.handleClose();
  };
  const handleopenAddEmailAccount = () => {
    setopenAddEmailAccount(true);
    // props.handleClose();
  };
  const handleopenAddLoyaltyProgram = () => {
    setopenAddLoyaltyProgram(true);
    // props.handleClose();
  };
  const handleopenAddPassport = () => {
    setopenAddPassport(true);
    // props.handleClose();
  };
  const handleopenAddPersonalInformation = () => {
    setopenAddPersonalInformation(true);
    // props.handleClose();
  };
  const handleopenAddVisas = () => {
    setopenAddVisas(true);
    // props.handleClose();
  };
  const handleopenAddWebLogin = () => {
    setopenAddWebLogin(true);
    // props.handleClose();
  };
  const handleopenAddFamilyCondition = () => {
    setopenAddFamilyCondition(true);
    // props.handleClose();
  };
  const handleopenAddHealthCondition = () => {
    setopenAddHealthCondition(true);
    // props.handleClose();
  };
  const handleopenAddHospitalization = () => {
    setopenAddHospitalization(true);
    // props.handleClose();
  };
  const handleopenAddImmunization = () => {
    setopenAddImmunization(true);
    // props.handleClose();
  };
  const handleopenAddMedication = () => {
    setopenAddMedication(true);
    // props.handleClose();
  };
  const handleopenAddMortgage = () => {
    setopenAddMortgage(true);
    // props.handleClose();
  };
  const handleopenAddProcedure = () => {
    setopenAddProcedure(true);
    // props.handleClose();
  };
  const handleopenAddTestResult = () => {
    setopenAddTestResult(true);
    // props.handleClose();
  };
  const handleopenAddWellness = () => {
    setopenAddWellness(true);
    // props.handleClose();
  };
  const handleopenAddAllergy = () => {
    setopenAddAllergy(true);
    // props.handleClose();
  };
  const handleopenAddSkill = () => {
    setopenAddSkill(true);
    // props.handleClose();
  };
  const handleopenAddEmployer = () => {
    setopenAddEmployer(true);
    // props.handleClose();
  };
  const handleopenAddAward = () => {
    setopenAddAward(true);
    // props.handleClose();
  };
  const handleopenAddCashAccounts = () => {
    setopenAddCashAccounts(true);
    // props.handleClose();
  };
  const handleopenAddExpense = () => {
    setopenAddExpense(true);
    // props.handleClose();
  };
  const handleopenAddIncome = () => {
    setopenAddIncome(true);
    // props.handleClose();
  };
  const handleopenAddMarginAccount = () => {
    setopenAddMarginAccount(true);
    // props.handleClose();
  };
  const handleopenAddMarketableSecurity = () => {
    setopenAddMarketableSecurity(true);
    // props.handleClose();
  };
  const handleopenAddNonMarketableSecurity = () => {
    setopenAddNonMarketableSecurity(true);
    // props.handleClose();
  };
  const handleopenAddRetirementFund = () => {
    setopenAddRetirementFund(true);
    // props.handleClose();
  };
  const handleopenAddTaxReturn = () => {
    setopenAddTaxReturn(true);
    // props.handleClose();
  };
  const handleopenAddAutoInsurance = () => {
    setopenAddAutoInsurance(true);
    // props.handleClose();
  };
  const handleopenAddDisabilityInsurance = () => {
    setopenAddDisabilityInsurance(true);
    // props.handleClose();
  };
  const handleopenAddHealthInsurance = () => {
    setopenAddHealthInsurance(true);
    // props.handleClose();
  };
  const handleopenAddLifeInsurance = () => {
    setopenAddLifeInsurance(true);
    // props.handleClose();
  };
  const handleopenAddOtherInsurance = () => {
    setopenAddOtherInsurance(true);
    // props.handleClose();
  };
  const handleopenAddPropertyInsurance = () => {
    setopenAddPropertyInsurance(true);
    // props.handleClose();
  };
  const handleopenAddAutoService = () => {
    setopenAddAutoService(true);
    // props.handleClose();
  };
  const handleopenAddVehicle = () => {
    setopenAddVehicle(true);
    // props.handleClose();
  };
  const handleopenAddHouse = () => {
    setopenAddHouse(true);
    // props.handleClose();
  };
  const handleopenAddPersonalItem = () => {
    setopenAddPersonalItem(true);
    // props.handleClose();
  };
  const handleopenAddRental = () => {
    setopenAddRental(true);
    // props.handleClose();
  };
  const handleopenAddSerialNumber = () => {
    setopenAddSerialNumber(true);
    // props.handleClose();
  };
  const handleopenAddCertificate = () => {
    setopenAddCertificate(true);
    // props.handleClose();
  };
  const handleopenAddLanguage = () => {
    setopenAddLanguage(true);
    // props.handleClose();
  };
  const handleopenAddEducation = () => {
    setopenAddEducation(true);
    // props.handleClose();
  };

  const handleCloseAddAlternateId = () => {
    setopenAddAlternateId(false);
  };

  const handleAddEmergencyContacts = () => {
    setOpenAddEmergencyContacts(true);
  };
  const closeModal = () => {
    setOpenAddEmergencyContacts(false);
    setopenAddAlternateId(false);
    setopenAddDriverLicense(false);
    setopenAddEmailAccount(false);
    setopenAddLoyaltyProgram(false);
    setopenAddPassport(false);
    setopenAddPersonalInformation(false);
    setopenAddVisas(false);
    setopenAddWebLogin(false);
    setopenAddFamilyCondition(false);
    setopenAddHealthCondition(false);
    setopenAddHospitalization(false);
    setopenAddImmunization(false);
    setopenAddMedication(false);
    setopenAddProcedure(false);
    setopenAddTestResult(false);
    setopenAddWellness(false);
    setopenAddAllergy(false);
    setopenAddSkill(false);
    setopenAddEmployer(false);
    setopenAddAward(false);
    setopenAddCashAccounts(false);
    setopenAddExpense(false);
    setopenAddIncome(false);
    setopenAddMarginAccount(false);
    setopenAddMarketableSecurity(false);
    setopenAddNonMarketableSecurity(false);
    setopenAddRetirementFund(false);
    setopenAddTaxReturn(false);
    setopenAddAutoInsurance(false);
    setopenAddDisabilityInsurance(false);
    setopenAddHealthInsurance(false);
    setopenAddLifeInsurance(false);
    setopenAddOtherInsurance(false);
    setopenAddPropertyInsurance(false);
    setopenAddAutoService(false);
    setopenAddVehicle(false);
    setopenAddHouse(false);
    setopenAddPersonalItem(false);
    setopenAddRental(false);
    setopenAddSerialNumber(false);
    setopenAddCertificate(false);
    setopenAddLanguage(false);
    setopenAddAutoService(false);
    setopenAddEducation(false);
    setopenAddCertificate(false);
    props.handleClose();
  };

  const handleClick = (e) => {
    e === "Alternate Identifications"
      ? handleopenAddAlternateId()
      : e === "Driver Licenses"
      ? handleopenAddDriverLicense()
      : e === "Auto Services"
      ? handleopenAddAutoService()
      : e === "Email Accounts"
      ? handleopenAddEmailAccount()
      : e === "Loyalty Programs"
      ? handleopenAddLoyaltyProgram()
      : e === "Passports"
      ? handleopenAddPassport()
      : e === "Personal Information"
      ? handleopenAddPersonalInformation()
      : e === "Visas"
      ? handleopenAddVisas()
      : e === "Web Logins"
      ? handleopenAddWebLogin()
      : e === "Family Conditions"
      ? handleopenAddFamilyCondition()
      : e === "Health Conditions"
      ? handleopenAddHealthCondition()
      : e === "Hospitalizations"
      ? handleopenAddHospitalization()
      : e === "Immunizations"
      ? handleopenAddImmunization()
      : e === "Medications"
      ? handleopenAddMedication()
      : e === "Mortgages"
      ? handleopenAddMortgage()
      : e === "Procedures"
      ? handleopenAddProcedure()
      : e === "Test Results"
      ? handleopenAddTestResult()
      : e === "Wellness"
      ? handleopenAddWellness()
      : e === "Allergies"
      ? handleopenAddAllergy()
      : e === "Skills"
      ? handleopenAddSkill()
      : e === "Employers"
      ? handleopenAddEmployer()
      : e === "Awards"
      ? handleopenAddAward()
      : e === "Cash Accounts"
      ? handleopenAddCashAccounts()
      : e === "Expenses"
      ? handleopenAddExpense()
      : e === "Incomes"
      ? handleopenAddIncome()
      : e === "Margin Accounts"
      ? handleopenAddMarginAccount()
      : e === "Marketable Securities"
      ? handleopenAddMarketableSecurity()
      : e === "Nonmarketable Securities"
      ? handleopenAddNonMarketableSecurity()
      : e === "Retirement Funds"
      ? handleopenAddRetirementFund()
      : e === "Tax Returns"
      ? handleopenAddTaxReturn()
      : e === "Auto Insurances"
      ? handleopenAddAutoInsurance()
      : e === "Disability Insurances"
      ? handleopenAddDisabilityInsurance()
      : e === "Health Insurances"
      ? handleopenAddHealthInsurance()
      : e === "Life Insurances"
      ? handleopenAddLifeInsurance()
      : e === "Other Insurances"
      ? handleopenAddOtherInsurance()
      : e === "Property Insurances"
      ? handleopenAddPropertyInsurance()
      : e === "Auto Services"
      ? handleopenAddAutoService()
      : e === "Vehicles"
      ? handleopenAddVehicle()
      : e === "Houses"
      ? handleopenAddHouse()
      : e === "Personal Items"
      ? handleopenAddPersonalItem()
      : e === "Rentals"
      ? handleopenAddRental()
      : e === "Serial Numbers"
      ? handleopenAddSerialNumber()
      : e === "Certificates"
      ? handleopenAddCertificate()
      : e === "Languages"
      ? handleopenAddLanguage()
      : e === "Educations"
      ? handleopenAddEducation()
      : e === "Emergency Contacts"
      ? handleAddEmergencyContacts()
      : handleopenAddCashAccounts();
  };
  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%"
        // margin="250px 0 40px"
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Add Information"}
          color={color.white}
        />
        <div className={classes.modalBody}>
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="start"
            alignItems="center"
            spacing={3}
          >
            {subCategories && (
              <ArrowBackIcon
                color="primary"
                fontSize="medium"
                style={{
                  cursor: "pointer",
                  zIndex: "10000",
                }}
                onClick={() => setSubCategories(null)}
              />
            )}
            <Typography
              variant="h6"
              component="h3"
              style={{ color: color.themeColorNew, marginLeft: "2rem" }}
            >
              {subCategories
                ? "Categories"
                : "Select Dimension to choose category from"}
            </Typography>
            <Grid item xs={12} sm={12} md={12} className="pb-0 m-4"></Grid>
            {!subCategories && (
              <Grid container spacing={3}>
                {routes.map((category, index) => {
                  return (
                    <Grid
                      item
                      xs={6}
                      sm={3}
                      style={{ cursor: "pointer" }}
                      key={index}
                      onClick={() => {
                        setSubCategories(category.submenu);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={category.iconImage}
                          height="25px"
                          width={"25px"}
                          style={{ margin: "2px" }}
                          alt=""
                        />
                        <p style={{ textAlign: "center" }}>{category.name}</p>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            )}
            {subCategories && (
              <Grid container spacing={3}>
                {subCategories.map((cat, index) => {
                  return (
                    <Grid
                      item
                      xs={6}
                      sm={3}
                      style={{ cursor: "pointer" }}
                      key={cat.path + index}
                      onClick={() => handleClick(cat.name)}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={cat.subIconImage}
                          height="25px"
                          width={"25px"}
                          style={{ margin: "2px" }}
                          alt=""
                        />
                        <p style={{ textAlign: "center" }}>{cat.name}</p>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            )}
            {openAddAlternateId && (
              <AddAlternateId
                open={openAddAlternateId}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddMortgage && (
              <AddMortgage
                open={openAddMortgage}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddDriverLicense && (
              <AddDriverLicense
                open={openAddDriverLicense}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddEmailAccount && (
              <AddEmailAccount
                open={openAddEmailAccount}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddLoyaltyProgram && (
              <AddLoyaltyProgram
                open={openAddLoyaltyProgram}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddPassport && (
              <AddPassport
                open={openAddPassport}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddEmergencyContacts && (
              <AddEmergencyContact
                open={openAddEmergencyContacts}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddPersonalInformation && (
              <AddPersonalInformation
                open={openAddPersonalInformation}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddVisas && (
              <AddVisa
                open={openAddVisas}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddWebLogin && (
              <AddWebLogin
                open={openAddWebLogin}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddFamilyCondition && (
              <AddFamilyCondition
                open={openAddFamilyCondition}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddHealthCondition && (
              <AddHealthCondition
                open={openAddHealthCondition}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddHospitalization && (
              <AddHospitalization
                open={openAddHospitalization}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddImmunization && (
              <AddImmunization
                open={openAddImmunization}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddMedication && (
              <AddMedication
                open={openAddMedication}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddProcedure && (
              <AddProcedure
                open={openAddProcedure}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddTestResult && (
              <AddTestResult
                open={openAddTestResult}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddWellness && (
              <AddWellness
                open={openAddWellness}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddAllergy && (
              <AddAllergy
                open={openAddAllergy}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddSkill && (
              <AddSkills
                open={openAddSkill}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddEmployer && (
              <AddEmployer
                open={openAddEmployer}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddAward && (
              <AddAward
                open={openAddAward}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddCashAccounts && (
              <AddCashAccounts
                open={openAddCashAccounts}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddExpense && (
              <AddExpenses
                open={openAddExpense}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddIncome && (
              <AddIncomes
                open={openAddIncome}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddMarginAccount && (
              <AddMarginAccounts
                open={openAddMarginAccount}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddMarketableSecurity && (
              <AddMarketableSecurities
                open={openAddMarketableSecurity}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddNonMarketableSecurity && (
              <AddNonmarketableSecurities
                open={openAddNonMarketableSecurity}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddRetirementFund && (
              <AddRetirementFunds
                open={openAddRetirementFund}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddTaxReturn && (
              <AddTaxReturns
                open={openAddTaxReturn}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddAutoInsurance && (
              <AddAutoInsurance
                open={openAddAutoInsurance}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddDisabilityInsurance && (
              <AddDisabilityInsurance
                open={openAddDisabilityInsurance}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddHealthInsurance && (
              <AddHealthInsurance
                open={openAddHealthInsurance}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddLifeInsurance && (
              <AddLifeInsurance
                open={openAddLifeInsurance}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddOtherInsurance && (
              <AddOtherInsurance
                open={openAddOtherInsurance}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddPropertyInsurance && (
              <AddPropertyInsurance
                open={openAddPropertyInsurance}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddAutoService && (
              <AddAutoService
                open={openAddAutoService}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddVehicle && (
              <AddVehicle
                open={openAddVehicle}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddHouse && (
              <AddHouse
                open={openAddHouse}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddPersonalItem && (
              <AddPersonalItem
                open={openAddPersonalItem}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddRental && (
              <AddRental
                open={openAddRental}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddSerialNumber && (
              <AddSerialNumber
                open={openAddSerialNumber}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddCertificate && (
              <AddCertificate
                open={openAddCertificate}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddLanguage && (
              <AddLanguage
                open={openAddLanguage}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {openAddEducation && (
              <AddEducation
                open={openAddEducation}
                handleClose={closeModal}
                closeModal={closeModal}
              />
            )}
            {/* <Grid item xs={12} sm={12} md={12} className="text-sm-center" style={{ textAlign: "right" }}>
              <CustomButton
                onClick={() => {
                    subCategory === "Alternate Identifications"
                    ? handleopenAddAlternateId()
                    : subCategory === "Driver Licenses"
                    ? handleopenAddDriverLicense(): subCategory === "Auto Services"
                    ? handleopenAddAutoService()
                    : subCategory === "Email Accounts"
                    ? handleopenAddEmailAccount()
                    : subCategory === "Loyalty Programs"
                    ? handleopenAddLoyaltyProgram()
                    : subCategory === "Passports"
                    ? handleopenAddPassport()
                    : subCategory === "Personal Information"
                    ? handleopenAddPersonalInformation()
                    : subCategory === "Visas"
                    ? handleopenAddVisas()
                    : subCategory === "Web Logins"
                    ? handleopenAddWebLogin()
                    : subCategory === "Family Conditions"
                    ? handleopenAddFamilyCondition()
                    : subCategory === "Health Conditions"
                    ? handleopenAddHealthCondition()
                    : subCategory === "Hospitalizations"
                    ? handleopenAddHospitalization()
                    : subCategory === "Immunizations"
                    ? handleopenAddImmunization()
                    : subCategory === "Medications"
                    ? handleopenAddMedication()
                    : subCategory === "Procedures"
                    ? handleopenAddProcedure()
                    : subCategory === "Test Results"
                    ? handleopenAddTestResult()
                    : subCategory === "Wellness"
                    ? handleopenAddWellness()
                    : subCategory === "Allergies"
                    ? handleopenAddAllergy()
                    : subCategory === "Skills"
                    ? handleopenAddSkill()
                    : subCategory === "Employers"
                    ? handleopenAddEmployer()
                    : subCategory === "Awards"
                    ? handleopenAddAward()
                    : subCategory === "Cash Accounts"
                    ? handleopenAddCashAccounts()
                    : subCategory === "Expenses"
                    ? handleopenAddExpense()
                    : subCategory === "Incomes"
                    ? handleopenAddIncome()
                    : subCategory === "Margin Accounts"
                    ? handleopenAddMarginAccount()
                    : subCategory === "Marketable Securities"
                    ? handleopenAddMarketableSecurity()
                    : subCategory === "NonMarketable Securities"
                    ? handleopenAddNonMarketableSecurity()
                    : subCategory === "Retirement Funds"
                    ? handleopenAddRetirementFund()
                    : subCategory === "Tax Returns"
                    ? handleopenAddTaxReturn()
                    : subCategory === "Auto Insurances"
                    ? handleopenAddAutoInsurance()
                    : subCategory === "Disability Insurances"
                    ? handleopenAddDisabilityInsurance()
                    : subCategory === "Health Insurances"
                    ? handleopenAddHealthInsurance()
                    : subCategory === "Life Insurances"
                    ? handleopenAddLifeInsurance()
                    : subCategory === "Other Insurances"
                    ? handleopenAddOtherInsurance()
                    : subCategory === "Property Insurances"
                    ? handleopenAddPropertyInsurance()
                    : subCategory === "Auto Services"
                    ? handleopenAddAutoService()
                    : subCategory === "Vehicles"
                    ? handleopenAddVehicle()
                    : subCategory === "Houses"
                    ? handleopenAddHouse()
                    : subCategory === "Personal Items"
                    ? handleopenAddPersonalItem()
                    : subCategory === "Rentals"
                    ? handleopenAddRental()
                    : subCategory === "Serial Numbers"
                    ? handleopenAddSerialNumber()
                    : subCategory === "Certificates"
                    ? handleopenAddCertificate()
                    : subCategory === "Languages"
                    ? handleopenAddLanguage()
                    : subCategory === "Education"
                    ? handleopenAddEducation()
                    : handleopenAddCashAccounts();
                }}
                btnText="Next"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="transparent"
                hoverBg="#0042a8"
                border="1px solid #0042a8"
                disabled={subCategory ? false : true}
                borderRadius="8px"
                color="#0042a8"
              />
              <CustomButton
                onClick={() => {
                  props.handleClose();
                }}
                btnText="Cancel"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="transparent"
                hoverBg="#0042a8"
                border="1px solid #0042a8"
                borderRadius="8px"
                color="#0042a8"
              />
            </Grid> */}
          </Grid>
        </div>
      </CustomModal>
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddInformation);
