import "date-fns";
import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close } from "@material-ui/icons";
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import { FormInput } from "../../../components/FormInput/FormInput.js";
import {
  rerenderEmployer,
  setLoading,
} from "../../../store/actions/loadingAction";
import { CustomButton } from "../../../components/CustomButton/Button.js";
import { PersonalService } from "../../../services/personal.service.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import SureToAdd from "../Alerts/SureToAdd/SureToAdd.js";
import AddDocument from "../AddDocument/AddDocument.js";
import EditDocument from "../EditDocument/EditDocument.js";
import disableCheck from "../../../Utils/disableCheck.js";
import { DocumentService } from "../../../services/document.service.js";
import AlertPopup from "../Alerts/Generic/GenericAlert.js";
import SpinnerModal from "../Alerts/SpinnerModal/SpinnerModal.js";
import FileViewer from "../../../components/FileViewer/FileViewer.js";
import PasswordVerification from "../PasswordVerification/PasswordVerification.js";
import _default from "../../../config/default.js";

const relationShip = [
  "Aunt (maternal)",
  "Aunt (paternal)",
  "Brother",
  "Cousin (maternal)",
  "Cousin (paternal)",
  "Daughter",
  "Father",
  "Grandfather (maternal)",
  "Grandfather (paternal)",
  "Grandmother (maternal)",
  "Grandmother (paternal)",
  "Mother",
  "Nephew (maternal)",
  "Nephew (paternal)",
  "Niece (maternal)",
  "Niece (paternal)",
  "Partner",
  "Sister",
  "Son",
  "Spouse",
  "Uncle (maternal)",
  "Uncle (paternal)",
  "Other",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const useStyles = makeStyles(styles);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function EditEmergencyContact(props) {
  const classes = useStyles();
  const errRef = useRef(null);
  const personalService = new PersonalService();
  const [personName, setPersonName] = React.useState([]);
  const [link, setLink] = React.useState(null);
  const [showDoc, setShowDoc] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [spinner, setSpinner] = React.useState(false);
  const [alert, setAlert] = React.useState({
    open: false,
    type: null,
    btnText: null,
    cancelText: null,
    heading: null,
    message: null,
  });
  const [password, setPassword] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showPasswordVerification, setShowPasswordVerification] =
    React.useState(false);
  const [other, setOther] = React.useState(
    !relationShip.includes(props?.driverLicense?.relation)
  );
  const [err, setErr] = React.useState([]);
  const [documentModal, setDocumentModal] = React.useState(false);
  const [editDoc, setEditDoc] = React.useState(false);
  const [currentDocument, setCurrentDocument] = React.useState(null);
  const obj = props?.driverLicense?.associated_documents_attributes
    ? props?.driverLicense?.associated_documents_attributes
    : [];
  const [doc, setDoc] = React.useState(
    props?.driverLicense?.documents ? props?.driverLicense?.documents : []
  );
  const [data, setData] = React.useState({
    name: props?.driverLicense?.name,
    relation: props?.driverLicense?.relation,
    notes:
      props?.driverLicense?.notes || props?.driverLicense?.notes_ciphertext,
    associated_documents_attributes: obj,
  });
  const [sureToAdd, setSureToAdd] = React.useState({ open: false, str: null });

  const handleOpenSureToAdd = (str) => {
    setSureToAdd({ open: true, str: str ?? null });
  };
  const handleCloseSureToAdd = () => {
    if (sureToAdd.str !== null) {
      props?.handleClose();
    }
    setSureToAdd({ open: false, str: null });
  };

  const handleSubmit = () => {
    handleCloseSureToAdd();
    const temp = data?.associated_documents_attributes?.filter(
      (e) => e.document_id !== null
    );
    if (props?.driverLicense?.associated_documents_attributes) {
      setData({ ...data, associated_documents_attributes: temp });
    } else {
      if (temp && temp.length > 0) {
        setData({ ...data, associated_documents_attributes: temp });
      } else {
        let temp2 = delete data["associated_documents_attributes"];
        setData(data);
      }
    }
    setIsLoading(true);
    personalService
      .editEmergencyCotnacts(data, props?.driverLicense?.id)
      .then((response) => {
        setIsLoading(false);
        props.forceRerender();
        props.handleClose();

        console.log(response.data);
      })
      .catch((err) => {
        errRef.current.scrollIntoView();
        setIsLoading(false);
        if (err.response && err.response.data.message) {
          if (err.response?.data?.message === "Please enter a valid date") {
            setErr([err.response?.data?.message]);
          } else {
            let array = [];
            Object.keys(err.response.data.message).forEach((key) =>
              array.push(`${key} ${err.response.data.message[key]}`)
            );
            setErr(array);
          }
        } else {
          setErr(["Internal Server Error"]);
        }
      });
  };

  const documentService = new DocumentService();

  const show = (docId) => {
    const documentId = docId ?? id;
    setSpinner(true);
    documentService
      .show(documentId, password)
      .then((res) => {
        setSpinner(false);
        setLink({
          attachment: res.data.document.attachment,
          contentType: res.data.document.content_type,
        });
        setShowDoc(true);
        setShowPasswordVerification(false);
        setPassword(null);
      })
      .catch((err) => {
        setSpinner(false);
        setPassword(null);
        setAlert({
          type: "Error",
          open: true,
          cancelText: "CANCEL",
          heading:
            err?.response && err?.response?.status === 422
              ? "Unauthorized!"
              : "Error!",
          message:
            err?.response && err?.response?.status === 422
              ? "Wrong password! please try again."
              : "An unknown error occurred, please try later.",
        });
      });
  };

  const handleChange = (event) => {
    if (event.target.name === "relation" && event.target.value === "Other") {
      setOther(true);
    } else if (
      event.target.name === "relation" &&
      relationShip.includes(event.target.value)
    ) {
      setOther(false);
      setData({ ...data, [event.target.name]: event.target.value });
    } else {
      setData({ ...data, [event.target.name]: event.target.value });
    }
  };

  const theme = useTheme();

  const initialRef = useRef(null);
  React.useEffect(() => {
    initialRef.current = data;
  }, []);
  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={() => {
          !disableCheck(initialRef, data)
            ? handleOpenSureToAdd("exit")
            : props?.handleClose();
        }}
        width="35%" /*margin="250px 0 40px"*/
        inset="15px!important"
      >
        {alert.open && (
          <AlertPopup
            btnText={alert.btnText}
            cancelText={alert.cancelText}
            open={alert.open}
            type={alert.type}
            heading={alert.heading}
            message={alert.message}
            isLoading={false}
            handleClose={() => {
              setAlert({
                open: false,
                btnText: null,
                cancelText: null,
                heading: null,
                message: null,
                type: null,
              });
            }}
            handleClick={() => {
              setAlert({
                open: false,
                btnText: null,
                cancelText: null,
                heading: null,
                message: null,
                type: null,
              });
            }}
          />
        )}
        {spinner && <SpinnerModal open={spinner} msg={"Loading"} />}
        {showDoc && (
          <FileViewer
            contentType={link?.contentType}
            link={link?.attachment}
            open={showDoc}
            handleClose={() => setShowDoc(false)}
          />
        )}
        {showPasswordVerification && (
          <PasswordVerification
            open={showPasswordVerification}
            handleClose={() => {
              setShowPasswordVerification(false);
              setId(null);
            }}
            handleChange={(e) => setPassword(e)}
            handleSubmit={() => {
              show();
            }}
          />
        )}
        <ModalHeader
          closeModal={() => {
            !disableCheck(initialRef, data)
              ? handleOpenSureToAdd("exit")
              : props?.handleClose();
          }}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          heading={"Edit " + data.name}
          color={color.white}
        />
        <div className={classes.modalBody} ref={errRef}>
          <div className="w-100 d-flex flex-row mb-4">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Personal > Emergency Contact > Edit"}
            </Typography>
          </div>
          {err?.length > 0 && (
            <div className="w-100 d-flex flex-row mb-4">
              {err?.map((val, key) => {
                return (
                  <Typography
                    key={key}
                    variant="p"
                    component="p"
                    align="left"
                    style={{
                      color: "red",
                      fontWeight: "normal",
                      marginBottom: "2px",
                    }}
                  >
                    {val.charAt(0).toUpperCase() + val.slice(1)}
                  </Typography>
                );
              })}
            </div>
          )}
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            spacing={3}
          >
            {editDoc && (
              <EditDocument
                password_protected={currentDocument?.password_protected}
                docs={doc}
                category={currentDocument?.category}
                id={currentDocument.id}
                title={currentDocument.title}
                open={editDoc}
                setDocs={setDoc}
                handleClose={() => setEditDoc(false)}
              />
            )}
            {documentModal && (
              <AddDocument
                setData={setData}
                setDoc={setDoc}
                data={data}
                associated_documents_attributes={
                  props?.driverLicense?.associated_documents_attributes
                    ? true
                    : false
                }
                doc={doc}
                open={documentModal}
                handleClose={() => setDocumentModal(false)}
                closeModal={() => setDocumentModal(false)}
                category={"Personal"}
              />
            )}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Name"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <FormInput
                id="id_number"
                name="name"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.name}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Relation"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormControl id="security-question" fullWidth={true}>
                <Select
                  id="state"
                  variant="outlined"
                  fullWidth={true}
                  margin="dense"
                  required={true}
                  value={other ? "Other" : data?.relation || "Select Relation"}
                  onChange={handleChange}
                  name="relation"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled value={"Select Relation"}>
                    Select Relation
                  </MenuItem>
                  {relationShip?.map((state, index) => {
                    return (
                      <MenuItem
                        value={state}
                        key={index}
                        style={getStyles(name, personName, theme)}
                      >
                        {state}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {other && (
              <>
                <Grid item xs={12} sm={12} md={12} className="pb-0">
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Other"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} sm={12}>
                  <FormInput
                    id="class"
                    name="relation"
                    onChange={handleChange}
                    label=""
                    variant="outlined"
                    fullWidth={true}
                    value={data.relation}
                    margin="dense"
                    required={true}
                    placeholder={"Other Relation"}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={12} className="pb-0">
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Notes"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FormInput
                id="class"
                name="notes"
                onChange={handleChange}
                label=""
                variant="outlined"
                fullWidth={true}
                margin="dense"
                required={true}
                value={data?.notes}
              />
            </Grid>
            {doc.length > 0 && (
              <>
                <Grid item xs={12} sm={4} md={4}>
                  <Typography
                    variant="h6"
                    component="h3"
                    style={{ color: color.themeColorNew }}
                  >
                    {"Document"}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={8} md={8}>
                  {doc.map((e, k) => (
                    <>
                      <Typography
                        variant="p"
                        component="p"
                        style={{ color: color.themeColorNew }}
                        key={k}
                      >
                        {e?.title}
                      </Typography>
                      <CustomButton
                        btnText={"Remove"}
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          let temp = data.associated_documents_attributes?.map(
                            (d) => {
                              if (d.document_id === e.id) {
                                return {
                                  ...d,
                                  _destroy: 1,
                                };
                              } else {
                                return d;
                              }
                            }
                          );
                          let newDoc = doc?.filter((d) => d.id !== e.id);
                          setDoc(newDoc);
                          setData({
                            ...data,
                            associated_documents_attributes: temp,
                          });
                        }}
                      />

                      <CustomButton
                        btnText={"Edit"}
                        className="ms-2"
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          setCurrentDocument(e);
                          setEditDoc(true);
                        }}
                      />

                      <CustomButton
                        btnText={"View"}
                        className="ms-2"
                        textAlign="center"
                        display={"block"}
                        background="transparent"
                        hoverBg="#0042a8"
                        border="1px solid #0042a8"
                        borderRadius="8px"
                        color="#0042a8"
                        onClick={() => {
                          if (!props?.driverLicense?.documents) {
                            show(e?.id);
                          } else {
                            if (e?.attachment) {
                              setLink({
                                attachment: _default.BASE_URL + e.attachment,
                                contentType: e.content_type,
                              });
                              setShowDoc(true);
                            } else {
                              setId(e?.id);
                              setShowPasswordVerification(true);
                            }
                          }
                        }}
                      />
                    </>
                  ))}
                </Grid>
              </>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="text-sm-center"
              style={{ textAlign: "right" }}
            >
              <CustomButton
                btnText="Add Document"
                className="mb-sm-2"
                textAlign="center"
                display={"block"}
                padding="8px 25px"
                background="#0042a8"
                hoverBg="#0042a8"
                border="0"
                borderRadius="8px"
                color="#fff"
                marginRight={"10px"}
                onClick={() => setDocumentModal(true)}
              />

              {!isLoading ? (
                <CustomButton
                  type="submit"
                  btnText="Save"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  disabled={disableCheck(initialRef, data)}
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                  onClick={() => handleOpenSureToAdd(null)}
                />
              ) : (
                <CustomButton
                  btnText={<CircularProgress size={20} color="#DDD" />}
                  type="submit"
                  textAlign="center"
                  display={"block"}
                  padding="8px 25px"
                  background="#0042a8"
                  hoverBg="#0042a8"
                  border="0"
                  borderRadius="8px"
                  color="#fff"
                />
              )}
            </Grid>
          </Grid>
        </div>
      </CustomModal>
      <SureToAdd
        open={sureToAdd.open}
        handleClose={handleCloseSureToAdd}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLoading: (bool) => {
      dispatch(setLoading(bool));
    },
    forceRerender: () => {
      dispatch(rerenderEmployer());
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.loading.isLoading,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEmergencyContact);
