export const resumeHtml = (resume) => {
    const educationFn = () => {

    let education= ""
        resume?.education?.length > 0 ? resume?.education?.forEach((e) => {
       education += `<div>
            <p><strong>School - </strong>${e?.school}</p>
        <p><strong>End Date - </strong>${e?.end_date}</p>
    </div>`



    }) : <div></div>
        return {education}
    }
    const employersFn = () => {

        let employers= ""
        resume?.employers?.length > 0 ? resume?.employers?.forEach((e) => {
            employers += `<div>
         <p><strong>Company - </strong>${e?.company}</p>
        <p><strong>Title - </strong>${e?.title}</p>
             <p><strong>Start Date - </strong>${e?.start_date}</p>
             <p><strong>End Date - </strong>${e?.end_date}</p>
         </div>`



        }) : <div></div>
        return {education}
    }
    const skillsFn = () => {

        let skills= ""
        resume?.skills?.length > 0 ? resume?.skills?.forEach((e) => {
            skills += `<div>
            <p><strong>Skill Name - </strong>${e?.skill_name}</p>
        </div`



        }) : <div></div>
        return {education}
    }

    const awardsFn = () => {

        let awards= ""
        resume?.awards?.length > 0 ? resume?.awards?.forEach((e) => {
            awards += `<div>
            <p><strong>Award Name - </strong>${e?.award_name}</p>
            <p><strong>Organization - </strong>${e?.organization}</p>
        </div>`



        }) : <div></div>
        return {awards}
    }
    const certificatesFn = () => {

        let certificates= ""
        resume?.certificates?.length > 0 ? resume?.certificates?.forEach((e) => {
            certificates += `<div>
            <p><strong>Award Name - </strong>${e?.award_name}</p>
            <p><strong>Organization - </strong>${e?.organization}</p>
        </div>`



        }) : <div></div>
        return {certificates}
    }
    const languagesFn = () => {

        let languages= ""
        resume?.languages?.length > 0 ? resume?.languages?.forEach((e) => {
            languages += `<div>
            <p><strong>Language Name - </strong>${e?.language_name}</p>
            <p><strong>Qualification - </strong>${e?.qualification}</p>
        </div`



        }) : <div></div>
        return {languages}
    }
    const {education} = educationFn()
    const {employers} = employersFn()
    const {skills} = skillsFn()
    const {awards} = awardsFn()
    const {certificates} = certificatesFn()
    const {languages} = languagesFn()
    let html = `<h1>Personal Information</h1>
    <p><strong>Name </strong>${resume?.personal_information?.first_name + " " + resume?.personal_information?.last_name}</p>
<br>
<h1>Employers</h1>
 ${employers}
<h1>Education</h1>
${education}
<h1>Skills</h1>
${skills}
<h1>Awards</h1>
${awards}
<h1>Certificates</h1>
${certificates}
<h1>Languages</h1>
${languages}

`
    console.log({html})
    return {html}
}