import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import GridItem from "../../Grid/GridItem";
import file from "../../../assets/img/file.png";
import { DocumentService } from "../../../services/document.service";
import CircularProgress from "@material-ui/core/CircularProgress";
import CertificateInformation from "../../../containers/Modals/CertificateInformation/CertificateInformation";
import LanguageInformation from "../../../containers/Modals/LanguageInformation/LanguageInformation";
import EducationInformation from "../../../containers/Modals/EducationInformation/EducationInformation";
import AwardInformation from "../../../containers/Modals/AwardInformation/AwardInformation";
import EmployerInformation from "../../../containers/Modals/EmployerInformation/EmployerInformation";
import SkillsInformation from "../../../containers/Modals/SkillsInformation/SkillsInformation";
import EditCertificate from "../../../containers/Modals/EditCertificate/EditCertificate";
import EditLanguage from "../../../containers/Modals/EditLanguage/EditLanguage";
import EditEducation from "../../../containers/Modals/EditEducation/EditEducation";
import EditAward from "../../../containers/Modals/EditAward/EditAward";
import EditEmployer from "../../../containers/Modals/EditEmployer/EditEmployer";
import CashAccountInformation from "../../../containers/Modals/CashAccountsInformation/CashAccountsInformation";
import EditSkills from "../../../containers/Modals/EditSkills/EditSkills";
import EditCashAccount from "../../../containers/Modals/EditCashAccounts/EditCashAccounts";
import ExpensesInformation from "../../../containers/Modals/ExpensesInformation/ExpensesInformation";
import EditExpenses from "../../../containers/Modals/EditExpenses/EditExpenses";
import IncomesInformation from "../../../containers/Modals/IncomesInformation/IncomesInformation";
import EditIncomes from "../../../containers/Modals/EditIncomes/EditIncomes";
import MarginAccountsInformation from "../../../containers/Modals/MarginAccountsInformation/MarginAccountsInformation";
import EditMarginAccounts from "../../../containers/Modals/EditMarginAccounts/EditMarginAccounts";
import MarketableSecuritiesInformation from "../../../containers/Modals/MarketableSecuritiesInformation/MarketableSecuritiesInformation";
import EditMarketableSecurities from "../../../containers/Modals/EditMarketableSecurities/EditMarketableSecurities";
import NonmarketableSecuritiesInformation from "../../../containers/Modals/NonmarketableSecuritiesInformation/NonmarketableSecuritiesInformation";
import EditNonmarketableSecurities from "../../../containers/Modals/EditNonmarketableSecurities/EditNonmarketableSecurities";
import RetirementFundsInformation from "../../../containers/Modals/RetirementFundsInformation/RetirementFundsInformation";
import EditRetirementFunds from "../../../containers/Modals/EditRetirementFunds/EditRetirementFunds";
import TaxReturnsInformation from "../../../containers/Modals/TaxReturnsInformation/TaxReturnsInformation";
import EditTaxReturns from "../../../containers/Modals/EditTaxReturns/EditTaxReturns";
import AllergyInformation from "../../../containers/Modals/AllergyInformation/AllergyInformation";
import EditAllergy from "../../../containers/Modals/EditAllergy/EditAllergy";
import FamilyConditionInformation from "../../../containers/Modals/FamilyConditionInformation/FamilyConditionInformation";
import EditFamilyCondition from "../../../containers/Modals/EditFamilyCondition/EditFamilyCondition";
import HealthConditionInformation from "../../../containers/Modals/HealthConditionInformation/HealthConditionInformation";
import EditHealthCondition from "../../../containers/Modals/EditHealthCondition/EditHealthCondition";
import HospitalizationInformation from "../../../containers/Modals/HospitalizationInformation/HospitalizationInformation";
import EditHospitalization from "../../../containers/Modals/EditHospitalization/EditHospitalization";
import ImmunizationInformation from "../../../containers/Modals/ImmunizationInformation/ImmunizationInformation";
import EditImmunization from "../../../containers/Modals/EditImmunization/EditImmunization";
import MedicationInformation from "../../../containers/Modals/MedicationInformation/MedicationInformation";
import EditMedication from "../../../containers/Modals/EditMedication/EditMedication";
import ProcedureInformation from "../../../containers/Modals/ProcedureInformation/ProcedureInformation";
import EditProcedure from "../../../containers/Modals/EditProcedure/EditProcedure";
import TestResultInformation from "../../../containers/Modals/TestResultInformation/TestResultInformation";
import EditTestResult from "../../../containers/Modals/EditTestResult/EditTestResult";
import EditWellness from "../../../containers/Modals/EditWellness/EditWellness";
import AutoInsuranceInformation from "../../../containers/Modals/AutoInsuranceInformation/AutoInsuranceInformation";
import EditAutoInsurance from "../../../containers/Modals/EditAutoInsurance/EditAutoInsurance";
import DisabilityInsuranceInformation from "../../../containers/Modals/DisabilityInsuranceInformation/DisabilityInsuranceInformation";
import EditDisabilityInsurance from "../../../containers/Modals/EditDisabilityInsurance/EditDisabilityInsurance";
import HealthInsuranceInformation from "../../../containers/Modals/HealthInsuranceInformation/HealthInsuranceInformation";
import EditHealthInsurance from "../../../containers/Modals/EditHealthInsurance/EditHealthInsurance";
import OtherInsuranceInformation from "../../../containers/Modals/OtherInsuranceInformation/OtherInsuranceInformation";
import EditOtherInsurance from "../../../containers/Modals/EditOtherInsurance copy/EditOtherInsurance";
import PropertyInsuranceInformation from "../../../containers/Modals/PropertyInsuranceInformation/PropertyInsuranceInformation";
import EditPropertyInsurance from "../../../containers/Modals/EditPropertyInsurance/EditPropertyInsurance";
import AlternateIdInformation from "../../../containers/Modals/AlternateIdInformation/AlternateIdInformation";
import EditAlternateId from "../../../containers/Modals/EditAlternateId/EditAlternateId";
import DriverLicenses from "../../../views/Personal/DriverLicenses/DriverLicenses";
import EditDriverLicense from "../../../containers/Modals/EditDriverLicense/EditDriverLicense";
import EmailAccountInformation from "../../../containers/Modals/EmailAccountInformation/EmailAccountInformation";
import EditEmailAccount from "../../../containers/Modals/EditEmailAccount/EditEmailAccount";
import LoyaltyProgramInformation from "../../../containers/Modals/LoyaltyProgramInformation/LoyaltyProgramInformation";
import EditLoyaltyProgram from "../../../containers/Modals/EditLoyaltyProgram/EditLoyaltyProgram";
import PassportInformation from "../../../containers/Modals/PassportInformation/PassportInformation";
import EditPassport from "../../../containers/Modals/EditPassport/EditPassport";
import PersonalInformations from "../../../containers/Modals/PersonalInformation/PersonalInformation";
import EditPersonalInformation from "../../../containers/Modals/EditPersonalInformation/EditPersonalInformation";
import VisaInformation from "../../../containers/Modals/VisaInformation/VisaInformation";
import EditVisa from "../../../containers/Modals/EditVisa/EditVisa";
import WebLoginInformation from "../../../containers/Modals/WebLoginInformation/WebLoginInformation";
import EditWebLogin from "../../../containers/Modals/EditWebLogin/EditWebLogin";
import HouseInformation from "../../../containers/Modals/HouseInformation/HouseInformation";
import EditHouse from "../../../containers/Modals/EditHouse/EditHouse";
import PersonalItemInformation from "../../../containers/Modals/PersonalItemInformation/PersonalItemInformation";
import EditPersonalItem from "../../../containers/Modals/EditPersonalItem/EditPersonalItem";
import RentalInformation from "../../../containers/Modals/RentalInformation/RentalInformation";
import EditRental from "../../../containers/Modals/EditRental/EditRental";
import SerialNumberInformation from "../../../containers/Modals/SerialNumberInformation/SerialNumberInformation";
import EditSerialNumber from "../../../containers/Modals/EditSerialNumber/EditSerialNumber";
import AutoServiceInformation from "../../../containers/Modals/AutoServiceInformation/AutoServiceInformation";
import EditAutoService from "../../../containers/Modals/EditAutoService/EditAutoService";
import VehicleInformation from "../../../containers/Modals/VehicleInformation/VehicleInformation";
import EditVehicle from "../../../containers/Modals/EditVehicle/EditVehicle";
import LifeInsuranceInformation from "../../../containers/Modals/LifeInsuranceInformation/LifeInsuranceInformation";
import EditLifeInsurance from "../../../containers/Modals/EditLifeInsurance/EditLifeInsurance";
import { connect } from "react-redux";
import { truncateString } from "../../../Utils/truncateString";
import "../../../assets/css/material-dashboard-react.css";
import GridContainer from "../../Grid/GridContainer";

const RecentlyViewed = (props) => {
  const documentService = new DocumentService();
  const [openEdit, setOpenEdit] = useState(false);
  const [category, setCategory] = useState(null);
  const [index, setIndex] = useState(null);
  const [recents, setRecents] = useState([]);
  const [openInfo, setOpenInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  React.useEffect(() => {
    setLoading(true);
    documentService
      .recentlyViewed()
      .then((res) => {
        setLoading(false);
        console.log({ recents: res.data });
        if (
          res?.data?.recently_viewed_items &&
          res?.data?.recently_viewed_items?.length > 0
        ) {
          let array = [];
          res?.data?.recently_viewed_items?.forEach(
            (a) => a.record && array.push(a)
          );
          console.log({ array });
          setRecents(array);
        } else {
          setRecents([]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setErr("Nothing to show yet.");
      });
  }, []);

  return (
    <>
      <Grid
        container
        wrap="wrap"
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={12} style={{ margin: "25px 0" }}>
          <Typography
            variant="p"
            component="p"
            style={{ color: "#060d5c", fontWeight: "500" }}
          >
            {"Recently Viewed"}
          </Typography>
        </Grid>
      </Grid>

      {!loading && recents.length > 0 ? (
        recents
          .filter((cat) => cat.actionable_type !== "Document")
          .sort((a, b) => {
            if (props?.sortType === "alpha") {
              if (props?.order === "asc") {
                return a.actionable_type?.localeCompare(b.actionable_type);
              } else {
                return b.actionable_type?.localeCompare(a.actionable_type);
              }
            } else {
              if (props?.order === "asc") {
                return a.created_at?.localeCompare(b.created_at);
              } else {
                return b.created_at?.localeCompare(a.created_at);
              }
            }
          })
          .map((r, key) => {
            return (
              <Card style={{ marginBottom: "1rem", height: "auto" }} key={key}>
                {openInfo && index === key && category === "Certificate" && (
                  <CertificateInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    certificate={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}{" "}
                {openEdit && index === key && category === "Certificate" && (
                  <EditCertificate
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    certificate={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Language" && (
                  <LanguageInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    language={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Language" && (
                  <EditLanguage
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    language={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Education" && (
                  <EducationInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    education={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Education" && (
                  <EditEducation
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    education={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Award" && (
                  <AwardInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    award={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Award" && (
                  <EditAward
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    award={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Employer" && (
                  <EmployerInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    info={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Employer" && (
                  <EditEmployer
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    employer={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Skill" && (
                  <SkillsInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    skills={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Skill" && (
                  <EditSkills
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    skill={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Cash Account" && (
                  <CashAccountInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    cashAccount={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Cash Account" && (
                  <EditCashAccount
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    cashAccount={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Expense" && (
                  <ExpensesInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    expenses={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Expense" && (
                  <EditExpenses
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    expenses={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Income" && (
                  <IncomesInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    income={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Income" && (
                  <EditIncomes
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    income={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Margin Account" && (
                  <MarginAccountsInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    marginAccount={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Margin Account" && (
                  <EditMarginAccounts
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    marginAccount={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo &&
                  index === key &&
                  category === "Marketable Security" && (
                    <MarketableSecuritiesInformation
                      open={openInfo}
                      handleClose={() => {
                        setOpenInfo(false);
                        setOpenEdit(false);
                        setCategory(null);
                        setIndex(null);
                      }}
                      handleOpenEdit={() => {
                        setOpenInfo(false);
                        setOpenEdit(true);
                      }}
                      marketableSecuritie={[r.record]}
                      closeModal={() => setOpenInfo(false)}
                    />
                  )}
                {openEdit &&
                  index === key &&
                  category === "Marketable Security" && (
                    <EditMarketableSecurities
                      open={openEdit}
                      handleClose={() => setOpenEdit(false)}
                      marketableSecuritie={r.record}
                      closeModal={() => setOpenEdit(false)}
                    />
                  )}
                {openInfo &&
                  index === key &&
                  category === "Nonmarketable Security" && (
                    <NonmarketableSecuritiesInformation
                      open={openInfo}
                      handleClose={() => {
                        setOpenInfo(false);
                        setOpenEdit(false);
                        setCategory(null);
                        setIndex(null);
                      }}
                      handleOpenEdit={() => {
                        setOpenInfo(false);
                        setOpenEdit(true);
                      }}
                      nonmarketableSecuritie={[r.record]}
                      closeModal={() => setOpenInfo(false)}
                    />
                  )}
                {openEdit &&
                  index === key &&
                  category === "Nonmarketable Security" && (
                    <EditNonmarketableSecurities
                      open={openEdit}
                      handleClose={() => setOpenEdit(false)}
                      nonmarketableSecuritie={r.record}
                      closeModal={() => setOpenEdit(false)}
                    />
                  )}
                {openInfo &&
                  index === key &&
                  category === "Retirement Fund" && (
                    <RetirementFundsInformation
                      open={openInfo}
                      handleClose={() => {
                        setOpenInfo(false);
                        setOpenEdit(false);
                        setCategory(null);
                        setIndex(null);
                      }}
                      handleOpenEdit={() => {
                        setOpenInfo(false);
                        setOpenEdit(true);
                      }}
                      retirementFund={[r.record]}
                      closeModal={() => setOpenInfo(false)}
                    />
                  )}
                {openEdit &&
                  index === key &&
                  category === "Retirement Fund" && (
                    <EditRetirementFunds
                      open={openEdit}
                      handleClose={() => setOpenEdit(false)}
                      retirementFund={r.record}
                      closeModal={() => setOpenEdit(false)}
                    />
                  )}
                {openInfo && index === key && category === "Tax Return" && (
                  <TaxReturnsInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    taxReturn={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Tax Return" && (
                  <EditTaxReturns
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    taxReturn={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Allergy" && (
                  <AllergyInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    allergy={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Allergy" && (
                  <EditAllergy
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    allergy={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo &&
                  index === key &&
                  category === "Family Condition" && (
                    <FamilyConditionInformation
                      open={openInfo}
                      handleClose={() => {
                        setOpenInfo(false);
                        setOpenEdit(false);
                        setCategory(null);
                        setIndex(null);
                      }}
                      handleOpenEdit={() => {
                        setOpenInfo(false);
                        setOpenEdit(true);
                      }}
                      familyCondition={[r.record]}
                      closeModal={() => setOpenInfo(false)}
                    />
                  )}
                {openEdit &&
                  index === key &&
                  category === "Family Condition" && (
                    <EditFamilyCondition
                      open={openEdit}
                      handleClose={() => setOpenEdit(false)}
                      familyCondition={r.record}
                      closeModal={() => setOpenEdit(false)}
                    />
                  )}
                {openInfo &&
                  index === key &&
                  category === "Health Condition" && (
                    <HealthConditionInformation
                      open={openInfo}
                      handleClose={() => {
                        setOpenInfo(false);
                        setOpenEdit(false);
                        setCategory(null);
                        setIndex(null);
                      }}
                      handleOpenEdit={() => {
                        setOpenInfo(false);
                        setOpenEdit(true);
                      }}
                      healthCondition={[r.record]}
                      closeModal={() => setOpenInfo(false)}
                    />
                  )}
                {openEdit &&
                  index === key &&
                  category === "Health Condition" && (
                    <EditHealthCondition
                      open={openEdit}
                      handleClose={() => setOpenEdit(false)}
                      healthCondition={r.record}
                      closeModal={() => setOpenEdit(false)}
                    />
                  )}
                {openInfo &&
                  index === key &&
                  category === "Hospitalization" && (
                    <HospitalizationInformation
                      open={openInfo}
                      handleClose={() => {
                        setOpenInfo(false);
                        setOpenEdit(false);
                        setCategory(null);
                        setIndex(null);
                      }}
                      handleOpenEdit={() => {
                        setOpenInfo(false);
                        setOpenEdit(true);
                      }}
                      hospitalization={[r.record]}
                      closeModal={() => setOpenInfo(false)}
                    />
                  )}
                {openEdit &&
                  index === key &&
                  category === "Hospitalization" && (
                    <EditHospitalization
                      open={openEdit}
                      handleClose={() => setOpenEdit(false)}
                      hospitalization={r.record}
                      closeModal={() => setOpenEdit(false)}
                    />
                  )}
                {openInfo && index === key && category === "Immunization" && (
                  <ImmunizationInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    immunization={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Immunization" && (
                  <EditImmunization
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    immunization={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Medication" && (
                  <MedicationInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    medication={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Medication" && (
                  <EditMedication
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    medication={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Procedure" && (
                  <ProcedureInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    procedure={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Procedure" && (
                  <EditProcedure
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    procedure={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Test Result" && (
                  <TestResultInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    testResult={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Test Result" && (
                  <EditTestResult
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    testResult={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Wellness" && (
                  <WellnessInformationss
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    wellness={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Wellness" && (
                  <EditWellness
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    wellness={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Auto Insurance" && (
                  <AutoInsuranceInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    autoInsurance={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Auto Insurance" && (
                  <EditAutoInsurance
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    autoInsurance={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo &&
                  index === key &&
                  category === "Disability Insurance" && (
                    <DisabilityInsuranceInformation
                      open={openInfo}
                      handleClose={() => {
                        setOpenInfo(false);
                        setOpenEdit(false);
                        setCategory(null);
                        setIndex(null);
                      }}
                      handleOpenEdit={() => {
                        setOpenInfo(false);
                        setOpenEdit(true);
                      }}
                      disabilityInsurance={[r.record]}
                      closeModal={() => setOpenInfo(false)}
                    />
                  )}
                {openEdit &&
                  index === key &&
                  category === "Disability Insurance" && (
                    <EditDisabilityInsurance
                      open={openEdit}
                      handleClose={() => setOpenEdit(false)}
                      disabilityInsurance={r.record}
                      closeModal={() => setOpenEdit(false)}
                    />
                  )}
                {openInfo &&
                  index === key &&
                  category === "Health Insurance" && (
                    <HealthInsuranceInformation
                      open={openInfo}
                      handleClose={() => {
                        setOpenInfo(false);
                        setOpenEdit(false);
                        setCategory(null);
                        setIndex(null);
                      }}
                      handleOpenEdit={() => {
                        setOpenInfo(false);
                        setOpenEdit(true);
                      }}
                      healthInsurance={[r.record]}
                      closeModal={() => setOpenInfo(false)}
                    />
                  )}
                {openEdit &&
                  index === key &&
                  category === "Health Insurance" && (
                    <EditHealthInsurance
                      open={openEdit}
                      handleClose={() => setOpenEdit(false)}
                      healthInsurance={r.record}
                      closeModal={() => setOpenEdit(false)}
                    />
                  )}
                {openInfo &&
                  index === key &&
                  category === "Other Insurance" && (
                    <OtherInsuranceInformation
                      open={openInfo}
                      handleClose={() => {
                        setOpenInfo(false);
                        setOpenEdit(false);
                        setCategory(null);
                        setIndex(null);
                      }}
                      handleOpenEdit={() => {
                        setOpenInfo(false);
                        setOpenEdit(true);
                      }}
                      otherInsurance={[r.record]}
                      closeModal={() => setOpenInfo(false)}
                    />
                  )}
                {openEdit &&
                  index === key &&
                  category === "Other Insurance" && (
                    <EditOtherInsurance
                      open={openEdit}
                      handleClose={() => setOpenEdit(false)}
                      otherInsurance={r.record}
                      closeModal={() => setOpenEdit(false)}
                    />
                  )}
                {openInfo &&
                  index === key &&
                  category === "Property Insurance" && (
                    <PropertyInsuranceInformation
                      open={openInfo}
                      handleClose={() => {
                        setOpenInfo(false);
                        setOpenEdit(false);
                        setCategory(null);
                        setIndex(null);
                      }}
                      handleOpenEdit={() => {
                        setOpenInfo(false);
                        setOpenEdit(true);
                      }}
                      propertyInsurance={[r.record]}
                      closeModal={() => setOpenInfo(false)}
                    />
                  )}
                {openEdit &&
                  index === key &&
                  category === "Property Insurance" && (
                    <EditPropertyInsurance
                      open={openEdit}
                      handleClose={() => setOpenEdit(false)}
                      propertyInsurance={r.record}
                      closeModal={() => setOpenEdit(false)}
                    />
                  )}
                {openInfo &&
                  index === key &&
                  category === "Alternate Identification" && (
                    <AlternateIdInformation
                      open={openInfo}
                      handleClose={() => {
                        setOpenInfo(false);
                        setOpenEdit(false);
                        setCategory(null);
                        setIndex(null);
                      }}
                      handleOpenEdit={() => {
                        setOpenInfo(false);
                        setOpenEdit(true);
                      }}
                      alternateIdentification={[r.record]}
                      closeModal={() => setOpenInfo(false)}
                    />
                  )}
                {openEdit &&
                  index === key &&
                  category === "Alternate Identification" && (
                    <EditAlternateId
                      open={openEdit}
                      handleClose={() => setOpenEdit(false)}
                      alternateIdentification={r.record}
                      closeModal={() => setOpenEdit(false)}
                    />
                  )}
                {openInfo && index === key && category === "Driver License" && (
                  <DriverLicenses
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    driverLicense={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Driver License" && (
                  <EditDriverLicense
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    driverLicense={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Email Account" && (
                  <EmailAccountInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    emailAccountInformation={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Email Account" && (
                  <EditEmailAccount
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    emailAccount={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo &&
                  index === key &&
                  category === "Loyalty Program" && (
                    <LoyaltyProgramInformation
                      open={openInfo}
                      handleClose={() => {
                        setOpenInfo(false);
                        setOpenEdit(false);
                        setCategory(null);
                        setIndex(null);
                      }}
                      handleOpenEdit={() => {
                        setOpenInfo(false);
                        setOpenEdit(true);
                      }}
                      loyaltyProgram={[r.record]}
                      closeModal={() => setOpenInfo(false)}
                    />
                  )}
                {openEdit &&
                  index === key &&
                  category === "Loyalty Program" && (
                    <EditLoyaltyProgram
                      open={openEdit}
                      handleClose={() => setOpenEdit(false)}
                      loyaltyProgram={r.record}
                      closeModal={() => setOpenEdit(false)}
                    />
                  )}
                {openInfo && index === key && category === "Passport" && (
                  <PassportInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    passport={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Passport" && (
                  <EditPassport
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    passport={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo &&
                  index === key &&
                  category === "Personal Information" && (
                    <PersonalInformations
                      open={openInfo}
                      handleClose={() => {
                        setOpenInfo(false);
                        setOpenEdit(false);
                        setCategory(null);
                        setIndex(null);
                      }}
                      handleOpenEdit={() => {
                        setOpenInfo(false);
                        setOpenEdit(true);
                      }}
                      personalInformation={[r.record]}
                      closeModal={() => setOpenInfo(false)}
                    />
                  )}
                {openEdit &&
                  index === key &&
                  category === "Personal Information" && (
                    <EditPersonalInformation
                      open={openEdit}
                      handleClose={() => setOpenEdit(false)}
                      personalInformation={r.record}
                      closeModal={() => setOpenEdit(false)}
                    />
                  )}
                {openInfo && index === key && category === "Visa" && (
                  <VisaInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    visa={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Visa" && (
                  <EditVisa
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    visa={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Web Login" && (
                  <WebLoginInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    webLogin={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Web Login" && (
                  <EditWebLogin
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    webLogin={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Life Insurance" && (
                  <LifeInsuranceInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    lifeInsurance={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Life Insurance" && (
                  <EditLifeInsurance
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    lifeInsurance={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "House" && (
                  <HouseInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    house={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "House" && (
                  <EditHouse
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    house={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Personal Item" && (
                  <PersonalItemInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    personalItem={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Personal Item" && (
                  <EditPersonalItem
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    personalItem={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Rental" && (
                  <RentalInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    rental={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Rental" && (
                  <EditRental
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    rental={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Serial Number" && (
                  <SerialNumberInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    serialNumber={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Serial Number" && (
                  <EditSerialNumber
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    serialNumber={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Auto Service" && (
                  <AutoServiceInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    autoService={[
                      {
                        ...r?.record,
                        vehicle_type: r?.vehicle_type,
                        make: r?.make,
                        model: r?.model,
                      },
                    ]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Auto Service" && (
                  <EditAutoService
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    autoService={{
                      ...r?.record,
                      vehicle_type: r?.vehicle_type,
                      make: r?.make,
                      model: r?.model,
                    }}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                {openInfo && index === key && category === "Vehicle" && (
                  <VehicleInformation
                    open={openInfo}
                    handleClose={() => {
                      setOpenInfo(false);
                      setOpenEdit(false);
                      setCategory(null);
                      setIndex(null);
                    }}
                    handleOpenEdit={() => {
                      setOpenInfo(false);
                      setOpenEdit(true);
                    }}
                    vehicle={[r.record]}
                    closeModal={() => setOpenInfo(false)}
                  />
                )}
                {openEdit && index === key && category === "Vehicle" && (
                  <EditVehicle
                    open={openEdit}
                    handleClose={() => setOpenEdit(false)}
                    vehicle={r.record}
                    closeModal={() => setOpenEdit(false)}
                  />
                )}
                <CardHeader color="warning" stats icon>
                  <Grid
                    container
                    alignItems={"center"}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setOpenInfo(true);
                      setCategory(r.actionable_type);
                      setIndex(key);
                    }}
                  >
                    <GridItem
                      xs={12}
                      sm={1}
                      md={1}
                      align="left"
                      container
                      alignItems="center"
                    >
                      <img src={file} />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={8}
                      container
                      alignItems="center"
                    >
                      <Grid container alignItems="center">
                        {r.actionable_type === "House" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              alignItems="center"
                              className="px-sm-0"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{ color: "#060d5c", fontWeight: "500" }}
                              >
                                House
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{ color: "#060d5c", fontWeight: "500" }}
                              >
                                {r.record.ownership_description?.length > 20
                                  ? truncateString(
                                      r?.record?.ownership_description,
                                      20
                                    )
                                  : r.record.ownership_description}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Alternate Identification" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{ color: "#060d5c", fontWeight: "500" }}
                              >
                                Alternate Identification
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{ color: "#060d5c", fontWeight: "500" }}
                              >
                                {r.record?.id_type?.length > 20
                                  ? truncateString(r?.record?.id_type, 20)
                                  : r.record?.id_type}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Driver License" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{ color: "#060d5c", fontWeight: "500" }}
                              >
                                Driver License
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{ color: "#060d5c", fontWeight: "500" }}
                              >
                                {r.record.license_state?.length > 20
                                  ? truncateString(r?.record?.license_state, 20)
                                  : r.record.license_state}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Email Account" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{ color: "#060d5c", fontWeight: "500" }}
                              >
                                Email Account
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{ color: "#060d5c", fontWeight: "500" }}
                              >
                                {r.record.type?.length > 20
                                  ? truncateString(r?.record?.type, 20)
                                  : r.record.type}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Loyalty Program" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{ color: "#060d5c", fontWeight: "500" }}
                              >
                                Loyalty Program
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{ color: "#060d5c", fontWeight: "500" }}
                              >
                                {r.record.description?.length > 20
                                  ? truncateString(r?.record?.description, 20)
                                  : r.record.description}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Passport" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{ color: "#060d5c", fontWeight: "500" }}
                              >
                                Passport
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.country?.length > 20
                                  ? truncateString(r?.record?.country, 20)
                                  : r.record.country}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Personal Information" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Personal Information
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.first_name?.length > 20
                                  ? truncateString(r?.record?.first_name, 20)
                                  : r.record.first_name}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Visa" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Visa
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.visa_number?.length > 20
                                  ? truncateString(r?.record?.visa_number, 20)
                                  : r.record.visa_number}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Web Login" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Web Login
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.description?.length > 20
                                  ? truncateString(r?.record?.description, 20)
                                  : r.record.description}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Emergency Contact" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Emergency Contact
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.name?.length > 20
                                  ? truncateString(r?.record?.name, 20)
                                  : r.record.name}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Family Condition" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Family Condition
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.family_condition_name?.length > 20
                                  ? truncateString(
                                      r?.record?.family_condition_name,
                                      20
                                    )
                                  : r.record.family_condition_name}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Health Condition" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Health Condition
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.health_condition_name?.length > 20
                                  ? truncateString(
                                      r?.record?.health_condition_name,
                                      20
                                    )
                                  : r.record.health_condition_name}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Hospitalization" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Hospitalization
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.medical_problem?.length > 20
                                  ? truncateString(
                                      r?.record?.medical_problem,
                                      20
                                    )
                                  : r.record.medical_problem}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Immunization" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Immunization
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.immunization_name?.length > 20
                                  ? truncateString(
                                      r?.record?.immunization_name,
                                      20
                                    )
                                  : r.record.immunization_name}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Medication" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Medication
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.medicine?.length > 20
                                  ? truncateString(r?.record?.medicine, 20)
                                  : r.record.medicine}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Procedure" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Procedure
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.procedure_name?.length > 20
                                  ? truncateString(
                                      r?.record?.procedure_name,
                                      20
                                    )
                                  : r.record.procedure_name}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Test Result" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Test Result
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.test_name?.length > 20
                                  ? truncateString(r?.record?.test_name, 20)
                                  : r.record.test_name}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Wellness" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Wellness
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.primary_care_physician?.length > 20
                                  ? truncateString(
                                      r?.record?.primary_care_physician,
                                      20
                                    )
                                  : r.record.primary_care_physician}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Allergy" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Allergy
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.allergy_name?.length > 20
                                  ? truncateString(r?.record?.allergy_name, 20)
                                  : r.record.allergy_name}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Skill" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Skill
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.skill_name?.length > 20
                                  ? truncateString(r?.record?.skill_name, 20)
                                  : r.record.skill_name}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Employer" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Employer
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.company?.length > 20
                                  ? truncateString(r?.record?.company, 20)
                                  : r.record.company}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Award" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Award
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.award_name?.length > 20
                                  ? truncateString(r?.record?.award_name, 20)
                                  : r.record.award_name}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Cash Account" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Cash Account
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.institution?.length > 20
                                  ? truncateString(r?.record?.institution, 20)
                                  : r.record.institution}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Expense" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Expense
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.expense_type?.length > 20
                                  ? truncateString(r?.record?.expense_type, 20)
                                  : r.record.expense_type}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Income" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Income
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.income_type?.length > 20
                                  ? truncateString(r?.record?.income_type, 20)
                                  : r.record.income_type}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Margin Account" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Margin Account
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.brokerage_company?.length > 20
                                  ? truncateString(
                                      r?.record?.brokerage_company,
                                      20
                                    )
                                  : r.record.brokerage_company}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Marketable security" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Marketable Security
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.account_description?.length > 20
                                  ? truncateString(
                                      r?.record?.account_description,
                                      20
                                    )
                                  : r.record.account_description}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Nonmarketable Security" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Nonmarketable Security
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.account_description?.length > 20
                                  ? truncateString(
                                      r?.record?.account_description,
                                      20
                                    )
                                  : r.record.account_description}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Retirement Fund" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Retirement fund
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.brokerage_company?.length > 20
                                  ? truncateString(
                                      r?.record?.brokerage_company,
                                      20
                                    )
                                  : r.record.brokerage_company}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Tax Return" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Tax Return
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.tax_year?.length > 20
                                  ? truncateString(r?.record?.tax_year, 20)
                                  : r.record.tax_year}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Auto Insurance" ||
                          r.actionable_type === "Disability Insurance" ||
                          r.actionable_type === "Health Insurance" ||
                          r.actionable_type === "Life Insurance" ||
                          r.actionable_type === "Property Insurance" ||
                          r.actionable_type === "Other Insurance" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Auto Insurance
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.provider?.length > 20
                                  ? truncateString(r?.record?.provider, 20)
                                  : r.record.provider}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Disability Insurance" ||
                          r.actionable_type === "Health Insurance" ||
                          r.actionable_type === "Life Insurance" ||
                          r.actionable_type === "Property Insurance" ||
                          r.actionable_type === "Other Insurance" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Disability Insurance
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.provider?.length > 20
                                  ? truncateString(r?.record?.provider, 20)
                                  : r.record.provider}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Health Insurance" ||
                          r.actionable_type === "Life Insurance" ||
                          r.actionable_type === "Property Insurance" ||
                          r.actionable_type === "Other Insurance" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Health Insurance
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.provider?.length > 20
                                  ? truncateString(r?.record?.provider, 20)
                                  : r.record.provider}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Life Insurance" ||
                          r.actionable_type === "Property Insurance" ||
                          r.actionable_type === "Other Insurance" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Life Insurance
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.provider?.length > 20
                                  ? truncateString(r?.record?.provider, 20)
                                  : r.record.provider}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Property Insurance" ||
                          r.actionable_type === "Other Insurance" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Property Insurance
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.provider?.length > 20
                                  ? truncateString(r?.record?.provider, 20)
                                  : r.record.provider}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Other Insurance" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Other Insurance
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.provider?.length > 20
                                  ? truncateString(r?.record?.provider, 20)
                                  : r.record.provider}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Auto Service" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Auto Service
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.service_type?.length > 20
                                  ? truncateString(r?.record?.service_type, 20)
                                  : r.record.service_type}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Vehicle" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Vehicle
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.vehicle_type?.length > 20
                                  ? truncateString(r?.record?.vehicle_type, 20)
                                  : r.record.vehicle_type}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Personal Item" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Personal Item
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.description?.length > 20
                                  ? truncateString(r?.record?.description, 20)
                                  : r.record.description}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Rental" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Rental
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.description?.length > 20
                                  ? truncateString(r?.record?.description, 20)
                                  : r.record.description}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Serial Number" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Serial Number
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.product_name?.length > 20
                                  ? truncateString(r?.record?.product_name, 20)
                                  : r.record.product_name}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Certificate" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Certificate
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.certificate_name?.length > 20
                                  ? truncateString(
                                      r?.record?.certificate_name,
                                      20
                                    )
                                  : r.record.certificate_name}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Language" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Language
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.language_name?.length > 20
                                  ? truncateString(r?.record?.language_name, 20)
                                  : r.record.language_name}
                              </Typography>
                            </GridItem>
                          </>
                        ) : r.actionable_type === "Education" ? (
                          <>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                Education
                              </Typography>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="ps-0"
                              alignItems="center"
                            >
                              <Typography
                                variant="p"
                                component="p"
                                align="left"
                                style={{
                                  color: "#060d5c",
                                  fontWeight: "500",
                                }}
                              >
                                {r.record.school?.length > 20
                                  ? truncateString(r?.record?.school, 20)
                                  : r.record.school}
                              </Typography>
                            </GridItem>
                          </>
                        ) : null}
                      </Grid>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={3}
                      container
                      alignItems="center"
                    >
                      <Typography
                        variant="p"
                        component="p"
                        align="left"
                        style={{ color: "#060d5c", fontWeight: "500" }}
                      >
                        {new Date(r.created_at).toLocaleString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </Typography>
                    </GridItem>
                  </Grid>
                </CardHeader>
              </Card>
            );
          })
      ) : err ? (
        <Grid item xs={12} md={3}>
          <p>{err}</p>
        </Grid>
      ) : loading ? (
        <Grid item xs={12} md={3}>
          <CircularProgress size={20} color="#DDD" />
        </Grid>
      ) : (
        <Grid item xs={12} md={3}>
          <p>Nothing to show yet.</p>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    sortType: state?.sort?.sort,
    order: state?.sort?.order,
  };
};

export default connect(mapStateToProps)(RecentlyViewed);
