import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Card from "../../Card/Card";
import {Grid, Typography} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import {Auth} from "../../../services/auth.service";

const useStyles = makeStyles(styles);
export default function AlertSharesAndStorageInfo(props) {
    const auth = new Auth();
    const classes = useStyles();
    const [ac, setAc] = useState(null)
    useEffect(() => {
        auth.accountAttributes()
            .then((response) => {
                setAc(response.data.account_attributes)
                console.log(response.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])
    return (
        <>
            {ac && <Card className={classes.accountCard}>
                <Grid item xs={12} md={12} className={classes.marginBottom18px}>
                    <Typography variant="h5" component="h3" style={{color: "#060d5c"}}>
                        {"Account Attributes Summary"}
                    </Typography>
                </Grid>
                <Grid container className={classes.marginBottom18px}>
                    <Grid item xs={12} md={6}>
                        <Typography
                            variant="p"
                            component="p"
                            className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
                            style={{color: "#ffb722", fontWeight: "bold"}}
                        >
                            {"Alerts Count"}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        container
                        className="justify-content-sm-end justify-content-start mt-sm-0 mt-3"
                    >
                        <Typography variant="p" component="p" style={{color: "#060d5c"}}>
                            {ac.alerts_count}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container className={classes.marginBottom18px}>
                    <Grid item xs={12} md={6}>
                        <Typography
                            variant="p"
                            component="p"
                            className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
                            style={{color: "#ffb722", fontWeight: "bold"}}
                        >
                            {"Shares Count"}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        container
                        className="justify-content-sm-end justify-content-start mt-sm-0 mt-3"
                    >
                        <Typography variant="p" component="p" style={{color: "#060d5c"}}>
                            {ac.shares_count}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container className={classes.marginBottom18px}>
                    <Grid item xs={12} md={6}>
                        <Typography
                            variant="p"
                            component="p"
                            className={`${classes.normalFontSize} ${classes.marginBottom14px}`}
                            style={{color: "#ffb722", fontWeight: "bold"}}
                        >
                            {"Storage Used"}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        container
                        className="justify-content-sm-end justify-content-start mt-sm-0 mt-3"
                    >
                        <Typography variant="p" component="p" style={{color: "#060d5c"}}>
                            {ac.total_storage_used}
                        </Typography>
                    </Grid>
                </Grid>
            </Card>}
        </>
    );
}
