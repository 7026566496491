import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Slider } from "@material-ui/core";
import styles from "../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import color from "../../assets/css/color";
import photo from "../../assets/img/photo.jpg";
import { CustomButton } from "../CustomButton/Button.js";
import _default from "../../config/default";
import business from "../../assets/img/business.jpeg";
import businessImage from "../../assets/img/business-image.png";
import AvatarEditor from "react-avatar-editor";

const useStyles = makeStyles(styles);
export default function ProfileImageUpload(props) {
  const classes = useStyles();
  const [preview, setPreview] = useState(null);
  const [attachment, setAttachment] = useState(props.profile.attachment);
  const [url, setUrl] = useState(null);
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImg: null,
  });
  const [src, setSrc] = useState(null);
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  var editor = "";

  const handleSlider = (event, value) => {
    setPicture({
      ...picture,
      zoom: value,
    });
  };
  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      props.setInfo({ ...props.info, attachment: file });
      console.log({ file });
      setSrc(file);
      setPicture({ ...picture, cropperOpen: true });
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      // props.setData({ ...props?.data, image: file });
    }
    console.log(file);
  };
  const handleCancel = () => {
    setPicture({
      ...picture,
      cropperOpen: false,
    });
  };

  const changeImage = useCallback(() => {
    console.log({ src: props.src, attachment });
    if (props.profile.attachment !== attachment) {
      setPicture({
        ...picture,
        img: null,
        cropperOpen: false,
        croppedImg: null,
      });
      setAttachment(props.profile.attachment);
    }
  }, [props.profile.attachment, attachment]);

  useEffect(() => {
    changeImage();
  }, [changeImage]);
  const setEditorRef = (ed) => {
    editor = ed;
  };

  const handleSave = (e) => {
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();

      setPicture({
        ...picture,
        img: null,
        cropperOpen: false,
        croppedImg: croppedImg,
      });
      props.setInfo({ ...props.info, attachment: croppedImg });
      uploadedImage.current.src = croppedImg;
      console.log({ croppedImg, canvasScaled });
    }
  };

  const handleFileChange = (e) => {
    let url = URL.createObjectURL(e.target.files[0]);
    console.log(url);
    setPicture({
      ...picture,
      img: url,
      cropperOpen: true,
    });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "25px",
        }}
      >
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          ref={imageUploader}
          style={{
            display: "none",
          }}
        />
        <div
          style={{
            height: "150px",
            width: "150px",
            borderRadius: "100%",
            // border: "1px dashed black",
            overflow: "hidden",
          }}
          onClick={() => imageUploader.current.click()}
        >
          <img
            src={
              picture.croppedImg
                ? picture?.croppedImg
                : props.src
                ? _default.BASE_URL + props.src
                : props?.profile?.profile_type === "business" &&
                  !props?.profile?.attachment
                ? businessImage
                : photo
            }
            ref={uploadedImage}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </div>
        {picture.cropperOpen && (
          <Box display="block">
            <AvatarEditor
              ref={setEditorRef}
              image={src}
              width={200}
              height={200}
              border={50}
              color={[255, 255, 255, 0.6]} // RGBA
              rotate={0}
              scale={picture.zoom}
            />
            <Slider
              aria-label="raceSlider"
              value={picture.zoom}
              min={1}
              max={10}
              step={0.1}
              onChange={handleSlider}
            ></Slider>
            <Box>
              <Button variant="contained" onClick={handleCancel}>
                Cancel
              </Button>
              <Button onClick={handleSave}>Save</Button>
            </Box>
          </Box>
        )}
        {props.add && (
          <Grid
            item
            xs={12}
            md={12}
            container
            justify="center"
            style={{ marginTop: "10px" }}
          >
            <CustomButton
              btnText={"Add a Profile Image"}
              padding="15px 25px"
              minWidth="113px"
              height="50px"
              background={"#0042a8"}
              hoverBg="#0042a8"
              color={color.white}
              type="file"
              border="2px solid #0042a8"
              marginBottom="20px"
              textTransform="uppercase"
              onClick={() => imageUploader.current.click()}
            />
          </Grid>
        )}
      </div>
    </>
  );
}
