import * as actions from "../actions/types";

export const intialState = {
  isLoading: false,
  render: 0,
  expired: false
};

export default function loadingReducer(state = intialState, action) {
  switch (action.type) {
    case actions.LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actions.RERENDER_EMPLOYER:
      return {
        ...state,
        render: state.render + 1,
      };
      case actions.IS_EXPIRED:
      return {
        ...state,
        expired: action.payload,
      };
    default:
      return state;
  }
}
