import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import {Close, EditOutlined} from "@material-ui/icons";
// import AvatarGroup from '@mui/material/AvatarGroup';
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import jpegImage from "../../../assets/img/jpegImage.png";
import pdfImage from "../../../assets/img/pdfImage.png";
import {PropertyService} from "../../../services/property.service.js";
import { dateFormat } from "../../../Utils/dateFormate.js";
import moment from "moment";

const useStyles = makeStyles(styles);
export default function SerialNumberInformation(props) {
    const classes = useStyles();
    const propertyService = new PropertyService()

    React.useEffect(() => {
        propertyService.serialNumbersShow(props?.serialNumber[0]?.id).then(() => {
        }).catch(() => {
        })
    }, [])

    return (
        <>
            <CustomModal
                visible={props.open}
                onClose={props.handleClose}
                width="35%"
                inset="15px!important"
            >
                <ModalHeader
                    closeModal={props.handleClose}
                    icon={
                        <Close
                            style={{
                                color: color.white,
                                marginRight: "20px",
                                fontSize: "30px",
                                cursor: "pointer",
                            }}
                        />
                    }
                    rightIcon={
                        <EditOutlined
                            onClick={() => props?.handleOpenEdit(props?.serialNumber[0])}
                            style={{
                                color: color.white,
                                marginLeft: "auto",
                                fontSize: "36px",
                                cursor: "pointer",
                            }}
                        />
                    }
                    heading={"Serial Number"}
                    color={color.white}
                />
                <div className={classes.modalBody}>
                    <div className="w-100 d-flex flex-row mb-5">
                        <Typography
                            variant="p"
                            component="p"
                            align="left"
                            style={{color: "#060d5c", fontWeight: "normal"}}
                        >
                            {"Property > Serial Number > Information"}
                        </Typography>
                    </div>
                    <Grid
                        container
                        wrap="wrap"
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                    >
                        <Grid item xs={12} sm={4} md={4}>
                            <Typography
                                variant="h6"
                                component="h3"
                                style={{color: color.themeColorNew}}
                            >
                                {"Product Name"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                            <Typography
                                variant="p"
                                component="p"
                                style={{color: color.themeColorNew, fontSize: "18px"}}
                            >
                                {props?.serialNumber[0]?.product_name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Typography
                                variant="h6"
                                component="h3"
                                style={{color: color.themeColorNew}}
                            >
                                {"Serial Number"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                            <Typography
                                variant="p"
                                component="p"
                                style={{color: color.themeColorNew, fontSize: "18px"}}
                            >
                                {props?.serialNumber[0]?.number}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Typography
                                variant="h6"
                                component="h3"
                                style={{color: color.themeColorNew}}
                            >
                                {"Warranty Expiration"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                            <Typography
                                variant="p"
                                component="p"
                                style={{color: color.themeColorNew, fontSize: "18px"}}
                            >
                                {props?.serialNumber[0]?.expires
                                ? moment(props?.serialNumber[0]?.expires?.replaceAll("-","/")).format(
                                    dateFormat().toUpperCase()
                                    )
                                : "-"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Typography
                                variant="h6"
                                component="h3"
                                style={{color: color.themeColorNew}}
                            >
                                {"Notes"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                            <Typography
                                variant="p"
                                component="p"
                                style={{color: color.themeColorNew, fontSize: "18px"}}
                            >
                                {props?.serialNumber[0]?.notes}
                            </Typography>
                        </Grid>
                        {props?.serialNumber[0].web_logins ? (
                            <>
                                <Grid item xs={12} sm={4} md={4}>
                                    <Typography
                                        variant="h6"
                                        component="h3"
                                        style={{color: color.themeColorNew}}
                                    >
                                        {"Web Login"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8}>
                                    <Typography
                                        variant="p"
                                        component="p"
                                        style={{color: color.themeColorNew, fontSize: "18px"}}
                                    >
                                        {props?.serialNumber[0]?.web_logins[0]?.user_name}
                                    </Typography>
                                </Grid>
                            </>
                        ) : null}
                    </Grid>
                    {props?.serialNumber[0].documents &&
                    <Grid
                        container
                        wrap="wrap"
                        direction="row"
                        spacing={3}
                        className="mt-5"
                    >
                        <Grid item xs={12} sm={6} md={6}>
                            <Typography
                                variant="h6"
                                component="h3"
                                style={{color: color.themeColorNew}}
                            >
                                {"Linked Items"}
                            </Typography>
                            <div className="d-flex flex-row align-items-center mt-2">
                                <img src={jpegImage} className="me-2"/>
                                <img src={pdfImage} className="me-2"/>
                                <Typography
                                    onClick={props.handleOpenLinkedItems}
                                    variant="h6"
                                    component="h3"
                                    align="right"
                                    className="text-decoration-underline"
                                    style={{color: color.themeColorNew, cursor: "pointer"}}
                                >
                                    {"See all"}
                                </Typography>
                            </div>
                        </Grid>
                        {/*<Grid item xs={12} sm={6} md={6}>*/}
                        {/*  <Typography*/}
                        {/*    variant="h6"*/}
                        {/*    component="h3"*/}
                        {/*    align="right"*/}
                        {/*    style={{ color: color.themeColorNew }}*/}
                        {/*  >*/}
                        {/*    {"Collaborators"}*/}
                        {/*  </Typography>*/}
                        {/*  <div className="d-flex flex-row align-items-center justify-content-end mt-2">*/}
                        {/*    <img src={jpegImage} className="me-2" />*/}
                        {/*    <img src={pdfImage} className="me-2" />*/}
                        {/*    <Typography*/}
                        {/*      onClick={props.handleOpenCollaborators}*/}
                        {/*      variant="h6"*/}
                        {/*      component="h3"*/}
                        {/*      align="right"*/}
                        {/*      className="text-decoration-underline"*/}
                        {/*      style={{ color: color.themeColorNew, cursor: "pointer" }}*/}
                        {/*    >*/}
                        {/*      {"See all"}*/}
                        {/*    </Typography>*/}
                        {/*  </div>*/}
                        {/*</Grid>*/}
                    </Grid>}
                </div>
            </CustomModal>
        </>
    );
}
