import {store} from "../store/store";

export class Converter {
    constructor(data, heading) {
        this.data = data;
        this.heading = heading;
    }

    convertToHtml = () => {
        let str = `
    <!doctype html>
<html lang="en">
    <head>
    <!-- Required meta tags -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
​
    <!-- Bootstrap CSS -->
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
​
    <title>Document</title>
    <style>
      .oddBgLight {
        list-style: none;
      }
      .oddBgLight li div {
        width: 50%;
        float:left;
      }
      .oddBgLight li h2 {
        font-size: 26px;
        padding: 0;
        margin: 0;
        line-height: 29px;
      }
      .oddBgLight li h3 {
        font-size: 20px;
        padding: 0;
        margin: 0;
        line-height: 23px;
      }
      .oddBgLight li p {
        font-size: 18px;
        padding: 0;
        margin: 0;
        line-height: 21px;
      }
      .oddBgLight li {
        padding:25px;
        list-style: none;
        width: 100%;
        overflow: hidden;
      }
      .odd{
        padding:25px;
        background-color: #f3f3f3;
      }
    </style>
  </head>
  <body>
    <header style="background-color: #051C2C;padding:15px 70px;">
        <img src="https://axiel-public.s3.us-west-2.amazonaws.com/emails/logo.png" />
      </header>
      <div style="padding: 50px 70px;">
        <div style="display: flex; flex-direction: row; margin-bottom: 25px;">
            <div style="width: 30%;margin-right: 50px; display: inline-block;">
                <h4>DATE</h4>
                <h4 style="font-weight: bold;">${new Date().toLocaleString(
            "en-US",
            {
                year: "numeric",
                month: "long",
                day: "numeric",
            }
        )}</h4>
            </div>
            <div style="width: 50%;display: inline-block;">
                <h4>BELONGS TO</h4>
                <h4 style="font-weight: bold;">${
            store.getState().profile?.profileInfo?.profile?.first_name + " " +
            store.getState().profile?.profileInfo?.profile?.last_name
        }</h4>
            </div>
        </div>
        <div><h2 style="font-weight: bold;">${this.heading
            .split(/(?=[A-Z])/)
            .join()
            .replaceAll(",", " ")}</h3>
            <div style="width: 100%; height:2px; background: #000;"></div>
            <ul class="oddBgLight p-0 m-0 list-group">
                            <li>
                              <div>
                                <h3 class="m-0 text-break">Title</h2>
                              </div>
                             <div>
                                <h3 class="m-0 text-break">Description</h2>
                              </div>
                            </li>
                        </ul>
                <div style="width: 100%; height:2px; background: #000;"></div>`;
        Object.keys(this.data).forEach((key, index) => {
            let arr = this.data[key] instanceof Array;
            if (this.data[key]) {
                if (key !== "id" && key !== "created_at") {
                    if (!arr && typeof this.data[key] !== "object") {
                        str += `
          <ul class="oddBgLight p-0 m-0 mb-4 list-group">
                            <li class=${index % 2 === 0 ? "odd" : ""}>
                              <div>
                                <h3 class="m-0 text-break">${key
                            ?.replaceAll("_", " ")?.replace("ciphertext", "")
                            ?.toUpperCase()}</h3>
                              </div>
                              <div><p class="m-0 text-break">${
                            this.data[key]
                        }</p></div>
                            </li>
                        </ul>`;
                    }
                }
            }
        });
        str += `
            <p class="mt-5">The information in this document is confidential and may be legally privileged. Access to this document by anyone other than the intended addressee is unauthorized. If you are not the intended recipient of this message, any review, disclosure, copying, distribution, retention, or any action taken or omitted to be taken in reliance on it is prohibited and may be unlawful.</p></div></body></html>`;
        return str;
    };
}
