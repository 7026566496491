import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close, EditOutlined } from "@material-ui/icons";
// import AvatarGroup from '@mui/material/AvatarGroup';
import color from "../../../assets/css/color";
import CustomModal from "../../../components/Modal/Modal.js";
import ModalHeader from "../../../components/Modal/ModalHeader.js";
import jpegImage from "../../../assets/img/jpegImage.png";
import pdfImage from "../../../assets/img/pdfImage.png";
import { store } from "../../../store/store";
import { CustomButton } from "../../../components/CustomButton/Button";
import RevealPassword from "../RevealPassword/RevealPassword";
import moment from "moment/moment.js";
import { dateFormat } from "../../../Utils/dateFormate.js";

const useStyles = makeStyles(styles);
export default function PersonalInformations(props) {
  const classes = useStyles();
  const [openReveal, setOpenReveal] = useState(false);
  return (
    <>
      <CustomModal
        visible={props.open}
        onClose={props.handleClose}
        width="35%"
        inset="15px!important"
      >
        <ModalHeader
          closeModal={props.handleClose}
          icon={
            <Close
              style={{
                color: color.white,
                marginRight: "20px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
          rightIcon={
            <EditOutlined
              onClick={() =>
                props?.handleOpenEdit(props?.personalInformation[0])
              }
              style={{
                color: color.white,
                marginLeft: "auto",
                fontSize: "36px",
                cursor: "pointer",
              }}
            />
          }
          heading={
            props?.personalInformation[0]?.first_namee
              ? props?.personalInformation[0]?.first_namee +
                " " +
                props?.personalInformation[0]?.last_namee
              : "Personal Information"
          }
          color={color.white}
        />
        <div className={classes.modalBody}>
          <div className="w-100 d-flex flex-row mb-5">
            <Typography
              variant="p"
              component="p"
              align="left"
              style={{ color: "#060d5c", fontWeight: "normal" }}
            >
              {"Personal > Personal Information > Information"}
            </Typography>
          </div>
          <Grid
            container
            wrap="wrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Full Name"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.personalInformation[0]?.first_name +
                  " " +
                  props?.personalInformation[0]?.last_name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Birth Date"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.personalInformation[0]?.birthdate ? (
                  moment(props?.personalInformation[0]?.birthdate?.replaceAll("-","/"))?.format(
                    dateFormat()?.toLocaleUpperCase()
                  )
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Gender"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.personalInformation[0]?.gender ? (
                  props?.personalInformation[0]?.gender?.charAt(0)?.toUpperCase() + props?.personalInformation[0]?.gender.slice(1)
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Relationship Status"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.personalInformation[0]?.relationship_status ? (
                  props?.personalInformation[0]?.relationship_status
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Employment Status"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.personalInformation[0]?.employment_status ? (
                  props?.personalInformation[0]?.employment_status
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Blood Type"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.personalInformation[0]?.blood_type ? (
                  props?.personalInformation[0]?.blood_type
                ) : (
                  <small>-</small>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography
                variant="h6"
                component="h3"
                style={{ color: color.themeColorNew }}
              >
                {"Social Security Number"}
              </Typography>
              {store.getState().auth.user.masking ? (
                <>
                  {props?.personalInformation[0]
                    ?.social_security_number_ciphertext ||
                  props?.personalInformation[0]?.social_security_number ? (
                    <CustomButton
                      onClick={() => {
                        setOpenReveal(true);
                        // props.handleClose()
                      }}
                      btnText={"Reveal"}
                      textAlign="center"
                      display={"block"}
                      background="transparent"
                      hoverBg="#0042a8"
                      border="1px solid #0042a8"
                      borderRadius="8px"
                      color="#0042a8"
                    />
                  ) : null}
                </>
              ) : null}
              {openReveal && (
                <RevealPassword
                  open={openReveal}
                  handleClose={() => setOpenReveal(false)}
                  subcategory_name={"PersonalInformation"}
                  password={
                    props?.personalInformation[0]
                      ?.social_security_number_ciphertext ||
                    props?.personalInformation[0]?.social_security_number
                  }
                  id={props?.personalInformation[0]?.id}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
              <Typography
                variant="p"
                component="p"
                style={{ color: color.themeColorNew, fontSize: "18px" }}
              >
                {props?.personalInformation[0]
                  ?.social_security_number_ciphertext ||
                  props?.personalInformation[0]?.social_security_number}
              </Typography>
              {/*{store.getState().auth.user.masking ? (*/}
              {/*  <>*/}
              {/*    <CustomButton*/}
              {/*      onClick={() => {*/}
              {/*        setOpenReveal(true);*/}
              {/*        // props.handleClose()*/}
              {/*      }}*/}
              {/*      btnText={"Reveal"}*/}
              {/*    />*/}
              {/*  </>*/}
              {/*) : null}*/}
              {/*{openReveal && (*/}
              {/*  <RevealPassword*/}
              {/*    closeParent={props.handleClose}*/}
              {/*    open={openReveal}*/}
              {/*    handleClose={() => setOpenReveal(false)}*/}
              {/*    subcategory_name={"PersonalInformation"}*/}
              {/*    password={*/}
              {/*      props?.personalInformation[0]?.social_security_number*/}
              {/*    }*/}
              {/*    id={props?.personalInformation[0]?.id}*/}
              {/*  />*/}
              {/*)}*/}
            </Grid>
          </Grid>
          {props?.personalInformation[0]?.documents && (
            <Grid
              container
              wrap="wrap"
              direction="row"
              spacing={3}
              className="mt-5"
            >
              <Grid item xs={12} sm={6} md={6}>
                <Typography
                  variant="h6"
                  component="h3"
                  style={{ color: color.themeColorNew }}
                >
                  {"Linked Items"}
                </Typography>
                <div className="d-flex flex-row align-items-center mt-2">
                  <img src={jpegImage} className="me-2" />
                  <img src={pdfImage} className="me-2" />
                  <Typography
                    onClick={props.handleOpenLinkedItems}
                    variant="h6"
                    component="h3"
                    align="right"
                    className="text-decoration-underline"
                    style={{ color: color.themeColorNew, cursor: "pointer" }}
                  >
                    {"See all"}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          )}
        </div>
      </CustomModal>
    </>
  );
}
