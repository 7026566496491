import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

export default function CustomModal(props) {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      // alignItems: 'center',
      justifyContent: "center",
      overflow: "overlay",
      inset: [props.inset],
      zIndex: [props.zIndex],
      height: [props.height],
      background: [props.background],
    },
    paper: {
      minWidth: "45%",
      width: [props.width],
      maxWidth: [props.maxWidth],
      backgroundColor: theme.palette.background.paper,
      background: [props.innerbackgruond],
      height: "fit-content",
      borderRadius: "12px",
      //   border: '2px solid #000',
      boxShadow: theme.shadows[5],
      margin: [props.margin],
      //   padding: theme.spacing(2, 4, 3),
      [theme.breakpoints.down("md")]: {
        width: "70%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
    },
  }));

  const classes = useStyles();

  return (
    <div>
      <Modal
        width={props.width}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal + " " + props.bgOpacity}
        open={props?.visible}
        onClose={props?.onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade
          in={props?.visible}
          style={{
            marginTop: props.marginTop,
            marginBottom: props.marginBottom,
          }}
        >
          <div className={classes.paper + " " + props.extraclass}>
            {props?.children}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
