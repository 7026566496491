import _default from "../config/default";

export class Auth {
  constructor() {
    console.log({_default})
    this.BASE_URL = _default.BASE_URL;
    this.AUTH_ENDPOINT = _default.ENDPOINT_AUTH;
  }

  signOutAll(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/clear_sessions`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  receivedDocumentShow(profile_id, shared_document_id, token) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${profile_id}/unauthorized_show/${shared_document_id}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      method: "GET",
      params: {
        token,
      },
    });
  }

  receivedMultiDocuments(share_id, token) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/unauthorized_list`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      method: "GET",
      params: {
        share_id,
        token,
      },
    });
  }

  viewedAt(profile_id, shared_document_id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${profile_id}/viewed_shared_documents/${shared_document_id}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      method: "GET",
    });
  }

  deleteAccount(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/delete_users`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  license(bool, pid, sid) {
    console.log({ pid, sid });
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/profiles/${pid}/shares/${sid}/share_license`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      method: "PUT",
      data: {
        is_accepted: bool,
      },
    });
  }

  sendOtp(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/sms`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      method: "POST",
      data: data,
    });
  }

  register(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/auth`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      method: "POST",
      data: data,
    });
  }

  updatePassword(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/auth`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  axielNotifications(bool) {
    return _default.Axios({
      url: `${this.BASE_URL}/auth`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: {
        pause_notification: bool,
      },
    });
  }

  twofa(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/auth`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  getEmailPreferences() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/email_preferences`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  updateMasking(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/auth`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  updateEmailPreferences(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/email_preferences/${data.id}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: { email_preference: data.object },
    });
  }

  updateTimeZone(data) {
    console.log({ data });
    return _default.Axios({
      url: `${this.BASE_URL}/auth`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: { time_zone: data },
    });
  }

  updateEmail(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/auth`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  createSubscription(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/subscription/${data?.plan}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: data,
    });
  }

  createPaymentMethod(tok) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/payment_methods`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "POST",
      data: {
        token: tok,
      },
    });
  }

  updatePaymentMethod(id, bool) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/payment_methods/${id}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: {
        is_primary: bool,
      },
    });
  }

  deletePaymentMethod(id) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/payment_methods/${id}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  indexPaymentMethod() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/payment_methods`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }
  indexPlans() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/plans`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  updateSubscription(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/subscription`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: data,
    });
  }

  requestPasswordReset(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/auth/password`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      method: "POST",
      data: data,
    });
  }

  resetPassword(data, headers) {
    return _default.Axios({
      url: `${this.BASE_URL}/auth/password`,
      headers: headers,
      method: "PUT",
      data: data,
    });
  }

  login(data) {
    return _default.Axios({
      url: `${this.BASE_URL}/auth/sign_in`,
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      data: data,
    }, {
      withCredentials: true
    });
  }

  logout() {
    return _default.Axios({
      url: `${this.BASE_URL}/auth/sign_out`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "DELETE",
    });
  }

  accountAttributes() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/account_attributes`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  fetchReminders() {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/reminders`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "GET",
    });
  }

  updateReminders(paylaod) {
    return _default.Axios({
      url: `${this.BASE_URL}/api/v1/update_reminders`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        client: JSON.parse(localStorage.getItem("brow$3rCt")),
        uid: JSON.parse(localStorage.getItem("uid")),
        "access-token": JSON.parse(localStorage.getItem("brow$3rAT")),
      },
      method: "PUT",
      data: {
        ids: paylaod,
      },
    });
  }
}
