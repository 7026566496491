import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import { Close, EditOutlined, MoreVert } from '@material-ui/icons';
import { Avatar } from '@material-ui/core';
// import AvatarGroup from '@mui/material/AvatarGroup';
import color from '../../../assets/css/color';
import CustomModal from '../../../components/Modal/Modal.js';
import ModalHeader from '../../../components/Modal/ModalHeader.js';
import jpegImage from '../../../assets/img/jpegImage.png'
import pdfImage from '../../../assets/img/pdfImage.png'
import backMe from '../../../assets/img/back.png'
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import { Link } from 'react-router-dom';
import { CustomButton } from '../../../components/CustomButton/Button.js';


const useStyles = makeStyles(styles);
export default function W2Form(props) {
    const classes = useStyles();

    return (
        <>
            <CustomModal visible={props.open} onClose={props?.handleCloseW2Form} width="35%" inset= "15px!important">
                <ModalHeader closeModal={props.handleClose}
                    icon={
                        <Close
                            style={{
                                color: color.white,
                                marginRight: "20px",
                                fontSize: "30px",
                                cursor: "pointer",
                            }}
                        />
                    }
                    rightIcon={
                        <EditOutlined
                            style={{
                                color: color.white,
                                marginLeft: "auto",
                                fontSize: "36px",
                                cursor: "pointer",
                            }}
                        />
                    }
                    heading={"W2 Form"}
                    color={color.white}
                />
                <div className={classes.modalBody}>
                    <div className="w-100 d-flex flex-row align-items-center mb-5">
                        <Typography variant="p" component="p" align="left" className="me-3" style={{ color: "#060d5c", fontWeight: "normal", }}>
                            {"Employment > Employers > Document"}
                            <span className="px-4 py-3 ms-3 rounded-pill" style={{ background: color.themeOrange, color: color.white }}>READ ONLY</span>
                        </Typography>
                        {/* <CustomButton 
                            type="submit"
                            fullWidth={false}
                            btnText="READ ONLY"
                            marginBottom="0"
                            height="52px"
                            padding="8px 30px"
                            background="#0042a8"
                            hoverBg="#0042a8"
                            border="0"
                            borderRadius="25px"
                            color="#fff"
                       />*/}
                    </div>
                    <div className={classes.W2FormDoc} id="click-to-expand-main" style={{cursor:"pointer"}}>
                        <div className="w-100 h-100 justify-content-center align-items-center position-absolute click-to-expand">
                            <Typography variant="h4" component="h3" align='center' style={{ color: color.white, }}>
                                {"Click to expand"}
                            </Typography>
                        </div>
                    </div>
                    <Grid container wrap="wrap"
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={3}
                        className="mt-5">
                        <Grid item xs={12} sm={6} md={6}>
                            <Typography variant="h6" component="h3" style={{ color: color.themeColorNew }}>
                                {"Linked Items"}
                            </Typography>
                            <div className="d-flex flex-row align-items-center mt-2">
                                <img src={jpegImage} className="me-2" />
                                <img src={pdfImage} className="me-2" />
                                <Typography onClick={props.handleOpenLinkedItems} variant="h6" component="h3" align='right' className="text-decoration-underline" style={{ color: color.themeColorNew, cursor: 'pointer' }}>
                                    {"See all"}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Typography variant="h6" component="h3" align='right' style={{ color: color.themeColorNew }}>
                                {"Collaborators"}
                            </Typography>
                            <div className="d-flex flex-row align-items-center justify-content-end mt-2">
                                <img src={jpegImage} className="me-2" />
                                <img src={pdfImage} className="me-2" />
                                <Typography onClick={props.handleOpenCollaborators} variant="h6" component="h3" align='right' className="text-decoration-underline" style={{ color: color.themeColorNew, cursor: 'pointer' }}>
                                    {"See all"}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </CustomModal>
        </>
    );
}