import React, { useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CustomButton } from "../../components/CustomButton/Button";
import exiellogo from "../../assets/img/exiel-logo.png";
import { FormInput } from "../../components/FormInput/FormInput";
import {
  clearError,
  resetPasswordRequest,
  setError,
} from "../../store/actions/authAction";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import NewLogo from "./../../assets/img/tag-logo.png";
import {Validation} from "../../services/validators";

const validator = new Validation();

const ResetPasswordVerification = (props) => {
  const [state, setState] = React.useState({
    email: "",
  });
  const [error, setError] = React.useState({
    emailError: null,
  });

  const handleChange = (event) => {
    if (event.target.name === "checkedA") {
      setState({ ...state, [event.target.name]: event.target.checked });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
    if (event.target.name === "email") {
        setError({
            ...error,
            emailError: !validator.email(event.target.value)
                ? "Please enter a valid email address"
                : null,
        });
        setState({...state, email: event.target.value});
    }
  };

  const handleSubmit = () => {
    const data = {
      email: state.email,
      redirect_url: process.env.REACT_APP_REDIRECT_URL,
    };

    props.resetPasswordRequest(data, props?.history);
  };

  const isFormValid = () => {
    return !error.emailError && state.email.trim() !== "";
  };
  const useStyles = makeStyles((theme) => ({}));
  const classes = useStyles();
  useEffect(() => {
    return () => {
      props?.setReduxError(false);
    };
  }, []);
  return (
    <div className="w-75 mx-auto">
      <Box mb={5} textAlign="center">
        <img
          src={NewLogo}
          alt="s"
          width={120}
          style={{ marginBottom: "15px" }}
        />
        <Typography variant="h5" component="h3" align="center">
          {"Forgot Password"}
        </Typography>
      </Box>
      {props?.authError ? (
        <Box mb={3}>
          <Typography variant="p" component="p" color="error">
            {props?.authError}
          </Typography>
        </Box>
      ) : null}
      <Box mb={3}>
        <FormInput
          id="email"
          name="email"
          label="Email"
          variant="outlined"
          fullWidth={true}
          margin="dense"
          required={true}
          autoComplete="email"
          onChange={handleChange}
        />
        <Typography
          color="error"
          // className="errorPositioning"
          component="p"
          variant="p"
        >
          {error.emailError ?? error.emailError}
        </Typography>
      </Box>
      {props?.loading ? (
        <CustomButton
          type="submit"
          fullWidth={true}
          btnText={<CircularProgress size={20} color="#DDD" />}
          textAlign="center"
          display={"block"}
          marginBottom="18px"
          padding="16px 17px"
          background="#0042a8"
          hoverBg="#0042a8"
          border="0"
          borderRadius="8px"
          color="#fff"
        />
      ) : (
        <CustomButton
          type="submit"
          disabled={!isFormValid()}
          fullWidth={true}
          btnText="Send"
          textAlign="center"
          display={"block"}
          marginBottom="18px"
          padding="16px 17px"
          background="#0042a8"
          hoverBg="#0042a8"
          border="0"
          borderRadius="8px"
          color="#fff"
          onClick={handleSubmit}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPasswordRequest: (data, props) =>
      dispatch(resetPasswordRequest(data, props)),
    setReduxError: (err) => dispatch(clearError(err)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state?.auth?.loading,
    authError: state?.auth?.error,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordVerification);
