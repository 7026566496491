import React, { useEffect, useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import styles from "../../../assets/jss/material-dashboard-react/components/AccountSettingTabs.js";
import ActivityHistoryAccordion from "./ActivityHistoryAccordion";
import { HistoryService } from "../../../services/history.service";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Pagination } from "@material-ui/lab";
import { connect } from "react-redux";
import { getDateIgnoreTimezone } from "../../../Utils/dateHelper";

const useStyles = makeStyles(styles);

function ActivityHistory({ sort, order }) {
  const classes = useStyles();
  const [current, setCurrent] = useState([]);
  const [err, setErr] = useState(null);
  const [activities, setActivites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [activitiesPerPage, setActivitiesPerPage] = useState(5);
  const historyService = new HistoryService();

  useEffect(() => {
    setLoading(true);
    historyService
      .activityHistoryIndex()
      .then((res) => {
        setLoading(false);
        setActivites(res?.data?.actions ?? []);
      })
      //         sort === "alpha"
      //             ? a.actionable_type?.localeCompare(
      //                 b.actionable_type
      //             )
      //             : new Date(b?.created_at) - new Date(a?.created_at)
      //     ) : []);
      // })
      .catch((err) => {
        setLoading(false);
      });
  }, []);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const indexOfLastActivity = currentPage * activitiesPerPage;
  const indexOfFirstActivity = indexOfLastActivity - activitiesPerPage;
  const currentActivities = useCallback(() => {
    setCurrent(
      activities
        ?.sort((a, b) => {
          if (sort === "alpha") {
            if (order === "asc") {
              return a.actionable_type?.localeCompare(b.actionable_type);
            } else {
              return b.actionable_type?.localeCompare(a.actionable_type);
            }
          } else {
            if (order === "asc") {
              return (
                getDateIgnoreTimezone(
                  new Date(b?.created_at?.split(" ")[0]?.replaceAll("-", "/"))
                ) -
                getDateIgnoreTimezone(
                  new Date(a?.created_at?.split(" ")[0]?.replaceAll("-", "/"))
                )
              );
            } else {
              return (
                getDateIgnoreTimezone(
                  new Date(a?.created_at?.split(" ")[0]?.replaceAll("-", "/"))
                ) -
                getDateIgnoreTimezone(
                  new Date(b?.created_at?.split(" ")[0]?.replaceAll("-", "/"))
                )
              );
            }
          }
        })
        .slice(indexOfFirstActivity, indexOfLastActivity)
    );
  }, [sort, order, activities, currentPage]);

  useEffect(() => {
    currentActivities();
  }, [activities.length, sort, order, currentPage]);

  return (
    <>
      <Grid container className={classes.marginBottom18px}>
        <Grid item xs={12} md={12}>
          <Typography
            variant="h6"
            component="h3"
            style={{ color: "#060d5c", marginBottom: "18px" }}
          >
            {"Activity History"}
          </Typography>
        </Grid>
      </Grid>
      {!loading && activities?.length > 0 ? (
        <>
          <ActivityHistoryAccordion activities={current} />
          <Pagination
            showLastButton
            showFirstButton
            page={currentPage}
            onChange={handleChangePage}
            count={Math.ceil(activities.length / activitiesPerPage)}
            color="primary"
          />
        </>
      ) : err ? (
        <p>{err}</p>
      ) : !loading && activities.length === 0 ? (
        <p>No history yet</p>
      ) : (
        <CircularProgress size={20} color="#DDD" />
      )}
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    sort: state.sort.sort,
    order: state?.sort?.order,
  };
};
export default connect(mapStateToProps)(ActivityHistory);
