import * as types from "./types";
import { Search } from "../../services/search.service.js";
import { store } from "../store";

const searchService = new Search();

const searchResult = (results) => {
  return {
    type: types.SEARCH,
    payload: results,
  };
};

export const setSearchQuery = (query) => {
  return {
    type: types.SEARCH_QUERY,
    payload: query,
  };
};

export const setSearchError = (err) => {
  return {
    type: types.SEARCH_ERROR,
    payload: err,
  };
};

export const searchApi = (query) => {
  return async (dispatch) => {
    dispatch(setSearchQuery(query));
    try {
      const response = await searchService.search(query);
      if (response.data.search) {
        const searchResults = await response.data?.search;

        dispatch(searchResult(searchResults));
      }
      dispatch(setSearchError("Could not find anything"));
      console.log("====================================");
      console.log({ response });
      console.log("====================================");
    } catch (err) {
      dispatch(setSearchError("Could not find anything"));
    }
  };
};
